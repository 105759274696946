import React, { Component } from 'react';
import onBoardUtils from './OnBoardingConstant';
import Constant from '../../../constant';
import { Grid, Card } from '@material-ui/core';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../../utils/CustomFilter/CustomFilter';
import CustomTable from "../../../utils/Table/Table";

class EmployerOnBoarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onBoardList: [],
            filters: onBoardUtils.OnBoardingFilterList,
            currentPage: 0,
            count:0,
            rowsPerPage:10,
            isTableUpdating:false,
            column: [{
                id: "1",
                name: "status",
                label: Constant.employerOnBoarding.status,
                isColumnVisible: true
            }, {
                id: "2",
                name: "employerId",
                label: Constant.employerOnBoarding.employerId,
                isColumnVisible: true
            }, {
                id: "3",
                name: "company_name",
                label: Constant.employerOnBoarding.company_name,
                isColumnVisible: true
            }, {
                id: "4",
                name: "industry",
                label: Constant.employerOnBoarding.industry,
                isColumnVisible: true
            }
                , {
                id: "5",
                name: "location",
                label: Constant.employerOnBoarding.location,
                isColumnVisible: true
            },
            {
                id: "6",
                name: "contact_person",
                label: Constant.employerOnBoarding.contact_person,
                isColumnVisible: true

            },
            {
                id: "7",
                name: "phone_number",
                label: Constant.employerOnBoarding.phone_number,
                isColumnVisible: true
            }, {
                id: "8",
                name: "email",
                label: Constant.employerOnBoarding.email,
                isColumnVisible: true
            }, {
                id: "9",
                name: "registered_on",
                label: Constant.employerOnBoarding.registered_on,
                isColumnVisible: true
            }, {
                id: "10",
                name: "referral_code",
                label: Constant.employerOnBoarding.referral_code,
                isColumnVisible: true
            }, {
                id: "11",
                name: "last_activity_on",
                label: Constant.employerOnBoarding.last_activity_on,
                isColumnVisible: true
            }, {
                id: "12",
                name: "review_status",
                label: Constant.employerOnBoarding.review_status,
                isColumnVisible: true
            },
            {
                id: "13",
                name: "last_comment",
                label: Constant.employerOnBoarding.last_comment,
                isColumnVisible: true
            },
            {
                id: "14",
                name: "action",
                label: Constant.employerOnBoarding.action,
                isColumnVisible: true
            }]
        }
        this.data = [
            {
                "status": "Registered Only",
                "employerId": 1,
                "company_name": "Sushi Junction",
                "industry": "Restaurant",
                "location": "Gurgaon",
                "contact_person": "Himanshu Chopara",
                "phone_number": "+91-1235698470",
                "email":"abc@gmail.com",
                "registered_on":"20/11/2019",
                "referral_code":"",
                "last_activity_on":"20/11/2019",
                "review_status":"Not Reviewed",
                "last_comment":"By Swami Sagar On 12/11/2019 Lorem Ipsum LoremIpsum"
            },
            {
                "status": "Partly Filled Info",
                "employerId": 2,
                "company_name": "Chillis",
                "industry": "Restaurant",
                "location": "Gurgaon",
                "contact_person": "Himanshu Chopara",
                "phone_number": "+91-1235698470",
                "email":"abc@gmail.com",
                "registered_on":"20/11/2019",
                "referral_code":"",
                "last_activity_on":"20/11/2019",
                "review_status":"Reviewed",
                "last_comment":"By Swami Sagar On 12/11/2019 Lorem Ipsum LoremIpsum"

            },
            {

                "status": "All Info Filled",
                "employerId": 3,
                "company_name": "Fifth Avenue",
                "industry": "Restaurant",
                "location": "Delhi",
                "contact_person": "Himanshu Chopara",
                "phone_number": "+91-1235698470",
                "email":"abc@gmail.com",
                "registered_on":"20/11/2019",
                "referral_code":"",
                "last_activity_on":"20/11/2019",
                "review_status":"In Reviewed",
                "last_comment":"By Swami Sagar On 12/11/2019 Lorem Ipsum LoremIpsum"

            }
        ];


        this.rowsOptions=[5,10,25,50,100,200,500];
        this.updateColumnFileds = this.updateColumnFileds.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateDataOnTablePagination =this.updateDataOnTablePagination.bind(this);


    }
    updateDataOnTablePagination(rowsPerPage=this.state.rowsPerPage,nextPage=this.state.currentPage){
        //to do
     
    }
    updateColumnFileds(columnsToHide) {
        const upadtedColumns = this.state.column.map((col) => {
            if (columnsToHide.includes(col.id)) {
                col.isColumnVisible = true;
            } else {
                col.isColumnVisible = false;
            }
            return (col);
        })

        this.setState({
            columns: upadtedColumns,
        })
    }
    async updateFilters(recievedFilters) {

        const updatedFilter = this.state.filters.map((filter) => {
            for (let el of recievedFilters) {
                if (el[filter.name]) {
                    filter.default = el[filter.name]
                }
            }
            return filter;
        })
        await this.setState({
            filters: updatedFilter
        })

    }

    componentDidMount() {
        // Axios.get("http://localhost:3001/employers").then(
        //     (response) => { 
        //         this.setState({
        //             employer: response.data,

        //         });
        //     }
        // ).catch(
        //     (error) => { console.log(error) }
        // );
        this.setState({ onBoardList: this.data });

    }
    render() {
        const tablePagination = {
            currentPage:this.state.currentPage,
            count:this.state.count,
            changePage:this.updateDataOnTablePagination,
            rowsOptions:this.rowsOptions,
            rowsPerPage:this.state.rowsPerPage,
            tableUpdating:this.state.isTableUpdating
        }
        return (
            <div>
                <Card style={{ margin: "20px 0px", padding: "20px" }}>
                    <Grid container >
                        <Grid item sm={3}>
                            <HideUnhideColumns columns={this.state.column} toggleColumn={this.updateColumnFileds}></HideUnhideColumns>
                        </Grid>
                        <Grid item sm={3}>
                            <CustomFilter filters={this.state.filters} updateFilters={this.updateFilters}></CustomFilter>
                        </Grid>
                    </Grid>
                </Card>
                <CustomTable columns={this.state.column} data={this.state.onBoardList} links={onBoardUtils.OnBoardingListAction} title="OnBoarding Review Details" tablePagination={tablePagination}></CustomTable>
            </div>

        );
    }
}

export default EmployerOnBoarding;