import React, {useState} from 'react'
import './onDemandJobs.css'
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import location from '../../../assets/icons8-marker-100 (1).png' 
import phone from '../../../assets/icons8-call-100.png' 
import clock from '../../../assets/icons8-clock-500 (1).png' 
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as moment from 'moment';


const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#525252',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
  },
  arrow:{
      backgroundColor: '#ffffff',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  select:{
    minWidth: 140,
    padding: 15,
  }
}));

const OnDemandJob=(props) =>{
    const classes = useStyles();    
    let joining= props.data;
    const [status, setStatus] = useState('Joining status')
    const handleChange = event => {
        // console.log(props)
        setStatus(event.target.value);
    };
    
    let shortAddress=`${joining.companyName}, ${joining.companyCity}`
    let chipLabel='On Demand';

    return (
        <div className={props.cname}>
            <div>
               <div className="NPT">
                    <h4>{joining.name}-{joining.profile}</h4> <Chip label={chipLabel} className="chipStyle" />
                </div> 
        
                <div className="address">
                    <img className="iconImg" src={location} alt='location' />
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <div className="toolTipTitle">
                                <span><b>{joining.companyName}</b></span>
                                <span>{joining.contactName} - {joining.contactNumber}</span>
                                <span>{joining.address}</span>
                            </div>
                        </React.Fragment>
                        } interactive  placement="bottom-start"
                            TransitionComponent={Zoom}  
                    > 
                        <span>{shortAddress}</span>
                    </HtmlTooltip>
                </div>
                <div className="time">
                    <img className="iconImg" src={clock} alt='location' /> {joining.joiningDate==moment(new Date()).format('L')?'Today':'Tomorrow'}, {joining.startTime} to {joining.endTime}
                </div>
            </div>
            <div className="status">
                <div className="phoneNumber">
                    <img src={phone} alt='location' /><span className="candidateNumber"><a href={`tel:${joining.candidateNumber}`}>+91-{joining.candidateNumber}</a></span>
                </div>
                <div className="joiningStatus">
                    <Select
                        label="joiningStatus"
                        value={status}
                        variant='outlined'

                        onChange={handleChange}
                        classes={{root: classes.select}}
                        >
                        <MenuItem value="Joining status" disabled>
                            <em>Joining status</em>
                        </MenuItem>
                        <MenuItem value='going'>Going</MenuItem>
                        <MenuItem value='notGoing'>Not going</MenuItem>
                        <MenuItem value='noResponse'>No Response</MenuItem>
                        <MenuItem value='backupFound'>Backup Found</MenuItem>
                        <MenuItem value='backupNotFound'>Backup Not Found</MenuItem>
                        <MenuItem value='noShow'>No Show</MenuItem>
                        <MenuItem value='jobStarted'>Job Started</MenuItem>
                        <MenuItem value='jobCompleted'>Job Completed</MenuItem>
                    </Select>
                </div>
            </div>
        </div>
    )

}

export default OnDemandJob;