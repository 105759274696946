import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import { lightGreen } from '@material-ui/core/colors';
import MaterialTable from 'material-table';
import Axios from 'axios';
import urlLinks from '../../urlLinks';
import Select from '../../utils/simpleSelect/simpleSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const styles = {
  Container: {
    backgroundColor: '#E8EAF6',
    marginTop: '3%',
    fontSize: '.8rem',
    paddingBottom: '2%'
  },
  Expanation: {
    paddingTop: '2%',
    paddingBottom: '2%',
    fontSize: '.8rem'
  },
  switch: {
    marginTop: '-3px'
  },
  pikers: {
    marginBottom: '5px',
    marginRight: '20%'
  },
  textField: {
    marginBottom: '13px',
    marginTop: '-11px'
  },
  font: {
    fontSize: '.8rem'
  },
  heading: {
    fontWeight: 'bold'
  },
  m: {
    paddingTop: '20%'
  }
};
const PurpleSwitch = withStyles({
  switchBase: {
    color: lightGreen[200],
    '&$checked': {
      color: lightGreen['A700']
    },
    '&$checked + $track': {
      backgroundColor: lightGreen['A700']
    }
  },
  checked: {},
  track: {}
})(Switch);

class CutOffConditions2 extends Component {
  constructor(props) {
    super(props);

    this.category='od-work backup';
    this.section='cut-off';
    this.CutOffConditionsData=[];
    this.nData={};
    this.dumy=[{hello:0}];

    this.label='Value';
    this.option = [
      {
        id: 1,
        rating: 'cut-off',
        label: 'Cut-Off'
      },
      {
        id: 2,
        rating: 'scoring',
        label: 'Scoring'
      },
      {
        id: 3,
        rating: 'distance-cut-off',
        label: 'Distance-Cut-Off'
      }
    ];

    this.state = {
      rating:'cut-off',
      mytext1: '',
      mytext2: '',
      mytext3: '',
      isLoading:true,
      columns: [
        {
          title: 'Criteria',
          field: 'Criteria',
          cellStyle: {
            backgroundColor: '',
            color: '',
            paddingTop: '2px',
            paddingLeft: '4%',
            paddingBottom: '2px'
          }
        },
        { title: 'Value', field: 'Value', type: 'numeric', min: 0 },
        { title: 'Weightage', field: 'Weightage', type: 'numeric' }
      ],
      data: [
        // { Criteria: 'Worker availability', Value: 1, Weightage: 1 },
        // { Criteria: 'Experience/Education/Job Type', Value: 1, Weightage: 1 },
        // { Criteria: 'Language requirement', Value: 1, Weightage: 1 },
        // { Criteria: 'Distance from work location lesser than', Value: 25,Weightage: 1},
        // { Criteria: 'Breach lesser than', Value: 3, Weightage: 1 },
        // { Criteria: 'Rating by the same Employer greater than',Value: 2,Weightage: 1}
      ],
      calculationColumns: [
        {
          title: 'Criteria',
          field: 'Criteria',
          editable: 'never',
          cellStyle: {
            backgroundColor: '',
            color: '',
            paddingTop: '2px',
            paddingLeft: '4%',
            paddingBottom: '2px',
            fontSize: '.8rem'
          }
        },
        { title: 'Score Formula', field: 'scoreFormula' },
        { title: 'Weightage', field: 'Weightage', type: 'numeric' }
      ],
      calculationData: [
        { Criteria: 'Brech Score', scoreFormula: '5-x', Weightage: 0.2 },
        {
          Criteria: 'Distance from work location(in kms)',
          scoreFormula: 'IF(x>=25,0,(25-x)/5)',
          Weightage: 0.325
        },
        { Criteria: 'Rating Average', scoreFormula: 'x', Weightage: 0.325 },
        {
          Criteria: 'Number of Gigs',
          scoreFormula: 'IF(x>=80,5,x/16)',
          Weightage: 0.1
        },
        {
          Criteria: 'Year of experience',
          scoreFormula: 'IF(C16>5,5,C16)',
          Weightage: 0.05
        },
        {
          Criteria: 'Recency of Gigs (Lat Gig Day)',
          scoreFormula: 'IF(C17>=60,1,(60-C17)/12)',
          Weightage: 0
        },
        { Criteria: 'Acceptance Rate', scoreFormula: 'x*5', Weightage: 0 }
      ],

    

      switchVal1: false,
      switchVal2: false,
      switchVal3: false,
      switchVal4: false,
      switchVal5: false,
      switchVal6: false,
      switchVal7: false,
      switchVal8: false,
      switchVal9: false,
      switchVal10: false
    };
    this.getDataFunction=this.getDataFunction.bind(this);
  }
  handleChange = ({ target }) => {
    if (target.value > 0) {
      this.setState({ [target.name]: target.value });
    }
  };


  
  handleSelectChange = val => {
    this.setState({ rating: val },()=>{this.getDataFunction()});
    // console.log(val);
  };


  
  getDataFunction(){


    Axios.get(
      `${urlLinks.baseUrl}/admin/GlobalCuttof?category=${this.category}&section=${this.state.rating}`
    ).then(response => {
      const data=response.data.response;
      this.CutOffConditionsData=data;
      var sTableData=[];
      // console.log(this.CutOffConditionsData)

      var j=0;
      for(let i=0;i<this.CutOffConditionsData.length;i++){
        var a={};
        a.Criteria=this.CutOffConditionsData[i].criteria;
        a.Value=this.CutOffConditionsData[i].subWeightage;
        a.Weightage=this.CutOffConditionsData[i].weightage;
        a.id=this.CutOffConditionsData[i].assignRulesId;
        sTableData[j]=a;
        j++;
      }
      // console.log(sTableData);

      this.setState({data:sTableData});
      this.setState({isLoading:false});

      if(this.state.rating==='cut-off'){
        this.setState({
          switchVal1: this.CutOffConditionsData[0].enabled?true:false,
          switchVal2: this.CutOffConditionsData[1].enabled?true:false,
          switchVal3: this.CutOffConditionsData[2].enabled?true:false,
          switchVal4: this.CutOffConditionsData[3].enabled?true:false,
          switchVal5: this.CutOffConditionsData[4].enabled?true:false,
          switchVal6: this.CutOffConditionsData[5].enabled?true:false,
          switchVal7: this.CutOffConditionsData[6].enabled?true:false,
          switchVal8: this.CutOffConditionsData[7].enabled?true:false,
          switchVal9: this.CutOffConditionsData[8].enabled?true:false,
          // switchVal10: this.CutOffConditionsData[9].enabled?true:false
        })
      }else if(this.state.rating==='cut-off'){
        this.setState({
          switchVal1: this.CutOffConditionsData[0].enabled?true:false,
          switchVal2: this.CutOffConditionsData[1].enabled?true:false,
          switchVal3: this.CutOffConditionsData[2].enabled?true:false,
          switchVal4: this.CutOffConditionsData[3].enabled?true:false,
          switchVal5: this.CutOffConditionsData[4].enabled?true:false,
          switchVal6: this.CutOffConditionsData[5].enabled?true:false,
        })
        
      }else{
        this.setState({
          switchVal1: this.CutOffConditionsData[0].enabled?true:false,
          switchVal2: this.CutOffConditionsData[1].enabled?true:false,
          switchVal3: this.CutOffConditionsData[2].enabled?true:false,
          switchVal4: this.CutOffConditionsData[3].enabled?true:false,
        })
      }
    });
  }


  componentDidMount(){
    // console.log(`Condition2`)
    // console.log(this.props.id);
    this.getDataFunction();
   
  };

  render() {
    // console.log( this.props  );
    const { classes } = this.props;
    return this.state.isLoading?(

      <CircularProgress
      
      style={{
        position: 'absolute',
        // display: 'flex',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width:'3em',
        height:'3em',
        margin: 'auto'
      }}

      >

      </CircularProgress>

    ): (

      <div>
      <ToastContainer
      position='top-right'
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      autoClose={4000}
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
     ></ToastContainer>
      <Container maxWidth='sm' className={classes.Container}>
        <Grid className={classes.Expanation}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={classes.heading}>
                {' '}
                Cut Off Conditions{' '}
              </Typography>
              {<ExpandMoreIcon />}

              <div style={{marginLeft:"120px"}}>
              <Select
               ratingValue={this.state.rating}
               option={this.option}
               label={this.label}
               handleChange={this.handleSelectChange}
              
              ></Select>
              </div>

            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              
              <Grid item xs={10}>
                {this.CutOffConditionsData.map((Data, index)=>{
                  // console.log(Data);
                  return(
                    <div key={index}>
                      <Typography className={classes.font}>
                        {Data.criteria}
                      </Typography>
                      <br />
                    </div>
                  );
                })}

                {/* <Typography className={classes.font}>
                  Experience/Education/Job Types Matches
                </Typography>
                <br />
                <Typography className={classes.font}>
                  Language requirement Matches
                </Typography>
                <br />
                <Typography className={classes.font}>
                  Distance from work location
                </Typography>
                <br />
                <Typography className={classes.font}>Breach Count</Typography>
                <br />
                <Typography className={classes.font}>
                  Rating by the same Empoyer before
                </Typography>
                <br />
                <Typography className={classes.font}>
                  No other Job scheduled at that time
                </Typography> */}
              </Grid>
              <Grid item xs={1} className={classes.pikers}>
                {/* <TextField
                  className={classes.textField}
                  label="Employee available"
                  id="outlined-size-small"
                  type="number"
                  defaultValue="5"
                  variant="outlined"
                  size="small"
                />
                <TextField
                  className={classes.textField}
                  label="Employee available"
                  id="outlined-size-small"
                  type="number"
                  defaultValue="5"
                  variant="outlined"
                  size="small"
                />
                <TextField
                  className={classes.textField}
                  label="Employee available"
                  id="outlined-size-small"
                  type="number"
                  defaultValue="5"
                  variant="outlined"
                  size="small"
                />
                <TextField
                  className={classes.textField}
                  label="Employee available"
                  id="outlined-size-small"
                  type="number"
                  defaultValue="5"
                  variant="outlined"
                  size="small"
                />
                <TextField
                  className={classes.textField}
                  label="Employee available"
                  id="outlined-size-small"
                  type="number"
                  defaultValue="5"
                  variant="outlined"
                  size="small"
                />
                <TextField
                  className={classes.textField}
                  label="Employee available"
                  id="outlined-size-small"
                  type="number"
                  defaultValue="5"
                  variant="outlined"
                  size="small"
                />
                <TextField
                  className={classes.textField}
                  label="Employee available"
                  id="outlined-size-small"
                  type="number"
                  defaultValue="5"
                  variant="outlined"
                  size="small"
                /> */}
              </Grid>
              <Grid item xs={1}>
                <Typography>
                  <PurpleSwitch

                    checked={this.state.switchVal1}
                    className={classes.switch}
                    onChange={() => {
                      this.setState({ switchVal1: !this.state.switchVal1 });
                      var data={};
                      data.enabled=this.state.switchVal1?0:1;
                      Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[0].assignRulesId}`, data
                      ).then(response=>{
                          // console.log("success toggel");
                      });
                    }}
                  />
                </Typography>
                <Typography>
                  <PurpleSwitch
                    checked={this.state.switchVal2}
                    className={classes.switch}
                    onChange={() => {
                      this.setState({ switchVal2: !this.state.switchVal2 });
                      var data={};
                      data.enabled=this.state.switchVal2?0:1;
                      Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[1].assignRulesId}`, data
                      ).then(response=>{
                          // console.log("success toggel");
                      });
                    }}
                  />
                </Typography>
                <Typography>
                  <PurpleSwitch
                    checked={this.state.switchVal3}
                    className={classes.switch}
                    onChange={() => {
                      this.setState({ switchVal3: !this.state.switchVal3 });
                      var data={};
                      data.enabled=this.state.switchVal3?0:1;
                      Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[2].assignRulesId}`, data
                      ).then(response=>{
                          // console.log("success toggel");
                      });
                    }}
                  />
                </Typography>
                <Typography>
                  <PurpleSwitch
                    checked={this.state.switchVal4}
                    className={classes.switch}
                    onChange={() => {
                      this.setState({ switchVal4: !this.state.switchVal4 });
                      var data={};
                      data.enabled=this.state.switchVal4?0:1;
                      Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[3].assignRulesId}`, data
                      ).then(response=>{
                          // console.log("success toggel");
                      });
                    }}
                  />
                </Typography>

                  {this.dumy.map((Data, index)=>{
                  if(this.CutOffConditionsData.length>4){
                    return(
                      <div key={index} >   

                <Typography>
                  <PurpleSwitch
                    checked={this.state.switchVal5}
                    className={classes.switch}
                    onChange={() => {
                      this.setState({ switchVal5: !this.state.switchVal5 });
                      var data={};
                      data.enabled=this.state.switchVal5?0:1;
                      Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[4].assignRulesId}`, data
                      ).then(response=>{
                          // console.log("success toggel");
                      });
                    }}
                  />
                </Typography>
                <Typography>
                  <PurpleSwitch
                    checked={this.state.switchVal6}
                    className={classes.switch}
                    onChange={() => {
                      this.setState({ switchVal6: !this.state.switchVal6 });
                      var data={};
                      data.enabled=this.state.switchVal6?0:1;
                      Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[5].assignRulesId}`, data
                      ).then(response=>{
                          // console.log("success toggel");
                      });
                    }}
                  />
                </Typography>
                    <br/>
                        <Typography>
                          <PurpleSwitch
                            checked={this.state.switchVal7}
                            className={classes.switch}
                            onChange={() => {
                              this.setState({ switchVal7: !this.state.switchVal7 });
                              var data={};
                              data.enabled=this.state.switchVal7?0:1;
                              Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[6].assignRulesId}`, data
                              ).then(response=>{
                                  // console.log("success toggel");
                              });
                            }}
                          />
                        </Typography>
                        <br/>
                        
                        {this.dumy.map((Data, index)=>{
                  if(this.CutOffConditionsData.length>7){
                    return(
                      <div key={index} >
                        <Typography>
                          <PurpleSwitch
                            checked={this.state.switchVal8}
                            className={classes.switch}
                            onChange={() => {
                              this.setState({ switchVal8: !this.state.switchVal8 });
                              var data={};
                              data.enabled=this.state.switchVal8?0:1;
                              Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[7].assignRulesId}`, data
                              ).then(response=>{
                                  // console.log("success toggel");
                              });
                            }}
                          />
                        </Typography>
                        <Typography>
                          <PurpleSwitch
                            checked={this.state.switchVal9}
                            className={classes.switch}
                            onChange={() => {
                              this.setState({ switchVal9: !this.state.switchVal9 });
                              var data={};
                              data.enabled=this.state.switchVal9?0:1;
                              Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[8].assignRulesId}`, data
                              ).then(response=>{
                                  // console.log("success toggel");
                              });
                            }}
                          />
                        </Typography>
                         </div>
                         );
                         }
                       })}
                   </div>
                    );
                    }
                  })}

                       

                {/* <Typography>
                  <PurpleSwitch
                    checked={this.state.switchVal10}
                    className={classes.switch}
                    onChange={() => {
                      this.setState({ switchVal10: !this.state.switchVal10 });
                      var data={};
                      data.enabled=this.state.switchVal10?0:1;
                      Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.CutOffConditionsData[7].assignRulesId}`, data
                      ).then(response=>{
                          // console.log("success toggel");
                      });
                    }}
                  />
                </Typography> */}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>

        <Grid>
          <MaterialTable
            title=''
            options={{
              search: false,
              paging: false,
              actionsColumnIndex: 4
            }}
            icons={{
              Add: props => {
                return (
                  <div>
                    {' '}
                    <i className='fa fa-plus'></i>{' '}
                  </div>
                );
              }
            }}
            columns={this.state.columns}
            data={this.state.data}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        if (newData.Weightage > -1 && newData.Value > -1)
                          data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                      
                      // console.log(newData);
                      this.nData=newData;

                      var postData={};
                      postData.subWeightage=this.nData.Value;
                      postData.weightage=this.nData.Weightage;
                      
                      Axios.post(`${urlLinks.baseUrl}/admin/GlobalCuttof/${this.nData.id}`, postData
                      ).then(response=>{
                          // console.log("success");
                          toast.success(`Value Changed SuccessFully`)
                      })
                      .catch(err => {
                        toast.error(
                          `Value can not be in float `
                        );
                    });


                    }
                   
                  }, 600);
                })
            }}
          
          />
        </Grid>
        {/* <Grid className={classes.Expanation}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={classes.heading}>
                {' '}
                Score Calculation{' '}
              </Typography>
              {<ExpandMoreIcon />}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid item xs={12}>
                <MaterialTable
                  title=''
                  options={{
                    search: false,
                    paging: false,
                    actionsColumnIndex: 4
                  }}
                  icons={{
                    Add: props => {
                      return (
                        <div>
                          {' '}
                          <i className='fa fa-plus'></i>{' '}
                        </div>
                      );
                    }
                  }}
                  columns={this.state.calculationColumns}
                  data={this.state.calculationData}
                  editable={{
                    onRowUpdate: (newData, oldData) => {
                      return new Promise(resolve => {
                        setTimeout(() => {
                          resolve();
                          if (oldData) {
                            this.setState(prevState => {
                              const data = [...prevState.calculationData];
                              if (newData.Weightage > -1)
                                data[data.indexOf(oldData)] = newData;
                              return Object.assign({}, prevState, {
                                calculationData: data
                              });
                            });
                          }
                        }, 600);
                      });
                    }
                  }}
                />
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid> */}
      </Container>
      </div>
    );
  }
}
export default withStyles(styles)(CutOffConditions2);
