import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from '../../../utils/DatePicker/DatePicker';
import * as moment from 'moment';
import CustomMultiSelectDropDown from '../../../utils/CustomMultiselectDropDown/CustomMultiSelectDropDown';
const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '700px',
    minWidth: '300px'
  },
  gridItemStyle: {
    padding: '10px !important'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class CustomFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultValue: [
                { vacancy_name: [] },
                {company_name: [] },
                {phone_number: [] },
                {user_name: [] }
                
            ],
            dates: null,
            start: null,
            end: null,
            start_Label: 'Interview Date From',
            end_Label: 'Interview Date To',
            open: false,
            anchorEl: <b></b>,
            filters: []
        

        };
        
        
        
        this.url=''
        this.dates=''
        this.defaultValue = this.state.defaultValue;
        this.getFilterElemnts = this.getFilterElemnts.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        // this.handleChange = this.handleChange.bind(this);
    }

    recieveDates = (startDate, endDate) => {
        const dates = {
            registered_from: startDate,
            registered_to: endDate
        };
        startDate=startDate?moment(startDate).format("YYYY-MM-DD"):"2019-01-01"
        endDate=endDate?`${moment(endDate).format("YYYY-MM-DD")} 23:59:59`:`${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        this.dates=`interviews_from_date=${startDate}&interviews_to_date=${endDate}`
        this.setState({ dates: dates }, ()=>console.log(this.state.dates));
    };

    handleInputChange(name, data) {
        for (let el of this.defaultValue) {
            // console.log({name, data});
            
            if (el[name]) {
                el[name] = data;
            }
        }
        this.setState({
        defaultValue: this.defaultValue
        },() => this.createURL(this.defaultValue));
    }

    handleRequestClose(event) {
        this.setState({
        open: !this.state.open,
        anchorEl: event.currentTarget
        });
    }

    resetFilters() {
        // for (let el of this.defaultValue) {
        //   const key = Object.keys(el)[0];
        //   el[key] = [];
        // }
        this.dates=""
        this.setState(
        {
            defaultValue: [
                { vacancy_name: [] },
                {company_name: [] },
                {phone_number: [] },
                {user_name: [] }
                
            ], 
            dates: null
            
        },
        () =>{
            console.log(this.state)
            this.props.resetFilterURL()}
        );
    }

    createURL =(filterList) =>{
        let url="";
        let filterTemp= this.props.filters;
        filterList.map((filter, index) =>{
            console.log({filter});
            if(filter[Object.getOwnPropertyNames(filter)].length>0)
            {
                let addurl=''
                console.log(filterTemp[index]);
                
                if(filterTemp[index])
                    filterTemp[index]["default"]=filter[Object.getOwnPropertyNames(filter)];
                addurl= `${Object.getOwnPropertyNames(filter)}=${filter[Object.getOwnPropertyNames(filter)]}&`
                console.log({addurl});
                url=`${url}${addurl}`
            }
            else if(filter[Object.getOwnPropertyNames(filter)].length===0)
            {
                
                if(filterTemp[index])
                    filterTemp[index]["default"]=[];
                
            }
            return null;
        })
        this.fil=filterTemp;
        this.url=url;
        console.log({url, filterTemp});
        

    }
    getFilterElemnts(classes) {

            const filters = this.props.filters.map((element, index) => {
            console.log({element});
            
            return (
                <Grid item xs={6} key={element.id} className={classes.gridItemStyle}>
                <CustomMultiSelectDropDown
                    default={this.state.defaultValue[index][element.name]}
                    updatedDefault={element.default}
                    data={element[element.name]}
                    name={element.name}
                    label={element.label}
                    updateChange={this.handleInputChange}
                ></CustomMultiSelectDropDown>
                </Grid>
            );
            });
            return filters;
        }
    

    componentDidMount() {
       
        console.log(this.props.filters);
        
        this.setState({
        defaultValue:[
                { vacancy_name: [] },
                {company_name: [] },
                {phone_number: [] },
                {user_name: [] }
            ],
        dates: null
        });
    }
    



    render() {
       
        console.log(this.props.filter);
        

        if (this.state.dates) {
        // console.log(this.state.dates.registered_from.substring(0, 10));
        // console.log(new Date(this.state.dates.registered_from.substring(0, 10)));
        }
        return (
        <PopupState variant='popover' popupId={'popover' + this.props.id}>
            {popupState => (
            <div>
                <Button
                variant='contained'
                color='primary'
                {...bindTrigger(popupState)}
                className={this.props.classes.buttonTextSize}
                onClick={this.handleRequestClose}
                >
                Filter
                </Button>
                <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    id: 2,
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                >
                <Grid
                    container
                    spacing={3}
                    className={this.props.classes.gridStyle}
                >
                    {this.getFilterElemnts(this.props.classes)}
                    
                    <Grid item xs={4}>
                        {' '}
                        <DatePicker
                            sendData={this.recieveDates}
                            start={
                            this.state.dates
                                ? this.state.dates.registered_from
                                ? new Date(
                                    this.state.dates.registered_from.substring(0, 10)
                                    )
                                : null
                                : null
                            }
                            end={
                            this.state.dates
                                ? this.state.dates.registered_to
                                ? new Date(
                                    this.state.dates.registered_to.substring(0, 10)
                                    )
                                : null
                                : null
                            }
                            label={this.state.start_Label}
                            label1={this.state.end_Label}
                        ></DatePicker>{' '}
                    </Grid>
                </Grid>

                <Divider />
                <Grid container className={this.props.classes.gridStyle}>
                    <Grid item xs={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={event => {
                        this.resetFilters(true);
                        // this.handleRequestClose(event);
                        }}
                        className={this.props.classes.buttonTextSize}
                    >
                        Reset
                    </Button>
                    </Grid>
                    <Grid item xs={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={event => {
                        // this.resetFilters(false);
                        this.props.updateFilters(this.url, this.fil, this.dates);
                        this.handleRequestClose(event);
                        }}
                        className={this.props.classes.buttonTextSize}
                    >
                        Apply
                    </Button>
                    </Grid>
                    <Grid item xs={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={event => {
                        this.handleRequestClose(event);
                        }}
                        className={this.props.classes.buttonTextSize}
                    >
                        Cancel
                    </Button>
                    </Grid>
                </Grid>
                </Popover>
            </div>
            )}
        </PopupState>
        );
    }
}

export default withStyles(styles)(CustomFilter);
