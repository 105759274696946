import React, { Component } from 'react';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { withStyles } from '@material-ui/core/styles';
import utils from '../../../utilMethods';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import { FormControlLabel, Checkbox } from '@material-ui/core';
const styles = theme => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  text_container: {
    width: '80%',
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  input: {
    padding: '10px',
    height: '40px',
    fontSize: '0.8rem'
  },
  checkBox: {
    marginLeft: '10px'
  },
  inputLabel: {
    transform: 'translate(13px, 12px) scale(1)',
    fontSize: '0.8rem'
  }
});

class JobCategory extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      selectedStartDate: this.props.daysToSelect.startDate || new Date(),
      selectedEndDate: this.props.daysToSelect.endDate || new Date(),
      selectedStartTime: utils.roundTimeQuarterHour(this.props.daysToSelect.startTime) || new Date(),
      selectedEndTime: utils.roundTimeQuarterHour(this.props.daysToSelect.endTime) || new Date(),
      jobDaysSchedule: [],
      daycount:1
    };

    this.weekDays = [
      {
        id: '1',
        day: '1'
      },
      {
        id: '2',
        day: '2'
      },
      {
        id: '3',
        day: '3'
      },
      {
        id: '4',
        day: '4'
      },
      {
        id: '5',
        day: '5'
      },
      {
        id: '6',
        day: '6'
      },
      {
        id: '7',
        day: '7'
      }
    ];
  }
  componentDidMount(){
    // console.log(this.props.schedule);
    
    if (this.props.data) {
      let category={};
      let startDate= new Date();
      let endDate= null;
      let startTime= new Date();
      let endTime= new Date();
      let schedule=[];
      if(this.props.value=== 'onDemand'){
        startDate= this.props.daysToSelect? this.props.daysToSelect.startDate :new Date();
        startTime= this.props.daysToSelect? utils.roundTimeQuarterHour(this.props.daysToSelect.startTime) :new Date();
        endTime= this.props.daysToSelect? utils.roundTimeQuarterHour(this.props.daysToSelect.endTime) :new Date();
      }
      if(this.props.value=== 'partTime'){
        startDate= this.props.daysToSelect.startDate;
        endDate= this.props.daysToSelect.endDate;
        startTime= utils.roundTimeQuarterHour(this.props.daysToSelect.startTime);
        endTime= utils.roundTimeQuarterHour(this.props.daysToSelect.endTime);
      }
      if(this.props.value=== 'fullTime'){
        startDate= this.props.daysToSelect.startDate;
        startTime= utils.roundTimeQuarterHour(this.props.daysToSelect.startTime);
        endTime= utils.roundTimeQuarterHour(this.props.daysToSelect.endTime);
      }
      
      this.setState({
        selectedStartDate: startDate,
        selectedEndDate: endDate,
        selectedStartTime: utils.roundTimeQuarterHour(startTime),
        selectedEndTime: utils.roundTimeQuarterHour(endTime),
        jobDaysSchedule:this.props.schedule?this.props.schedule: [],
        daycount: this.props.dayCount?this.props.dayCount: 1
      })
      // if(this.props.value=== 'onDemand'){
      //   category=
      //   {
      //     OD: {
      //       startDate: this.state.selectedStartDate,
      //       startTime: this.state.selectedStartTime,
      //       endTime: this.state.selectedEndTime
      //     },
      //     PT: {
      //       startDate: null,
      //       endDate: null,
      //       startTime:null,
      //       endTime: null
      //     },
      //     FT: {
      //       startDate: null,
      //       startTime: null,
      //       endTime: null
      //     }
      //   }

      // }
      // if(this.props.value=== 'partTime'){
      //   category=
      //   {
      //     OD: {
      //       startDate: null,
      //       startTime:null,
      //       endTime: null
      //     },
      //     PT: {startDate: this.state.selectedStartDate,
      //       startTime: this.state.selectedStartTime,
      //       endDate: this.state.selectedEndDate,
      //       endTime: this.state.selectedEndTime
            
      //     },
      //     FT: {
      //       startDate: null,
      //       startTime: null,
      //       endTime: null
      //     }
      //   }
      // }
      // if(this.props.value=== 'fullTime'){
      //   category=
      //   {
      //     OD: {
      //       startDate: null,
      //       startTime: null,
      //       endTime: null 
      //     },
      //     PT: {
      //       startDate: null,
      //       endDate: null,
      //       startTime:null,
      //       endTime: null
      //     },
      //     FT: {
      //       startDate: this.state.selectedStartDate,
      //       startTime: this.state.selectedStartTime,
      //       endTime: this.state.selectedEndTime
            
      //     }
      //   }
      // }
      
      // console.log(category)
    //this.props.setCategoryValues(category);
    this.props.setDaysValues(this.props.schedule);
    this.props.setDayValue(this.props.dayCount);


      
       

    
      }
  
  }



  handleStartDateChange = date => {
    this.setState({ selectedStartDate: date });
  };
  handleEndDateChange = date => {
    this.setState({ selectedEndDate: date });
  };
  handleStartTimeChange = date => {
    this.setState({ selectedStartTime: date });
  };
  handleEndTimeChange = date => {
    this.setState({ selectedEndTime: date });
  };
  
  render() {
    // console.log(this.props.data);
    // console.log(this.formikValues);
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Formik
          initialValues={{
            day: '1',
            days: [],
            category:
              {
                OD: {
                  startDate: this.props.daysToSelect?this.props.daysToSelect.startDate:null,
                  startTime: this.props.daysToSelect?this.props.daysToSelect.startTime:null,
                  endTime: this.props.daysToSelect?this.props.daysToSelect.endTime:null
                },
                PT: {
                  startDate: this.props.daysToSelect?this.props.daysToSelect.startDate:null,
                  endDate: this.props.daysToSelect?this.props.daysToSelect.endDate:null,
                  startTime:this.props.daysToSelect?this.props.daysToSelect.startTime:null,
                  endTime: this.props.daysToSelect?this.props.daysToSelect.endTime:null
                },
                FT: {
                  startDate: this.props.daysToSelect?this.props.daysToSelect.startDate:null,
                  startTime: this.props.daysToSelect?this.props.daysToSelect.startTime:null,
                  endTime: this.props.daysToSelect?this.props.daysToSelect.endTime:null
                }
              }
          }}
          validationSchema={this.addWorkerSchema}
          onSubmit={values => {
            this.handleSubmitValues(values);
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              setValues
            } = props;
            this.formikValues = values;
            this.formikSetValues = setValues;
            const setDayValue = day => {
              if (day) {
                this.setState({daycount: day});
                
                
                this.props.setDayValue(day);
                // console.log(day);
              }
            };
            const handleDaysChange = day => {
              let days=this.state.jobDaysSchedule;
              if (days.includes(day)) {
                var index = days.indexOf(day);
                if (index !== -1) days.splice(index, 1);
              } else {
                days.push(day);
              }
              this.setState({jobDaysSchedule: days})
              this.props.setDaysValues(days);
            };

            const handleSelectTimings = (type, value, dateTime) => {
              values.category[type][value] = dateTime;
              // console.log(values.category)
              if(value==='startDate'){
                this.setState({selectedStartDate: dateTime}) ;
              }
              if(value==='endDate'){
                this.setState({selectedEndDate: dateTime}) ;
              }
              if(value==='startTime'){
                this.setState({selectedStartTime: utils.roundTimeQuarterHour(dateTime)}) ;
              }
              if(value==='endTime'){
                this.setState({selectedEndTime: utils.roundTimeQuarterHour(dateTime)}) ;
              }
              this.props.setCategoryValues(values.category);
            };

            // const handleSelectTimings = (type, value, dateTime) => {
            //   console.log(type);
            //   console.log(value);
            //   console.log(dateTime);
              
            //   let category={};
            //     if(value==='startDate'){
            //       this.setState({selectedStartDate: dateTime}) ;
            //     }
            //     if(value==='endDate'){
            //       this.setState({selectedEndDate: dateTime}) ;
            //     }
            //     if(value==='startTime'){
            //       this.setState({selectedStartTime: dateTime}) ;
            //     }
            //     if(value==='endTime'){
            //       this.setState({selectedEndTime: dateTime}) ;
            //     }
            //     if(type==='OD'){
            //       category={
            //           startDate: this.state.selectedStartDate,
            //           startTime: this.state.selectedStartTime,
            //           endTime: this.state.selectedEndTime
            //     }
            //     }
            //     if(type==='PT'){
            //       category={
            //        startDate: this.state.selectedStartDate,
            //           startTime: this.state.selectedStartTime,
            //           endDate: this.state.selectedEndDate,
            //           endTime: this.state.selectedEndTime
            //     }     
            //     }
            //     if(type==='FT'){
            //       category={
                  
            //           startDate: this.state.selectedStartDate,
            //           startTime: this.state.selectedStartTime,
            //           endTime: this.state.selectedEndTime
            //     }
            //     }
            //     console.log(category)
            //   this.props.setCategoryValues(category);
            // };

            return (
              <React.Fragment>
                {this.props.value === 'onDemand' ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        margin='normal'
                        id='date-picker-dialog'
                        // minDate={new Date()}
                        label='Job Start Date'
                        format='dd/MM/yyyy'
                        value={this.state.selectedStartDate}
                        onChange={date =>
                          handleSelectTimings('OD', 'startDate', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                      <KeyboardTimePicker
                        margin='normal'
                        id='time-picker'
                        label='Job Start Time'
                        value={this.state.selectedStartTime}
                        onChange={date =>
                          handleSelectTimings('OD', 'startTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </Grid>
                    <Grid container justify='space-around'>
                      <TextField
                        style={{
                          width: '250px',
                          marginTop: '25px'
                        }}
                        className={classes.text_container}
                        variant='outlined'
                        select
                        error={errors.day && touched.day}
                        label='Select Days'
                        name='day'
                        value={this.state.daycount}
                        onChange={event => {
                          // console.log(event.target.value);
                          
                          setDayValue(event.target.value)}}
                        helperText={errors.day && touched.day && errors.day}
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      >
                        {this.weekDays.map(option => (
                          <MenuItem key={option.id} value={option.day}>
                            {option.day}
                          </MenuItem>
                        ))}
                      </TextField>

                      <KeyboardTimePicker
                        margin='normal'
                        id='time-picker2'
                        label='Job End Time'
                        value={this.state.selectedEndTime}
                        onChange={date =>
                          handleSelectTimings('OD', 'endTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : (
                  <Grid></Grid>
                )}
                {this.props.value === 'partTime'
                 ? (
                  <div>
                    <Grid style={{ textAlign: 'center' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value='Monday'
                            onClick={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={this.state.jobDaysSchedule.includes('Monday')}
                          />
                        }
                        label='M'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value='Tuesday'
                            className={classes.checkBox}
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={this.state.jobDaysSchedule.includes('Tuesday')}
                          />
                        }
                        label='T'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Wednesday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={this.state.jobDaysSchedule.includes('Wednesday')}
                          />
                        }
                        label='W'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Thursday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={this.state.jobDaysSchedule.includes('Thursday')}
                          />
                        }
                        label='T'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Friday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={this.state.jobDaysSchedule.includes('Friday')}
                          />
                        }
                        label='F'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Saturday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={this.state.jobDaysSchedule.includes('Saturday')}
                          />
                        }
                        label='S'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Sunday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={this.state.jobDaysSchedule.includes('Sunday')}
                          />
                        }
                        label='S'
                      />
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify='space-around'>
                        <KeyboardDatePicker
                          margin='normal'
                          // minDate={new Date()}
                          style={{ width: '200px' }}
                          id='date-picker-dialog'
                          label='Job Start Date'
                          format='dd/MM/yyyy'
                          value={this.state.selectedStartDate}
                          onChange={date =>
                            handleSelectTimings('PT', 'startDate', date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                        <KeyboardDatePicker
                          margin='normal'
                          // minDate={this.state.selectedStartDate || new Date()}
                          style={{ width: '200px', marginLeft: '50px' }}
                          id='date-picker-dialog2'
                          label='Job End Date'
                          format='dd/MM/yyyy'
                          value={this.state.selectedEndDate}
                          onChange={date =>
                            handleSelectTimings('PT', 'endDate', date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </Grid>
                      <Grid container justify='space-around'>
                        <KeyboardTimePicker
                          style={{ width: '200px' }}
                          margin='normal'
                          id='time-picker'
                          label='Job Start Time'
                          value={this.state.selectedStartTime}
                          onChange={date =>
                            handleSelectTimings('PT', 'startTime', date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change time'
                          }}
                        />
                        <KeyboardTimePicker
                          style={{ width: '200px', marginLeft: '50px' }}
                          margin='normal'
                          id='time-picker2'
                          label='Job End Time'
                          value={this.state.selectedEndTime}
                          onChange={date =>
                            handleSelectTimings('PT', 'endTime', date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change time'
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                ) : (
                  <Grid></Grid>
                )}
                {this.props.value === 'fullTime' ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        margin='normal'
                        // minDate={new Date()}
                        id='date-picker-dialog'
                        label='Job Start Date'
                        format='dd/MM/yyyy'
                        value={this.state.selectedStartDate}
                        onChange={date =>
                          handleSelectTimings('FT', 'startDate', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />

                      <KeyboardTimePicker
                        style={{ width: '200px' }}
                        margin='normal'
                        id='time-picker'
                        label='Job Start Time'
                        value={this.state.selectedStartTime}
                        onChange={date =>
                          handleSelectTimings('FT', 'startTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                      <KeyboardTimePicker
                        style={{ width: '200px' }}
                        margin='normal'
                        id='time-picker2'
                        label='Job End Time'
                        value={this.state.selectedEndTime}
                        onChange={date =>
                          handleSelectTimings('FT', 'endTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : (
                  <Grid></Grid>
                )}
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    );
  }
}
export default withStyles(styles)(JobCategory);
