import React, { useState, useEffect } from 'react'
import {Grid, Button} from '@material-ui/core'
import {experience, ageBracket, weekTodays, setqualification, setcuisine, setgender} from "../Job/JobList/newJobViewComponents/jobRequirement"
import Axios from 'axios'
import * as moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import urlLinks from '../../urlLinks'
import SelectVacancyPage from '../RecuiterDashboard/SelectVacancyPage'
import JobModal from '../../utils/Modals/jobModal'
import AssignToRecruiterModal from '../../utils/Modals/assignToRecruiter'

const AssignVacancyToRecruiter =() =>{
    const [jobList, setJobList] = useState([])
    const [jobValue, setJobValue] = useState()
    const [jobDetails, setJobDetails] = useState()
    const [openJobModal, setOpenJobModal] = useState(false)
    const [openAssignRecruiterModal, setOpenAssignRecruiterModal] = useState(false)
    
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    const convertJobIdtoHex=(val) =>{
        var s = "abcde" + val.toString(36);
        // console.log("called");
        return ""+s.substring(s.length - 5).toUpperCase();
    }
    const makeJobData =(data) =>{
        let exp="None";
        let gender="None";
        let qualification="None";
        let cuisine="None";
        console.log({data});
        
        if(data.detailsJobs && data.detailsJobs[0] && data.detailsJobs[0].detailSkills.length>0){
        data.detailsJobs[0].detailSkills.map(skill =>{
            if(skill.skillsTypeId>0 && skill.skillsTypeId<8 )
            qualification=skill.skillsTypeId;
            else if(skill.skillsTypeId===26 || skill.skillsTypeId===27 )
            gender=skill.skillsTypeId;
            else if(skill.skillsTypeId>39 && skill.skillsTypeId<44 )
            exp=skill.skillsTypeId;
            else if((skill.skillsTypeId>31 && skill.skillsTypeId<36)|| skill.skillsTypeId===115)
            cuisine=skill.skillsTypeId;
            else{
            }
        })
        }
        const startTime=data.loginTime?moment(data.loginTime, "hh:mm a").format("hh:mm A"):null;
        const endTime=data.logoutTime?moment(data.logoutTime, "hh:mm a").format("hh:mm A"):null;
        const startDate= data.startDate? data.startDate:null;
        let perkArray=[]
        if(data.company.is_2meal)
            perkArray.push("Meals provided")
        if(data.company.is_conveyance)
            perkArray.push("Conveyance provided")
        if(data.company.is_pf_esic)
            perkArray.push("Pf/Esic provided")
        if(data.company.is_rooms)
            perkArray.push("Accommodation provided")

        const jobData={
            brandName: data.brandName?data.brandName:data.companyName,
            companyDetails: data.company.shortDescription,
            perks: perkArray,
            encryptId: data.detailsJobs[0].jobDetailsId?convertJobIdtoHex(data.detailsJobs[0].jobDetailsId):'-',
            jobTitle: data.detailsJobs[0].jobTitle?data.detailsJobs[0].jobTitle:'-',
            workType:data.workType,
            vacancyType: data.detailsJobs[0].vacancyType==="entry"?
                "Entry level"
                :data.detailsJobs[0].vacancyType==="mid"?
                    "Mid level"
                    :data.detailsJobs[0].vacancyType==="senior"?
                        "Senior level"
                        :'N/a',
            payrollType: data.detailsJobs[0].payrollType==="company"?
                "Company"
                :data.detailsJobs[0].payrollType==="third"?
                    "Third Party"
                    :data.detailsJobs[0].payrollType==="contract"?
                        "Contract":'N/a',
            noOfOpenings: data.detailsJobs[0].employeeRequired?data.detailsJobs[0].employeeRequired:'-',
            city: data.city_name?`${data.city_name}${data.state_name?", " :''}${data.state_name?data.state_name:''}`:'-',
            ExactJobAddress: data.address?data.address:'-',
            lat:data.jobLocationLat?data.jobLocationLat:null,
            long:data.jobLocationLong?data.jobLocationLong:null,
            salary: data.workType===3?`${numberFormat(data.detailsJobs[0].amount)}`:(data.detailsJobs[0].minSalary===data.detailsJobs[0].maxSalary)?`${numberFormat(data.detailsJobs[0].minSalary)}`:`${numberFormat(data.detailsJobs[0].minSalary)} - ${numberFormat(data.detailsJobs[0].maxSalary)}`,
            salaryType: data.detailsJobs[0].amountPer,
            jobDescription: data.detailsJobs[0].additionalRequirement?data.detailsJobs[0].additionalRequirement:'-',
            noOfDays: data.workdaysCount?data.workdaysCount:'-',
            noOfHrs: data.detailsJobs[0].noOfHrs?data.detailsJobs[0].noOfHrs:'-',
            weekdays: data.workdaysDetails?weekTodays(JSON.parse(data.workdaysDetails)): [],
            startTime: startTime,
            endTime: endTime,
            startDate: startDate,
            jobRole:data.detailsJobs[0].jobTypeId,
            exp: experience(exp),
            gender: setgender(gender),
            qualification:  setqualification(qualification),
            cuisine: setcuisine(data.detailsJobs[0].cuisine===null? cuisine:data.detailsJobs[0].cuisine),
            specialization: data.detailsJobs[0].qualificationSpecification?data.detailsJobs[0].qualificationSpecification: "N/a",
            engFluency: data.detailsJobs[0].englishFluency===1?
                "No English"
                :data.detailsJobs[0].englishFluency===2?
                    "Thoda English"
                    :data.detailsJobs[0].englishFluency===3?
                        "Good English":"-",
            ageBracket: ageBracket(data.detailsJobs[0].minAge, data.detailsJobs[0].maxAge),
            cvRequired: data.detailsJobs[0].cvRequired===0?
                "Not Required"
                :data.detailsJobs[0].cvRequired===1?
                    "Optional"
                    :data.detailsJobs[0].cvRequired===2?
                        "Mandatory":"-",
            interviewAddress: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewAddress?
                    data.detailsJobs[0].jobInterviewDetails.interviewAddress:'-'
                :'-' ,
            interviewProcess: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewProcess?
                    data.detailsJobs[0].jobInterviewDetails.interviewProcess:'-'
                :'-',
            lat:data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewLat?
                    data.detailsJobs[0].jobInterviewDetails.interviewLat:null
                :null,
            long:data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.interviewLong?
                    data.detailsJobs[0].jobInterviewDetails.interviewLong:null
                :null,
            // interviewSlots:  interviewArraytoSlots(JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays)),
            interviewSlots:  data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays? 
                    JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays):[]
                :[],
            interviewDesc: data.detailsJobs[0].jobInterviewDetails?
                data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement?
                    data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement:'-'
                :'-',
            pocName:data.contactCandidateName?data.contactCandidateName:'-' ,
            recruiterPayoutAmount:data.detailsJobs[0].jobPayout?
                data.detailsJobs[0].jobPayout.remoteRecPayoutAmount?
                    data.detailsJobs[0].jobPayout.remoteRecPayoutAmount:0:0,
            instructionsCandidate: data.detailsJobs[0].instructionsCandidate?data.detailsJobs[0].instructionsCandidate:'',
            instructionsRecruiter:data.detailsJobs[0].instructionsCandidate?data.detailsJobs[0].instructionsCandidate:'', 
            recruiterPitch: data.detailsJobs[0].instructionsCandidate?data.detailsJobs[0].instructionsCandidate:'',
            recruiterPayoutDetails: data.detailsJobs[0].jobPayout?
                data.detailsJobs[0].jobPayout.remoteRecPayout_details?
                    data.detailsJobs[0].jobPayout.remoteRecPayout_details:'':'',

        }
        return jobData;
    
    }
    const makeJobList =(data) =>{
        let list =[];
        data.map(el =>{
            list.unshift({
                jobId: el.jobId,
                encryptId: el.jobDetailId? convertJobIdtoHex(el.jobDetailId):'',
                jobDetailId: el.jobDetailId,
                brandName:el.brandName? el.brandName: el.company?el.company:'',
                jobTitle: el.jobTitle?el.jobTitle: "",
                city: el.city_name?el.state_name==="Delhi"?"Delhi":el.city_name:''
            })
        })
        return list;
    }
    const getJobDetails =() =>{
        if(jobValue){
            Axios.get(`${urlLinks.baseUrl}job/V2/getdetailedjob?job_id=${jobValue.jobId}`)
                .then(response =>{
                    if(response){
                        setJobDetails(makeJobData(response.data.response.content[0]))
                    }
                })
        }
    }
    const showToast =(type, message) =>{
        if(type==="success")    
            toast.success(message);
        else if (type==="error")
            toast.error(message);
    }
    
    useEffect(() => {
        getJobDetails();
    }, [jobValue])
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}extWorker/getAllJobDetails`)
            .then(response =>{
                setJobList(makeJobList(response.data.response))
            })
    }, [])
    console.log({jobValue});
    
    return (
        <Grid container style={{padding: "40px"}}>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                autoClose={2000}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                >
            </ToastContainer>
            <Grid item xs={12} className="padding0">
                <span className= "fontSize24 fontWeightBold">Assign Vacancy to Recruiter</span>
            </Grid>
            <Grid item xs={12} className="padding0" style={{margin: "30px 0"}}>
                <SelectVacancyPage
                    jobList={jobList} 
                    jobValue = {jobValue} 
                    setJobValue ={setJobValue} 
                    setOpenJobModal ={setOpenJobModal}
                />
            </Grid>
            <Grid item xs={12} className="padding0">
                <Button
                    variant="contained"
                    className="dataButton"
                    onClick={() => setOpenAssignRecruiterModal(true)}
                >
                    Assign to recruiter
                </Button>
            </Grid>
            {openJobModal && <JobModal
                openJobModal={openJobModal}
                setOpenJobModal={setOpenJobModal}
                values={jobDetails}
            />}
            {openAssignRecruiterModal?<AssignToRecruiterModal
                openAssignRecruiterModal={openAssignRecruiterModal}
                setOpenAssignRecruiterModal={setOpenAssignRecruiterModal}
                toVacancy={true}
                showToast={showToast}
                currentJobId={jobValue.jobId}
            />:null}

        </Grid>
    )
}

export default AssignVacancyToRecruiter