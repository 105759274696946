import React from 'react';
import { MdModeEdit } from "react-icons/md";
// import { MdComment,MdModeEdit,MdLibraryAdd} from "react-icons/md";
// import { FaEye} from "react-icons/fa";

const InActiveEmployerUtils={
    title:"Inactive Employer",
    inActiveEmployerListAction:
        [{
            id:1,
            name:"UpdateStatus",
            subIcon:<MdModeEdit className="fitIcon"/>,
            route:"/employer/inactive"
        }
    ],
 inactiveEmployerFilterList:[
     {industry:["Restaurant","Health","Mechanical"],id:"1",name:"industry",label:"Industry",default:[]},

 ]

    
}

export default InActiveEmployerUtils