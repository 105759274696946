import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import CustomMultiSelectDropDown from '../CustomMultiselectDropDown/CustomMultiSelectDropDown';
import DatePicker from '../DatePicker/DatePicker';
import Select from '../simpleSelect/simpleSelect';
const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '700px',
    minWidth: '300px'
  },
  gridItemStyle: {
    padding: '10px !important'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class CustomFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultValue: [
        { industry: [] },
        { location: [] },
        { employer_status: [] },
        {company_name: [] },
        { addedBy: [] }
      ],
      dates: null,
      start: null,
      end: null,
      start_Label: 'Reg-Date-From',
      end_Label: 'Reg-Date-To',
      open: this.props.isOpen,
      anchorEl: <b></b>,
      rating: ''
    };

    this.option = [
      {
        id: 1,
        rating: 1,
        label: '>1'
      },
      {
        id: 2,
        rating: 2,
        label: '>2'
      },
      {
        id: 3,
        rating: 3,
        label: '>3'
      },
      {
        id: 4,
        rating: 4,
        label: '>4'
      },
      {
        id: 5,
        rating: 5,
        label: '>5'
      }
    ];

    this.label = 'Rating';

    this.defaultValue = this.state.defaultValue;
    this.getFilterElemnts = this.getFilterElemnts.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  handleSelectChange = val => {
    this.setState({ rating: val });
  };

  recieveDates = (startDate, endDate) => {
    // console.log(startDate);
    // console.log(endDate);
    const dates = {
      registered_from: startDate,
      registered_to: endDate
    };
    this.setState({ dates: dates });
  };

  handleInputChange(name, data) {
    for (let el of this.defaultValue) {
      if (el[name]) {
        el[name] = data;
      }
    }
    this.setState({
      defaultValue: this.defaultValue
    });
  }

  handleRequestClose(event) {
    this.setState({
      open: !this.state.open,
      anchorEl: event.currentTarget
    });
  }

  resetFilters() {
    // for (let el of this.defaultValue) {
    //   const key = Object.keys(el)[0];
    //   el[key] = [];
    // }
    this.setState(
      {
        defaultValue: [
          { industry: [] },
          { location: [] },
          { employer_status: [] },
          { company_name: [] },
          { addedBy: [] }
        ],
        dates: null,
        start: null,
        end: null,
        rating: ''
      },
      () =>
        this.props.updateFilters(
          this.state.defaultValue,
          this.state.dates,
          this.state.rating
        )
    );
  }

  getFilterElemnts(classes) {
    
    const filters = this.props.filters.map((element, index) => {
      
      return (
        <Grid item xs={6} key={element.id} className={classes.gridItemStyle}>
          <CustomMultiSelectDropDown
            default={this.state.defaultValue[index][element.name]}
            updatedDefault={element.default}
            data={element[element.name]}
            name={element.name}
            label={element.label}
            updateChange={this.handleInputChange}
          ></CustomMultiSelectDropDown>
        </Grid>
      );
    });
    return filters;
  }

  componentDidMount() {
    this.setState({
      defaultValue: [
        { industry: [] },
        { location: [] },
        { employer_status: [] },
        { company_name: [] },
        { addedBy: [] }
      ],
      dates: null,
      start: null,
      end: null,
    });
  }

  render() {
    if (this.state.dates) {
      // console.log(this.state.dates.registered_from.substring(0, 10));
      // console.log(new Date(this.state.dates.registered_from.substring(0, 10)));
    }
    return (
      <PopupState variant='popover' popupId={'popover' + this.props.id}>
        {popupState => (
          <div>
            <Button
              variant='contained'
              color='primary'
              {...bindTrigger(popupState)}
              className={this.props.classes.buttonTextSize}
              onClick={this.handleRequestClose}
            >
              Filter
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                id: 2,
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.open}
              anchorEl={this.state.anchorEl}
            >
              <Grid
                container
                spacing={3}
                className={this.props.classes.gridStyle}
              >
                {this.getFilterElemnts(this.props.classes)}

                <Grid item xs={6}>
                  <Select
                    ratingValue={this.state.rating}
                    option={this.option}
                    label={this.label}
                    handleChange={this.handleSelectChange}
                  ></Select>
                </Grid>
                <Grid item xs={4}>
                  {' '}
                  <DatePicker
                    sendData={this.recieveDates}
                    start={
                      this.state.dates
                        ? this.state.dates.registered_from
                          ? new Date(
                              this.state.dates.registered_from.substring(0, 10)
                            )
                          : null
                        : null
                    }
                    end={
                      this.state.dates
                        ? this.state.dates.registered_to
                          ? new Date(
                              this.state.dates.registered_to.substring(0, 10)
                            )
                          : null
                        : null
                    }
                    label={this.state.start_Label}
                    label1={this.state.end_Label}
                  ></DatePicker>{' '}
                </Grid>
              </Grid>

              <Divider />
              <Grid container className={this.props.classes.gridStyle}>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.resetFilters(true);
                      // this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      // this.resetFilters(false);
                      this.props.updateFilters(
                        this.state.defaultValue,
                        this.state.dates,
                        this.state.rating
                      );
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Apply
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>
        )}
      </PopupState>
    );
  }
}

export default withStyles(styles)(CustomFilter);
