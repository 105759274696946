import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Grid,List, Button } from '@material-ui/core/';

const FormSuccess = () => {
  const history= useHistory();

  const location= useLocation();
  console.log({history, location});
  
  return (
    <div className="postJob">
      {(location.data && location.data.code ===1000)?<h1>Form submitted Successfully</h1>:<h1>Form submission failed</h1>}
      <div className="postJobButtons">
          <Button 
            color="primary"
            variant="contained"
            style={{float:"left"}}
            onClick={()=> history.push("/jobs/newAddJobForm")}
            >
            {(location.data && location.data.code ===1000)?<span>Add new job</span>:<span>Retry</span>}
          </Button>
          <Button 
            color="primary"
            variant="contained"
            style={{float:"right"}}
            onClick={()=> history.push("/jobs/jobList")}
            >
            Go to Job list
          </Button>
      </div>
    </div>
  )
}

export default FormSuccess;