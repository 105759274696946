import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton,  Modal, Button, Backdrop, Grid, Slide, TextField} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import {  makeStyles} from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Axios from 'axios';
import './modals.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import * as moment from 'moment';
import urlLinks from '../../../urlLinks';
import WorkerModalTable from './workerModalTable'
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:"flex",
    minWidth:"1000px",
    width:"75%",
    flexDirection:"row",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));
const WorkerModal =(props) =>{
    const [loading, setLoading] = useState(false)
    const {openExternalWorkerModal, setOpenExternalWorkerModal, userId, newTab, recruiterDashBoardData, interviewDashboardData} = props;
    const [workerDetails, setWorkerDetails] = useState({})
    const [preferredLocation, setPreferredLocation] = useState("")
    const [workerAdditionalData, setWorkerAdditionalData] = useState({})
    const [industryData, setIndustryData] = useState("")
    const [interestList, setInterestList] = useState('')
    const classes = useStyles();
    console.log({recruiterDashBoardData})
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);
    }

    const fixSalary= (salary, type) =>{
        let salaryText="0"
        let typeText=""
        if(salary){
            salaryText=salary;
        }
        if(type===0){
            typeText=" / month"
        }
        else if(type===1){
            typeText=" / year"
        }
        return `${salaryText}${typeText}`;
    }
    const fixpreferredDetails= (data)=>{
        let objList=[]
        let flag=false
        if(data)
        {
            const locationArray= data.split(',')
            Axios.get(`${urlLinks.baseUrl}job/getDistinctCity`)
                .then(response => {
                    locationArray.map(loc =>{
                        const obj = response.data.response.find(x=> x.city_id === parseInt(loc))
                        if(!obj)
                            flag=true;
                        else
                            objList.push(obj.district)
                    })
                    console.log({objList})
                    setPreferredLocation(flag ?data: objList.toString())
                });
        }
    }
    const makeSkills =(data) => {
        let skills= []
        data.map(el =>{
            skills.push(el.skillsName)
        })
        return skills.join(", ");
    }
    let vehicledata=[]
    const makeData =(workerdata) =>{
        console.log(workerdata);
        // const lastJob=workerdata.workerExperiences.length>0?workerdata.workerExperiences[workerdata.workerExperiences.length-1]:null;\
        vehicledata=[
            {
                vehicle:"Bike" ,
                own: workerdata.own_bike?workerdata.own_bike:'-',
                license: workerdata.bike_license?workerdata.bike_license:'-',
            },
            {
                vehicle:"Car" ,
                own: workerdata.own_car?workerdata.own_car:'-',
                license: workerdata.car_licence?workerdata.car_licence:'-',
            }
        ]
        
        fixpreferredDetails(workerdata.preferred_location)
        const data = {
            userId:workerdata.userId,
            workerId:workerdata.workerId,
            external_worker_id: workerdata.external_worker_id,
            name: workerdata.user_name,
            age: workerdata.age?`${workerdata.age} yrs`:'N/a',
            gender: workerdata.gender==="26"?"Male":workerdata.gender==="27"?"Female":'N/a',
            contactNumber: workerdata.phoneNumber?workerdata.phoneNumber:"N/a",
            last_activity:workerdata.last_activity? moment(workerdata.last_activity).format("DD/MM/YYYY"): "N/a",
            cvLink:workerdata.cv_link? workerdata.cv_link.replace("http://","https://"):"",
            email: workerdata.emailId?workerdata.emailId:"N/a",
            city: workerdata.newCity ?workerdata.newCity :"N/a",
            locality: workerdata.locality?`${workerdata.locality}, `:'',
            english_known_level: workerdata.english_known_level===3?"Good English":workerdata.english_known_level===2?"Thoda English":workerdata.english_known_level===2?"No English":"N/a",
            qualification: workerdata.qualification?workerdata.qualification:'N/a',
            specialization:workerdata.specialisation?workerdata.specialisation:'N/a',
            year_of_passing: workerdata.year_of_passing?workerdata.year_of_passing:'N/a',
            language: workerdata.languages_known?workerdata.languages_known.replace(/\s+/g, ", "):'N/a',
            own_bike:(workerdata.own_bike==="false" || workerdata.own_bike===false || !workerdata.own_bike||workerdata.own_bike==0)?false:true,
            own_car: (workerdata.own_car==="false" || workerdata.own_car===false || !workerdata.own_car)?false:true,
            car_licence: (workerdata.car_license==="false" || workerdata.car_license===false || !workerdata.car_license)?false:true,
            bike_licence: (workerdata.bike_license==="false" || workerdata.bike_license===false || !workerdata.bike_license||workerdata.bike_license==0)?false:true,
            remarks:workerdata.remark?workerdata.remark:'None',
            industry: workerdata.industry?workerdata.industry:'-',
            skills:workerdata.userSkills?makeSkills(workerdata.userSkills):workerdata.skill?workerdata.skill:'N/a',
            skillsData: workerdata.skill? workerdata.skill.replaceAll(",", ", "):"",
            role: workerdata.role_type?workerdata.role_type:'None',
            experience:workerdata.total_experince_in_month?`${((workerdata.total_experince_in_month/12).toFixed(1))} years`: "Fresher",
            experience_type:workerdata.experience_type?workerdata.experience_type.replace(/\s+/g, ", "):'N/a',
            lastRole: workerdata.role_type?workerdata.role_type:'N/a',
            lastIndustry:workerdata.industry?workerdata.industry:'N/a',
            lastCompany: workerdata.company_name?workerdata.company_name:'N/a',
            lastSalary: workerdata.last_salary?fixSalary(workerdata.last_salary, workerdata.last_salary_type):'N/a',
            notice_period:workerdata.notice_period?`${workerdata.notice_period} days`:"N/a",
        }
        return data;
    }
    const fixIndustry =(data) =>{
        console.log({data})
        if(data.industryId===15)
            setIndustryData(data.otherIndustry)
        else{
            Axios.get(`${urlLinks.baseUrl}/admin/getIndustryMaster`)
                .then(response => {
                    const obj=response.data.response.find(x => x.industry_id ===data.industryId)
                    if(obj){
                        setIndustryData(obj.industry_name)

                    }
                });
        }
    }
    const fixExperience =(exp) =>{
        console.log({exp});
        let numbersArray=[]
        let yrs="";
        let months=""
        if(exp){
            numbersArray= exp.match(/\d+/g);
            if(numbersArray[0]){
                yrs= `${numbersArray[0]} yrs`
            }
            if(numbersArray[1]){
                if(yrs){
                    months= `, ${numbersArray[1]} months`
                }
                else{
                    months= `${numbersArray[1]} months`
                }
            }
            return `${yrs}${months}`
        }
        else{
          return "Fresher";
        }
    }
    const fixInterests =(data) =>{
        if(data.interested_cat_name)
            setInterestList(data.interested_cat_name)
        else{
            let array =data?.interested_roles?.split(',')
            Axios.get(`${urlLinks.baseUrl}configmaster/getIndustryAndJobType`)
                .then(response => {
                    if(array){
                        array.map( interest =>{
                            const obj = response.data.response.find(x=>{return x.id ===parseInt(interest)})
                            if(obj.id !== 1354){
                                objList.push(obj.categorySubType==="industry"?obj.typeDesc : obj.categorySubType)
                            }
                        })
                    }
                    setInterestList(objList.toString())
                });
        }
      let objList=[] 
      return objList;
    }
    const makeUpdatedData =(workerdata) =>{
        let lastRole=""
        let lastIndustry=""
        let lastCompany=""
        let lastIndustryName= ''
        let lastCity= ''
        let designation= ''
        if (workerdata.workerExperiences && workerdata.workerExperiences.length>0){
            const data= workerdata.workerExperiences.pop()   
            lastRole=data.jobTypeName
            lastIndustry=data.industryName
            lastCompany= data.companyName
            designation= data.designation
            lastCity= data.workLocation
            fixIndustry(data)
        }
        fixInterests(workerdata)
        const data = {
            whatsAppNumber: workerdata.whatsappNumber?workerdata.whatsappNumber:'',
            cvLink:workerdata.cv_link? workerdata.cv_link.replace("http://","https://"):"",
            locality: workerdata.addressLine1?`${workerdata.addressLine1}, `:'',
            experience:fixExperience(workerdata.total_experience),
            lastRole: lastRole?lastRole:'N/a',
            lastIndustry:lastIndustry?lastIndustry:'N/a',
            lastCompany: lastCompany?lastCompany:'N/a',
            designation: designation, 
            lastCity: lastCity?lastCity:'N/a',
            lastSalary: workerdata.last_salary?fixSalary(workerdata.last_salary, workerdata.last_salary_type):'N/a',
            laptop: (workerdata.own_laptop==="false" || workerdata.own_laptop===false  || !workerdata.own_laptop)?false:true,
        }
        setWorkerAdditionalData(data)
    }
    useEffect(() => {
        console.log("entered modal")
        if(userId){
            Axios.get(`${urlLinks.baseUrl}/extWorker/getExtWorkerDb?user_id=${userId}`)
                .then(response =>{
                    setWorkerDetails(makeData(response.data.response.content[0]))
                })
        }
            // Axios.get(`${urlLinks.baseUrl}/admin/getIndustryMaster`)
            //   .then(response => {
            //       setIndustryList(makeIndustryData(response.data.response)) ;
            //   });
    }, [])
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}workermaster/V2/workerByUserId?user_id=${userId}`)
                .then(response =>{
                    makeUpdatedData(response.data.response.content[0])
                })
        
    }, [])
    const handleClose = () =>{
        setOpenExternalWorkerModal(false)
    }
    

    return (
        <Modal
            className={classes.modal}
            open={openExternalWorkerModal}
            onClose={handleClose}
            closeAfterTransition
            style={{height:"99%"}}
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            {loading?<CircularProgress style={{margin:"auto"}} />:
            workerDetails && 
                <div className={`${classes.paper} modalContainer`}> 
                    <Grid  xs={4} className="borderRight padding0 ">
                        <Grid item xs={12} className="rowOrder alginItemsCenter borderBottom padding20">
                            <Grid item xs={7} className="columnOrder padding0">
                                <span className="WorkerName">{workerDetails.name}</span>
                                <span className="detailValues">{workerDetails.age} &#9679; {workerDetails.gender} &#9679; {workerDetails.qualification}</span>
                            </Grid>
                            <Grid item xs={5} className=" padding0">
                                <a
                                    style={{float:"right"}}
                                    href={workerAdditionalData.cvLink}
                                    color="transparent"
                                    target="_blank"
                                >
                                    <Button 
                                        className="fitContentWidth marginTopBottom"
                                        variant="contained"
                                        style={{padding: "4px"}}
                                        disabled={!workerAdditionalData.cvLink}
                                        color= "primary"    
                                    >
                                        DOWNLOAD CV
                                    </Button>
                                </a>
                                <Link
                                    to={{
                                        pathname: '/worker/newAddWorker', 
                                        id: userId,
                                        edit: true,
                                        recruiterDashBoardData: recruiterDashBoardData,
                                        interviewDashboardData: interviewDashboardData
                                    }}
                                    target={newTab?"_blank":""}
                                >
                                    <Button
                                        style={{marginTop: "24px"}}
                                        color= "primary"
                                        variant= "outlined"
                                        onClick={() => localStorage.setItem("recruiterEditWorkerId", workerDetails.workerId)}
                                    >
                                        Edit
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="columnOrder padding20">
                            <span className="headings">Contact Details</span>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Contact
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {workerDetails.contactNumber}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Whatsapp number
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {workerAdditionalData.whatsAppNumber}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Email
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {workerDetails.email}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Location
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {`${workerAdditionalData.locality?workerAdditionalData.locality:workerDetails.locality}${workerDetails.city}`}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Preferred cities
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {preferredLocation}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="columnOrder padding20">
                            <span className="headings">Education</span>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Highest level
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {workerDetails.qualification}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Specialization
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {workerDetails.specialization}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Year of passing
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {workerDetails.year_of_passing}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                Language
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {workerDetails.language}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            <Grid xs={4} className="detailValues weight600">
                                English proficiency
                            </Grid>
                            <Grid xs={8} className="detailValues">
                                {workerDetails.english_known_level}
                            </Grid>
                        </Grid>
                        <Grid xs={12} className="columnOrder padding20">
                            <span className="headings">Ownership details</span>
                        </Grid>
                        <Grid xs={12} className="columnOrder padding20">
                            <Grid item xs={12} className="rowOrder padding0">
                                <Grid xs={4} className="detailValues weight600">
                                    Laptop
                                </Grid>
                                <Grid xs={8} className="detailValues">
                                    {workerAdditionalData.laptop?"Available":"Not available"}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="rowOrder padding0">
                                <Grid xs={4} className="detailValues weight600">
                                    Bike
                                </Grid>
                                <Grid xs={8} className="detailValues">
                                    {workerDetails.own_bike?"Available":"Not available"}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="rowOrder padding0">
                                <Grid xs={4} className="detailValues weight600">
                                    Bike license
                                </Grid>
                                <Grid xs={8} className="detailValues">
                                    {workerDetails.bike_licence?"Available":"Not available"}
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid xs={12} className="columnOrder padding20">
                            <span className="headings">Remarks</span>
                        </Grid>
                        <Grid xs={12} className="rowOrder contactDetailsPadding">
                            {workerDetails.remarks}
                        </Grid>
                    </Grid>
                    <Grid  xs={8} style={{padding:"20px"}}>
                        <IconButton 
                            className="closeButton"
                            onClick={handleClose} 
                        >
                            <CloseSharpIcon />
                        </IconButton>
                        <Grid className="workerModalRightPanel">
                            <Grid xs={12} className="columnOrder padding20">
                                <span className="headings">Work</span>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={3} className="detailValues weight600">
                                    Industry
                                </Grid>
                                <Grid xs={9} className="detailValues">
                                    {industryData}
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={3} className="detailValues weight600">
                                    Skills
                                </Grid>
                                <Grid xs={9} className="detailValues">
                                    {workerDetails.skills}
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={3} className="detailValues weight600">
                                    Skills Data
                                </Grid>
                                <Grid xs={9} className="detailValues">
                                    {workerDetails.skillsData}
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={3} className="detailValues weight600">
                                    Interests
                                </Grid>
                                <Grid xs={9} className="detailValues">
                                    {interestList}
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={3} className="detailValues weight600">
                                    Role
                                </Grid>
                                <Grid xs={9} className="detailValues">
                                    {workerAdditionalData.lastRole?workerAdditionalData.lastRole:workerDetails.role}
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={3} className="detailValues weight600">
                                    Total experience
                                </Grid>
                                <Grid xs={9} className="detailValues">
                                    {workerAdditionalData.experience}
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={3} className="detailValues weight600">
                                    Experience type
                                </Grid>
                                <Grid xs={9} className="detailValues">
                                    {workerDetails.experience_type}
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="columnOrder padding20">
                                <span className="headings">Last/Current job</span>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={12} className="detailValues">
                                    {`${workerAdditionalData.lastRole!=="N/a"?workerAdditionalData.lastRole:workerDetails.role} | ${workerAdditionalData.lastIndustry!=="N/a"?workerAdditionalData.lastIndustry:workerDetails.lastIndustry}`}
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={12} className="detailValues">
                                    <span className="detailValues">{workerAdditionalData.designation} &#9679; {workerAdditionalData.lastCompany!=="N/a"?workerAdditionalData.lastCompany:workerDetails.lastCompany} &#9679; {workerAdditionalData.lastCity} &#9679; {workerAdditionalData.lastSalary}</span>
                                </Grid>
                            </Grid>
                            <Grid xs={12} className="rowOrder contactDetailsPadding">
                                <Grid xs={12} className="detailValues">
                                    <span className="detailValues">{`Notice period: ${workerDetails.notice_period}`}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
            </Slide>
        </Modal>
    )
}
export default WorkerModal;