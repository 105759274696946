import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { Typography,Container,Grid,withStyles,Button } from '@material-ui/core';

const styles = () => ({
    root: {
      margin: 'auto',
      flexGrow: 1,
    },
    margin: {
      margin: '2px',
    }
});

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      maxWidth:'712px',
      minWidth:'712px',
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#eeeeee',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
}))(InputBase);

class CustomSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
                       type:this.props.type,
            // questionlist:this.props.questionlist
        };
        this.question=this.props.question.length>0?this.props.question:"Select Question";

       this.questionlist=this.props.questionlist;
    }
    handleChange=(event)=>{
        this.question=event.target.value;
          this.props.handleChange(event)
      }

     
    render() {
        const { classes } = this.props;
        console.log(this.props);
        return (
          <div className={classes.root}>            
              
                    <div className={classes.margin}>
                       <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={this.props.question.length>0?this.question:"Select Question"}
                            onChange={this.handleChange}
                            input={<BootstrapInput />}>
                            <MenuItem value='Select Question' disabled>Select Question</MenuItem>
                            {this.props.questionlist.map(questionlist => (
                            <MenuItem key={questionlist.question_id} value={questionlist}>
                            {questionlist.question}
                            </MenuItem>
                            ))}
                        </Select>
                    </div>
               
          </div>
        );
    }
}
export default withStyles(styles)(CustomSelect);
