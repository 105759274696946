import React, {useState} from 'react'
import './Feedbacks.css'
import {Chip, IconButton , Zoom, Select, MenuItem, TextField, Tooltip, Modal, Button, Backdrop, Slide, Grid} from '@material-ui/core';
import * as moment from 'moment';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import location from '../../../assets/icons8-marker-100 (1).png' 
import phone from '../../../assets/icons8-call-100.png' 
import clock from '../../../assets/icons8-clock-500 (1).png' 
import { withStyles, makeStyles} from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 160,
    padding: 5,
  }
}));
const Feedback=(props) =>{
    const classes = useStyles();
    let interview= props.data;
    const [feedback, setFeedback] = useState(props.data.feedbackStatus===null?'feedback':props.data.feedbackStatus)
    const [tempFeedback, setTempFeedback] = useState("feedback")
    const [showButtons, setShowButtons] = useState(false)
    const [openSelectedStatus, setOpenSelectedStatus] = useState(false)
    const [joiningdate, setJoiningdate] = useState(`${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`)
    const [offerDate, setOfferDate] = useState(`${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`)
    const [salary, setSalary] = useState('')
    // console.log(tempFeedback, props.data.interviewId);
    
        let updateData={}
        const id=props.data.interviewId
    
    const handleConfirm =() =>{
        updateData={feedback, id}
        console.log({updateData});
        
        props.updateStatus(updateData)
        setShowButtons(false);
    }
    
    const handleCancel =() =>{
        setFeedback(tempFeedback);
        setShowButtons(false);
    }
    const handleSelectedStatusClose =() =>{
        setOpenSelectedStatus(false)
    }
    const confirmSelectedStatus = () =>{
        if(!salary)
        {
            alert("EnterSalary");
            return
        }
        updateData={ feedback,id, joiningdate, offerDate, salary}
        props.updateSelectedStatus(updateData)
        // props.updateStatus(updateData)
        setOpenSelectedStatus(false)
    }
    const handleSalary =(event) =>{
        setSalary(event.target.value)
    }

    const handleChange = event => {
       if(event.target.value==='SELECTED')
        {   
            setShowButtons(true)
            setOpenSelectedStatus(true)
            setTempFeedback(feedback)
            setFeedback(event.target.value)
        }
        else
        {
            setShowButtons(true)
            setTempFeedback(feedback)
            setFeedback(event.target.value)
        }
    };
    
    let shortAddress=` ${interview.companyName}, ${interview.companyCity} `
    let chipLabel=interview.jobType==='fullTime'?'Full time':'Part time';
    return (
        <div className={props.cname}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openSelectedStatus}
                onClose={handleSelectedStatusClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Slide direction="up" in={openSelectedStatus} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                    
                    <KeyboardDatePicker
                        margin="normal"
                        id="joining-date"
                        label="Enter Joining Date"
                        format="dd-MM-yyyy"
                        value={joiningdate}
                        onChange={date => setJoiningdate(`${moment(date).format("YYYY-MM-DD")} 00:00:00`)}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    
                    </Grid>
                    <Grid container justify="space-around">
                    
                    <KeyboardDatePicker
                        margin="normal"
                        id="offer-date"
                        label="Enter Offer Date"
                        format="dd-MM-yyyy"
                        value={offerDate}
                        onChange={date => setOfferDate(`${moment(date).format("YYYY-MM-DD HH:mm")}:00`)}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    <TextField 
                        id="salary" 
                        label="Salary"
                        required 
                        onChange={handleSalary}
                    />

                    
                    </Grid>
                </MuiPickersUtilsProvider>
                <Button color="primary"  onClick={confirmSelectedStatus} > Confirm Date</Button>
                <Button color="secondary" onClick={handleSelectedStatusClose} >Cancel</Button>
                </div>
                </Slide>
            </Modal>
            <div className="details">
                <div className="NPT">
                    <span style={{  fontSize: '20px'}}><a href="#" >{interview.name}</a></span> &nbsp; ({interview.candidateNumber})
                    
                </div>  
                <div >
                    <span>{interview.profile}</span> <Chip label={chipLabel} className='chipStyle' />
                </div>
                    
            </div>
            <div className="feedbackInfo">
                <span> {shortAddress} </span> 
                <span>{interview.contactName} - {interview.contactNumber }</span>
            </div>
            <div className="status">
                <div className="interviewDate">
                    <span>Interview date: {interview.interviewDate}</span>
                </div>
                <div>
                    <Select
                        id="interviewStatus"
                        value={feedback}
                        variant='outlined'
                        onChange={handleChange}
                        classes={{root: classes.select}}
                        >
                        <MenuItem value="feedback" disabled>
                            <em>Feedback</em>
                        </MenuItem>
                        <MenuItem value='SELECTED'>Selected</MenuItem>
                        <MenuItem value='REJECTED'>Rejected</MenuItem>
                        <MenuItem value='ROUNDS_PENDING'>Rounds pending</MenuItem>
                        <MenuItem value='RESULT_AWAITED'>Result awaited</MenuItem>
                        <MenuItem value='CANDIDATE_DROPPED'>Candidate dropped</MenuItem>
                        <MenuItem value='NOT_APPEARED'>Not appeared</MenuItem>
                    </Select>
                </div>
                {
                    showButtons &&
                    <div>
                        <IconButton color="primary" onClick={handleConfirm}>
                            <DoneSharpIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={handleCancel} >
                            <CloseSharpIcon />
                        </IconButton>
                    </div>
                }
            </div>
        </div>
    )
}

export default Feedback;