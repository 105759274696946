// React Imports
import React, { Component } from 'react';
import JobCategory from '../AddJob/jobCategoryForEdit';
// Module Imports
import AvailableTime from '../../../utils/AvailableTimeComponent/AvailableTime';
import { Formik, Form, FieldArray } from 'formik';
import qs from 'querystring';
import DropDownConstants from '../../../dropDownConstant';
import utilFunctions from '../../../utilMethods';
import Experience from '../../../utils/Experience/experience';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import BackButton from '../../../utils/BackButton/BackButton';
import { CircleLoader } from 'react-spinners';
import * as Yup from 'yup';
import Axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import urlLinks from '../../../urlLinks';
import {
  TextareaAutosize,
  Select,
  InputLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl
} from '@material-ui/core';
// CSS Imports
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Typography,
  Divider,
  OutlinedInput,
  Radio
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  form: {
    display: 'flex',
    flexFlow: 'column'
  },
  text_container: {
    width: '80%',
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonStyle: {
    width: '90px',
    margin: 'auto',
    fontSize: '0.8rem'
  },
  gap: {
    padding: '10px 5px',
    wordBreak: 'break-word'
  },
  input: {
    padding: '10px',
    height: '40px',
    fontSize: '0.8rem'
  },
  inputStyle: {
    padding: '0px',
    height: '40px',
    fontSize: '0.8rem'
  },
  selectInput: {
    padding: '10px',
    height: '0px',
    fontSize: '0.8rem'
  },
  inputLabel: {
    transform: 'translate(13px, 12px) scale(1)',
    fontSize: '0.8rem'
  },
  grid: {
    maxWidth: '800px',
    margin: 'auto'
  },
  textheading: {
    textDecoration: 'underline',
    fontSize: '0.9rem'
  },
  textContent: {
    fontSize: '0.8rem'
  },
  alignFormat: {
    margin: 'auto',
    fontSize: '0.8rem'
  },
  textBoxStyle: {
    height: '35px',
    marginLeft: '10px'
  }
});

class EditJob extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      dateTime: null,
      detailSkillsForState: [],
      isSubmitting: false,
      // isLoading: true,
      selectedStartDate: '',
      selectedEndDate: '',
      selectedStartTime: '',
      selectedEndTime: '',
      entities: [],
      dataForJobCategory: {},
      dayForJobSchedule: 0,
      daysForJobSchedule: [],
      requirementsContainer: [],
      jobCategory: null,
      jobType: [],
      particularCompanyDetail: '',
      companyDetails: {},
      companies: {},
      labelMaps: [],
      entitylist: {},
      jobTypesList: {},
      jobTypesToCheck: [],
      typesToRender: [],
      labels: [],
      requirements: [],
      allEntities: [],
      userId: '',
      getdetailedJobs: [],
      detailedJobTypes: [],
      googleAddress: '',
      address: {
        addressLine1: '',
        landmark: '',
        city: '',
        state: '',
        pincode: ''
      },
      location: {
        lat: '',
        lng: ''
      }
    };
    this.jobType=[];
    this.addJobSchema = Yup.object().shape({
      companyName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
      contactPerson: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
      radioGroup: Yup.string().required('Select job type'),
      // dateOfBirth: Yup.date()
      //   .required('Required')
      //   .nullable(),
      contactNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Invalid Contact Number')
        .required('Contact Number is Required'),
      // qualification: Yup.string().required('Required'),
      // jobScheduleDays: Yup.string().required('Required'),
      jobType: Yup.string().required('Required'),
      addressLine1: Yup.string()
        .min(2, 'Too Short!')
        .max(150, 'Too Long!')
        .required('Required'),
      city: Yup.string()
        .trim()
        .required('Required'),
      // state: Yup.string()
      //   .trim()
      //   .required('Required'),
      pincode: Yup.string()
        .matches(/^[0-9]{6}$/, 'Invalid Pincode')
        .required('Required'),
      // jobScheduleCategory: Yup.array().of(
      //   Yup.object().shape({
      //     // day: Yup.string().required('Required'),
      //     startTime: Yup.date()
      //       .required('Required')
      //       .nullable(),
      //     endTime: Yup.date()
      //       .required('Required')
      //       .nullable()
      //   })
      // ),
      jobType: Yup.array().of(
        Yup.object().shape({
          jobtype: Yup.string().required('Required'),
          openings: Yup.string()
            .required('Required')
            .nullable(),
          rate: Yup.string()
            .required('Required')
            .nullable()
        })
      )
      // experience: Yup.array().of(
      //   Yup.object().shape({
      //     industry: Yup.string().required('Required'),
      //     company: Yup.string().required('Required'),
      //     jobProfile: Yup.string().required('Required'),
      //     experience: Yup.string().required('Required')
      //   })
      // ),
      // socialMedium: Yup.string().required('Required'),
      // otherSource: Yup.string().when('socialMedium', {
      //   is: socialMedium => socialMedium === 'Other Source',
      //   then: Yup.string().required('Required')
      // })
    });
    this.addressProof = ['Driving Licence', 'Voter ID'];
    this.socialMedium = [
      'Quicker/Job Portal',
      'Leader',
      'Personal Contact',
      'Reference from some worker',
      'Other Source'
    ];
    this.addressProofBlob = null;
    this.industry = ['Restaurant', 'Mechanical'];
    this.jobProfiles = ['Chef', 'Waiter'];
    this.cities = [
      {
        id: 1,
        city: 'Gurugram',
        state: 'Haryana'
      },
      {
        id: 2,
        city: 'Delhi',
        state: 'Delhi'
      },
      {
        id: 3,
        city: 'Noida',
        state: 'UP'
      }
    ];

    this.requirements = [];
    this.requirementsContainer = [];

    this.qualifications = [
      {
        id: '1',
        qualification: '10th Pass'
      },
      {
        id: '2',
        qualification: '12th Pass'
      },
      {
        id: '3',
        qualification: 'UnderGraduate'
      },
      {
        id: '4',
        qualification: 'Graduate'
      }
    ];
    this.weekDays = [
      {
        id: '1',
        day: '1'
      },
      {
        id: '2',
        day: '2'
      },
      {
        id: '3',
        day: '3'
      },
      {
        id: '4',
        day: '4'
      },
      {
        id: '5',
        day: '5'
      },
      {
        id: '6',
        day: '6'
      },
      {
        id: '7',
        day: '7'
      }
    ];
    this.jobTypes = [
      {
        id: '1',
        type: 'Chef'
      },
      {
        id: '2',
        type: 'Bartender'
      },
      {
        id: '3',
        type: 'Receptionist'
      },
      {
        id: '4',
        type: 'other'
      }
    ];
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.formikSetValues = null;
    this.formikValues = {};
  }
  handleStartDateChange = date => {
    this.setState({ selectedStartDate: date });
  };
  handleEndDateChange = date => {
    this.setState({ selectedEndDate: date });
  };
  handleStartTimeChange = date => {
    this.setState({ selectedStartTime: date });
  };
  handleEndTimeChange = date => {
    this.setState({ selectedEndTime: date });
  };
  handlejobCategory = event => {
    this.setState({ jobCategory: event.target.value });
  };

  jobCategoryValues = values => {
    // console.log(values)
  };

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    const geometry = addressObject.geometry.location;
    // this.setState({
    //   location: {
    //     lat: geometry.lat(),
    //     lng: geometry.lng()
    //   }
    // });
    this.lat = geometry.lat();
    this.lang = geometry.lng();
    if (utilFunctions.checkValidLatLng(this.lat, this.lang)) {
      this.setState({
        location: {
          lat: geometry.lat(),
          lng: geometry.lng()
        }
      });
      let addressDetails = {
        addressLine1: '',
        landmark: '',
        city: '',
        state: '',
        pincode: ''
      };
      // console.log(addressObject.address_components);
      addressObject.address_components.forEach(element => {
        if (element.types.includes('administrative_area_level_1')) {
          addressDetails['state'] = element.long_name;
        } else if (element.types.includes('administrative_area_level_2')) {
          addressDetails['city'] = element.long_name;
        } else if (element.types.includes('postal_code')) {
          addressDetails['pincode'] = parseInt(element.long_name);
        } else if (element.types.includes('sublocality_level_1')) {
          addressDetails['landmark'] = element.long_name;
        } else if (!element.types.includes('country')) {
          addressDetails['addressLine1'] =
            (addressDetails['addressLine1']
              ? addressDetails['addressLine1']
              : '') +
            element.long_name +
            ', ';
        }
      }, addressDetails);
      addressDetails['addressLine1'] = addressDetails['addressLine1'].replace(
        /,\s*$/,
        ''
      );
      if (addressDetails.pincode) {
        let newformikValues = Object.assign(
          {},
          this.formikValues,
          addressDetails
        );
        this.formikSetValues(newformikValues);
        this.setState({
          address: addressDetails,
          googleAddress: addressDetails['addressLine1']
        });
      }
      if (!addressDetails.pincode) {
        Axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${geometry.lat()},${geometry.lng()}&key=AIzaSyDml6rqKwjgQgPomyAhC-WxVt4aLodlraU`
        )
        .then(response => {
          if (response.data.results.length > 0) {
            let addressComponent = response.data.results[0].address_components;
            addressDetails['pincode'] = parseInt(
              addressComponent[addressComponent.length - 1].long_name
            );
            let newformikValues = Object.assign(
              {},
              this.formikValues,
              addressDetails
            );
            // console.log(newformikValues)
            this.formikSetValues(newformikValues);
            this.setState({
              address: addressDetails,
              googleAddress: addressDetails['addressLine1']
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
      }
    } else {
      toast.error('Currently Non Serviceable Area :(');
    }
  }

  handleSubmitValues = (event, values) => {
    event.preventDefault();
    // console.log(`Hello`)
    // console.log(this.formikValues, values);
  };

  mapIdsToWorkTypes = workTypeId => {
    const workTypes = {
      1: 'fullTime',
      2: 'partTime',
      3: 'onDemand'
    };
    if (workTypeId) {
      return workTypes[workTypeId];
    }
  };

  mapWorkyTypesToId = workType => {
    const workTypes = {
      onDemand: 3,
      partTime: 2,
      fullTime: 1
    };
    if (workType) {
      return workTypes[workType];
    }
  };

  mapCityToId = (city, id) => {
    const citymaps = {
      Delhi: 71,
      Noida: 72,
      Gurugram: 73
    };
    const idMaps = {
      71: 'Delhi',
      72: 'Noida',
      73: 'Gurugram'
    };
    if (city) {
      return citymaps[city];
    }
    if (id) {
      return idMaps[id];
    }
  };

  getUniqueArray = array => {
    var uniqueArray = [];

    // Loop through array values
    array.map(el => {
      if (uniqueArray.indexOf(el) === -1) {
        uniqueArray.push(el);
      }
    });
    return uniqueArray;
  };

  // getSpecialRequirements(jobType);

  getSpecialRequirements = async (jobType, index) => {
    const detailedJobKeys = [...this.state.detailedJobKeys];
    console.log({detailedJobKeys});
    
    // console.log(...this.formikValues.jobType);
    return Promise.all(
      detailedJobKeys.map(async el => {
        if (el[jobType]) {
          // console.log(el[jobType], index);
          
          
          
          const data = await Axios.get(
            `${urlLinks.baseUrl}/configmaster/inquery?inquery=${el[jobType]['keys']}`
          ).then(response => response.data.response.content);
          // Axios.get(
          //   `${urlLinks.baseUrl}/configmaster/inquery?inquery=${el[jobType]['keys']}`
          // ).then(response => {
            console.log(data);
            
          let typesToRender = data.map(el => {
            return el.typeKey;
          });
          let ids = data.map(el => {
            return el.id;
          });
          let labels = data.map(el => el.typeDesc);
          labels = this.getUniqueArray(labels);
          const tempLabels = [...this.state.labels];
          tempLabels[index] = labels;
          typesToRender = this.getUniqueArray(typesToRender);
          // console.log(typesToRender);
          
          
          
          const jobtypes=this.formikValues["jobType"]
          // console.log(index, jobtypes);
          let detailedSkill=[];
          let detailedSkills=[];
            detailedSkill=
              typesToRender.map(
                skill =>  Object.keys(this.state.labelMaps).find(key => this.state.labelMaps[key] === skill))
            // console.log(detailedSkill);
            const skillArr = [];
            detailedSkill.forEach((element,index) => {
              // console.log(element)
              let pair={};
              // const jobEntities = {}
              // console.log(this.state.entities[typesToRender[index]])
              Object.keys(this.state.entities[typesToRender[index]]).map(el2 => {
                if(ids.includes(+el2)) {
                  // console.log(ids.includes(el2))
                  pair[el2] = this.state.entities[typesToRender[index]][el2];
                }
              })

              skillArr.push(pair)

              let skillsTypeId= Object.entries(pair)[0][0];
              let skillValue=Object.entries(pair)[0][1];
              
              console.log("element", element)
              detailedSkills.push({

                skillName:element,
                insertedBy: this.state.userId,
                  jobDetailsId: 0,
                  jobId: this.state.jobId,
                  skillsTypeId: element==="Experience"?40:0,
                  updatedBy: 0,
                  skillValue: element==="Experience"?"Fresher":null,
                
                });
            });

          jobtypes[index].jobtype=jobType;
          jobtypes[index].detailedSkills=[...detailedSkills];
          jobtypes[index].skillValues=[...skillArr]
          
          console.log(jobtypes)

          // console.log(this.formikValues);
          
          // let newformikValues=Object.assign(
          //     {},
          //     this.formikValues,
          //     {"jobType": [...jobtypes]}
              
          //   );
          //   this.formikSetValues(newformikValues);
          // console.log(this.formikValues);
          
  
          
          //  console.log(typesToRender);
           this.setState({typesToRender: typesToRender});
        //   const holder = {};
        //   const requirementsHolder = [...this.state.requirementsContainer];
        //   typesToRender.map((el, index) => {
        //     let values = { ...this.state.entities[el] };
        //     const value = Object.values(values)[0];
        //     holder[el] = value ? `${value}` : '';
        //   });
        //   holder['isColumnVisible'] = true;
        //   requirementsHolder[index] = holder;
        //   console.log(requirementsHolder);
        //   this.requirementsContainer = requirementsHolder;
        //   // const createdLabels = [];
        //   // typesToRender.map((el, index) => {
        //   //   createdLabels.push({[el]: labels[index]})
        //   // })

        //   const allEntities = [...this.state.allEntities];
        //   const asd = [];

        //   typesToRender.map((el, index) => {
        //     if (el !== 'job_type') {
        //       asd.push(this.state.entities[el]);
        //     }
        //   });

        //   allEntities[index] = asd;

        //   const requirements = [];
        //   typesToRender.map(type => requirements.push({ [type]: [] }));
        //   requirements.map(el2 => {
        //     data.map(el => {
        //       if (el2[el.categoryType]) {
        //         el2[el.categoryType].push({
        //           [el.typeValue]: {
        //             id: el.id,
        //             label: el.typeDesc,
        //             selectable: el.other
        //           }
        //         });
        //       }
        //     });
        //   });
        //   requirements.map((el, index) => {
        //     // console.log(el[typesToRender[index]])
        //   });
        //   this.requirements = requirements;
        //   console.log(typesToRender);
        //   this.setState({
        //     requirementsContainer: requirementsHolder,
        //     labels: tempLabels,
        //     typesToRender: typesToRender,
        //     allEntities: allEntities,
        //     requirements: requirements
        //   });
        //   // });
        //   console.log(this.state);
        
        }
      })
    );
  };

  mapRadioGroupToJobType = jobType => {
    const jobTypes = {
      onDemand: 'OD',
      fullTime: 'FT',
      partTime: 'PT'
    };
    return jobTypes[jobType];
  };
  mapJobTypeTpRadioGroup = jobType => {
    const jobTypes = {
      'OD': 'onDemand' ,
       'FT': 'fullTime',
      'PT': 'partTime' 
    };
    return jobTypes[jobType];
  };

  handleCompanyChange = companyName => {
    console.log(this.state.companyDetails);
    const userId = this.state.companyDetails[companyName]['userId'];
    const companyDetail = { ...this.state.companyDetails[companyName] };
    const lat = companyDetail.lat;
    const lng = companyDetail.lng;
    const address = {
      addressLine1: companyDetail.addressLine1,
      landmark: companyDetail.landmark,
      state: DropDownConstants.idToState[companyDetail.cityId] || 'Other',
      city: companyDetail.city,
      pincode: companyDetail.pincode
    };
    this.setState({
      particularCompanyDetail: companyDetail,
      location: {
        lat: lat,
        lng: lng
      },
      address: address,
      googleAddress: '',
      userId: userId
    });
    let newformikValues = Object.assign(
      {},
      this.formikValues,
      address,
      companyDetail
    );
    this.formikSetValues(newformikValues);
  };

  toDateWithOutTimeZone = date => {
    let tempTime = date.split(':');
    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    dt.setSeconds(tempTime[2]);
    return dt;
  };

  toDateWithOutTimeZone = (date) => {
    let tempTime = date.split(":");
    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    dt.setSeconds(tempTime[2]);
    return dt;
  }

  componentDidMount() {
    if(!this.props.location.id) {
      this.props.history.push('/jobs/jobList');
    }
    this._isMounted = true;
    const label=[];
    Axios.get(`${urlLinks.baseUrl}/configmaster/?rows=100`)
      .then(response=> {
        response.data.response.content.map(el=>{
          label[el.typeDesc]=el.typeKey;
        })
        this.setState({labelMaps: label});
        // console.log(this.state.labelMaps );
        }
      )


    Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_type=job_type&inserted_by=0&rows=100&type_desc=job_type_name`
    ).then(response => {
      let jobTypes = response.data.response.content.map(
        el => el.categorySubType
      );
      // console.log(jobTypes);
      
      let jobTypesList = {};
      response.data.response.content.map(
        el => (jobTypesList[el.id] = el.categorySubType)
      );
      jobTypes = this.getUniqueArray(jobTypes);
      this.setState({ jobTypes: jobTypes, jobTypesList: jobTypesList });

      const detailedJobTypes = [];
      response.data.response.content.map(el => {
        if (el.typeValue !== '-') {
          detailedJobTypes.push({
            [el.categorySubType]: {
              id: el.id,
              keys: el.typeValue
            }
          });
        }
      });
      this.setState({ detailedJobKeys: detailedJobTypes });
    });
    Axios.get(
      `${urlLinks.baseUrl}/configmaster/entityList`
    ).then(response => {
      const allEntities = Object.values(response.data.response.content);
      const entitylist = {};
      allEntities.map(el => {
        Object.keys(el).map(el2 => (entitylist[el[el2]] = el2));
      });
      this.setState({
        entities: response.data.response.content,
        entitylist: entitylist
      });
    });
    Axios.get(
      `${urlLinks.baseUrl}/admin/companies/`
    ).then(response => {
      const companies = {};      
      response.data.response.map(el => {
        companies[el.companyName] = el.companyId;
      });
      const companyDetail = {};
      response.data.response.map(el => {
        companyDetail[el.companyName] = {
          companyName: el.companyName,
          userId: el.userId,
          contactPerson: el.contactPerson || '',
          contactNumber: +el.contactNo || '',
          addressLine1: el.addressLine1,
          landmark: el.addressLine2,
          city: this.mapCityToId(null, el.cityId) || 'Other',
          cityId: el.cityId,
          pincode: el.postalCode,
          lat: el.locationLat,
          lng: el.locationLong
        };
      });
      this.setState(
        { companies: companies, companyDetails: companyDetail },
        () => {
          Axios.get(
            `${urlLinks.baseUrl}/job/getdetailedjob?job_id=${this.props.location.id}`
          ).then(response => {
            const data = response.data.response.content[0];
            // console.log(data);
            const workTypeName = this.mapIdsToWorkTypes(data.workType);
            const workTypeValue = this.mapRadioGroupToJobType(workTypeName);
            let values = {};
            if (workTypeValue === 'OD') {
              values = {
                startDate: new Date(data.startDate),
                startTime: data.loginTime!==null?this.toDateWithOutTimeZone(data.loginTime):null,
                endTime: data.logoutTime!==null?this.toDateWithOutTimeZone(data.logoutTime):null
              };
            } else if (workTypeValue === 'PT') {
              values = {
                startDate: new Date(data.startDate),
                endDate: new Date(data.endDate),
                startTime: data.loginTime!==null?this.toDateWithOutTimeZone(data.loginTime):null,
                endTime: data.logoutTime!==null?this.toDateWithOutTimeZone(data.logoutTime):null
              };
            } else if (workTypeValue === 'FT') {
              values = {
                startDate: new Date(data.startDate),
                startTime: data.loginTime!==null?this.toDateWithOutTimeZone(data.loginTime):null,
                endTime: data.logoutTime!==null?this.toDateWithOutTimeZone(data.logoutTime):null
              };
            }
            
            const dataForJobCategory =  values;
            console.log(data)
            console.log(data.address)
            const address = {
              addressLine1: data.address.split(',').slice(0, data.address.split(',').length-2).join(',') || '',
              landmark: data.landMark || '',
              city: data.cityName || 'Other',
              state: DropDownConstants.idToState[data.cityId] || 'Other',
              pincode: data.postalCode || ''
            };
            const location = {
              lat: data.jobLocationLat || '',
              lng: data.jobLocationLong || ''
            };
            const mapIdToDays = {
              1:'Sunday',
              2:'Monday',
              3:'Tuesday',
              4:'Wednesday',
              5: 'Thursday',
              6: 'Friday',
              7:'Saturday'
            };
            let weekarr=[];
            if(data.workdaysDetails){
              let weeks=JSON.parse(data.workdaysDetails);
              Object.keys(weeks).map(el =>{
                if(weeks[el]){
                  weekarr.push(mapIdToDays[el]);
                }
              })
            }
            let detailSkillsForState = [];
            data.detailsJobs.map(el => {
              detailSkillsForState.push(el.detailSkills)
            })
            this.setState(
              {
                selectedStartDate: data.startDate
                  ? new Date(data.startDate)
                  : new Date(),
                selectedEndDate: data.endDate
                  ? new Date(data.endDate)
                  : new Date(),
                selectedStartTime: data.loginTime!=null?this.toDateWithOutTimeZone(data.loginTime):null,
                selectedEndTime: data.loginTime!=null?this.toDateWithOutTimeZone(data.logoutTime): null,
                jobCategory: this.mapIdsToWorkTypes(data.workType),
                dayForJobSchedule: data.workdaysCount ? data.workdaysCount : 1,
                daysForJobSchedule: weekarr,
                jobId: data.jobId,
                radioType: this.mapJobTypeTpRadioGroup(workTypeValue),
                dataForJobCategory: dataForJobCategory,
                userId: '',
                detailedJobTypes: [...data.detailsJobs],
                detailSkillsForState: detailSkillsForState,
                address: address,
                location: location
              },
              () => this.handleCompanyChange(data.company.companyName)
            );
            data.detailsJobs.map(job=>{
              if(job.jobDetailsId) {
                this.jobType.push({
                  jobDetailsId: job.jobDetailsId,
                  jobtype: job.jobTitle,
                  openings: job.employeeRequired,
                  rate: job.amount,
                  isVisible: true,
                  paymentduration: job.amountPer,
                  additionalRequirements: job.additionalRequirement,
                  jobtypeId: job.jobTypeId,
                  jobId: job.Id,
                  isEmpty:false,
                  detailedSkills: [...job.detailSkills]
                })
              } else {
                this.jobType.push({
                  jobtype: job.jobTitle,
                  openings: job.employeeRequired,
                  rate: job.amount,
                  isVisible: true,
                  paymentduration: job.amountPer,
                  additionalRequirements: job.additionalRequirement,
                  jobtypeId: job.jobTypeId,
                  jobId: job.Id,
                  isEmpty:false,
                  detailedSkills: [...job.detailSkills]
                })
              }
            })

            let newformikValues=Object.assign(
              {},
              this.formikValues,
              {state: DropDownConstants.idToState[data.cityId]},
              {addressLine1: address.addressLine1},
              {addressLine2: address.landmark},
              {city: address.city},
              {pincode: address.pincode},
              {"jobType": [...this.jobType]},
              {'jobScheduleCategory': dataForJobCategory},
              {"jobScheduleDays": weekarr },
              {'radioGroup': this.mapJobTypeTpRadioGroup(workTypeValue)},
              {'jobDaysCount':this.state.dayForJobSchedule}
            );
            this.formikSetValues(newformikValues);
            this.data=this.formikValues;
          });
        }
      );
    });

    this.autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('google-autocomplete-input-worker', {})
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    console.log();
    
    const { classes } = this.props;
    if (this.state.isSubmitting) {
      return (
        <CircleLoader
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto'
          }}
          color={'#194D33'}
        ></CircleLoader>
      );
    } else {
    return (
      <div className={classes.root}>
        <BackButton />
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={2000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <Formik
          initialValues={{
            companyName: this.state.particularCompanyDetail.companyName || '',
            radioGroup: this.state.radioType||'',
            googleAddress: this.state.googleAddress,
            addressLine1: this.state.address.addressLine1
              ? this.state.address.addressLine1
              : '',
            addressLine2: this.state.address.landmark || '',
            contactPerson:
              this.state.particularCompanyDetail.contactPerson || '',
            contactNumber:
              this.state.particularCompanyDetail.contactNumber || '',
            city: this.state.address.city || '',
            state: '',
            pincode: this.state.address.pincode || '',
            longitude: this.state.location.lat || '',
            latitude: this.state.location.lng || '',
            specialRequirement: [...this.state.requirementsContainer],
            jobType: [],
            jobDaysCount:1,
            jobScheduleDays: [],
            jobScheduleCategory: {},
            category:{}
          }}
          validationSchema={this.addJobSchema}
          onSubmit={values => {
            this.setState({isSubmitting: true});
            const mapDaysToId = {
              Sunday: 1,
              Monday: 2,
              Tuesday: 3,
              Wednesday: 4,
              Thursday: 5,
              Friday: 6,
              Saturday: 7
            };
            const makeDayToId = {
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
              7: false
            };
            let jobScheduledDays = {};
            if (Array.isArray(values.jobScheduleDays)) {
              const days = [];
              values.jobScheduleDays.map(el => days.push(mapDaysToId[el]));
              days.map(el => (makeDayToId[el] = true));
              jobScheduledDays = JSON.stringify({ ...makeDayToId });
            }
            let cityId = DropDownConstants.states[values.state] || 71;
              let data = {};
            if (values.radioGroup === 'partTime') {
              data = {
                address: `${values.addressLine1}, ${values.city}, ${values.pincode}`,
                city_id: cityId,
                job_id: this.props.location.id,
                company_id: this.state.companies[values.companyName],
                contact_no: values.contactNumber.toString(),
                contact_person: values.contactPerson,
                country_id: 0,
                end_date:values.jobScheduleCategory.endDate ? values.jobScheduleCategory.endDate.toISOString().split('T')[0] : 
                values.radioGroup === 'partTime'
                ? JSON.stringify(
                    values.jobScheduleCategory[
                      this.mapRadioGroupToJobType(values.radioGroup)
                    ].endDate
                  ).slice(1, 11)
                : null,
                favourite_first: 0,
                job_location_lat: this.state.location.lat,
                job_location_long: this.state.location.lng,
                job_status: 1,
                land_mark: values.addressLine2,
                login_time: values.jobScheduleCategory.startTime ? values.jobScheduleCategory.startTime.toTimeString().split(' ')[0] :  
                values.jobScheduleCategory[
                  this.mapRadioGroupToJobType(values.radioGroup)
                ].startTime
                  .toTimeString()
                  .split(' ')[0],
                logout_time:values.jobScheduleCategory.endTime ? values.jobScheduleCategory.endTime.toTimeString().split(' ')[0] :  
                 values.jobScheduleCategory[
                  this.mapRadioGroupToJobType(values.radioGroup)
                ].endTime
                  .toTimeString()
                  .split(' ')[0],
                no_end_date: values.radioGroup === 'fullTime' ? 1 : 0,
                postal_code: values.pincode.toString(),
                requested_by: localStorage.getItem('userId'),
                start_date: values.jobScheduleCategory.startDate ? values.jobScheduleCategory.startDate.toISOString().split('T')[0] :   
                JSON.stringify(
                  values.jobScheduleCategory[
                    this.mapRadioGroupToJobType(values.radioGroup)
                  ].startDate
                ).slice(1, 11),
                user_id: this.state.userId,
                work_type: this.mapWorkyTypesToId(values.radioGroup),
                workdays_count:
                  Array.isArray(values.jobScheduleDays) === false
                    ? +values.jobScheduleDays
                    : 0,
                workdays_details:
                  values.radioGroup === 'partTime' ? Array.isArray(values.jobScheduleDays) ? jobScheduledDays : JSON.stringify({}) : JSON.stringify({})
              };
            } else {
              data = {
                address: `${values.addressLine1}, ${values.city}, ${values.pincode}`,
                city_id: cityId || 71,
                job_id: this.props.location.id,
                company_id: this.state.companies[values.companyName],
                contact_no: values.contactNumber.toString(),
                contact_person: values.contactPerson,
                country_id: 0,
                favourite_first: 0,
                job_location_lat: this.state.location.lat,
                job_location_long: this.state.location.lng,
                job_status: 1,
                land_mark: values.addressLine2,
                login_time:values.jobScheduleCategory.startTime ? values.jobScheduleCategory.startTime.toTimeString().split(' ')[0] :  
                 values.jobScheduleCategory[
                  this.mapRadioGroupToJobType(values.radioGroup)
                ].startTime
                  .toTimeString()
                  .split(' ')[0],
                logout_time: values.jobScheduleCategory.endTime ? values.jobScheduleCategory.endTime.toTimeString().split(' ')[0] :  
                 values.jobScheduleCategory[
                  this.mapRadioGroupToJobType(values.radioGroup)
                ].endTime
                  .toTimeString()
                  .split(' ')[0],
                no_end_date: values.radioGroup === 'fullTime' ? 1 : 0,
                postal_code: values.pincode.toString(),
                requested_by: localStorage.getItem('userId'),
                start_date: values.jobScheduleCategory.startDate ? values.jobScheduleCategory.startDate.toISOString().split('T')[0] :  
                 JSON.stringify(
                  values.jobScheduleCategory[
                    this.mapRadioGroupToJobType(values.radioGroup)
                  ].startDate
                ).slice(1, 11),
                user_id: this.state.userId,
                work_type: this.mapWorkyTypesToId(values.radioGroup),
                workdays_count:
                  Array.isArray(values.jobDaysCount) === false
                    ? +values.jobDaysCount
                    : 1
              };
              // if(values.radioGroup === 'onDemand') {
              //   const date = values.jobScheduleCategory.startDate
              //   data.end_date = new Date(date.setDate(date.getDate() + (+values.jobDaysCount))).toISOString().split('T')[0];
              // }
              // console.log(values)
              // console.log(data)
              if(values.radioGroup === 'onDemand') {
                let date = values.jobScheduleCategory.OD ? values.jobScheduleCategory.OD.startDate : values.jobScheduleCategory.startDate;
                if(+values.jobDaysCount === 1) {
                  const startTime = data.login_time
                  const endTime = data.logout_time
                    if(+startTime.substring(0, 2) > +endTime.substring(0, 2)) {
                      data.end_date = new Date(date.setDate(date.getDate() + (+values.jobDaysCount))).toISOString().split('T')[0];
                    } else {
                      data.end_date = values.jobScheduleCategory.startDate ? values.jobScheduleCategory.startDate.toISOString().split('T')[0] : values.jobScheduleCategory.OD ? values.jobScheduleCategory.OD.startDate.toISOString().split('T')[0] : null;
                    }
                } else {
                  const startTime = data.login_time
                  const endTime = data.logout_time
                  if(+startTime.substring(0, 2) > +endTime.substring(0, 2)) {
                    data.end_date = new Date(date.setDate(date.getDate() + (+values.jobDaysCount))).toISOString().split('T')[0];
                  } else {
                    data.end_date = new Date(date.setDate(date.getDate() + (+values.jobDaysCount-1))).toISOString().split('T')[0];
                  }
                }
              }
            }
            console.log(values.jobType)
            Axios.post(
              `${urlLinks.baseUrl}${urlLinks.jobUrls.postAddEditJob}`,
              qs.stringify(data)
            ).then(response => {
              const jobId = this.props.location.id;
              let detailedJob = [];
              values.jobType.map((el, index) => {
                if(values.jobType[index]) {
                  if(!values.jobType[index].jobDetailsId && values.jobType[index]['isVisible'] === true) {
                    detailedJob.push({
                      additional_requirement: values.jobType[index].textarea
                        ? values.jobType[index].textarea
                        : '',
                      amount: +el.rate,
                      amount_per:
                        values.radioGroup === 'onDemand' ? 'Daily' : 'Monthly',
                      employee_required: +el.openings,
                      experience_required: 0,
                      gender: 0,
                      is_recurring: 0,
                      job_category_id: 0,
                      job_id: this.props.location.id,
                      job_position: el.jobtype,
                      job_title: el.jobtype,
                      job_type_id: +Object.keys(this.state.jobTypesList).find(
                        key => this.state.jobTypesList[key] === el.jobtype
                      ),
                      qualification_required: 0,
                      skill_set: JSON.stringify(values.jobType[index].detailedSkills)
                    });
                  }
                }
              });
              console.log(detailedJob);
              
              if(detailedJob.length === 0) {
                toast.error(`Job Edited Successfully`);
                this.props.history.push('/jobs/jobList');
              }
              let counter = 0;
              detailedJob.map(el => {
                Axios.post(`${urlLinks.baseUrl}/job/details/`,qs.stringify(el))
                .then(response2 => {
                  console.log(response2);
                  
                  counter++;
                  if(counter === detailedJob.length) {
                    toast.error(`Job Edited Successfully`);
                    this.props.history.push('/jobs/jobList');
                  }
                })
                .catch(err => {
                  toast.error(`Error Editing Job`);
                  this.props.history.push('/jobs/jobList');
                })
              })
            }).catch(err => {
              toast.error(`Error Editing Job`);
              this.props.history.push('/jobs/jobList');
            })
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              setValues
            } = props;
            let { handleSubmit } = props;
            this.formikValues = values;
            this.formikSetValues = setValues;

            return (
              <Form
                className={classes.form}
                onSubmit={event => handleSubmit(event, values)}
              >
                <Grid container spacing={2} className={classes.grid}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h6' className={classes.textheading}>
                      Basic Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl className={classes.text_container}>
                      <InputLabel id='demo-simple-select-label'>
                        Company Name
                      </InputLabel>
                      <Select
                        name='companyName'
                        labelId='demo-simple-select-label'
                        disabled
                        id='demo-simple-select'
                        value={values.companyName}
                        onChange={event => {
                          handleChange(event);
                          this.handleCompanyChange(event.target.value);
                        }}
                      >
                        {Object.keys(this.state.companies).map(el => (
                          <MenuItem key={el} value={el}>
                            {el}
                          </MenuItem>
                        ))}

                        {/* <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        label='Contact Person'
                        name='contactPerson'
                        value={values.contactPerson}
                        onChange={handleChange}
                        error={
                          touched.contactPerson && Boolean(errors.contactPerson)
                        }
                        helperText={
                          touched.contactPerson ? errors.contactPerson : ''
                        }
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        label='Contact Number'
                        type='number'
                        name='contactNumber'
                        value={values.contactNumber}
                        onChange={handleChange}
                        error={
                          touched.contactNumber && Boolean(errors.contactNumber)
                        }
                        helperText={
                          touched.contactNumber ? errors.contactNumber : ''
                        }
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginBottom: '20px' }}>
                    <Typography
                      className={classes.textheading}
                      variant='h6'
                      style={{
                        display: 'inline',
                        marginRight: '50px'
                      }}
                      // onChange= {setFieldValue("radioGroup",this.state.jobCategory)}
                    >
                      Category
                    </Typography>
                    <Radio
                      disabled
                      checked={this.state.jobCategory === 'onDemand'}
                      onChange={event => {
                        this.handlejobCategory(event);
                        handleChange(event);
                      }}
                      value='onDemand'
                      label='OD'
                      name='radioGroup'
                      inputProps={{ 'aria-label': 'onDemand' }}
                      control={<Radio color='primary' />}
                    />
                    OD
                    <Radio
                      disabled                      
                      checked={this.state.jobCategory === 'fullTime'}
                      onChange={event => {
                        this.handlejobCategory(event);
                        handleChange(event);
                      }}
                      value='fullTime'
                      label='FT'
                      name='radioGroup'
                      inputProps={{ 'aria-label': 'fullTime' }}
                    />
                    FT
                    <Radio
                      disabled
                      checked={this.state.jobCategory === 'partTime'}
                      onChange={event => {
                        this.handlejobCategory(event);
                        handleChange(event);
                      }}
                      value='partTime'
                      label='PT'
                      name='radioGroup'
                      inputProps={{ 'aria-label': 'partTime' }}
                    />
                    PT
                    {touched.radioGroup && errors.radioGroup ? (
                      <div>
                        <span style={{ color: 'red' }}>
                          {errors.radioGroup}
                        </span>
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant='h6' className={classes.textheading}>
                      Job Schedule
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {this.state.jobCategory ? (
                      <JobCategory
                        setDayValue={day=>
                          setFieldValue('jobDaysCount', day)
                        }
                        setDaysValues={updatedSchedule =>
                          setFieldValue('jobScheduleDays', updatedSchedule)
                        }
                        setCategoryValues={values =>
                          setFieldValue('jobScheduleCategory', values)
                        }
                        daysToSelect={{startDate: this.state.selectedStartDate, endDate: this.state.selectedEndDate, startTime: this.state.selectedStartTime, endTime: this.state.selectedEndTime}}
                        value={this.state.jobCategory}
                        data={values.jobScheduleCategory}
                        schedule={this.state.daysForJobSchedule}
                        dayCount={this.state.dayForJobSchedule}
                      />
                    ) : (
                      <Grid></Grid>
                    )}
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  <Grid container justify='space-around'>
                    <Grid item xs={12} sm={9}>
                      <Typography variant='h6' className={classes.textheading}>
                        Job Type
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{ display: 'flex', marginBottom: '10px' }}
                    >
                    {/* AddButton for Job Type */}
                      <Button
                        onClick={() => {
                          // console.log(values.jobType);
                          const data =[ 
                            ...values.jobType,
                            {
                              jobtype: '',
                              openings: '',
                              rate: '',
                              isVisible: true,
                              paymentduration: '',
                              additionalRequirements: '',
                              jobtypeId: '',
                              jobId: '',
                              isEmpty:true,
                              detailedSkills: []
                            }
                          ];
                          // console.log(this.state.requirementsContainer);
                          // const data1 = this.state.requirementsContainer;
                          
                          setFieldValue('jobType', data);
                          // console.log(values.jobType)
                        }}
                      >
                        <AddBoxIcon></AddBoxIcon>
                      </Button>
                    </Grid>
                  </Grid>
                  {values.jobType.map((job, index) => {
                    // console.log(values);
                    
                    if (job['isVisible']) {
                      return (
                        <React.Fragment key={index}>
                          <Grid container justify='space-around'>
                          
                            <Grid item xs={12} sm={3}>
                              <TextField
                                className={classes.text_container}
                                variant='outlined'
                                select
                                disabled={values.jobType[index].jobDetailsId ? true : false}
                                name={`jobType[${index}].jobtype`}
                                key={index}
                                value={values.jobType[index].jobtype}
                                onChange={async event => {
                                  // console.log(event)
                                  
                                  // console.log(this.state.requirementsContainer)


                                  await this.getSpecialRequirements(
                                    event.target.value,
                                    index
                                  );


                                  // console.log(this.state.typesToRender);
                                  // // console.log(skills);
                                  
                                  // let detailedSkill=[];
                                  // let detailedSkills=[];
                                  // detailedSkill=
                                  //   this.state.typesToRender.map(
                                  //     skill =>  Object.keys(this.state.labelMaps).find(key => this.state.labelMaps[key] === skill))
                                  
                                  // detailedSkill.forEach(element => {
                                  //   detailedSkills.push({"skillName":element});
                                  // });
                                  // console.log(values.jobType);
                                  
                                  // const updatedValues = values.jobType.map(
                                  //   (element, id) => {
                                  //     if (id === index) {
                                  //       element.jobtype = event.target.value;
                                  //       element.detailedSkills=[...detailedSkills];
                                  //     }
                                  //     return element;
                                  //   }
                                  // );
                                  // console.log(updatedValues);
                                  
                                  // setFieldValue('jobType', [...values.jobType]);
                                  // this.setState({jobType: updatedValues})
                                  
                                  

                                  // console.log(result)

                                  // setFieldValue('specialRequirement', this.state.requirementsContainer)
                                  // await console.log(this.requirementsContainer)
                                  // await console.log(this.state.requirementsContainer)
                                  // setTimeout(() => {
                                  //   setFieldValue('specialRequirement', this.state.requirementsContainer)
                                  // }, 500);
                                }}
                                error={
                                  errors.jobType
                                    ? errors.jobType[index]
                                      ? Boolean(errors.jobType[index].jobtype)
                                      : false
                                    : false
                                }
                                label='Job Type'
                                InputProps={{
                                  className: classes.input
                                }}
                                InputLabelProps={{
                                  className: classes.inputLabel
                                }}
                              >
                                {this.state.jobTypes.map((jobType, index) => (
                                  <MenuItem key={index + 1} value={jobType}>
                                    {jobType}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            

                            <Grid item xs={12} sm={3}>
                              <TextField
                                className={classes.text_container}
                                disabled={values.jobType[index].jobDetailsId ? true : false}
                                key={index}
                                variant='outlined'
                                name='openings'
                                value={values.jobType[index].openings}
                                onChange={event => {
                                  const updatedValues = values.jobType.map(
                                    (element, id) => {
                                      if (id === index) {
                                        element.openings = event.target.value;
                                      }
                                      return element;
                                    }
                                  );
                                  setFieldValue('jobType', updatedValues);
                                }}
                                error={
                                  errors.jobType
                                    ? errors.jobType[index]
                                      ? Boolean(errors.jobType[index].openings)
                                      : false
                                    : false
                                }
                                label='Openings'
                                type='number'
                                InputProps={{
                                  className: classes.input,
                                  inputProps: {
                                    className: classes.inputStyle
                                  }
                                }}
                                InputLabelProps={{
                                  className: classes.inputLabel
                                }}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                className={classes.text_container}
                                variant='outlined'
                                disabled={values.jobType[index].jobDetailsId ? true : false}
                                label={
                                  values.radioGroup === 'onDemand'
                                    ? 'Rate'
                                    : 'Salary'
                                }
                                name={`jobType[${index}].rate`}
                                value={values.jobType[index].rate}
                                onChange={event => {
                                  const updatedValues = values.jobType.map(
                                    (element, id) => {
                                      if (id === index) {
                                        element.rate = event.target.value;
                                      }
                                      return element;
                                    }
                                  );
                                  setFieldValue('jobType', updatedValues);
                                }}
                                error={
                                  errors.jobType
                                    ? errors.jobType[index]
                                      ? Boolean(errors.jobType[index].rate)
                                      : false
                                    : false
                                }
                                key={index}
                                type='number'
                                InputProps={{
                                  className: classes.input,
                                  inputProps: {
                                    className: classes.inputStyle
                                  }
                                }}
                                InputLabelProps={{
                                  className: classes.inputLabel
                                }}
                              ></TextField>
                            </Grid>
                            {/* delete Button for jobTypes */}
                            <Grid item xs={12} sm={3}>
                              <Button
                                disabled={values.jobType[index].jobDetailsId ? true : false}
                                variant='contained'
                                onClick={() => {
                                  if (values.jobType.length > 1) {
                                    const updatedValues = [...values.jobType];
                                    // console.log(updatedValues);
                                    // console.log(updatedValues[index]);
                                    updatedValues[index]['isVisible'] = false;
                                    const specialRequirementValues = [
                                      ...values.specialRequirement
                                    ];
                                    if (!specialRequirementValues[index]) {
                                      updatedValues[index]['jobtype'] = 'a';
                                      updatedValues[index]['openings'] = 1;
                                      updatedValues[index]['rate'] = 1;
                                    } else {
                                      specialRequirementValues[index][
                                        'isColumnVisible'
                                      ] = false;
                                    }
                                    // const specialRequirementValues = [
                                    //   ...values.specialRequirement
                                    // ];
                                    // if (!specialRequirementValues[index]) {
                                    //     updatedValues[index]['jobtype'] = 'a';
                                    //     updatedValues[index]['openings'] = 'a';
                                    //     updatedValues[index]['rate'] = 1;
                                    //   } else {
                                    //     specialRequirementValues[index][
                                    //       'isColumnVisible'
                                    //     ] = false;
                                    //   }
                                    // console.log(values.specialRequirement);

                                    // const allEntities = this.state.allEntities[index].filter((element,id) => {
                                    //   return id!==index
                                    // })
                                    // this.setState({allEntities: allEntities})

                                    // const labels = this.state.labels[index].filter((element, id) => {
                                    //   return id!==index
                                    // })
                                    // this.setState({labels: labels})

                                    // const specialRequirements = values.specialRequirement[index].filter((element, id) => {
                                    //   return id!==index
                                    // })
                                    // setFieldValue('specialRequirement',specialRequirements);

                                    // const typesToRender = this.state.typesToRender[index].filter((element, id) => {
                                    //   return id!==index
                                    // })
                                    // this.setState({typesToRender: typesToRender})

                                    setFieldValue('jobType', updatedValues);
                                  } else {
                                    toast.error(`Jobs can't be less than 1`);
                                  }
                                }}
                              >
                                <DeleteIcon></DeleteIcon>
                              </Button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {values.jobType.map((job, idx) => {
                    if (values.jobType[idx].jobtype === 'other') {
                      return (
                        <React.Fragment key={idx}>
                          <Grid container className={classes.grid}>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                className={classes.text_container}
                                id='job'
                                name='job'
                                placeholder='specify job type'
                                multiline
                                variant='outlined'
                                fullWidth
                                onChange={value =>
                                  setFieldValue('job', value.name)
                                }
                                InputProps={{
                                  className: classes.input,
                                  inputProps: {
                                    className: classes.inputStyle
                                  }
                                }}
                                InputLabelProps={{
                                  className: classes.inputLabel
                                }}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    }
                  })}
                  {values.jobType.map((job, idx) => {
                    // console.log(values.jobType);
                    let editableJobType=values.jobType;
                    // console.log(editableJobType);
                    
                    
                    if (job['isVisible']) {
                      return (
                        <React.Fragment key={idx}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={10}
                              style={{ margin: '5px' }}
                            >
                              <ExpansionPanel disabled={values.jobType[idx].jobDetailsId ? true : false}>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls='panel2a-content'
                                  id='panel2a-header'
                                  style={{ height: '40px', fontSize: '0.8rem' }}
                                >
                                  <Typography>
                                    {values.jobType[idx].jobtype}
                                  </Typography>
                                  <Typography style={{marginLeft: '270px'}}>
                                    {values.jobType[idx].jobtype ? 'Add Job description & Special Req' : null }
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Grid container>
                                    {/* {console.log(this.state.allEntities)} */}
                                    {job.detailedSkills.length>0
                                      ? job.detailedSkills.map(
                                          (requirement, index) => {
                                            // console.log(requirement)
                                            // console.log(requirement);
                                            
                                            
                                            //console.log(this.state.entities[this.state.labelMaps[requirement.skillName]])
                                            return (
                                              <>
                                                <Grid
                                                  key={index + 1}
                                                  item
                                                  xs={2}
                                                  className={classes.gap}
                                                >
                                                  {/* {console.log(this.state.entities)} */}
                                                  {
                                                    requirement.skillName
                                                  }
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={4}
                                                  className={classes.gap}
                                                >
                                                  <FormControl
                                                    variant='outlined'
                                                    fullWidth
                                                  >
                                                    <InputLabel htmlFor='outlined-age-native-simple'>
                                                      {/* {Object.values(requirement)[0]} */}
                                                    </InputLabel>
                                                    <Select
                                                      key={idx}
                                                      value={
                                                       values.jobType[idx]['detailedSkills'][index]['skillValue']
                                                      }
                                                      native
                                                      onChange={event => {
                                                        // console.log(event.target.value,index)
                                                        //  console.log(this.state.typesToRender[index])
                                                        // const values1 = [
                                                        //   ...job.detailedSkills
                                                        // ];
                                                        // console.log(values1[index]);

                                                        requirement.skillValue=event.target.value;
                                                        const values2 = [];
                                                          // console.log(values.specialRequirement)
                                                        console.log(event.target.value);
                                                        
                                                        values2.push({

                                                          insertedBy: this.state.userId,
                                                          jobDetailsId: 0,
                                                          jobId: this.state.jobId,
                                                          skillsTypeId:  event.target.value==='None'?0:this.state.entitylist[event.target.value],
                                                          updatedBy: 0,
                                                          skillName: requirement.skillName,
                                                          skillValue: event.target.value==='None'?null:event.target.value
                                                        })
                                                        
                                                        editableJobType[idx]['detailedSkills'][index]=values2[0];
                                                        console.log(editableJobType[idx]);
                                                        setFieldValue('jobType',editableJobType);
                                                       
                                                       }}
                                                      label={
                                                        Object.values(
                                                          requirement
                                                        )[0]
                                                      }
                                                      inputProps={{
                                                        name: Object.values(
                                                          requirement
                                                        )[0],
                                                        id: `outlined-age-native-simple${index}`
                                                      }}
                                                      id={
                                                        this.state
                                                          .typesToRender[idx]
                                                      }
                                                    >
                                                      {requirement.skillName==="Experience"?null:
                                                        <option
                                                          key="nullValue"
                                                          value={null}
                                                        >
                                                          None
                                                        </option>
                                                      }
                                                      
                                                      { job.skillValues ? 
                                                      Object.values(job.skillValues[index]).map((el, index) => {
                                                        return (
                                                        <option
                                                          key={index}
                                                          value={el}
                                                        >
                                                          {el}
                                                        </option>
                                                        )
                                                      }) :
                                                      Object.values(
                                                        this.state.entities[this.state.labelMaps[requirement.skillName]]?this.state.entities[this.state.labelMaps[requirement.skillName]]:''
                                                      ).map((el, index) => {
                                                        return (
                                                        <option
                                                          key={index}
                                                          value={el}
                                                        >
                                                          {el}
                                                        </option>
                                                        )
                                                      })}
                                                    </Select>
                                                  </FormControl>
                                                </Grid>
                                              </>
                                            );
                                          }
                                        )
                                      :null
                                      }
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.gap}
                                      >
                                        Job Description
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        className={classes.gap}
                                      >
                                        <TextField 
                                          rows={10}
                                          style={{ width: '100%' }}
                                          id='textarea'
                                          inputProps={{
                                            maxlength: 1500
                                          }}
                                          variant="outlined"
											                    multiline
                                          helperText={
                                          `${(values&&values.jobType[idx])?values.jobType[idx].textarea?values.jobType[idx].textarea.length:0:0}/1500`}
                                          
                                          key={idx}
                                          // value={values.jobType[idx].specialRequirement.textarea}
                                          onChange={event => {
                                            const updatedValues = values.jobType.map(
                                              (element, id) => {
                                                if (id === idx) {
                                                  element.textarea =
                                                    event.target.value;
                                                }
                                                return element;
                                              }
                                            );
                                            setFieldValue(
                                              'specialRequirement',
                                              updatedValues
                                            );
                                          }}
                                        />
                                      </Grid>
                                  </Grid>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h6' className={classes.textheading}>
                      Job Location
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    // style={{ display: 'flex', justifyContent: 'center' }} // --Akshat
                  >
                    <TextField
                      className={classes.text_container}
                      id='google-autocomplete-input-worker'
                      variant='outlined'
                      onKeyPress={event =>
                        event.key === 'Enter' ? event.preventDefault() : null
                      }
                      label='Search Google Maps'
                      name='search Address'
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.addressLine1 && touched.addressLine1}
                      readOnly
                      label='Address Line 1'
                      name='addressLine1'
                      value={values.addressLine1}
                      onChange={handleChange}
                      helperText={
                        errors.addressLine1 &&
                        touched.addressLine1 &&
                        errors.addressLine1
                      }
                      InputProps={{
                        className: classes.input,
                        readOnly: Boolean(true),
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.addressLine2 && touched.addressLine2}
                      label='Landmark'
                      name='addressLine2'
                      value={values.addressLine2}
                      onChange={handleChange}
                      helperText={
                        errors.addressLine2 &&
                        touched.addressLine2 &&
                        errors.addressLine2
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.city && touched.city}
                      label='City'
                      name='city'
                      onChange={handleChange}
                      readOnly
                      value={values.city}
                      helperText={errors.city && touched.city && errors.city}
                      InputProps={{
                        readOnly: Boolean(true),
                        className: classes.input
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.state && touched.state}
                      label='State'
                      onChange={handleChange}
                      name='state'
                      readOnly
                      value={values.state}
                      InputProps={{
                        className: classes.input,
                        readOnly: Boolean(true),
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      type='number'
                      error={errors.pincode && touched.pincode}
                      label='Pincode'
                      name='pincode'
                      onChange={handleChange}
                      value={values.pincode}
                      readOnly
                      helperText={
                        errors.pincode && touched.pincode && errors.pincode
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          readOnly: Boolean(true),
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  <Button
                    type='submit'
                    variant='contained'
                    className={classes.buttonStyle}
                    color='primary'
                  >
                    Submit
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
    }
  }
}

export default withStyles(styles)(EditJob);
