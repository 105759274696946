import React, { useState, useEffect, useMemo , useCallback} from 'react'
// import DataTable from "react-data-table-component";
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import {Grid, Button, TableSortLabel, Checkbox} from '@material-ui/core';
import styled from 'styled-components'
import ScaleLoader from "react-spinners/ScaleLoader";
import Pagination from '../../../../utils/react-table/pagination'
import './tables.css'
import urlLinks from '../../../../urlLinks';
import Axios from 'axios';
import WorkerModal from './functions/workerModal'
import SliderFilter from './Filters/SliderFilter'
import InputTextFilter from './Filters/inputTextFilter'
import EditableDropdown from './functions/EditableDropdown'
import AssignVacancyThroughRecruiter from '../../../../utils/Modals/AssignToRecruiterThroughJob'
import SelectFilter from './Filters/SelectFilter'
import Filters from './filterModal'
import SendLinkModal from  '../../../../utils/Modals/messageTriggerModal'
import RemarkStatusModal from '../../../../utils/Modals/remarkStatusModal'
import ExternalWorkerModal from '../../../worker/externalWorker/externalWorkerModal'
import { ToastContainer, toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import AssignToRecruiterModal from '../../../../utils/Modals/assignToRecruiter'
import SubmitResponseModel from '../../../../utils/Modals/submitResponseModel'


const Styles = 
    styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            height:700px;
        }
        table {
            border-spacing: 0;
            height:700px;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #1c75bc !important;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
    `

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} color= "primary" />
        </React.Fragment>
    )
    }
)
const MatchedWorkers =(props) =>{
    const [data, setData] = useState([]);
    const { disableQuestionnaireButton} = props
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentJobId, setCurrentJobId] = useState(0)
    const [openWorkerModal, setOpenWorkerModal] = useState(false)
    const [openExternalWorkerModal, setOpenExternalWorkerModal] = useState(false)
    const [openAssignRecruiterModal, setOpenAssignRecruiterModal] = useState(false)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [perPage, setPerPage] = useState(10);
    const [workerDetails, setWorkerDetails] = useState({})
    const [userId, setUserId] = useState()
    const [openAssignRecruiterJobModal, setOpenAssignRecruiterJobModal] = useState(false)
    const [jobRecData, setJobRecData] = useState()
    const [openSendLinkModal, setOpenSendLinkModal] = useState(false)   
    const [openRemarkModal, setOpenRemarkModal] = useState(false)
    const [statusRemarkData, setStatusRemarkData] = useState()
    const [userIdList, setUserIdList] = useState([])
    const [sortUrl, setSortUrl] = useState('')
    const [filterUrl, setFilterUrl] = useState('')
    const [openSubmitResponseModel, setOpenSubmitResponseModel] = useState(false)
    const [getQuestion, setGetQuestion]= useState();
    const [rowData, setRowData]=useState();
    const [filters, setFilters] = useState({
        averageRating: 0,
        actualScore: 0,
        cutoffScore: 0,
        callStatus:[""],
        approvalStatus:[""],
        assignedTo: {},
        expInYears: 0,
        totalGigs: 0,
        userName: '',
        phoneNumber: '',
        gender:"",
        remark_status:[],
        preferred_job_type_id:[],
        low_salary:0,
        preferred_location_id:[],
        skill_mismatch: null,
        communication_issue:null
    })
    const [currentPage, setCurrentPage] = useState(1);
    const handleWorkerDetail=(row) =>{
        console.log(row.row.original);
        if(row.row.original.intExt==="INTERNAL"){
            setWorkerDetails({
                userId:row.row.original.userId,
                workerId:row.row.original.workerId,
                jobDetailsId: row.row.original.jobDetailsId
            })
            setOpenWorkerModal(true);
        }
        else{
            setUserId(row.row.original.userId)
            setOpenExternalWorkerModal(true)
        }
    }
    const handleApply = useCallback(
        (row) => {
        Axios.post(`${urlLinks.baseUrl}/job/assigner/accept?requested_by=${localStorage.getItem('userId')}&assign_id=${row.row.original.assignId}`)
            .then(response => {
                toast.success(`Applied Worker successfully`);
                let curpage=currentPage
                if(totalPages>Math.ceil((totalRows-1)/perPage)){
                    if(currentPage===totalPages)
                    {
                        curpage=currentPage-1
                        setCurrentPage(curpage)
                    }
                    setTotalPages(totalPages-1)
                }   
                getMatchedWorkerList(perPage, curpage)
                setLoading(false)
                
            })
            .catch(err => {
                toast.error(`Error Applying Worker`);
                setLoading(false); 
            })   
        },
        [loading, perPage, currentPage, totalPages]
    );
    const handleStatusRemark =(row) =>{
        console.log({row})
        const data={
            remark_status: row.remark_status,
            low_salary: row.low_salary,
            preferred_job_type: row.preferred_job_type,
            preferred_job_type_id: row.preferred_job_type_id,
            preferred_location: row.preferred_location,
            preferred_location_id: row.preferred_location_id,
            other: row.other,
            skill_mismatch: row.skill_mismatch,
            communication_issue: row.communication_issue,
        }
        setStatusRemarkData(data);
        setOpenRemarkModal(true)
    }
    const handleSendLink = (row) =>{
        setWorkerDetails(row)
        setOpenSendLinkModal(true)
    }
    const columns = useMemo(
        () => [
        {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} />
                </div>
            ),
            Cell: ({ row }) => (
                <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}/>
                </div>
            ),
        },
       
        {
            Header: "Int/Ext",
            accessor: "intExt",
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "No.",
            accessor: "no",
            defaultCanFilter :false
        },
        {
            Header: "Worker Id",
            accessor: "workerId",
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Name",
            accessor: "userName",
            Cell: row => {
                return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.userName}</button>},
            defaultCanFilter :true,
            Filter: InputTextFilter ,
            defaultCanSort:true
        },
        {
            Header: "Assigned to",
            accessor: "recruiterName",
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Phone Number",
            accessor: "phoneNumber",
            minWidth:130,
            defaultCanFilter:true,
            Filter: InputTextFilter ,
            defaultCanSort:true
        },
        {
            Header:"Screening Questions",
            accessor:"submitResponse",
            Cell: row => { console.log({disableQuestionnaireButton})
                return row.row.original.submitResponse===1? <Button variant="outlined" color="primary" onClick={() =>openEditResponse(row)}>
                CHECK / EDIT RESPONSE
                </Button>:<Button variant="contained" color="primary" onClick={() =>openSubmitResponse(row)} disabled={disableQuestionnaireButton} >
                   SUBMIT RESPONSE
                </Button>},
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible: true
        },
        {
            Header: "Actual score",
            accessor: "actualScore",
            minWidth:120,
            defaultCanFilter:true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Cutoff score",
            accessor: "cutoffScore",
            minWidth:120,
            Filter: SliderFilter,
            defaultCanFilter:true,
            defaultCanSort:true
        },
        {
            Header: "City",
            accessor: "city",
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Last seen distance",
            accessor: "lastSeenDistance",
            minWidth:170,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Distance captured on",
            accessor: "distanceCapturedOn",
            minWidth:200,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Travel mode",
            accessor: "travelMode",
            minWidth:120,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Last job experience",
            accessor: "lastJobExp",
            minWidth: 350,
            defaultCanFilter:false,
            defaultCanSort:true
        },{
            Header: "Experience In Years",
            accessor: "expInYears",
            minWidth:170,
            defaultCanFilter:true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Average rating",
            accessor: "averageRating",
            minWidth:150,
            defaultCanFilter:true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Total gigs",
            accessor: "totalGigs",
            defaultCanFilter:true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Breach",
            accessor: "breach",
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Inserted On",
            accessor: "insertedOn",
            minWidth:170,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Breach cutoff",
            accessor: "breachCutOff",
            minWidth:120,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Availability cutoff",
            accessor: "availabilityCutOff",
            minWidth:180,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Distance cutoff",
            accessor: "distanceCutOff",
            minWidth:150,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Gender cutoff",
            accessor: "genderCutOff",
            minWidth:150,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Experience cutoff",
            accessor: "expCutOff",
            minWidth:160,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Qaulification cutoff",
            accessor: "qualCutOff",
            minWidth: 170,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Negative rating cutoff",
            accessor: "negativeRatingCutOff",
            minWidth:190,
            defaultCanFilter:false,
            defaultCanSort:true
        },
        {
            Header: "Address",
            accessor: "address",
            minWidth:750,
            defaultCanFilter:false 
        },
        {
            Header:"recruiter assigned",
            accessor:"recruiter_name",
            defaultCanFilter :true,
            Filter: InputTextFilter ,
            defaultCanSort:true
        },
        {
            Header: "Call status",
            accessor: "callStatus",
            Cell: EditableDropdown,
            defaultCanFilter :true,   //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true
        },
        {
            Header: "Approval status",
            accessor: "approvalStatus",
            Cell: EditableDropdown,
            defaultCanFilter :true,     //needed filter
            Filter: SelectFilter ,
            defaultCanSort:true
        },
        {
            Header: "Status remark",
            accessor: "statusRemark",
            Cell: row => { 
                return <Button className="linkButton" onClick={() =>handleStatusRemark(row.row.original)}>
                    show details
                </Button>},
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Link triggers",
            accessor: "whatsApp_trigger",
            Cell: row => { 
                return <Button className="linkButton" onClick={()=>handleSendLink(row.row.original)}>
                    Send link
                </Button>},
            defaultCanFilter :false,
            Filter: InputTextFilter ,
            defaultCanSort:false
        },
        {
            Header: "Apply",
            Cell: row => <button className="linkButton" onClick={()=>handleApply(row)}>Apply</button>
        }
        ],
        [handleApply]
    );
    const showToast = (type, message) =>{
        if(type==="success")
            return toast.success(message);
        else if(type==="error")
            return toast.error(message);
    }
    const updateMyData = () => {
        getMatchedWorkerList(perPage, currentPage);
    }

    const openSubmitResponse = (row) =>{
        var job_id = row.cell.row.original.jobId;
        var user_id = row.cell.row.original.userId;
        console.log(job_id,user_id);
        const url=`${urlLinks.baseUrl}question_controller/getJobQuestion?job_id=${job_id}`
        Axios.get(url).then(response=>{
            console.log(response.data);
            setRowData(row.cell.row.original)
            setGetQuestion(response.data);
            
        })
        setOpenSubmitResponseModel(true);
        console.log("hello open submit called");
        console.log(row);
        
    }

    const openEditResponse = (row) =>{

        var job_id = row.cell.row.original.jobId;
        var user_id = row.cell.row.original.userId;
        console.log(job_id,user_id);
        const url=`${urlLinks.baseUrl}question_controller/getWorkerJobQuestion?job_id=${job_id}&user_id=${user_id}`
        Axios.get(url).then(response=>{
            console.log(response.data);
            setRowData(row.cell.row.original)
            setGetQuestion(response.data.response);
        })
        setOpenSubmitResponseModel(true);
        console.log("edit open submit called");
        console.log(row);
    }


    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected,  state: {hiddenColumns, selectedRowIds,  sortBy} } = useTable({ columns, data, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true }, useFilters, useSortBy, useRowSelect)
    const ApplyFilter =() =>{
        let url='';
        if(filters["averageRating"])
            url=`${url}&averageRating=${filters["averageRating"]}`
        if(filters["actualScore"])
            url=`${url}&actualScore=${filters["actualScore"]}`
        if(filters["cutoffScore"])
            url=`${url}&cutoffScore=${filters["cutoffScore"]}`
        if(filters["expInYears"])
            url=`${url}&expInYrs=${filters["expInYears"]}`
        if(!filters["callStatus"].includes(""))
            url=`${url}&callStatus=${filters["callStatus"]}`
        if(!filters["approvalStatus"].includes(""))
            url=`${url}&approvalStatus=${filters["approvalStatus"]}`
        if(filters["totalGigs"])
            url=`${url}&totalGigs=${filters["totalGigs"]}`
        if(filters["userName"])
            url=`${url}&name=${filters["userName"]}`
        if(filters["phoneNumber"])
            url=`${url}&number=${filters["phoneNumber"]}`
        if(filters["gender"])
            url=`${url}&_gender=${filters["gender"]}` 
        if(Object.keys(filters["assignedTo"]).length>0){
                url=`${url}&recruiter_id=${filters["assignedTo"].id}`
            }
        if(filters["remark_status"].length!==0){
            let statusList = []
            filters["remark_status"].map(status => statusList.push(status.nameId))
            if(statusList.includes("skill_mismatch"))
                url=`${url}&skill_mismatch=1`
            if(statusList.includes("communication_issue"))
                url=`${url}&communication_issue=1`
            if(statusList.includes("low_salary") && filters["low_salary"])
                url=`${url}&low_salary=${filters.low_salary}`
            if(statusList.includes("preferred_job_location_id") && filters["preferred_job_location_id"].length!==0){
                let cityIds = []
                filters["preferred_job_location_id"].map(city => cityIds.push(city.id))
                url=`${url}&preferred_job_location_id=${cityIds}`
            }
            if(statusList.includes("preferred_job_type_id") && filters["preferred_job_type_id"].length!==0){
                let jobIds = []
                filters["preferred_job_type_id"].map(job => jobIds.push(job.id))
                url=`${url}&preferred_job_type_id=${jobIds}`
            }
        }   
        setFilterUrl(url)
    }
    const handleExternalFilters = (list) =>{
        setFilters({...filters, ...list})
    }
    useEffect(() => {
        ApplyFilter();
    }, [filters])

    const resetFilters=() =>{
        let tempFilters={
            averageRating: 0,
            actualScore: 0,
            cutoffScore: 0,
            expInYears: 0,
            callStatus:[""],
            approvalStatus:[""],
            assignedTo: {},
            totalGigs: 0,
            userName: '',
            phoneNumber: '',
            gender:"",
            remark_status:[],
            preferred_job_type_id:[],
            low_salary:0,
            preferred_location_id:[],
            skill_mismatch: null,
            communication_issue:null
        }
        setFilters(tempFilters)
        return tempFilters;
    }
    const ApplySort = () =>{
        let url=''
        if(sortBy.length>0){
            switch (sortBy[0].id) {
                case "workerId":
                    url='&orderBy=wm.worker_id'
                    break;
                case "userName":
                    url='&orderBy=um.first_name'
                    break;
                case "phoneNumber":
                    url='&orderBy=um.phone_number'
                    break;
                case "actualScore":
                    url='&orderBy=actual_score'
                    break;
                case "cutoffScore":
                    url='&orderBy=cutoff_score'
                    break;
                case "city":
                    url='&orderBy=city'
                    break;
                case "lastSeenDistance":
                    url='&orderBy=user_last_seen_distance'
                    break;
                case "distanceCapturedOn":
                    url='&orderBy=jar.distance_captured_on'
                    break;
                case "travelMode":
                    url='&orderBy=travel_mode'
                    break;
                case "jobType":
                    url='&orderBy=job_type'
                    break;
                case "lastJobExp":
                    url='&orderBy=last_job_exp'
                    break;
                case "expInYears":
                    url='&orderBy=exp_in_yrs'
                    break;
                case "averageRating":
                    url='&orderBy=wm.average_rating'
                    break;
                case "totalGigs":
                    url='&orderBy=wm.total_gigs'
                    break;
                case "breach":
                    url='&orderBy=wm.breach'
                    break;
                case "insertedOn":
                    url='&orderBy=jar.inserted_on'
                    break;
                case "breachCutOff":
                    url='&orderBy=breach_cut_off'
                    break;
                case "availabilityCutOff":
                    url='&orderBy=availability_cut_off'
                    break;
                case "distanceCutOff":
                    url='&orderBy=distance_cut_off'
                    break;
                case "genderCutOff":
                    url='&orderBy=gender_cut_off'
                    break;
                case "expCutOff":
                    url='&orderBy=exp_cut_off'
                    break;
                case "qualCutOff":
                    url='&orderBy=qual_cut_off'
                    break;
                case "negativeRatingCutOff":
                    url='&orderBy=negative_rating_cut_off'
                    break;
                default:
                    url=''
                    break;
            }
            if(!sortBy[0].desc)
                url=`${url}&sortBy=ascending`
            setSortUrl(url)
        }
        else
            setSortUrl('')
    }
    useEffect(() => {
        ApplySort();
    }, [sortBy])

    const idToUserId =() =>{
        console.log({selectedRowIds});
        const indexes= Object.keys(selectedRowIds)
        let idList=[]
        indexes.map(id => {
            idList.push(data[id].userId)
        })
        
        console.log(idList.toString())
        return idList;
    }
    useEffect(() => {
        let userIdList= []
        if(openAssignRecruiterModal || openAssignRecruiterJobModal)
            setUserIdList(idToUserId())
    }, [openAssignRecruiterModal, openAssignRecruiterJobModal])
    
    const matchCuttOff = (cuttOff) =>{
        const cuttOffNotations = {
            '-1': 'Disabled',
            '0': 'Failed',
            '1': 'Passed'
        };
        return cuttOffNotations[cuttOff];
    }

    const handlejobAssign =() =>{
        const recId=jobRecData.recruiterId?`&recruiter_id=${jobRecData.recruiterId}`:''
        Axios.post(`${urlLinks.baseUrl}extWorker/assignWorkerToRecruiter?job_id=${jobRecData.jobId}${recId}&user_ids=${userIdList}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Worker assigned to job successfully");
                } 
                else {
                    showToast("error","Worker assigned to job successfully") 
                }
            })
            .catch(error => {
                showToast("error",error.message) 
            })
    }
    
    const makeData= (response) =>{
        setCurrentJobId(response.content[0].jobId)
        let extractedData = [];
        let index= response.pageable.offset+1;
        response.content.map(el => {
            extractedData.push({
                ...el,
                no: index++,
                intExt:el.int_ext?el.int_ext:'',
                assignId: el.assignId === null ? '-' : el.assignId,
                recruiterName: el.recruiter_name,
                actualScore: el.actualScore === null ? '-' : el.actualScore,
                phoneNumber: el.phoneNumber,
                callStatus:  el.call_status?el.call_status:'',
                email: el.emailId?el.emailId:'',
                approvalStatus:  el.approval_status?el.approval_status:'',
                availabilityCutOff:
                    el.availabilityCutOff === null
                        ? '-'
                        : matchCuttOff(el.availabilityCutOff),
                breachCutOff:
                    el.breachCutOff === null ? '-' : matchCuttOff(el.breachCutOff),
                cutoffScore: el.cutoffScore === null ? '-' : el.cutoffScore,
                distanceCutOff:
                el.distanceCutOff === null
                    ? '-'
                    : matchCuttOff(el.distanceCutOff),
                expCutOff:
                    el.expCutOff === null ? '-' : matchCuttOff(el.expCutOff),
                genderCutOff:
                    el.genderCutOff === null ? '-' : matchCuttOff(el.genderCutOff),
                qualCutOff:
                    el.qualCutOff === null ? '-' : matchCuttOff(el.qualCutOff),
                insertedOn: el.insertedOn === null ? '-' : el.insertedOn,
                jobDetailsId: el.jobDetailId === null ? '-' : el.jobDetailId,
                jobId: el.jobId === null ? '-' : el.jobId,
                negativeRatingCutOff:
                    el.negativeRatingCutOff === null
                    ? '-'
                    : matchCuttOff(el.negativeRatingCutOff),
                userId: el.userId === null ? '-' : el.userId,
                workerId: el.workerId?el.int_ext==="INTERNAL"?el.workerId:`E-${el.workerId}`:'',
                userName: el.userName === null ? '-' :el.userName,
                recruiter_name:el.recruiter_name ? el.recruiter_name : '-' , 
                lastActivity: el.lastActivity === null ? '-' : el.lastActivity,
                address: el.address === null ? '-' : el.address,
                city: el.cityName === null ? 'Other' : el.cityName.toLowerCase().includes("delhi")?"Delhi":el.cityName,
                lastSeenDistance:
                    el.lastSeenDistance === null ? '-' : el.lastSeenDistance < 2500 ? el.lastSeenDistance : 'Not Captured',
                distanceCapturedOn:
                    el.distanceCapturedOn === null ? '-' : el.distanceCapturedOn,
                travelMode: el.travelMode === null ? '-' : el.travelMode,
                lastJobExp: el.lastJobExp === null ? '-' : el.lastJobExp,
                expInYears: el.expInYears === null ? '-' : el.expInYears,
                averageRating: el.averageRating === null ? '-' : el.averageRating,
                totalGigs: el.totalGigs === null ? '-' : el.totalGigs,
                breach: el.breach === null ? '-' : el.breach,
                remark_status: el.remark_status==="NOT_INTERESTED"?"Not interested" :el.remark_status==="NOT_APPROVED"?"Not approved":"None",
                low_salary: el.low_salary,
                preferred_job_type: el.preferred_job_type,
                preferred_job_type_id: el.preferred_job_type_id,
                preferred_location: el.preferred_location,
                preferred_location_id: el.preferred_location_id,
                submitResponse:el.question_submitted,
                other: el.other,
                skill_mismatch: el.skill_mismatch?"Yes":"No",
                communication_issue: el.communication_issue?"Yes":"No",
            });
        });
        return extractedData;
    }
    const getMatchedWorkerList =(rows, page) =>{
        if(props.data){

            const url=`${urlLinks.baseUrl}/job/assigner/assigned?job_details_id=${props.data.jobDetailsId}&job_id=${props.data.jobId}&page_no=${page-1}&rows=${rows}${filterUrl}${sortUrl}`
            Axios.get(url)
            .then(response =>{
                // console.log({response})
                if(response)
                setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    if(currentPage>response.data.response.totalPages)
                        setCurrentPage(totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
            })
            .catch(error =>{
                toast.error("No worker found")
                setLoading(false)
                
            })
        }
    }
    useEffect(() => {
        setLoading(true);
        getMatchedWorkerList(perPage,1);
    }, [filterUrl, sortUrl]);

    const handlePageChange = page => {
        setLoading(true)
        getMatchedWorkerList(perPage, page)
        setCurrentPage(page);
    };
    
    const handlePerRowsChange =  (newPerPage) => {
        setLoading(true)
        getMatchedWorkerList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    
    return (
        <Styles>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                autoClose={2000}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                >
            </ToastContainer>
            {openWorkerModal?<WorkerModal
                data={workerDetails}
                openWorkerModal={openWorkerModal}
                setOpenWorkerModal={setOpenWorkerModal}
                isWorkerHistory={false}
                interviewDashboardData= {{id: currentJobId, currenttab: props.currenttab, perPage: perPage, currentPage: currentPage, filters: filters}}
            />: null}
            {openExternalWorkerModal && userId && <ExternalWorkerModal
                userId={userId}
                openExternalWorkerModal={openExternalWorkerModal}
                setOpenExternalWorkerModal={setOpenExternalWorkerModal}
                interviewDashboardData= {{id: currentJobId, currenttab: props.currenttab, perPage: perPage, currentPage: currentPage, filters: filters}}
            />}
            {openSendLinkModal && <SendLinkModal
                openSendLinkModal= {openSendLinkModal} 
                setOpenSendLinkModal= {setOpenSendLinkModal}
                showToast={showToast}
                data={workerDetails}
            />
            }
            <Grid container className="displayFlex">
                <Grid item xs={6} style={{padding: "20px 0"}}>
                    <Button
                        variant='contained'
                        style={{backgroundColor: "#1c75bc !important" }}
                    >
                        <CSVLink
                        data={data}
                        filename={`MatchedWorker.csv`}
                        className='btn btn-primary'
                        target='_blank'
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            fontWeight: '500'
                        }}
                        >
                        Export To Excel
                        </CSVLink>
                    </Button>
                    <Button
                        variant="contained"
                        className="dataButton"
                        style={{marginLeft: "25px"}}
                        disabled={Object.keys(selectedRowIds).length===0}
                        onClick={() => setOpenAssignRecruiterModal(true)}
                    >
                        Assign to recruiter
                    </Button>
                </Grid>
                <Grid item xs={6} style={{padding: "20px 0", display: "flex", flexDirection: "row-reverse"}}>
                    <Filters 
                        filters={filters}
                        updateFilters={handleExternalFilters}
                        resetFilters={resetFilters}
                        isFilterApplied={isFilterApplied}
                        setIsFilterApplied={setIsFilterApplied}
                        page="Matched"
                    />
                    <Button
                        variant="contained"
                        className="dataButton"
                        style={{marginLeft: "25px"}}
                        disabled={Object.keys(selectedRowIds).length===0}
                        onClick={() => setOpenAssignRecruiterJobModal(true)}
                    >
                        Assign to Vacancy
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={ApplyFilter}
                        style={{ marginLeft: "20px"}}
                        >Apply
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={resetFilters}
                        style={{}}
                        >RESET FILTERS
                    </Button>
                </Grid>
                <Grid item xs={12} style={{padding:0}} >
                    <div className="tableContainer">
                        <table {...getTableProps()}  className="table">
                            <thead className="thead">
                                {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        // console.log({column}),
                                    <th {...column.getHeaderProps()} 
                                        className={column.render('Header')==="Worker Id"?"stickWorkerId zIndex1080 header":
                                            column.render('Header')==="Name"?"stickWorkerName zIndex1080 header":
                                                column.render('Header')==="Apply"?"stickApply zIndex1080 header":"header"}>
                                                <span {...column.getSortByToggleProps()}>
                                                    {column.id !== 'selection' ? 
                                                    <div>
                                                        {column.render('Header')}
                                                        <TableSortLabel
                                                            active={column.isSorted}
                                                            // react-table has a unsorted state which is not treated here
                                                            direction={column.isSortedDesc ? 'asc' : 'desc'}
                                                        />
                                                    </div> : null}
                                                </span>
                                                <div>{column.defaultCanFilter ? column.render('Filter') : null}</div></th>
                                        ))}
                                </tr>
                                ))}
                            </thead>
                            {
                                loading?
                                    <div style={{margin:"auto", position:"sticky", left:"45%", top: "30%"}}>
                                    <ScaleLoader
                                        height={70}
                                        width={10}
                                        color={"#1c75bc"}    
                                    />
                                    </div>:
                                    <tbody {...getTableBodyProps()}> 
                                        {rows.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="row">
                                                {row.cells.map(cell => {
                                                    return <td {...cell.getCellProps()}  
                                                        className={cell.column.Header==="Worker Id"?"stickWorkerId":
                                                        cell.column.Header==="Name"?"stickWorkerName":
                                                            cell.column.Header==="Apply"?"stickApply":"cell"}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        )
                                        })}
                                    </tbody>
                            }
                        </table>
                    </div>
                    <Pagination 
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid> 
            </Grid>
            {openAssignRecruiterModal?<AssignToRecruiterModal
                openAssignRecruiterModal={openAssignRecruiterModal}
                setOpenAssignRecruiterModal={setOpenAssignRecruiterModal}
                selectedRowIds={selectedRowIds}
                toggleAllRowsSelected={toggleAllRowsSelected}
                showToast={showToast}
                userIdList={userIdList}
                currentJobId={currentJobId}
                updateMyData={updateMyData}
            />:null}
            {openAssignRecruiterJobModal && <AssignVacancyThroughRecruiter
                openAssignRecruiterJobModal={openAssignRecruiterJobModal}
                setOpenAssignRecruiterJobModal ={setOpenAssignRecruiterJobModal}
                handlejobAssign ={handlejobAssign}
                setJobRecData={setJobRecData}
                jobRecData = {jobRecData}
            />}
            {openRemarkModal?<RemarkStatusModal
                openRemarkModal= {openRemarkModal}
                setOpenRemarkModal= {setOpenRemarkModal}
                values={statusRemarkData}
                />: null
            }
            {openSubmitResponseModel && getQuestion && <SubmitResponseModel
                openJobModal={openSubmitResponseModel}
                setOpenJobModal={setOpenSubmitResponseModel}
                questionValues={getQuestion}
                data={rowData}
                updateMyData={getMatchedWorkerList}
            />}
        </Styles>
    )
}
export default MatchedWorkers;