import React, { useState, useEffect, useMemo } from 'react'
import UploadExcel from '../../../utils/uploadExcel/uploadExcel'
import {Button, Grid, IconButton, Checkbox, TextField } from '@material-ui/core'
import Table from  '../../../utils/react-table/table'
import LoadingOverlay from 'react-loading-overlay';
import Autocomplete from '@material-ui/lab/Autocomplete';
import urlLinks from '../../../urlLinks'
import { Link } from "react-router-dom";
import Filters from './filterModal'
import WorkerModal from '../../Job/InterviewDashboard/JobTabs/functions/workerModal'
import Axios from 'axios'
import * as moment from 'moment'
import qs from 'querystring';
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from '../../../utils/SelectComponent/Select'
import { useDebounce } from "use-debounce";
import SendMessageModal from './SendMessageModal'
import '../externalWorker/externalWorker.css';
import { ToastContainer, toast } from 'react-toastify';
import ExternalWorkerModal from '../externalWorker/externalWorkerModal'
import ToggleButton from '@material-ui/lab/ToggleButton';
import { CSVLink } from 'react-csv';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MessageIcon from './noun_message_3666764.svg'

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <React.Fragment>
          <Checkbox ref={resolvedRef} {...rest} color= "primary" />
        </React.Fragment>
      )
    }
  )
  
  const useStyles = makeStyles((theme) => ({
    root: {
    "& .MuiOutlinedInput-root": {
        //  border:"1px solid grey",
        marginLeft:'0',
        "&.Mui-focused fieldset": {
        border: "1px solid grey"
        }
    }
    },
    input: {
    // padding:0,
    '& [class*="MuiOutlinedInput-input"]': {
        padding: "8px",
        borderLeft: "1px solid grey",
        borderRadius: "0px"
    }
    },
    select:{
        "& .MuiOutlinedInput-inputMarginDense":{
            padding: "8px 10px"
        }
    }
}));
const ExternalWorker =(props) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const [excelData, setExcelData] = useState([])
    const [openAssignRecruiterModal, setOpenAssignRecruiterModal] = useState(false)
    const [openAssignJobModal, setOpenAssignJobModal] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openMessageModal, setOpenMessageModal] = useState(false)
    const [openExternalWorkerModal, setOpenExternalWorkerModal] = useState(false)
    const [externalWorkerModalData, setExternalWorkerModalData] = useState({})
    const [openWorkerModal, setOpenWorkerModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState(0)
    const [totalRows, setTotalRows] = useState(0);
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [nameNumberSearch, setNameNumberSearch] = useState("")
    const [jobList, setJobList] = useState([]) 
    const [userId, setUserId] = useState()
    const [workerDetails, setWorkerDetails] = useState({})
    const [jobValue, setJobValue] = useState()  
    const [debouncedText] = useDebounce(nameNumberSearch, 350);
    const [openColumnModal, setOpenColumnModal] = useState(false)
    const [sortValue, setSortValue] = useState("")
    const [excelDataLoading, setExcelDataLoading] = useState(false)
    const [sortDirection, setSortDirection] = useState(false)
    const [rowIds, setRowIds] = useState([])
    const [sortDirectionUrl, setSortDirectionUrl] = useState('')
    const [sortUrl, setSortUrl] = useState("")
    const [filterUrl, setFilterUrl] = useState("")
    const [filters, setFilters] = useState({
        name:'',
        phoneNumber:'',
        jobRole:[],
        interests:[],
        yrsOfExp:[null, null],
        newCity:[],
        skills:[],
        preferred_location:[],
        assignedTo:{},
        ctc:[null, null],
        source:[""],
        gender:[""], 
        noticePeriod: "",
        singleSkill:"",
        multiSkill:""
    })
    const sortOptions= [
        ["added_on", "Added on"],
        ["last_activity", "Last activity"],
        ["name", "Name"],
        ["age", "Age"],
        ["qualification", "Qualification"],
        ["last_salary", "Last Salary"]
    ]

    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);
    }

    const ApplySort = () =>{
        let url=''
        switch (sortValue) {
            case "added_on":
                url='&orderBy=wm.inserted_on'
                break;
            case "last_activity":
                url='&orderBy=wm.updated_on'
                break;
            case "name":
                url='&orderBy=um.first_name'
                break;
            case "age":
                url='&orderBy=um.date_of_birth'
                break;
            case "qualification":
                url='&orderBy=wm.qualification_id'
                break;
            case "last_salary":
                url='&orderBy=wm.last_salary'
                break;
            default:
                url=''
                break;
        }
        // if(!sortBy[0].desc)
        //     url=`${url}&sortBy=ascending`
        setSortUrl(url)
    }
    useEffect(() => {
        ApplySort();
        setSortDirection(false)
        setSortDirectionUrl('')
    }, [sortValue])
    
    const getRecruiterName =(data)  =>{
        if(jobValue){
            const obj= data.jobDetailList.find( x => x.jobId === jobValue.jobId)
            console.log({jobValue, obj})
            if(obj.recruiter_name)
                return obj.recruiter_name;
            else
                return "Not assigned"
        }
        else{
            if(data?.jobDetailList?.length>0) {
                const name= data.jobDetailList.some(x => x.recruiter_name)
                if(name)
                    return "Assigned with recruiter"
                else 
                    return "Assigned without recruiter"
            }
            else 
                return "Not Assigned"
        }
    }

    const updateFilters= (filters) => {
        console.log({filters});
        
        let url='';
        
        if(filters["name"])
            url=`${url}&name=${filters["name"]}`
        if(filters["multiSkill"])
            url=`${url}&searchByMultiple=${filters["multiSkill"]}`
        if(filters["singleSkill"])
            url=`${url}&searchBySingle=${filters["singleSkill"]}`
        if(filters["phoneNumber"])
            url=`${url}&phoneNumber=${filters["phoneNumber"]}`
        if(filters["noticePeriod"])
            url=`${url}&noticePeriod=${filters["noticePeriod"]}`
        if(Object.keys(filters["assignedTo"]).length>0){
            url=`${url}&recruiter_id=${filters["assignedTo"].id}`
        }
        if(filters["newCity"].length!==0){
            let cityIds = []
            filters["newCity"].map(city => cityIds.push(city.id))
            url=`${url}&city_id=${cityIds}`
        }
        if(filters["preferred_location"].length!==0){
            let cityIds = []
            filters["preferred_location"].map(city => cityIds.push(city.id))
            url=`${url}&preferred_remark_location=${cityIds}`
        }
        if(filters["jobRole"].length!==0){
            let cityIds = []
            filters["jobRole"].map(city => cityIds.push(city.id))
            url=`${url}&job_type=${cityIds}`
        }
        if(filters["interests"].length!==0){
            let cityIds = []
            filters["interests"].map(city => cityIds.push(city.id))
            url=`${url}&interested_cat=${cityIds}`
        }
        if(filters["skills"].length!==0){
            let cityIds = []
            filters["skills"].map(city => cityIds.push(city.id))
            url=`${url}&skills=${cityIds}`
        }
        
        if(!filters["source"].includes(""))
            url=`${url}&source=${filters["source"]}`
        if(!filters["gender"].includes(""))
            url=`${url}&gender=${filters["gender"]}`        
        
        if(filters["yrsOfExp"][0] && !filters["yrsOfExp"][1])
            url=`${url}&experience_from=${filters["yrsOfExp"][0]}&experience_to=42`
        if(!filters["yrsOfExp"][0] && filters["yrsOfExp"][1])
            url=`${url}&experience_from=0&experience_to=${filters["yrsOfExp"][1]}`
        if(filters["yrsOfExp"][0] && filters["yrsOfExp"][1])
            url=`${url}&experience_from=${filters["yrsOfExp"][0]}&experience_to=${filters["yrsOfExp"][1]}`

        if(filters["ctc"][0] && !filters["ctc"][1])
            url=`${url}&last_salary_from=${filters["ctc"][0]}&last_salary_to=100000000}`
        if(!filters["ctc"][0] && filters["ctc"][1])
            url=`${url}&last_salary_from=0&last_salary_to=${filters["ctc"][1]}`
        if(filters["ctc"][0] && filters["ctc"][1])
            url=`${url}&last_salary_from=${filters["ctc"][0]}&last_salary_to=${filters["ctc"][1]}`
        setFilters(filters)
        setIsFilterApplied(true)
        setFilterUrl(url)
        setCurrentPage(1)
      }
    const resetFilters =() =>{
        const filterList={
            name:'',
            phoneNumber:'',
            jobRole:[],
            yrsOfExp:["", ""],
            interests:[],
            newCity:[],
            skills:[],
            preferred_location:[],
            ctc:["", ""],
            source:[""],
            gender:[""], 
            assignedTo:{},
            noticePeriod: "",
            singleSkill:"",
            multiSkill:""
        }
        setFilters(filterList)
        setFilterUrl('')
        setIsFilterApplied(false)
        setCurrentPage(1)
        return filterList;
    }
    const handleSortDirection =() =>{
        console.log({sortDirection})
        if(!sortDirection)
            setSortDirectionUrl("&sortBy=ascending");
        else
            setSortDirectionUrl("");
        setSortDirection(!sortDirection)

    }
    const handleEditWorker =(row) =>{

    }
    const handleWorkerDetail =(row) =>{
        setWorkerDetails({
            userId:row.row.original.userId,
            workerId:row.row.original.workerId
        })
        setOpenWorkerModal(true)
    }
    const columns = useMemo(
        () => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        {
            Header: "Worker Id",
            accessor: "workerId",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Name",
            accessor: "name",
            Cell:  row => {
               return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.name}</button>},
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Assigned to",
            accessor: "assignedTo",
            defaultCanFilter :false,
            defaultCanSort:false,
            isVisible:false

        },
        {
            Header: "Contact",
            accessor: "contact",
            Cell:  row => {
                return <Grid container className="padding0">
                        <Grid item xs={12} className="padding0"><span><b>Contact No: </b>{row.row.original.contactNumber}</span></Grid>
                        <Grid item xs={12} className="padding0"><span><b>WhatsApp : </b>{row.row.original.whatsappNumber}</span></Grid>
                    </Grid>},
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Email",
            accessor: "email",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Job role",
            accessor: "jobRole",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Designation",
            accessor: "designation",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Company",
            accessor: "company",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "CTC",
            accessor: "ctc",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Notice Period",
            accessor: "noticePeriod",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Location",
            accessor: "location",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Preferred Location",
            accessor: "preferredLocation",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Total Experience",
            accessor: "totalExperience",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Skills",
            accessor: "skills",
            Cell: row => {
                return <div style={{width:"250px"}}>{row.row.original.skills}</div>
            },
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Other skills",
            accessor: "otherSkills",
            Cell: row => {
                return <div style={{width:"250px"}}>{row.row.original.otherSkills}</div>
            },
            defaultCanFilter :false,
            defaultCanSort:false
        },
        {
            Header: "Interests",
            accessor: "interests",
            Cell: row => {
                return <div style={{width:"250px"}}>{row.row.original.interests}</div>
            },
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Gender",
            accessor: "gender",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Source",
            accessor: "source",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Onboarding Date",
            accessor: "onboardingDate",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Last Active on",
            accessor: "lastActiveOn",
            defaultCanFilter :false,
            defaultCanSort:false, 
            isVisible: true
        },
        {
            Header: "Edit worker",
            Cell: row => <Link
                            to={{
                                pathname: '/worker/newAddWorker', 
                                id: row.row.original.userId,
                                from: "internalWorker",
                                edit: true
                            }}
                        >
                            <Button
                                style={{marginTop: "24px"}}
                                color= "primary"
                                variant= "outlined"
                            >
                                Edit
                            </Button>
                        </Link>
        }
        ], 
        [handleEditWorker]
    );
    const HandleColumns =() =>{
        setOpenColumnModal(true)
    }
    
    const fixSkills = (data, type) => {
        let skills=[]
        console.log({data})
        if(data?.userSkills?.length>0){
          data.userSkills.map((skill, index) =>{
            if(type==="Show" && index>2)
                return skills
            skills.push(skill.skillsName)
          })
        }
        return skills
      }
      const fixExperience =(exp) =>{
        console.log({exp});
        if(exp==="Years,Months"){
            console.log("in "+exp);
            return null;
        }
        let numbersArray=[]
        let yrs="";
        let months=""
        if(exp){
            numbersArray= exp.match(/\d+/g);
            if(numbersArray){
                if(numbersArray[0]){
                    yrs= `${numbersArray[0]} yrs`
                }
                if(numbersArray[1]){
                    if(yrs){
                        months= `, ${numbersArray[1]} months`
                    }
                    else{
                        months= `${numbersArray[1]} months`
                    }
                }

            }
            return `${yrs}${months}`
        }
        else{
          return "";
        }
    }
    const FixSalary =(data) =>{
        
        if(data.lastSalary){
            console.log(data.expected_salary)
            if(data.last_salary_type===0){
                return `${numberFormat(data.lastSalary*12)}/yr`
            }
            else if(data.last_salary_type===1){
                return `${numberFormat(data.lastSalary)}/yr`
            }
            else {
                return `${numberFormat(data.lastSalary*12)}/yr`
            }
        }
        else if(data.expected_salary){
            console.log(data.expected_salary)
            if(data.expected_salary_type===0){
                return `${numberFormat(data.expected_salary*12)}/yr`
            }
            else if(data.expected_salary_type===1){
                return `${numberFormat(data.expected_salary)}/yr`
            }
        }
        else 
            return "";
    }

    const fixInterests = (data) =>{

    }

    const makeData= (response) =>{
        console.log({response})
        let extractedData = [];
        let lastupdatedon=[];
        let index= response.pageable.offset+1;
        response.content.map(el => {
            extractedData.push({
                ...el,
                no: index++,
                name: el.name,
                jobRole: el.jobType,
                assignedTo: getRecruiterName(el),
                company: el.company,
                ctc: FixSalary(el), //
                noticePeriod: el.noticePeriod,
                location: `${el.newCity}${el.newState?`, ${el.newState}`:""}`,
                preferredLocation: el.preferred_location, //
                totalExperience: fixExperience(el.total_experience),
                skills: fixSkills(el, "none").join(", "),
                skillsToShow: fixSkills(el, "Show").join(", "),
                otherSkills: el.skill? el.skill.replaceAll(",", ", "):"",
                interests: el.interested_cat_name,
                interestsToShow: fixInterests(el),
                gender: parseInt(el.gender)===26?"Male":parseInt(el.gender)===27?"Female":'N/a',
                onboardingDate: el.insertedOn?moment(el.insertedOn).format("DD/MM/YYYY"):"",
                lastActiveOn: el.lastUpdatedOn?moment(el.lastUpdatedOn).format("DD/MM/YYYY"):""
            });
            lastupdatedon.push(el.lastUpdatedOn)
        });
        console.log({lastupdatedon});
        
        return extractedData;
    }
    
    const convertJobIdtoHex=(val) =>{
        var s = "abcde" + val.toString(36);
        // console.log("called");
        return ""+s.substring(s.length - 5).toUpperCase();
    }
    const showToast = (type, message) =>{
        if(type==="success")
            return toast.success(message);
        else if(type==="error")
            return toast.error(message);
    }
    const updateMyData = () => {
        getExternalWorkerList(perPage, currentPage);
    }
    const makeJobList =(data) =>{
        let list =[];
        data.map(el =>{
            list.unshift({
                jobId: el.jobId,
                jobDetailId: el.jobDetailId,
                encryptId: el.jobDetailId? convertJobIdtoHex(el.jobDetailId):'',
                brandName:el.brandName? el.brandName: el.company?el.company:'',
                jobTitle: el.jobTitle?el.jobTitle: "",
                city: el.city_name?el.state_name==="Delhi"?"Delhi":el.city_name:'',
            })
        })
        return list;
    }
    const getExternalWorkerList =(rows, pageNo) =>{
        console.log({pageNo});
        setLoading(true)
        let jobUrl=""
        if(jobValue){
            jobUrl=`&job_id=${jobValue.jobId}`
        }
        Axios.get(`${urlLinks.baseUrl}admin/V2/workersdb?page_no=${pageNo-1}&rows=${rows}${jobUrl}${filterUrl}${sortUrl}${sortDirectionUrl}`)
            .then(response =>{
                if(response){
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    if(currentPage>response.data.response.totalPages)
                        setCurrentPage(totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }

            })
    }
    const fixOwnership =(data) =>{
        if(data==="1" || data==="true" || data===1){
            return  "Yes";
        }
        return "No"
    }
    const fixSalary= (salary, type) =>{
        let salaryText="0"
        let typeText=""
        if(salary){
            salaryText=salary;
        }
        if(type===0){
            typeText=" / month"
        }
        else if(type===1){
            typeText=" / year"
        }
        return `${salaryText}${typeText}`;
    }
    
    const fixNoticePeriod =(notice) =>{
        let numbersArray=[]
        if(notice){
            numbersArray= notice.match(/\d+/g);
        }
        console.log({numbersArray})
        if(numbersArray && numbersArray[0]){
            return `${numbersArray[0]} days`;
        }
        else{
            return "No Notice";
        }
    }
    const createExcelRow =(el) =>{
        console.log({el})
        return {
            "Worker ID": el.workerId?el.workerId:'',
            "Internal/External": el.int_ext?el.int_ext:'',
            "Name": el.name,
            "Phone No": el.contactNumber?el.contactNumber:'',
            "Whatsapp No": el.whatsappNumber?el.whatsappNumber:'',
            "Email id": el.email?el.email:'',
            "Gender": el.gender===26?"Male":el.gender===27?"Female":"",
            "Address": el.address?el.address:"",
            "City": el.city || "",
            "Preferred Location": el.preferred_location?el.preferred_location:"",
            "Highest Qualification": el.highestQualification || "",
            "Specialisation": el.specialisation || "",
            "Year of Passing": el.year_of_passing || "",
            "Ownership-Bike": fixOwnership(el.own_bike),
            "Ownership-License": fixOwnership(el.bike_license),
            "Ownership-Smartphone": fixOwnership(el.own_smartphone),
            "Ownership-Laptop": fixOwnership(el.own_laptop),
            "Ownership-WiFi": fixOwnership(el.own_wifi),
            "Ownership-Aadhar Card": fixOwnership(el.own_aadhar_card),
            "Ownership-Vehicle RC": fixOwnership(el.own_vehicle_rc),
            "Ownership-PAN card": fixOwnership(el.own_pan_card),
            "English Proficiency": el.english_known_level===1?"No English":el.english_known_level===2?"Thoda English":el.english_known_level===3?"Good English":"",
            "Languages Known": el.languages_known || "",
            "Total experience": fixExperience(el.total_experience),
            "Expected salary": fixSalary(el.expected_salary, el.expected_salary_type ),
            "Last Company": el.last_company || "",
            "Designation": el.designation || "",
            "Last Salary": fixSalary(el.lastSalary, el.last_salary_type ),
            "Notice Period": fixNoticePeriod(el.noticePeriod)
        }
    }
    const handleSMSTrigger =() =>{
        setOpenMessageModal(true)
    }
    const getUserIDs =() =>{
        let userIdList=[]
        rowIds.map(row =>{
            userIdList.push(data[row[0]].userId)
        })
        return userIdList;
    }
    const handleSendMessage = (jobData, messageModes) =>{
        let serviceModes=[];
        if(messageModes.sms)
            serviceModes.push("sms")
        if(messageModes.email)
            serviceModes.push("email")
        if(messageModes.notification)
            serviceModes.push("notification")
        let postData={
            inserted_by : parseInt(localStorage.getItem('userId')),
            jobDetailId: jobData.jobDetailId,
            jobId: jobData.jobId,
            serviceType: serviceModes.toString(),
            templateName : "download_app_and_apply_for_job",
            userId: getUserIDs().toString()
        }
        Axios.post(`${urlLinks.baseUrl}workermaster/sendMessage`, qs.stringify(postData))
            .then(response => {
                if(response.data.code ===1000){
                    showToast("success", "Message sent successfully")
                    updateMyData();
                    setOpenMessageModal(false)
                }
                else{
                    showToast("error", "Message not sent successfully")
                    setOpenMessageModal(false)
                }
            })
            .catch(error =>{
                console.log(error, "error")
                setOpenMessageModal(false)
                showToast("error", "Message not sent successfully")
            })
    }
    const getExportExcelList =() =>{
        let jobUrl=""
        if(jobValue){
            jobUrl=`&job_id=${jobValue.jobId}`
        }
        setExcelDataLoading(true)
        Axios.get(`${urlLinks.baseUrl}admin/V2/workersdb?page_no=${currentPage-1}&rows=${perPage}${jobUrl}${filterUrl}${sortUrl}${sortDirectionUrl}`)
            .then(response =>{
                if(response){
                    
                    console.log({response, rowIds})
                    const list=[];
                    rowIds.map(row =>{
                        console.log({row})
                        list.push(createExcelRow(response.data.response.content[row[0]]));
                    })
                    setExcelData(list)
                    console.log({list})
                    
                    setExcelDataLoading(false)
                }
                else{
                    console.log("falil")
                    setExcelDataLoading(false)
                    throw toast.error("No data from API")
                }
            })
            .catch(error =>{
                console.log({error})
                setExcelDataLoading(false)
                throw toast.error("No data from API")
            })
    }
    useEffect(() => {
        if(debouncedText.includes(",")){
            setFilters({...filters, multiSkill: debouncedText, singleSkill: "" })
        }
        else if(debouncedText){
            setFilters({...filters, singleSkill: debouncedText, multiSkill:"" })
        }
        else if(!debouncedText){
            setFilters({...filters, singleSkill: "", multiSkill:"" })
        }
        
        
    }, [debouncedText])
    useEffect(() => {
        updateFilters(filters)
        if(filters.singleSkill || filters.multiSkill){
            
        }
        else{
            setTimeout(() => {
                setIsFilterApplied(false)
                
            }, 300);
        }
    }, [filters.singleSkill, filters.multiSkill])
    useEffect(() => {
        getExternalWorkerList(perPage, 1);
        
    }, [filterUrl, sortUrl,sortDirectionUrl, jobValue])

    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}extWorker/getAllJobDetails`)
            .then(response =>{
                setJobList(makeJobList(response.data.response))
            })
    }, [])
    const handlePageChange = page => {
        getExternalWorkerList(perPage, page)
        console.log("changePAge", page)
        setCurrentPage(page);
    };
    
    const handlePerRowsChange =  (newPerPage) => {
        getExternalWorkerList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    console.log({jobList})

    return (
        <LoadingOverlay
            active={uploadLoading}
            spinner
            style={{position: "fixed"}}
            text={uploadLoading?'Excel is uploading...':''}
            >
            <Grid container >
                <ToastContainer
                    position='top-right'
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    autoClose={2000}
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                ></ToastContainer>
                {/* <Grid item xs={12} className="buttonList" alignItems='flex-start'>
                    
                    
                    <Grid item xs={7} style={{padding:0}} className="buttonList marginRightForChildExceptLast">
                        
                        <Button
                            variant="outlined"
                            className="filterButton marginRight20 marginLight20 paddingHorizontal"
                            onClick={HandleColumns}
                        >
                            Columns
                        </Button>
                        <Grid item className="padding0" xs={4} >
                            <Select 
                                options={sortOptions}
                                value={sortValue}
                                setValue={setSortValue}
                                label="Sort By:"
                            />
                        </Grid>
                        {sortValue?<ToggleButton
                            selected={sortDirection}
                            onChange={handleSortDirection}
                        >
                            {sortDirection?"A-Z":'Z-A'}
                        </ToggleButton>: null}
                        <Filters 
                            filters={filters}
                            updateFilters={updateFilters}
                            resetFilters={resetFilters}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                            jobValue={jobValue}
                        />  
                    </Grid>
                </Grid> */}
                <Grid item xs={12} className="buttonList" alignItems='center'>
                    <Grid item xs={8} className="flexDisplay padding0" direction ="row" justify="space-between">
                        <TextField
                            className={`${classes.root} margin0`}
                            placeholder="Search skill"
                            fullWidth
                            value={nameNumberSearch}
                            onChange={(event) => setNameNumberSearch(event.target.value)}
                            variant="outlined"
                            InputProps={{
                            className: classes.input,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                            }}
                        />
                        <IconButton 
                            className="skillCLoseButton"
                            onClick={() =>{setIsFilterApplied(false); setNameNumberSearch("");}}
                        >
                            <CloseSharpIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} className="buttonList padding0" alignItems='flex-start'>
                        <Filters 
                            filters={filters}
                            updateFilters={updateFilters}
                            resetFilters={resetFilters}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                            jobValue={jobValue}
                        />  
                    </Grid>
                    <Grid item xs={3} className="buttonList paddingTop20Other0" alignItems='flex-start'>
                        <Select 
                            options={sortOptions}
                            value={sortValue}
                            setValue={setSortValue}
                            label="Sort By:"
                        />
                        {sortValue?<ToggleButton
                            selected={sortDirection}
                            onChange={handleSortDirection}
                        >
                            {sortDirection?"A-Z":'Z-A'}
                        </ToggleButton>: null}
                    </Grid>
                    
                </Grid>
                <Grid container>
                    <Grid item xs={8} className="buttonList padding0" alignItems='flex-start'>
                        <span className="fontSize16 fontWeightBold">Select Vacancy:</span>
                        <Autocomplete
                            id="jobList"
                            options={jobList}
                            value={jobValue}
                            onChange={(event, value) => {
                                setFilters({...filters, assignedTo:{}})
                                setJobValue(value)
                            }}
                            style={{ width: "450px", margin: "0 50px 0 30px"}}
                            getOptionLabel={(option) => `${option.encryptId} - ${option.brandName} - ${option.jobTitle} - ${option.city}`}
                            renderInput={(params) => <TextField {...params}  className="margin0 borderLightGrey "/>}
                        />
                        
                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled={(selectedRows===0 || !jobValue)}
                            onClick={() => setOpenAssignRecruiterModal(true)}
                        >
                            Assign to recruiter
                        </Button>
                        <Button
                            variant="contained"
                            className="dataButton"
                            disabled={selectedRows===0}
                            onClick={() => setOpenAssignJobModal(true)}
                        >
                            Assign to job
                        </Button>
                    </Grid>
                    <Grid item xs={4} className="buttonList padding0" alignItems='flex-end'>
                        <Button
                            variant="contained"
                            style={{marginLeft: "20px"}}
                            className="dataButton"
                            onClick={getExportExcelList}
                            disabled={excelDataLoading || rowIds.length===0}
                        >
                            Export to Excel
                        </Button>
                        {excelDataLoading?
                            <span style={{marginLeft: "20px"}}>Please wait ... it might take few minutes. </span>
                            :excelData.length>0?
                            <CSVLink
                                data={excelData}
                                filename={`Worker List.csv`}
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    marginLeft:"20px",
                                    fontWeight: '500',
                                    color: "#1c75bc",
                                    textDecoration: "underline"
                                }}
                            >
                                Download now
                            </CSVLink>
                        :null}   
                        <Button
                            variant="contained"
                            style={{marginLeft: "20px"}}
                            className="dataButton"
                            onClick={handleSMSTrigger}
                            disabled={selectedRows===0}
                            startIcon={<img src={MessageIcon} alt="messageIcon" />}
                        >
                            SEND MESSAGE
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} className= "padding0">
                    <Table
                        perPage={perPage}
                        currentPage={currentPage}
                        totalRows={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                        columns={columns}
                        data={data}
                        isPagination={true}
                        jobValue= {jobValue}
                        openAssignJobModal={openAssignJobModal} 
                        setOpenAssignJobModal ={setOpenAssignJobModal}
                        openAssignRecruiterModal= {openAssignRecruiterModal} 
                        setOpenAssignRecruiterModal = {setOpenAssignRecruiterModal}
                        jobList={jobList}
                        loading={loading}
                        rowIds={rowIds} 
                        setRowIds={setRowIds}
                        updateMyData = {updateMyData}
                        table= "internalWorker"
                        setLoading={setLoading}
                        showToast={showToast}
                        openColumnModal={openColumnModal}
                        setSelectedRows={setSelectedRows}
                        setOpenColumnModal={setOpenColumnModal}
                    />
                </Grid>
                {openExternalWorkerModal?<ExternalWorkerModal
                    openExternalWorkerModal={openExternalWorkerModal}
                    setOpenExternalWorkerModal={setOpenExternalWorkerModal}
                    userId={userId}
                />:null}
                {openWorkerModal?<WorkerModal
                    data={workerDetails}
                    openWorkerModal={openWorkerModal}
                    setOpenWorkerModal={setOpenWorkerModal}
                    isWorkerHistory={false}
                    from="internalWorker"
                />: null}
                {
                    openMessageModal? <SendMessageModal
                        vacancyList={jobList}
                        open={openMessageModal}
                        setOpen={setOpenMessageModal}
                        handleSendMessage={handleSendMessage}
                    />: null
                }
            </Grid>
        </LoadingOverlay>
    )
}

export default ExternalWorker;


