import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Popover, Button ,Divider , Grid, IconButton, Badge } from '@material-ui/core';
import Axios from 'axios' 
import SelectFilter from '../../utils/filters/SelectFilter'
import InputRangeFilter from '../../utils/filters/InputRangeFilter'
import SliderFilter from '../../utils/filters/SliderFilter'
import DateFilter from '../../utils/filters/DateFilter'
import LocationFilter from '../../utils/filters/locationFilter'
import GroupedSelectFilter from '../../utils/filters/groupedSelectFilter'
import SelectInput from '../RecruiterDB/RecrutierDBElements/Select'
import InputTextFilter from '../../utils/filters/inputTextFilter' 
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover(props) {
    const classes = useStyles();
    const {currentTab, isFilterApplied, setIsFilterApplied, companyList, managerList, recruiterList} = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = useState(props.filters[currentTab])
    const [jobRoleList, setJobRoleList] = useState([])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    console.log(props.filters[currentTab])
    useEffect(() => {
        setFilters(props.filters[currentTab])
    }, [currentTab])
    console.log({filters})
    console.log({props})
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleResetFilters =() =>{
        const newFilters=props.resetFilters()
        setFilters(newFilters[currentTab]);
        setIsFilterApplied(false)
        
    }
    const handleApplyFilters =() =>{
        let temp=props.filters
        temp[currentTab]=filters
        props.updateFilters([...temp]);
        handleClose();
    }
    const handleCancelFilters =() =>{
        setFilters(props.filters[currentTab]);
        handleClose();
    }
    const makeJobRoleData= (data)  =>{
        let list=[];
        data.map(el => 
            list.push({
                id: el.id,
                name: el.categorySubType
            })
        )
        return list;
    }
    useEffect(() => {
        Axios.get("https://qaapi.okaygo.in//configmaster/?category_type=job_type&inserted_by=0&rows=100&type_desc=job_type_name")
            .then(response =>{
                setJobRoleList(makeJobRoleData(response.data.response.content))
            })
    }, [])
    let popoverData= <Grid></Grid>
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    popoverData= 
        currentTab===0?
            <Grid container style={{width:"350px", display:"flex", flexDirection:"row"}}>
                <Grid item xs={12} className="padding20">
                    <InputTextFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Referral id"
                        id="referral_id"
                    />
                </Grid>
                <Grid item xs={12} className="padding20">
                    <InputTextFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Candidate name"
                        id="name"
                    />
                </Grid>
                <Grid item xs={12} className="padding20">
                    <InputTextFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Candidate number"
                        id="number"
                    />
                </Grid>
                <Grid item xs={12} className="padding20">
                    <InputTextFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Referrer name"
                        id="referred_by_name"
                    />
                </Grid>
                <Grid item xs={12} className="padding20">
                    <InputTextFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Referrer number"
                        id="referred_by_number"
                    />
                </Grid>
                <Grid item sm className="padding20" style={{marginLeft: "20px"}}>
                    <LocationFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Location"
                        id="location"
                    />
                </Grid>
                <Grid item sm className="padding20">
                    <GroupedSelectFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Job role"
                        id="jobRole"
                        data={jobRoleList}
                    />
                </Grid>
                <Grid item sm className="padding20">
                    <GroupedSelectFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Recruiter name"
                        id="recruiter_name"
                        data={recruiterList}
                    />
                </Grid>
                <Grid item sm className="padding20">
                    <GroupedSelectFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Company"
                        id="brand_name"
                        data={companyList}
                    />
                </Grid>
                <Grid item sm className="padding20">                
                    <GroupedSelectFilter
                        filters={filters}
                        setFilters={setFilters}
                        label="Account Manager"
                        id="account_manager"
                        data={managerList}
                    />
                </Grid>
                
                <Grid item xs={12} className="padding20">
                    <span style={{marginLeft: "15px"}}>Joining date</span>
                    <DateFilter
                        filters={filters}
                        setFilters={setFilters}
                        labelTo="To"
                        labelFrom="From"
                        id="joining_date"
                    />
                </Grid>

                <Grid item xs={12} className="padding20">
                    <span style={{marginLeft: "15px"}}>Referred on date</span>
                    <DateFilter
                        filters={filters}
                        setFilters={setFilters}
                        labelTo="To"
                        labelFrom="From"
                        id="referred_on"
                    />
                </Grid>
                {/* <Grid item sm className="padding20">
                    <span style={{marginLeft: "15px"}}>Referral amount</span>
                    <InputRangeFilter
                        filters={filters}
                        setFilters={setFilters}
                        labelTo="To"
                        labelFrom="From"
                        id="referral_amount"
                    />
                </Grid> */}
                
            </Grid>:
            currentTab===1?
                <Grid container style={{width:"350px", display:"flex", flexDirection:"row"}}>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Referral id"
                            id="referral_id"
                        />
                    </Grid>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Candidate name"
                            id="name"
                        />
                    </Grid>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Candidate number"
                            id="number"
                        />
                    </Grid>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Referrer name"
                            id="referred_by_name"
                        />
                    </Grid>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Referrer number"
                            id="referred_by_number"
                        />
                    </Grid>
                    <Grid item sm className="padding20" style={{marginLeft: "20px"}}>
                        <LocationFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Location"
                            id="location"
                        />
                    </Grid>
                    <Grid item sm className="padding20">
                        <GroupedSelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Job role"
                            id="jobRole"
                            data={jobRoleList}
                        />
                    </Grid>
                    <Grid item sm className="padding20">
                        <GroupedSelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Recruiter name"
                            id="recruiter_name"
                            data={recruiterList}
                        />
                    </Grid>
                    <Grid item sm className="padding20">
                        <SelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Approval status"
                            id="referralApprovalStatus"
                        />
                    </Grid>
                    <Grid item sm className="padding20">
                        <GroupedSelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Company"
                            id="brand_name"
                            data={companyList}
                        />
                    </Grid>
                    <Grid item sm className="padding20">                
                        <GroupedSelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Account Manager"
                            id="account_manager"
                            data={managerList}
                        />
                    </Grid>
                    
                    <Grid item xs={12} className="padding20">
                        <span style={{marginLeft: "15px"}}>Joining date</span>
                        <DateFilter
                            filters={filters}
                            setFilters={setFilters}
                            labelTo="To"
                            labelFrom="From"
                            id="joining_date"
                        />
                    </Grid>

                    <Grid item xs={12} className="padding20">
                        <span style={{marginLeft: "15px"}}>Referred on date</span>
                        <DateFilter
                            filters={filters}
                            setFilters={setFilters}
                            labelTo="To"
                            labelFrom="From"
                            id="referred_on"
                        />
                    </Grid>
                    {/* <Grid item sm className="padding20">
                        <span style={{marginLeft: "15px"}}>Referral amount</span>
                        <InputRangeFilter
                            filters={filters}
                            setFilters={setFilters}
                            labelTo="To"
                            labelFrom="From"
                            id="referral_amount"
                        />
                    </Grid> */}
                    
                </Grid>
            :
                <Grid container style={{width:"350px", display:"flex", flexDirection:"row"}}>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Referral id"
                            id="referral_id"
                        />
                    </Grid>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Candidate name"
                            id="name"
                        />
                    </Grid>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Candidate number"
                            id="number"
                        />
                    </Grid>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Referrer name"
                            id="referred_by_name"
                        />
                    </Grid>
                    <Grid item xs={12} className="padding20">
                        <InputTextFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Referrer number"
                            id="referred_by_number"
                        />
                    </Grid>
                    <Grid item sm className="padding20" style={{marginLeft: "20px"}}>
                        <LocationFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Location"
                            id="location"
                        />
                    </Grid>
                    <Grid item sm className="padding20">
                        <GroupedSelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Job role"
                            id="jobRole"
                            data={jobRoleList}
                        />
                    </Grid>
                    <Grid item sm className="padding20">
                        <GroupedSelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Recruiter name"
                            id="recruiter_name"
                            data={recruiterList}
                        />
                    </Grid>
                    <Grid item sm className="padding20">
                        <GroupedSelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Company"
                            id="brand_name"
                            data={companyList}
                        />
                    </Grid>
                    <Grid item sm className="padding20">                
                        <GroupedSelectFilter
                            filters={filters}
                            setFilters={setFilters}
                            label="Account Manager"
                            id="account_manager"
                            data={managerList}
                        />
                    </Grid>
                    
                    <Grid item xs={12} className="padding20">
                        <span style={{marginLeft: "15px"}}>Joining date</span>
                        <DateFilter
                            filters={filters}
                            setFilters={setFilters}
                            labelTo="To"
                            labelFrom="From"
                            id="joining_date"
                        />
                    </Grid>

                    <Grid item xs={12} className="padding20">
                        <span style={{marginLeft: "15px"}}>Referred on date</span>
                        <DateFilter
                            filters={filters}
                            setFilters={setFilters}
                            labelTo="To"
                            labelFrom="From"
                            id="referred_on"
                        />
                    </Grid>
                     <Grid item xs={12} className="padding20">
                        <span style={{marginLeft: "15px"}}>Transaction date</span>
                        <DateFilter
                            filters={filters}
                            setFilters={setFilters}
                            labelTo="To"
                            labelFrom="From"
                            id="transaction_date"
                        />
                    </Grid>
                    {/* <Grid item sm className="padding20">
                        <span style={{marginLeft: "15px"}}>Referral amount</span>
                        <InputRangeFilter
                            filters={filters}
                            setFilters={setFilters}
                            labelTo="To"
                            labelFrom="From"
                            id="referral_amount"
                        />
                    </Grid> */}
                    
                </Grid>
        
    return (
        <Grid container className="buttonList" style={{width:"350px", marginTop:"40px"}}>
            <Grid item xs={5} className="padding0">
                <span className= "FilterHeading">Filters</span>
            </Grid>
            <Grid item xs={7}  className="flexRowReverse padding0">      
                <Button 
                    className= "dataButton"
                    style={{marginLeft: "30px"}}
                    onClick={handleResetFilters}
                >
                    Reset
                </Button>
            </Grid>
            <Divider />
                {popoverData}
            <Divider />
            <Grid container style={{padding: "20px"}}>      
                <Button 
                    className= "dataButton"
                    style={{marginLeft: "30px"}}
                    onClick={handleApplyFilters}
                >
                    Apply
                </Button>
                <Button 
                    className= "dataButton"
                    onClick={handleCancelFilters}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );
}
