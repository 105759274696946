import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CSVLink } from 'react-csv';
import Download from './Group 3238.svg'
import Typography from "@material-ui/core/Typography";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from './index.module.scss'
import UrlLinks from "../../urlLinks";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {openResponseModal, setOpenResponseModal,  setOpenOnboardingModal, responseData , type,  invalidData} = props
  const handleClose = () => {
    setOpenResponseModal(false);
    setOpenOnboardingModal(false);
  };
  // console.log({invalidData})
  // console.log({responseData})
  const inv=invalidData===undefined?0:invalidData.length
  let dialogData=
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openResponseModal}
            className={Styles.onboardingDialog}
            classes={{paper: Styles.paperResponse}}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <Grid item xs={12} style={{paddingTop: "5px",paddingBottom: "5px"}}>
              <span className={Styles.summaryText} >Summary of uploaded data</span>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              <Grid item xs={12} className="padding0">
                <span className={Styles.mainRecord}>{`# Total records attempted to upload : ${responseData["total_records_attempted"]}`}</span>
              </Grid>
              {responseData && <Grid item xs={12} className="padding0 marginTop16">
                <Grid container className="padding0 marginTop16">
                  <table className="tableContainer">
               
                    {responseData.hasOwnProperty("invalid_records") && type==="Onboarding" &&
                      <tr>
                        <td># Invalid data records</td>
                        <td>{inv}</td>
                      </tr>
                    }
                    
          
                    {responseData.hasOwnProperty("records_uploaded")  &&
                      <tr>
                        <td># Total records uploaded</td>
                        <td>{responseData["records_uploaded"]}</td>
                      </tr>
                    }
          
                  </table>
                </Grid>
                {invalidData && <Grid item xs={12} className="padding0" style={{marginTop:"20px"}}>
                  <CSVLink
                    data={invalidData}
                    filename={"Invalid Bulk upload data.csv"}
                    className='btn btn-primary blueColor downloadExcelButton'
                    target='_blank'
                    style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontWeight: '500'
                    }}
                  >
                  <span style={{marginRight: "10px"}}>Download invalid data</span><img src={Download} alt="download" />
                  </CSVLink>
                </Grid>}
              </Grid>}
            </DialogContent>
            <DialogActions>
              {responseData && <Button variant="contained" onClick={() =>{setOpenOnboardingModal(false); setOpenResponseModal(false);}} className={"blueButtonContained"}>
                  Done
              </Button>}
            </DialogActions>
        </Dialog>
  
  

  return dialogData; 
  
}
