import React, { Component } from 'react';

class EmployerPayment extends Component {

render(){
    return(
        <div>Payment Page</div>
    )
}
}
 
export default EmployerPayment;