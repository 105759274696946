import React, { Component } from 'react';
import Constant from '../../../constant';
import InActiveEmployerUtils from './InactiveEmployerConstant';
import { Card, Grid } from '@material-ui/core';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../../utils/CustomFilter/CustomFilter';
import CustomTable from "../../../utils/Table/Table";
class InActiveEmployer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            filters: InActiveEmployerUtils.inactiveEmployerFilterList,
            currentPage: 0,
            count:0,
            rowsPerPage:10,
            isTableUpdating:false,
            column: [{
                id: "1",
                name: "employerId",
                label: Constant.employerInactive.employerId,
                isColumnVisible: true
            }, {
                id: "2",
                name: "company_name",
                label: Constant.employerInactive.company_name,
                isColumnVisible: true
            }, {
                id: "3",
                name: "industry",
                label: Constant.employerInactive.industry,
                isColumnVisible: true
            }
                , {
                id: "4",
                name: "location",
                label: Constant.employerInactive.location,
                isColumnVisible: true
            },
            {
                id: "5",
                name: "contact",
                label: Constant.employerInactive.company_name,
                isColumnVisible: true

            },
            {
                id: "6",
                name: "phone_number",
                label: Constant.employerInactive.phone_number,
                isColumnVisible: true
            }, {
                id: "7",
                name: "email",
                label: Constant.employerInactive.email,
                isColumnVisible: true
            }, {
                id: "8",
                name: "last_status",
                label: Constant.employerInactive.last_status,
                isColumnVisible: true
            }, {
                id: "9",
                name: "last_activity",
                label: Constant.employerInactive.last_activity,
                isColumnVisible: true
            },{
                id: "10",
                name: "review",
                label: Constant.employerInactive.review,
                isColumnVisible: true
            },
             {
                id: "11",
                name: "last_comments",
                label: Constant.employerInactive.last_comments,
                isColumnVisible: true
            },
            {
                id: "12",
                name: "action",
                label: Constant.employerInactive.action,
                isColumnVisible: true
            }]
        }
        this.data = [
            {
                   "employerId":"E_001",
                   "company_name":"McDonald's",
                   "industry":"Restaurant",
                   "location":"Delhi",
                   "contact":"Himanshu",
                   'phone_number':'9876543201',
                   "email":"abc@gmail.com",
                   "last_status":"Regstered",
                   "last_activity":"01/12/2019",
                   "review":"Open",
                   "last_comments":"By Swami Sagar On 12/11/2019 Lorem Ipsum",
                   "action":"Action"
                
            },
            {
            "employerId":"E_002",
            "company_name":"Sushi Junction",
            "industry":"Restaurant",
            "location":"Delhi",
            "contact":"Himanshu",
            'phone_number':'9876543201',
            "email":"abc@gmail.com",
            "last_status":"Regstered",
            "last_activity":"01/12/2019",
            "review":"Open",
            "last_comments":"By Swami Sagar On 12/11/2019 Lorem Ipsum",
            "action":"Action"

            },
            {

                "employerId":"E_003",
                "company_name":"Swiggy",
                "industry":"Restaurant",
                "location":"Delhi",
                "contact":"Himanshu",
                'phone_number':'9876543201',
                "email":"abc@gmail.com",
                "last_status":"Regstered",
                "last_activity":"01/12/2019",
                "review":"Open",
                "last_comments":"By Swami Sagar On 12/11/2019 Lorem Ipsum",
                "action":"Action"
            }
        ];


        
        this.rowsOptions=[5,10,25,50,100,200,500];
        this.updateColumnFileds = this.updateColumnFileds.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateDataOnTablePagination =this.updateDataOnTablePagination.bind(this);

    }
    updateColumnFileds(columnsToHide) {
        const upadtedColumns = this.state.column.map((col) => {
            if (columnsToHide.includes(col.id)) {
                col.isColumnVisible = true;
            } else {
                col.isColumnVisible = false;
            }
            return (col);
        })

        this.setState({
            columns: upadtedColumns,
        })
    }
    async updateFilters(recievedFilters) {

        const updatedFilter = this.state.filters.map((filter) => {
            for (let el of recievedFilters) {
                if (el[filter.name]) {
                    filter.default = el[filter.name]
                }
            }
            return filter;
        })
        await this.setState({
            filters: updatedFilter
        })

    }
    updateDataOnTablePagination(rowsPerPage=this.state.rowsPerPage,nextPage=this.state.currentPage){
        //to do
     
    }

    componentDidMount() {
        // Axios.get("http://localhost:3001/employers").then(
        //     (response) => { 
        //         this.setState({
        //             employer: response.data,

        //         });
        //     }
        // ).catch(
        //     (error) => { console.log(error) }
        // );
        this.setState({ list: this.data });

    }
    render() {
        const tablePagination = {
            currentPage:this.state.currentPage,
            count:this.state.count,
            changePage:this.updateDataOnTablePagination,
            rowsOptions:this.rowsOptions,
            rowsPerPage:this.state.rowsPerPage,
            tableUpdating:this.state.isTableUpdating
        }
        return (
            <div>
                <Card style={{ margin: "20px 0px", padding: "20px" }}>
                    <Grid container >
                        <Grid item sm={3}>
                            <HideUnhideColumns columns={this.state.column} toggleColumn={this.updateColumnFileds}></HideUnhideColumns>
                        </Grid>
                        <Grid item sm={3}>
                            <CustomFilter filters={this.state.filters} updateFilters={this.updateFilters}></CustomFilter>
                        </Grid>
                    </Grid>
                </Card>
                <CustomTable name={Constant.employerInactive.idName}columns={this.state.column} data={this.state.list} links={InActiveEmployerUtils.inActiveEmployerListAction} title={InActiveEmployerUtils.title} tablePagination={tablePagination}></CustomTable>
            </div>

        );
    }
}
 
export default InActiveEmployer;