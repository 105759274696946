import React, {useState, useEffect} from 'react'
import { Grid, TextField, IconButton } from '@material-ui/core'
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Axios from 'axios';
import qs from 'querystring';
import urlLinks from '../../urlLinks';
const EditableRemark = (props) => {
    const {row, showToast, currentTab, updateMyData}= props
    console.log({currentTab})
    const [value, setValue] = useState(row.original.remarks)
    const initialValue=row.original.remarks
    const [showButtons, setShowButtons] = useState(false)
    const handleRemarkChange =(event) =>{
        if(showButtons===false){
            setShowButtons(true);
        }
        setValue(event.target.value)
    }
    const handleRemarksConfirm =() =>{
        if(currentTab!==0){
            const postData={
                inserted_by: localStorage.getItem("userId"),
                job_id: row.original.jobId,
                refer_by: row.original.referredById  ,
                remark: value,
                user_id: row.original.userId
            }
            Axios.post(`${urlLinks.baseUrl}referral/addRemark`, qs.stringify(postData))
                .then(response =>{
                    if(response.data.code===1000){
                        updateMyData();
                        showToast("success", "Remark added successfully")
                    }
                    else {
                        showToast("error", "Remark failed")
                    }
                    setShowButtons(false)
                })
        }
        else{
            const postData={
                updated_by: localStorage.getItem('userId'),
                assign_id: row.original.assignId,
                field: "remark",
                fieldStatus: value,
                user_id: row.original.userId,
                worker_id: row.original.workerId,
                job_details_id: row.original.jobDetailsId,
                job_id: row.original.jobId
            }
            Axios.post(`${urlLinks.baseUrl}job/assigner/updateStatus`, qs.stringify(postData))
                .then(response =>{
                     if (response.data.code === 1000) {
                        updateMyData();
                        setShowButtons(false)
                    } else {
                        throw showToast("error", response.data.message);
                    }
                })
        }   
        
    }
    const handleCancel =() =>{
        setShowButtons(false)
        setValue(initialValue)
    }
    return (
        <Grid container>
            <Grid item xs={12} className="padding0">
                <TextField
                    value={value}
                    // style={{padding:"8px"}}
                    multiline
                    InputProps={{
                        style:{padding:"8px"}
                    }}
                    onChange={handleRemarkChange}
                    variant="outlined"
                />
            </Grid>
            {showButtons && <Grid item xs={12} className="padding0">
                <IconButton color="primary" onClick={handleRemarksConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </Grid>}
        </Grid>
    )
}

export default EditableRemark
