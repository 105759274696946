import React, { Component } from 'react';
import Constant from '../../../constant';
import { Card, Grid } from '@material-ui/core';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
import ODJobUtils from '../../Job/ODJob/OdJobConstant';
// import CustomFilter from '../../../utils/CustomFilter/CustomFilter';
import { RingLoader } from 'react-spinners';
import FilterBase from '../../../utils/filters/filterbase'
import * as moment from 'moment'
import ExpandableTable from '../../../utils/ExpandableTable/ExpandableTable';
import Axios from 'axios';
import ToggleButton from '@material-ui/lab/ToggleButton';
import urlLinks from '../../../urlLinks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from '../../../utils/simpleSelect/nativeSelect';
import { withStyles } from '@material-ui/core/styles';



const style = {
  SearchBar: {
    marginTop: '-2%',
    marginLeft: '10%'
  },
  select:{
    marginTop:'-2%',
    marginLeft:'2%'
  }
};

class ODJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type:'',
      label:'',
      eValue:'',
      type1:'',
      label1:'',
      eValue1:'ascending',
      uiLabel:'ByPera',
      uiLabel1:'ByDir',
      sortAccessed: false,
      sortingDirection: true,
      open: false,
      list: [],
      companyList:[],
      jobTypeList:[],
      filterUrl:'',
      filters: {
        brandName: [],
        location:[],
        jobType: [],
        jobStatus: [""],
        addedBy: [""],
        workType: [""],
        jobPostDate:[null, null],
        jobDurationDate:[null, null]
      },
      isLoading: true,
      url: `${urlLinks.baseUrl}/job/V2/getdetailedjob?work_type=3`,
      isTableUpdating: false,
      paramsAvailable: false,
      rowsOptions: [5, 10, 50, 100, 250, 500],
      // filters: ODJobUtils.odJobFilterList,
      cols: [
        //     {
        //     id: "1",
        //     label: Constant.ODJob.urgency,
        //     name: "urgency",
        //     isColumnVisible: true
        // },
        
        {
          id: '2',
          name: 'jobId',
          label: Constant.ODJob.job_id,
          isColumnVisible: true
        },
        {
          id: '3',
          name: 'jobDetailsId',
          label: Constant.ODJob.job_detailed_id,
          isColumnVisible: true
        },
        {
          id: '30',
          name: 'encryptId',
          label: Constant.ptftJob.enjobId,
          isColumnVisible: true
        },
        {
          id: '4',
          name: 'companyId',
          label: Constant.ODJob.brand_name,
          isColumnVisible: true
        },
        {
          id:'89',
          name:'contactPerson',
          label:Constant.ODJob.contactPerson,
          isColumnVisible:true
        },
        {
          id: '5',
          name: 'insertedOn',
          label: Constant.ODJob.posted_on,
          isColumnVisible: true
        },
        {
          id: '6',
          name: 'startDate',
          label: Constant.ODJob.job_date,
          isColumnVisible: true
        },
        {
          id: '250',
          name: 'endDate',
          label: Constant.ODJob.end_date,
          isColumnVisible: true
        },
        {
          id: '7',
          name: 'loginTime',
          label: Constant.ODJob.start_time,
          isColumnVisible: true
        },
        {
          id: '8',
          name: 'logoutTime',
          label: Constant.ODJob.end_time,
          isColumnVisible: true
        },
        {
          id:'565',
          name:'city',
          label:Constant.ODJob.city,
          isColumnVisible:true
        },
        {
          id: '9',
          name: 'address',
          label: Constant.ODJob.job_location,
          isColumnVisible: true
        },
        {
          id: '10',
          name: 'title',
          label: Constant.ODJob.title,
          isColumnVisible: true
        },
        {
          id: '108',
          label: Constant.ODJob.gender,
          name: 'gender',
          isColumnVisible: true
        },
        {
          id: '109',
          label: Constant.ODJob.qualification,
          name: 'qualification',
          isColumnVisible: true
        },
        {
          id: '200',
          label: Constant.ODJob.experience,
          name: 'experience',
          isColumnVisible: true
        },
        // {
        //   id: '11',
        //   name: 'otherRequirements',
        //   label: Constant.ODJob.other_requirements,
        //   isColumnVisible: false
        // },
        // {
        //   id: '12',
        //   label: Constant.ODJob.specialRequirements,
        //   name: 'specialRequirements',
        //   isColumnVisible: true
        // },
        {
          id: '13',
          name: 'quantity',
          label: Constant.ODJob.quantity,
          isColumnVisible: true
        },
        {
          id: '14',
          name: 'jobStatus',
          label: Constant.ODJob.status,
          isColumnVisible: true
        },
        {
          id: '15',
          name: 'lastComment',
          label: Constant.ODJob.last_comment,
          isColumnVisible: true
        },
        {
          id: '16',
          name: 'action',
          label: Constant.ODJob.action,
          isColumnVisible: true
        }
      ],
      data: [],
      currentPage: 0,
      count: 0,
      rowsPerPage: 10
    };

    this.option = [
      {
        id: 1,
        type: 1,//int
        label: 'Job ID',
        eValue:'job.job_id'
      },
      {
        id: 2,
        type:2,//String
        label:'Contact Person',
        eValue:'job.contact_person'
      },
      {
        id: 3,
        type:2,
        label:'Status',
        eValue:'job.job_status'
      },
      {
        id: 4,
        type: 1,//integer
        label: 'Category',
        eValue:'job.work_type'
      },
      {
        id:78,
        type:1,
        label:'City',
        eValue:'job.city_id'
      },
      {
        id:5,
        type: 3,//date
        label: 'Job Start Date',
        eValue:'job.start_date'
      },
      {
        id:6,
        type:3,
        label:'Job End Date',
        eValue:'job.end_date'
      },
      {
        id:7,
        type:4,
        label:'Job Login Time',
        eValue:'job.login_time'
      },
        {
        id:9,
        type:3,
        label:'Inserted On',
        eValue:'job.inserted_on'
      }
    ];
    this.option1=[
      {
      id:1,
      label:"ASC",
      eValue:'ascending',
      type:2
      },
      {
        id:2,
        label:"DES",
        eValue:'descending',
        type:2
      }
    ];
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangeToggle = this.handleChangeToggle.bind(this);
    this.convertJobIdtoHex = this.convertJobIdtoHex.bind(this);
    this.updateColumnFileds = this.updateColumnFileds.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
  }
  handleChangeRowsPerPage(value) {
    const newrowsPerPage = parseInt(value, 10);
    if (newrowsPerPage !== this.state.rowsPerPage) {
      this.setState({
        rowPerPage: newrowsPerPage
      });
    }
  }
  convertJobIdtoHex(val){
    var s = "abcde" + val.toString(36);
    console.log("called");
    return " "+s.substring(s.length - 5).toUpperCase();
  }
  updateColumnFileds(columnsToHide) {
    const upadtedColumns = this.state.cols.map(col => {
      if (columnsToHide.includes(col.id)) {
        col.isColumnVisible = true;
      } else {
        col.isColumnVisible = false;
      }
      return col;
    });

    this.setState({
      cols: upadtedColumns
    });
  }

  updateDataOnTablePagination = (
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) => {
    this.setState({ isTableUpdating: true }, () => {
      this.getJobList(rowsPerPage, nextPage);
    });
  };

  async updateFilters(filters) {
    let url='';
    if(filters["brandName"].length!==0){
      let companyIds = []
      filters["brandName"].map(brand => companyIds.push(brand.id))
      url=`${url}&user_id=${companyIds}`
    }
    if(filters["location"].length!==0){
      let cityIds = []
      filters["location"].map(city => cityIds.push(city.id))
      url=`${url}&city_id=${cityIds}`
    }
    if(filters["jobType"].length!==0){
      let jobTypeIds = []
      filters["jobType"].map(jobType => jobTypeIds.push(jobType.id))
      url=`${url}&job_type=${jobTypeIds}`
    }
    if(!filters["jobStatus"].includes(""))
      url=`${url}&job_status=${filters["jobStatus"]}`
    if(!filters["addedBy"].includes(""))
      url=`${url}&inserted_by=${filters["addedBy"]}`
    if(filters["rating"])
      url=`${url}&rating=${filters["rating"]}`
      if(filters["jobPostDate"][0] && !filters["jobPostDate"][1])
      url=`${url}&posted_from=${moment(filters["jobPostDate"][0]).format("YYYY-MM-DD")}&posted_to=${moment(new Date()).format("YYYY-MM-DD")}`
    if(!filters["jobPostDate"][0] && filters["jobPostDate"][1])
      url=`${url}&posted_from=2019-01-01&posted_to=${moment(filters["jobPostDate"][1]).format("YYYY-MM-DD")}`
    if(filters["jobPostDate"][0] && filters["jobPostDate"][1])
      url=`${url}&posted_from=${moment(filters["jobPostDate"][0]).format("YYYY-MM-DD")}&posted_to=${moment(filters["jobPostDate"][1]).format("YYYY-MM-DD")}`
    if(filters["jobDurationDate"][0] && !filters["jobDurationDate"][1])
      url=`${url}&start_date=${moment(filters["jobDurationDate"][0]).format("YYYY-MM-DD")}&end_date=${moment(new Date()).format("YYYY-MM-DD")}`
    if(!filters["jobDurationDate"][0] && filters["jobDurationDate"][1])
      url=`${url}&start_date=2019-01-01&end_date=${moment(filters["jobDurationDate"][1]).format("YYYY-MM-DD")}`
    if(filters["jobDurationDate"][0] && filters["jobDurationDate"][1])
      url=`${url}&start_date=${moment(filters["jobDurationDate"][0]).format("YYYY-MM-DD")}&end_date=${moment(filters["jobDurationDate"][1]).format("YYYY-MM-DD")}`
    console.log({url});
    this.setState({filters: filters, currentPage: 0, filterUrl: url, },  () => this.getJobList())
  }
  resetFilters =() =>{
    const filters= {
      brandName: [],
      location:[],
      jobType: [],
      jobStatus: [""],
      addedBy: [""],
      jobPostDate:[null, null],
      jobDurationDate:[null, null]
    }
    this.setState({filterUrl: '', currentPage: 0, filters: filters}, () => this.getJobList())
    return filters;
  }

  makeData = data => {
    const extractedData = [];
    data.map(el => 
      el.detailsJobs.map(el2 => {
        const qualificationValue = el2.detailSkills.map(skill => skill.skillName === 'Qualification' ? skill.skillValue : null);
        const genderValue = el2.detailSkills.map(skill => skill.skillName === 'Gender' ? skill.skillValue : null);
        const experienceValue = el2.detailSkills.map(skill => skill.skillName === 'Experience' ? skill.skillValue : null);
        extractedData.push({
          jobId: el.jobId ? el.jobId : '-',
          companyId: el.company['brandName'] ? el.company['brandName'] : el.company['companyName'] ? el.company['companyName'] : '-',
          jobDetailsId: el2.jobDetailsId ? el2.jobDetailsId : '-',
          encryptId: el2.jobDetailsId ? this.convertJobIdtoHex(el2.jobDetailsId) : '-',

          insertedOn: el.insertedOn
            ? `${el.insertedOn
                .substring(0, 10)
                .toString()
                .split('-')
                .reverse()
                .join('-')} ${el.insertedOn.substring(11, 20)}`
            : '-',
          startDate: el.startDate
            ? el.startDate
                .substring(0, 10)
                .toString()
                .split('-')
                .reverse()
                .join('-')
            : '-',
            endDate: el.endDate
            ? el.endDate
                .substring(0, 10)
                .toString()
                .split('-')
                .reverse()
                .join('-')
            : '-',
          loginTime: el.loginTime ? el.loginTime : '-',
          logoutTime: el.logoutTime ? el.logoutTime : '-',
          address: el.address ? el.address : '-',
          contactPerson:el.contactPerson?el.contactPerson:el.contactPerson,
          city:el.city_name?`${el.city_name}${el.state_name?", " :''}${el.state_name?el.state_name:''}`: '-',
          title: el2.jobTitle ? el2.jobTitle : '-',
          gender: genderValue,
          qualification: qualificationValue,
          experience: experienceValue,
          otherRequirements: el2.additionalRequirement
            ? el2.additionalRequirement
            : '-',
          lastComment: el2.latestComment || '-',
          specialRequirements: el2.detailSkills
          .map(el3 => (el3.skillName !== 'Qualification' && el3.skillName !== 'Gender' && el3.skillName !== 'Experience') ? el3.skillValue : '')
          .filter(el4 => el4 !== '')
            .join(', ') || '-',
          quantity: `${el2.employeeHired}/${el2.employeeRequired}`,
          jobStatus:  el.jobStatus===1 ? el2.employeeHired === el2.employeeRequired ? 'Hired' : 'Hiring' :el.jobStatus===2?  'On hold' : "Closed",
          status: el.jobStatus
        });
        return null;
      })
    )
    return extractedData;
  };

  getJobList = (
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) => {
    console.log("GET JOB LIST CALLED");
    let url = '';
    url = `${this.state.url}&page_no=${nextPage}&rows=${rowsPerPage}${this.state.filterUrl}`;
    if( this.state.eValue && this.state.eValue1 ){
      url = `${url}&orderBy=${this.state.eValue}&sortBy=${this.state.eValue1}`;
    }if(this.state.eValue && !this.state.eValue1){
      url = `${url}&orderBy=${this.state.eValue}`;
    }
    Axios.get(url)
      .then(response => {
        console.log(response.data.response.content);
        const data = this.makeData(response.data.response.content);
        console.log(data);
        this.setState({
          list: data,
          count: response.data.response.totalElements,
          currentPage: nextPage,
          rowsPerPage: rowsPerPage,
          isLoading: false,
          isTableUpdating: false
        });
      })
      .catch(error => {
        toast.error('Error While Loading ODJobs');
      });
  };

  getCompanyList = (data) =>{
    let list=[];
    data.map(company =>
      list.push({
        id: company.userId,
        name: company.brandName?company.brandName: company.companyName
      })
    )
    this.setState({companyList: list});
  }
  getJobTypeList = (data) =>{
    let list=[];
    data.map(jobType =>
      list.push({
        id: jobType.id,
        name: jobType.categorySubType
      })
    )
    this.setState({jobTypeList: list});
  }

  componentDidMount() {
    Axios.get(`${urlLinks.baseUrl}company/?rows=1000`)
      .then(response =>{  
        this.getCompanyList(response.data.response.content)
      })
    Axios.get(`${urlLinks.baseUrl}/configmaster/?category_type=job_type&inserted_by=0&rows=100&type_desc=job_type_name`)
      .then(response =>{
        this.getJobTypeList(response.data.response.content)
      })
    this.getJobList();
  }

  handleChange(event){
    console.log(event);
    this.setState({sortAccessed: true, label:event.target.value.label, eValue:event.target.value.eValue,isTableUpdating:true},()=>this.getJobList(this.state.rowsPerPage,this.state.currentPage));
  }

  handleChange1(event){
    console.log(event)
    this.setState({label1:event.target.value.label, eValue1:event.target.value.eValue,isTableUpdating:true},()=>this.getJobList(this.state.rowsPerPage,this.state.currentPage));
  }
  handleChangeToggle(){
    // console.log(event)
    let direction= "ascending"
    !this.state.sortingDirection?direction= "ascending":direction= "descending";
    this.setState({sortingDirection: !this.state.sortingDirection, eValue1:direction,isTableUpdating:true},()=>this.getJobList(this.state.rowsPerPage,this.state.currentPage));
  }

  render() {
    const { classes } = this.props;
    const tablePagination = {
      currentPage: this.state.currentPage,
      count: this.state.count,
      changePage: this.updateDataOnTablePagination,
      rowsOptions: this.state.rowsOptions,
      rowsPerPage: this.state.rowsPerPage,
      tableUpdating: this.state.isTableUpdating
    };
    return this.state.isLoading ? (
      <RingLoader
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto'
        }}
        color={'#194D33'}
      ></RingLoader>
    ) : (
      <div>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={4000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <Card style={{ margin: '20px 0px', padding: '20px' }}>
          <Grid container>
            <Grid item sm={3}>
              <HideUnhideColumns
                columns={this.state.cols}
                toggleColumn={this.updateColumnFileds}
              ></HideUnhideColumns>
            </Grid>
            <Grid item sm={3}>
              <FilterBase
                filters={this.state.filters}
                updateFilters={this.updateFilters}
                resetFilters={this.resetFilters}
                companyList={this.state.companyList}
                jobTypeList={this.state.jobTypeList}
                page="partialJobDB"
              />
            </Grid>
            <Grid item sm={2} className={classes.SearchBar}>
              <Select
              val={this.state.label}
              type={this.state.type}
              option={this.option}
              label={this.uiLabel}
              handleChange={this.handleChange}>
              </Select>
            </Grid>
            <Grid item sm={1} >
              {this.state.sortAccessed && 
                <ToggleButton
                    className="sortDir"
                    value="check"
                    selected={this.state.sortingDirection}
                    onChange={this.handleChangeToggle}
                  >
                    {this.state.sortingDirection? 'A to Z':'Z to A' }
                </ToggleButton>
              }
            </Grid> 
            

          </Grid>
        </Card>
        <ExpandableTable
          name={'odJobs'}
          jobIdColumn={'jobId'}
          idColumn={'jobDetailsId'}
          keyColumn={'jobDetailsId'}
          title={ODJobUtils.title}
          columns={this.state.cols}
          links={ODJobUtils.odJobListAction}
          data={this.state.list}
          currentPage={this.state.currentPage}
          rowsPerPage={this.state.rowPerPage}
          tablePagination={tablePagination}
          changeRowsPerPage={this.handleChangeRowsPerPage}
        ></ExpandableTable>
      </div>
    );
  }
}

export default withStyles(style)(ODJob);
