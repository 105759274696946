import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from "@material-ui/core/Typography";
// import Typography from "@material-ui/";

// import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Grid } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
// import DialogTitle from '@material-ui/core/DialogTitle';
import Styles from './index.module.scss'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const { closeResponseModal, resp } = props
  // let extractedData = [];
  // resp.map(el => {
  //   console.log({ el })
  //   extractedData.push({

  //   }
  //     )})
  console.log(resp)
  console.log(resp['job_not_associated_with_worker_id'])
  // console.log(resp.data['Total records attempted'])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeResponseModal()
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        className={Styles.onboardingDialog}
        classes={{ paper: Styles.paper }}
        // aria-labelledby="alert-dialog-title"
        aria-labelledby="customized-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle> */}
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className={Styles.summaryText}>{"Summary of uploaded data:"}</DialogTitle>
        <DialogContent>

          <Grid container className="padding0 marginTop16">
            <table className="tableContainer">

              <tr>
                <td># Total records attempted</td>
                <td>{resp['Total records attempted']}</td>
              </tr>
              <tr>
                <td># Records uploaded</td>
                <td>{resp['records_uploaded']}</td>
              </tr>
              {/* 
              {resp['job_not_associated_with_worker_id']  &&
                <tr>
                  <td># Job not associated with worker id:</td>
                  <td>{resp['job_not_associated_with_worker_id']}</td>
                </tr>
              } */}
              {/* {resp['wrong_records'] &&
                <tr>
                  <td># Invalid record IDs</td>
                  <td>{resp['wrong_records']}</td>
                </tr>
              } */}
            </table>
            {/* <div> */}
            <Grid item xs={12} className="padding0">

              {resp['job_not_associated_with_worker_id'] && <Typography style={{ fontWeight: 402, fontSize: "13px", lineHeight: 2.5 }}>
                Worker ids incorrectly mapped with job:  {resp['job_not_associated_with_worker_id']}

              </Typography>
              }
              </Grid>
 
              {/* </div> */}

            <Grid  item xs={12}  className="padding0">
              {/* <div> */}
                {resp['wrong_records'] && <Typography style={{ fontWeight: 402, fontSize: "13px", lineHeight: 2.5 }}>
                  Worker ids with invalid data inputs:  {resp['wrong_records']}

                </Typography>

                  

                }
                {/* </div> */}
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} variant="contained" className="blueButtonContained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
