import React, {useState} from 'react'
import './upcomingJoinings.css'
import {Chip, IconButton , Zoom, Select, MenuItem, Tooltip, Modal, Button, Backdrop, Slide, Grid} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import location from '../../../assets/icons8-marker-100 (1).png' 
import phone from '../../../assets/icons8-call-100.png' 
import clock from '../../../assets/icons8-clock-500 (1).png' 
import { withStyles, makeStyles} from '@material-ui/core/styles';
import * as moment from 'moment';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#525252',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
  },
  arrow:{
      backgroundColor: '#ffffff',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));

const UpcomingJoining=(props) =>{
    const classes = useStyles();    
    let joining= props.data;
    const [status, setStatus] = useState(joining.joiningStatus!==null?joining.joiningStatus:'Joining status')
    const [tempstatus, setTempstatus] = useState("Joining status")
    const [openJoinedStatus, setOpenJoinedStatus] = useState(false)
    const [showButtons, setShowButtons] = useState(false)
    const [joiningDate, setJoiningDate] = useState(`${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`)
    const [datepickerLabel, setDatepickerLabel] = useState('')
    console.log(joiningDate);
        let updateData={};
        const id= joining.interviewId
    
    
    const handleConfirm =() =>{
        if(!(tempstatus==="JOINED"||tempstatus==="POSTPONED"))
        {
            updateData={status, id}
            props.updateStatus(updateData)
            setShowButtons(false);

        }
        
    }
    
    const handleCancel =() =>{
        setStatus(tempstatus);
        setShowButtons(false);
    }
    const handlejoinedStatusClose =() =>{
        setOpenJoinedStatus(false)
    }
    const confirmJoinedStatus = () =>{
        updateData={status, id}
        props.updateStatus({...updateData, joiningDate})
        setOpenJoinedStatus(false)
    }

    const handleChange = event => {
       if(event.target.value==='JOINED')
        {   
            setDatepickerLabel("Confirm Joining Date")
            setOpenJoinedStatus(true)
            setTempstatus(status)
            setStatus(event.target.value)
        }
        if(event.target.value==='NOT_JOINING')
        {
            setShowButtons(true)
            setTempstatus(status)
            setStatus(event.target.value)
        }
        if(event.target.value==='POSTPONED')
        {   
            setDatepickerLabel("Enter New Joining Date")
            setOpenJoinedStatus(true)
            setTempstatus(status)
            setStatus(event.target.value)
        }
        if(event.target.value==='NO_RESPONSE')
        {
            setShowButtons(true)
            setTempstatus(status)
            setStatus(event.target.value)
        }
    };
    
    let shortAddress=`${joining.companyName}, ${joining.companyCity}`
    let chipLabel=joining.jobType===1?'Full time':'Part time';

    return (
        <div className={props.cname}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openJoinedStatus}
                onClose={handlejoinedStatusClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Slide direction="up" in={openJoinedStatus} mountOnEnter unmountOnExit>
                <div className={classes.paper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                    
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={datepickerLabel}
                        format="dd-MM-yyyy"
                       
                        value={joiningDate}
                        onChange={date => setJoiningDate(`${moment(date).format("YYYY-MM-DD")} 00:00:00`)}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    
                    </Grid>
                </MuiPickersUtilsProvider>
                <Button color="primary"  onClick={confirmJoinedStatus} > Confirm Date</Button>
                <Button color="secondary" onClick={handlejoinedStatusClose} >Cancel</Button>
                </div>
                </Slide>
            </Modal>
            <div className="details">
               <div className="NPT">
                    <h4>{joining.name}-{joining.profile}</h4> <Chip label={chipLabel} className="chipStyle" />
                </div> 
        
                <div className="address">
                    <img className="iconImg" src={location} alt='location' />
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <div className="toolTipTitle">
                                <span><b>{joining.companyName}</b></span>
                                <span>{joining.contactName} - {joining.contactNumber}</span>
                                <span>{joining.companyAddress}</span>
                            </div>
                        </React.Fragment>
                        } interactive  placement="bottom-start"
                            TransitionComponent={Zoom}  
                    > 
                        <span>{shortAddress}</span>
                    </HtmlTooltip>
                </div>
            </div>
            <div className="status">
                <div className="phoneNumber">
                    <img src={phone} alt='location' /><span className="candidateNumber">+91-{joining.candidateNumber}</span>
                </div>
                <div className="joiningStatus">
                    <Select
                        id="joiningStatus"
                        value={status}
                        variant='outlined'
                        onChange={handleChange}
                        classes={{root: classes.select}}
                        >
                        <MenuItem value="Joining status" disabled>
                            <em>Joining status</em>
                        </MenuItem>
                        <MenuItem value='JOINED'>Joined</MenuItem>
                        <MenuItem value='NOT_JOINING'>Not joining</MenuItem>
                        <MenuItem value='POSTPONED'>Postponed</MenuItem>
                        <MenuItem value='NO_RESPONSE'>No response</MenuItem>
                    </Select>
                </div>
                {
                    showButtons &&
                    <div>
                        <IconButton color="primary" onClick={handleConfirm}>
                            <DoneSharpIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={handleCancel} >
                            <CloseSharpIcon />
                        </IconButton>
                    </div>
                }
            </div>
        </div>
    )
}

export default UpcomingJoining;