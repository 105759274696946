import React from 'react';
import { MdComment, MdModeEdit } from 'react-icons/md';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import ScheduleIcon from '@material-ui/icons/Schedule';
const ChildTabelConstant = {
  odJobs: {
    table1: {
      cols: [
        // {
        //   id: '1',
        //   label: 'Assign Id',
        //   name: 'assignId',
        //   isColumnVisible: true
        // },
        // {
        //   id: '2',
        //   label: 'Job Detail Id',
        //   name: 'jobDetailId',
        //   isColumnVisible: true
        // },
        // {
        //   id: '3',
        //   label: 'Job Id',
        //   name: 'jobId',
        //   isColumnVisible: true
        // },
        // {
        //   id: '4',
        //   label: 'User Id',
        //   name: 'userId',
        //   isColumnVisible: true
        // },
        {
          id: '5',
          label: 'Worker Id',
          name: 'workerId',
          isColumnVisible: true
        },
        {
          id: '6',
          label: 'UserName',
          name: 'userName',
          isColumnVisible: true
        },
        {
          id: '7',
          label: 'Last Activity (in Days)',
          name: 'lastActivity',
          isColumnVisible: false
        },
        {
          id: '4',
          label: 'Actual Score',
          name: 'actualScore',
          isColumnVisible: true
        },
        {
          id: '20',
          label: 'Cutoff Score',
          name: 'cutoffScore',
          isColumnVisible: true
        },
        {
          id: '9',
          label: 'City',
          name: 'city',
          isColumnVisible: true
        },
        {
          id: '10',
          label: 'Last Seen Distance',
          name: 'lastSeenDistance',
          isColumnVisible: true
        },
        {
          id: '11',
          label: 'Distance Captured On',
          name: 'distanceCapturedOn',
          isColumnVisible: true
        },
        {
          id: '12',
          label: 'Travel Mode',
          name: 'travelMode',
          isColumnVisible: true
        },
        {
          id: '13',
          label: 'Job Type',
          name: 'jobType',
          isColumnVisible: true
        },
        {
          id: '14',
          label: 'Last Job Experience',
          name: 'lastJobExp',
          isColumnVisible: true
        },
        {
          id: '15',
          label: 'Experience In Years',
          name: 'expInYears',
          isColumnVisible: true
        },
        {
          id: '16',
          label: 'Average Rating',
          name: 'averageRating',
          isColumnVisible: true
        },
        {
          id: '17',
          label: 'Total Gigs',
          name: 'totalGigs',
          isColumnVisible: true
        },
        {
          id: '18',
          label: 'Breach',
          name: 'breach',
          isColumnVisible: true
        },
        {
          id: '19',
          label: 'Inserted On',
          name: 'insertedOn',
          isColumnVisible: true
        },

        {
          id: '21',
          label: 'Breach CutOff',
          name: 'breachCutOff',
          isColumnVisible: true
        },
        {
          id: '22',
          label: 'Availability CutOff',
          name: 'availabilityCutOff',
          isColumnVisible: true
        },
        {
          id: '23',
          label: 'Distance CutOff',
          name: 'distanceCutOff',
          isColumnVisible: true
        },
        {
          id: '24',
          label: 'Gender CutOff',
          name: 'genderCutOff',
          isColumnVisible: true
        },
        {
          id: '25',
          label: 'Experience CutOff',
          name: 'expCutOff',
          isColumnVisible: true
        },
        {
          id: '26',
          label: 'Qualification CutOff',
          name: 'qualCutOff',
          isColumnVisible: true
        },
        {
          id: '27',
          label: 'Negative Rating CutOff',
          name: 'negativeRatingCutOff',
          isColumnVisible: true
        },
        {
          id: '8',
          label: 'Address',
          name: 'address',
          isColumnVisible: true
        },
        {
          id: '35',
          label: 'Apply Job',
          name: 'applyJob',
          isColumnVisible: true
        },
        {
          id: '28',
          label: 'Action',
          name: 'action',
          isColumnVisible: false
        }
      ],
      listAction: [
        {
          id: 1,
          name: 'Assign',
          subIcon: <MdModeEdit className='fitIcon' />,
          route: '/'
        }
      ]
    },
    table2: {
      cols: [
        {
          id: '1',
          label: 'ID',
          name: 'assignId',
          isColumnVisible: true
        },
        {
          id: '101',
          label: 'Worker ID',
          name: 'workerId',
          isColumnVisible: true
        },
        {
          id: '2',
          label: 'Candidate',
          name: 'userName',
          isColumnVisible: true
        },
        {
          id: '3',
          label: 'Last Activity (in Days)',
          name: 'lastActivity',
          isColumnVisible: true
        },
        {
          id: '16',
          label: 'CutOff Sore',
          name: 'cutoffScore',
          isColumnVisible: true
        },
        {
          id: '17',
          label: 'Actual Sore',
          name: 'actualScore',
          isColumnVisible: true
        },
        {
          id:'895',
          label:'Total Gigs',
          name:'totalGigs',
          isColumnVisible: true

        },
        {
          id: '5',
          label: 'Current Location',
          name: 'currentLocation',
          isColumnVisible: false
        },
        {
          id: '6',
          label: 'Location Captured On',
          name: 'distanceCapturedOn',
          isColumnVisible: true
        },
        {
          id: '7',
          label: 'Distance (in km)',
          name: 'lastSeenDistance',
          isColumnVisible: true
        },
        {
          id: '8',
          label: 'Mode',
          name: 'travelMode',
          isColumnVisible: true
        },
        {
          id: '9',
          label: 'Availability',
          name: 'availability',
          isColumnVisible: false
        },
        {
          id: '10',
          label: 'Industry',
          name: 'industry',
          isColumnVisible: false
        },
        {
          id: '11',
          label: 'Last Job Experience',
          name: 'lastJobExp',
          isColumnVisible: true
        },
        {
          id: '12',
          label: 'Yrs',
          name: 'expInYears',
          isColumnVisible: true
        },
        {
          id: '13',
          label: 'Rating',
          name: 'averageRating',
          isColumnVisible: true
        },
        {
          id: '14',
          label: 'Total Gigs',
          name: 'totalGigs',
          isColumnVisible: true
        },
        {
          id: '15',
          label: 'Breach',
          name: 'breach',
          isColumnVisible: true
        },
        {
          id: '18',
          label: 'Availability CutOff',
          name: 'availabilityCutOff',
          isColumnVisible: true
        },
        {
          id: '19',
          label: 'Breach CutOff',
          name: 'breachCutOff',
          isColumnVisible: true
        },
        {
          id: '20',
          label: 'Distance CutOff',
          name: 'distanceCutOff',
          isColumnVisible: true
        },
        {
          id: '21',
          label: 'Gender CutOff',
          name: 'genderCutOff',
          isColumnVisible: true
        },
        {
          id: '22',
          label: 'Experience CutOff',
          name: 'expCutOff',
          isColumnVisible: true
        },
        {
          id: '23',
          label: 'Qualification CutOff',
          name: 'qualCutOff',
          isColumnVisible: true
        },
        {
          id: '24',
          label: 'Negative Rating CutOff',
          name: 'negativeRatingCutOff',
          isColumnVisible: true
        },
        {
          id: '4',
          label: 'Address',
          name: 'address',
          isColumnVisible: true
        },
        {
          id: '25',
          label: 'Action',
          name: 'action',
          isColumnVisible: false
        }
      ],
      listAction: [
        {
          id: 1,
          name: 'Assign',
          subIcon: <MdModeEdit className='fitIcon' />,
          route: '/'
        }
      ],
      filterList: [
        {
          industry: ['Restaurant', 'Health', 'Mechanical'],
          id: '1',
          name: 'industry',
          label: 'Industry',
          default: []
        },
        {
          location: ['Delhi', 'Gurgaon', 'Noida'],
          id: '2',
          name: 'location',
          label: 'Location',
          default: []
        },
        {
          status: ['Active', 'Inactive', 'Suspended'],
          id: '3',
          name: 'status',
          label: 'Status',
          default: []
        },
        {
          rating: ['1', '2', '3', '4', '5'],
          id: '4',
          name: 'rating',
          label: 'Rating',
          default: []
        }
      ]
    },
    table3: {
      cols: [
        // {
        //   id: '1',
        //   label: 'Assign Id',
        //   name: 'assign_id',
        //   isColumnVisible: true
        // },
        // {
        //   id: '2',
        //   label: 'User Id',
        //   name: 'user_id',
        //   isColumnVisible: true
        // },
        // {
        //   id: '3',
        //   label: 'About Me',
        //   name: 'about_me',
        //   isColumnVisible: true
        // },
        // {
        //   id: '5',
        //   label: 'Last Name',
        //   name: 'last_name',
        //   isColumnVisible: true
        // },
        // {
        //   id: '6',
        //   label: 'Contact Number',
        //   name: 'phone_number',
        //   isColumnVisible: true
        // },
        // {
        //   id: '7',
        //   label: 'Job Id',
        //   name: 'job_id',
        //   isColumnVisible: true
        // },
        // {
        //   id: '8',
        //   label: 'Job Detail Id',
        //   name: 'job_detail_id',
        //   isColumnVisible: false
        // },

        // {
        //   id: '10',
        //   label: 'CuttOff Score',
        //   name: 'cutoff_score',
        //   isColumnVisible: true
        // },
        // {
        //   id: '11',
        //   label: 'Is Accepted',
        //   name: 'is_accepted',
        //   isColumnVisible: true
        // },
        // {
        //   id: '12',
        //   label: 'Is Rejected',
        //   name: 'is_rejected',
        //   isColumnVisible: true
        // },
        // {
        //   id: '13',
        //   label: 'Is Paid',
        //   name: 'is_paid',
        //   isColumnVisible: true
        // },
        // {
        //   id: '14',
        //   label: 'Accepted By',
        //   name: 'accepted_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '15',
        //   label: 'Rejected By',
        //   name: 'rejected_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '16',
        //   label: 'Is Confirmed',
        //   name: 'is_confirmed',
        //   isColumnVisible: true
        // },
        // {
        //   id: '17',
        //   label: 'Confirmed By',
        //   name: 'confirmed_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '18',
        //   label: 'Inserted By',
        //   name: 'inserted_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '19',
        //   label: 'Inserted On',
        //   name: 'inserted_on',
        //   isColumnVisible: true
        // },
        // {
        //   id: '20',
        //   label: 'Updated By',
        //   name: 'updated_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '21',
        //   label: 'Updated On',
        //   name: 'updated_on',
        //   isColumnVisible: true
        // },
        {
          id: '22',
          label: 'Worker Id',
          name: 'worker_id',
          isColumnVisible: true
        },
        {
          id: '4',
          label: 'Worker Name',
          name: 'first_name',
          isColumnVisible: true
        },
        {
          id: '9',
          label: 'Actual Score',
          name: 'actual_score',
          isColumnVisible: true
        },
        {
          id: '55',
          label: 'Job Status',
          name: 'acceptJob',
          isColumnVisible: true
        },
        {
          id: '65',
          label: 'Start Date',
          name: 'startDate',
          isColumnVisible: true
        },
        {
          id: '75',
          label: 'End Date',
          name: 'endDate',
          isColumnVisible: true
        },
        {
          id: '25',
          label: 'Rating',
          name: 'average_rating',
          isColumnVisible: true
        },
        {
          id: '23',
          label: 'Total Gigs',
          name: 'total_gigs',
          isColumnVisible: true
        },
        // {
        //   id: '24',
        //   label: 'Reviews',
        //   name: 'total_reviews',
        //   isColumnVisible: true
        // },
        // {
        //   id: '26',
        //   label: 'Breach',
        //   name: 'breach',
        //   isColumnVisible: true
        // },
        // {
        //   id: '27',
        //   label: 'Has Experience',
        //   name: 'has_experience',
        //   isColumnVisible: true
        // },
        // {
        //   id: '28',
        //   label: 'Is Kyc',
        //   name: 'is_kyc',
        //   isColumnVisible: true
        // },
        // {
        //   id: '29',
        //   label: 'Started On',
        //   name: 'started_on',
        //   isColumnVisible: true
        // },
        // {
        //   id: '31',
        //   label: 'Ended On',
        //   name: 'ended_on',
        //   isColumnVisible: true
        // },{
        //   id: '32',
        //   label: 'Is Extended',
        //   name: 'is_extended',
        //   isColumnVisible: true
        // },
        {
          id: '33',
          label: 'Status',
          name: 'status',
          isColumnVisible: true
        },
        {
          id: '34',
          label: 'Update Status',
          name: 'updateStatus',
          isColumnVisible: true
        },
        // {
        //   id: '34',
        //   label: 'OTP',
        //   name: 'otp',
        //   isColumnVisible: true
        // },{
        //   id: '35',
        //   label: 'Is Verified By OTP',
        //   name: 'is_verified_by_otp',
        //   isColumnVisible: true
        // },
        // {
        //   id: '36',
        //   label: 'Is Started',
        //   name: 'is_started',
        //   isColumnVisible: true
        // },
        // {
        //   id: '37',
        //   label: 'Is No Show',
        //   name: 'is_no_show',
        //   isColumnVisible: true
        // },
        // {
        //   id: '38',
        //   label: 'Is Ended',
        //   name: 'is_ended',
        //   isColumnVisible: true
        // },
        // {
        //   id: '39',
        //   label: 'Qualification Id',
        //   name: 'qualification_id',
        //   isColumnVisible: true
        // },
        // {
        //   id: '40',
        //   label: 'Spoken Language',
        //   name: 'spoken_language',
        //   isColumnVisible: true
        // },
        // {
        //   id: '41',
        //   label: 'Is Leader',
        //   name: 'is_leader',
        //   isColumnVisible: true
        // },
        // {
        //   id: '42',
        //   label: 'Paytm Number',
        //   name: 'paytm_number',
        //   isColumnVisible: true
        // },
        // {
        //   id: '43',
        //   label: 'Available PT',
        //   name: 'available_pt',
        //   isColumnVisible: true
        // },
        // {
        //   id: '44',
        //   label: 'Available FT',
        //   name: 'available_ft',
        //   isColumnVisible: true
        // },
        // {
        //   id: '45',
        //   label: 'Available OD',
        //   name: 'available_od',
        //   isColumnVisible: true
        // },
        // {
        //   id: '46',
        //   label: 'Super Toggle',
        //   name: 'super_toggle',
        //   isColumnVisible: true
        // },
        // {
        //   id: '47',
        //   label: 'Global Availability',
        //   name: 'global_availability',
        //   isColumnVisible: true
        // },
        // {
        //   id: '48',
        //   label: 'Check In Id',
        //   name: 'check_in_id',
        //   isColumnVisible: true
        // },
        {
          id: '49',
          label: 'Action',
          name: 'action',
          isColumnVisible: false
        }
      ],
      listAction: [
        {
          id: 1,
          name: 'Assign',
          subIcon: <MdModeEdit className='fitIcon' />,
          route: '/'
        }
      ]
    },
    table4: {
      cols: [
        // {
        //   id: '1',
        //   label: 'Assign Id',
        //   name: 'assign_id',
        //   isColumnVisible: true
        // },
        // {
        //   id: '2',
        //   label: 'User Id',
        //   name: 'user_id',
        //   isColumnVisible: true
        // },
        // {
        //   id: '3',
        //   label: 'About Me',
        //   name: 'about_me',
        //   isColumnVisible: true
        // },
        // {
        //   id: '5',
        //   label: 'Last Name',
        //   name: 'last_name',
        //   isColumnVisible: true
        // },
        // {
        //   id: '6',
        //   label: 'Contact Number',
        //   name: 'phone_number',
        //   isColumnVisible: true
        // },
        // {
        //   id: '7',
        //   label: 'Job Id',
        //   name: 'job_id',
        //   isColumnVisible: true
        // },
        // {
        //   id: '8',
        //   label: 'Job Detail Id',
        //   name: 'job_detail_id',
        //   isColumnVisible: false
        // },

        // {
        //   id: '10',
        //   label: 'CuttOff Score',
        //   name: 'cutoff_score',
        //   isColumnVisible: true
        // },
        // {
        //   id: '11',
        //   label: 'Is Accepted',
        //   name: 'is_accepted',
        //   isColumnVisible: true
        // },
        // {
        //   id: '12',
        //   label: 'Is Rejected',
        //   name: 'is_rejected',
        //   isColumnVisible: true
        // },
        // {
        //   id: '13',
        //   label: 'Is Paid',
        //   name: 'is_paid',
        //   isColumnVisible: true
        // },
        // {
        //   id: '14',
        //   label: 'Accepted By',
        //   name: 'accepted_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '15',
        //   label: 'Rejected By',
        //   name: 'rejected_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '16',
        //   label: 'Is Confirmed',
        //   name: 'is_confirmed',
        //   isColumnVisible: true
        // },
        // {
        //   id: '17',
        //   label: 'Confirmed By',
        //   name: 'confirmed_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '18',
        //   label: 'Inserted By',
        //   name: 'inserted_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '19',
        //   label: 'Inserted On',
        //   name: 'inserted_on',
        //   isColumnVisible: true
        // },
        // {
        //   id: '20',
        //   label: 'Updated By',
        //   name: 'updated_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '21',
        //   label: 'Updated On',
        //   name: 'updated_on',
        //   isColumnVisible: true
        // },
        {
          id: '22',
          label: 'Worker Id',
          name: 'worker_id',
          isColumnVisible: true
        },
        {
          id: '4',
          label: 'Worker Name',
          name: 'first_name',
          isColumnVisible: true
        },
        {
          id: '9',
          label: 'Actual Score',
          name: 'actual_score',
          isColumnVisible: true
        },
        {
          id: '25',
          label: 'Rating',
          name: 'average_rating',
          isColumnVisible: true
        },
        {
          id: '23',
          label: 'Total Gigs',
          name: 'total_gigs',
          isColumnVisible: true
        },
        {
          id: '65',
          label: 'Start Date',
          name: 'startDate',
          isColumnVisible: true
        },
        {
          id: '75',
          label: 'End Date',
          name: 'endDate',
          isColumnVisible: true
        },
        // {
        //   id: '24',
        //   label: 'Reviews',
        //   name: 'total_reviews',
        //   isColumnVisible: true
        // },
        // {
        //   id: '26',
        //   label: 'Breach',
        //   name: 'breach',
        //   isColumnVisible: true
        // },
        // {
        //   id: '27',
        //   label: 'Has Experience',
        //   name: 'has_experience',
        //   isColumnVisible: true
        // },
        // {
        //   id: '28',
        //   label: 'Is Kyc',
        //   name: 'is_kyc',
        //   isColumnVisible: true
        // },
        // {
        //   id: '29',
        //   label: 'Started On',
        //   name: 'started_on',
        //   isColumnVisible: true
        // },
        // {
        //   id: '31',
        //   label: 'Ended On',
        //   name: 'ended_on',
        //   isColumnVisible: true
        // },{
        //   id: '32',
        //   label: 'Is Extended',
        //   name: 'is_extended',
        //   isColumnVisible: true
        // },
        {
          id: '34',
          label: 'Update Status',
          name: 'updateStatus',
          isColumnVisible: true
        },
        // {
        //   id: '33',
        //   label: 'Status',
        //   name: 'status',
        //   isColumnVisible: true
        // },
        // {
        //   id: '34',
        //   label: 'OTP',
        //   name: 'otp',
        //   isColumnVisible: true
        // },{
        //   id: '35',
        //   label: 'Is Verified By OTP',
        //   name: 'is_verified_by_otp',
        //   isColumnVisible: true
        // },
        // {
        //   id: '36',
        //   label: 'Is Started',
        //   name: 'is_started',
        //   isColumnVisible: true
        // },
        // {
        //   id: '37',
        //   label: 'Is No Show',
        //   name: 'is_no_show',
        //   isColumnVisible: true
        // },
        // {
        //   id: '38',
        //   label: 'Is Ended',
        //   name: 'is_ended',
        //   isColumnVisible: true
        // },
        // {
        //   id: '39',
        //   label: 'Qualification Id',
        //   name: 'qualification_id',
        //   isColumnVisible: true
        // },
        // {
        //   id: '40',
        //   label: 'Spoken Language',
        //   name: 'spoken_language',
        //   isColumnVisible: true
        // },
        // {
        //   id: '41',
        //   label: 'Is Leader',
        //   name: 'is_leader',
        //   isColumnVisible: true
        // },
        // {
        //   id: '42',
        //   label: 'Paytm Number',
        //   name: 'paytm_number',
        //   isColumnVisible: true
        // },
        // {
        //   id: '43',
        //   label: 'Available PT',
        //   name: 'available_pt',
        //   isColumnVisible: true
        // },
        // {
        //   id: '44',
        //   label: 'Available FT',
        //   name: 'available_ft',
        //   isColumnVisible: true
        // },
        // {
        //   id: '45',
        //   label: 'Available OD',
        //   name: 'available_od',
        //   isColumnVisible: true
        // },
        // {
        //   id: '46',
        //   label: 'Super Toggle',
        //   name: 'super_toggle',
        //   isColumnVisible: true
        // },
        // {
        //   id: '47',
        //   label: 'Global Availability',
        //   name: 'global_availability',
        //   isColumnVisible: true
        // },
        // {
        //   id: '48',
        //   label: 'Check In Id',
        //   name: 'check_in_id',
        //   isColumnVisible: true
        // },
        {
          id: '49',
          label: 'Action',
          name: 'action',
          isColumnVisible: false
        }
      ],
      listAction: [
        {
          id: 1,
          name: 'Assign',
          subIcon: <MdModeEdit className='fitIcon' />,
          route: '/'
        }
      ]
    }
  },
  ptftJobs: {
    table1: {
      cols: [
        // {
        //   id: '1',
        //   label: 'Assign Id',
        //   name: 'assignId',
        //   isColumnVisible: true
        // },
        // {
        //   id: '2',
        //   label: 'Job Detail Id',
        //   name: 'jobDetailId',
        //   isColumnVisible: true
        // },
        // {
        //   id: '3',
        //   label: 'Job Id',
        //   name: 'jobId',
        //   isColumnVisible: true
        // },
        {
          id: '5',
          label: 'Worker Id',
          name: 'workerId',
          isColumnVisible: true
        },
        {
          id: '6',
          label: 'UserName',
          name: 'userName',
          isColumnVisible: true
        },
        {
          id: '4',
          label: 'Phone Number',
          name: 'phoneNumber',
          isColumnVisible: true
        },
        {
          id: '7',
          label: 'Last Activity (in Days)',
          name: 'lastActivity',
          isColumnVisible: false
        },
        {
          id: '4',
          label: 'Actual Score',
          name: 'actualScore',
          isColumnVisible: true
        },
        {
          id: '20',
          label: 'Cutoff Score',
          name: 'cutoffScore',
          isColumnVisible: true
        },
        {
          id: '9',
          label: 'City',
          name: 'city',
          isColumnVisible: true
        },
        {
          id: '10',
          label: 'Last Seen Distance',
          name: 'lastSeenDistance',
          isColumnVisible: true
        },
        {
          id: '11',
          label: 'Distance Captured On',
          name: 'distanceCapturedOn',
          isColumnVisible: true
        },
        {
          id: '12',
          label: 'Travel Mode',
          name: 'travelMode',
          isColumnVisible: true
        },
        {
          id: '13',
          label: 'Job Type',
          name: 'jobType',
          isColumnVisible: true
        },
        {
          id: '14',
          label: 'Last Job Experience',
          name: 'lastJobExp',
          isColumnVisible: true
        },
        {
          id: '15',
          label: 'Experience In Years',
          name: 'expInYears',
          isColumnVisible: true
        },
        {
          id: '16',
          label: 'Average Rating',
          name: 'averageRating',
          isColumnVisible: true
        },
        {
          id: '17',
          label: 'Total Gigs',
          name: 'totalGigs',
          isColumnVisible: true
        },
        {
          id: '18',
          label: 'Breach',
          name: 'breach',
          isColumnVisible: true
        },
        {
          id: '19',
          label: 'Inserted On',
          name: 'insertedOn',
          isColumnVisible: true
        },
        {
          id: '21',
          label: 'Breach CutOff',
          name: 'breachCutOff',
          isColumnVisible: true
        },
        {
          id: '22',
          label: 'Availability CutOff',
          name: 'availabilityCutOff',
          isColumnVisible: true
        },
        {
          id: '23',
          label: 'Distance CutOff',
          name: 'distanceCutOff',
          isColumnVisible: true
        },
        {
          id: '24',
          label: 'Gender CutOff',
          name: 'genderCutOff',
          isColumnVisible: true
        },
        {
          id: '25',
          label: 'Experience CutOff',
          name: 'expCutOff',
          isColumnVisible: true
        },
        {
          id: '26',
          label: 'Qualification CutOff',
          name: 'qualCutOff',
          isColumnVisible: true
        },
        {
          id: '27',
          label: 'Negative Rating CutOff',
          name: 'negativeRatingCutOff',
          isColumnVisible: true
        },
        {
          id: '8',
          label: 'Address',
          name: 'address',
          isColumnVisible: true
        },
        {
          id: '35',
          label: 'Apply Job',
          name: 'applyJob',
          isColumnVisible: true
        },
        {
          id: '28',
          label: 'Action',
          name: 'action',
          isColumnVisible: false
        }
      ],
      listAction: [
        // {
        //   id: 1,
        //   name: 'ReSchedule Interview',
        //   subIcon: <ScheduleIcon className='fitIcon' />,
        //   route: '/'
        // },
        // {
        //   id: 1,
        //   name: 'Cancel Interview',
        //   subIcon: <CancelScheduleSendIcon className='fitIcon' />,
        //   route: '/'
        // },
        // {
        //   id: 1,
        //   name: 'Comment',
        //   subIcon: <MdComment className='fitIcon' />,
        //   route: '/'
        // }
      ]
    },
    table2: {
      cols: [
        {
          id: '1',
          label: 'ID',
          name: 'assignId',
          isColumnVisible: true
        },
        {
          id: '101',
          label: 'Worker ID',
          name: 'workerId',
          isColumnVisible: true
        },
        {
          id: '2',
          label: 'Candidate',
          name: 'userName',
          isColumnVisible: true
        },
        {
          id: '4',
          label: 'Phone Number',
          name: 'phoneNumber',
          isColumnVisible: true
        },
        {
          id: '3',
          label: 'Last Activity (in Days)',
          name: 'lastActivity',
          isColumnVisible: true
        },
        {
          id: '16',
          label: 'CutOff Sore',
          name: 'cutoffScore',
          isColumnVisible: true
        },
        {
          id: '17',
          label: 'Actual Sore',
          name: 'actualScore',
          isColumnVisible: true
        },
        {
          id: '5',
          label: 'Current Location',
          name: 'currentLocation',
          isColumnVisible: false
        },
        {
          id: '6',
          label: 'Location Captured On',
          name: 'distanceCapturedOn',
          isColumnVisible: true
        },
        {
          id: '7',
          label: 'Distance (in km)',
          name: 'lastSeenDistance',
          isColumnVisible: true
        },
        {
          id: '8',
          label: 'Mode',
          name: 'travelMode',
          isColumnVisible: true
        },
        {
          id: '9',
          label: 'Availability',
          name: 'availability',
          isColumnVisible: false
        },
        {
          id: '10',
          label: 'Industry',
          name: 'industry',
          isColumnVisible: false
        },
        {
          id: '11',
          label: 'Last Job Experience',
          name: 'lastJobExp',
          isColumnVisible: true
        },
        {
          id: '12',
          label: 'Yrs',
          name: 'expInYears',
          isColumnVisible: true
        },
        {
          id: '13',
          label: 'Rating',
          name: 'averageRating',
          isColumnVisible: true
        },
        {
          id: '14',
          label: 'Total Gigs',
          name: 'totalGigs',
          isColumnVisible: true
        },
        {
          id: '15',
          label: 'Breach',
          name: 'breach',
          isColumnVisible: true
        },
        {
          id: '18',
          label: 'Availability CutOff',
          name: 'availabilityCutOff',
          isColumnVisible: true
        },
        {
          id: '19',
          label: 'Breach CutOff',
          name: 'breachCutOff',
          isColumnVisible: true
        },
        {
          id: '20',
          label: 'Distance CutOff',
          name: 'distanceCutOff',
          isColumnVisible: true
        },
        {
          id: '21',
          label: 'Gender CutOff',
          name: 'genderCutOff',
          isColumnVisible: true
        },
        {
          id: '22',
          label: 'Experience CutOff',
          name: 'expCutOff',
          isColumnVisible: true
        },
        {
          id: '23',
          label: 'Qualification CutOff',
          name: 'qualCutOff',
          isColumnVisible: true
        },
        {
          id: '22',
          label: 'Negative Rating CutOff',
          name: 'negativeRatingCutOff',
          isColumnVisible: true
        },
        {
          id: '4',
          label: 'Address',
          name: 'address',
          isColumnVisible: true
        },
        {
          id: '23',
          label: 'Action',
          name: 'action',
          isColumnVisible: false
        }
        // {
        //   id: '1',
        //   label: 'ID',
        //   name: 'workerId',
        //   isColumnVisible: true
        // },
        // {
        //   id: '2',
        //   label: 'Candidate',
        //   name: 'userName',
        //   isColumnVisible: true
        // },
        // {
        //   id: '3',
        //   label: 'Last Activity (in Days)',
        //   name: 'lastActivity',
        //   isColumnVisible: true
        // },
        // {
        //   id: '4',
        //   label: 'Address',
        //   name: 'address',
        //   isColumnVisible: true
        // },
        // {
        //   id: '5',
        //   label: 'Current Location',
        //   name: 'currentLocation',
        //   isColumnVisible: false
        // },
        // {
        //   id: '6',
        //   label: 'Location Captured On',
        //   name: 'distanceCapturedOn',
        //   isColumnVisible: true
        // },
        // {
        //   id: '7',
        //   label: 'Industry',
        //   name: 'industry',
        //   isColumnVisible: false
        // },
        // {
        //   id: '8',
        //   label: 'Last Job Experience',
        //   name: 'lastJobExp',
        //   isColumnVisible: true
        // },
        // {
        //   id: '9',
        //   label: 'Yrs',
        //   name: 'expInYears',
        //   isColumnVisible: true
        // },
        // {
        //   id: '10',
        //   label: 'CuttOff Score',
        //   name: 'cutoffScore',
        //   isColumnVisible: true
        // },
        // {
        //   id: '11',
        //   label: 'Actual Sore',
        //   name: 'actualScore',
        //   isColumnVisible: true
        // },
        // {
        //   id: '12',
        //   label: 'Rating',
        //   name: 'averageRating',
        //   isColumnVisible: true
        // },
        // {
        //   id: '13',
        //   label: 'OD Jobs',
        //   name: 'totalGigs',
        //   isColumnVisible: true
        // },
        // {
        //   id: '14',
        //   label: 'Breach',
        //   name: 'breach',
        //   isColumnVisible: true
        // },
        // {
        //   id: '15',
        //   label: 'Action',
        //   name: 'action',
        //   isColumnVisible: false
        // }
      ],
      listAction: [
        {
          id: 1,
          name: 'Schedule',
          subIcon: <MdModeEdit className='fitIcon' />,
          route: '/'
        }
      ],
      filterList: [
        {
          industry: ['Restaurant', 'Health', 'Mechanical'],
          id: '1',
          name: 'industry',
          label: 'Industry',
          default: []
        },
        {
          location: ['Delhi', 'Gurgaon', 'Noida'],
          id: '2',
          name: 'location',
          label: 'Location',
          default: []
        },
        {
          status: ['Active', 'Inactive', 'Suspended'],
          id: '3',
          name: 'status',
          label: 'Status',
          default: []
        },
        {
          rating: ['1', '2', '3', '4', '5'],
          id: '4',
          name: 'rating',
          label: 'Rating',
          default: []
        }
      ]
    },
    table3: {
      cols: [
        {
          id: '1',
          label: 'Worker Id',
          name: 'worker_id',
          isColumnVisible: true
        },
        // {
        //   id: '2',
        //   label: 'Assign Id',
        //   name: 'assign_id',
        //   isColumnVisible: true
        // },
        {
          id: '3',
          label: 'Candidate Name',
          name: 'first_name',
          isColumnVisible: true
        },
        // {
        //   id: '4',
        //   label: 'Last Name',
        //   name: 'last_name',
        //   isColumnVisible: true
        // // },
        // {
        //   id: '5',
        //   label: 'Rating',
        //   name: 'average_rating',
        //   isColumnVisible: true
        // },
        // {
        //   id: '6',
        //   label: 'Contact Number',
        //   name: 'phone_number',
        //   isColumnVisible: true
        // },
        // {
        //   id: '7',
        //   label: 'Interview Id',
        //   name: 'interviewId',
        //   isColumnVisible: true
        // },
        {
          id: '23',
          label: 'Interview Address',
          name: 'address',
          isColumnVisible: true
        },
        {
            id: '11',
            label: 'Score',
            name: 'actualScore',
            isColumnVisible: true
          },
        {
          id: '55',
          label: 'Create Interview Slots',
          name: 'isInterview',
          isColumnVisible: true
        },
        {
          id: '98',
          label: 'ReSchedule Interview Slots',
          name: 'rescheduleInterview',
          isColumnVisible: true
        },
        {
          id: '56',
          label: 'Select Interview Slot',
          name: 'selectSlot',
          isColumnVisible: true
        },
        {
          id:'555',
          label:'Cancel Interview',
          name:'cancelInterview',
          isColumnVisible: true
        },
        {
          id: '560',
          label: 'No Show',
          name: 'isNoShow',
          isColumnVisible: true
        },
        {
          id: '13',
          label: 'OTP',
          name: 'otp',
          isColumnVisible: true
        },
        {
          id: '14',
          label: 'Verify OTP',
          name: 'verifyOtp',
          isColumnVisible: true
        },
        {
          id: '8',
          label: 'Interview Conducted',
          name: 'interviewConducted',
          isColumnVisible: true
        },
        {
          id: '9',
          label: 'Interview Cleared',
          name: 'interviewCleared',
          isColumnVisible: true
        },
        // {
        //   id: '10',
        //   label: 'Workerd Hired',
        //   name: 'isWorkerdHired',
        //   isColumnVisible: true
        // },
        // {
        //   id: '12',
        //   label: 'Job Detailed Id',
        //   name: 'jobDetailsId',
        //   isColumnVisible: true
        // },

        // {
        //   id: '14',
        //   label: 'OTP Verified',
        //   name: 'otpVerified',
        //   isColumnVisible: true
        // },
        // {
        //   id: '15',
        //   label: 'Accepted By',
        //   name: 'acceptedBy',
        //   isColumnVisible: true
        // },
        // {
        //   id: '16',
        //   label: 'Slot',
        //   name: 'whichSlot',
        //   isColumnVisible: true
        // },
        // {
        //   id: '17',
        //   label: 'Job Offered',
        //   name: 'is_job_offered',
        //   isColumnVisible: true
        // },
        // {
        //   id: '88',
        //   label: 'Reject Candidate',
        //   name: 'rejectCanditate',
        //   isColumnVisible: true
        // },
        {
          id: '23',
          label: 'Update Salary',
          name: 'updateSalary',
          isColumnVisible: true
        },
        {
          id: '55',
          label: 'Reject Candidate',
          name: 'rejectCandidate',
          isColumnVisible: true
        },
        {
          id: '22',
          label: 'Update Offer',
          name: 'rejectOffer',
          isColumnVisible: true
        },
        {
          id: '27',
          label: 'Joining Date',
          name: 'joining_date',
          isColumnVisible: true
        },
        {
          id: '18',
          label: 'Working',
          name: 'is_worker_hired',
          isColumnVisible: true
        },
        {
          id: '22',
          label: 'Joining Status',
          name: 'workingStatus',
          isColumnVisible: true
        },
        {
          id: '11',
          label: 'Interview Status',
          name: 'interviewStatus',
          isColumnVisible: false
        },

        // {
        //   id: '22',
        //   label: 'Reject Job',
        //   name: 'rejectJob',
        //   isColumnVisible: true
        // },
        // {
        //   id: '19',
        //   label: 'Job Offered Amount',
        //   name: 'job_offered_amount',
        //   isColumnVisible: true
        // },
        // {
        //   id: '20',
        //   label: 'Offer Accepted',
        //   name: 'is_offer_accepted',
        //   isColumnVisible: true
        // },
        // {
        //   id: '21',
        //   label: 'Rejected',
        //   name: 'is_rejected',
        //   isColumnVisible: true
        // },
        {
          id: '22',
          label: 'Joined',
          name: 'is_joined',
          isColumnVisible: false
        },
        {
          id:'56',
          label:'Last Event',
          name:'last_event',
          isColumnVisible: true
        },
        {
          id:'58',
          label:'Last Event Status',
          name:'last_event_status',
          isColumnVisible: true
        },
        {
          id:'60',
          label:'Event Captured On',
          name:'event_captured_on',
          isColumnVisible: true
        },
        // {
        //   id: '24',
        //   label: 'Invoice Amount',
        //   name: 'invoiceAmount',
        //   isColumnVisible: true
        // },
        // {
        //   id: '25',
        //   label: 'Transaction Unique Id',
        //   name: 'transactionUniqueId',
        //   isColumnVisible: true
        // },
        // {
        //   id: '26',
        //   label: 'Offer Accepted Date',
        //   name: 'offer_accepted_date',
        //   isColumnVisible: true
        // },
        // {
        //   id: '28',
        //   label: 'Accepted On',
        //   name: 'accepted_on',
        //   isColumnVisible: true
        // },
        // {
        //   id: '29',
        //   label: 'Reviews',
        //   name: 'total_reviews',
        //   isColumnVisible: true
        // },
        // {
        //   id: '30',
        //   label: 'Inserted By',
        //   name: 'insertedBy',
        //   isColumnVisible: true
        // },
        // {
        //   id: '31',
        //   label: 'Inserted On',
        //   name: 'insertedOn',
        //   isColumnVisible: true
        // },
        // {
        //   id: '32',
        //   label: 'Updated By',
        //   name: 'updatedBy',
        //   isColumnVisible: true
        // },
        // {
        //   id: '33',
        //   label: 'Interview End Time 2',
        //   name: 'interviewEndTime2',
        //   isColumnVisible: true
        // },
        // {
        //   id: '34',
        //   label: 'Interview Start Time 2',
        //   name: 'interviewStartTime2',
        //   isColumnVisible: true
        // },
        // {
        //   id: '35',
        //   label: 'Interview End Time 3',
        //   name: 'interviewEndTime3',
        //   isColumnVisible: true
        // },
        // {
        //   id: '36',
        //   label: 'Interview Start Time 3',
        //   name: 'interviewStartTime3',
        //   isColumnVisible: true
        // },  
        // {
        //   id: '37',
        //   label: 'Accepted On',
        //   name: 'accepted_on',
        //   isColumnVisible: true
        // },
        // {
        //   id: '38',
        //   label: 'Postal Code',
        //   name: 'postalCode',
        //   isColumnVisible: true
        // },
        // {
        //   id: '39',
        //   label: 'Lob LocationLat',
        //   name: 'jobLocationLat',
        //   isColumnVisible: true
        // },
        // {
        //   id: '40',
        //   label: 'Job LocationLong',
        //   name: 'jobLocationLong',
        //   isColumnVisible: true
        // },
        // {
        //   id: '41',
        //   label: 'LandMark',
        //   name: 'landMark',
        //   isColumnVisible: true
        // },
        // {
        //   id: '42',
        //   label: 'Offer Accepted By',
        //   name: 'offer_accepted_by',
        //   isColumnVisible: true
        // },
        // {
        //   id: '43',
        //   label: 'Is Paid',
        //   name: 'isPaid',
        //   isColumnVisible: true
        // },
        {
          id: '44',
          label: 'Action',
          name: 'action',
          isColumnVisible: false
        }
      ],
      listAction: [
        {
          id: 1,
          name: 'ReSchedule Interview',
          subIcon: <ScheduleIcon className='fitIcon' />,
          route: '/'
        },
        {
          id: 1,
          name: 'Cancel Interview',
          subIcon: <CancelScheduleSendIcon className='fitIcon' />,
          route: '/'
        },
        {
          id: 1,
          name: 'Comment',
          subIcon: <MdComment className='fitIcon' />,
          route: '/'
        }
      ]
      // listAction: [
      //   {
      //     id: 1,
      //     name: 'Pay',
      //     subIcon: <MdModeEdit className='fitIcon' />,
      //     route: '/'
      //   },
        
      // ]
    },
  // },
  table4: {
    cols: [
      {
        id: '1',
        label: 'Worker Id',
        name: 'worker_id',
        isColumnVisible: true
      },
      // {
      //   id: '2',
      //   label: 'Assign Id',
      //   name: 'assign_id',
      //   isColumnVisible: true
      // },
      {
        id: '3',
        label: 'Candidate Name',
        name: 'first_name',
        isColumnVisible: true
      },
      // {
      //   id: '4',
      //   label: 'Last Name',
      //   name: 'last_name',
      //   isColumnVisible: true
      // // },
      // {
      //   id: '5',
      //   label: 'Rating',
      //   name: 'average_rating',
      //   isColumnVisible: true
      // },
      // {
      //   id: '6',
      //   label: 'Contact Number',
      //   name: 'phone_number',
      //   isColumnVisible: true
      // },
      // {
      //   id: '7',
      //   label: 'Interview Id',
      //   name: 'interviewId',
      //   isColumnVisible: true
      // },
      {
        id: '23',
        label: 'Address',
        name: 'address',
        isColumnVisible: true
      },
      {
        id: '8',
        label: 'Interview Conducted',
        name: 'interviewConducted',
        isColumnVisible: true
      },
      {
        id: '9',
        label: 'Interview Cleared',
        name: 'interviewCleared',
        isColumnVisible: true
      },
      // {
      //   id: '10',
      //   label: 'Workerd Hired',
      //   name: 'isWorkerdHired',
      //   isColumnVisible: true
      // },
      // {
      //   id: '12',
      //   label: 'Job Detailed Id',
      //   name: 'jobDetailsId',
      //   isColumnVisible: true
      // },
      // {
      //   id: '13',
      //   label: 'OTP',
      //   name: 'otp',
      //   isColumnVisible: true
      // },
      // {
      //   id: '14',
      //   label: 'OTP Verified',
      //   name: 'otpVerified',
      //   isColumnVisible: true
      // },
      // {
      //   id: '15',
      //   label: 'Accepted By',
      //   name: 'acceptedBy',
      //   isColumnVisible: true
      // },
      // {
      //   id: '16',
      //   label: 'Slot',
      //   name: 'whichSlot',
      //   isColumnVisible: true
      // },
      // {
      //   id: '17',
      //   label: 'Job Offered',
      //   name: 'is_job_offered',
      //   isColumnVisible: true
      // },
      {
        id: '27',
        label: 'Joining Date',
        name: 'joining_date',
        isColumnVisible: true
      },
      {
        id: '18',
        label: 'Working',
        name: 'is_worker_hired',
        isColumnVisible: true
      },
      {
        id: '180',
        label: 'Payment',
        name: 'payment',
        isColumnVisible: true
      },
      {
        id: '19',
        label: 'Job Offered Amount',
        name: 'job_offered_amount',
        isColumnVisible: true
      },
      {
        id: '98',
        label: 'Update Salary',
        name: 'updateHiredSalary',
        isColumnVisible: true
      },
      {
        id: '24',
        label: 'Invoice Amount',
        name: 'invoiceAmount',
        isColumnVisible: true
      },
      {
        id: '11',
        label: 'Interview Status',
        name: 'interviewStatus',
        isColumnVisible: false
      },
      // {
      //   id:'56',
      //   label:'Last Event',
      //   name:'last_event',
      //   isColumnVisible: true
      // },
      // {
      //   id:'58',
      //   label:'Last Event Status',
      //   name:'last_event_status',
      //   isColumnVisible: true
      // },
      // {
      //   id:'60',
      //   label:'Event Captured On',
      //   name:'event_captured_on',
      //   isColumnVisible: true
      // },
      // {
      //   id: '20',
      //   label: 'Offer Accepted',
      //   name: 'is_offer_accepted',
      //   isColumnVisible: true
      // },
      // {
      //   id: '21',
      //   label: 'Rejected',
      //   name: 'is_rejected',
      //   isColumnVisible: true
      // },
      // {
      //   id: '22',
      //   label: 'Joined',
      //   name: 'is_joined',
      //   isColumnVisible: true
      // },
      // {
      //   id: '24',
      //   label: 'Invoice Amount',
      //   name: 'invoiceAmount',
      //   isColumnVisible: true
      // },
      // {
      //   id: '25',
      //   label: 'Transaction Unique Id',
      //   name: 'transactionUniqueId',
      //   isColumnVisible: true
      // },
      // {
      //   id: '26',
      //   label: 'Offer Accepted Date',
      //   name: 'offer_accepted_date',
      //   isColumnVisible: true
      // },
      // {
      //   id: '28',
      //   label: 'Accepted On',
      //   name: 'accepted_on',
      //   isColumnVisible: true
      // },
      // {
      //   id: '29',
      //   label: 'Reviews',
      //   name: 'total_reviews',
      //   isColumnVisible: true
      // },
      // {
      //   id: '30',
      //   label: 'Inserted By',
      //   name: 'insertedBy',
      //   isColumnVisible: true
      // },
      // {
      //   id: '31',
      //   label: 'Inserted On',
      //   name: 'insertedOn',
      //   isColumnVisible: true
      // },
      // {
      //   id: '32',
      //   label: 'Updated By',
      //   name: 'updatedBy',
      //   isColumnVisible: true
      // },
      // {
      //   id: '33',
      //   label: 'Interview End Time 2',
      //   name: 'interviewEndTime2',
      //   isColumnVisible: true
      // },
      // {
      //   id: '34',
      //   label: 'Interview Start Time 2',
      //   name: 'interviewStartTime2',
      //   isColumnVisible: true
      // },
      // {
      //   id: '35',
      //   label: 'Interview End Time 3',
      //   name: 'interviewEndTime3',
      //   isColumnVisible: true
      // },
      // {
      //   id: '36',
      //   label: 'Interview Start Time 3',
      //   name: 'interviewStartTime3',
      //   isColumnVisible: true
      // },  
      // {
      //   id: '37',
      //   label: 'Accepted On',
      //   name: 'accepted_on',
      //   isColumnVisible: true
      // },
      // {
      //   id: '38',
      //   label: 'Postal Code',
      //   name: 'postalCode',
      //   isColumnVisible: true
      // },
      // {
      //   id: '39',
      //   label: 'Lob LocationLat',
      //   name: 'jobLocationLat',
      //   isColumnVisible: true
      // },
      // {
      //   id: '40',
      //   label: 'Job LocationLong',
      //   name: 'jobLocationLong',
      //   isColumnVisible: true
      // },
      // {
      //   id: '41',
      //   label: 'LandMark',
      //   name: 'landMark',
      //   isColumnVisible: true
      // },
      // {
      //   id: '42',
      //   label: 'Offer Accepted By',
      //   name: 'offer_accepted_by',
      //   isColumnVisible: true
      // },
      // {
      //   id: '43',
      //   label: 'Is Paid',
      //   name: 'isPaid',
      //   isColumnVisible: true
      // },
      
      {
        id: '44',
        label: 'Mark Worker Left',
        name: 'markWorkerLeft',
        isColumnVisible: true
      },
      {
        id: '44',
        label: 'Action',
        name: 'action',
        isColumnVisible: false
      }
    ],
    listAction: [
      {
        id: 1,
        name: 'ReSchedule Interview',
        subIcon: <ScheduleIcon className='fitIcon' />,
        route: '/'
      },
      {
        id: 1,
        name: 'Cancel Interview',
        subIcon: <CancelScheduleSendIcon className='fitIcon' />,
        route: '/'
      },
      {
        id: 1,
        name: 'Comment',
        subIcon: <MdComment className='fitIcon' />,
        route: '/'
      }
    ]
    // listAction: [
    //   {
    //     id: 1,
    //     name: 'Pay',
    //     subIcon: <MdModeEdit className='fitIcon' />,
    //     route: '/'
    //   },
      
    // ]
  }
}
  }
// };

export default ChildTabelConstant;
