import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Grid} from '@material-ui/core';
import Axios from 'axios'
import 'react-tabs/style/react-tabs.css';
import './interviewDashboard.css'
import urlLinks from '../../../urlLinks'
import { useHistory } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import ActiveApplications from './JobTabs/ActiveApplications'
import MatchedWorkers from './JobTabs/MatchedWorkers'
import MatchNewWorkers from './JobTabs/MatchNewWorkers'
const InterviewDashboard =(props) =>{
    const [loading, setLoading] = useState(false)
    const [disableQuestionnaireButton, setDisableQuestionnaireButton] = useState(1)
    const [data, setData] = useState({})
    const history= useHistory();
    const [currenttab, setCurrenttab] = useState(history?.location?.interviewDashboardData?.currenttab?history?.location?.interviewDashboardData?.currenttab:0)
    console.log({history});
    
    const getJobDetails= () =>{
        Axios.get(`${urlLinks.baseUrl}job/V2/getdetailedjob?job_id=${history.location.id}`)
            .then(response =>{
                if(response)
                {
                    setData({
                        jobId: response.data.response.content[0].jobId,
                        jobDetailsId: response.data.response.content[0].detailsJobs[0].jobDetailsId,
                        brandName: response.data.response.content[0].company.brandName? response.data.response.content[0].company.brandName: response.data.response.content[0].company.companyName,
                        userId: response.data.response.content[0].company.brandName? response.data.response.content[0].company.userId: response.data.response.content[0].company.userId,
                        accountManager:response.data.response.content[0].accountManager ||0,
                        workType: response.data.response.content[0].workType===1?"Full time":response.data.response.content[0].workType===2?"Part time":response.data.response.content[0].workType===3?"On Demand":'',
                        jobTitle: response.data.response.content[0].detailsJobs[0].jobTitle,
                        interviewLat: response.data.response.content[0].detailsJobs[0].jobInterviewDetails?
                            response.data.response.content[0].detailsJobs[0].jobInterviewDetails.interviewLat?
                                response.data.response.content[0].detailsJobs[0].jobInterviewDetails.interviewLat:null
                            :null,
                        interviewLong: response.data.response.content[0].detailsJobs[0].jobInterviewDetails?
                            response.data.response.content[0].detailsJobs[0].jobInterviewDetails.interviewLong?
                                response.data.response.content[0].detailsJobs[0].jobInterviewDetails.interviewLong:null
                            :null,
                        interviewUrl:  response.data.response.content[0].detailsJobs[0].jobInterviewDetails?
                            response.data.response.content[0].detailsJobs[0].jobInterviewDetails.interviewUrl?
                                response.data.response.content[0].detailsJobs[0].jobInterviewDetails.interviewUrl:null
                            :null,
                        replacementPeriod:  response.data.response.content[0].detailsJobs[0].jobPayout?
                            response.data.response.content[0].detailsJobs[0].jobPayout.okaygoReplacementClause?
                                `${response.data.response.content[0].detailsJobs[0].jobPayout.okaygoReplacementClause} days`:null
                            :null,
                        jobInterviewType: response?.data?.response?.content[0]?.jobInterviewType,
                    })
                    setLoading(false)
                }
            })
            .catch(error=> {
                console.log({error});
                setLoading(false)
                
            })
    }
    useEffect(() => {
        setDisableQuestionnaireButton (0)
        if(!history.location.id)
            history.push("/jobs/jobList");
        else{
            setLoading(true);
            Axios.get(`${urlLinks.baseUrl}question_controller/getJobQuestion?job_id=${history.location.id}`)
            .then(response=>{
                let disable= response.data.length
                console.log({disable})
                setDisableQuestionnaireButton(disable>0?2:1);
            })
            console.log(disableQuestionnaireButton);
            getJobDetails();

        }
    }, []) 
    
    return (
        loading ?
        <div style={{margin:"auto", position:"sticky", left:"45%", top: "30%"}}>
            <ScaleLoader
                height={70}
                width={10}
                color={"#1c75bc"}    
            />
        </div>:
        <Grid container>
            <Grid item xs={6} style={{padding:0}}>
                <h1>{data.brandName}</h1>
            </Grid>
            <Grid item xs={6} style={{padding:0, display:"flex", justifyContent: "space-between"}}>
                <h3>{`${data.workType} - ${data.jobTitle}`}</h3>
                {data.replacementPeriod && <h3>{`Replacement period: ${data.replacementPeriod}`}</h3>}
            </Grid>
            <Grid item xs={12}  style={{padding:0}}>
                <Tabs className="interviewDashboardTabContainer" selectedIndex={currenttab} onSelect={tabIndex => setCurrenttab(tabIndex)}>
                    <TabList className="interviewDashboardTabs">
                        <Tab>Active applications </Tab>
                        <Tab>Matched workers</Tab>
                        <Tab>Match new workers</Tab>
                    </TabList>
                    <TabPanel  >
                        {Object.keys(data).length>0 && <ActiveApplications disableQuestionnaireButton={disableQuestionnaireButton===2?false:true} data={data} currenttab={currenttab} interviewDashboardData= {history?.location?.interviewDashboardData} />}
                    </TabPanel>
                    <TabPanel >
                        {Object.keys(data).length>0 && <MatchedWorkers disableQuestionnaireButton={disableQuestionnaireButton===2?false:true} data={data}  currenttab={currenttab} interviewDashboardData= {history?.location?.interviewDashboardData}  />}
                    </TabPanel>
                    <TabPanel >
                        {Object.keys(data).length>0 && <MatchNewWorkers data={data}  currenttab={currenttab} interviewDashboardData= {history?.location?.interviewDashboardData}  />}
                    </TabPanel>
                </Tabs>
            </Grid>

        </Grid>
    )
}

export default InterviewDashboard