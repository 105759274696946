import React, {Component}from 'react';
import TextField from '@material-ui/core/TextField';
import { Typography,Container,Grid,withStyles,Button } from '@material-ui/core';

const styles = () => ({
    root: {
      margin: 'auto',
      flexGrow: 1,
    },
    margin: {
      margin: '2px',
    }
});

class CustomTextField extends Component{
    constructor(props) {
        super(props);
        this.state = {
            // value:this.props.value
        }; 
        this.value=this.props.value
        this.label=this.props.label;
        this.obj=this.props.obj;
        this.id=this.props.questionId;
        this.placeHolder=this.props.placeHolder;
        this.forceUpdate();
    }

    handleChange=(event)=>{

        if(this.label=="Option 1"){
            this.obj.first_parameter=event.target.value;
        }else if(this.label=="Option 2"){
            this.obj.second_parameter=event.target.value;
        }else if(this.label=="Option 3"){
            this.obj.third_parameter=event.target.value;
        }else if(this.label=="Option 4"){
            this.obj.forth_parameter=event.target.value;
        }else if(this.label=="Option 5"){
            this.obj.fifth_parameter=event.target.value;
        }
        this.value=event.target.value
        this.forceUpdate();
        this.props.prameterHandleChange(this.value, this.obj)
    }

    componentDidMount(){
        this.forceUpdate();
       // this.props.setData();
       
        if(this.label=="Option 1"){
            this.value=this.props.obj.first_parameter
            this.forceUpdate();
        }else if(this.label=="Option 2"){
            this.value=this.props.obj.second_parameter
            this.forceUpdate();
        }else if(this.label=="Option 3"){
            this.value=this.props.obj.third_parameter
            this.forceUpdate();
        }else if(this.label=="Option 4"){
            this.value=this.props.obj.forth_parameter
            this.forceUpdate();
        }else if(this.label=="Option 5"){
            this.value=this.props.obj.fifth_parameter
            this.forceUpdate();
        }
        this.forceUpdate();
    }

    // componentWillReceiveProps(){
    //     this.obj=this.obj;
    //     if(this.label=="Option 1"){
    //         this.setState({value:this.obj.first_parameter})
    //     }else if(this.label=="Option 2"){
    //         this.setState({value:this.obj.second_parameter})
    //     }else if(this.label=="Option 3"){
    //         this.setState({value:this.obj.third_parameter})
    //     }else if(this.label=="Option 4"){
    //         this.setState({value:this.obj.forth_parameter})
    //     }else if(this.label=="Option 5"){
    //         this.setState({value:this.obj.fifth_parameter})
    //     }
    // }
    forceUpdateHandler(){
        this.forceUpdate();
      };
    render() {
       // this.forceUpdate();
        console.log(this.props)
        console.log(this.id)
        const { classes } = this.props;

        return (          
            <TextField 
           
            id="outlined-basic" 
            label={this.props.placeHolder} 
            variant="outlined"
            value={this.label=='Option 1'? this.props.obj.first_parameter:
            this.label=='Option 2'?this.props.obj.second_parameter:this.label=='Option 3'?this.props.obj.third_parameter:
            this.label=='Option 4'?this.props.obj.forth_parameter:this.label=='Option 5'?this.props.obj.forth_parameter:null }
            inputProps={{ style: { paddingTop: 10.5, paddingBottom: 10.5 } }}
            onChange={this.handleChange}
            />
          );
    }

}
export default withStyles(styles)(CustomTextField);