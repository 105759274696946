import { Auth } from 'aws-amplify';

const SignOut = async (event, props, context) => {
  const { updateLoginState } = context;
  try {
    await Auth.signOut().then(() => {
      updateLoginState();
      props.history.push('/Login');
    });
  } catch (e) {
    alert(e.message);
  }
};
export default SignOut;
