import React, { Component } from 'react';
import Constant from '../../../constant';
import CustomTable from '../../../utils/Table/Table';
import jobScreeningUtils from '../screening/screeningUtils';
import { RingLoader } from 'react-spinners';
import './job.css';
import Axios from 'axios';
import FilterBase from '../../../utils/filters/filterbase'
import * as moment from 'moment'
import { Grid, Card } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
// import createColumnnsAndData from '../../../createColumnsAndData';
// import urls from "../../../urlLinks"
import { withStyles } from '@material-ui/core/styles';
import urlLinks from '../../../urlLinks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from '../../../utils/simpleSelect/nativeSelect';
// import DSelect from '../../../utils/simpleSelect/Direction';


const style = {
  SearchBar: {
    marginTop: '-2%',
    marginLeft: '10%'
  },
  select:{
    marginTop:'-2%',
    marginLeft:'2%'
  }
};

class Job extends Component {
  constructor(props) {
    super(props);
    this.Sdata='';
    this.state = {
      type:'',
      label:'',
      eValue:'',
      type1:'',
      label1:'',
      eValue1:'ascending',
      uiLabel:'ByPera',
      uiLabel1:'ByDir',
      sortAccessed: false,
      sortingDirection: true,
      jobTypeList:[],
      list: [],
      filters: {
        brandName: [],
        location:[],
        jobType: [],
        jobStatus: [""],
        addedBy: [""],
        workType: [""],
        jobPostDate:[null, null],
        jobDurationDate:[null, null]
      },
      filterUrl:'',
      isLoading: true,
      url: `${urlLinks.baseUrl}/job/V2/getdetailedjob?`,
      isTableUpdating: false,
      paramsAvailable: false,
      open: false,
      column: [
        {
          id: '2',
          label: Constant.job.jobtype,
          name: 'workType',
          isColumnVisible: true
        },
        {
          id:"1",
          label:Constant.job.status,
          name:"status",
          isColumnVisible:true
        },
        {
          id:'452548',
          label:Constant.job.boostJob,
          name:'boostJob',
          isColumnVisible:true
        },
        {
          id: '20',
          label: Constant.job.jobId,
          name: 'jobId',
          isColumnVisible: true
        },
        {
          id: '3',
          label: Constant.job.jobDetailsId,
          name: 'jobDetailsId',
          isColumnVisible: true
        },
        {
          id: '301',
          label: Constant.job.encryptId,
          name: 'encryptId',
          isColumnVisible: true
        },
        {
          id: '4',
          label: Constant.job.brandName,
          name: 'companyId',
          isColumnVisible: true
        },
        {
          id:'54',
          label:Constant.job.contactPerson,
          name:'contactPerson',
          isColumnVisible:true
        },
        {
          id:'5230',
          label:Constant.job.contactNo,
          name:'contactNo',
          isColumnVisible:true
        },
        {
          id:'828',
          label:Constant.job.accountManager,
          name:'accountManager',
          isColumnVisible:true
        },
        {
          id:'1210',
          label:"Contact email",
          name:'emailAccountManager',
          isColumnVisible:true
        },
        {
          id:'2002',
          label:Constant.job.contactCandidateName,
          name:'contactCandidateName',
          isColumnVisible:true
        },
        {
          id:'2012',
          label:Constant.job.contactCandidateNumber,
          name:'contactCandidateNumber',
          isColumnVisible:true
        },
        {
          id: '5',
          label: Constant.job.date,
          name: 'insertedOn',
          isColumnVisible: true
        },
        {
          id: '6',
          label: Constant.job.jobDate,
          name: 'startDate',
          isColumnVisible: true
        },
        {
          id: '13',
          label: Constant.job.endDate,
          name: 'endDate',
          isColumnVisible: true
        },
        {
          id: '7',
          label: Constant.job.timeStart,
          name: 'loginTime',
          isColumnVisible: true
        },

        {
          id: '8',
          label: Constant.job.timeEnd,
          name: 'logoutTime',
          isColumnVisible: true
        },

        {
          id:'8989',
          label:Constant.job.city,
          name:'city',
          isColumnVisible:true
        },

        {
          id: '9',
          label: Constant.job.location,
          name: 'address',
          isColumnVisible: true
        },
        {
          id: '10',
          label: Constant.job.title,
          name: 'jobTitle',
          isColumnVisible: true
        },
        {
          id: '100',
          label: Constant.job.category,
          name: 'jobCategoryName',
          isColumnVisible: true
        },
        {
          id: '18',
          label: Constant.job.gender,
          name: 'gender',
          isColumnVisible: true
        },
        {
          id:'546',
          label:Constant.job.age,
          name:'age',
          isColumnVisible: true
        },
        {
          id: '19',
          label: Constant.job.qualification,
          name: 'qualification',
          isColumnVisible: true
        },
        {
          id:'8596',
          label:Constant.job.cvRequired,
          name:'cvRequired',
          isColumnVisible: true
        },
        {
          id:'546',
          label:Constant.job.englishFluency,
          name:'englishFluency',
          isColumnVisible: true
        },
        {
          id:'5200',
          label:Constant.job.vacancyType,
          name:'vacancyType',
          isColumnVisible: true
        },
        {
          id: '20',
          label: Constant.job.experience,
          name: 'experience',
          isColumnVisible: true
        },
        {
          id: '11',
          label: Constant.job.salary,
          name: 'amount',
          isColumnVisible: true
        },
        {
          id: '12',
          label: Constant.job.salaryType,
          name: 'amountPer',
          isColumnVisible: true
        },
        // {
        //   id: '14',
        //   label: Constant.job.specialRequirements,
        //   name: 'specialRequirements',
        //   isColumnVisible: true
        // },
        {
          id: '15',
          label: Constant.job.quantity,
          name: 'quantity',
          isColumnVisible: true
        },
        {
          id: '25',
          label: Constant.job.addedBy,
          name: 'addedBy',
          isColumnVisible: true
        },
        {
          id: '16',
          label: Constant.job.jobStatus,
          name: 'jobStatus',
          isColumnVisible: true
        },
        {
          id: '17',
          label: 'Action',
          name: 'action',
          isColumnVisible: true
        }
      ],
      currentPage: 0,
      count: 0,
      rowsPerPage: 10,
      rowsOptions: [5, 10, 50, 100, 250, 500]
    };
    
    
    this.option = [
      {
        id: 1,
        type: 1,//int
        label: 'Job ID',
        eValue:'job.job_id'
      },
      {
        id: 2,
        type:2,//String
        label:'Contact Person',
        eValue:'job.contact_person'
      },
      {
        id: 3,
        type:2,
        label:'Status',
        eValue:'job.job_status'
      },
      {
        id: 4,
        type: 1,//integer
        label: 'Category',
        eValue:'job.work_type'
      },
      {
        id:78,
        type:1,
        label:'City',
        eValue:'job.city_id'
      },
      {
        id:5,
        type: 3,//date
        label: 'Job Start Date',
        eValue:'job.start_date'
      },
      {
        id:6,
        type:3,
        label:'Job End Date',
        eValue:'job.end_date'
      },
      {
        id:7,
        type:4,
        label:'Job Login Time',
        eValue:'job.login_time'
      },
        {
        id:9,
        type:3,
        label:'Inserted On',
        eValue:'job.inserted_on'
      }
    ];
    this.option1=[
      {
      id:1,
      label:"ASC",
      eValue:'ascending',
      type:2
      },
      {
        id:2,
        label:"DES",
        eValue:'descending',
        type:2
      }
    ];

    this.data = [];
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChangeToggle = this.handleChangeToggle.bind(this);
    this.updateColumnFileds = this.updateColumnFileds.bind(this);
    this.convertJobIdtoHex = this.convertJobIdtoHex.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.updateDataOnTablePagination = this.updateDataOnTablePagination.bind(
      this
    );
  }
  updateColumnFileds(columnsToHide) {
    const upadtedColumns = this.state.column.map(col => {
      if (columnsToHide.includes(col.id)) {
        col.isColumnVisible = true;
      } else {
        col.isColumnVisible = false;
      }
      return col;
    });

    this.setState({
      columns: upadtedColumns
    });
  }
  // async updateFilters(recievedFilters) {
  //   const updatedFilter = this.state.filters.map(filter => {
  //     for (let el of recievedFilters) {
  //       if (el[filter.name]) {
  //         filter.default = el[filter.name];
  //       }
  //     }
  //     return filter;
  //   });
  //   await this.setState({
  //     filters: updatedFilter
  //   });
  // }

  async updateFilters(filters) {
    let url='';
    if(filters["brandName"].length!==0){
      let companyIds = []
      filters["brandName"].map(brand => companyIds.push(brand.id))
      url=`${url}&user_id=${companyIds}`
    }
    if(filters["location"].length!==0){
      let cityIds = []
      filters["location"].map(city => cityIds.push(city.id))
      url=`${url}&city_id=${cityIds}`
    }
    if(filters["jobType"].length!==0){
      let jobTypeIds = []
      filters["jobType"].map(jobType => jobTypeIds.push(jobType.id))
      url=`${url}&job_type=${jobTypeIds}`
    }
    if(!filters["jobStatus"].includes(""))
      url=`${url}&job_status=${filters["jobStatus"]}`
    if(!filters["addedBy"].includes(""))
      url=`${url}&inserted_by=${filters["addedBy"]}`
    if(!filters["workType"].includes(""))
      url=`${url}&work_type=${filters["workType"]}`
    if(filters["rating"])
      url=`${url}&rating=${filters["rating"]}`
    if(filters["jobPostDate"][0] && !filters["jobPostDate"][1])
      url=`${url}&posted_from=${moment(filters["jobPostDate"][0]).format("YYYY-MM-DD")}&posted_to=${moment(new Date()).format("YYYY-MM-DD")}`
    if(!filters["jobPostDate"][0] && filters["jobPostDate"][1])
      url=`${url}&posted_from=2019-01-01&posted_to=${moment(filters["jobPostDate"][1]).format("YYYY-MM-DD")}`
    if(filters["jobPostDate"][0] && filters["jobPostDate"][1])
      url=`${url}&posted_from=${moment(filters["jobPostDate"][0]).format("YYYY-MM-DD")}&posted_to=${moment(filters["jobPostDate"][1]).format("YYYY-MM-DD")}`
    if(filters["jobDurationDate"][0] && !filters["jobDurationDate"][1])
      url=`${url}&start_date=${moment(filters["jobDurationDate"][0]).format("YYYY-MM-DD")}&end_date=${moment(new Date()).format("YYYY-MM-DD")}`
    if(!filters["jobDurationDate"][0] && filters["jobDurationDate"][1])
      url=`${url}&start_date=2019-01-01&end_date=${moment(filters["jobDurationDate"][1]).format("YYYY-MM-DD")}`
    if(filters["jobDurationDate"][0] && filters["jobDurationDate"][1])
      url=`${url}&start_date=${moment(filters["jobDurationDate"][0]).format("YYYY-MM-DD")}&end_date=${moment(filters["jobDurationDate"][1]).format("YYYY-MM-DD")}`
    console.log({url});
    this.setState({filters: filters, currentPage: 0, filterUrl: url, },  () => this.getJobList())
  }
  resetFilters =() =>{
    const filters= {
      brandName: [],
      location:[],
      jobType: [],
      jobStatus: [""],
      addedBy: [""],
      workType: [""],
      jobPostDate:[null, null],
      jobDurationDate:[null, null]
    }
    this.setState({filterUrl: '', currentPage: 0, filters: filters}, () => this.getJobList())
    return filters;
  }

  mapIdToworkType = id => {
    const workTypes = {
      1: 'Full Time',
      2: 'Part Time',
      3: 'On Demand'
    };
    return workTypes[id];
  };
  
  updateDataOnTablePagination(
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) {
    this.setState({ isTableUpdating: true }, () => {
      this.getJobList(rowsPerPage, nextPage);
    });
  }
  accountManager =(data) =>{
    const allAdmins=JSON.parse(localStorage.getItem('allAdmins'))
    return allAdmins[data]?allAdmins[data]:"Manager not assigned";
  }
  makeData = data => {
    console.log({data});
    const extractedData = [];
    data.map(el => {
      el.detailsJobs.map(el2 => {
        let qualificationValue = el2.detailSkills.map(skill => skill.skillName === 'Qualification' ? skill.skillValue : null);
        let genderValue = el2.detailSkills.map(skill => skill.skillName === 'Gender' ? skill.skillValue : null);
        let experienceValue = el2.detailSkills.map(skill => skill.skillName === 'Experience' ? skill.skillValue : null);
        genderValue = genderValue.filter(el4 => {return el4 !== null});
        qualificationValue = qualificationValue.filter(el4 => {return el4 !== null});
        experienceValue = experienceValue.filter(el4 => el4 !== null);
        // console.log(qualificationValue)
        // console.log(genderValue)
        // console.log(experienceValue)
        const allAdmins = JSON.parse(localStorage.getItem('allAdmins')) 
        extractedData.push({
          workType:
            el.workType === null ? '-' : this.mapIdToworkType(el.workType),
          status: el.jobStatus===1 ? 'Active' :el.jobStatus===2 ? 'InActive' : 'Closed',
          jobId: el.jobId ? el.jobId : '-',
          age:el.detailsJobs[0].minAge ? el.detailsJobs[0].minAge+" to "+el.detailsJobs[0].maxAge:"--",
          cvRequired:el.detailsJobs[0].cvRequired ? el.detailsJobs[0].cvRequired===0 ? "Not Required" : el.detailsJobs[0].cvRequired===1 ? "Optional" : el.detailsJobs[0].cvRequired===2 ? "Required" :'--':'--', 
          englishFluency:el.detailsJobs[0].englishFluency? el.detailsJobs[0].englishFluency===1?'No English': el.detailsJobs[0].englishFluency===2 ? "Thoda English": el.detailsJobs[0].englishFluency===3?"Good English":"--":"--",
          vacancyType:el.detailsJobs[0].vacancyType? el.detailsJobs[0].vacancyType:'--', 
          accountManager:el.accountManager? this.accountManager(el.accountManager):'--',
          contactNo:el.contactNo?el.contactNo:'--',
          emailAccountManager:el.emailAccountManager?el.emailAccountManager:'--',
          contactCandidateName:el.contactCandidateName?el.contactCandidateName:'--',
          contactCandidateNumber:el.contactCandidateNumber?el.contactCandidateNumber:'--',
          boostJob:el.detailsJobs[0].boostJob?el.detailsJobs[0].boostJob===1?"Boosted":'--':'--',
          jobDetailsId: el2.jobDetailsId ? el2.jobDetailsId : '-',
          encryptId: el2.jobDetailsId ? this.convertJobIdtoHex(el2.jobDetailsId) : '-',
          contactPerson:el.contactPerson?el.contactPerson:'-',
          city:el.city_name?`${el.city_name}${el.state_name?", " :''}${el.state_name?el.state_name:''}`: '-',
          jobCategoryName:el2.jobCategoryName?el2.jobCategoryName:'-',
          companyId: el.company['brandName']
            ? el.company['brandName']
            : el.company['companyName'],
          insertedOn: el.insertedOn
            ? `${el.insertedOn
                .substring(0, 10)
                .toString()
                .split('-')
                .reverse()
                .join('-')} ${el.insertedOn.substring(11, 20)}`
            : '-',
          startDate: el.startDate
            ? el.startDate
                .substring(0, 10)
                .toString()
                .split('-')
                .reverse()
                .join('-')
            : '-',
          endDate: el.endDate
            ? el.endDate
                .substring(0, 10)
                .toString()
                .split('-')
                .reverse()
                .join('-')
            : el.workdaysCount && (el.workType === 3) ? `${el.workdaysCount} Days` : '-',
          loginTime: el.loginTime ? el.loginTime : '-',
          logoutTime: el.logoutTime ? el.logoutTime : '-',
          address: el.address ? el.address : '-',
          jobTitle: el2.jobTitle ? el2.jobTitle : '-',
          gender: el2.gender===26?"Male":el2.gender===27?"Female":"Any",
          qualification: qualificationValue[0] || '-',
          experience: experienceValue[0] || '-',
          amount: el.workTypeKey === "On Demand" ? el2.amount : el2.amountRange ? el2.amountRange : '-',
          addedBy: el.insertedBy ? allAdmins[el.insertedBy] ? allAdmins[el.insertedBy] : 'Employer' : '-', 
          amountPer: el2.amountPer ? el2.amountPer : '-',
          requirements: el2.additionalRequirement
            ? el2.additionalRequirement
            : '-',
          specialRequirements: el2.detailSkills
            .map(el3 => (el3.skillName !== 'Qualification' && el3.skillName !== 'Gender' && el3.skillName !== 'Experience') ? el3.skillValue : '')
            .filter(el4 => {return el4 !== ''})
            .join(', ') || '-',
          quantity: `${el2.employeeHired}/${el2.employeeRequired}`,
          jobStatus:  el.jobStatus===1 ? el2.employeeHired === el2.employeeRequired ? 'Hired' : 'Hiring' :el.jobStatus===2?  'On hold' : "Closed",
        });
      });
    });
    return extractedData;
  };
  convertJobIdtoHex(val){
    var s = "abcde" + val.toString(36);
    // console.log("called");
    return " "+s.substring(s.length - 5).toUpperCase();
  }

  getJobList = (rowsPerPage = this.state.rowsPerPage,nextPage = this.state.currentPage) => {
    // console.log("GET JOB LIST CALLED");
    let url = '';
    url = `${this.state.url}page_no=${nextPage}&rows=${rowsPerPage}${this.state.filterUrl}`;
    if( this.state.eValue && this.state.eValue1 ){
      url = `${url}&orderBy=${this.state.eValue}&sortBy=${this.state.eValue1}`;
    }if(this.state.eValue && !this.state.eValue1){
      url = `${url}&orderBy=${this.state.eValue}`;
    }
    //  console.log(url);
    Axios.get(url)
      .then(response => {
        this.setState({list:[]})
        
        this.Sdata=[];
        this.Sdata=response.data.response.content;
        // console.log(this.Sdata);
        const data = this.makeData(this.Sdata);
        console.log(data);
        // console.log(this.state.list)
        // console.log("*****");
        this.setState({
          list: data,
          count: response.data.response.totalElements,
          currentPage: nextPage,
          rowsPerPage: rowsPerPage,
          isLoading: false,
          isTableUpdating: false
        },()=>(this.state.list));
      })
      .catch(error => {
        toast.error('Error While Loading JobList');
      });
  };
  getCompanyList = (data) =>{
    let list=[];
    data.map(company => 
      list.push({
        id: company.userId,
        name: company.brandName?company.brandName: company.companyName
      })
    )
    this.setState({companyList: list});
  }
  getJobTypeList = (data) =>{
    let list=[];
    data.map(jobType =>
      list.push({
        id: jobType.id,
        name: jobType.categorySubType
      })
    )
    this.setState({jobTypeList: list});
  }

  componentDidMount() {

    Axios.get(`${urlLinks.baseUrl}company/?rows=1000`)
      .then(response =>{  
        this.getCompanyList(response.data.response.content)
      })
    Axios.get(`${urlLinks.baseUrl}/configmaster/?category_type=job_type&inserted_by=0&rows=100&type_desc=job_type_name`)
      .then(response =>{
        this.getJobTypeList(response.data.response.content)
      })
    this.getJobList();
  }


  handleChange(event){
    // console.log(event);
    this.setState({ sortAccessed: true,  label:event.target.value.label, eValue:event.target.value.eValue,isTableUpdating:true},()=>this.getJobList(this.state.rowsPerPage,this.state.currentPage));
  }

  handleChange1(event){
    // console.log(event)
    this.setState({label1:event.target.value.label, eValue1:event.target.value.eValue,isTableUpdating:true},()=>this.getJobList(this.state.rowsPerPage,this.state.currentPage));
  }
  handleChangeToggle(){
    // console.log()
    let direction= "asccending"
    !this.state.sortingDirection?direction= "ascending":direction= "descending";
    // console.log({direction});
    
    this.setState({sortingDirection: !this.state.sortingDirection, eValue1:direction,isTableUpdating:true},()=>this.getJobList(this.state.rowsPerPage,this.state.currentPage));
  }

  render() {
    // console.log(this.state)
    const { classes } = this.props;
    const tablePagination = {
      currentPage: this.state.currentPage,
      count: this.state.count,
      changePage: this.updateDataOnTablePagination,
      rowsOptions: this.state.rowsOptions,
      rowsPerPage: this.state.rowsPerPage,
      tableUpdating: this.state.isTableUpdating
    };
    return this.state.isLoading ? (
      <RingLoader
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto'
        }}
        color={'#194D33'}
      ></RingLoader>
    ) : (
      <div>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={4000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <Card style={{ margin: '20px 0px', padding: '20px' }}>
          <Grid container>
            <Grid item sm={3}>
              <HideUnhideColumns
                columns={this.state.column}
                toggleColumn={this.updateColumnFileds}
              ></HideUnhideColumns>
            </Grid>
            <Grid item sm={3}>
              <FilterBase
                filters={this.state.filters}
                updateFilters={this.updateFilters}
                resetFilters={this.resetFilters}
                companyList={this.state.companyList}
                jobTypeList={this.state.jobTypeList}
                page="jobDB"
              />
            </Grid>
            <Grid item sm={2} className={classes.SearchBar}>
              <Select
                val={this.state.label}
                type={this.state.type}
                option={this.option}
                label={this.uiLabel}
                handleChange={this.handleChange}>
              </Select>
            </Grid>
            <Grid item sm={1} >
              {this.state.sortAccessed && 
                  <ToggleButton
                      className="sortDir"
                      value="check"
                      selected={this.state.sortingDirection}
                      onChange={this.handleChangeToggle}
                    >
                      {this.state.sortingDirection? 'A to Z':'Z to A' }
                  </ToggleButton>
                }
            </Grid>

          </Grid>
        </Card>
        <CustomTable
          name={Constant.job.idName}
          idColumn={'jobDetailsId'}
          columns={this.state.column}
          data={this.state.list}
          numericIndex={true}
          links={jobScreeningUtils.screeningListAction}
          title={jobScreeningUtils.title}
          tablePagination={tablePagination}
        ></CustomTable>
      </div>
    );
  }
}
export default withStyles(style)(Job);
