import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import styled from 'styled-components'
// import DoneSharpIcon from '@material-ui/icons/DoneSharp';
// import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import ScaleLoader from "react-spinners/ScaleLoader";
import Pagination from '../../utils/react-table/pagination'
import { Grid, Button, Select, MenuItem, IconButton, Modal, Checkbox, Backdrop, Slide, Zoom, TableSortLabel } from '@material-ui/core';
import urlLinks from '../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import CustomizedDialogs from './PaymentUploadsModal';
import AlertDialog from './AlertDialog'
import { ExcelRenderer } from "react-excel-renderer";
// import CVuploadDownload from './Functions/cvUploadDownload'
import { CSVLink } from 'react-csv';
import { ToastContainer, toast } from 'react-toastify';
import Filters from './../Sales tracker/filterModal'
// import styles from './index.module.scss'
// import EditableTextInput from "./Functions/EditableTextInput"
import EditableDropdown from "./functions/EditableDropdown"

import Typography from '@material-ui/core/Typography';
// import ResponseModal from './ResponseModal'
import { Link } from 'react-router-dom';
const Styles = styled.div`
padding: 1rem;
.tableContainer{
    overFlow-x: scroll;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    height:700px;
}
table {
    border-spacing: 0;
    height:680px;
    width: max-content;
    tr {
    :last-child {
        td {
        border-bottom: 0;
        }
    }
    }
    th {
        :first-child{
            border-top-left-radius:5px;
        }
        :last-child{
            border-top-right-radius:5px;
                border-left: 0;
        }
        background-color: #1c75bc !important;

    }
    th,td{
        padding:7px 15px;
    }
    
    td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    background-color: white;
    nth-last-child(2) {
        border-right: 0;
    } 
    :last-child {
        border-right: 0;
    }

    input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
    }Invoice Status
    }
}

.pagination {
    padding: 0.5rem;
}
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const WorkerLedgerV2 = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [filterUrl, setFilterUrl] = useState('')
    const [sortUrl, setSortUrl] = useState('')
    const [filters, setFilters] = useState({

    })
    const [openResponseModalDialog, setOpenResponseModalDialog] = useState(false)
    const [response, setResponse] = useState(null)
    console.log("rere")
    const columns = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} disabled={true} />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.invoiceFilePath ? false : true} />
                    </div>
                ),
            },
            //change in emp emp
            {
                Header: "ID",
                accessor: "paymentUploadId",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Name",
                accessor: "name",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Job Type",
                accessor: "jobType",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Job",
                accessor: "job",
                defaultCanFilter: false,
                defaultCanSort: false,
                // Cell: CVuploadDownload,
                isVisible: true
            },
            {
                Header: "Payout for",
                accessor: "payoutMonth",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice Date",
                accessor: "invoiceDate",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice No",
                accessor: "invoiceNo",
                Cell: row => {
                    if (row.row.original.invoiceFilePath != null)
                        // return
                        return row.row.original.invoiceFilePath ? <Link to={{ pathname: `${row.row.original.invoiceFilePath}` }} target="_blank" className="nameLink">{row.row.original.invoiceNo}</Link> : <span></span>
                    else
                        return row.row.original.invoiceNo
                },
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice Amount",
                accessor: "totalAmount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Approval Status",
                accessor: "approvalStatus",
                Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Payment Remarks",
                accessor: "paymentRemarks",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },

            {
                Header: "Upi Id",
                accessor: "upiId",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Payout Id",
                accessor: "payoutId",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },

            
            {
                Header: "Last updated on",
                accessor: "lastUpdatedOn",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            }
            // {
            //     Header: "Invoice No.",
            //     accessor: "invoiceNo",
            //     Cell: EditableTextInput,
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
        ],
        []
    );

    const showToast = (type, message) => {
        if (type === "success")
            return toast.success(message);
        else if (type === "error")
            return toast.error(message);
    }

    const updateMyData = () => {
        getTrackerList(perPage, currentPage);
    }
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected, setHiddenColumns, state: { hiddenColumns, selectedRowIds, sortBy } } = useTable({ columns, data, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)

    //added by Vaibhav
    const setWorkType = (type) => {
        if (type == 1)
            return "Full Time";
        else if (type == 2)
            return "Part Time";
        else if (type == 3)
            return "On Demand";

    }
    const makeData = (response) => {

        let extractedData = [];
        response.content.map(el => {
            console.log({ el })
            extractedData.push({

                paymentUploadId: el.paymentUploadId == null ? '-' : el.paymentUploadId,
                workerId: el.workerId == null ? '-' : el.workerId,
                name: el.name == null ? '-' : el.name,
                jobType: el.jobType == null ? '-' : setWorkType(el.jobType),
                job: el.job == null ? '-' : el.job,
                paymentStatus: el.paymentStatus == null ? '-' : el.paymentStatus,
                paymentRemarks: el.paymentRemarks == null ? '-' : el.paymentRemarks,
                upiId: el.upiId == null ? '-' : el.upiId,
                payoutId: el.payoutId == null ? '-' : el.payoutId,
                totalAmount: el.totalAmount == null ? '-' : `₹ ` + el.totalAmount + ` /-`,
                intExt: el.intExt == null ? '-' : el.intExt,
                jobId: el.jobId == null ? '-' : el.jobId,
                payoutMonth: el.payoutMonth == null ? '-' : el.payoutMonth,
                empInvoiceNo: el.empInvoiceNo == null ? '-' : el.empInvoiceNo,
                particular: el.particular == null ? '-' : el.particular,
                insertedOn: el.insertedOn == null ? '-' : el.insertedOn,
                insertedBy: el.insertedBy == null ? '-' : el.insertedBy,
                invoiceDate: el.invoiceDate == null ? '-' : moment(el.invoiceDate).format("DD/MM/YYYY"),
                invoiceNo: el.invoiceNo == null ? '-' : el.invoiceNo,
                invoiceFileName: el.invoiceFileName == null ? '-' : el.invoiceFileName,
                invoiceFilePath: el.invoiceFilePath == null ? null : el.invoiceFilePath,
                isInvoiceCreated: el.isInvoiceCreated == null ? '-' : el.isInvoiceCreated,
                
                approvalStatus: el.approvalStatus ,
                
                paymentRemarks: el.paymentRemarks == null ? '-' : el.paymentRemarks,

                lastUpdatedOn: el.lastUpdatedOn == null ? '-' : moment(el.lastUpdatedOn).format("DD/MM/YYYY")
            });
            console.log(extractedData.approvalStatus)
            // let extractedData = [];
            console.log(localStorage.setItem('paymentUploadId', extractedData.paymentUploadId));
            console.log(localStorage.getItem('paymentUploadId'))
        });
        console.log("Hello makeData ended");
        return extractedData;
    }

    const openResponseModal = () => {
        setOpenResponseModalDialog(true)
        console.log(openResponseModalDialog)
    }
    const closeResponseModal = () => {
        // setResponse(null)
        setOpenResponseModalDialog(false)
    }
    // const modifyResponse = (resp) => {// 
    //     let a = resp['job_not_associated_with_worker_id']
    //     let b = ``
    //     for (let i = 0; i < a.length; i++) {
    //         b = b + a[i];
    //         b = b + ','
    //     }
    //     if (b.length > 1)
    //         b = b.slice(0, b.length - 1)
    //     resp['job_not_associated_with_worker_id'] = b
    //     ///////////////////////////////////////////////////
    //     a = resp['wrong_records']
    //     b = ``
    //     for (let i = 0; i < a.length; i++) {
    //         b = b + a[i];
    //         b = b + ','
    //     }
    //     if (b.length > 1)
    //         b = b.slice(0, b.length - 1)
    //     resp['wrong_records'] = b
    //     setResponse(resp)
    // }

    // const handleExcel = (event) => {
    //     console.warn("Came")
    //     var fileObj = event.target.files[0];
    //     if (event.target.files[0]) {
    //         ExcelRenderer(fileObj, (err, resp) => {
    //             if (err) {
    //                 console.log(err);
    //             }
    //             else {
    //                 // console.log({ resp })
    //                 const formData = new FormData();
    //                 formData.append('file', fileObj)
    //                 Axios.post(`${urlLinks.baseUrl}/workermaster/uploadInvoice`, formData, {
    //                     headers: {
    //                         'content-type': 'multipart/form-data'
    //                     }
    //                 })
    //                     .then(response => {
    //                         updateMyData()
    //                         openResponseModal()
    //                         modifyResponse(response.data.response)
    //                         // AlertDialog(true)

    //                     })

    //                 showToast("success", "Uploaded Sucessfully")
    //             }
    //         })
    //     }
    //     event.target.value = null//this was missing
    // }
    const getTrackerList = (rows, page) => {
        const url = `${urlLinks.baseUrl}transactions/workerCustom/v2?page_no=${page - 1}&rows=${rows}${filterUrl}${sortUrl}`
        Axios.get(url)
            .then(response => {
                if (response) {
                    console.log({ response })
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    throw toast.error("No data from API")
                }
            })
            .catch(error => {
                setLoading(false)
                throw toast.error("No data from API")
            })
    }

    useEffect(() => {
        setLoading(true)
        getTrackerList(perPage, 1);

    }, [filterUrl, sortUrl])

    const handlePageChange = page => {
        setLoading(true)
        getTrackerList(perPage, page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setLoading(true)
        getTrackerList(newPerPage, 1);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const loader = <div style={{ margin: "auto", position: "sticky", left: "45%", top: "30%" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>

    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={
                                    column.render('Header') === "Name" ? "stickWorkerLedger zIndex1080 header" :
                                        column.id === "selection" ? "stickWorkerLedgerCheckBox zIndex1080 header" : "header"
                                }>

                                <span {...column.getSortByToggleProps()}>
                                    {column.id !== 'selection' ?
                                        <div>
                                            {column.render('Header')}
                                            <TableSortLabel
                                                style={{ color: "white" }}
                                                active={column.isSorted}
                                                direction={column.isSortedDesc ? 'asc' : 'desc'}
                                            />
                                        </div> : column.render('Header')}
                                </span>
                                <div>{column.defaultCanFilter ? column.render('Filter') : null}</div></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                loading ?
                    loader :
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="row">
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}
                                            className={
                                                cell.column.Header === "Name" ? "stickWorkerLedger zIndex540" :
                                                    cell.column.id === "selection" ? "stickWorkerLedgerCheckBox zIndex540" : "cell" //`${styles.workerLedgerNameFix}`:""
                                            }>
                                            {cell.render('Cell')}
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
            }
        </table>
    </div>

            // const style = `.p{margin : -7px}`
            

    return (
        <Styles>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={true}
                closeOnClick
                autoClose={1500}
                rtl={false}
                draggable
                pauseOnHover
            ></ToastContainer>
            <Typography style={{fontWeight:" bold",fontSize:"2em"}} variant="h4" component="h2">
             Worker ledger
          </Typography>
            <Grid style={{margin: "-7px",
            padding : "18px 100px 20px 0"}}
            item xs={16} className="buttonList paddingHorizontal" >
                <Grid item xs={4} >
                    {/* <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        // type="file"
                        // onChange={openResponseModal}
                    /> */}
                    {/* <label htmlFor="contained-button-file"> */}
                        <Button
                            // variant="outlined"
                            // className="uploadButton marginLight20"
                            variant="contained"
                            className="dataButton"
                            component="span"
                            onClick={openResponseModal}
                        >
                            Upload Payment Excel
                        </Button>
                    {/* </label> */}

                    {openResponseModalDialog && <CustomizedDialogs
                        open={true}
                        updateMyData={updateMyData}
                        resp={response}
                        setOpenOnboardingModal={closeResponseModal}
                    />}

                </Grid>
                <Grid item xs={16} >
                    {/* <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={handleExcel}
                />
                <label htmlFor="contained-button-file"> */}
                    <Button disabled
                        // variant="outlined"
                        // className="uploadButton marginLight20"
                        variant="contained"
                        // className="dataButton"
                        component="span"
                        // disabled={true}
                        // dis
                    >
                        Trigger Payment
                        </Button>
                    {/* </label>

                {openResponseModalDialog && response && <AlertDialog
                    open={true}
                    resp={response}
                    closeResponseModal={closeResponseModal}
                />} */}

                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={12} style={{ padding: 0 }}>
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>

            </Grid>

        </Styles>
    )
}
export default WorkerLedgerV2; 
 
/**
 * 
 * This fork is for experiment
 * 
 * 
*/