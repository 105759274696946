import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Popover, Button ,Divider , Grid, IconButton, Badge } from '@material-ui/core';
import Axios from 'axios' 
import SelectFilter from '../../utils/filters/SelectFilter'
import InputRangeFilter from '../../utils/filters/InputRangeFilter'
import SliderFilter from '../../utils/filters/SliderFilter'
import DateFilter from '../../utils/filters/DateFilter'
import LocationFilter from '../../utils/filters/locationFilter'
import GroupedSelectFilter from '../../utils/filters/groupedSelectFilter'
import SelectInput from './RecrutierDBElements/Select'
import InputTextFilter from '../../utils/filters/inputTextFilter' 
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover(props) {
    const classes = useStyles();
    const {isFilterApplied, setIsFilterApplied} = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = useState(props.filters)
    const [jobRoleList, setJobRoleList] = useState([])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // console.log({filters})
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleResetFilters =() =>{
        const newFilters=props.resetFilters()
        setFilters(newFilters);
        setIsFilterApplied(false)
        
    }
    const handleApplyFilters =() =>{
        props.updateFilters(filters);
        handleClose();
    }
    const handleCancelFilters =() =>{
        setFilters(props.filters);
        handleClose();
    }
    const makeJobRoleData= (data)  =>{
        let list=[];
        data.map(el => 
            list.push({
                id: el.id,
                name: el.categorySubType
            })
        )
        return list;
    }
    useEffect(() => {
        Axios.get("https://qaapi.okaygo.in//configmaster/?category_type=job_type&inserted_by=0&rows=100&type_desc=job_type_name")
            .then(response =>{
                setJobRoleList(makeJobRoleData(response.data.response.content))
            })
    }, [])
    let popoverData= <Grid></Grid>
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const remarkStatusOptions=[
        {
            heading: "Not interested" ,
            headingId: "NOT_INTERESTED" ,
            name: "Low Salary",
            nameId: "low_salary"
        },
        {
            heading: "Not interested" ,
            headingId: "NOT_INTERESTED" ,
            name: "Preferred Location",
            nameId: "preferred_job_location_id"
        },
        {
            heading: "Not interested" ,
            headingId: "NOT_INTERESTED" ,
            name: "Preferred Job",
            nameId: "preferred_job_type_id"
        },
        {
            heading: "Not approved" ,
            headingId: "NOT_APPROVED" ,
            name: "Skill Mismatch",
            nameId: "skill_mismatch"
        },
        {
            heading: "Not approved" ,
            headingId: "NOT_APPROVED" ,
            name: "Communication issue",
            nameId: "communication_issue"
        }
    ]
    popoverData= 
        <Grid container style={{width:"350px", display:"flex", flexDirection:"row"}}>
            <Grid item xs={12} className="padding20">
                <span style={{marginLeft: "15px"}}>Call status updated between</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="callStatusDate"
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="approvalStatus"
                    label="Approval status"
                    setFilters={setFilters}
                    multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="appliedBy"
                    label="Applied By"
                    setFilters={setFilters}
                    multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="interviewMode"
                    label="Interview Mode"
                    setFilters={setFilters}
                    multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="interviewFollowUp"
                    label="Interview Follow up"
                    setFilters={setFilters}
                    multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="recruiterFeedback"
                    label="Recruiter Feedback"
                    setFilters={setFilters}
                    multipleFlag={true}
                />
            </Grid>
            <Grid item xs={12} className="padding20">
                <SelectInput
                    filters={filters}
                    id="joiningStatus"
                    label="Joining status"
                    setFilters={setFilters}
                    multipleFlag={true}
                />
            </Grid>

            <Grid item sm className="padding20">
                <span style={{marginLeft: "15px"}}>Profile shared between</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="profile_shared_date"
                />
            </Grid>
            <Grid item sm className="padding20">
                <span style={{marginLeft: "15px"}}>Selection date</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="selectionDate"
                />
            </Grid>
            <Grid item sm className="padding20">
                <span style={{marginLeft: "15px"}}>Joining date</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="joiningDate"
                />
            </Grid>
            <Grid item sm className="padding20">
                <span style={{marginLeft: "15px"}}>Date of leaving</span>
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="To"
                    labelFrom="From"
                    id="dateOfLeaving"
                />
            </Grid>
            {/* <Grid item sm className="padding20">
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Remark Status"
                    id="remark_status"
                    data={remarkStatusOptions}
                />
            </Grid>
            {filters.remark_status.find(o => o.nameId === 'low_salary') && <Grid item sm className="padding20">
                <InputTextFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Low salary"
                    id="low_salary"
                />
            </Grid>}
            {filters.remark_status.find(o => (o.nameId === 'preferred_job_type_id' || o.nameId === 'skill_mismatch')) && <Grid item sm className="padding20">
                <GroupedSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Preferred Job"
                    id="preferred_job_type_id"
                    data={jobRoleList}
                />
            </Grid>}
            {filters.remark_status.find(o => o.nameId === 'preferred_job_location_id') && <Grid item sm className="padding20">
                <LocationFilter
                    filters={filters}
                    setFilters={setFilters}
                    label="Preferred Location"
                    id="preferred_job_location_id"
                />
            </Grid>}
             */}
        </Grid>
    return (
        <Grid container className="buttonList" style={{width:"350px", marginTop:"40px"}}>
            <Grid item xs={5} className="padding0">
                <span className= "FilterHeading">Other Filters</span>
            </Grid>
            <Grid item xs={7}  className="flexRowReverse padding0">      
                <Button 
                    className= "dataButton"
                    style={{marginLeft: "30px"}}
                    onClick={handleResetFilters}
                >
                    Reset
                </Button>
            </Grid>
            <Divider />
                {popoverData}
            <Divider />
            <Grid container style={{padding: "20px"}}>      
                <Button 
                    className= "dataButton"
                    style={{marginLeft: "30px"}}
                    onClick={handleApplyFilters}
                >
                    Apply
                </Button>
                <Button 
                    className= "dataButton"
                    onClick={handleCancelFilters}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );
}
