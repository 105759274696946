import React, { useState, useEffect } from 'react'
import { TextField, MenuItem, IconButton,} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import urlLinks from '../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';



const EditableTextInput =( props) =>{
    const {row, column, setLoading, showToast, updateMyData} = props
    const initialValue=row.original[column.id]
    const statusUpdateUrl=`${urlLinks.baseUrl}sales/updateStatus`
    const [value, setValue] = useState(initialValue);
    const label=""
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        setShowButtons(true)
        setValue(e.target.value)
    }
    
    const updateStatus = () =>{
        setLoading(true)
        const postData={
            amount: row.original.amount,
            updated_by: localStorage.getItem('userId'),
            interview_id: row.original.interviewId,
            job_id: row.original.jobId,
            assign_id: row.original.assignId,
            user_id: row.original.userId,
            job_details_id: row.original.jobDetailsId,
            
            fieldStatus: value
        }
        if(row.original.invoice_id){

            postData["invoice_id"]= row.original.invoice_id
        }
        if(!row.invoice_id && column.id==="amount") 
            postData["amount"]= value;
        if(column.id==="amount")
            postData["field"]="amount"
        else if(column.id==="invoiceNo")
            postData["field"]="invoice_number"
        else if(column.id==="invoiceDate")
            postData["field"]="invoice_date"
        else if(column.id==="paymentCredited")
            postData["field"]="payment_credited"
        else if(column.id==="paymentDate")
            postData["field"]="payment_date"
        else if(column.id==="tdsDeducted")
            postData["field"]="tds_deducted"
        else if(column.id==="pocForInvoice")
            postData["field"]="poc_invoice"
        else if(column.id==="pocContactNumber")
            postData["field"]="poc_contact_number"
        else if(column.id==="remarks")
            postData["field"]="invoice_remarks"
        Axios.post(statusUpdateUrl, qs.stringify(postData))
            .then(response =>{
                 if (response.data.code === 1000) {
                    updateMyData();
                    setShowButtons(false)
                    setLoading(false);
                } else {
                    throw showToast("error", response.data.message);
                    setLoading(false);
                }
            })
    }
    const handleConfirm =() =>{
        setLoading(true)
        updateStatus();
    }
    const handleCancel =() =>{
        console.log({initialValue})
        setValue(initialValue)
        setTimeout(() => {
            setShowButtons(false)
            
        }, 200);
    }
    
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    return (
        <div>
            <TextField 
                id="standard-basic" 
                disableUnderline
                value={value}
                onChange={onChange}
                className="borderStd margin0"
                placeholder={(column.id==="paymentDate" || column.id==="invoiceDate")?"DD/MM/YYYY": ""}
                multiline={true} 
                InputProps={{
                    disableUnderline: true
                }} 
            />
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
        </div>
    )
}

export default EditableTextInput;