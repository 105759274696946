import React, { Component } from 'react';
import { Typography,Container,Grid,withStyles,Button,ThemeProvider,createMuiTheme } from '@material-ui/core';
import CustomSelect from '../../utils/simpleSelect/customSelect';
import CustomTextField from '../../utils/simpleSelect/CustomTextField';
import SimpleTextField from '../../utils/simpleSelect/simpleTextField';
import SelectTime from '../../utils/DatePicker/TimePicker';
import { green, purple } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Axios from 'axios';
import urlLinks from '../../urlLinks';
import { ToastContainer, toast } from 'react-toastify';
import Parser from 'html-react-parser';
import JobModal from '../../utils/Modals/jobModal'
import * as moment from 'moment'
import {experience, ageBracket, weekTodays, setqualification, setcuisine, setgender} from "../Job/JobList/newJobViewComponents/jobRequirement"
import { isThisHour } from 'date-fns';
import Divider from '@material-ui/core/Divider';


const theme = createMuiTheme({
  palette: {
    asd: green,
  },
});

const styles = () => ({
  root: {
    margin: 'auto',
    flexGrow: 1,
  },
  textStyle: {
    textDecoration: 'underline',
    fontWeight: 'bold'
  },
  container: {
    backgroundColor: '#eeeeee',
    marginTop: '2%',
    fontSize: '.10rem',
    paddingBottom: '2%',
    paddingTop: '2%'
  },
  textcontainer: {
    // backgroundColor: '#eeeeee',
    marginTop: '1px',
    fontSize: '.10rem',
    paddingBottom: '1px',
    paddingTop: '1px'
  },
  heading:{
    fontWeight: 'bold',
    fontSize: '14px'
  },
  gridStyle: {
    margin: '5px 0 5px 0'
  },
  gridPadding:{
    padding: '10px 30px 10px 0px',
    fontSize: '14px'
  },
  gridPaddingJobDetails:{
    padding: '4px 30px 10px 0px',
    fontSize: '14px',
    marginBottom:'5px'
  },
  font:{
    fontSize: '14px'
  },
  margin: {
    margin: '2px',
  },
});


class JobQuestion extends Component {
    constructor(props) {
      super(props);
      this.state = {
        questionObj:{ 
          job_question_id:'' ,
          question_id:'',
          question_type:'' ,//integer
          question: '',
          question_parameters_no:'',
          first_parameter:'',
          second_parameter:'',
          third_parameter:'',
          forth_parameter:'',
          fifth_parameter:'',
          expected_answer:'',
          status:1
        },
        value:'',
        freeQuestion:'',
        // disable:'',
        customAns:null,
        openJobModal:false,
        setOpenJobModal:true,
        setJobDetails:'',
        hederData:{
          jobTitle:'',
          brandName:'',
          cityName:'',
          jobDescription:'',
          jobDetailId:'',
          insertedBy:'',
          companyId:'',
          jobId:'',
          amount:'',
          amountPer:''
        },
        questionlist:[
          // {
          //     question_id: 1,
          //     question_type: 1,//integer
          //     question: 'In this job role you will have fixed day-off on <editable days>. Are you comfortable with this? ',
          //     question_parameters_no:2,
          //     first_parameter:'',
          //     second_parameter:'',
          //     third_parameter:'',
          //     forth_parameter:'',
          //     fifth_parameter:'',
          //     answer:''
          // },
          // {
          //     question_id:2,
          //     question_type: 3,//date
          //     question: 'The job requires you to work 6 days per week. Are you okay with this?',
          //     question_parameters_no:3,
          //     first_parameter:'',
          //     second_parameter:'',
          //     third_parameter:'',
          //     forth_parameter:'',
          //     fifth_parameter:'',
          //     answer:''
             
          // },
          // {
          //     question_id:3,
          //     question_type:3,
          //     question:'Maximum salary offered for this job role is Rs. 23,000 per month. Are you okay with it?',
          //     question_parameters_no:1,
          //     first_parameter:'',
          //     second_parameter:'',
          //     third_parameter:'',
          //     forth_parameter:'',
          //     fifth_parameter:'',
          //     answer:''
          // },
          // {
          //     question_id: 4,
          //     question_type: 2,//integer
          //     question: 'This role is based out of Gurgaon. Are you okay with this?',
          //     question_parameters_no:4,
          //     first_parameter:'',
          //     second_parameter:'',
          //     third_parameter:'',
          //     forth_parameter:'',
          //     fifth_parameter:'',
          //     answer:''
          // }
        ]
      };
      this.objeParms={
        first_parameter:'',
        second_parameter:'',
        third_parameter:'',
        forth_parameter:'',
        fifth_parameter:''
      }
      this.editObjeParms={
        first_parameter:'',
        second_parameter:'',
        third_parameter:'',
        forth_parameter:'',
        fifth_parameter:''
      }
      this.customQuestion=[];
      this.label='First Params';
      this.isOpen=0;
      this.customOpen=0;
      this.index=0;
      this.disable='';
      this.question='';
      this.type='';
      // this.questionlist=[
      //   // {
      //   //     question_id: 1,
      //   //     question_type: 1,//integer
      //   //     question: 'In this job role you will have fixed day-off on <editable days>. Are you comfortable with this? ',
      //   //     question_parameters_no:2,
      //   //     first_parameter:'',
      //   //     second_parameter:'',
      //   //     third_parameter:'',
      //   //     forth_parameter:'',
      //   //     fifth_parameter:'',
      //   //     answer:''
      //   // },
      //   // {
      //   //     question_id:2,
      //   //     question_type: 3,//date
      //   //     question: 'The job requires you to work 6 days per week. Are you okay with this?',
      //   //     question_parameters_no:3,
      //   //     first_parameter:'',
      //   //     second_parameter:'',
      //   //     third_parameter:'',
      //   //     forth_parameter:'',
      //   //     fifth_parameter:'',
      //   //     answer:''
           
      //   // },
      //   // {
      //   //     question_id:3,
      //   //     question_type:3,
      //   //     question:'Maximum salary offered for this job role is Rs. 23,000 per month. Are you okay with it?',
      //   //     question_parameters_no:1,
      //   //     first_parameter:'',
      //   //     second_parameter:'',
      //   //     third_parameter:'',
      //   //     forth_parameter:'',
      //   //     fifth_parameter:'',
      //   //     answer:''
      //   // },
      //   // {
      //   //     question_id: 4,
      //   //     question_type: 2,//integer
      //   //     question: 'This role is based out of Gurgaon. Are you okay with this?',
      //   //     question_parameters_no:4,
      //   //     first_parameter:'',
      //   //     second_parameter:'',
      //   //     third_parameter:'',
      //   //     forth_parameter:'',
      //   //     fifth_parameter:'',
      //   //     answer:''
      //   // }
      // ];
      this.jobQuestionList=[
      //   {
      //     job_question_id: 1,
      //     question_type: 1,//integer
      //     question: 'In this job role you will have fixed day-off on <editable days>. Are you comfortable with this? ',
      //     question_parameters_no:2,
      //     first_parameter:'',
      //     second_parameter:'',
      //     third_parameter:'',
      //     forth_parameter:'',
      //     fifth_parameter:'',
      //     expected_answer:1,
      //     status:1
      // },
      // {
      //     job_question_id:2,
      //     question_type: 3,//date
      //     question: 'The job requires you to work 6 days per week. Are you okay with this?',
      //     question_parameters_no:3,
      //     first_parameter:9000,
      //     second_parameter:'ravi',
      //     third_parameter:2,
      //     forth_parameter:'',
      //     fifth_parameter:'',
      //     expected_answer:0,
      //     status:1
         
      // },
      // {
      //     job_question_id:3,
      //     question_type:3,
      //     question:'Maximum salary offered for this job role is Rs. 23,000 per month. Are you okay with it?',
      //     question_parameters_no:1,
      //     first_parameter:15000,
      //     second_parameter:'',
      //     third_parameter:'',
      //     forth_parameter:'',
      //     fifth_parameter:'',
      //     expected_answer:0,
      //     status:0
      // },
      // {
      //     job_question_id: 4,
      //     question_type: 2,//integer
      //     question: 'This role is based out of Gurgaon. Are you okay with this?',
      //     question_parameters_no:4,
      //     first_parameter:30000,
      //     second_parameter:'rina',
      //     third_parameter:6,
      //     forth_parameter:'rtd pvt ',
      //     fifth_parameter:'not',
      //     expected_answer:1,
      //     status:0
      // }
      ]
      // this.hederData={};
      this.answer='';
      this.addAnswer=null;
      this.textField=[];
      this.isEdit=0;
      this.editTextField=[];
      this.first_parameter='';
      this.second_parameter='';
      this.third_parameter='';
      this.forth_parameter='';
      this.fifth_parameter='';
      this.freeQuestion12='';
      this.questionId='';
      this.placeHolder=[];
      this.startTime=null;
      this.endTime=null;
      this.editStartDate='';
      this.editEndDate='';
    } 
    
    prameterHandleChange=(event, obj)=>{
      console.log(event);
      console.log(obj)
      
      this.first_parameter=obj.first_parameter;
      this.second_parameter=obj.second_parameter;
      this.third_parameter=obj.third_parameter;
      this.forth_parameter=obj.forth_parameter;
      this.fifth_parameter=obj.fifth_parameter;
      this.setState({value:event},()=>console.log(this.state.value));

    }

    CustomQuestionHandleChange=(value)=>{
      console.log(value);
      //this.freeQuestion12=value;
     
        this.freeQuestion12=value;
      
      //
      //this.setState({ freeQuestion: value });
    }
    CustomQuestionAnsHandleChange=(event)=>{
      console.log("*********************************")
      console.log(event.target.value)
      if(event.target.value==='a'){
        this.setState({ customAns: 1 });
      }else{
        this.setState({ customAns: 0 });
      }
    }

    submitCutomeQuestion=()=>{
   
      
    }

    getAnswerHandleChange=(event)=>{
      console.log(event);
     
      var values=0;
      var i=0;
      console.log(this.answer)
      if(event!=null){
          values=event.target.value.split(' ');
          console.log(values);
        if(values[2]!=null){
          if(this.state.questionObj.job_question_id==values[1]){
            if(values[0]=='a'){
              this.addAnswer=1;
              this.forceUpdateHandler()
            }else{
              this.addAnswer=0;
              this.forceUpdateHandler()
            }
          }
        }else{
          this.jobQuestionList.map((jobQuestionList)=>{
            i++;
            if(jobQuestionList.job_question_id==values[1]){
              if(values[0]=='a'){
                jobQuestionList.expected_answer=1;
                this.answer=1;
                this.forceUpdateHandler()
                console.log(this.jobQuestionList)
              }else{
                jobQuestionList.expected_answer=0;
                this.answer=0;
                this.forceUpdateHandler()
                console.log(this.jobQuestionList)
              }
              
            }
          })
        }
      }
    }

    
    handleChange=(event)=>{
      console.log(event);
      this.questionId=event.target.value.question_id;

      if(event.target.value.question_id===13){
        this.questionId=event.target.value.question_id;
        this.textField=[];
        this.isOpen=0;
        this.resetParameter()
        this.customOpen=1;
        this.forceUpdate()
      }else{
        this.customOpen=0;
        this.forceUpdate()
        if(this.questionId==1){
          this.placeHolder[0]="Salary"
        }else if(this.questionId==2){
          this.placeHolder[0]="City"
        }else if(this.questionId==3){
          this.placeHolder[0]="Days"
        }else if(this.questionId==4){
          this.placeHolder[0]="Days"
        }else if(this.questionId==5){

        }else if(this.questionId==6){
        this.placeHolder[0]="Days"
        }else if(this.questionId==7){
        this.placeHolder[0]="Start Time"
        this.placeHolder[1]="End Time"
        }else if(this.questionId==8 ){
        this.placeHolder[0]="years"
        this.placeHolder[1]="Industry"
        }else if(this.questionId==11){
        this.placeHolder[0]="Document"
        }else if(this.questionId==12){
        this.placeHolder[0]="CompanyName"
        }

        
        this.objeParms.first_parameter='';
      this.first_parameter='';
      //this.question='';
      this.numberOfTextField();
      this.forceUpdate()
      this.isOpen=1;
      this.question=event.target.value.question;
      this.type=event.target.value.question_type;

      if(event.target.value.question_id===1){
        this.objeParms.first_parameter=this.state.hederData.amount+' / '+this.state.hederData.amountPer
        this.first_parameter=this.state.hederData.amount+' / '+this.state.hederData.amountPer
      }else if(event.target.value.question_id===2){
        this.objeParms.first_parameter=this.state.hederData.cityName
        this.first_parameter=this.state.hederData.cityName
      }else if(event.target.value.question_id===12){
        this.objeParms.first_parameter=this.state.hederData.brandName
        this.first_parameter=this.state.hederData.brandName
      }

      this.setState({
        questionObj:{
          job_question_id:5000000,
          question_id:event.target.value.question_id,
          question_type:event.target.value.question_type,
          question:event.target.value.question,
          question_parameters_no:event.target.value.question_parameters_no,
          first_parameter:event.target.value.first_parameter,
          second_parameter:event.target.value.second_parameter,
          third_parameter:event.target.value.third_parameter,
          forth_parameter:event.target.value.forth_parameter,
          fifth_parameter:event.target.value.fifth_parameter,
          expected_answer:event.target.value.answer
        }
      },this.numberOfTextField());
      }
      
    }

    numberOfTextField=()=>{
      console.log(this.textField);
      this.textField=[];
      console.log("called");
      console.log(this.state.questionObj.question_parameters_no);
      for(var i=0;i<this.state.questionObj.question_parameters_no;i++){
        var a={};
        var c=i+1;
        a.number="Option "+c;
        this.textField[i]=a;
      }
      console.log(this.textField);
    }

    globalCancel=()=>{
      this.question='';
      this.type='';
      this.resetParameter();
      this.getDefaultQuestion()
    }

    cancel=()=>{
      this.isEdit=0;
      this.disable='';
      //this.forceUpdate();
      this.getJobQuestion()
      this.resetParameter();
    }

    editQuestion=(id)=>{
      this.editTextField=[]
      console.log("edit called");
      this.isEdit=id;
      var myobj=0;
      this.jobQuestionList.map((jobQuestionList)=>{
        if(jobQuestionList.job_question_id==id){
          
          this.editObjeParms.first_parameter=jobQuestionList.first_parameter;
          this.editObjeParms.second_parameter=jobQuestionList.second_parameter;
          this.editObjeParms.third_parameter=jobQuestionList.third_parameter;
          this.editObjeParms.forth_parameter=jobQuestionList.forth_parameter;
          this.editObjeParms.fifth_parameter=jobQuestionList.fifth_parameter;

          this.first_parameter=jobQuestionList.first_parameter;
          this.second_parameter=jobQuestionList.second_parameter;
          this.third_parameter=jobQuestionList.third_parameter;
          this.forth_parameter=jobQuestionList.forth_parameter;
          this.fifth_parameter=jobQuestionList.fifth_parameter;

          if(jobQuestionList.question_id==7){
            console.log("yes its 7")
            this.editStartDate=jobQuestionList.first_parameter;
            this.editEndDate=jobQuestionList.second_parameter;
           
            console.log(this.editStartDate,this.editEndDate)
            this.startTime=this.editStartDate
            this.endTime=this.editEndDate
          }
          
          // jobQuestionList.first_parameter=this.first_parameter;
          // jobQuestionList.second_parameter=this.second_parameter;
          // jobQuestionList.third_parameter=this.third_parameter;
          // jobQuestionList.forth_parameter=this.forth_parameter;
          // jobQuestionList.fifth_parameter=this.fifth_parameter;
          console.log(jobQuestionList);
          myobj=jobQuestionList;
          console.log(myobj);
        }
      })
      for(var i=0;i<myobj.question_parameters_no;i++){
        var a={};
        var c=i+1;
        a.number="Option "+c;
        this.editTextField[i]=a;
      }
      console.log(this.editTextField);
      this.disable=myobj.job_question_id
      this.forceUpdate();
    }

    editQuestionSubmit=(id)=>{
     

      var customQuestion='';
      var validate=false;
      var editableQuestion='';
      console.log("submit");
      var postQustion=[];
      this.jobQuestionList.map((jobQuestionList)=>{
        if(jobQuestionList.job_question_id==id){
          if(jobQuestionList.question_id===7){
            jobQuestionList.first_parameter=this.startTime;
          jobQuestionList.second_parameter=this.endTime;  
          }else{
            jobQuestionList.first_parameter=this.first_parameter;
          jobQuestionList.second_parameter=this.second_parameter;
          }
        
          jobQuestionList.third_parameter=this.third_parameter;
          jobQuestionList.forth_parameter=this.forth_parameter;
          jobQuestionList.fifth_parameter=this.fifth_parameter;
          jobQuestionList.updated_By=this.state.hederData.insertedBy; 
          //jobQuestionList.question=finalQuestion;
          customQuestion=jobQuestionList.question
          postQustion[0]=jobQuestionList;
        }
      })

      this.state.questionlist.map((questionlist)=>{
        if(postQustion[0].question_id==questionlist.question_id){
          editableQuestion=questionlist.question;
        }
      })

      var arr=[this.first_parameter,this.second_parameter,
        this.third_parameter,this.forth_parameter,this.fifth_parameter];

      if(postQustion[0].question_id==7){
        var starttime=moment(this.startTime).format("hh:mm A")
         var endtime=moment(this.endTime).format("hh:mm A")
          arr=[starttime,endtime,
          this.third_parameter,this.forth_parameter,this.fifth_parameter];
      }

     

      var finalQuestion=this.questionBuild(editableQuestion,
        postQustion[0].question_parameters_no,arr);
        postQustion[0].question=finalQuestion;

        if(postQustion[0].question_id===7){
          if(postQustion[0].question_parameters_no==2){
            if(this.startTime!='' && this.endTime!=''){
              validate=true
            }
          }
        }else{
          if(postQustion[0].question_parameters_no==1){
            if(this.first_parameter!=''){
              validate=true
            }
          }else if(postQustion[0].question_parameters_no==2){
            if(this.first_parameter!='' && this.second_parameter!=''){
              validate=true
            }
          }else if(postQustion[0].question_parameters_no==3){
            if(this.first_parameter!='' && this.second_parameter!='' && this.third_parameter!=null){
              validate=true
            }
    
          }else if(postQustion[0].question_parameters_no==4){
            if(this.first_parameter!='' && this.second_parameter!='' && this.third_parameter!='' && this.forth_parameter!=''){
              validate=true
            }
    
          }else if(postQustion[0].question_parameters_no==5){
            if(this.first_parameter!='' && this.second_parameter!='' && this.third_parameter!='' && this.forth_parameter!='' && this.fifth_parameter!=''){
              validate=true
            }
          }else if(postQustion[0].question_parameters_no==0){
              validate=true
              postQustion[0].question=customQuestion;
          }
        }
        if(validate){
          Axios.post(`${urlLinks.baseUrl}question_controller/job_question?job_question_id=${id}`,postQustion).then(response=>{
            console.log(response);
            this.getJobQuestion();
            this.resetParameter();
            if(response.data.code===1000){
              toast.success("Question Edited successfully")
              this.disable='';
              this.forceUpdate();
            }else{
              toast.error("Question Not Edited successfully")
            }
        }).catch(error => toast.error(`Error While Edit Question`));
        }else{
          toast.error(`Fill the values properly`);
        }
        
      console.log(postQustion);
    }



    addQuestion=(value)=>{

      if(this.customOpen){
        var customQuestion=[];
        var obj={};
        obj.question_type=1;
        obj.question_id=0;
        obj.question= this.freeQuestion12;
        obj.question_parameters_no=0;
        obj.first_parameter='';
        obj.second_parameter='';
        obj.third_parameter='';
        obj.forth_parameter='';
        obj.fifth_parameter='';
        obj.expected_answer=this.state.customAns;
        obj.status=1;
        obj.company_id=this.state.hederData.companyId;
        obj.job_id=this.state.hederData.jobId;
        obj.job_detail_id=this.state.hederData.jobDetailId;
        obj.inserted_by=this.state.hederData.insertedBy;
        obj.updated_By=this.state.hederData.insertedBy;
        // this.customQuestion[0]=obj;
        customQuestion[0]=obj;
        if(customQuestion[0].question!='' && customQuestion[0].expected_answer!==null ){

          Axios.post(`${urlLinks.baseUrl}question_controller/job_question`,customQuestion).then(response=>{
            console.log(response); 
            this.freeQuestion12='';
            this.forceUpdate();
            this.resetParameter();
            
            this.getJobQuestion();
            if(response.data.code===1000){
              toast.success("Question Added successfully")
            }else{
              toast.error("Question Not Added successfully")
            }
        }).catch(error => toast.error(`Error While Adding Question`));

        }else{
          toast.error("Fill the question properly");
        }
        
        console.log(customQuestion);
      }else{
        var validate=false;
        var repeat=false;
        if(value.question_id==7){
          var starttime=moment(this.startTime).format("hh:mm A")
         var endtime=moment(this.endTime).format("hh:mm A")
          var arr=[  starttime,endtime,
          this.third_parameter,this.forth_parameter,this.fifth_parameter];
        }else{
          var arr=[this.first_parameter,this.second_parameter,
          this.third_parameter,this.forth_parameter,this.fifth_parameter];
        }
        // var arr=[this.first_parameter,this.second_parameter,
        //   this.third_parameter,this.forth_parameter,this.fifth_parameter];

        var finalQuestion=this.questionBuild(this.question,
          value.question_parameters_no,arr);

        var postQuestion=[];
        console.log(value);
        console.log( this.first_parameter,
        this.second_parameter,
        this.third_parameter,
        this.forth_parameter,
        this.fifth_parameter)
        if(value.question_id==7){
          value.first_parameter=this.startTime;
          value.second_parameter=this.endTime;
        }else{
          value.first_parameter=this.first_parameter;
          value.second_parameter=this.second_parameter;
        }
        
        value.third_parameter=this.third_parameter;
        value.forth_parameter=this.forth_parameter;
        value.fifth_parameter=this.fifth_parameter;
        value.expected_answer=this.addAnswer;
        value.job_id=this.state.hederData.jobId;
        value.job_detail_id=this.state.hederData.jobDetailId;
        value.company_id=this.state.hederData.companyId;
        value.inserted_by=this.state.hederData.insertedBy;
        value.updated_By=this.state.hederData.insertedBy;
        value.status=1;
        value.question=finalQuestion;
        postQuestion[0]=value;
        console.log(postQuestion);
        console.log(finalQuestion);
        
        this.jobQuestionList.map((questionlist)=>{
          if(postQuestion[0].question_id===questionlist.question_id && questionlist.question_id!=11 ){
            console.log(postQuestion[0].question_id + " == " + questionlist.question_id )
            repeat=true;
          }
        })
        if(repeat){
          repeat=false;
          toast.error('You have already added this question')  
        }else{
          if(value.question_id===7){
            if(value.question_parameters_no==2){
              if((this.startTime!='' && this.endTime!='') && this.addAnswer!==null){
                validate=true
              }
            }
          }else{

            if(value.question_parameters_no==1){
              if((this.first_parameter!='')&& this.addAnswer!==null){
                validate=true
              }
            }else if(value.question_parameters_no==2){
              if((this.first_parameter!='' && this.second_parameter!='') && this.addAnswer!==null ){
                validate=true
              }
            }else if(value.question_parameters_no==3){
              if((this.first_parameter!='' && this.second_parameter!='' && this.third_parameter!='') && this.addAnswer!==null){
                validate=true
              }
      
            }else if(value.question_parameters_no==4){
              if((this.first_parameter!='' && this.second_parameter!='' && this.third_parameter!='' && this.forth_parameter!='') && this.addAnswer!==null){
                validate=true
              }
      
            }else if(value.question_parameters_no==5){
              if((this.first_parameter!='' && this.second_parameter!='' && this.third_parameter!='' && this.forth_parameter!='' && this.fifth_parameter!='') && this.addAnswer!==null){
                validate=true
              }
            }else if(value.question_parameters_no==0){
              if(this.addAnswer!==null){
                validate=true
              }
            }

          }
          
          
          if(validate){   
            Axios.post(`${urlLinks.baseUrl}question_controller/job_question`,postQuestion).then(response=>{
              console.log(response);
              this.resetParameter();
              this.getJobQuestion();
              if(response.data.code===1000){
                toast.success("Question Added successfully")
                this.forceUpdate();
              }else{
                toast.error("Question Added Not successfully")
              }
          }).catch(error => toast.error(`Error during adding Question`));
          }else{
            toast.error(" Fill the question values ")
          }
    

        }
        console.log(this.jobQuestionList);
      }
     
    }

    questionBuild=(question,prams_no,arr)=>{
      var que=question;
      var cor1=0;
      var cor2=0;
      for(var k=0;k<prams_no;k++){
        for(var i=0;i<que.length;i++){
            if(que[i]=="<"){
            cor1=i;
          }
          if(que[i]==">"){
            cor2=i
            break;
          }
        }
        var quePart1=que.slice(0,cor1);
        var quePart2= que.slice(cor2+1,que.length)
        que=quePart1+arr[k]+quePart2;
      }
      console.log(que);
      return que;
    }

    resetParameter=()=>{
      this.placeHolder=[];
      this.questionId='';
      this.customOpen=0;
      this.isOpen=0;
      this.question='';
      this.type=null;
      this.freeQuestion12='';
      this.first_parameter='';
      this.second_parameter='';
      this.third_parameter='';
      this.forth_parameter='';
      this.fifth_parameter='';
      this.addAnswer=null;
      this.textField=[];
      this.editTextField=[];
      this.isEdit=0;
      this.objeParms={
        first_parameter:'',
        second_parameter:'',
        third_parameter:'',
        forth_parameter:'',
        fifth_parameter:''
      };
      this.editObjeParms={
        first_parameter:'',
        second_parameter:'',
        third_parameter:'',
        forth_parameter:'',
        fifth_parameter:''
      }
      console.log(this.state.freeQuestion);
      this.setState({
        value:'',
        // disabled:'',
        freeQuestion:'',
        customAns:null,
        questionObj:{ 
          job_question_id:'' ,
          question_id:'',
          question_type:'' ,//integer
          question: '',
          question_parameters_no:'',
          first_parameter:'',
          second_parameter:'',
          third_parameter:'',
          forth_parameter:'',
          fifth_parameter:'',
          expected_answer:'',
          status:1
        },
      },this.forceUpdate())
      console.log(this.state.freeQuestion);
      console.log(this.freeQuestion12);
      this.forceUpdate();
    }

    deleteQuestion=(id)=>{
      console.log("delete",id)
      var myObj=0;
      Axios.post(`${urlLinks.baseUrl}question_controller/deleteJobQuestion?job_question_id=${id}`).then(
        response=>{
          this.getJobQuestion();
          this.resetParameter();
          console.log(response);
          if(response.data.code===1000){
            toast.success("Question Deleted successfully");
          }else{
            toast.success("Question Not Deleted successfully");
          }
        }
      ).catch(error => toast.error(`Error While Deleting question`));
    }

    redirectToJobList() {
      this.props.history.push('/jobs/pt_ftJob');
    }
    
    forceUpdateHandler(){
      this.forceUpdate();
    };

    getJobQuestion=()=>{
      var id=0;
      if (!this.props.location.id) {
        this.redirectToJobList();
      }else{
        id=this.props.location.id;
        Axios.get(`${urlLinks.baseUrl}question_controller/getJobQuestion?job_id=${id}`).then(
          response=>{   
            console.log(response);
            if(response.data.length>0){
              const data=response.data[0];
            const allData=response.data;
            this.jobQuestionList=allData;
            this.forceUpdate();
            console.log(this.jobQuestionList);
            // this.setState({
            //   hederData:{ 
            //   jobId:data.job_id,
            //   jobTitle:data.job_title,
            //   brandName:data.brandName,
            //   cityName:data.city_name,
            //   jobDescription:data.additional_requirement,
            //   companyId:data.company_id,
            //   jobDetailId:data.job_detail_id,
            //   insertedBy:localStorage.getItem('userId')
            //   }
            // })
            }
          }
        )
        this.getDefaultQuestion();
      }
    }

      getJobDetailModel=()=>{
      this.getJobDetails();
      // console.log(data)
      // console.log(this.state.setJobDetails)
      
      }

      setOpenJobModal=(value)=>{
        this.setState({openJobModal:value});
      }

      setQuestion=(question, pramsNo, first, second, third, forth, fifth, id)=>{
        
        var arr=[first,second,third,forth,fifth];
       
        console.log({arr, id, pramsNo})
        var str=question
        console.log({question})
        var final=0;
        if(pramsNo==0){
          return str
        }
        else if(id===7){
          str=`Are you comfortable working from <b style="color: #1c75bc;">${moment(arr[0]).format("hh:mm A")}</b> to <b style="color: #1c75bc;">${moment(arr[1]).format("hh:mm A")}</b>`
          return str
        }
        else{
          for(var i=0; i<pramsNo; i++){
              str=str.replace(arr[i],'<b style="color: #1c75bc;"> '+arr[i]+'</b>')
          }
          final= str;
          console.log({final})
          return final
        }
        
      }

       formatAMPM = (date)=> {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }

      handleTime = (date, id) =>{
        console.log(date, id);  

        var makedate = this.formatAMPM(date)


        if(id===1){
          this.startTime=date
        }
        if(id===2){
          this.endTime=date
        }
        //moment(date).format("HH:mm")
        console.log(this.startTime,this.endTime)

      }
   
      makeJobData =(data) =>{
        let exp="None";
        let gender="None";
        let qualification="None";
        let cuisine="None";
        console.log({data});
        
        if(data.detailsJobs && data.detailsJobs[0] && data.detailsJobs[0].detailSkills.length>0){
        data.detailsJobs[0].detailSkills.map(skill =>{
        if(skill.skillsTypeId>0 && skill.skillsTypeId<8 )
        qualification=skill.skillsTypeId;
        else if(skill.skillsTypeId===26 || skill.skillsTypeId===27 )
        gender=skill.skillsTypeId;
        else if(skill.skillsTypeId>39 && skill.skillsTypeId<44 )
        exp=skill.skillsTypeId;
        else if((skill.skillsTypeId>31 && skill.skillsTypeId<36)|| skill.skillsTypeId===115)
        cuisine=skill.skillsTypeId;
        else{
        }
        })
        }
        const startTime=data.loginTime?moment(data.loginTime, "hh:mm a").format("hh:mm A"):null;
        const endTime=data.logoutTime?moment(data.logoutTime, "hh:mm a").format("hh:mm A"):null;
        const startDate= data.startDate? data.startDate:null;
        let perkArray=[]
        if(data.company.is_2meal)
        perkArray.push("Meals provided")
        if(data.company.is_conveyance)
        perkArray.push("Conveyance provided")
        if(data.company.is_pf_esic)
        perkArray.push("Pf/Esic provided")
        if(data.company.is_rooms)
        perkArray.push("Accommodation provided")
        const jobData={
        brandName: data.company.brandName?data.company.brandName:data.company.companyName,
        encryptId: data.detailsJobs[0].jobDetailsId?this.convertJobIdtoHex(data.detailsJobs[0].jobDetailsId):'-',
        companyDetails: data.company.shortDescription,
        perks: perkArray,
        jobTitle: data.detailsJobs[0].jobTitle?data.detailsJobs[0].jobTitle:'-',
        workType:data.workType,
        vacancyType: data.detailsJobs[0].vacancyType==="entry"?
        "Entry level"
        :data.detailsJobs[0].vacancyType==="mid"?
        "Mid level"
        :data.detailsJobs[0].vacancyType==="senior"?
        "Senior level"
        :'N/a',
        payrollType: data.detailsJobs[0].payrollType==="company"?
        "Company"
        :data.detailsJobs[0].payrollType==="third"?
        "Third Party"
        :data.detailsJobs[0].payrollType==="contract"?
        "Contract":'N/a',
        noOfOpenings: data.detailsJobs[0].employeeRequired?data.detailsJobs[0].employeeRequired:'-',
        city: data.city_name?`${data.city_name}${data.state_name?", " :''}${data.state_name?data.state_name:''}`:'-',
        ExactJobAddress: data.address?data.address:'-',
        lat:data.jobLocationLat?data.jobLocationLat:null,
        long:data.jobLocationLong?data.jobLocationLong:null,
        salary: data.workType===3?`${this.numberFormat(data.detailsJobs[0].amount)}`:(data.detailsJobs[0].minSalary===data.detailsJobs[0].maxSalary)?`${this.numberFormat(data.detailsJobs[0].minSalary)}`:`${this.numberFormat(data.detailsJobs[0].minSalary)} - ${this.numberFormat(data.detailsJobs[0].maxSalary)}`,
        salaryType: data.detailsJobs[0].amountPer,
        jobDescription: data.detailsJobs[0].additionalRequirement?data.detailsJobs[0].additionalRequirement:'-',
        noOfDays: data.workdaysCount?data.workdaysCount:'-',
        noOfHrs: data.detailsJobs[0].noOfHrs?data.detailsJobs[0].noOfHrs:'-',
        weekdays: data.workdaysDetails?weekTodays(JSON.parse(data.workdaysDetails)): [],
        startTime: startTime,
        endTime: endTime,
        startDate: startDate,
        jobRole:data.detailsJobs[0].jobTypeId,
        exp: experience(exp),
        gender: setgender(gender),
        qualification: setqualification(qualification),
        cuisine: setcuisine(data.detailsJobs[0].cuisine===null? cuisine:data.detailsJobs[0].cuisine),
        specialization: data.detailsJobs[0].qualificationSpecification?data.detailsJobs[0].qualificationSpecification: "N/a",
        engFluency: data.detailsJobs[0].englishFluency===1?
        "No English"
        :data.detailsJobs[0].englishFluency===2?
        "Thoda English"
        :data.detailsJobs[0].englishFluency===3?
        "Good English":"-",
        ageBracket: ageBracket(data.detailsJobs[0].minAge, data.detailsJobs[0].maxAge),
        cvRequired: data.detailsJobs[0].cvRequired===0?
        "Not Required"
        :data.detailsJobs[0].cvRequired===1?
        "Optional"
        :data.detailsJobs[0].cvRequired===2?
        "Mandatory":"-",
        interviewAddress: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewAddress?
        data.detailsJobs[0].jobInterviewDetails.interviewAddress:'-'
        :'-' ,
        interviewProcess: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewProcess?
        data.detailsJobs[0].jobInterviewDetails.interviewProcess:'-'
        :'-',
        lat:data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewLat?
        data.detailsJobs[0].jobInterviewDetails.interviewLat:null
        :null,
        long:data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewLong?
        data.detailsJobs[0].jobInterviewDetails.interviewLong:null
        :null,
        // interviewSlots: interviewArraytoSlots(JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays)),
        interviewSlots: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays?
        JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays):[]
        :[],
        interviewDesc: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement?
        data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement:'-'
        :'-',
        pocName:data.contactCandidateName?data.contactCandidateName:'-' ,
        recruiterPayoutAmount:data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.remoteRecPayoutAmount?
        data.detailsJobs[0].jobPayout.remoteRecPayoutAmount:0:0,
        instructionsCandidate: data.detailsJobs[0].instructionsCandidate?data.detailsJobs[0].instructionsCandidate:'',
        instructionsRecruiter:data.detailsJobs[0].instructionsRecruiter?data.detailsJobs[0].instructionsRecruiter:'',
        recruiterPitch: data.detailsJobs[0].recruiterPitch?data.detailsJobs[0].recruiterPitch:'',
        recruiterPayoutDetails: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.remoteRecPayout_details?
        data.detailsJobs[0].jobPayout.remoteRecPayout_details:'':'',
        
        }
        return jobData;
        }
        
        numberFormat = (value) =>{
          return new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0, 
              minimumFractionDigits: 0, 
          }).format(value);}

    getJobDetails =() =>{
      var id=0;
      if(this.props.location.id){
        id=this.props.location.id;
          Axios.get(`${urlLinks.baseUrl}job/V2/getdetailedjob?job_id=${id}`)
          .then(response =>{
          if(response){
            const data=this.makeJobData(response.data.response.content[0]);
            this.setState({setJobDetails:data},()=>this.setState({openJobModal:true}))
          
          }
        })
      }
    }

    //   {openJobModal && <JobModal
    //     openJobModal={openJobModal}
    //     setOpenJobModal={setOpenJobModal}
    //     values={jobDetails}
    // />}
  
    componentDidMount(){
      var id=0;
      if (!this.props.location.id) {
        this.redirectToJobList(); 
      }else{
          id=this.props.location.id;
        Axios.get(`${urlLinks.baseUrl}job/V2/getdetailedjob?job_id=${id}`).then(
          response=>{   
            console.log(response);
            const data=response.data.response.content[0];
  
            this.setState({
              hederData:{ 
              jobId:data.detailsJobs[0].jobId,
              jobTitle:data.detailsJobs[0].jobTitle,
              brandName:data.company.brandName?data.company.brandName:data.company.companyName,/////////
              cityName:data.city_name,/////////////
              jobDescription:data.detailsJobs[0].additionalRequirement,
              companyId:data.companyId,//////////
              jobDetailId:data.detailsJobs[0].jobDetailsId,
              amount:data.detailsJobs[0].amount,////////////////\\\\\\
              amountPer:data.detailsJobs[0].amountPer,////////////////\\\\\\
              insertedBy:localStorage.getItem('userId')
            }
          })
        })
        this.getJobQuestion();
      }  
    }

    convertJobIdtoHex=(val) =>{
      var s = "abcde" + val.toString(36);
      return ""+s.substring(s.length - 5).toUpperCase();
  }

    getDefaultQuestion=()=>{
      console.log("default question called")
      Axios.get(`${urlLinks.baseUrl}question_controller/getDefaultQuestion`).then(
        response=>{
          console.log(response);
          const data=response.data;
          console.log(data);
          this.setState({questionlist:data}) 
          this.forceUpdate();
        }
      )
    }

    render() {  
      console.log(this.freeQuestion12);
       this.numberOfTextField();
       this.index=0;
      // this.getAnswerHandleChange();
      // this.getAnswerHandleChange();
      const { classes } = this.props;
      return (
        <div className={classes.root}>
          <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={4000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          ></ToastContainer>
          <Container>
            <Grid item xs={12}>
              <Typography variant='h6' display="block" className={classes.textStyle}>
                Job questionnaire
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <Container >
                <Typography className={classes.heading}  >
                  Job Details
                </Typography>
                <Grid container className={classes.gridStyle}>
                  <Grid item xs={1} className={classes.gridPadding}>
                    <Typography className={classes.heading}>
                      Job:
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.gridPadding}>
                    <Typography style={{marginLeft:'-36px',fontSize: '14px'}}>
                      {this.state.hederData.jobId} - {this.state.hederData.jobTitle} - {this.state.hederData.brandName} - {this.state.hederData.cityName}
                    </Typography>          
                  </Grid>
                  <Grid item xs={6} className={classes.gridPaddingJobDetails}>
                    <Button
                     style={{color:'#1c75bc',borderColor:'#1c75bc'}}
                      onClick={this.getJobDetailModel}
                      variant= "outlined">
                      Job Details
                    </Button>
                  </Grid>
                </Grid>
                <Grid>
                <Typography className={classes.heading}  >
                  Job description:
                </Typography>
                </Grid>
                <Grid >
                  <Typography className={classes.font}>
                   {this.state.hederData.jobDescription}
                  </Typography>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}  className={classes.gridPadding}>
              <Typography className={classes.font}>
                To add questions for this job, kindly select one from the drop-down list, enter your values and choose the expected answer. 
              </Typography>
            </Grid>
            <Grid container  style={{padding: '10px 30px 10px 0px'}}>
              <Grid item xs={9} style={{padding: '33px 30px 10px 0px'}}>
              <div className={classes.margin}>
                <CustomSelect
                question={this.question}
                type={this.type}
                questionlist={this.state.questionlist}
                handleChange={this.handleChange}
                >
                </CustomSelect>
              </div>
              </Grid>
              <Grid item xs={2} style={{padding: '40px 10px 10px 65px'}}>
              <Button
                style={{color:'white', backgroundColor:'#1c75bc'}}
                onClick={this.addQuestion.bind(this,this.state.questionObj)}
                variant= "contained">
                Add Ques.
              </Button>
              </Grid>
              <Grid item xs={1} style={{padding: '40px 30px 10px 0px'}}>
              <Button
                style={{color:'#1c75bc',borderColor:'#1c75bc'}}
                onClick={this.globalCancel}
                variant= "outlined">
                Cancel
              </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{padding: '-1px 30px 10px 0px'}} className={classes.textcontainer} >
              {
                this.questionId==7?
                <Grid item xs={4} style={{padding: '10px 10px 10px 0px'}}>
                <SelectTime
                handleChange={this.handleTime}

                >

                </SelectTime>
                </Grid>:
                this.textField.map((items,index)=>( 
                  <Grid item xs={2} style={{padding: '10px 10px 10px 0px'}}>
                  <CustomTextField
                  value={this.state.value}
                  label={items.number}
                  obj={this.objeParms}
                  questionId={this.questionId}
                  placeHolder={this.placeHolder[index]}
                  prameterHandleChange={this.prameterHandleChange}>  
                  </CustomTextField>
                  </Grid>
                ))
              }
              
              {this.isOpen===1? 
                <Grid container item xs={6}  style={{padding: '10px 10px 10px 0px'}}>
                  <Grid item xs={1} style={{padding: '20px 20px 5px 0px'}}>
                    <Radio
                      color="default"
                      style={{color:'#727272'}}
                      checked={this.addAnswer == 1 }
                      onChange={this.getAnswerHandleChange}
                      value={'a'+' '+this.state.questionObj.job_question_id+' '+'new'}
                      name="radio-button-demo"
                    />
                  </Grid>
                  <InputLabel style={{marginTop:'33px',fontWeight: 'bold'}} >Yes</InputLabel>
                  <Grid item xs={1} style={{padding: '20px 20px 10px 17px'}}>
                    <Radio
                      color="default"
                      checked={this.addAnswer == 0}
                      onChange={this.getAnswerHandleChange}
                      value={'b'+' '+this.state.questionObj.job_question_id+' '+'new'}
                      name="radio-button-demo"
                    />
                    <InputLabel style={{marginTop:'-26px', marginLeft:'48px', fontWeight: 'bold'}} >No</InputLabel>
                  </Grid>   
                  
                </Grid>:null
              }
              {
                this.customOpen?
                <Grid container style={{padding: '10px 10px -2px 0px'}}>
                <Grid item sx={10} style={{padding: '10px 30px 10px 0px'}} >
                <SimpleTextField
                  value={this.freeQuestion12}
                  CustomQuestionHandleChange={this.CustomQuestionHandleChange}
                  >
                </SimpleTextField>
              </Grid>
             
                <Grid item xs={1} style={{padding: '34px 5px 5px 43px'}}>
                  <Radio
                    color="default"
                    checked={this.state.customAns == 1 }
                    onChange={this.CustomQuestionAnsHandleChange}
                    value='a'
                    name="radio-button-demo"
                    label="yes"
                  />
                </Grid>
                <InputLabel style={{marginTop:'48px',fontWeight: 'bold'}} >Yes</InputLabel>
                <Grid item xs={1} style={{padding: '34px 5px 10px 43px'}}>
                  <Radio
                    color="default"
                    checked={this.state.customAns == 0}
                    onChange={this.CustomQuestionAnsHandleChange}
                    value='b'
                    name="radio-button-demo"
                  />
                </Grid>
                <InputLabel style={{marginTop:'48px',fontWeight: 'bold'}} >No</InputLabel>
              </Grid>:null
              }
              
            </Grid>
            <hr/>
            <Grid container style={{padding: '10px 20px 5px 0px'}}>
              {
              
              
              this.jobQuestionList.map((jobQuestionList)=>(
                this.index=this.index+1,
                this.answer=jobQuestionList.expected_answer,
                jobQuestionList.status==1?
                  <Grid container style={{padding: '10px 20px 5px 0px'}}>
                      <Grid item xs={10} style={{padding: '10px 20px 5px 0px'}}>
                      <Typography>
                      { this.index} . { Parser(this.setQuestion(jobQuestionList.question, jobQuestionList.question_parameters_no, jobQuestionList.first_parameter,
                        jobQuestionList.second_parameter, jobQuestionList.third_parameter,jobQuestionList.forth_parameter, jobQuestionList.fifth_parameter, jobQuestionList.question_id ))}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} style={{padding: '10px 20px 5px 0px'}}>
                        <Button
                          style={{color:'#1c75bc',borderColor:'#1c75bc'}}
                          onClick={this.editQuestion.bind(this,jobQuestionList.job_question_id)}
                          variant= "outlined">  
                          Edit
                        </Button>
                      </Grid>
                    <Grid item xs={1} style={{padding: '10px 30px 5px 0px'}}>
                    <Button
                        style={{color:'#d9534f',borderColor:'#d9534f'}}
                        onClick={this.deleteQuestion.bind(this,jobQuestionList.job_question_id)}
                        variant= "outlined">
                        Delete
                      </Button>
                    </Grid>
                    
                    
                    <Grid item xs={1} style={{padding: '5px 20px 5px 43px'}}>
                      <Radio
                        color="default"
                        checked={this.answer == 1 }
                        disabled={this.disable !== jobQuestionList.job_question_id}
                        onChange={this.getAnswerHandleChange}
                        value={'a'+' '+jobQuestionList.job_question_id}
                        name="radio-button-demo"
                      />
                    </Grid>
                    <InputLabel style={{marginTop:'17px',fontWeight: 'bold'}} >Yes</InputLabel>
                    <Grid item xs={1} style={{padding: '5px 20px 10px 43px'}}>
                      <Radio
                        color="default"
                        checked={this.answer == 0}
                        disabled={this.disable !== jobQuestionList.job_question_id}
                        onChange={this.getAnswerHandleChange}
                        value={'b'+' '+jobQuestionList.job_question_id}
                        name="radio-button-demo"
                      />
                    </Grid>
                    <InputLabel style={{marginTop:'17px',fontWeight: 'bold'}} >No</InputLabel>
                    <Grid item xs={8}>
                    </Grid>
                    {this.isEdit==jobQuestionList.job_question_id?
                      jobQuestionList.question_id===7?
                      <Grid item xs={4} style={{padding: '18px 10px 10px 0px'}}>
                      <SelectTime
                      handleChange={this.handleTime}
                      startTime={jobQuestionList.first_parameter}
                      endTime={jobQuestionList.second_parameter}
                      >

                      </SelectTime>
                      </Grid>
                      
                        :this.editTextField.map((items)=>( 
                          <Grid item xs={2} style={{padding: '18px 10px 10px 0px'}}>
                          <CustomTextField
                          value={this.state.value}
                          label={items.number}
                          obj={this.editObjeParms}
                          prameterHandleChange={this.prameterHandleChange}>  
                          </CustomTextField>
                          </Grid>
                        )):null
                      }
                      {
                        this.isEdit==jobQuestionList.job_question_id?<Grid container item xs={4} style={{padding: '0px 10px 10px 0px'}}>
                          <Grid item xs={2}>
                        <Button
                          color="secondary"
                          onClick={this.editQuestionSubmit.bind(this,jobQuestionList.job_question_id)}
                          variant= "outlined">
                          submit 
                          </Button>
                          </Grid>
                          <Grid item xs={2}>
                            
                          <Button
                          color="secondary"
                          onClick={this.cancel}
                          variant= "outlined">
                          Cancel
                          </Button>  
                          </Grid>
                        </Grid>:null
                      }
                  </Grid>:null
                ))}
            </Grid> 
            {/* <Grid item sx={12} style={{padding: '10px 30px 10px 0px'}} >
                <SimpleTextField
                  value={this.freeQuestion12}
                  CustomQuestionHandleChange={this.CustomQuestionHandleChange}
                  >
                </SimpleTextField>
              </Grid>
              <Grid container style={{padding: '5px 5px 5px 0px'}} >
                <Grid item xs={1} style={{padding: '5px 5px 5px 43px'}}>
                  <Radio
                    checked={this.state.customAns == 1 }
                    onChange={this.CustomQuestionAnsHandleChange}
                    value='a'
                    name="radio-button-demo"
                    label="yes"
                  />
                </Grid>
                <InputLabel style={{marginTop:'17px',fontWeight: 'bold'}} >Yes</InputLabel>
                <Grid item xs={1} style={{padding: '5px 5px 10px 43px'}}>
                  <Radio
                    checked={this.state.customAns == 0}
                    onChange={this.CustomQuestionAnsHandleChange}
                    value='b'
                    name="radio-button-demo"
                  />
                </Grid>
                <InputLabel style={{marginTop:'17px',fontWeight: 'bold'}} >No</InputLabel>
                <Grid item xs={3} style={{padding: '5px 5px 10px 55px'}}>
                    <Button
                      color="secondary"
                      onClick={this.submitCutomeQuestion}
                      variant= "outlined">
                      Submit Custom Question 
                    </Button>
                </Grid>
            </Grid> */}
              {this.state.openJobModal && 
              <JobModal
              openJobModal={this.state.openJobModal}
              setOpenJobModal={this.setOpenJobModal}
              values={this.state.setJobDetails}
              />}
          </Container>
        </div>
      );
    }
  }
export default withStyles(styles)(JobQuestion);