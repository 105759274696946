import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import './Login.css';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { AuthContext } from '../../context/AuthContext';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import Logo from '../../assets/OkayGo-website-4-3.png';
import urlLinks from '../../urlLinks'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      authenticated: false,
      isSubmitting: false,
      user: null
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInput(event) {
    const target = event.target;
    const name = target.name;

    this.setState({
      [name]: target.value
    });
  }

  async handleSubmit(event) {
    this.setState({ isSubmitting: true });
    event.preventDefault();
    const { updateLoginState } = this.context; //helps to have authContext property
    try {
      await Auth.signIn(this.state.username, this.state.password).then(
        response => {
          Axios.defaults.headers.common['Authorization'] = response.signInUserSession.accessToken.jwtToken;
          Axios.get(`${urlLinks.baseUrl}/user/?role_types=1,2,5&rows=1400`)
          .then(response2 => {
            if(response2.data.response.content.length > 0) {
              const userData = response2.data.response.content.filter(el => el.emailId === response.attributes.email)
              // console.log(response2)
              const allAdmins = {};
              response2.data.response.content.map(el => {
                allAdmins[el.userId] = `${el.firstName}${el.lastName ? " "+el.lastName : ''}`
              })
              toast.success(`Welcome ${response.username}`);
              // console.log(response.attributes.email)
              updateLoginState(response, allAdmins);
              localStorage.setItem('userId',userData[0].userId);
              localStorage.setItem('firstName',userData[0].firstName);
              localStorage.setItem('roleType', userData[0].roleType === 1 ? 'Super Admin' : userData[0].roleType === 5 ? 'recruiter' : 'Operational Admin')
              this.setState({
                authenticated: true,
                user: response,
                isSubmitting: false
              });
              this.props.history.push('/');
            } else {
              toast.error(`User Not Found In Database`);
              this.setState({ authenticated: false, user: null, isSubmitting: false });
            }
          })
          .catch(error => {
            toast.error(`User Not Found In Database`);
            this.setState({ authenticated: false, user: null, isSubmitting: false });
          })
        }
      );
    } catch (e) {
      toast.error(e.message);
      this.setState({ authenticated: false, user: null, isSubmitting: false });
    }
  }

  render() {
    const { isAuthorised } = this.context;
    if (isAuthorised && this.state.authenticated && this.state.user) {
      return <Redirect to='/' />;
    } else {
      return (
        <>
          <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={4000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          ></ToastContainer>
          <Container
            component='main'
            maxWidth='xs'
            className='container-margin'
          >
            <CssBaseline />
            <div>
              <Avatar src={Logo} className='logo-icon'></Avatar>
              <form noValidate onSubmit={this.handleSubmit}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Email'
                  name='username'
                  autoComplete='email'
                  autoFocus
                  value={this.state.username}
                  onChange={this.handleInput}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  value={this.state.password}
                  onChange={this.handleInput}
                />
                <Button
                  disabled={this.state.isSubmitting}
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  style={{marginLeft: "20px"}}
                >
                  Sign In
                </Button>
              </form>
            </div>
          </Container>
        </>
      );
    }
  }
}
export default Login;
