import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import styled from 'styled-components'
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import ScaleLoader from "react-spinners/ScaleLoader";
import Pagination from '../../utils/react-table/pagination'
import { Grid, Button, Select, MenuItem, IconButton, Modal, Checkbox, Backdrop, Slide, Zoom, TableSortLabel } from '@material-ui/core';
import urlLinks from '../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import axios from 'axios';
import CVuploadDownload from './Functions/cvUploadDownload'
import { CSVLink } from 'react-csv';
import { ToastContainer, toast } from 'react-toastify';
import Filters from './filterModal'
import EditableTextInput from "./Functions/EditableTextInput"
import EditableDropdown from "./Functions/EditableDropdown"
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            height:700px;
        }
        table {
            border-spacing: 0;
            height:700px;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #1c75bc !important;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }Invoice Status
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const ActiveApplications = (props) => {
    const { disableQuestionnaireButton } = props
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState({})
    const [companyList, setCompanyList] = useState()
    const [managerList, setManagerList] = useState()
    const [recruiterList, setRecruiterList] = useState()
    const [filterUrl, setFilterUrl] = useState('')
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [userId, setUserId] = useState()
    const [sortUrl, setSortUrl] = useState('')
    const [filters, setFilters] = useState({
        candidate_status: [""],
        location: [],
        interview_date: [null, null],
        invoice_status: [""],
        job_role: [],
        joining_date: [null, null],
        name: '',
        phoneNumber: '',
        selection_date: [null, null],
        commission_type: [""],
        recruiter_name: [],
        brand_name: [],
        account_manager: [],
        invoice_date: [null, null],
        invoice_number: ''
    })

    const numberFormat = (value) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).format(value);
    }

    console.log(numberFormat(15234.9896))
    const handleInvoiceUpload = (e, row) => {
        const formData = new FormData();
        const cvData = {
            name: e.target.files[0].name,
            file: e.target.files[0]
        }
        console.log({ row })
        formData.append('file', cvData.file)
        formData.append('user_id', row.userId)
        console.log({ formData })
        Axios.post(`${urlLinks.baseUrl}userdocs/getDocLink`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(response => {
                // console.log({response})
                if (response.data.code === 1000) {
                    Axios.post(`${urlLinks.baseUrl}/sales/uploadInvoice?file_name=${cvData.name}&file=${response.data.response}&invoice_id=${row.invoice_id}&inserted_by=${localStorage.getItem("userId")}`)
                        .then(response => {
                            showToast("success", "Invoice uploaded successfully");
                            updateMyData();
                        })
                        .catch(error => {
                            showToast("error", "Invoice upload failed");
                        })
                }
                else {
                    showToast("error", "Invoice conversion failed");
                }
            })
            .catch(error => {
                showToast("error", "Invoice upload failed");
            })
    }
    const columns = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ color: "white" }} disabled={true} />
                    </div>
                ),
                Cell: ({ row }) => (
                    <div>
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.invoice_id ? false : true} />
                    </div>
                ),
            },
            {
                Header: "Recruiter Name",
                accessor: "recruiterName",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Candidate Name",
                accessor: "candidateName",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Candidate Number",
                accessor: "phoneNumber",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },

            {
                Header: "Company",
                accessor: "company",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Role / vacancy",
                accessor: "roleVacancy",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Location",
                accessor: "location",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "CTC (yearly)",
                accessor: "ctc",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Interview Date",
                accessor: "interviewDate",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Selection Date",
                accessor: "selectionDate",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Joining Date",
                accessor: "joiningDate",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Commission Type",
                accessor: "commissionType",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Commission",
                accessor: "commission",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoicing Period",
                accessor: "invoicingPeriod",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Replacement Period",
                accessor: "replacementPeriod",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Candidate Status",
                accessor: 'feedbackStatus',
                // accessor: "candidateStatus",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "GST",
                accessor: "gst",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Total Amount",
                accessor: "totalAmount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice Status",
                accessor: "invoiceStatus",
                Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice No.",
                accessor: "invoiceNo",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice",
                accessor: "invoice",
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: CVuploadDownload,
                isVisible: true
            },
            {
                Header: "Invoice Date",
                accessor: "invoiceDate",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Payment Credited",
                accessor: "paymentCredited",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Payment Date",
                accessor: "paymentDate",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "TDS Deducted",
                accessor: "tdsDeducted",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "POC for invoice",
                accessor: "pocForInvoice",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "POC Contact Number",
                accessor: "pocContactNumber",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "OkayGo Account Manager",
                accessor: "okaygoAccountManager",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Remarks",
                accessor: "remarks",
                Cell: EditableTextInput,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            }
        ],
        []
    );
    const GoodupdateMyData = (rowIndex, columnId, value) => {
        setData(old =>
            old.map((row,) => {
                if (row.workerId === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }


    // useEffect(() => {
    //     let userIdList= []
    //     // if(openAssignRecruiterModal)
    //         // setUserIdList(idToUserId())
    // }, [openAssignRecruiterModal])

    const showToast = (type, message) => {
        if (type === "success")
            return toast.success(message);
        else if (type === "error")
            return toast.error(message);
    }

    const updateMyData = () => {
        getTrackerList(perPage, currentPage);
    }
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected, setHiddenColumns, state: { hiddenColumns, selectedRowIds, sortBy } } = useTable({ columns, data, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)

    React.useEffect(() => {
        setHiddenColumns(
            columns.filter(column => !column.isVisible).map(column => column.accessor)
        );
    }, [setHiddenColumns, columns]);

    const ApplyFilter = () => {
        let url = '';
        if (filters["name"])
            url = `${url}&name=${filters["name"]}`
        if (filters["invoice_number"])
            url = `${url}&invoice_number=${filters["invoice_number"]}`
        if (filters["phoneNumber"])
            url = `${url}&number=${filters["phoneNumber"]}`
        if (!filters["candidate_status"].includes("")) {
            let value = []
            if (filters["candidate_status"].includes("SELECTED"))
                value.push(...["ACCEPTED", "SELECTED", "POSTPONED", "NO_RESPONSE"])
            if (filters["candidate_status"].includes("NOT_JOINING"))
                value.push(...["NOT_JOINING", "REJECTED"])
            if (filters["candidate_status"].includes("JOINED"))
                value.push(...["JOINED", "UPDATE JOINING DATE"])
            if (filters["candidate_status"].includes("LEFT_AFTER_JOINING"))
                value.push(...["LEFT_AFTER_JOINING"])
            url = `${url}&candidate_status=${value}`
        }
        if (!filters["commission_type"].includes(""))
            url = `${url}&okaygo_commission_term=${filters["commission_type"]}`
        if (filters["brand_name"].length !== 0) {
            let companyIds = []
            filters["brand_name"].map(brand => companyIds.push(brand.id))
            url = `${url}&user_id=${companyIds}`
        }
        if (filters["account_manager"].length !== 0) {
            let companyIds = []
            filters["account_manager"].map(brand => companyIds.push(brand.id))
            url = `${url}&account_manager=${companyIds}`
        }
        if (filters["recruiter_name"].length !== 0) {
            let companyIds = []
            filters["recruiter_name"].map(brand => companyIds.push(brand.id))
            url = `${url}&recuiter_id=${companyIds}`
        }
        // if(filters["job_role"].length!==0)
        //     url=`${url}&job_role=${filters["job_role"]}`
        if (!filters["invoice_status"].includes(""))
            url = `${url}&invoice_status=${filters["invoice_status"]}`
        if (filters["location"].length !== 0) {
            let cityIds = []
            filters["location"].map(city => cityIds.push(city.id))
            url = `${url}&city_id=${cityIds}`
        }
        if (filters["interview_date"][0] && !filters["interview_date"][1])
            url = `${url}&interviews_from_date=${moment(filters["interview_date"][0]).format("YYYY-MM-DD")}&interviews_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["interview_date"][0] && filters["interview_date"][1])
            url = `${url}&interviews_from_date=2019-01-01&interviews_to_date=${moment(filters["interview_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["interview_date"][0] && filters["interview_date"][1])
            url = `${url}&interviews_from_date=${moment(filters["interview_date"][0]).format("YYYY-MM-DD")}&interviews_to_date=${moment(filters["interview_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["invoice_date"][0] && !filters["invoice_date"][1])
            url = `${url}&invoice_from_date=${moment(filters["invoice_date"][0]).format("YYYY-MM-DD")}&invoice_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["invoice_date"][0] && filters["invoice_date"][1])
            url = `${url}&invoice_from_date=2019-01-01&invoice_to_date=${moment(filters["invoice_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["invoice_date"][0] && filters["invoice_date"][1])
            url = `${url}&invoice_from_date=${moment(filters["invoice_date"][0]).format("YYYY-MM-DD")}&invoice_to_date=${moment(filters["invoice_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["joining_date"][0] && !filters["joining_date"][1])
            url = `${url}&joining_from_date=${moment(filters["joining_date"][0]).format("YYYY-MM-DD")}&joining_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["joining_date"][0] && filters["joining_date"][1])
            url = `${url}&joining_from_date=2019-01-01&joining_to_date=${moment(filters["joining_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["joining_date"][0] && filters["joining_date"][1])
            url = `${url}&joining_from_date=${moment(filters["joining_date"][0]).format("YYYY-MM-DD")}&joining_to_date=${moment(filters["joining_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["selection_date"][0] && !filters["selection_date"][1])
            url = `${url}&selection_from_date=${moment(filters["selection_date"][0]).format("YYYY-MM-DD")}&selection_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if (!filters["selection_date"][0] && filters["selection_date"][1])
            url = `${url}&selection_from_date=2019-01-01&selection_to_date=${moment(filters["selection_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        if (filters["selection_date"][0] && filters["selection_date"][1])
            url = `${url}&selection_from_date=${moment(filters["selection_date"][0]).format("YYYY-MM-DD")}&selection_to_date=${moment(filters["selection_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        setFilterUrl(url)
    }


    useEffect(() => {
        ApplyFilter();
    }, [filters])
    const handleExternalFilters = (list) => {
        setFilters({ ...filters, ...list })
    }
    const resetFilters = () => {
        let tempFilters = {
            candidate_status: [""],
            location: [],
            interview_date: [null, null],
            invoice_status: [""],
            job_role: [],
            joining_date: [null, null],
            name: '',
            phoneNumber: '',
            selection_date: [null, null],
            commission_type: [""],
            recruiter_name: [],
            brand_name: [],
            account_manager: [],
            invoice_date: [null, null],
            invoice_number: ''
        }
        setFilters(tempFilters)
        return tempFilters;
    }
    const ApplySort = () => {
        let url = ''
        if (sortBy.length > 0) {
            switch (sortBy[0].id) {
                case "intExt":
                    url = '&orderBy=worker_master.int_ext'
                    break;
                case "workerId":
                    url = '&orderBy=worker_master.worker_id'
                    break;
                case "name":
                    url = '&orderBy=user_master.first_name'
                    break;
                case "phoneNumber":
                    url = '&orderBy=user_master.phone_number'
                    break;
                case "score":
                    url = '&orderBy=job_assign_request.actual_score'
                    break;
                case "applicationDate":
                    url = '&orderBy= job_assign_request.applied_on'
                    break;
                case "callStatus":
                    url = '&orderBy=job_assign_request.call_status'
                    break;
                case "approvalStatus":
                    url = '&orderBy= job_assign_request.approval_status'
                    break;
                case "applicationStage":
                    url = '&orderBy=event_status'
                    break;
                case "preferredInterviewDate":
                    url = '&orderBy=job_assign_request.preferred_interview_date'
                    break;
                case "interviewSelectionDate":
                    url = '&orderBy=interviews.accepted_on'
                    break;
                case "interviewDate":
                    url = '&orderBy=c.company_name'
                    break;
                case "interviewFollowUp":
                    url = '&orderBy=interviews.status'
                    break;
                case "recruiterFeedback":
                    url = '&orderBy=interviews.recruiter_feedback	'
                    break;
                case "employerFeedback":
                    url = '&orderBy=interviews.employer_feedback'
                    break;
                case "finalFeedback":
                    url = '&orderBy=interviews.feedback_status'
                    break;
                case "selectionDate":
                    url = '&orderBy=interviews.offered_date'
                    break;
                case "joiningDate":
                    url = '&orderBy=interviews.joining_date'
                    break;
                case "offeredSalary":
                    url = '&orderBy=interviews.job_offered_amount'
                    break;
                case "joiningStatus":
                    url = '&orderBy=interviews.joining_status'
                    break;
                case "dateOfLeaving":
                    url = '&orderBy=interviews.date_of_leaving'
                    break;
                default:
                    url = ''
                    break;
            }
            if (!sortBy[0].desc)
                url = `${url}&sortBy=ascending`
            setSortUrl(url)
        }
        else
            setSortUrl('')
    }
    useEffect(() => {
        ApplySort();
    }, [sortBy])

    const setInterviewDate = (el) => {
        console.log({ el })
        if (el.interview_mode === 3) {

            if (el.whichSlot === 0)
                return "";
            else {
                if (el.whichSlot === 1) {
                    if (el.interviewStartTime)
                        return `${moment(el.interviewStartTime).format("DD/MM/YYYY")} ${moment(el.interviewStartTime).format("hh:mm a")} - ${moment(el.interviewEndTime).format("hh:mm a")}`;
                    else
                        return '';
                }
                if (el.whichSlot === 2)
                    return `${moment(el.interviewStartTime2).format("DD/MM/YYYY")} ${moment(el.interviewStartTime2).format("hh:mm a")} - ${moment(el.interviewEndTime2).format("hh:mm a")}`;
                if (el.whichSlot === 3)
                    return `${moment(el.interviewStartTime3).format("DD/MM/YYYY")} ${moment(el.interviewStartTime3).format("hh:mm a")} - ${moment(el.interviewEndTime3).format("hh:mm a")}`;
            }
        }
        else if (el.interview_mode === 2) {
            if (el.from_date === el.to_date)
                return `${moment(el.from_date).format("DD/MM/YYYY")}`
            else if (el.from_date)
                return `${moment(el.from_date).format("DD/MM/YYYY")} - ${moment(el.to_date).format("DD/MM/YYYY")}`
            else
                return ''
        }
    }
    const getUserFromId = (id) => {
        const users = JSON.parse(localStorage.getItem('allAdmins'));

        const name = users[id];
        if (name) {
            return name;
        }
        else return "Self";
    }
    const handleCandidateStatus = (status) => {
        if (status === "ACCEPTED" || status === "SELECTED" || status === "POSTPONED" || status === "NO_RESPONSE")
            return "Selected"
        else if (status === "REJECTED" || status === "NOT_JOINING")
            return "Not joining"
        else if (status === "UPDATE JOINING DATE" || status === "JOINED")
            return "Joined"
        else if (status === "LEFT_AFTER_JOINING")
            return "Left after joining"
        else
            return status ? (status.charAt(0) + status.slice(1).toLowerCase()) : ""

    }
    const makeData = (response) => {

        let extractedData = [];
        // let index= response.pageable.offset+1;
        // setCurrentJobId(props.data.jobId)
        response.content.map(el => {
            console.log({ el })
            extractedData.push({
                recruiterName: el.recruiter_name ? el.recruiter_name : "Not Assigned",
                intExt: el.int_ext ? el.int_ext : '',
                workerId: el.worker_id ? el.worker_id : '',
                candidateName: el.first_name === null ? '-' : `${el.first_name} ${el.last_name ? el.last_name : ''}`,
                phoneNumber: el.phone_number ? el.phone_number : '',
                company: el.company,
                roleVacancy: el.jobTitle,
                location: el.city_name,
                ctc: el.job_offered_amount,
                interviewDate: setInterviewDate(el),
                selectionDate: el.offeredDate ? moment(el.offeredDate).format("DD/MM/YYYY") : '',
                joiningDate: el.joining_date ? moment(el.joining_date).format("DD/MM/YYYY") : '',
                commissionType: el.okaygoCommissionTerm === 0 ? "Fixed" : el.okaygoCommissionTerm === 1 ? "Variable" : "N/a",
                commission: el.okaygoCommissionTerm === 1 ? `${el.okaygoCommissionPerJoining} %` : el.okaygoCommissionTerm === 0 ? `${numberFormat(el.okaygoCommissionPerJoining)}` : '',
                invoicingPeriod: el.okaygoInvoicingPeriod ? `${el.okaygoInvoicingPeriod} days` : "0 days",
                replacementPeriod: el.okaygoReplacementClause ? `${el.okaygoReplacementClause} days` : "0 days",
                candidateStatus: handleCandidateStatus(el.candidateStatus),
                feedbackStatus: el.feedbackStatus === null ? '-' : handleCandidateStatus(el.feedbackStatus),
                amount: el.amount,
                gst: "18%",
                totalAmount: el.invoice_total_amount ? numberFormat(el.invoice_total_amount) : '',
                invoiceStatus: el.invoice_status,
                invoiceNo: el.invoice_number,
                invoiceDate: el.invoice_date ? moment(el.invoice_date).format("DD/MM/YYYY") : "",
                paymentCredited: el.payment_credited,
                paymentDate: el.payment_date ? moment(el.payment_date).format("DD/MM/YYYY") : "",
                tdsDeducted: el.tds_deducted,
                pocForInvoice: el.poc_invoice ? el.poc_invoice : el.contactCandidateName,
                pocContactNumber: el.poc_contact_number ? el.poc_contact_number : el.contactCandidateNumber,
                okaygoAccountManager: el.accountManagerName || "",
                accountManagerId: el.accountManager || "",
                remarks: el.invoice_remarks || "",
                assignId: el.assign_id ? el.assign_id : '',
                recruiterId: el.recruiter_id,
                appliedByName: el.appliedByName,
                userId: el.user_id ? el.user_id : '',
                email: el.emailId ? el.emailId : '',
                jobId: el.jobId,
                jobDetailsId: el.jobDetailsId,
                interviewId: el.interviewId,
                invoice_id: el.invoice_id,
                file_path: el.file_path,
                file_name: el.file_name
            });
            console.log({ extractedData })
        });
        console.log("Hello makeData ended");
        return extractedData;
    }
    const getTrackerList = (rows, page) => {
        const url = `${urlLinks.baseUrl}sales/getSalesTrackerList?page_no=${page - 1}&rows=${rows}${filterUrl}${sortUrl}`
        Axios.get(url)
            .then(response => {
                if (response) {
                    console.log({ response })
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    throw toast.error("No data from API")
                }
            })
            .catch(error => {
                setLoading(false)
                throw toast.error("No data from API")
            })
    }

    useEffect(() => {
        setLoading(true)
        getTrackerList(perPage, 1);

    }, [filterUrl, sortUrl])

    useEffect(() => {
        axios.get(`${urlLinks.baseUrl}/admin/companies`)
            .then(response => {
                let list = [];
                console.log({ response })
                response.data.response.map(company => {
                    list.push({
                        id: company.userId,
                        name: company.brandName ? company.brandName : company.companyName
                    })
                })

                setCompanyList(list)
            })
        axios.get(`${urlLinks.baseUrl}user/?role_types=1,2&rows=100`)
            .then(response => {
                console.log({ response })
                let list = []
                response.data.response.content.map(company => {
                    list.push({
                        id: company.userId,
                        name: `${company.firstName} ${company.lastName ? company.lastName : ''}`
                    })
                })
                setManagerList(list)
            })

        axios.get(`${urlLinks.baseUrl}user/getAllRecruiter`)
            .then(response => {
                console.log({ response })
                let list = []
                response.data.response.map(x => {
                    list.push({
                        id: x.userID,
                        name: `${x.firstName} ${x.lastName ? x.lastName : ''}`
                    })
                })
                setRecruiterList(list)
            })
    }, [])

    const handleGenerateInvoice = () => {
        let rowIds = Object.keys(selectedRowIds)
        let ids = rowIds.map(id => { return rows[id].original.interviewId })
        console.log({ selectedRowIds, ids })
        if (ids.length > 0) {
            Axios.post(`${urlLinks.baseUrl}sales/generateNewInvoice?interview_ids=${ids.join(',')}&inserted_by=${localStorage.getItem("userId")}`)
                .then(response => {
                    console.log({ response })
                    toast.success(`Invoices Generated Successfully For : ${ids.join(',')}`)
                    toggleAllRowsSelected(false);
                    setLoading(true)
                    updateMyData();
                })
                .catch(error => toast.error(error));
        } else {
            toast.error(`Select Workers To Generate Invoice`);
        }

    }




    const handlePageChange = page => {
        setLoading(true)
        getTrackerList(perPage, page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setLoading(true)
        getTrackerList(newPerPage, 1);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const loader = <div style={{ margin: "auto", position: "sticky", left: "45%", top: "30%" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>

    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={column.render('Header') === "Worker Id" ? "stickWorkerIdnewMatch zIndex1080 header" :
                                    column.render('Header') === "Name" ? "stickWorkerNameMatchNew zIndex1080 header" :
                                        column.id === "selection" ? "stickCheckbox zIndex1080 header" : "header"}>

                                <span {...column.getSortByToggleProps()}>
                                    {column.id !== 'selection' ?
                                        <div>
                                            {column.render('Header')}
                                            <TableSortLabel
                                                style={{ color: "white" }}
                                                active={column.isSorted}
                                                direction={column.isSortedDesc ? 'asc' : 'desc'}
                                            />
                                        </div> : column.render('Header')}
                                </span>
                                <div>{column.defaultCanFilter ? column.render('Filter') : null}</div></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                loading ?
                    loader :
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="row">
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}
                                            className={cell.column.Header === "Worker Id" ? "stickWorkerIdnewMatch  zIndex540" :
                                                cell.column.Header === "Name" ? "stickWorkerNameMatchNew zIndex540" :
                                                    cell.column.id === "selection" ? "stickCheckbox zIndex540" : "cell"}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
            }
        </table>
    </div>


    return (
        <Styles>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={true}
                closeOnClick
                autoClose={1500}
                rtl={false}
                draggable
                pauseOnHover
            ></ToastContainer>

            <Grid container >
                <Grid item xs={12} className="buttonList paddingHorizontal" >
                    <Grid item xs={8} style={{ padding: 0 }}>
                        <Button
                            variant="contained"
                            className="dataButton"
                            // style={{marginLeft: "25px"}}
                            disabled={Object.keys(selectedRowIds).length === 0}
                            onClick={handleGenerateInvoice}
                        >
                            Generate Invoice
                        </Button>
                        <Button
                            variant="contained"
                            className="dataButton"
                        >
                            <CSVLink
                                data={data}
                                filename={`Sales-tracker-${(moment().format("DD/MM/YYYY HH:mm"))}.csv`}
                                className='btn btn-primary'
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    fontWeight: '500'
                                }}
                            >
                                Export To Excel
                            </CSVLink>
                        </Button>

                    </Grid>
                    <Grid item xs={4} style={{ padding: 0 }} className="buttonList marginRightForChildExceptLast " direction="row-reverse">
                        <Filters
                            filters={filters}
                            updateFilters={handleExternalFilters}
                            resetFilters={resetFilters}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                            companyList={companyList}
                            managerList={managerList}
                            recruiterList={recruiterList}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ padding: 0 }}>
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>

            </Grid>

        </Styles>
    )
}
export default ActiveApplications;