import React, {Component, isValidElement} from 'react'
import UpcomingInterview from './upcomingInterview';
import './upcomingInterviews.css'
import * as moment from 'moment';
import getinterviewFilterList from './interviewContant'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import utilFunctions from '../../../utilMethods';
// import InterviewFilter from './InterviewFilter'
import qs from 'querystring';
import { CircleLoader } from 'react-spinners';
import ScaleLoader from "react-spinners/ScaleLoader";
import CustomFilter from './InterviewFilter'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ExpansionPanel as MuiExpansionPanel, ExpansionPanelSummary as MuiExpansionPanelSummary, ExpansionPanelDetails as MuiExpansionPanelDetails, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const ExpansionPanel = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

class UpcomingInterviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listToday: [],
            listTomorrow: [],
            jobIdList: {},
            isSubmitting:false,
            
            filtersToday:[],
            filtersTomorrow:[],
            filtersNoresponse:[],
            URLToday:`${urlLinks.baseUrl}tracking/getUpcomingInterviews` ,
            URLTomorrow:`${urlLinks.baseUrl}tracking/getTomorrowInterviews` ,
            URLNoResponse: `${urlLinks.baseUrl}tracking/noResponseInterviews`,

        }
        
        
	}
    handleOpenFilter =(name,event) =>{
 
        
        if(name==="today")
            this.setState({openFilterToday: event.currentTarget})
        if(name==="tomorrow")
            this.setState({openFilterTomorrow: event.currentTarget})
        if(name==="nores")
            this.setState({openFilterNoRes: event.currentTarget})
    }
    handleCloseFilter = (name, event)  => {
        if(name==="today")
            this.setState({openFilterToday: false})
        else if(name==="tomorrow")
            this.setState({openFilterTomorrow: false})
        else if(name==="nores")
            this.setState({openFilterNoRes: false})
    }
    updateStatus = (updateData, loc) =>{
        const {status, id}=  updateData;
        this.setState({isSubmitting: true})
        const postData={
            interview_id: id,
            interview_status: status,
            updated_by: localStorage.getItem('userId')
        }      
        Axios.post(`${urlLinks.baseUrl}tracking/updateInterviewStatus`,qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    console.log({loc});
                    
                    this.getInterviewList(loc);
                    toast.success('Status updated Successfully');
                } else {
                    throw new Error(response.data.message);
                }
            })
            .catch(error => {
                this.setState({isSubmitting: false})
                toast.error(error.message);
            });
    }
    updateSelectedSlot = (slot, id, updateData, loc) =>{
        const postData={
            interview_id: id,
            which_slot : slot,
            requested_by: localStorage.getItem('userId')
        }
        Axios.post(`${urlLinks.baseUrl}interview/?`,qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    toast.success('Slot selected Successfully');
                    this.updateStatus(updateData, loc)
                } else {
                    throw toast.error(response.data.message);
                }
            })
            .catch(error => {
                toast.error(error.message);
            });
    }
    updateInteriewSlots = (updateData, setDisableSelect, loc) =>{
        this.setState({isSubmitting: true})
        const {interviewSlot1, interviewSlot1StartTime, interviewSlot1EndTime, interviewSlot2, interviewSlot2StartTime, interviewSlot2EndTime, interviewSlot3,  interviewSlot3StartTime, interviewSlot3EndTime, updatearray, id}=  updateData;
        console.log(setDisableSelect);
        
        const postData={
            interview_id: id,
            requested_by: localStorage.getItem('userId')
        }
        if(updatearray.includes(1)||updatearray.includes(2))
        {
            let interivewstartSlot = `${moment(interviewSlot1).format("YYYY-MM-DD")} ${moment(interviewSlot1StartTime).format("HH:mm:ss")}`
            postData.interview_start_time= interivewstartSlot
        }
        if(updatearray.includes(3))
        {
            let interivewstartSlot = `${moment(interviewSlot1).format("YYYY-MM-DD")} ${moment(interviewSlot1EndTime).format("HH:mm:ss")}`
            postData.interview_end_time= interivewstartSlot
        }
        if(updatearray.includes(4)||updatearray.includes(5))
        {
            let interivewstartSlot = `${moment(interviewSlot2).format("YYYY-MM-DD")} ${moment(interviewSlot2StartTime).format("HH:mm:ss")}`
            postData.interview_start_time2= interivewstartSlot
        }
        if(updatearray.includes(6)) 
        {
            let interivewstartSlot = `${moment(interviewSlot2).format("YYYY-MM-DD")} ${moment(interviewSlot2EndTime).format("HH:mm:ss")}`
            postData.interview_end_time2= interivewstartSlot
        }
        if(updatearray.includes(7)||updatearray.includes(8))
        {
            let interivewstartSlot = `${moment(interviewSlot3).format("YYYY-MM-DD")} ${moment(interviewSlot3StartTime).format("HH:mm:ss")}`
            postData.interview_start_time3= interivewstartSlot
        }
        if(updatearray.includes(9))
        {
            let interivewstartSlot = `${moment(interviewSlot3).format("YYYY-MM-DD")} ${moment(interviewSlot3EndTime).format("HH:mm:ss")}`
            postData.interview_end_time3= interivewstartSlot

        }
        
        Axios.post(`${urlLinks.baseUrl}interview/schedule`,qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {
                    setDisableSelect(false)
                    this.getInterviewList(loc);
                    this.setState({isSubmitting: false})
                    toast.success('Slots updated Successfully');
                } else {
                    this.setState({isSubmitting: false})
                    throw new Error(response.data.message);
                }
            })
            .catch(error => {
                this.setState({isSubmitting: false})
                toast.error(error.message);
            });
    }
    getIdArray = (data) =>{
        const id=data? data.map(el =>{
            return el.jobId
        }): null;
        return id
    }
    idToCity= (id) =>{
        let city=""
        switch (id) {
            case "71":
                // console.log({id});
                city= "New Delhi";
                break;
            case "72":
                // console.log({id});
                city="Noida";
                break;
            case "73":
                // console.log({id});
                city= "Gurugram";
                break;
            default:
                // console.log({id});
                city= "Other";
                break;
            
        }
        return city;
    }
    resetFilterURL =(loc) =>{
        if(loc==="Today")
            this.setState({URLToday :`${urlLinks.baseUrl}tracking/getUpcomingInterviews`}, () => this.getInterviewList(loc))
         if(loc==="Tomorrow")
            this.setState({URLTomorrow :`${urlLinks.baseUrl}tracking/getTomorrowInterviews`}, () => this.getInterviewList(loc))
         if(loc==="NoResponse")
            this.setState({URLNoResponse :`${urlLinks.baseUrl}tracking/noResponseInterviews`}, () => this.getInterviewList(loc))
    }
    updateFilters =( url, loc, value) =>{
        if(loc==="Today")
            this.setState({URLToday :`${urlLinks.baseUrl}tracking/getUpcomingInterviews?${url}`}, () => this.getInterviewList(loc, value))
         if(loc==="Tomorrow")
            this.setState({URLTomorrow :`${urlLinks.baseUrl}tracking/getTomorrowInterviews?${url}`}, () => this.getInterviewList(loc, value))
         if(loc==="NoResponse")
            this.setState({URLNoResponse :`${urlLinks.baseUrl}tracking/noResponseInterviews?${url}`}, () => this.getInterviewList(loc, value))
    }
    getInterviewList =(loc, value) =>{
        this.setState({isSubmitting: true})
        let url="";
        let listToday= [], listTomorrow= [], listNoResponse=[] ;
        if(loc==="Today")
        {
            Axios.get(this.state.URLToday)
                .then(response => { 
                    if (response.data.response) {
                        listToday=this.makeData(response.data.response);        
                        const jobIdList=listToday?this.getIdArray(listToday):null;
                        url=`${urlLinks.baseUrl}tracking/getUpcomingInterviewsCompanyDetails?job_id=${jobIdList}`;                
                        Axios.get(url)
                            .then(response => {
                                // console.log(jobIdList);
                                const companyData = this.updateCompanyData(response.data.response , jobIdList);
                                const data=this.completeData(companyData, listToday);
                                console.log({value});
                                let filters=[];
                                if(value)
                                    filters=value
                                else 
                                    filters=getinterviewFilterList(data)
                                console.log("interviews Today", {filters});
                                
                                this.setState({ listToday: data, companyDataToday: companyData, isSubmitting: false, filtersToday: filters })
                            })
                            .catch(error =>{
                                this.setState({isSubmitting: false})
                                toast.error(error.message);
                            })
                    }
                    else{
                        this.setState({isSubmitting: false})
                        throw toast.error(response.data.message);
                    } 
                })
                .catch(error =>{
                    this.setState({isSubmitting: false})
                    toast.error(error.message);
                })
        }
        else if(loc==="Tomorrow")
        {
            Axios.get(this.state.URLTomorrow)
                .then(response => {
                    if (response.data.response) {
                        listTomorrow=this.makeData(response.data.response);       
                        const jobIdList=listTomorrow?this.getIdArray(listTomorrow):null;
                        url=`${urlLinks.baseUrl}tracking/getUpcomingInterviewsCompanyDetails?job_id=${jobIdList}`;                
                        Axios.get(url)
                            .then(response => {
                                // console.log(jobIdList);
                                const companyData = this.updateCompanyData(response.data.response , jobIdList);
                                // console.log({companyData});
                                
                                const data=this.completeData(companyData,listTomorrow);
                                // console.log({data});
                                let filters=[];
                                if(value)
                                    filters=value
                                else 
                                    filters=getinterviewFilterList(data)
                                console.log("interviews Tomorrow", {filters});
                                this.setState({ listTomorrow: data, companyDataTomorrow: companyData, isSubmitting: false, filtersTomorrow: filters})
                            })
                            .catch(error =>{
                                this.setState({isSubmitting: false})
                                toast.error(error.message);
                            })
                    }
                    else{
                        this.setState({isSubmitting: false})
                        throw toast.error(response.data.message);
                    }

                })
                .catch(error =>{
                    this.setState({isSubmitting: false})
                    toast.error(error.message);
                })
        }
        else if(loc==="NoResponse")
        {
            Axios.get(this.state.URLNoResponse)
                .then(response => {
                    if (response) {
                        listNoResponse=this.makeNoResponseData(response.data.response);    
                        const jobIdList=listNoResponse?this.getIdArray(listNoResponse):null;
                        url=`${urlLinks.baseUrl}tracking/getUpcomingInterviewsCompanyDetails?job_id=${jobIdList}`;                
                        Axios.get(url).then(response => {
                            const companyData = this.updateCompanyData(response.data.response , jobIdList);
                            const data=this.completeData(companyData,listNoResponse);
                            let filters=[];
                            if(value)
                                filters=value
                            else 
                                filters=getinterviewFilterList(data)
                            console.log("interviews No Response", {filters});
                            this.setState({ listNoResponse: data, companyDataNoResponse: companyData, isSubmitting: false, filtersNoresponse: filters})
                        })
                        .catch(error =>{
                            this.setState({isSubmitting: false})
                            toast.error(error.message);
                        })
                    }
                    else{
                        this.setState({isSubmitting: false})
                        throw toast.error(response.data.message);
                    }
                })
                .catch(error =>{
                    this.setState({isSubmitting: false})
                    toast.error(error.message);
                })
        }
    }
    updateCompanyData =( data, list) => {
        if(!data)
            return null;
        const len=list.length
        const datalen=data.length
        let finalList=[]
        for( let i=0; i<len; i++)
        {   
            for (let j=0; j<datalen; j++)
            {
                if(list[i]===data[j].jobId)
                {
                    finalList.push(data[j])
                }
            }
        }
        return finalList;
        
    }

    completeData =(data, halfData) =>{
        if(!halfData)
            return null;
        const temp= halfData;
        if(!data)
            return null;
        // console.log(halfData);
        for(let i=0 ; i<temp.length; i++){
            if(data[i])
            {
                temp[i].companyName=data[i].companyName;
                temp[i].companyCity=this.idToCity(data[i].cityID);            
                temp[i].contactName=data[i].name;
                temp[i].contactNumber=data[i].phoneNumber;
                temp[i].companyAddress=data[i].addressLine;

            }
        }
        // console.log(temp);
        
        return temp
        
    }
	makeData = (data) => {        
        let CompanyDetail={};
        const extractedData = [];
        data.map(el => {
            if(el.status!=="NO_RESPONSE"){
                extractedData.push({
                    interviewId: el.interviewId,
                    jobId: el.jobId,
                    name: `${el.firstName} ${el.lastName?el.lastName:''}`,
                    profile: el.jobTittle,
                    jobType:el.workType,
                    companyName:'',
                    companyCity:'',
                    slot:el.slot,
                    interviewStartTime:el.interviewStartTime?el.interviewStartTime: null,
                    interviewEndTime:el.interviewEndTime?el.interviewEndTime:null ,
                    interviewStartTime2:el.interviewStartTime2?el.interviewStartTime2: null,
                    interviewEndTime2:el.interviewEndTime2?el.interviewEndTime2:null ,
                    interviewStartTime3:el.interviewStartTime3?el.interviewStartTime3: null,
                    interviewEndTime3:el.interviewEndTime3?el.interviewEndTime3:null ,
                    contactName:'',
                    contactNumber:'',
                    companyAddress:'',
                    candidateNumber:el.contactNumber,
                    address:el.address,
                    status: el.status==="RESCHEDULE"?null:el.status
            })}
        })
        return extractedData;
    }
	makeNoResponseData = (data) => {        
        let CompanyDetail={};
        const extractedData = [];
        data.map(el => {
            if(el.slot!==0){
                extractedData.push({
                    interviewId: el.interviewId,
                    jobId: el.jobId,
                    name: `${el.firstName} ${el.lastName?el.lastName:''}`,
                    profile: el.jobTittle,
                    jobType:el.workType,
                    companyName:'',
                    companyCity:'',
                    slot:el.slot,
                    interviewStartTime:el.interviewStartTime?el.interviewStartTime: null,
                    interviewEndTime:el.interviewEndTime?el.interviewEndTime:null ,
                    interviewStartTime2:el.interviewStartTime2?el.interviewStartTime2: null,
                    interviewEndTime2:el.interviewEndTime2?el.interviewEndTime2:null ,
                    interviewStartTime3:el.interviewStartTime3?el.interviewStartTime3: null,
                    interviewEndTime3:el.interviewEndTime3?el.interviewEndTime3:null ,
                    contactName:'',
                    contactNumber:'',
                    companyAddress:'',
                    candidateNumber:el.contactNumber,
                    address:el.address,
                    status: el.status
            })}
        })
        return extractedData;
    }
	componentDidMount () { 
        this.getInterviewList("Today");
        this.getInterviewList("Tomorrow");
        this.getInterviewList("NoResponse");  
    }
	


    render() {
        let lenToday= this.state.listToday?this.state.listToday.length:0
        let lenTomorrow= this.state.listTomorrow?this.state.listTomorrow.length:0
        let lenNoResponse= this.state.listNoResponse?this.state.listNoResponse.length:0
        // console.log(this.state.filtersToday);
        // console.log(this.state.filtersTomorrow);
        // console.log(this.state.filtersNoresponse);
        
        
        let todayInterviewList=this.state.listToday?this.state.listToday.map((interviewtoday, index)  =>
             {
                // console.log(interviewtoday.interviewStartTime);
                return <UpcomingInterview 
                updateInteriewSlots={this.updateInteriewSlots} 
                updateStatus={this.updateStatus} 
                loc={'Today'}
                updateSelectedSlot={this.updateSelectedSlot}
                data={interviewtoday} 
                id={interviewtoday.interviewId} 
                cname={index!=lenToday-1?'upcomingInterview':'upcomingInterviewLast'} 
            />}): null
        
        let tomorrowInterviewList=this.state.listTomorrow?this.state.listTomorrow.map((interview, index)  =>
            
            {
                // console.log(interview.interviewStartTime);
                
                return <UpcomingInterview 
                    updateInteriewSlots={this.updateInteriewSlots} 
                    updateStatus={this.updateStatus} 
                    loc={'Tomorrow'}
                    updateSelectedSlot={this.updateSelectedSlot}
                    data={interview} 
                    id={interview.interviewId} 
                    cname={index!=lenTomorrow-1?'upcomingInterview':'upcomingInterviewLast'} 
                />}):null

        let noResponseList=this.state.listNoResponse?this.state.listNoResponse.map((interview, index)  =>
            <UpcomingInterview 
                updateInteriewSlots={this.updateInteriewSlots} 
                updateStatus={this.updateStatus} 
                loc={'NoResponse'}
                updateSelectedSlot={this.updateSelectedSlot}
                data={interview} 
                id={interview.interviewId} 
                cname={index!=lenTomorrow-1?'upcomingInterview':'upcomingInterviewLast'} 
            />):null
        
        
        return (
            this.state.isSubmitting?
                <div style={{margin:"auto", position:"sticky", width: "fit-content"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
                :
                <div className="upcomingInterviews">
                    <ToastContainer
                        position='top-right'
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        autoClose={3000}
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    ></ToastContainer>
                    <div className="today">
                        <ExpansionPanel 
                            TransitionProps={{ unmountOnExit: false }}
                            defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3> Today </h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="list">
                                    
                                    {this.state.filtersToday &&<CustomFilter
                                        filters={this.state.filtersToday}
                                        updateFilters={this.updateFilters}
                                        isOpen={this.state.open}
                                        loc={'Today'}
                                        resetFilterURL={this.resetFilterURL}
                                    ></CustomFilter>}
                                    <div className="list">
                                        {lenToday>0?todayInterviewList:"No Interviews Today"}
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div> 
                    <div className="tomorrow">
                        <ExpansionPanel 
                            TransitionProps={{ unmountOnExit: false }}
                            defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3> Tomorrow </h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="list">
                                    {this.state.filtersTomorrow &&<CustomFilter
                                        filters={this.state.filtersTomorrow}
                                        updateFilters={this.updateFilters}
                                        isOpen={this.state.open}
                                        resetFilterURL={this.resetFilterURL}
                                        loc={'Tomorrow'}
                                    ></CustomFilter>}
                                    <div className="list">
                                        {lenTomorrow>0?tomorrowInterviewList:"No Interviews Tomorrow"}
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className="noResponse">
                        <ExpansionPanel 
                            TransitionProps={{ unmountOnExit: false }}
                            defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3> No Response </h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="list">
                                    {this.state.filtersNoresponse &&<CustomFilter
                                        filters={this.state.filtersNoresponse}
                                        updateFilters={this.updateFilters}
                                        isOpen={this.state.open}
                                        resetFilterURL={this.resetFilterURL}
                                        loc={'NoResponse'}
                                    ></CustomFilter>}
                                    <div className="list">
                                        {lenNoResponse>0?noResponseList:"No Cantidate Without response"}
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </div>
        )
    }

}


    

export default UpcomingInterviews;