import React from 'react';
import {
  MdComment,
  MdModeEdit,
  MdLibraryAdd,
  MdAccessAlarm,
  MdPauseCircleOutline,
  MdHighlightOff,
  MdDoneAll
} from 'react-icons/md';
import { FaEye } from 'react-icons/fa';

const jobScreeningUtils = {
  title: 'Job Details',
  screeningListAction: [[
    
    {
      id: 11,
      name: 'Interview DashBoard',
      subIcon: <FaEye className='fitIcon' />,
      route: '/jobs/JobDetailed'
    },
    // {
    //   id: 1,
    //   name: 'View Details',
    //   subIcon: <FaEye className='fitIcon' />,
    //   route: '/job/jobList/viewJob'
    // },
    {
      id: 11,
      name: 'View Details',
      subIcon: <FaEye className='fitIcon' />,
      route: '/jobs/jobList/newViewJob'
    },
    // {
    //   id: 2,
    //   name: 'Edit Details',
    //   subIcon: <MdModeEdit className='fitIcon' />,
    //   route: '/jobs/editJob'
    // },
    {
      id: 3,
      name: 'Edit Job',
      subIcon: <MdModeEdit className='fitIcon' />,
      route: '/jobs/newEditJob'
    },
    // {
    //   id: 3,
    //   name: 'Edit Details',
    //   subIcon: <MdModeEdit className='fitIcon' />,
    //   route: '/jobs/editJob'
    // },
    // {
    //   id: 3,
    //   name: 'Add Job',
    //   subIcon: <MdLibraryAdd className='fitIcon' />,
    //   route: '/jobs/pt_ftJob'
    // },
    {
      id: 4,
      name: 'Comments',
      subIcon: <MdComment className='fitIcon' />,
      route: '/job/jobList/commentSection'
    },
    // {
    //   id: 31,
    //   name: 'Deactivate Job',
    //   subIcon: <MdLibraryAdd className='fitIcon' />,
    // },
  ],
  [
    {
      id: 11,
      name: 'Interview DashBoard',
      subIcon: <FaEye className='fitIcon' />,
      route: '/jobs/JobDetailed'
    },
    // {
    //   id: 1,
    //   name: 'View Details',
    //   subIcon: <FaEye className='fitIcon' />,
    //   route: '/job/jobList/viewJob'
    // },
    {
      id: 11,
      name: 'View Details',
      subIcon: <FaEye className='fitIcon' />,
      route: '/jobs/jobList/newViewJob'
    },
    {
      id: 4,
      name: 'Comments',
      subIcon: <MdComment className='fitIcon' />,
      route: '/job/jobList/commentSection'
    }
  ],
  [
    
    {
      id: 11,
      name: 'Interview DashBoard',
      subIcon: <FaEye className='fitIcon' />,
      route: '/jobs/JobDetailed'
    },
    // {
    //   id: 1,
    //   name: 'View Details',
    //   subIcon: <FaEye className='fitIcon' />,
    //   route: '/job/jobList/viewJob'
    // },
    {
      id: 11,
      name: 'View Details',
      subIcon: <FaEye className='fitIcon' />,
      route: '/jobs/jobList/newViewJob'
    },
    {
      id: 2,
      name: 'Edit Details',
      subIcon: <MdModeEdit className='fitIcon' />,
      route: '/jobs/newEditJob'
    },
    // {
    //   id: 3,
    //   name: 'Edit Details',
    //   subIcon: <MdModeEdit className='fitIcon' />,
    //   route: '/jobs/editJob'
    // },
    {
      id: 4,
      name: 'Comments',
      subIcon: <MdComment className='fitIcon' />,
      route: '/job/jobList/commentSection'
    },
    // {
    //   id: 31,
    //   name: 'Activate Job',
    //   subIcon: <MdLibraryAdd className='fitIcon' />,
    // },
  ]],
  screeningFilterList: [
    {
      urgency: ['urgent', 'required', 'will be required'],
      id: '1',
      name: 'urgency',
      label: 'Urgency',
      default: []
    },
    {
      city: ['Delhi', 'Gurgaon', 'Noida'],
      id: '2',
      name: 'city',
      label: 'City',
      default: []
    },
    {
      industry: ['Restaurant', 'Health', 'Mechanical'],
      id: '1',
      name: 'industry',
      label: 'Industry',
      default: []
    }
  ]
};

export default jobScreeningUtils;
