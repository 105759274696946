import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton, Button } from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { Link } from "react-router-dom";
import DashboardRemarkModal from '../../../utils/Modals/dashboardRemarkModal'
import urlLinks from '../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';
import { ToastContainer } from 'react-toastify';



const EditableDropdownSubComponent = (props) => {
    const { row, columnId, initialValue, updateMyData, showToast, setLoading, interviewAddress } = props

    const [selectDisable, setSelectDisable] = useState(false)
    const [selectDisableTwo, setSelectDisableTwo] = useState(false)
    const [selectDisableThree, setSelectDisableThree] = useState(false)
    const [value, setValue] = useState(initialValue);
    // console.log(localStorage.getItem("userId"))
    useEffect(() => {
        if (columnId === "dataForRecruitment" && row.recruiterMasterId === "-") {
            setSelectDisableTwo(true)
        }
        else
            setSelectDisableTwo(false)
        if (columnId === "haveLaptopSmartphoneWifi" && row.recruiterMasterId === "-") {
            setSelectDisableThree(true)
        }
        else
            setSelectDisableThree(false)
        if ((columnId === "recruiterStatus" && initialValue === "INCOMPLETE REGISTRATION")) {
            setSelectDisable(true)
        } else
            setSelectDisable(false)
    }, [initialValue])



    let label = ""
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        setShowButtons(true)
        setValue(e.target.value)
    }
    // console.log({row})
    const updateStatus = () => {
        setLoading(true)
        console.log("entered", value)
        if (columnId === "recruiterStatus") {
            const postData = {
                status: value,
                recruiterMasterId: row.recruiterMasterId
            }


            Axios.post(`${urlLinks.baseUrl}recruiter/changeRecruiterStatus?status=${postData.status}&recruiter_id=${postData.recruiterMasterId}&updatedBy=${localStorage.getItem("userId")}`)
                .then(response => {
                    if (response.data.code === 1000) {
                        updateMyData()
                        setLoading(false);
                    } else {
                        showToast(response.data.message);
                        setLoading(false);
                    }
                })
        }
        if (columnId === "haveLaptopSmartphoneWifi") {
        
            console.log(value)
            const postData = {
                recruiterMasterId: row.recruiterMasterId,
                haveLaptopSmartPhoneWifi: value

            };


            Axios.post(`${urlLinks.baseUrl}/recruiter/adminUpdateRecruiter`, postData)
                .then(response => {
                    if (response.data.code === 1000) {
                        updateMyData()
                        setLoading(false);
                    } else {
                        showToast(response.data.message);
                        setLoading(false);
                    }
                })



        }
        if (columnId === "dataForRecruitment") {
            const postData = {
                recruiterMasterId: row.recruiterMasterId,
                dataForRecruitment: value

            };


            Axios.post(`${urlLinks.baseUrl}/recruiter/adminUpdateRecruiter`, postData)
                .then(response => {
                    if (response.data.code === 1000) {
                        updateMyData()
                        setLoading(false);
                    } else {
                        showToast(response.data.message);
                        setLoading(false);
                    }
                })



        }


    }
    const handleConfirm = () => {
        setLoading(true)
        updateStatus();
    }
    const handleCancel = () => {
        setValue(initialValue)
        setShowButtons(false)
    }
    // const isDisable = () => {
    //     if (initialValue == 'INCOMPLETE REGISTRATION')
    //         return true;
    //     else
    //         return false;
    // }
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    let dropdown = <div></div>
    // if (columnId === "recruiterStatus")
    dropdown = <div className="index">
        {columnId === "recruiterStatus" && <Select
            id="recruiterStatus"
            value={value}
            disableUnderline
            disabled={selectDisable}
            onChange={onChange}
            inputProps={{
                classes: {
                    icon: "selectIcon",
                },
            }}
        >
            <MenuItem value="" disabled>
                <em>Recruiter status</em>
            </MenuItem>
            <MenuItem value={'REGISTERED'}>Registered</MenuItem>
            <MenuItem value={'UNDER ASSESMENT'}>Under Assessment</MenuItem>
            <MenuItem value={'APPROVED'}>Approved</MenuItem>
            <MenuItem value={'ON HOLD'}>On Hold</MenuItem>
            <MenuItem value={'PENDING FOR ONBOARDING'}>Pending for Onboarding</MenuItem>
            <MenuItem value={'REJECTED'}>Rejected</MenuItem>
            <MenuItem value={'ACTIVE'}>Active</MenuItem>
            <MenuItem value={'INACTIVE'}>Inactive</MenuItem>
            {<MenuItem disabled={true} style={{ padding: '0px', height: '0px' }} value={'INCOMPLETE REGISTRATION'}>Incomplete Registration</MenuItem>}


        </Select>}
        {columnId === "dataForRecruitment" && <Select
            id="dataForRecruitment"
            value={value}
            disableUnderline
            disabled={selectDisableTwo}
            onChange={onChange}
            inputProps={{
                classes: {
                    icon: "selectIcon",
                },
            }}
        >
            <MenuItem value="-" disabled>
                <em>Data Source</em>
            </MenuItem>
            <MenuItem value={'OKAYGO_DATA'}>OkayGo Data</MenuItem>
            <MenuItem value={'SELF'}>Self</MenuItem>
            <MenuItem value={'BOTH'}>Both</MenuItem>


        </Select>}
        {columnId === "haveLaptopSmartphoneWifi" && <Select
            id="haveLaptopSmartphoneWifi"
            value={value}
            disableUnderline
            disabled={selectDisableThree}
            onChange={onChange}
            inputProps={{
                classes: {
                    icon: "selectIcon",
                },
            }}
        >
            <MenuItem value="-" disabled>
                <em>Logistics</em>
            </MenuItem>
            <MenuItem value={1}>Available</MenuItem>
            <MenuItem value={2}>Not Available</MenuItem>



        </Select>}

        {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
    </div>


    return (
        <React.Fragment>
            {dropdown}

        </React.Fragment>
    )
}

export default EditableDropdownSubComponent;



