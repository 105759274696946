import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";

import { FirstPage } from "./pageComponents/firstPage";
import { SecondPage } from "./pageComponents/secondPage";
import { Formik, Form, useFormikContext } from "formik";
import Axios from 'axios';
import qs from 'querystring';
// import { EditorState, convertToRaw } from 'draft-js'
import * as moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import FormSuccess from "./pageComponents/FormSuccess";
import { StepButton } from "./stepButton";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step, StepLabel, Typography } from '@material-ui/core';
import urlLinks from '../../../urlLinks';

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const renderStep = (step, values, errors, touched, handleChange, setFieldValue, setValues,
  genderOptions, highestQualificationOptions, optionsYearofPassing, englishProficiencyOptions, locationList, interestList, editFlag, workerData, emailExist, contactExist, setEmailExist, setContactExist) => {
  switch (step) {
    case 1:
      return <FirstPage
        errors={errors}
        touched={touched}
        values={values}
        emailExist={emailExist}
        setEmailExist={setEmailExist}
        contactExist={contactExist}
        setContactExist={setContactExist}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        genderOptions={genderOptions}
        highestQualificationOptions={highestQualificationOptions}
        optionsYearofPassing={optionsYearofPassing}
        englishProficiencyOptions={englishProficiencyOptions}
        locationList={locationList}
        editFlag={editFlag}
        workerData={workerData}
      />;
    case 2:
      return <SecondPage
        errors={errors}
        touched={touched}
        values={values}
        setValues={setValues}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        locationList={locationList}
        interestList={interestList}
        editFlag={editFlag}
      />;
    default:
      return <FirstPage
        errors={errors}
        touched={touched}
        values={values}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        genderOptions={genderOptions}
        highestQualificationOptions={highestQualificationOptions}
        optionsYearofPassing={optionsYearofPassing}
        englishProficiencyOptions={englishProficiencyOptions}
        locationList={locationList}
      />;
  }
};
const MultiStep = () => {
  const [step, setStep] = useState(1);
  const classes = useStyles();
  const history = useHistory();
  const workerId = history.location.id;
  const editFlag = history.location.edit
  const [workerData, setWorkerData] = useState({})
  const steps = ['Step 1', 'Step 2'];
  const [contactExist, setContactExist] = useState(false)
  const [emailExist, setEmailExist] = useState(false)
  const [industryList, setIndustryList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [interestList, setInterestList] = useState([])
  console.log({ workerId })
  const formWeekDays = (weekdays) => {
    const week = {
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false
    }
    weekdays.map(day => {
      if (day === "monday")
        week["2"] = true
      if (day === "tuesday")
        week["3"] = true
      if (day === "wednesday")
        week["4"] = true
      if (day === "thursday")
        week["5"] = true
      if (day === "friday")
        week["6"] = true
      if (day === "saturday")
        week["7"] = true
      if (day === "sunday")
        week["1"] = true
    })
    console.log({ week });

    return week;
  }
  const genderOptions = [
    ["26", "Male"],
    ["27", "Female"]
  ]
  const highestQualificationOptions = [
    [1, "Below 10th"],
    [2, "10th passed"],
    [3, "12th passed"],
    [4, "Diploma"],
    [5, "in College"],
    [6, "Graduate"],
    [7, "Post graduate"]
  ]
  const currentYear = (new Date()).getFullYear()
  const optionsYearofPassing = []
  for (var i = currentYear; i >= currentYear - 35; i--) {
    optionsYearofPassing.push([i, i])
  }
  const englishProficiencyOptions = [
    [1, "No english"],
    [2, "Thoda english"],
    [3, "Good english"]
  ]
  const makeLocationData = (data) => {
    console.log({ data })
    let list = [];
    data.map(el => {
      list.push({
        id: el.city_id,
        city: el.district,
        state: el.stateName
      })
    })
    return list
  }
  const makeInterestData = (data) => {
    let list = [];
    data.map(el => {
      if (el.id !== 1354)
        list.push({
          id: el.id,
          name: el.categorySubType === "industry" ? el.typeDesc : el.categorySubType,
          type: el.categorySubType === "industry" ? "category" : "role"
        })
    })
    return list
  }
  const formDays = (week) => {
    const days = []
    if (week["0"] === true)
      days.push("monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday")
    if (week["2"] === true)
      days.push("monday")
    if (week["3"] === true)
      days.push("tuesday")
    if (week["4"] === true)
      days.push("wednesday")
    if (week["5"] === true)
      days.push("thursday")
    if (week["6"] === true)
      days.push("friday")
    if (week["7"] === true)
      days.push("saturday")
    if (week["1"] === true)
      days.push("sunday")


    return days;
  }
  const showToast = (type, message) => {
    if (type === "success")
      return toast.success(message);
    else if (type === "error")
      return toast.error(message);
  }
  const makeIndustryData = (data) => {
    const result = []
    data.map(el =>
      result.push({
        id: el.industry_id,
        name: el.industry_name,
        typeValue: el.typeValue
      })
    )
    return result;
  }
  const fixJobCategory = (id) => {
    const obj = interestList.find(x => x.id === id)
    if (obj && Object.keys(obj).length > 0)
      return obj
    else
      return {}
  }
  const fixJobRole = (id) => {
    const obj = interestList.find(x => x.id === id)
    if (obj && Object.keys(obj).length > 0)
      return obj
    else
      return {}
  }
  const fixIndustry = (id) => {
    const obj = industryList.find(x => x.id === id)
    if (obj && Object.keys(obj).length > 0)
      return obj
    else
      return {};
  }
  const fixCity = (id) => {
    const obj = locationList.find(x => x.id === id)
    console.log({ id, obj })
    if (obj && Object.keys(obj).length > 0)
      return obj
    else
      return {}
  }
  const fixPreferredCity = (data) => {
    let objList = []
    if (!data)
      return objList;
    data.map(loc => {
      const obj = locationList.find(x => { return x.id === parseInt(loc) })
      if (obj) {
        objList.push(obj)

      }
    })
    return objList;

  }
  const fixInterests = (data) => {
    let objList = []
    console.log({ data })
    if (!data)
      return objList;
    data.map(interest => {
      const obj = interestList.find(x => { return x.id === parseInt(interest) })
      console.log({ obj, interest })
      if (obj) {

        objList.push(obj)
      }
    })
    return objList;

  }
  // const FixSkills =(data) =>{
  //   let jobCategoryList=[]
  //       let jobTypeList= []
  //       if(values?.lastExperience?.job_category)
  //           jobCategoryList.push(values.lastExperience.job_category.id)
  //       if(values?.lastExperience?.job_role)
  //           jobTypeList.push(values.lastExperience.job_role.id)
  //       if(values?.interests?.length>0){
  //           values.interests.map(interest =>{
  //               if(interest.type==="category")
  //                   jobCategoryList.push(interest.id)
  //               if(interest.type==="role")
  //                   jobTypeList.push(interest.id)
  //           })
  //       }    
  //       console.log(values.interests, jobCategoryList, jobTypeList)
  //       Axios.get(`${urlLinks.baseUrl}/workermaster/getskills?job_type_id=${jobTypeList.toString()}&job_category_id=${jobCategoryList.toString()}`)
  //           .then(response =>{
  //               setSkillsList(response.data.response)
  //           })
  // }

  const fixExperience = (exp, index) => {
    // console.log({exp});
    if(exp==="Years,Months"){
      console.log("in "+exp);
      return null;
  }
    let numbersArray = []
    if (exp) {
      numbersArray = exp.match(/\d+/g);
    }
    if (numbersArray[index]) {
      return numbersArray[index];
    }
    else {
      return "";
    }
  }
  const makeData = (data) => {
    console.log({ data },"--------------->")
    let avaliabilityList = []
    data.workerAvailabilities.map(avail => {
      avaliabilityList.push({
        availablityId: avail.availablityId,
        startTime: moment(avail.loginTime, "HH:mm:ss").format(),
        endTime: moment(avail.logoutTime, "HH:mm:ss").format(),
        weekdays: formDays(JSON.parse(avail.workdaysDetails))
      })
    })

    console.log({ avaliabilityList })
    let lastExp = {}
    let experienceList = []
    data.workerExperiences.map(exp => {

      experienceList.push({
        company: exp.companyName,
        designation: exp.designation,
        jobStartDate: exp.fromDate,
        jobEndDate: exp.toDate,
        expId: exp.experienceId,
        job_category: fixJobCategory(exp.industryType),
        job_role: fixJobRole(exp.jobType),
        industry: fixIndustry(exp.industryId),
        otherIndustry: exp.otherIndustry,
        city: exp.workLocation

      })
    })
    if (experienceList.length > 0)
      lastExp = experienceList.pop();
      console.log({lastExp})
      
    const formData = {
      appRegistered: data.worker_status === "Deactivated" ? 1 : 0,
      name: `${data.firstName} ${data.lastName ? data.lastName : ''}`,
      dob: data.dateOfBirth ? moment(data.dateOfBirth, "DD-MM-YYYY") : null, //missing
      gender: data.gender,
      contact_number: data.phoneNumber,
      email: data.emailId || '',
      whatsapp_number: data.whatsappNumber || '',
      address: data.addressLine1 || '',
      city: fixCity(data.cityId),
      pincode: data.pincode,
      preferred_locations: data.preferred_location ? fixPreferredCity(data.preferred_location.split(',')) : [],
      interests: data.interested_cat ? fixInterests(data.interested_cat.split(',')) : [],
      highest_qualification: data.qualificationId,
      year_of_passing: data.year_of_passing,
      specialization: data.specialisation,
      language: data.languages_known ? data.languages_known.split(',') : [],
      english_proficiency: data.english_known_level,
      bike: (data.own_bike === "false" || data.own_bike === false || !data.own_bike) ? false : true,
      bike_license: (data.bike_license === "false" || data.bike_license === false || !data.bike_license) ? false : true,
      laptop: (data.own_laptop === "false" || data.own_laptop === false || !data.own_laptop) ? false : true,
      no_exp: data.hasExperience === 1 ? false : true,
      expected_salary: data.expected_salary,
      expected_salary_type: data.expected_salary_type ? data.expected_salary_type : 0,
      last_salary: data.last_salary,
      last_salary_type: data.last_salary_type ? data.last_salary_type : 0,
      notice_period: data.notice_period,
      experienceList: experienceList,
      lastExperience: lastExp,
      lastExperienceView: lastExp,
      total_experience: data.total_experience,
      years_exp: fixExperience(data.total_experience, 0),
      month_exp: fixExperience(data.total_experience, 1),
      skillData: data.skill?data.skill.replace(",",", "):"",
      skillsReceived: data.userSkills,
      currentlyWorking: data.workerExperiences?.pop()?.currentWorking === 1 ? true : false,
      workerAvailability: avaliabilityList,
      newExp: { jobStartDate: null, jobEndDate: null },
      global_available: data.globalAvailability === 0 ? false : true,
      cvLink: data.cvLink,
      cvName: data.cv_file_name,
      workerIdData: data.workerId,
      skills: [],
      delAvail: [],
      delExp: []
    };
    console.log({ formData })

    setWorkerData(formData);
  }
  useEffect(() => {
    if (history.location.edit) {
      Axios.get(`${urlLinks.baseUrl}/admin/getIndustryMaster`)
        .then(response => {
          setIndustryList(makeIndustryData(response.data.response));
        });
    }

    Axios.get(`${urlLinks.baseUrl}job/getDistinctCity`)
      .then(response => {
        console.log({ response });
        setLocationList(makeLocationData(response.data.response));
      });
    Axios.get(`${urlLinks.baseUrl}configmaster/getIndustryAndJobType`)
      .then(response => {
        console.log({ response });
        setInterestList(makeInterestData(response.data.response));
      });


  }, [])
  useEffect(() => {
    if (history.location.id && locationList.length > 0 && interestList.length > 0 && industryList.length > 0) {
      fetch(`${urlLinks.baseUrl}workermaster/V2/workerByUserId?user_id=${workerId}`)
        .then(response => response.json())
        .then(data => {
          localStorage.removeItem("recruiterEditWorkerId")
          makeData(data.response.content[0]);
        })
    };
  }, [locationList, interestList, industryList])
  const getExp = (yrs, months) => {
    return `${yrs},${months} `;
  }

  const formData = {
    appRegistered: 1,
    name: '',
    dob: null,
    gender: '',
    contact_number: '',
    email: '',
    whatsapp_number: '',
    address: '',
    city: {},
    pincode: '',
    preferred_locations: [],
    highest_qualification: "",
    year_of_passing: "",
    specialization: '',
    language: [],
    english_proficiency: '',
    bike: false,
    bike_license: false,
    laptop: false,
    no_exp: false,
    total_experience: '',
    expected_salary: '',
    expected_salary_type: 0,
    last_salary: '',
    last_salary_type: 0,
    currentlyWorking: false,
    experienceList: [],
    lastExperience: {},
    workerAvailability: [],
    newExp: { jobStartDate: null, jobEndDate: null },
    global_available: false,
    delAvail: [],
    delExp: [],
    skills: [],
    skillData:"",
    skillsReceived: []
  };
  console.log(JSON.parse(localStorage.getItem("recNewAddCandidate")))
  const postWorkerCall = (data, setSubmitting) => {
    console.log({ data })
    if (data.delExp) {
      console.log("delExp")
      Axios.delete(`${urlLinks.baseUrl}/workermaster/experience?experience_id=${data.delExp.toString()}`)
    }
    if (data.delAvail) {
      console.log("delAvail")
      data.delAvail.map(avail => {
        Axios.delete(`${urlLinks.baseUrl}/workermaster/availability?availablity_id=${avail}`)
      })
    }
    console.log("past del")
    Axios.post(`${urlLinks.baseUrl}admin/V2/addWorker/${editFlag ? data.workerId : ''}`, data)
      .then(response => {
        if (response.data.code === 1000) {

          if (history?.location?.interviewDashboardData) {
            history.push({
              pathname: '/jobs/JobDetailed',
              interviewDashboardData: history?.location?.interviewDashboardData,
              id: history?.location?.interviewDashboardData?.id
            })
          }
          else if (history?.location?.recruiterDashBoardData) {
            history.push({
              pathname: localStorage.getItem('roleType') === "recruiter" ? '/' : '/recruiter/dashboard',
              recruiterDashBoardData: history.location.recruiterDashBoardData
            })
          }
          else if (localStorage.getItem("recNewAddCandidate")) {
            const recData = { ...JSON.parse(localStorage.getItem("recNewAddCandidate")) }
            console.log({ recData })
            const postData = {
              recruiter_id: recData.recruiterId,
              job_id: recData.jobId,
              user_ids: response.data.response.userId,
              inserted_by: localStorage.getItem("userId")
            }
            Axios.post(`${urlLinks.baseUrl}extWorker/assignExtToRecruiter`, qs.stringify(postData))
              .then(response => {
                if (response.data.code === 1000) {
                  history.push({
                    pathname: '/worker/workersuccess',
                    data: {
                      code: response.data.code,
                      recruiterDashBoardData: history.location.recruiterDashBoardData,
                      from: history.location.from
                    }
                  })
                }
                if (response.data.code === 1002) {
                  history.push({
                    pathname: '/worker/workersuccess',
                    data: {
                      code: response.data.code,
                      recruiterDashBoardData: history.location.recruiterDashBoardData,
                      message: response.data.message,
                      from: history.location.from
                    }
                  })
                }
              })
              .catch(error => {
                showToast("error", "Worker cannot be assigned to recruiter")
                setSubmitting.setSubmitting(false)
              })
          }
          else {
            history.push({
              pathname: '/worker/workersuccess',
              data: {
                code: response.data.code,
                recruiterDashBoardData: history.location.recruiterDashBoardData,
                message: response.data.message,
                from: history.location.from
              }
            })
          }
        }
        else {
          history.push({
            pathname: '/worker/workersuccess',
            data: { from: history.location.from }
          })
        }
      })
      .catch(error => {
        showToast("error", "Worker cannot be assigned to recruiter")
        setSubmitting.setSubmitting(false)
      });
  }
  const handleSubmit = (values, setSubmitting) => {

    if (step === 2) {
      const postDataWorker = {}
      let nameArray = values.name ? values.name.trim().split(' ') : [];
      if (nameArray.length > 0)
        postDataWorker["firstName"] = nameArray[0]
      if (nameArray.length > 1) {
        postDataWorker["lastName"] = nameArray.slice(1).join(" ");
      }
      let tempList = values.experienceList
      console.log("One")
      if (Object.keys(values.lastExperience).length > 0)
        tempList.push(values.lastExperience)
      let experienceFixed = []
      if (tempList.length > 0)
        tempList.map((exp, index) => {
          let data = {}
          data.toDate = null
          if (exp.company) data.companyName = exp.company
          if (exp.designation) data.designation = exp.designation
          if (exp.jobStartDate) data.fromDate = exp.jobStartDate
          if (exp.jobEndDate) data.toDate = exp.jobEndDate
          if (exp.job_role) data.jobType = exp.job_role.id
          if (exp.job_category) data.industryType = exp.job_category.id
          if (exp.industry) data.industryId = exp.industry.id
          if (exp.city) data.workLocation = exp.city
          if (exp.expId) data.experienceId = exp.expId
          if (exp.otherIndustry) data.otherIndustry = exp.otherIndustry
          if (index === tempList.length - 1) {
            data.currentWorking = values.currentlyWorking === false ? 0 : 1
          }
          experienceFixed.push(data)
        })
      tempList = values.workerAvailability;
      let availabilityFixed = []
      console.log("Two")
      if (values?.workerAvailability?.length > 0)
        tempList.map(avail => {
          let data = {}
          if (avail.startTime) data.loginTime = moment(avail.startTime).format("HH:mm:00")
          if (avail.endTime) data.logoutTime = moment(avail.endTime).format("HH:mm:00")
          if (avail.weekdays.length > 0) data.workdaysDetails = JSON.stringify(formWeekDays(avail.weekdays))
          if (avail.availablityId) data.availabilityId = avail.availablityId
          availabilityFixed.push(data)
        })
      console.log("Three")
      tempList = values.interests;
      let interestFixed = []
      if (values?.interests?.length > 0)
        tempList.map(interest => {
          interestFixed.push(interest.id)
        })
      tempList = values.preferred_locations;
      let pLocationFixed = []
      if (values?.preferred_locations?.length > 0)
        tempList.map(location => {
          pLocationFixed.push(location.id)
        })
      let skillsFixed = []
      if (values?.skills?.length > 0)
        values.skills.map(skill => {
          let data = {
            skillsName: skill.skills_name,
            skillsTypeId: skill.skills_type_id
          }
          skillsFixed.push(data)

        })

      postDataWorker["requestedBy"] = localStorage.getItem('userId');
      if (values.gender) postDataWorker["gender"] = values.gender;
      if (values.dob) postDataWorker["dateOfBirth"] = values.dob ? moment(values.dob).format("YYYY-MM-DD") : null;
      if (values.contact_number) postDataWorker["phoneNumber"] = values.contact_number;
      if (values.whatsapp_number) postDataWorker["whatsappNumber"] = values.whatsapp_number;
      if (values.email) postDataWorker["emailId"] = values.email;
      if (values.address) postDataWorker["addressLine1"] = values.address;
      if (values.city) {
        postDataWorker["cityId"] = values?.city?.id;
        postDataWorker["city"] = values?.city?.city;
        postDataWorker["state"] = values?.city?.state
      }
      if (skillsFixed.length > 0) postDataWorker["skills"] = skillsFixed
      if (pLocationFixed.length > 0) postDataWorker["preferred_location"] = pLocationFixed.toString();
      if (values.pincode) postDataWorker["pincode"] = parseInt(values.pincode);
      if (values.highest_qualification) postDataWorker["qualificationId"] = values.highest_qualification;
      if (values.year_of_passing) postDataWorker["year_of_passing"] = values.year_of_passing;
      if (values.specialization) postDataWorker["specialisation"] = values.specialization;
      if (values.language) postDataWorker["languages_known"] = values.language.toString();
      if (values.english_proficiency) postDataWorker["english_known_level"] = values.english_proficiency;
      postDataWorker["own_bike"] = values.bike;
      postDataWorker["bike_license"] = values.bike_license;
      postDataWorker["own_laptop"] = values.laptop;
      postDataWorker["hasExperience"] = values.no_exp === false ? 1 : 0;
      postDataWorker["currentWorking"] = values.currentlyWorking === false ? 0 : 1;
      if (values.years_exp || values.month_exp) postDataWorker["total_experience"] = getExp(values.years_exp, values.month_exp);
      if (values.expected_salary) postDataWorker["expected_salary"] = values.expected_salary;
      if (values.expected_salary_type >= 0) postDataWorker["expected_salary_type"] = values.expected_salary_type;
      if (!values.no_exp) postDataWorker["workerExperiences"] = experienceFixed;
      postDataWorker["globalAvailability"] = (values.global_available === true || availabilityFixed.length === 0) ? 1 : 0;
      if (availabilityFixed.length > 0) postDataWorker["workerAvailabilities"] = availabilityFixed;
      if (interestFixed.length > 0) postDataWorker["interested_cat"] = interestFixed.toString();
      if (values.notice_period) postDataWorker["notice_period"] = values.notice_period;
      if (values.last_salary) postDataWorker["last_salary"] = values.last_salary;
      if (values.last_salary_type >= 0) postDataWorker["last_salary_type"] = values.last_salary_type;
      if (values.cv) postDataWorker["cvLink"] = values.cv;
      if (values.cvName) postDataWorker["cvName"] = values.cvName;
      if (values.workerIdData) postDataWorker["workerId"] = values.workerIdData;
      if (values.delAvail.length > 0) postDataWorker["delAvail"] = values.delAvail
      if (values.delExp.length > 0) postDataWorker["delExp"] = values.delExp
      if (values.skillData) postDataWorker["skill"] = values.skillData
      postWorkerCall(postDataWorker, setSubmitting);

    }
    if (step < 2) {
      console.log({ setSubmitting })
      console.log("fasedd")
      setSubmitting.setSubmitting(false)
      setStep(step + 1)
    }
  }
  function Effect(props) {
    const effect = () => {
      if (props.formik.submitCount > 0 && !props.formik.isValid) {
        props.onSubmissionError();
      }
    };
    React.useEffect(effect, [props.formik.submitCount]);
    return null;
  }


  const handleBack = () => {
    setStep(step => step - 1)
  }
  let validate1 = Yup.object().shape({

    name: Yup.string()
      .required("Please enter name"),
    contact_number: Yup.number().test('len', 'Candidate Contact number should be 10 digits', val => (val && val.toString().length === 10) || !val).required("Enter a phone number."),
    email: Yup.string()
      .email()
      .notRequired(),
    gender: Yup.string().required("Please select a gender.").nullable(),
    whatsapp_number: Yup.number().test('len', 'Candidate Contact number should be 10 digits', val => (val && val.toString().length === 10) || !val),



    // jobRole:Yup.number()
    //   .required("Select a job role"),
    // pincode:Yup.number()
    //   .test('len', 'Pincode should be 6 digits', val => (val && val.toString().length === 6))
    //   .required(),
    // minSalary:Yup.number()
    //   .when('jobCategory' , {
    //     is: 1 ,
    //     then: Yup.number().required("Required Minimum Salary").max(9999999),
    //     otherwise: Yup.number().notRequired()
    //   })
    //   .when('jobCategory' , {
    //     is: 2 ,
    //     then: Yup.number().required("Required Minimum Salary").max(9999999),
    //     otherwise: Yup.number().notRequired()
    //   }),

    // maxSalary:Yup.number()
    //   .when('jobCategory' , {
    //     is: 1  ,
    //     then: Yup.number().required("Required Maximum Salary").max(9999999),
    //     otherwise: Yup.number().notRequired()
    //   })
    //   .when('jobCategory' , {
    //     is: 2  ,
    //     then: Yup.number().required("Required Maximum Salary").max(9999999),
    //     otherwise: Yup.number().notRequired()
    //   }),
    // odSalary:Yup.number()
    //   .when('jobCategory' , {
    //     is: 3 ,
    //     then: Yup.number().required("Required Salary").max(99999),
    //     otherwise: Yup.number().notRequired()
    //   }),
    // salaryType: Yup.string().required("Required"),
    // noOfDays:Yup.number()
    //   .when('jobCategory' , {
    //     is: 1 ,
    //     then: Yup.number().required("Select number of days"),
    //     otherwise: Yup.number().notRequired()
    //   })
    //   .when('jobCategory' , {
    //     is:3 ,
    //     then: Yup.number().required("Select number of days"),
    //     otherwise: Yup.number().notRequired()
    //   }),
    // noOfHours:Yup.number()
    //   .when('jobCategory' , {
    //     is: 1  ,
    //     then: Yup.number().required("Select number of hours"),
    //     otherwise: Yup.number().notRequired()
    //   }),
    // weekedays:Yup.array().of(Yup.string())
    //   .when('jobCategory' , {
    //     is: 2 ,
    //     then: Yup.array().of(Yup.string().min(1)).required("Select atleast 1 day"),
    //     otherwise: Yup.array().of(Yup.string()).notRequired()
    //   }),
    // startTime:Yup.string()
    //   .when('jobCategory' , {
    //     is: 2 ,
    //     then: Yup.string().required("Select start time").nullable(),
    //     otherwise: Yup.string().notRequired()
    //   })
    //   .when('jobCategory' , {
    //     is: 3 ,
    //     then: Yup.string().required("Select start time").nullable(),
    //     otherwise: Yup.string().notRequired().nullable()
    //   }),
    // endTime:Yup.string()
    //   .when('jobCategory' , {
    //     is: 2 ,
    //     then: Yup.string().required("Select end time").nullable(),
    //     otherwise: Yup.string().nullable().notRequired()
    //   })
    //   .when('jobCategory' , {
    //     is: 3 ,
    //     then: Yup.string().required("Select end time").nullable(),
    //     otherwise: Yup.string().nullable().notRequired(),
    //   }),

    // startDate:Yup.date()
    //   .when('jobCategory' , {
    //     is: 3 ,
    //     then: Yup.date().required("Select start date").nullable(),
    //     otherwise: Yup.date().notRequired().nullable()
    //   }),
    // jobDescription:Yup.string()
    //   .required("Enter job description")
  })
  let validate2 =
    Yup.object().shape({
      years_exp: Yup.number().integer("No decimal allowed").nullable(),
      month_exp: Yup.number().integer("No decimal allowed").nullable().test('len', 'less than 12', val => (val && val < 12) || !val),
      expected_salary: Yup.number().nullable(),
      last_salary: Yup.number().nullable(),
      // notice_period:Yup.number().nullable()  
      //   interviewSlots:Yup.array().of(Yup.object().shape({
      //         weekdays: Yup.array().required("Select atleast one day"),
      //         startTime: Yup.string().required("Enter start time").nullable(),
      //         endTime: Yup.string().required("Enter end time").nullable()
      //   })).required().min(1)
    })
  let validate3 = Yup.object().shape({
    contact_number: Yup.number().test('len', 'Candidate Contact number shoyld be 10 digits', val => (val && val.toString().length === 10) || !val),
    pocNumber: Yup.number().test('len', 'Contact number shoyld be 10 digits', val => (val && val.toString().length === 10) || !val),

    accountManager: Yup.string()
      .required("Select one Account manager"),
    instructionsForRecruiter: Yup.string()
      .required("Please enter some instructions for recruiter"),
    radioGroup: Yup.string()
      .required("Select one option"),
    commisionPerJoining: Yup.number()
      .typeError('Must be a number')
      .when('radioGroup', {
        is: "Fixed",
        then: Yup.number().required("Enter Commission Value"),
        otherwise: Yup.number().notRequired()
      })
      .when('radioGroup', {
        is: "Variable",
        then: Yup.number().required("Enter Commission %").max(100, "Commission can't be more than 100%"),
        otherwise: Yup.number().notRequired()
      })
      .positive("Commission can't be negative"),
    invoicingPeriod: Yup.number()
      .typeError('Must be a number')
      .required("Enter Invoice Period")
      .min(0, "Invoice Period can't be less than 0 days")
      .max(180, "Invoice Period can't be more than 180 days"),
    replacementPeriod: Yup.number()
      .typeError('Must be a number')
      .required("Enter Replacement Period")
      .min(0, "Replacement Period can't be less than 0 days")
      .max(180, "Replacement Period can't be more than 180 days"),
  })
  console.log({formData, workerData})
  const validate = [validate1, validate2, validate3]
  return (
    <Fragment>
      <ToastContainer
        position='top-right'
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        autoClose={1500}
        rtl={false}
        draggable
        pauseOnHover
      ></ToastContainer>
      <div className="stepper">
        <Stepper activeStep={step - 1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <Formik
        enableReinitialize
        initialValues={Object.entries(workerData).length === 0 ? formData : workerData}
        onSubmit={(values, setSubmitting) => handleSubmit(values, setSubmitting)}
        validationSchema={validate[step - 1]}
        setValues
      >
        {(formik) => {
          const { values, errors, touched, handleChange, setFieldValue, setValues, isSubmitting } = formik;
          console.log({ isSubmitting })
          return (
            <Form className={classes.form}>

              {step === 1 &&
                <Effect
                  formik={formik}
                  onSubmissionError={() => console.log({ formik })}
                />}
              {step === 2 &&
                <Effect
                  formik={formik}
                  onSubmissionError={() => console.log({ formik })}
                />}
              {renderStep(step, values, errors, touched, handleChange, setFieldValue, setValues, genderOptions, highestQualificationOptions, optionsYearofPassing, englishProficiencyOptions, locationList, interestList, editFlag, workerData, emailExist, contactExist, setEmailExist, setContactExist)}
              <StepButton
                step={step}
                emailExist={emailExist}
                contactExist={contactExist}
                handleBack={handleBack}
                values={values}
                isSubmitting={isSubmitting}
              />
            </Form>)
        }}
      </Formik>
    </Fragment>
  );
};

export default MultiStep;