import React, { useState, useEffect , Fragment} from "react";
import { useHistory } from "react-router-dom";
import { FormFirstStep } from "./forms/FormFirstStep";
import { FormSecondStep } from "./forms/FormSecondStep";
import { FormThirdStep } from "./forms/FormThirdStep";
import { Formik, Form } from "formik";
import Axios from 'axios';
import qs from 'querystring';
import * as moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { StepButton } from "./StepButton";
import { makeStyles } from "@material-ui/core/styles";
import utilFunctions from '../../../utilMethods';
import {Stepper, Step, StepLabel, Typography} from '@material-ui/core';
import urlLinks from '../../../urlLinks';

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const renderStep = (step, values, errors, touched, handleChange, setFieldValue, companyList, jobRoleList, setValues, managerList) => {
  switch (step) {
    case 1:
      return <FormFirstStep 
                errors={errors} 
                touched={touched} 
                values={values} 
                handleChange={handleChange} 
                setFieldValue={setFieldValue}
                companyList={companyList} 
                setValues={setValues}
                jobRoleList={jobRoleList}
                
                />;
    case 2:
      return <FormSecondStep 
                errors={errors} 
                touched={touched} 
                values={values} 
                handleChange={handleChange} 
                setFieldValue={setFieldValue}
                setValues={setValues}
                />;
    case 3:
      return <FormThirdStep 
                errors={errors}
                touched={touched} 
                setFieldValue={setFieldValue} 
                values={values} 
                managerList={managerList}
                handleChange={handleChange} 
                />;
    default:
      return <FormFirstStep 
                errors={errors} 
                touched={touched} 
                values={values} 
                handleChange={handleChange} 
                setFieldValue={setFieldValue}
                companyList={companyList} 
                setValues={setValues}
                
                />;
  }
};
const EditJob = () => {
  const [step, setStep] = useState(1);
  const classes = useStyles();
  
  const [companyList, setCompanyList] = useState([])
  const [jobRoleList, setJobRoleList] = useState([])
  const [managerList, setManagerList] = useState([])
  const [skillData, setSkillData] = useState({})
  const [jobData, setJobData] = useState({})
  const history= useHistory();
  const jobId=history.location.id;
  const  steps = ['Step 1', 'Step 2', 'Step 3'];
  const formWeekDays =(weekdays) =>{
    const week={
      "1": false, 
      "2": false, 
      "3": false, 
      "4": false, 
      "5": false, 
      "6": false, 
      "7": false
    }
    weekdays.map(day =>{
     
      if(day==="monday")
        week["2"]=true
      if(day==="tuesday")
        week["3"]=true
      if(day==="wednesday")
        week["4"]=true
      if(day==="thursday")
        week["5"]=true
      if(day==="friday")
        week["6"]=true
      if(day==="saturday")
        week["7"]=true
      if(day==="sunday")
        week["1"]=true
    })
    return week;
  } 
  const formDays =(week) =>{
    const days=[]
     if(week["0"]===true)
        days.push("monday","tuesday","wednesday","thursday","friday","saturday","sunday")
    if(week["2"]===true)
      days.push("monday")
    if(week["3"]===true)
      days.push("tuesday")
    if(week["4"]===true)
      days.push("wednesday")
    if(week["5"]===true)
      days.push("thursday")
    if(week["6"]===true)
      days.push("friday")
    if(week["7"]===true)
      days.push("saturday")
    if(week["1"]===true)
      days.push("sunday")
    
    
    return days;
  }
  const makeData= (data) =>{ 
    var loginTime =data.loginTime? moment(data.loginTime,"HH:mm:ss"):null;
    var logoutTime = data.logoutTime?moment(data.logoutTime,"HH:mm:ss"):null;
    
    
    console.log(data.detailsJobs[0].gender)
    const formData = {
      jobId:data.jobId?data.jobId:'',
      jobDetailsId: data.detailsJobs[0].jobDetailsId?data.detailsJobs[0].jobDetailsId:'',
      userId: data.userId?data.userId:1,
      jobType: data.detailsJobs[0].jobType?data.detailsJobs[0].jobType:'',
      companyName:data.company?data.company.companyId?data.company.companyId:'':'',
      cName: data.company?data.company.companyName?data.company.companyName:'':'',
      bName: data.company?data.company.brandName?data.company.brandName:'':'',
      vacancyStatus: data.jobStatus,
      vacancyType: data.detailsJobs[0].vacancyType!==null?data.detailsJobs[0].vacancyType:'',
      payrollType: data.detailsJobs[0].payrollType?data.detailsJobs[0].payrollType:'',
      jobCategory: data.workType?data.workType:'',
      jobCategoryValue: data.workTypeKey?data.workTypeKey:'',
      numberOfOpenings: data.detailsJobs[0].employeeRequired?data.detailsJobs[0].employeeRequired:'',
      jobType: data.detailsJobs[0].jobCategoryId?data.detailsJobs[0].jobCategoryId:'',
      jobRole: data.detailsJobs[0].jobTypeId?data.detailsJobs[0].jobTypeId:'',
      otherJobrole: data.detailsJobs[0].jobTitle?data.detailsJobs[0].jobTitle:'',
      jobPosition: data.detailsJobs[0].jobPosition?data.detailsJobs[0].jobPosition:'',
      city: data.cityId?data.cityId:-1,
      detailSkills: data.detailsJobs[0].detailSkills,
      pincode: data.pincode?data.pincode:'',
      stateName: data.state_name?data.state_name:'',
      gender: data.detailsJobs[0].gender,
      cityName: data.city_name?data.city_name:'',
      boostJob: data.detailsJobs[0].boostJob===1?true:false,
      exactAddress: data.address?data.address:'',
      addressUrl: data.jobLocationUrl?data.jobLocationUrl:'',
      searchAddress:data.jobGoogleLocation ||'',
      lat: data.jobLocationLat?data.jobLocationLat:'',
      long: data.jobLocationLong?data.jobLocationLong:'',
      minSalary: data.detailsJobs[0].minSalary?data.detailsJobs[0].minSalary:data.detailsJobs[0].amount,
      maxSalary: data.detailsJobs[0].maxSalary?data.detailsJobs[0].maxSalary:data.detailsJobs[0].amount,
      odSalary: data.detailsJobs[0].amount?data.detailsJobs[0].amount:'',
      salaryType: data.detailsJobs[0].amountPer?data.detailsJobs[0].amountPer:'',
      salaryCompensation: data.detailsJobs[0].salaryCompensaction?data.detailsJobs[0].salaryCompensaction:'',
      jobDescription: data.detailsJobs[0].additionalRequirement?data.detailsJobs[0].additionalRequirement:'',
      noOfDays: data.workdaysCount?data.workdaysCount :'',
      noOfHours: data.detailsJobs[0].noOfHrs?data.detailsJobs[0].noOfHrs:0,
      weekedays: data.workdaysDetails?formDays(JSON.parse(data.workdaysDetails)):[],
      startTime: loginTime,
      endTime: logoutTime,
      startDate: data.startDate?data.startDate:null,
      specialization: data.detailsJobs[0].qualificationSpecification?data.detailsJobs[0].qualificationSpecification:'',
      englishLanguageFluency: data.detailsJobs[0].englishFluency!==null?data.detailsJobs[0].englishFluency:1,
      cvRequired:data.detailsJobs[0].cvRequired!==null?data.detailsJobs[0].cvRequired:1,
      ageFrom: data.detailsJobs[0].minAge?data.detailsJobs[0].minAge:'',
      ageTo: data.detailsJobs[0].minAge?data.detailsJobs[0].maxAge:'',
      interviewAddress: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewAddress?
          data.detailsJobs[0].jobInterviewDetails.interviewAddress:''
        :'',
      interviewAddressUrl: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewAddressUrl?
          data.detailsJobs[0].jobInterviewDetails.interviewAddressUrl:''
        :'',
      searchInterviewAddress:  data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewGoogleLocation?
          data.detailsJobs[0].jobInterviewDetails.interviewGoogleLocation:''
        :'',
      latInterview: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewLat?
          data.detailsJobs[0].jobInterviewDetails.interviewLat:''
        :'',
      longInterview: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewLong?
          data.detailsJobs[0].jobInterviewDetails.interviewLong:''
        :'',
      interviewJobDescription: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.interviewProcess?
          data.detailsJobs[0].jobInterviewDetails.interviewProcess:''
        :'',
      interviewSlots: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays?
          JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays):[]
        :[],
      specialRequirements: data.detailsJobs[0].jobInterviewDetails?
        data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement?
          data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement:''
          :'',
      pocCandidateName: data.contactCandidateName?data.contactCandidateName:'',
      pocContactNumber: data.contactCandidateNumber?data.contactCandidateNumber:'',
      pocName: data.contactPerson?data.contactPerson:'',
      pocNumber: data.contactNo?data.contactNo:'',
      pocEmailID: data.emailAccountManager?data.emailAccountManager:'',
      accountManager: data.accountManager?data.accountManager:'',
      instructionsForRecruiter: data.detailsJobs[0].instructionsRecruiter?data.detailsJobs[0].instructionsRecruiter:'',
      instructionsForCandidate: data.detailsJobs[0].instructionsCandidate?data.detailsJobs[0].instructionsCandidate:'',
      recruiterPitch: data.detailsJobs[0].recruiterPitch?data.detailsJobs[0].recruiterPitch:'',
      recruiterPayoutAmount: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.remoteRecPayoutAmount?
          data.detailsJobs[0].jobPayout.remoteRecPayoutAmount:''
        :'',
      recruiterPayoutDetails: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.remoteRecPayout_details?
          data.detailsJobs[0].jobPayout.remoteRecPayout_details:''
        :'',
      leaderPayoutAmount: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.leaderPayoutAmount?
          data.detailsJobs[0].jobPayout.leaderPayoutAmount:''
        :'',
      leaderPayoutDetails: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.leaderPayoutDetails?
          data.detailsJobs[0].jobPayout.leaderPayoutDetails:''
        :'',
      radioGroup: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.okaygoCommissionTerm!==null?
          data.detailsJobs[0].jobPayout.okaygoCommissionTerm===0?'Fixed': 'Variable'
          :''
        :'',
      commisionPerJoining: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.okaygoCommissionPerJoining?
          data.detailsJobs[0].jobPayout.okaygoCommissionPerJoining:''
        :'',
      invoicingPeriod: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.okaygoInvoicingPeriod?
          data.detailsJobs[0].jobPayout.okaygoInvoicingPeriod:''
        :'',
      replacementPeriod: data.detailsJobs[0].jobPayout?
        data.detailsJobs[0].jobPayout.okaygoReplacementClause?
          data.detailsJobs[0].jobPayout.okaygoReplacementClause:''
        :'',
      referralAmount: data.detailsJobs[0].referralClaimAmount?data.detailsJobs[0].referralClaimAmount:'',

    };
    setJobData(formData);
  }
  useEffect( () => {
    Axios.get(`${urlLinks.baseUrl}/configmaster/?category_sub_type=industry&inserted_by=0&rows=20&status=1`)
      .then(response => {
        setJobRoleList(response.data.response.content) ;
      }); 
    Axios.get(`${urlLinks.baseUrl}user/?role_types=1,2&rows=100`)
      .then(response => {
        setManagerList(response.data.response.content) ;
      });    
  }, []);
  useEffect( () => {
    if(!history.location.id)
      history.push("/jobs/jobList");
    else
    {
      fetch(`${urlLinks.baseUrl}/job/V2/getdetailedjob?job_id=${jobId}`)
      .then(response => response.json())
      .then(data => {

        makeData(data.response.content[0]) ;  
      })
    }
  }, []);
  const formData ={
    companyName:'',
    vacancyStatus:1,
    vacancyType:'',
    payrollType:'',
    jobCategory:'',
    numberOfOpenings:'',
    jobRole:'',
    jobType:'',
    otherJobrole:'',
    city:'',
    cityName:'',
    stateName:'',
    pincode: '',
    boostJob: false,
    exactAddress:'',
    addressUrl:'',
    searchAddress:'',
    lat:'',
    long:'',
    cuisine:'',
    minSalary:'',
    maxSalary:'',
    odSalary:'',
    salaryType:'',
    salaryCompensation:'',
    jobDescription:'',
    noOfDays:'',
    noOfHours:'',
    weekedays:[],
    startTime:null,
    endTime: null,
    startDate:null,
    experience:40,
    gender: "None",
    qualification_type:"None",
    specialization:'',
    cuisine:0,
    englishLanguageFluency:1,
    ageFrom:'',
    ageTo:'',
    interviewAddress:'',
    interviewAddressUrl:'',
    searchInterviewAddress:'',
    latInterview:'',
    longInterview:'',
    interviewJobDescription:'',
    interviewSlots:[
      {
        weekdays:[],
        startTime: null,
        endTime: null
      }
    ],
    specialRequirements:'',
    pocCandidateName:'',
    pocContactNumber:'',
    pocName:'',
    pocNumber:'',
    pocEmailID:'',
    accountManager:'',
    instructionsForRecruiter:'',
    instructionsForCandidate:'',
    recruiterPitch:'',
    recruiterPayoutAmount:'',
    recruiterPayoutDetails:'',
    leaderPayoutAmount:'',
    leaderPayoutDetails:'',
    radioGroup:'',
    commisionPerJoining:'',
    invoicingPeriod:"",
    replacementPeriod:"",
    bike: false,
    smartphone:false ,
    laptop: false,
    wifi: false,
    license: false,
    rc: false,
    aadhar: false,
    pan: false,
    expCheck: false,
    expToggle: null,
    lastExpCheck: false,
    lastExpToggle: null,
    lastSalCheck: false,
    lastSalToggle: null,
    noticePeriodCheck: false,
    noticePeriodToggle: null,
    qualification_typeCheck: false,
    qualification_typeToggle: null,
    specializationCheck: false,
    specializationToggle: null,
    englishLanguageFluencyCheck: false,
    englishLanguageFluencytoggle: null,
    referralAmount:""

  };
  const postJobCall = (data, detail, skill_set) =>{
    const requestedBy=localStorage.getItem('userId')
    console.log({data, detail});
    const jobDetailId=jobData.jobDetailsId
    Axios.post(`${urlLinks.baseUrl}/job/V2/?requested_by=${requestedBy}&job_id=${jobId}`,qs.stringify(data))
      .then(response => {
        if (response.data.code === 1000) {
          const jobId=response.data.response.jobId;
          detail["job_id"]=jobId;
          skill_set.map((skill, index) =>{
            skill["insertedBy"]=requestedBy
            skill["jobDetailsId"]=0
            skill["jobId"]=jobId
            skill["updatedBy"]=requestedBy
          })
          detail["skill_set"]=JSON.stringify(skill_set)
          console.log({detail});
          
          Axios.post(`${urlLinks.baseUrl}/job/V2/details/?job_id=${jobId}&job_details_id=${jobDetailId}`,qs.stringify(detail))
            .then(response => {
              if (response.data.code === 1000) {
                localStorage.removeItem("addjobform");
                history.push({
                  pathname: '/jobs/postjob',
                  data: { code: response.data.code }
                })
              } 
              else {
                history.push({
                  pathname: '/jobs/postjob',
                  
                })
              }
            })
            .catch(error => {
              history.push({
                pathname: '/jobs/postjob',
                
              })
            }); 
        } 
        else {
          history.push({
            pathname: '/jobs/postjob',
            
          })
        }
      })
      .catch(error => {
        history.push({
            pathname: '/jobs/postjob',
            
          })
      });
  }
  const handleSubmit = (values) => {
    // localStorage.removeItem("addjobform");
    // localStorage.setItem('addjobform', JSON.stringify(values));
    if(step===3)
    {
      
      const postDataJob={}
      const postDataJobDetails ={}
      postDataJob["requested_by"]= localStorage.getItem('userId');
      postDataJob["user_id"]=values.userId;
      if(values.companyName) postDataJob["company_id"]= values.companyName;
      if(values.vacancyStatus!==null) postDataJob["job_status"]=values.vacancyStatus;
      if(values.jobCategory) postDataJob["work_type"]=values.jobCategory;
      // if(values.city) postDataJob["city_id"]=values.city;
      if(values.city) postDataJob["new_city_id"]=values.city;
      if(values.pincode) postDataJob["pincode"]=values.pincode;
      if(values.cityName) postDataJob["city_name"]=values.cityName;
      if(values.stateName) postDataJob["state_name"]=values.stateName;
      if(values.exactAddress) postDataJob["address"]=values.exactAddress;
      if(values.searchAddress) postDataJob["job_google_location"]=values.searchAddress;
      if(values.addressUrl) postDataJob["job_location_url"]=values.addressUrl;
      if(values.weekedays) postDataJob["workdays_details"]=values.weekedays.length>0?JSON.stringify(formWeekDays(values.weekedays)):[];
      if(values.noOfDays) postDataJob["workdays_count"]=values.noOfDays;
      postDataJob["start_date"]=values.startDate?moment(values.startDate).format("YYYY-MM-DD"):(values.jobCategory===1? moment(new Date()).add(15, 'days').format("YYYY-MM-DD"): moment(new Date()).add(7, 'days').format("YYYY-MM-DD"));
      postDataJob["login_time"]=values.startTime?moment(values.startTime).format("HH:mm:ss"):("09:00:00");
      postDataJob["logout_time"]=values.endTime?moment(values.endTime).format("HH:mm:ss"):("18:00:00");
      postDataJob["contact_candidate_name"]=values.pocCandidateName;
      postDataJob["contact_candidate_number"]=values.pocContactNumber;
      postDataJob["contact_person"]=values.pocName;
      postDataJob["contact_no"]=values.pocNumber;
      postDataJob["email_account_manager"]=values.pocEmailID;
      if(values.accountManager) postDataJob["account_manager"]=values.accountManager;
      if(values.lat) postDataJob["job_location_lat"]=values.lat;
      if(values.long) postDataJob["job_location_long"]=values.long;

      if(values.noOfHours) postDataJobDetails["no_of_hrs"]=values.noOfHours;
      if(values.interviewAddressUrl) postDataJobDetails['interview_url']=values.interviewAddressUrl;
      if(values.interviewAddress) postDataJobDetails['interview_address']=values.interviewAddress;
      if(values.searchInterviewAddress) postDataJobDetails["interview_google_location"]=values.searchInterviewAddress;
      if(values.latInterview) postDataJobDetails["interview_lat"]=values.latInterview;
      if(values.longInterview) postDataJobDetails["interview_long"]=values.longInterview;
      if(values.vacancyType) postDataJobDetails["vacancy_type"]=values.vacancyType;
      if(values.payrollType) postDataJobDetails['payroll_type']=values.payrollType;
      if(values.jobRole) postDataJobDetails["job_type_id"]=values.jobRole;
      if(values.otherJobrole) postDataJobDetails["job_title"]=values.otherJobrole;
      if(values.jobPosition) postDataJobDetails["job_position"]=values.jobPosition;
      if(values.jobType) postDataJobDetails["job_category_id"]=values.jobType;
      if(values.otherjob) postDataJobDetails['otherjob']=values.otherjob;
      if(values.numberOfOpenings) postDataJobDetails['employee_required']=values.numberOfOpenings;
      if(values.minSalary) postDataJobDetails["min_salary"]=values.minSalary;
      if(values.maxSalary) postDataJobDetails['max_salary']=values.maxSalary;
      if(values.salaryType) postDataJobDetails["amount_per"]=values.salaryType;
      if(values.odSalary) postDataJobDetails['amount']=values.odSalary;
      if(values.salaryCompensation) postDataJobDetails["salary_compensaction"]=values.salaryCompensation;
      if(values.boostJob) postDataJobDetails['boost_job']=values.boostJob?1:0;
      if(values.jobDescription) postDataJobDetails["additional_requirement"]=values.jobDescription;
      if(values.experience) postDataJobDetails['experience_required']=values.experience;
      if(values.gender) postDataJobDetails["gender"]=values.gender==="None"?null:values.gender;
      if(values.qualification_type) postDataJobDetails['qualification_required']=values.qualification_type==="None"?null:values.qualification_type;
      if(values.cuisine) postDataJobDetails["cuisine"]= values.cuisine==="None"?null:values.cuisine;
      if(values.specialization) postDataJobDetails["qualification_specification"]=values.specialization;
      if(values.englishLanguageFluency!==null) postDataJobDetails['english_fluency']=values.englishLanguageFluency;
      if(values.cvRequired!==null) postDataJobDetails["cv_required"]=values.cvRequired;
      if(values.ageFrom) postDataJobDetails['min_age']=values.ageFrom;
      if(values.ageTo) postDataJobDetails["max_age"]=values.ageTo;
      if(values.interviewAddress) postDataJobDetails['interview_address']=values.interviewAddress;
      if(values.interviewJobDescription) postDataJobDetails["interview_process"]=values.interviewJobDescription;
      if(values.interviewSlots) postDataJobDetails['preferred_interview_days']=JSON.stringify(values.interviewSlots);
      if(values.specialRequirements) postDataJobDetails["joining_special_requirement"]=values.specialRequirements;
      if(values.instructionsForRecruiter) postDataJobDetails['instructions_recruiter']=values.instructionsForRecruiter;
      if(values.instructionsForCandidate) postDataJobDetails["instructions_candidate"]=values.instructionsForCandidate;
      if(values.recruiterPitch) postDataJobDetails['recruiter_pitch']=values.recruiterPitch;
      if(values.recruiterPayoutAmount) postDataJobDetails["remote_rec_payout_amount"]=values.recruiterPayoutAmount; else postDataJobDetails["remote_rec_payout_amount"]=-1;
      if(values.recruiterPayoutDetails) postDataJobDetails['remote_rec_payout_details']=values.recruiterPayoutDetails;
      if(values.leaderPayoutAmount) postDataJobDetails["leader_payout_amount"]=values.leaderPayoutAmount; else postDataJobDetails["leader_payout_amount"]=-1;
      if(values.leaderPayoutDetails) postDataJobDetails['leader_payout_details']=values.leaderPayoutDetails;
      postDataJobDetails["okaygo_commission_term"]=values.radioGroup==="Fixed"?0:1;
      postDataJobDetails['okaygo_commission_per_joining']=values.commisionPerJoining;
      postDataJobDetails["okaygo_replacement_clause"]=values.replacementPeriod;
      if(values.invoicingPeriod) postDataJobDetails['okaygo_invoicing_period']=values.invoicingPeriod;
      if(values.referralAmount) postDataJobDetails['referral_claim_amount']=values.referralAmount;
      let skillSetTitles= Object.keys(values.skillData)
      let skill_set=[]
      skillSetTitles.map(skill =>{
        
        console.log({skill});
        if(skill==="SMARTPHONE"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.smartphone?1:0
          }
          if(values.smartphone)
            temp["isMandatory"]=1
          skill_set.push(temp)

        }
        else if(skill==="LAPTOP"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.laptop?1:0
          }
          if(values.laptop)
            temp["isMandatory"]=1
          skill_set.push(temp)
        }
        else if(skill==="BIKE"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.bike?1:0
          }
          if(values.bike)
            temp["isMandatory"]=1
          skill_set.push(temp)
        }
        else if(skill==="LICENSE"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.license?1:0
          }
          if(values.license)
            temp["isMandatory"]=1
          skill_set.push(temp)
        }
        else if(skill==="PAN CARD"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.pan?1:0
          }
          if(values.pan)
            temp["isMandatory"]=1
          skill_set.push(temp)
        }
        else if(skill==="WIFI"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.wifi?1:0
          }
          if(values.wifi)
            temp["isMandatory"]=1
          skill_set.push(temp)
        }
        else if(skill==="VEHICLE RC"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.rc?1:0
          }
          if(values.rc)
            temp["isMandatory"]=1
          skill_set.push(temp)
        }
        else if(skill==="AADHAR CARD"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.aadhar?1:0
          }
          if(values.aadhar)
            temp["isMandatory"]=1
          skill_set.push(temp)
        }
        else if(skill==="LAST WORK EXPERIENCE"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.lastExpCheck?1:0
          }
          if(values.lastExpCheck && values.lastExpToggle!==null)
            temp["isMandatory"]=values.lastExpToggle
          skill_set.push(temp)
        }
        else if(skill==="LAST SALARY"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.lastSalCheck?1:0
          }
          if(values.lastSalCheck && values.lastSalToggle!==null)
            temp["isMandatory"]=values.lastSalToggle
          skill_set.push(temp)
        }
        else if(skill==="NOTICE PERIOD"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.noticePeriodCheck?1:0
          }
          if(values.noticePeriodCheck && values.noticePeriodToggle!==null)
            temp["isMandatory"]=values.noticePeriodToggle
          skill_set.push(temp)
        }
        else if(skill==="SPECIALISATION"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.specializationCheck?1:0
          }
          if(values.specializationCheck && values.specializationToggle!==null)
            temp["isMandatory"]=values.specializationToggle
          skill_set.push(temp)
        }
        else if(skill==="ENGLISH_FLUENCY"){
          let temp={
            "skillsTypeId":values.skillData[skill][0].id,
            "isRequired":values.englishLanguageFluencyCheck?1:0
          }
          if(values.englishLanguageFluencyCheck && values.englishLanguageFluencytoggle!==null)
            temp["isMandatory"]=values.englishLanguageFluencytoggle
          skill_set.push(temp)
        }
        else if(values[values.skillData[skill][0].typeKey]!=="None"){
          let temp={
            "skillsTypeId":values[values.skillData[skill][0].typeKey]
          }
          if(skill==="EXPERIENCE"){
            temp["isRequired"]=values.expCheck?1:0
            temp["isMandatory"]=values.expToggle
            skill_set.push({...temp})
          }
          if(skill==="QUALIFICATION"){
            temp["isRequired"]=values.qualification_typeCheck?1:0
            temp["isMandatory"]=values.qualification_typeToggle
            skill_set.push({...temp})
          }
        }
      })
      
      postJobCall (postDataJob, postDataJobDetails, skill_set);
    }
    if(step<3)
      setStep(step => step + 1);
  }
  function Effect(props) {
    const effect = () => {
      if (props.formik.submitCount > 0 && !props.formik.isValid) {
        props.onSubmissionError();
      }
    };
    React.useEffect(effect, [props.formik.submitCount]);
    return null;
  }


  const handleBack = () => {
    setStep(step =>step-1)
  }
  let validate1 = Yup.object().shape({
    companyName: Yup.number()
      .required("Select a Company"),
    vacancyStatus:Yup.string()
      .required("Select a vacancy Status"),
    jobCategory:Yup.number()
      .required(" Select Job category"),
    numberOfOpenings: Yup.number()
      .typeError('Must be a number')
      .positive("You can't less than 1 opening")
      .integer("Only Integers are allowed")
      .required("Required"),
    jobRole:Yup.number()
      .required("Select a job role"),
    pincode:Yup.number()
      .test('len', 'Pincode should be 6 digits', val => (val && val.toString().length === 6))
      .required(),
    minSalary:Yup.number()
      .typeError('Must be a number')
      .when('jobCategory' , {
        is: 1 ,
        then: Yup.number().required("Required Minimum Salary").max(9999999),
        otherwise: Yup.number().notRequired()
      })
      .when('jobCategory' , {
        is: 2 ,
        then: Yup.number().required("Required Minimum Salary").max(9999999),
        otherwise: Yup.number().notRequired()
      }),
      
    maxSalary:Yup.number()
      .typeError('Must be a number')
      .when('jobCategory' , {
        is: 1  ,
        then: Yup.number().required("Required Maximum Salary").max(9999999),
        otherwise: Yup.number().notRequired()
      })
      .when('jobCategory' , {
        is: 2  ,
        then: Yup.number().required("Required Maximum Salary").max(9999999),
        otherwise: Yup.number().notRequired()
      }),
    odSalary:Yup.number()
      .when('jobCategory' , {
        is: 3 ,
        then: Yup.number().required("Required Salary").max(99999),
        otherwise: Yup.number().notRequired()
      }),
    salaryType: Yup.string().required("Required"),
    noOfDays:Yup.number()
      .when('jobCategory' , {
        is: 1 ,
        then: Yup.number().required("Select number of days"),
        otherwise: Yup.number().notRequired()
      })
      .when('jobCategory' , {
        is:3 ,
        then: Yup.number().required("Select number of days"),
        otherwise: Yup.number().notRequired()
      }),
    noOfHours:Yup.number()
      .when('jobCategory' , {
        is: 1  ,
        then: Yup.number().required("Select number of hours"),
        otherwise: Yup.number().notRequired()
      }),
    weekedays:Yup.array().of(Yup.string())
      .when('jobCategory' , {
        is: 2 ,
        then: Yup.array().of(Yup.string().min(1)).required("Select atleast 1 day"),
        otherwise: Yup.array().of(Yup.string()).notRequired()
      }),
    startTime:Yup.string()
      .when('jobCategory' , {
        is: 2 ,
        then: Yup.string().required("Select start time").nullable(),
        otherwise: Yup.string().notRequired()
      })
      .when('jobCategory' , {
        is: 3 ,
        then: Yup.string().required("Select start time").nullable(),
        otherwise: Yup.string().notRequired().nullable()
      }),
    endTime:Yup.string()
      .when('jobCategory' , {
        is: 2 ,
        then: Yup.string().required("Select end time").nullable(),
        otherwise: Yup.string().nullable().notRequired()
      })
      .when('jobCategory' , {
        is: 3 ,
        then: Yup.string().required("Select end time").nullable(),
        otherwise: Yup.string().nullable().notRequired(),
      }),
      
    startDate:Yup.date()
      .when('jobCategory' , {
        is: 3 ,
        then: Yup.date().required("Select start date").nullable(),
        otherwise: Yup.date().notRequired().nullable()
      }),
    jobDescription:Yup.string()
      .required("Enter job description")
  })
  let validate2 =
   Yup.object().shape({
    //   interviewSlots:Yup.array().of(Yup.object().shape({
    //         weekdays: Yup.array().required("Select atleast one day"),
    //         startTime: Yup.string().required("Enter start time").nullable(),
    //         endTime: Yup.string().required("Enter end time").nullable()
    //   })).required().min(1)
  })
  let validate3 = Yup.object().shape({
    pocContactNumber: Yup.number().test('len', 'Candidate Contact number shoyld be 10 digits', val => (val && val.toString().length === 10) || !val), 
    pocNumber: Yup.number().test('len', 'Contact number shoyld be 10 digits', val => (val && val.toString().length === 10) || !val), 
    pocEmailID:Yup.string()
      .email()
      .notRequired(),
    accountManager:Yup.string()
      .required("Select one Account manager"),
    instructionsForRecruiter:Yup.string()
      .required("Please enter some instructions for recruiter"),
    radioGroup:Yup.string()
      .required("Select one option"),
    recruiterPayoutAmount: Yup.string()
      .when('payoutCheck', {
        is: true,
        then: Yup.string().required("Payout amount is required."),
        otherwise: Yup.string().notRequired()
      }),
    leaderPayoutAmount: Yup.string()
      .when('payoutCheck', {
        is: true,
        then: Yup.string().required("Payout amount is required."),
        otherwise: Yup.string().notRequired()
      }),
    commisionPerJoining:Yup.number()
      .typeError('Must be a number')
      .when('radioGroup' , {
        is: "Fixed" ,
        then: Yup.number().required("Enter Commission Value"),
        otherwise: Yup.number().notRequired()
      })
      .when('radioGroup' , {
        is: "Variable" ,
        then: Yup.number().required("Enter Commission %").max(100, "Commission can't be more than 100%"),
        otherwise: Yup.number().notRequired()
      })
      .positive("Commission can't be negative"),
    invoicingPeriod: Yup.number()
      .typeError('Must be a number')
      .required("Enter Invoice Period")
      .min(0, "Invoice Period can't be less than 0 days")
      .max(180, "Invoice Period can't be more than 180 days"),
    replacementPeriod: Yup.number()
      .typeError('Must be a number')
      .required("Enter Replacement Period")
      .min(0, "Replacement Period can't be less than 0 days")
      .max(180, "Replacement Period can't be more than 180 days"),
  })
  const validate= [validate1, validate2, validate3]
  // console.log({skillData});
  
  return (
    <Fragment>
      <div className="stepper">
        <Stepper activeStep={step-1} alternativeLabel>
          {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
        </Stepper>
      </div>
      <Formik
        enableReinitialize
        initialValues={Object.entries(jobData).length === 0 ? formData: jobData }
        onSubmit= {handleSubmit}
        validationSchema={validate[step-1]}
        setValues
      >
        {(formik) => {
          const { values, errors, touched, handleChange, setFieldValue, setValues} = formik;
          console.log({values});
          
          return (
          <Form className={classes.form}>
          
            {step ===1 &&
              <Effect
              formik={formik}
              onSubmissionError={() => console.log({formik})}
            />}
            {step ===2 &&
              <Effect
              formik={formik}
              onSubmissionError={() => console.log({formik})}
            />}
            {step ===3 &&
              <Effect
              formik={formik}
              onSubmissionError={() => console.log({formik})}
            />}
            {renderStep(step, values, errors, touched, handleChange, setFieldValue, companyList, jobRoleList, setValues, managerList)}
            <StepButton 
              step={step} 
              handleBack={handleBack} 
              values={values} 
             />
          </Form>)
        }
        }
      </Formik>
    </Fragment>
  );
};

export default EditJob;