import React from 'react'
import {Grid} from '@material-ui/core';
import * as moment from 'moment'


const Instructions = (props) =>{
    const data=props.data
    const values={
      instructionsForRecruiter: data.detailsJobs[0].instructionsRecruiter?data.detailsJobs[0].instructionsRecruiter:'-',
      instructionsForCandidate: data.detailsJobs[0].instructionsCandidate?data.detailsJobs[0].instructionsCandidate:'-',
      recruiterPitch: data.detailsJobs[0].recruiterPitch?data.detailsJobs[0].recruiterPitch:'-'

    }
    return (
        <Grid container spacing={0} className="jobDetail" >
            <Grid item xs={12} className="subHeading">
            <span>Instructions</span>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Instructions for recruiter</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.instructionsForRecruiter}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Instructions for candidate</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.instructionsForCandidate}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Recruiter's pitch</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.recruiterPitch}</span>
                </Grid>
            </Grid>
        </Grid>
        
    )
}
export default Instructions;

