import React, { Component } from 'react';
import financeUtils from './financeUtils';
import { Card, Grid } from '@material-ui/core';
import HideUnhideColumns from '../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../utils/CustomFilter/CustomFilter';
import CustomTable from "../../utils/Table/Table";
import Axios from 'axios';
import urlLinks from '../../urlLinks';

class FinanceAllLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            filters: financeUtils.allLedgerFilterList,
            currentPage: 0,
            count:0,
            open:false,
            rowsPerPage:10,
            isTableUpdating:false,
            column: [{
                id: "1",
                name: "category",
                label: "Category",
                isColumnVisible: true
            }, {
                id: "2",
                name: "employee id",
                label:"Employer ID",
                isColumnVisible: true
            }, {
                id: "3",
                name: "date",
                label: "Date",
                isColumnVisible: true
            }, 
            {
                id: "4",
                name: "invoice id",
                label: "Invoice ID",
                isColumnVisible: true
            }
                , {
                id: "5",
                name: "job type",
                label: "Job Type",
                isColumnVisible: true
            },
            // {
            //     id: "6",
            //     name: "item",
            //     label: "item",
            //     isColumnVisible: true

            // },
            // {
            //     id: "7",
            //     name: "due date",
            //     label: "Due Date",
            //     isColumnVisible: true
            // },
             {
                id: "8",
                name: "total amt",
                label: "Total Amt",
                isColumnVisible: true
            }, {
                id: "9",
                name: "due amt",
                label: "Due Amt",
                isColumnVisible: true
            }, {
                id: "10",
                name: "received amt",
                label: "Received Amount",
                isColumnVisible: true
            },
            // {
            //     id: "11",
            //     name: "overdue days",
            //     label: "Overdue Days",
            //     isColumnVisible: true
            // },
            // {
            //     id: "12",
            //     name: "paid amt",
            //     label: "Paid",
            //     isColumnVisible: true
            // },
            {
                id: "13",
                name: "trx Id",
                label: "Trx ID",
                isColumnVisible: true
            },{
                id: "14",
                name: "action",
                label: "Action",
                isColumnVisible: false
            }]
        }
        this.data = [

        ];

        this.rowsOptions=[5,10,25,50,100];
        this.updateColumnFileds = this.updateColumnFileds.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateDataOnTablePagination =this.updateDataOnTablePagination.bind(this);

    }
    updateColumnFileds(columnsToHide) {
        const upadtedColumns = this.state.column.map((col) => {
            if (columnsToHide.includes(col.id)) {
                col.isColumnVisible = true;
            } else {
                col.isColumnVisible = false;
            }
            return (col);
        })

        this.setState({
            columns: upadtedColumns,
        })
    }
    async updateFilters(recievedFilters) {

        const updatedFilter = this.state.filters.map((filter) => {
            for (let el of recievedFilters) {
                if (el[filter.name]) {
                    filter.default = el[filter.name]
                }
            }
            return filter;
        })
        await this.setState({
            filters: updatedFilter
        })

    }

    makeData = (data) => {
        const extractedData = [];
        data.map(el => {
            extractedData.push({
                "category": el.userCategory,
                "employee id": el.userId,
                "date": el.trxnDate ? el.trxnDate.substring(0, 10) : '-',
                "invoice id": el.invoiceId || '-',
                "job type": el.jobType,
                // "item": el.,
                // "due date": el.,
                "total amt": el.totalAmount,
                "due amt": el.dueAmount,
                "received amt": el.amount_paid,
                // "overdue days": el.,
                // "paid amt": el.,
                "trx Id": el.transactionId,
            });
        });
        return extractedData;
    }

    updateDataOnTablePagination(rowsPerPage=this.state.rowsPerPage,nextPage=this.state.currentPage){
        this.setState({ isTableUpdating: true }, () => {
            this.getAllLedgerList(rowsPerPage, nextPage);
        });
     
    }

    getAllLedgerList = (
        rowsPerPage = this.state.rowsPerPage,
        nextPage = this.state.currentPage
    ) => {
        Axios.get(`${urlLinks.baseUrl}/transactions/payments?page_no=${nextPage}&rows=${rowsPerPage}`)
        .then(response => {
            const data = this.makeData(response.data.response.content)
            this.setState({ 
                list: data,
                count: response.data.response.totalElements,
                currentPage: nextPage,
                rowsPerPage: rowsPerPage,
                isTableUpdating: false
            });
        })
    }

    componentDidMount() {
        this.getAllLedgerList();
    }
    render() {
        const tablePagination = {
            currentPage:this.state.currentPage,
            count:this.state.count,
            changePage:this.updateDataOnTablePagination,
            rowsOptions:this.rowsOptions,
            rowsPerPage:this.state.rowsPerPage,
            tableUpdating:this.state.isTableUpdating
        }
        return (
            <div>
                <Card style={{ margin: "20px 0px", padding: "20px" }}>
                    <Grid container >
                        <Grid item sm={3}>
                            <HideUnhideColumns columns={this.state.column} toggleColumn={this.updateColumnFileds}>Columns</HideUnhideColumns>
                        </Grid>
                        {/* <Grid item sm={3}>
                            <CustomFilter isOpen={this.state.open} filters={this.state.filters} updateFilters={this.updateFilters}></CustomFilter>
                        </Grid> */}
                    </Grid>
                </Card>
                <CustomTable name={'id'} columns={this.state.column} data={this.state.list} links={financeUtils.allLedgerListAction} title={'All Ledger'} tablePagination={tablePagination}></CustomTable>
            </div>

        );
    }
}
 
export default FinanceAllLedger;