import React from 'react';
import { MdComment, MdModeEdit, MdLibraryAdd } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import utils from '../../../utilMethods';

let jobTitle = {};
let company=[];
let status=
    {
        "Going": 'GOING',
    
        "Not Going": "NOT_GOING",
   
        "Reschedule": "RESCHEDULE",
    
        "Appeared": "APPEARED",
    }
    


const interviewList = ( jobTitle, company, status) => {
  const interviewFilterList = [
    {
      job_title: Object.keys(jobTitle),
      jobTitle: { ...jobTitle },
      id: '1',
      name: 'job_title',
      typeName: 'jobTitle',
      label: 'Job Title',
      default: []
    },
    {
      companyName: Object.keys(company),
      company: { ...company },
      id: '2',
      name: 'companyName',
      typeName: 'company',
      label: 'Company Name',
      default: []
    },
    {
      status: Object.keys(status),
      Status: { ...status },
      id: '3',
      name: 'status',
      typeName: 'Status',
      label: 'Status',
      default: []
    },
    
  ];
  return interviewFilterList;
};

 const getinterviewFilterList =  (list) => {
    if(list)
    {
        list.map((item, index ) => {
        jobTitle[item.profile]=index;
        
        company[item.companyName]= index;
        // console.log({jobTitle, company});
        })
        const filterlist =  interviewList(jobTitle, company, status);  
        jobTitle = {};
        company=[];
        return filterlist;
    }
};

export default getinterviewFilterList;