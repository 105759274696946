import React, { Component } from 'react'
import NavBar from '../Navbar/Navbar'

class Home extends Component {

    componentDidMount() {
        if(!localStorage.getItem('userId')) {
          localStorage.clear();
          this.props.history.push('Login')
        }
      }

    render() {
        return (
            <div>
                {localStorage.getItem('userId') ? <NavBar /> : null}
            </div>
        )
    }
}
export default Home 