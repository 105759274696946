import React, { Component } from 'react';
import { Button, Typography, Divider, OutlinedInput } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BackButton from '../../../utils/BackButton/BackButton';
import urls from '../../../urlLinks';
import ImageUpload from '../../../utils/ImageUploader/ImageUpload';
import { CircleLoader } from 'react-spinners';
import Experience from '../experience'
import * as moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import Switch from '@material-ui/core/Switch';
import { lightGreen } from '@material-ui/core/colors';
import AvailableTimeEdit from '../../../utils/AvailableTimeComponent/AvailableTimeforEdit';
import Experince from '../../../utils/Experience/experienceForEdit';
import utilFunctions from '../../../utilMethods';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DropDownConstants from '../../../dropDownConstant';
import Axios from 'axios';
import { isSaturday } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import getworkerFilterList from './workerConstants';

const styles = theme => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: '70px'
  },
  text_container: {
    width: '80%',
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonStyle: {
    width: '90px',
    margin: 'auto',
    fontSize: '0.8rem'
  },
  input: {
    padding: '10px',
    height: '40px',
    fontSize: '0.8rem'
  },
  inputStyle: {
    padding: '0px',
    height: '40px',
    fontSize: '0.8rem'
  },
  selectInput: {
    padding: '10px',
    height: '0px',
    fontSize: '0.8rem'
  },
  inputLabel: {
    transform: 'translate(13px, 12px) scale(1)',
    fontSize: '0.8rem'
  },
  grid: {
    maxWidth: '800px',
    margin: 'auto'
  },
  textheading: {
    textDecoration: 'underline',
    fontSize: '0.9rem'
  },
  textContent: {
    fontSize: '0.8rem'
  },
  alignFormat: {
    margin: 'auto',
    fontSize: '0.8rem'
  },
  textBoxStyle: {
    height: '35px',
    marginLeft: '10px'
  }
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: lightGreen[200],
    '&$checked': {
      color: lightGreen['A700']
    },
    '&$checked + $track': {
      backgroundColor: lightGreen['A700']
    }
  },
  checked: {},
  track: {}
})(Switch);

class AddWorker extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        workerId: '',
        name: '',
        firstName:'',
        lastName: '',
        dateOfBirth: '',
        contactNumber: '',
        EValue:'',
        email:'',
        cv:'',
        gender:'',
        qualification: '',
        searchAddress:'',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        pincode: '',
        gender:'',
        addressProof: '',
        availability: [],
        Experince: [],
        jobCategory: [],
        jobProfile: [],
        profileImageName: '',
        addressProofFilename: '',
        addressProofFilename1: '',
        socialMedium: '',
        otherSource: ''
      },
      gender:["Male", "Female"],
      isSubmitting: false,
      isExperience: false,
      profileImage: '',
      profileImageName: '',
      industry: [],
      jobTypes: [],
      qualification: {},
      everyDaySelected: false,
      pincode:null,
      isValidPin:true,
      isAvailability:false
    };
    // this.isAvailability=false;
    this.englishFluency=[
      {
        id:1,
        label:'No English',
        value:1,
      },
      {
        id:2,
        label:'Thoda English',
        value:2,
      },
      {
        id:3,
        label:'Good English',
        value:3,
      }
    ];
    this.aadhaarBackData = null;
    this.aadhaarBackData = null;
    this.isaadhaarFront = false;
    this.isaadhaarBack = false;
    this.addWorkerSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
      dateOfBirth: Yup.date()
        .required('Required')
        .nullable(),
      contactNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Invalid Contact Number')
        .required('Contact Number is Required'),
      qualification: Yup.string().required('Required'),
      gender: Yup.string().required('Required'),
      addressLine1: Yup.string()
        .min(2, 'Too Short!')
        .max(150, 'Too Long!')
        .required('Required'),
      city: Yup.string()
        .trim()
        .required('Required'),
      pincode: Yup.string()
        .matches(/^[0-9]{6}$/, 'Invalid Pincode')
        .required('Required'),
      availability: Yup.array().of(
        Yup.object().shape({
          values: Yup.object().shape({
            startTime: Yup.date()
              .required('Required')
              .nullable(),
            endTime: Yup.date()
              .required('Required')
              .nullable()
          })
        })
      )

    });

    this.isaadhaarFrontChanged = false;
    this.isaadhaarBackChanged = false;
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.updateProfileImage = this.updateProfileImage.bind(this);
    this.formikSetValues = null;
    this.formikValues = {};
    this.workDaysError = false;
    this.docs = [];
    this.jobInterests = [];
    this.profileImage = '';
    this.aadhaarFront = '';
    this.aadhaarFrontName = '';
    this.aadhaarBack = '';
    this.aadhaarBackName = '';
    this.profileImageFileName = '';
    this.aadhaarFrontType = '';
    this.aadhaarBackType = '';
    this.cv = '';
  }

  updateProfileImage(image, filename) {
    this.profileImage = image;
    this.profileImageFileName = filename;
    let newformikValues = { ...this.formikValues };
    newformikValues['profilePhotoName'] = filename;
    this.formikSetValues(newformikValues);
  }

  updateAadhaarFront = (image, filename, imageType) => {
    this.aadhaarFront = image;
    this.aadhaarFrontName = filename;
    this.aadhaarFrontType = imageType;
    this.isaadhaarFrontChanged = true;
    let newformikValues = { ...this.formikValues };
    newformikValues['aadhaarFrontName'] = filename;
    this.formikSetValues(newformikValues);
  };

  updateAadhaarBack = (image, filename, imageType) => {
    this.aadhaarBack = image;
    this.aadhaarBackName = filename;
    this.aadhaarBackType = imageType;
    this.isaadhaarBackChanged = true;
    let newformikValues = { ...this.formikValues };
    newformikValues['aadhaarBackName'] = filename;
    this.formikSetValues(newformikValues);
  };

  handlePincodeChange =(event) =>{
    let pincode=event.target.value;
    this.setFormikFieldValue("pincode", pincode)
    if(pincode.length===6){
      this.setState({pincode: pincode}, () => this.checkPincode(pincode))
    }
  }

  checkPincode =(pincode) =>{
    if(pincode.toString().length===0 || pincode===0){
      this.setState({isValidPin: false})
    }
    else{
      Axios.get(`${urls.baseUrl}tracking/getcityandstate?pincode=${pincode}`)
        .then(response =>{
          if(response.data.response.length!==0){
            this.setState({isValidPin:true})
            let addressDetails = {
              pincode: pincode,
              city:response.data.response[0].district, 
              state:response.data.response[0].stateName
            };
            let newformikValues = Object.assign( {}, this.formikValues, addressDetails);
            this.formikSetValues(newformikValues);
          }
          else{
            this.setState({isValidPin:false})
          }
        })
        .catch(error =>{
          this.setState({isValidPin:false})
          // toast.error("Unable to retrieve city and state.")
        })
    }
  }

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    const geometry = addressObject.geometry.location;
    this.lat = geometry.lat();
    this.lang = geometry.lng();
    let pincode= null;
    addressObject.address_components.map(component =>{
        if(component.types.includes("postal_code")){
          pincode=component.long_name;
        }
      })
    let addressDetails = {
      searchAddress: addressObject.formatted_address,
      pincode: pincode,
    };
    let newformikValues = Object.assign( {}, this.formikValues, addressDetails);
    this.formikSetValues(newformikValues);
    this.setState({pincode: pincode}, () => this.checkPincode(pincode))
    
  }


  redirectToWorkerList = () => {
    this.props.history.push('/worker/workerList');
  };

  toDateWithOutTimeZone = (date) => {
    let tempTime = date.split(":");
    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    dt.setSeconds(tempTime[2]);
    return dt;
  }

  handleChange1=(event)=>{
    console.log(event)
    console.log(event.target.value)
    this.setState({
      isAvailability:!this.state.isAvailability
    })
  }

  getWorkerList = id => {
    Axios.get(`${urls.baseUrl}/workermaster/workerByUserIdWithEid?user_id=${id}`).then(
      response => {
        console.log(response);
        let userDocs = [];
        Axios.get(`${urls.baseUrl}/userdocs/?user_id=${id}`).then(response2 => {
          const data = response.data.response.content[0];
          console.log("THE DATA")
          console.log(data);
          userDocs = response2.data.response.content;
          console.log("DOCUMENT")
            console.log(userDocs);
            let availabilities = [];
            // //data.workerAvailabilities.map(el => availabilities.push({
            //   startTime: el.loginTime,
            //   endTime: el.logoutTime
            // }))
            // this.setState({
            //   isAvailability:data.globalAvailability
            // })
            // this.isAvailability=data.globalAvailability?true:false;
            let exps = [];
            let jobProfile = [];
            this.profileImage = data?data.profilePhoto:null;
            if (userDocs) {
              const docs = [];
              userDocs.map(el => {
                if (el.document_type_id === 17) {
                  docs.push({
                    documentId: el.document_id,
                    b64EncodedDocument: el.file_path,
                    documentName: el.document_type || 'Aadhaar Front',
                    documentTypeId: 17,
                    fileMimeType: el.file_mime_type
                  });
                  this.isaadhaarFront = true;
                  this.aadhaarFrontData = {
                    documentId: el.document_id,
                    b64EncodedDocument: el.file_path,
                    documentName: el.document_type || 'Aadhaar Back',
                    documentTypeId: 17,
                    fileMimeType: el.file_mime_type
                  };
                  this.aadhaarFront = el.file_path;
                } else if (el.document_type_id === 18) {
                  docs.push({
                    documentId: el.document_id,
                    b64EncodedDocument: el.file_path,
                  documentName: el.document_type || 'Aadhaar Front',
                  documentTypeId: 18,
                  fileMimeType: el.file_mime_type
                });
                this.aadhaarBack = el.file_path;
                this.isaadhaarBack = true;
                this.aadhaarBackData = {
                  documentId: el.document_id,
                  b64EncodedDocument: el.file_path,
                  documentName: el.document_type || 'Aadhaar Back',
                  documentTypeId: 18,
                  fileMimeType: el.file_mime_type
                };
              }
              return null;
            });
            this.docs = [...docs];
          }
          console.log(this.state.industry);
          let typeArray=[];
          const experienceNew = [];
          exps = response.data.response.content[0]?
            response.data.response.content[0].workerExperiences?
              response.data.response.content[0].workerExperiences.map(exp =>{
                experienceNew.push({
                  expId: exp.experienceId,
                  industry: exp.industryType,
                  company: exp.companyName,
                  jobProfile: exp.jobType,
                  startDate: exp.fromDate,
                  endDate: exp. toDate,
                })
                const industryObj= this.state.industry.find(x=> x.id===exp.industryType)
                console.log({industryObj});
                
                typeArray.push((industryObj && industryObj.typeValue)?industryObj.typeValue:"0")

              }):[]:[];
            
            
          availabilities = response.data.response.content[0]?response.data.response.content[0]
            .workerAvailabilities
            ? [...response.data.response.content[0].workerAvailabilities]
            : []:[];
          this.cv=response.data.response.content[0]?response.data.response.content[0].cv_link:'';  
          
          jobProfile = response.data.response.content[0]?response.data.response.content[0].jobInterests
            ? [...response.data.response.content[0].jobInterests]
            : []:[];
          let isTrue = false;
          if (exps.length > 0) {
            isTrue = true;
          }

          
          const availabilityNew = [];
          const daysToIDMap = {
            1: 'Sunday',
            2: 'Monday',
            3: 'Tuesday',
            4: 'Wednesday',
            5: 'Thursday',
            6: 'Friday',
            7: 'Saturday'
          }
          
          if(availabilities.length < 1) {
            availabilityNew.push({
              id: 1,
              isVisible: true,
              day: null,
              startTime: null,
              endTime: null,
              values: {
                day: null,
                startTime: null,
                endTime: null,
              }
            })
          }

          availabilities.map((avail, availIndex) => {
            const availabilityDays = {};
            Object.keys(JSON.parse(avail.workdaysDetails)).map(availDay=>{
              availabilityDays[daysToIDMap[availDay]] = JSON.parse(avail.workdaysDetails)[availDay];
            })
            // console.log(availabilityDays)
            const startTime = this.toDateWithOutTimeZone(avail.loginTime);
            const endTime = this.toDateWithOutTimeZone(avail.logoutTime);
            availabilityNew.push({
              availId: avail.availablityId,
              id: availIndex+1,
              isVisible: true,
              day: availabilityDays,
              startTime: startTime,
              endTime: endTime,
              values: {
                day: availabilityDays,
                startTime: startTime,
                endTime: endTime,
              }
            })
          })
          const abvcccc = availabilities.map((el, indexForAvail) => {
            return {...el, ...availabilityNew[indexForAvail]}
          })

          console.log({typeArray})
          // console.log(availabilities)
          this.setState({
            formData: {
              ...this.state.formData,
              Experince: [...experienceNew],
              availability: [...availabilityNew],
              jobProfile: data.jobInterests.map(el => el.jobTypeName)
            },
            actualExp: experienceNew,
            isExperience: isTrue,
            typeArray: typeArray,
            workerId: response.data.response.content[0].workerId,
            profileImage: data.profilePhoto,
            isAvailability:response.data.response.content[0].globalAvailability===1?true:false
           
          }, () => console.log("formdata setState"));
          // this.formikSetValues({...this.formikValues, ...this.state.availability})
          // console.log(this.state.formData);
          // console.log('getWorker  here');
          // console.log(this.state.formData.availability);

          // console.log(data);
          // this.formikSetValues({...this.formikValues, ...abvcccc})
          let jobprofileArr = [];
          const jobInterests = [];
          data.jobInterests.map(el =>
            jobInterests.push({
              jobInterestId: el.jobInterestId,
              jobType: el.jobTypeName
            })
          );
          this.jobInterests = jobInterests;
          data.jobInterests.map(el => jobprofileArr.push(el.jobTypeName));
          // console.log(jobprofileArr);
          //  this.profileImage=data.profilePhoto;
          const formikValues = {
            name: `${data.firstName} ${data.lastName || ''}`,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: parseInt(data.gender)===26?"Male":"Female",
            dateOfBirth: new Date(
              data.dateOfBirth
                .split('-')
                .reverse()
                .join('-')
            ),
            contactNumber: data.phoneNumber || data.paytmNumber || null,
            email:data.emailId,
            EValue:data.english_known_level,
            cv:data.cvLink,
            qualification: data.qualificationName,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.newCity ,
            state: data.newState,
            searchAddress: data.userGoogleLocation ? data.userGoogleLocation:'',
            pincode: data.pincode,
            addressProof: '',
            availability: [...availabilityNew],
            Experince: [...experienceNew],
            jobCategory: [],
            jobProfile: jobprofileArr,
            profileImageName: data.profilePhoto,
            addressProofFilename: '',
            addressProofFilename1: '',
            socialMedium: '',
            otherSource: ''
          };
          this.formikSetValues(formikValues);
          this.setState({pincode: data.pincode}, () => this.checkPincode(data.pincode))
          console.log({formikValues});
        });
      }
    );
  };

  setAvailabilityValues = (data) => {
    const availability = [];
    availability.push(data)
    // console.log({...this.formikValues, ...availability})
  }

  componentDidMount() {
    this._isMounted = true;
    const id = this.props.location.id;
    if (this.props.location.id) {
      this.getWorkerList(id);
    } 
    else {
      this.redirectToWorkerList();
    }
    Axios.all([
      Axios.get(`${urls.baseUrl}/configmaster/?category_sub_type=industry&inserted_by=0&rows=20&status=1`),
      Axios.get(`${urls.baseUrl}/configmaster/?category_type=job_type&inserted_by=0&rows=100&type_desc=job_type_name`)
    ]).then(Axios.spread((response, response2) => {
      this.setState({ industry: response.data.response.content,  jobTypes: response2.data.response.content}, () => console.log("industry Log"))
    }));
    const industries = [];
    const jobTypes = [];
    Axios.get(
      `${urls.baseUrl}/configmaster/entityList`
    ).then(response => {
      const qualification = {};
      for (let [key, value] of Object.entries(
        response.data.response.content.qualification_type
      )) {
        qualification[value] = key;
      }
      this.setState({ qualification: qualification });
    });
    this.autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('google-autocomplete-input-worker', {})
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // render() {
  //   return <div>Coming Soon</div>
  // }

  render() {
    const { classes } = this.props;
    const qualifications = Object.keys(this.state.qualification);
    return (
      <div className={classes.root}>
        {this.state.isSubmitting ?
        (
          <CircleLoader
            css={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto'
            }}
            color={'#194D33'}
          ></CircleLoader>
        )
        :
        (
        <>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={4000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <BackButton />
        <Formik
          enableReinitialize
          initialValues={{ ...this.state.formData }}
          validationSchema={this.addWorkerSchema}
          onSubmit={values => {
            this.setState({isSubmitting: true})
            // let cityId = '';
            // for (let value of DropDownConstants.cities) {
            //   if (
            //     value.city.toLowerCase().indexOf(values.city.toLowerCase()) !==
            //     -1
            //   ) {
            //     cityId = value.id;
            //     break;
            //   }
            // }
            // console.log(values);

            const availabilitiesArr = values['availability'].filter(
              (value, index) => {
                return 'availablityId' in value;
              }
            );
            // console.log(availabilitiesArr);
            const values2 = values['availability'].filter((values, index) => {
              return values['isVisible'] !== false;
            });
            const avalArr = values['availability'].filter((value, index) => {
              if ('availId' in value && value['isVisible'] === false) {
                return true;
              }
            });
            const deleteAvail = avalArr.map(el => el.availId);
            // console.log(deleteAvail);

            const experienceArr = values['Experince'].filter((value, index) => {
              return 'experienceId' in value;
            });
            const values3 = values['Experince'].filter((values) => {
              return values['expId'] ;
            });

            
            console.log({values3})
            const expIdList =[]
            this.state.actualExp.filter(({expId: id1})=> {if(!values3.some(({expId: id2}) => id1===id2)) expIdList.push(id1)}) ;
            
            console.log({expIdList})
            // console.log(deleteExp);
            var finalAvailability = [];
            if (availabilitiesArr.length === 0) {
              const availability1 = values2.map(el =>
                el.values ? el.values['startTime'] : null
              );
              const availability2 = values2.map(el =>
                el.values ? el.values['endTime'] : null
              );
              let availability3 = values2.map(el =>
                el.values ? el.values['day'] : null
              );
              const availability5 = values2.map(el => {
                // console.log(el);
                if (el.availId) {
                  return el.availId;
                } else {
                  return 0;
                }
              });
              // console.log(availability5);
              // console.log(availability1);
              // console.log(availability2);
              // console.log(availability3);
              const daysMap = ['2', '3', '4', '5', '6', '7', '1'];
              const availability4 = [];
              const allEqualArr = [];
              availability3.map((el, id) => {
                let daystoMap = Object.values(el);
                // console.log(id,daystoMap);
                const eldays = {};
                for (var i = 0, len = daysMap.length; i < len; i++) {
                  eldays[daysMap[i]] = daystoMap[i];
                  // console.log(eldays);
                }
                const arr = Object.values(availability3[id]);
                const first = Object.values(availability3[id])[0];
                const allEqual = arr.every(v => v === false);
                // console.log(Object.values(availability3[id]));
                // console.log(allEqual);
                if (allEqual) {
                  allEqualArr.push(false);
                  // this.workDaysError = true;
                } else {
                  allEqualArr.push(true);
                  // this.workDaysError = false;
                }
                availability4.push(eldays);
              });
              const allEqual = allEqualArr.every(v => v === true);
              // console.log(allEqual)
              this.workDaysError = allEqual;

              availability3 = [...availability4];

              var StartTime = [],
                j = 0;
              for (let i = 0; i < availability1.length; i++) {
                StartTime[j] = availability1[i] ? availability1[i].toTimeString().substring(0, 8) : null;
                j++;
              }
              var EndTime = [],
                k = 0;
              for (let i = 0; i < availability2.length; i++) {
                EndTime[k] = availability1[i] ? availability2[i].toTimeString().substring(0, 8) : null;
                k++;
              }

              // console.log(availability3);

              for (let i = 0; i < StartTime.length; i++) {
                var a = {};
                a.active_status = 1;
                a.availabilityType = 0;
                a.loginTime = StartTime[i];
                a.logoutTime = EndTime[i];
                if (availability5[i]) {
                  a.availabilityId = availability5[i];
                }
                a.workdaysDetails = JSON.stringify(availability3[i]);
                finalAvailability[i] = a;
              }
            } else {
              availabilitiesArr.map(el => {
                finalAvailability.push({
                  availabilityId: el.availablityId,
                  availabilityType: el.availabilityType,
                  loginTime: el.loginTime,
                  logoutTime: el.logoutTime,
                  workdaysDetails: el.workdaysDetails,
                  active_status: el.active_status
                });
              });
            }
            // console.log(availability4);

            // const daysToMap = Object.values(availability3[0]);
            // console.log(daysToMap);
            // const availability4 = {};
            // for (var i = 0, len = daysMap.length; i < len; i++) {
            //   availability4[daysMap[i]] = daysToMap[i];
            // }

            //console.log(finalAvailability);

            function convertDate(inputFormat) {
              function pad(s) {
                return s < 10 ? '0' + s : s;
              }
              var d = new Date(inputFormat);
              return [
                pad(d.getDate()),
                pad(d.getMonth() + 1),
                d.getFullYear()
              ].join('/');
            }
            // console.log(values3);

            var finalExperince = [];
            values3.map(exp =>{
              finalExperince.push({
                toDate: exp.endDate?moment(exp.endDate).format("YYYY-MM-DD"): null,
                fromDate: moment(exp.startDate).format("YYYY-MM-DD"),
                companyName: exp.company,
                industryType: exp.industry,
                jobType: exp.jobProfile
              })
            })

            const jobProfiles = [];
            // console.log(this.state.jobTypes);
            // values.jobProfile.map(el => {
            //   jobProfiles.push({
            //     jobType: this.state.jobTypes[el]
            //   });
            // });

            var document = [];
            var c = {};
            var c2 = {};
            // c.file_mime_typ1=values.addressProofFilename1;
            // c.file_path1=this.addressProofBlo1;
            // document_id

            if (this.isaadhaarFrontChanged && this.isaadhaarFront) {
              document.push({
                documentId: this.aadhaarFrontData.documentId,
                b64EncodedDocument: this.aadhaarFront,
                documentName: this.aadhaarFrontName,
                documentTypeId: 17,
                fileMimeType: this.aadhaarFrontType
              });
            } else if (this.isaadhaarFrontChanged) {
              document.push({
                b64EncodedDocument: this.aadhaarFront,
                documentName: this.aadhaarFrontName,
                documentTypeId: 17,
                fileMimeType: this.aadhaarFrontType
              });
            } else if (this.isaadhaarFront) {
              document.push(this.aadhaarFrontData);
            }

            if (this.isaadhaarBackChanged && this.isaadhaarBack) {
              document.push({
                documentId: this.aadhaarBackData.documentId,
                b64EncodedDocument: this.aadhaarBack,
                documentName: this.aadhaarBackName,
                documentTypeId: 18,
                fileMimeType: this.aadhaarBackType
              });
            } else if (this.isaadhaarBackChanged) {
              document.push({
                b64EncodedDocument: this.aadhaarBack,
                documentName: this.aadhaarBackName,
                documentTypeId: 18,
                fileMimeType: this.aadhaarBackType
              });
            } else if (this.isaadhaarBack) {
              document.push(this.aadhaarBackData);
            }

            // c.file_mime_typ = values.addressProofFilename;
            // c.file_path = this.addressProofBlob;
            // document[0] = c;

            // c2.file_mime_typ = values.addressProofFilename1;
            // c2.file_path = this.addressProofBlob1;
            // document[1] = c2;

            // console.log(values.jobProfile);
            const finalJobInterests = [];
            values.jobProfile.map(el => {
              this.jobInterests.map(el2 => {
                if (el === el2.jobType) {
                  jobProfiles.push({
                    jobInterestId: el2.jobInterestId,
                    jobType: this.state.jobTypes[el2.jobType]
                  });
                }
              });
              const jobInsides = this.jobInterests.map(el => el.jobType);
              if (!jobInsides.includes(el)) {
                jobProfiles.push({
                  jobType: this.state.jobTypes[el]
                });
              }
            });
            // console.log(this.jobProfiles);

            var DateOfBirth = convertDate(values.dateOfBirth)
              .split('/')
              .reverse()
              .join('-');
              
            let cityId = DropDownConstants.states[values.state] || -1;
            // cityId = cityId ? cityId : 71;
            var updatedValues = Object.assign({});
            updatedValues.addressLine1 = values.addressLine1;
            updatedValues.addressLine2 = values.addressLine2;
            updatedValues.availableFt = 1;
            updatedValues.availableOd = 0;
            updatedValues.availablePt = 1;
            updatedValues.cityId = cityId;
            updatedValues.dateOfBirth = DateOfBirth;
            updatedValues.emailId = '';
            updatedValues.firstName = values.firstName;
            updatedValues.globalAvailability = 0;
            updatedValues.hasExperience = 1;
            updatedValues.userstatus = 1;
            updatedValues.lastName = values.lastName;
            updatedValues.gender = values.gender==="Male"?26:27;
            updatedValues.jobInterests = jobProfiles;
            updatedValues.requestedBy = localStorage.getItem('userId');
            updatedValues.paytmNumber = values.contactNumber.toString();
            updatedValues.phoneNumber = values.contactNumber.toString();
            updatedValues.pincode = values.pincode;
            updatedValues.userGoogleLocation=values.searchAddress;
            updatedValues.city = values.city;
            updatedValues.state = values.state;
            updatedValues.english_known_level=values.EValue;
            updatedValues.emailId=values.email;
            updatedValues.cvLink=this.cv;
            updatedValues.cvName=values.cv;

            if(this.profileImageFileName) {
              updatedValues.profilePhoto = this.profileImage;
              updatedValues.profilePhotoName = this.profileImageFileName;
            } else {
              updatedValues.profilePhoto = '';
              updatedValues.profilePhotoName = '';
            }
            updatedValues.qualificationId = +this.state.qualification[
              values.qualification
            ];
            updatedValues.spokenLanguage = 'hinglish';
            updatedValues.countryCode = '91';
            updatedValues.userDocuments = document;
            console.log("@@@@@@@@@");
            console.log(document);
            console.log(updatedValues.userDocuments)
            //updatedValues.jobInterests = jobProfiles;

            updatedValues.workerAvailabilities = finalAvailability;
            updatedValues.workerExperiences = values['Experince'];

            console.log("****");
            console.log(updatedValues);
            
            if (this.workDaysError) {
              if (deleteAvail.length > 0) {
                deleteAvail.map(id => {
                  Axios.delete(
                    `${urls.baseUrl}/workermaster/availability?availablity_id=${id}`
                  )
                    .catch(err => toast.error(`Error Deleting Availability`));
                });
              }
              Axios.delete(`${urls.baseUrl}/workermaster/experience?experience_id=${expIdList}`)
                .catch(err => toast.error(`Error Deleteing Experience`))
              // console.log(
              //   `${urls.baseUrl}/admin/addWorker/${this.state.workerId}`
              // );
              Axios.post(
                `${urls.baseUrl}/admin/addWorker/${this.state.workerId}`,
                updatedValues
              )
                .then(response => {
                  console.log(updatedValues);
                  // this.props.history.push('/worker/workerList');
                  this.setState({isSubmitting: false})
                  toast.success('Worker Edited Successfully');
                  this.props.history.push('/worker/workerList');
                  // setTimeout(window.location.reload(), 4000);
                })
                .catch(err => {
                  toast.error(err)
                  this.setState({isSubmitting: false})
                  setTimeout(window.location.reload(), 4000);
                });
            } else {
              this.setState({isSubmitting: false})
              toast.error(`Available Days Can't Be Unchecked`);
              // setTimeout(window.location.reload(), 4000);
            }
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              setFieldTouched,
              setValues,
              cv
            } = props;
            this.formikValues = values;
            this.formikSetValues = setValues;
            this.setFormikFieldValue=setFieldValue
            return (
              <>
                {this.state.profileImage ? (
                  <ImageUpload
                    updateProfileImage={this.updateProfileImage}
                    imageFileLink={
                      this.state.profileImage ? this.state.profileImage : ''
                    }
                  ></ImageUpload>
                ) : null}
                {!this.state.profileImage ? (
                  <ImageUpload
                    updateProfileImage={this.updateProfileImage}
                  ></ImageUpload>
                ) : null}
                <Form
                  className={classes.form}
                  // onSubmit={(event, values) => {
                  //   event.preventDefault();
                  //   console.log(this.formikValues)

                  // }}
                >
                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.textheading}>
                        Worker Details
                      </Typography>
                    </Grid>

                     <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.firstName && touched.firstName}
                          label='First Name'
                          name='firstName'
                          value={values.firstName}
                          onChange={handleChange}
                          helperText={
                            errors.firstName && touched.firstName && errors.firstName
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          // error={errors.name && touched.name}
                          label='Last Name'
                          name='lastName'
                          value={values.lastName}
                          onChange={handleChange}
                          // helperText={
                          //   errors.name && touched.name && errors.name
                          // }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          select
                          error={errors.gender && touched.gender}
                          label='Gender'
                          name='gender'
                          value={values.gender}
                          onChange={handleChange}
                          helperText={
                            errors.gender &&
                            touched.gender &&
                            errors.gender
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        >
                          {this.state.gender.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          type='number'
                          error={errors.contactNumber && touched.contactNumber}
                          label='Contact Number'
                          name='contactNumber'
                          value={values.contactNumber}
                          onChange={handleChange}
                          helperText={
                            errors.contactNumber &&
                            touched.contactNumber &&
                            errors.contactNumber
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        error={errors.email && touched.email}
                        label='Email Id'
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          select
                          error={errors.qualification && touched.qualification}
                          label='English Fluency'
                          name='EValue'
                          value={values.EValue}
                          onChange={handleChange}
                          helperText={
                            errors.qualification &&
                            touched.qualification &&
                            errors.qualification
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        >
                          {this.englishFluency.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>  



                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            maxDate={new Date()}
                            autoOk
                            InputProps={{
                              className: classes.input
                            }}
                            helperText={
                              errors.dateOfBirth &&
                              touched.dateOfBirth &&
                              errors.dateOfBirth
                            }
                            error={errors.dateOfBirth && touched.dateOfBirth}
                            variant='inline'
                            inputVariant='outlined'
                            label='Date Of Birth'
                            format='dd/MM/yyyy'
                            value={values.dateOfBirth}
                            InputAdornmentProps={{ position: 'start' }}
                            onChange={date => {
                              setFieldValue('dateOfBirth', date);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          select
                          error={errors.qualification && touched.qualification}
                          label='Qualification'
                          name='qualification'
                          value={values.qualification}
                          onChange={handleChange}
                          helperText={
                            errors.qualification &&
                            touched.qualification &&
                            errors.qualification
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        >
                          {qualifications.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <TextField
                        className={classes.text_container}
                        id='google-autocomplete-input-worker'
                        variant='outlined'
                        label='Search Google Maps'
                        name='searchAddress'
                        value={values.searchAddress}
                        onChange={handleChange}
                        onKeyPress={event =>
                          event.key === 'Enter' ? event.preventDefault() : null
                        }
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.addressLine1 && touched.addressLine1}
                          onChange={handleChange}
                          label='Exact address'
                          name='addressLine1'
                          value={values.addressLine1}
                          helperText={
                            errors.addressLine1 &&
                            touched.addressLine1 &&
                            errors.addressLine1
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.addressLine2 && touched.addressLine2}
                          label='Landmark'
                          name='addressLine2'
                          value={values.addressLine2}
                          onChange={handleChange}
                          helperText={
                            errors.addressLine2 &&
                            touched.addressLine2 &&
                            errors.addressLine2
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          type='number'
                          error={(errors.pincode && touched.pincode)|| !this.state.isValidPin}
                          label='Pincode'
                          name='pincode'
                          disabled={this.state.isValidPin}
                          value={values.pincode}
                          onChange={event => this.handlePincodeChange(event)}
                          helperText={
                            (errors.pincode && touched.pincode) || (!this.state.isValidPin && "Please enter a valid pin number.")
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.city && touched.city}
                          label='City'
                          name='city'
                          disabled
                          value={values.city}
                          helperText={
                            errors.city && touched.city && errors.city
                          }
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          error={errors.state && touched.state}
                          label='State'
                          name='state'
                          disabled
                          value={values.state}
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.textheading}>
                        Availability
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      Mein sab time free hun
                      <PurpleSwitch
                        checked={this.state.isAvailability}
                        onChange={this.handleChange1}
                      />
                      
                      {  
                      this.state.formData.availability.length > 0 ? 
                     
                        (
                          <FieldArray
                            name='availability'
                            render={arrayHelpers => (
                              <AvailableTimeEdit
                                setAvailabilityValues={this.setAvailabilityValues}
                                setFieldValue={arrayHelpers.form.setFieldValue}
                                errors={arrayHelpers.form.errors['availability']}
                                touched={
                                  arrayHelpers.form.touched['availability']
                                }
                                name='availability'
                                data={this.state.formData.availability}
                                count={this.state.formData.availability.length}
                              />
                            )}>
                          </FieldArray>
                        ): null
                      }
                      
                      </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.textheading}>
                        Experience
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={12}>

                          Object.keys(this.state.jobTypes).length > 0 &&
                          Object.keys(this.state.industry).length > 0 ? (
                            <FieldArray
                              name='Experince'
                              render={arrayHelpers => (
                                <Experince
                                  setFieldValue={
                                    arrayHelpers.form.setFieldValue
                                  }
                                  errors={arrayHelpers.form.errors['Experince']}
                                  touched={arrayHelpers.form.touched}
                                  industry={Object.keys(this.state.industry)}
                                  jobTypes={Object.keys(this.state.jobTypes)}
                                  name='Experince'
                                />
                              )}
                            ></FieldArray>

                      </Grid> */}
                      <Grid item xs={12} sm={12}>
                      Does The Worker Have Some Previous Experience
                      <PurpleSwitch
                        onChange={() => {
                          this.setState({
                            isExperience: !this.state.isExperience
                          });
                        }}
                      />
                      {this.state.isExperience ? (
                        this.state.jobTypes.length > 0 &&
                        this.state.industry.length > 0 ? (
                          <Experience
                              values={values}
                              setFieldValue={setFieldValue}
                              handleChange={handleChange}
                              industry={this.state.industry}
                              jobRoles= {this.state.jobTypes}
                              typeArray={this.state.typeArray}
                            />
                        ) : null
                      ) : null}
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.textheading}>
                        Interested In
                      </Typography>
                    </Grid>

                    {/* <Grid item sm={6} xs={12}>
                      <Autocomplete
                        multiple
                        name='jobCategory'
                        onChange={(event, value) => {
                          setFieldValue('jobCategory', value);
                        }}
                        options={Object.keys(this.state.industry)}
                        getOptionLabel={option => option}
                        defaultValue={values.jobCategory}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              variant='standard'
                              error={Boolean(
                                errors.jobCategory && touched.jobCategory
                              )}
                              style={{ width: '200px' }}
                              label={'Job Category'}
                            />
                          );
                        }}
                      />
                    </Grid> */}
                    <Grid item sm={6} xs={12}>
                      {/* {console.log(values.jobProfile)} */}
                      <Autocomplete
                        multiple
                        name='jobProfile'
                        onChange={(event, value) => {
                          setFieldValue('jobProfile', value);
                        }}
                        options={Object.keys(this.state.jobTypes)}
                        getOptionLabel={option => option}
                        value={values.jobProfile}
                        defaultValue={values.jobProfile}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              variant='standard'
                              error={Boolean(
                                errors.jobProfile && touched.jobProfile
                              )}
                              style={{ width: '200px' }}
                              label={'Job Profile'}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                     <Grid item xs={12} sm={12}>
                    {/*  <Typography variant='h6' className={classes.textheading}>
                        Aadhaar Card
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      style={{ padding: '8px', marginBottom: '15px' }}
                    >
                      <Grid item xs={2}>
                        FRONT
                      </Grid>
                      <Grid item xs={4}>
                        <OutlinedInput
                          name='addressProofFileName'
                          value={values.aadhaarFrontName}
                          error={
                            errors.aadhaarFrontName && touched.aadhaarFrontName
                          }
                          className={classes.textBoxStyle}
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={3} style={{ marginTop: '0px' }}>
                        <ImageUpload
                          display={true}
                          updateProfileImage={this.updateAadhaarFront}
                          imageFileLink={this.aadhaarFront}
                        ></ImageUpload>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        
                          // style={{ fontSize: '0.8rem' }}
                          // variant='contained'
                          // color='primary'
                          // disabled={this.aadhaarFront ? false : true}
                          // onClick={() => {
                          //   utilFunctions.downLoadFile(
                          //     this.aadhaarFront,
                          //     'aadhaarFront'
                          //   );
                          // }}
                        <a
                        href={this.aadhaarFront}
                        color="transparent"
                        target="_blank"
                        >
                        Download
                        </a>
                        
                        
                      </Grid>

                      {/* <Grid item xs={3}></Grid> */}

                      {/* <Grid item xs={2} style={{ paddingTop: '10px' }}>
                        {' '}
                        BACK{' '}
                      </Grid>
                      <Grid item xs={4} style={{ paddingTop: '10px' }}>
                        <OutlinedInput
                          name='addressProofFileName'
                          value={values.aadhaarBackName}
                          error={
                            errors.aadhaarBackName && touched.aadhaarBackName
                          }
                          className={classes.textBoxStyle}
                          readOnly
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ paddingTop: '10px', marginTop: '0px' }}
                      >
                        <ImageUpload
                          display={true}
                          updateProfileImage={this.updateAadhaarBack}
                          imageFileLink={this.aadhaarBack}
                        ></ImageUpload>
                      </Grid>
                      <Grid item xs={3} sm={3} style={{ paddingTop: '10px' }}>
                          style={{ fontSize: '0.8rem' }}
                          variant='contained'
                          color='primary'
                          disabled={this.aadhaarBack ? false : true}
                          onClick={() => {
                            utilFunctions.downLoadFile(
                              this.aadhaarBack,
                              'aadhaarBack'
                            );
                          }}
                        >
                        <a
                        href={this.aadhaarBack}
                        color="transparent"
                        target="_blank"
                        >
                        Download
                        </a> 
                      </Grid>
                    </Grid>
                    <Grid item xs={2}> */} 

                    <Typography style={{ fontSize: '0.9rem' }}>
                      CV:
                    </Typography>
                   </Grid>

                    <Grid item xs={2} style={{ paddingRight:'-50px' }} >
                    <OutlinedInput
                      name='pancardFileName'
                      value={values.cv}
                      error={errors.pancardFileName && touched.pancardFileName}
                      className={classes.textBoxStyle}
                      readOnly
                    />
                  </Grid>
                  {/* <Grid item xs={2}></Grid> */}
                  <Grid item xs={3} style={{ marginLeft:'140px' }}>
                    <Button
                      variant='contained'
                      component='label'
                      className={classes.alignFormat}
                    >
                      Browse
                      <input
                        type='file'
                        accept='application/pdf'
                        style={{ display: 'none' }}
                        onChange={event => {
                          const reader = new FileReader();
                          const file = event.target.files[0];
                          reader.readAsDataURL(file);
                          reader.onload = () => {
                            this.cv = reader.result;
                            setFieldValue('cv', file.name);
                            setFieldTouched('cv', true, false);
                          };
                        }}
                      />
                    </Button>
                  </Grid>
       
                  <Grid item xs={2}>
                
                      {/* // variant='contained'
                      // color='primary'
                      // disabled={this.cv ? false : true}
                      // onClick={() => {
                      //   utilFunctions.downLoadFile(
                      //     this.cv,
                      //     'cv'
                      //   );
                      // }} */}
                      <Button
                      href={this.cv}
                      variant="contained" color="primary"
                      Download>
                    Download
                     </Button>
                    
                      
                  
                  </Grid>
                     <Grid item sm={12}>
                      <Divider />
                    </Grid>

                      <Button
                        type='submit'
                        variant='contained'
                        className={classes.buttonStyle}
                        color='primary'
                      >
                        Submit
                      </Button>
                      {/* <Button
                        onClick={this.sendData}
                        variant='contained'
                        className={classes.buttonStyle}
                        color='primary'
                      >
                        sEND dATA
                      </Button> */}
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
        </>
      )
      }
      </div>
    );
  }
}

export default withStyles(styles)(AddWorker);
