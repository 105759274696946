import React, { Component } from 'react';
import { ExcelRenderer } from "react-excel-renderer";
import { Card, Grid } from '@material-ui/core';
import Axios from 'axios';
import urlLinks from '../../urlLinks';
import { ToastContainer, toast } from 'react-toastify';
import {
    Button
} from '@material-ui/core';

import WorkerLedgerV2 from './WorkerLedgerV2';

class FinanceAllLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // list: [],
            // records_uploaded: 0,
            // wrongRecordsList: [],
            // filters: financeUtils.allLedgerFilterList,
            // currentPage: 0,
            // count: 0,
            // open: false,
            // rowsPerPage: 10,
            // isTableUpdating: false,
            // column: [
            //     {
            //     id: "1",
            //     name: "category",
            //     label: "Category",
            //     isColumnVisible: true
            // }, 
            // {
            //     id: "2",
            //     name: "employee id",
            //     label: "Worker ID",
            //     isColumnVisible: true
            // },
            // {
            //     id: "3",
            //     name: "date",
            //     label: "Date",
            //     isColumnVisible: true
            // },
            // {
            //     id: "4",
            //     name: "invoice id",
            //     label: "Invoice ID",
            //     isColumnVisible: true
            // }
            //     , 
            //     {
            //     id: "5",
            //     name: "job type",
            //     label: "Job Type",
            //     isColumnVisible: true
            // },
            // {
            //     id: "6",
            //     name: "item",
            //     label: "item",
            //     isColumnVisible: true

            // },
            // {
            //     id: "7",
            //     name: "due date",
            //     label: "Due Date",
            //     isColumnVisible: true
            // }, 
            // {
            //     id: "8",
            //     name: "total amt",
            //     label: "Total Amt",
            //     isColumnVisible: true
            // }, 
            // {
            //     id: "9",
            //     name: "due amt",
            //     label: "Due Amt",
            //     isColumnVisible: true
            // },
            // {
            //     id: "10",
            //     name: "received amt",
            //     label: "Received Amt",
            //     isColumnVisible: true
            // },
            // {
            //     id: "11",
            //     name: "overdue days",
            //     label: "Overdue Days",
            //     isColumnVisible: true
            // },
            // {
            //     id: "12",
            //     name: "paid amt",
            //     label: "Paid",
            //     isColumnVisible: true
            // },
            // {
            //     id: "12",
            //     name: "paidOn",
            //     label: "Paid On",
            //     isColumnVisible: true
            // },
            // {
            //     id: "13",
            //     name: "trx Id",
            //     label: "Trx ID",
            //     isColumnVisible: true
            // },
            // {
            //     id: "14",
            //     name: "action",
            //     label: "Action",
            //     isColumnVisible: false
            // }
            // ]
        }
        // this.data = [];

        // this.rowsOptions = [5, 10, 25, 50, 100];
        // this.updateColumnFileds = this.updateColumnFileds.bind(this);
        // this.updateFilters = this.updateFilters.bind(this);
        // this.updateDataOnTablePagination = this.updateDataOnTablePagination.bind(this);

    }
    // handleExcel = (event) => {
    //     var fileObj = event.target.files[0];
    //     if (event.target.files[0]) {
    //         ExcelRenderer(fileObj, (err, resp) => {
    //             if (err) {
    //                 console.log(err);
    //             }
    //             else {
    //                 // console.log({ resp })
    //                 const formData = new FormData();
    //                 formData.append('file', fileObj)
    //                 Axios.post(`${urlLinks.baseUrl}/workermaster/uploadInvoice`, formData, {
    //                     headers: {
    //                         'content-type': 'multipart/form-data'
    //                     }
    //                 })
    //                     .then(response => {
    //                         // console.log(response)
    //                         this.setState({
    //                             records_uploaded: response.data.response.records_uploaded,
    //                             wrongRecordsList: response.data.wrong_records
    //                         }, () => console.log(this.state.records_uploaded))
    //                     })
             
    //                 this.showToast("success","Uploaded Sucessfully")
    //             }
    //         })
    //     }
    // }
    showToast = (type, message) => {
        if (type === "success")
            return toast.success(message);
        else if (type === "error")
            return toast.error(message);
    }
    // componentDidMount() {
    //     this.getWorkerLedgerList()
    // }
    render() {
        const tablePagination = {
            currentPage: this.state.currentPage,
            count: this.state.count,
            changePage: this.updateDataOnTablePagination,
            rowsOptions: this.rowsOptions,
            rowsPerPage: this.state.rowsPerPage,
            tableUpdating: this.state.isTableUpdating
        }
        return (
            <div>
                <ToastContainer
                    position='top-right'
                    hideProgressBar
                    newestOnTop={true}
                    closeOnClick
                    autoClose={1500}
                    rtl={false}
                    draggable
                    pauseOnHover
                ></ToastContainer>
                <Card style={{ margin: "20px 0px", padding: "20px" }}>
                    <Grid container >
                        {/* <Grid item sm={3}>
                            <HideUnhideColumns columns={this.state.column} toggleColumn={this.updateColumnFileds}>Columns</HideUnhideColumns>

                        </Grid> */}
                        {/* <Grid item sm={4}>
                            <input
                                style={{ display: "none" }}
                                id="contained-button-file"
                                type="file"
                                onChange={this.handleExcel}
                            />
                            <label htmlFor="contained-button-file">
                                <Button
                                    variant="outlined"
                                    className="uploadButton marginLight20"
                                    component="span"
                                >
                                    Upload Excel
                        </Button>
                            </label>
                        </Grid> */}
                        <Grid item xs={12} className="padding0" >
                            <WorkerLedgerV2 /></Grid>
                    </Grid>
                </Card>
            </div>

        );
    }
}
export default FinanceAllLedger;