import React from 'react';
import { TiContacts } from 'react-icons/ti';
import {
  IoMdPersonAdd,
  IoIosPeople,
  IoMdContacts,
  IoIosApps,
  IoIosListBox,
  IoMdAnalytics
} from 'react-icons/io';
import {
  MdAccountBox,
  MdNoteAdd,
  MdSettingsApplications
} from 'react-icons/md';
import { FaExpeditedssl, FaSuitcase, FaSlidersH } from 'react-icons/fa';
import './sidebarmenu.css';
import SwapHorizRoundedIcon from '@material-ui/icons/SwapHorizRounded';
const sideBarMenu = {
  workerManagemet: {
    buttonClassname: 'bootstrap-button-text',
    id: 'worker_management',
    name: 'Worker Management',
    buttonVarient: 'secondary',
    icon: <IoIosPeople className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'Worker DB',
        subIcon: <TiContacts className='fitIcon' />,
        route: '/worker/workerList'
      },
      // {
      //   id: 11,
      //   name: 'Worker DB',
      //   subIcon: <TiContacts className='fitIcon' />,
      //   route: '/worker/workerListNew'
      // },
      // {
      //   id: 2,
      //   name: 'Add Worker',
      //   subIcon: <IoMdPersonAdd className='fitIcon' />,
      //   route: '/worker/addWorker'
      // },
      {
        id: 6,
        name: 'Add New Worker',
        subIcon: <IoMdPersonAdd className='fitIcon' />,
        route: '/worker/newAddWorker'
      },
      // {
      //   id: 4,
      //   name: 'Worker view',
      //   subIcon: <IoMdPersonAdd className='fitIcon' />,
      //   route: '/worker/workerview'
      // },
      
      {
        id: 3,
        name: 'External Workers',
        subIcon: <TiContacts className='fitIcon' />,
        route: '/worker/externalWorkers'
      },
      // {
      //   id: 3,
      //   name: 'OnBoarding Review',
      //   subIcon: <TiContacts className='fitIcon' />,
      //   route: '/worker/onBoarding'
      // },
      // {
      //   id: 4,
      //   name: 'Screening',
      //   subIcon: <TiContacts className='fitIcon' />,
      //   route: '/worker/screening'
      // }
    ]
  },
  employerSubmenu: {
    buttonClassname: 'bootstrap-button-text',
    id: 'employer_submenu',
    name: 'Employer Management',
    buttonVarient: 'secondary',
    icon: <IoMdContacts className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'Employer DB',
        subIcon: <TiContacts className='fitIcon' />,
        route: '/employer/employerLists'
      },
      {
        id: 2,
        name: 'Add Employer',
        subIcon: <IoMdPersonAdd className='fitIcon' />,
        route: '/employer/addEmployer'
      },
      // {
      //   id: 3,
      //   name: 'Onboarding Review',
      //   subIcon: <IoMdPersonAdd className='fitIcon' />,
      //   route: '/employer/onBoardingReview'
      // },
      // {
      //   id: 4,
      //   name: 'Screening',
      //   subIcon: <IoMdPersonAdd className='fitIcon' />,
      //   route: '/employer/screening'
      // },
      // {
      //   id: 5,
      //   name: 'Inactive Employer',
      //   subIcon: <IoMdPersonAdd className='fitIcon' />,
      //   route: '/employer/inactiveEmployer'
      // },
      // {
      //   id: 6,
      //   name: 'Payments',
      //   subIcon: <IoMdPersonAdd className='fitIcon' />,
      //   route: '/employer/payments'
      // }
    ]
  },
  jobmenu: {
    buttonClassname: 'bootstrap-button-text',
    id: 'config_data',
    name: 'Job',
    buttonVarient: 'secondary',
    icon: <IoIosApps className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'Job DB',
        subIcon: <TiContacts className='fitIcon' />,
        route: '/jobs/jobList'
      },
      {
        id: 3,
        name: 'Assign Job',
        subIcon: <FaSuitcase className='fitIcon' />,
        route: '/jobs/assignvacancy'
      },
      // {
      //   id: 2,
      //   name: 'Add Job',
      //   subIcon: <MdNoteAdd className='fitIcon' />,
      //   route: '/jobs/addJob'
      // },
      {
        id: 21,
        name: 'Add Job',
        subIcon: <MdNoteAdd className='fitIcon' />,
        route: '/jobs/newAddJobForm'
      },
      {
        id: 3,
        name: 'OD Job',
        subIcon: <FaSuitcase className='fitIcon' />,
        route: '/jobs/odJob'
      },
      { 
        id: 4,
        name: 'PT/FT Job',
        subIcon: <FaSuitcase className='fitIcon' />,
        route: '/jobs/pt_ftJob'
      }
    ]
  },
  recruiters: {
    buttonClassname: 'bootstrap-button-text',
    id: 'recruiter_submenu',
    name: 'Recruiter',
    buttonVarient: 'secondary',
    icon: <MdAccountBox className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'Recruiter Dashboard',
        subIcon: <FaSuitcase className='fitIcon' />,
        route: '/recruiter/DB'
      },
      {
        id: 2,
        name: 'Recruiter Listing',
        subIcon: <TiContacts className='fitIcon' />,
        route: '/recruiter/verification'
      }
      
    ]
  },
  salesTracker: {
    buttonClassname: 'bootstrap-button-text',
    id: 'salesTracker_submenu',
    name: 'Sales Tracker',
    buttonVarient: 'secondary',
    icon: <MdAccountBox className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'Sales Tracker',
        subIcon: <FaSuitcase className='fitIcon' />,
        route: '/salestracker'
      }
    ]
  },
  referrals: {
    buttonClassname: 'bootstrap-button-text',
    id: 'salesTracker_submenu',
    name: 'Referrals',
    buttonVarient: 'secondary',
    icon: <MdAccountBox className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'Referrals',
        subIcon: <FaSuitcase className='fitIcon' />,
        route: '/referrals'
      }
    ]
  },
  myProfile: {
    buttonClassname: 'bootstrap-button-text',
    id: 'profile_data',
    name: 'Profile',
    buttonVarient: 'secondary',
    icon: <MdAccountBox className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'Account Details',
        subIcon: <MdAccountBox className='fitIcon' />,
        route: '/profile'
      },
      {
        id: 2,
        name: 'Change Password',
        subIcon: <FaExpeditedssl className='fitIcon' />,
        route: '/profile/changePassword'
      }
    ]
  },
  financeSubMenu: {
    buttonClassname: 'bootstrap-button-text',
    id: 'finance',
    name: 'Finance',
    buttonVarient: 'secondary',
    icon: <IoMdAnalytics className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'All Ledger',
        subIcon: <IoIosListBox className='fitIcon' />,
        route: '/finance/allledger'
      },
      // {
      //   id: 2,
      //   name: 'Transaction Detail',
      //   subIcon: <SwapHorizRoundedIcon className='fitIcon' />,
      //   route: '/finance/Transaction'
      // },
      {
        id: 3,
        name: 'Employer Ledger',
        subIcon: <IoIosListBox className='fitIcon' />,
        route: '/finance/employerLedger'
      },
      {
        id: 4,
        name: 'Worker Ledger',
        subIcon: <IoIosListBox className='fitIcon' />,
        route: '/finance/workerLedger'
      },
    ]
  },

  settingsSubMenu: {
    buttonClassname: 'bootstramp-button-text',
    id: 'settings',
    name: 'Settings',
    buttonVarient: 'secondary',
    icon: <MdSettingsApplications className='fitIcon' />,
    linkClassname: 'link-style',
    links: [
      {
        id: 1,
        name: 'OD Matching Setting',
        subIcon: <FaSlidersH className='fitIcon' />,
        route: '/Settings/CutOffConditions'
      },
      {
        id: 2,
        name: 'OD Matching - Work Later',
        subIcon: <FaSlidersH className='fitIcon' />,
        route: '/Settings/wlConditions'
      },
      {
        id: 3,
        name: 'OD Matching - Work Backup',
        subIcon: <FaSlidersH className='fitIcon' />,
        route: '/Settings/wbConditions'
      },
      {
        id:4,
        name:'PT-FT Matching',
        subIcon:<FaSlidersH className='fitIcon' />,
        route:'/Settings/ptConditions'
      }
    ]
  }
};
export default sideBarMenu;
