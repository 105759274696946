import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Popover, Button ,Divider , Grid, IconButton, Badge } from '@material-ui/core';
import Axios from 'axios' 
import SelectFilter from '../../utils/filters/SelectFilter'
import SliderFilter from '../../utils/filters/SliderFilter'
import DateFilter from '../../utils/filters/DateFilter'
import LocationFilter from '../../utils/filters/locationFilter'
import FilterIcon from '../../assets/icons8-filter-100.png'
import GroupedSelectFilter from '../../utils/filters/groupedSelectFilter'
import InputTextFilter from '../../utils/filters/inputTextFilter' 
import urlLinks from '../../urlLinks';
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover(props) {
    // const classes = useStyles();
    const {isFilterApplied, setIsFilterApplied} = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = useState(props.filters)
    // const [jobRoleList, setJobRoleList] = useState([])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    console.log({filters})
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleResetFilters =() =>{
        const newFilters=props.resetFilters()
        setFilters(newFilters);
        setIsFilterApplied(false)
        
    }
    const handleApplyFilters =() =>{
        props.updateFilters(filters);
        handleClose();
        setIsFilterApplied(true)
    }
    const handleCancelFilters =() =>{
        setFilters(props.filters);
        handleClose();
    }
    // const makeJobRoleData= (data)  =>{
    //     let list=[];
    //     data.map(el => 
    //         list.push({
    //             id: el.id,
    //             name: el.categorySubType
    //         })
    //     )
    //     return list;
    // }
    // console.log({companyList,
    //     managerList,
    //     recruiterList})
    
    let popoverData= <Grid></Grid>
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    popoverData= 
        <Grid container>
          
            <Grid item sm className="padding20">
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="Payment date to"
                    labelFrom="Payment date from"
                    id="payment_date"
                />
            </Grid>
            <Grid item sm className="padding20">
                <DateFilter
                    filters={filters}
                    setFilters={setFilters}
                    labelTo="Invoice date to"
                    labelFrom="Invoice date from"
                    id="invoice_date"
                />
            </Grid>
        </Grid>
    return (
        <div>
            <Badge color="secondary" variant="dot" invisible={!isFilterApplied}>
                <IconButton 
                    className="filterButton marginLight20"
                    onClick={handleClick}
                >
                    <img src={FilterIcon} alt="img" />
                </IconButton>
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container className="buttonList">
                    <Grid item xs={5}>
                        <span className= "FilterHeading"></span>
                    </Grid>
                    <Grid item xs={7} style={{padding: "20px"}} className="flexRowReverse">      
                        <Button 
                            className= "dataButton"
                            style={{marginLeft: "30px"}}
                            onClick={handleResetFilters}
                        >Reset</Button>
                        <Button 
                        className= "dataButton"
                            style={{marginLeft: "30px"}}
                            onClick={handleApplyFilters}
                        >Apply</Button>
                        <Button 
                            className= "dataButton"
                            onClick={handleCancelFilters}
                        >Cancel</Button>
                    </Grid>
                </Grid>
                <Divider />
                {popoverData}
            </Popover>
        </div>
    );
}
