import React from 'react'
import {Grid} from '@material-ui/core';
import * as moment from 'moment'


const Payout = (props) =>{
    const data=props.data
    
    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}
    const setCommision =(data) =>{
        if(data.detailsJobs[0].jobPayout.okaygoCommissionTerm===0)
            return `${props.numberFormat(data.detailsJobs[0].jobPayout.okaygoCommissionPerJoining)}`
        if(data.detailsJobs[0].jobPayout.okaygoCommissionTerm===1)
            return `${data.detailsJobs[0].jobPayout.okaygoCommissionPerJoining} %`
    }
    const values={
        recruiterPayoutAmount: data.detailsJobs[0].jobPayout?
            data.detailsJobs[0].jobPayout.remoteRecPayoutAmount?
                props.numberFormat(data.detailsJobs[0].jobPayout.remoteRecPayoutAmount):'-'
            :'-',
        recruiterPayoutDetails: data.detailsJobs[0].jobPayout?
            data.detailsJobs[0].jobPayout.remoteRecPayout_details?
                data.detailsJobs[0].jobPayout.remoteRecPayout_details:'-'
            :'-',
        leaderPayoutAmount:data.detailsJobs[0].jobPayout?
            data.detailsJobs[0].jobPayout.leaderPayoutAmount? 
                props.numberFormat(data.detailsJobs[0].jobPayout.leaderPayoutAmount):'-'
            :'-',
        leaderPayoutDetails: data.detailsJobs[0].jobPayout?
            data.detailsJobs[0].jobPayout.leaderPayoutDetails?
                data.detailsJobs[0].jobPayout.leaderPayoutDetails:'-'
            :'-',
        commission: data.detailsJobs[0].jobPayout?
            (data.detailsJobs[0].jobPayout.okaygoCommissionTerm===0?"Fixed":data.detailsJobs[0].jobPayout.okaygoCommissionTerm===1?"Variable":'-')
            :'-',
        commissionPerJoining: data.detailsJobs[0].jobPayout?setCommision(data): '-',
        replacementPeriod: data.detailsJobs[0].jobPayout?
            data.detailsJobs[0].jobPayout.okaygoReplacementClause?
                `${data.detailsJobs[0].jobPayout.okaygoReplacementClause} days`:'-'
            :'-',
        invoicePeriod: data.detailsJobs[0].jobPayout?
            data.detailsJobs[0].jobPayout.okaygoInvoicingPeriod?
                `${data.detailsJobs[0].jobPayout.okaygoInvoicingPeriod} days`:'-'
            :'-'
    }
    return (
        <Grid container spacing={0} className="jobDetail" >
            <Grid item xs={12} className="subHeading">
                <span>Payout</span>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Recruiter payout amount</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.recruiterPayoutAmount}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Recruiter payout details</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.recruiterPayoutDetails}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Leader payout amount</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.leaderPayoutAmount}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Leader payout details</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.leaderPayoutDetails}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>OkayGo commission term</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.commission}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>OkayGo commission per joining</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.commissionPerJoining}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>OkayGo replacement period</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.replacementPeriod}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>OkayGo invoice period</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.invoicePeriod}</span>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Payout;

