import Axios from 'axios';
import qs from 'querystring';
import React, { Component } from 'react';
import urlLinks from '../../../urlLinks';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import { CircleLoader } from 'react-spinners';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import { ExpansionPanel, TextField, ExpansionPanelSummary, OutlinedInput, InputAdornment, Typography, ExpansionPanelDetails, FormControl, Button, InputLabel,  Select,  Grid } from '@material-ui/core';

const styles = theme => ({
	gap: {
	  padding: '10px 5px',
	  wordBreak: 'break-word'
	},
	buttonStyle: {
		width: '90px',
		margin: 'auto',
		marginTop: '15px',
		align: 'center',
		fontSize: '0.8rem'
	},
});

class EditJobDetail extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			isSubmitting: false,
			isLoading: true,
			check:false,
			prevSkills: [],
			entities: [],
			labels: [],
			jobTypes: {},
			openings: 0,
			salary: 0,
			typesToRender: [],
			textArea: '',
			newSkills: {},
			labelToName: {},
			nameToLabel: {}
		}
		this.jobdata=null;
		this.hj=[];
		this.handleChange = this.handleChange.bind(this);
	}

	getUniqueArray = array => {
		var uniqueArray = [];
		array.map(el => {
			if (uniqueArray.indexOf(el) === -1) {
			uniqueArray.push(el);
			}
		});
    	return uniqueArray;
  	};
	updateValues =() =>{
		this.setState({isSubmitting: true});
		const newSkills= this.state.newSkills;
	}






  	handleSubmit = () => {
		this.setState({isSubmitting: true})
		let handledata = [];
		const newSkills=(this.state.newSkills)

		delete newSkills.undefined;
		const skillKeys=Object.keys(newSkills)
		console.log(newSkills);
		const updatedSkillSet= {}
		this.state.typesToRender.map(skill =>{
			if(skillKeys.find(element => element === skill)){
				return updatedSkillSet[skill]= newSkills[skill]
			}
			else{
				return updatedSkillSet[skill]= 0
			}
		})
		console.log({updatedSkillSet});
		console.log(handledata)
		let skillCounter=0
		this.state.prevSkills.map(el => {
			console.log({el});

			
			if(el.skillValue !== updatedSkillSet[this.state.labelToName[this.state.labels[skillCounter]]]) {
				console.log(this.state)
				console.log([updatedSkillSet[this.state.labelToName[el.skillName]]])
				const skilltypeID=
				handledata.push({
					job_skills_id: el.jobSkillsId,
					requested_by: this.props.location.userId,
					job_id: this.props.location.jobId,
					job_detail_id: this.props.location.id,
					skill_type_id: this.state.jobTypes[updatedSkillSet[this.state.labelToName[this.state.labels[skillCounter]]]]===undefined?0:this.state.jobTypes[updatedSkillSet[this.state.labelToName[this.state.labels[skillCounter]]]],
				})
				console.log({handledata});	
			}
			skillCounter++;
		})
		console.log(handledata)
		if(this.state.check) {
			if(handledata.length === 0) {
				const skills = [];
				this.state.typesToRender.map((el, index) => {
					if(this.state.newSkills[el]) {
						skills.push(this.state.newSkills[el])
					} else {
						skills.push(Object.values(this.state.entities[el])[0]);
					}
				})
				skills.map((el, index) => {
					handledata.push({
						requested_by: this.props.location.userId,
						job_id: this.props.location.jobId,
						job_detail_id: this.props.location.id,
						skill_type_id: this.state.jobTypes[el],
					})
				})
			}
		}
		const prevJobDetailData = this.props.location.jobDetails;
		// console.log(prevJobDetailData.detailSkills);
		const detailedJob = {
			additional_requirement: this.state.textArea,
			amount: +this.state.salary,
			amount_per: prevJobDetailData.amountPer,
			employee_required: +this.state.openings,
			experience_required: 0,
			gender: 0,
			is_recurring: 0,
			job_details_id: this.props.location.id,
			job_category_id: 0,
			job_id: this.props.location.jobId,
			qualification_required: '0',
			skill_set: [],
			job_position: prevJobDetailData.jobPosition,
			job_title: prevJobDetailData.jobTitle,
			job_type_id: prevJobDetailData.jobTypeId,
		};
		console.log(detailedJob);
		Axios.post(`${urlLinks.baseUrl}/job/details/`, qs.stringify(detailedJob))
		.then(response => {
			// console.log("**");
			if(!this.state.check) {
				toast.success(`Job Edited Succesfully`);
				this.props.history.push('/jobs/jobList');
			}
		})
		let counter = 0
		handledata.map(el => {
			// console.log(el)
			Axios.post(`${urlLinks.baseUrl}/job/skills/`, qs.stringify(el))
			.then(response => {
				counter++;
				if(counter === handledata.length) {
					toast.success(`Job Edited Succesfully`);
					this.props.history.push('/jobs/jobList');
				}
			})
		})
	}

	handleChange(){
		this.setState({ check:!this.state.check});
	  };

	componentDidMount() {
		
		
		if(this.props.location.jobDetails) {
			this.jobdata=this.props.location.jobDetails;
			this.hj=this.props.location.jobDetails.detailSkills;
			console.log(this.props.location.jobDetails)
			let entities = {};
			let keys = ''
			Axios.get(`${urlLinks.baseUrl}/configmaster/entityList`)
			.then(response => {
				entities = response.data.response.content
				console.log(response.data.response.content)
				Axios.get(`${urlLinks.baseUrl}/configmaster/?id=${this.props.location.jobDetails.jobTypeId}`)
				.then(response2 => {
					keys = response2.data.response.content[0].typeValue
					console.log(response2);
					Axios.get(`${urlLinks.baseUrl}/configmaster/inquery?inquery=${keys}`)
					.then(response3 => {
						let ids = response3.data.response.content.map(el => {
							return el.id;
						});
						const jobEntities = response3.data.response.content
						let labels = [];
						let typesToRender = [];
						const jobTypes = {};
						jobEntities.map(el => {
							labels.push(el.typeDesc);
							typesToRender.push(el.typeKey);
							if(ids.includes(+el.id)) {
								jobTypes[el.typeValue] = el.id;
							}
						})
						labels = this.getUniqueArray(labels);
						typesToRender = this.getUniqueArray(typesToRender);
						const labelToName = {};
						const nameToLabel = {};
						const newSkills = {};
						labels.map((el, index) => labelToName[el] = typesToRender[index])
						typesToRender.map((el, index) => nameToLabel[el] = labels[index])
						this.props.location.jobDetails.detailSkills.map(el => {
							newSkills[typesToRender[labels.indexOf(el.skillName)]] = el.skillValue
						})
						const jobEntitiesFinal = {};
						typesToRender.map(el => {
							const jobEntities = {};
							Object.keys(entities[el]).map(el2 => {
								if(ids.includes(+el2)) {
									// console.log(ids.includes(el2))
									jobEntities[el2] = entities[el][el2];
								}
							})
							jobEntitiesFinal[el] = jobEntities;
						})
						console.log(this.props.location)
						this.setState({
							labels: labels,
							typesToRender: typesToRender,
							entities: jobEntitiesFinal,
							newSkills: newSkills,
							prevSkills: this.props.location.jobDetails.detailSkills,
							labelToName: labelToName,
							nameToLabel: nameToLabel,
							salary: this.props.location.jobDetails.amount,
							textArea: this.props.location.jobDetails.additionalRequirement,
							openings: this.props.location.jobDetails.employeeRequired,
							jobTypes: jobTypes,
							isLoading: false,
						}, () =>console.log(this.state)
						)
					})
					.catch(err => {
						toast.error(`Error Loading Job Detail`)
						this.props.history.push('/jobs/jobList');
					});
				})
				.catch(err => {
					toast.error(`Error Loading Job Detail`)
					this.props.history.push('/jobs/jobList');
				});
			})
			.catch(err => {
				toast.error(`Error Loading Job Detail`)
				this.props.history.push('/jobs/jobList');
			});
		} else {
			this.props.history.push('/jobs/jobList');
		}
	}

	render() {
		// console.log(this.state)
		const { classes } = this.props;
		if (this.state.isLoading) {
      return (
        <CircleLoader
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto'
          }}
          color={'#194D33'}
        ></CircleLoader>
      );
    } else {
		return (
			<>
				<ToastContainer
					position='top-right'
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					autoClose={4000}
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				></ToastContainer>
				
				<Grid container spacing={2} style={{marginTop: '15px'}}>
				
					<Grid item xs={6}>
					<TextField
						id="outlined-number"
						label="Openings"
						type="number"
						value={this.state.openings}
						onChange={event => this.setState({openings: event.target.value})}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
					/>
					</Grid>
					<Grid item xs={6}>
						<FormControl className={classes.margin} variant="outlined">
							<InputLabel htmlFor="outlined-adornment-amount">Salary</InputLabel>
							<OutlinedInput
								id="outlined-adornment-amount"
								value={this.state.salary}
								onChange={event => this.setState({salary: event.target.value})}
								startAdornment={<InputAdornment position="start">₹</InputAdornment>}
								labelWidth={60}
							/>
						</FormControl>
					</Grid>
				</Grid>
				
				<Grid item xs={12} style={{marginTop: '15px'}} >
					{	
					this.hj[0]? <Typography>  </Typography>    :   
					<div><Chip	
							label="There is no special requirement"
							variant="outlined"
							color="primary"/>
								
							<Switch
								checked={this.state.check}
								onChange={this.handleChange}
								name="checkedA"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
							<Grid item xs={12} >
								{this.state.check? "" :<Button
									type='submit'
									disabled={this.state.isSubmitting}
									variant='contained'
									className={classes.buttonStyle}
									onClick={this.handleSubmit}
									color='primary'>
									Submit
									</Button>  
								}
							</Grid>
						</div>			
					}
				</Grid>
				{this.hj[0] || this.state.check ?
					<Grid container>
						<Grid item xs={12} style={{marginTop: '15px'}}>
							<ExpansionPanel>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel2a-content'
									id='panel2a-header'
									style={{ height: '40px', fontSize: '0.8rem' }}
								>
									<Typography>
										{this.props.location.jobDetails ? this.props.location.jobDetails.jobTitle : null}
									</Typography>
									<Typography style={{marginLeft: '70%'}}>
										Add Job description and Special Req
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid container>
										{this.state.typesToRender.length > 0 ?
											this.state.typesToRender.map(
											(requirement, index) => {
											return (
												<>
													<Grid
														key={index + 1}
														item
														xs={2}
														className={classes.gap}
													>
														{this.state.labels[index]}
													</Grid>
													<Grid
														item
														xs={4}
														className={classes.gap}
													>
														<FormControl
															variant='outlined'
															fullWidth
														>
															<InputLabel htmlFor='outlined-age-native-simple'>
															</InputLabel>
															{console.log(this.state.newSkills)}
															{requirement==="experience" && !this.state.newSkills.hasOwnProperty('experience')? 
																this.setState({newSkills:{...this.state.newSkills, experience: "Fresher"}}, console.log(this.state.newSkills)): null}
															
															<Select
																key={index + 1}
																value={(requirement==="experience" && !this.state.newSkills.hasOwnProperty('experience')) ? "Fresher" :this.state.newSkills[requirement]}
																native
																onChange={event => {
																	const newSkills = {...this.state.newSkills}
																	newSkills[requirement] = event.target.value==="None"?0:event.target.value;
																	console.log(newSkills);
																	
																	this.setState({ newSkills: newSkills })
																}}
																label={''}
																inputProps={{}}
																id={this.state.typesToRender[index]}
															>
															{console.log(requirement)}

															{requirement==="experience"?null:
																<option
																	key={0}
																	value={null}
																>
																	None
																</option>}
																{Object.values(
																	this.state.entities[requirement]).map((el, i) => (
																	<option
																		key={i}
																		value={el}
																	>
																		{el}
																	</option>
																))}
															</Select>
														</FormControl>
													</Grid>
												</>
											);
										})
										: null
										}
										<Grid item xs={12} className={classes.gap}>
											Job Description
										</Grid>
										<Grid item xs={12} className={classes.gap}>
											<TextField
												rows={10}
												value={this.state.textArea}
												style={{ width: '100%' }}
												id='textarea'
												variant="outlined"
												multiline
												inputProps={{
													maxlength: 1500
												}}
												helperText={
													`${this.state.textArea?this.state.textArea.length:0}/1500`}
												onChange={event => {
													this.setState({textArea: event.target.value});
												}}
											/>
										</Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
						<Button
						type='submit'
						disabled={this.state.isSubmitting}
						variant='contained'
						className={classes.buttonStyle}
						onClick={this.handleSubmit}
						color='primary'>
						Submit
					</Button>
					</Grid>
				 :""
				}	
				
			</>
		)
	}
	}
}

export default withStyles(styles)(EditJobDetail);

