import React, { Component } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Logo from '../../assets/OkayGo-website-4-3.png';
import { withRouter } from 'react-router-dom';
import SignOut from '../LogOut';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomeIcon from '@material-ui/icons/Home';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { Avatar, Drawer, Divider, List, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import './NavBar.css';
import CollapsableList from '../../utils/collapsableButton/CollapsableButton';
import sideBarMenu from '../../sidebarmenu';
import RoutingPath from '../RoutingPath/Routing';
import { Container } from '@material-ui/core';
import VesionPackage from '../../../package.json';  
const drawerWidth = 240;
const styles = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  },
  containerShift: {
    marginTop: '50px'
  },
  icon: {
    // marginRight: "8px",
    marginLeft: '-16px',
    marginRight: '8px',
    width:"22px",
    height:"22px",
    minWidth:"0px",
    color:"rgba(0, 0, 0, 0.74)"
  },
  link:{
    textDecoration:"none",
    color:"rgba(0, 0, 0, 0.84)"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
});
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false
    };
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
  }

  handleDrawerOpen() {
    this.setState({
      isDrawerOpen: true
    });
  }
  handleDrawerClose() {
    this.setState({
      isDrawerOpen: false
    });
  }
  render() {
    const classes = this.props.classes;
    return (
      <AuthContext.Consumer>
        {context => {
          return (
            <div style={{ flexGrow: 1 }}>
              <CssBaseline />
              <AppBar
                position='fixed'
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: this.state.isDrawerOpen
                })}
              >
                <Toolbar variant='dense'>
                  <IconButton
                    edge='start'
                    onClick={this.handleDrawerOpen}
                    className={clsx(
                      classes.menuButton,
                      this.state.isDrawerOpen && classes.hide
                    )}
                    style={{ marginRight: '5px' }}
                    color='inherit'
                    aria-label='menu'
                  >
                    <MenuIcon />
                  </IconButton>
                  <Link to='/'>
                    <Avatar
                      className='logo-image'
                      src={Logo}
                      alt='company-logo'
                    ></Avatar>
                  </Link>
                  <Typography style={{marginLeft: 'auto'}}>
                    Welcome, {localStorage.getItem('firstName')} ({localStorage.getItem('roleType')})
                  </Typography>

                  <IconButton
                    style={{ marginLeft: 'auto' }}
                    aria-label='logout'
                    color='inherit'
                    onClick={e => {
                      localStorage.clear();
                      SignOut(e, this.props, context);
                    }}
                  >
                    <Typography>
                      Logout
                    </Typography>
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Drawer
                className={classes.drawer}
                variant='persistent'
                anchor='left'
                open={this.state.isDrawerOpen}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={this.handleDrawerClose}>
                    {this.state.isDrawerOpen ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </div>
                <Divider />
                <List component='div' disablePadding>
                  {localStorage.getItem("roleType")!=="recruiter" && <Link to='/' className={classes.link}>
                    <ListItem style={window.location.pathname === '/' ? {backgroundColor: '#3f51b5', color: 'white'} : null} button className={classes.nested}>
                      <ListItemIcon className={classes.icon}><HomeIcon className='fitIcon' /></ListItemIcon>
                      <ListItemText primary="Dashboard">
                      </ListItemText>
                    </ListItem>
                  </Link>}
                  {localStorage.getItem("roleType")!=="recruiter" && <CollapsableList
                    {...sideBarMenu.employerSubmenu}
                  ></CollapsableList>}
                  {localStorage.getItem("roleType")!=="recruiter" && <CollapsableList
                    {...sideBarMenu.workerManagemet}
                  ></CollapsableList>}
                  {localStorage.getItem("roleType")!=="recruiter" && <CollapsableList {...sideBarMenu.jobmenu}></CollapsableList>}
                  {(localStorage.getItem("roleType")==="recruiter" || localStorage.getItem("roleType")=== 'Super Admin' || localStorage.getItem("roleType")=== 'Operational Admin') && <CollapsableList {...sideBarMenu.recruiters}></CollapsableList>}
                  {(localStorage.getItem('roleType')==="Super Admin" || localStorage.getItem("userId")==="1131" || localStorage.getItem("userId")==="8656" || localStorage.getItem("userId")==="38781") && <CollapsableList {...sideBarMenu.salesTracker}></CollapsableList>}
                  {(localStorage.getItem("roleType")=== 'Super Admin' || localStorage.getItem("userId")==="1131" || localStorage.getItem("userId")==="8656" || localStorage.getItem("userId")==="38781") && <CollapsableList {...sideBarMenu.referrals}></CollapsableList>}
                  {localStorage.getItem("roleType")!=="recruiter" && <CollapsableList {...sideBarMenu.financeSubMenu}></CollapsableList>}
                  {/* <CollapsableList {...sideBarMenu.myProfile}></CollapsableList> */}
                  {localStorage.getItem('roleType')==='Super Admin'? (<CollapsableList {...sideBarMenu.settingsSubMenu}></CollapsableList>):''}
                </List>
                <div style={{marginTop:'103%',marginLeft:'3%',color:'#3f51b5', fontSize:'small'}}>
                <Typography> version {VesionPackage.version} </Typography>
                </div>
              </Drawer>
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: this.state.isDrawerOpen
                })}
              >
                <Container maxWidth='lg' className={classes.containerShift}>
                  <RoutingPath></RoutingPath>
                </Container>
              </main>
            </div>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default withRouter(withStyles(styles)(NavBar));
