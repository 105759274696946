import React, {Component} from 'react'
import Feedback from './Feedback';
import './Feedbacks.css'
import * as moment from 'moment';
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import getfeedbackFilterList from './feedbackConstants'
import utilFunctions from '../../../utilMethods';
import CustomFilter from  './FeedbackFilter'
import ScaleLoader from "react-spinners/ScaleLoader";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import qs from 'querystring';
import { CircleLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ExpansionPanel as MuiExpansionPanel, ExpansionPanelSummary as MuiExpansionPanelSummary, ExpansionPanelDetails as MuiExpansionPanelDetails } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const ExpansionPanel = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

class Feedbacks extends Component{
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            jobIdList: {},
            isSubmitting: true,
            filters: [],
            url:`${urlLinks.baseUrl}tracking/feedbacks`
        }
	}
    resetFilterURL =() =>{
        this.setState({url :`${urlLinks.baseUrl}tracking/feedbacks`}, () => this.getFeedbackList())
    }
    updateFilters =( url, value, date) =>{
        console.log({value});
        let updatedURL=(url && date)?`${url}${date}`:(url && !date)?url:(date && !url)? date: ''
        this.setState({url :`${urlLinks.baseUrl}tracking/feedbacks?${updatedURL}`, filters: value}, () => this.getFeedbackList( value))
    }
    updateStatus =async (updateData) =>{
        // console.log(updateData);
        
        const{feedback, id, joiningdate, offerDate}=  updateData 
        this.setState({isSubmitting: true})
        const postData={
            interview_id: id,
            feedback_status: feedback,
            updated_by: localStorage.getItem('userId')
        }
        if(joiningdate)
            postData.update_joining_date=joiningdate
        if(offerDate)
            postData.update_offered_date=offerDate
        console.log({postData});
        
        Axios.post(`${urlLinks.baseUrl}tracking/updateFeedbackStatus`,qs.stringify(postData))
            .then(response => {
                // console.log(response);
                if (response.data.code === 1000) {
                    this.getFeedbackList();
                    this.setState({isSubmitting: false})
                    toast.success('Status updated Successfully');
                    // setTimeout(window.location.reload(), 4000);

                } else {
                    this.setState({isSubmitting: false})
                    throw toast.error(response.data.message);
                }
            })
            .catch(error => {
                this.setState({isSubmitting: false})
                toast.error(error.message);
            });
    }
    updateSelectedStatus =async (updateData) =>{
        // console.log(updateData);
        
        const{ id, joiningdate, offerDate, salary}=  updateData 
        // console.log({salary});
        
        this.setState({isSubmitting: true})
        const postData={
            interview_id: id,
            is_job_offered:1,
            job_offered_amount:salary,
            joining_date:joiningdate,
            requested_by: parseInt(localStorage.getItem('userId'))
        }
        console.log({postData});
        
        Axios.post(`${urlLinks.baseUrl}interview/offerjob?`,qs.stringify(postData))
            .then(response => {
                console.log(response);
                if (response.data.code === 1000) {
                    this.setState({isSubmitting: false})
                    toast.success('Status updated Successfully');
                } else {
                    throw toast.error(response.data.message);
                }
            })
            .catch(error => {
                this.setState({isSubmitting: false})
                toast.error(error.message);
            });
    }
    extractDate = (date) =>{
        return moment(date).format("DD/MM/YYYY");
    }
    extractTime = (date) =>{
        return moment(date).format("hh:mm a");
    }
    getIdArray = (data) =>{
        const id=data? data.map(el =>{
            return el.jobId
        }): null;
        return id
    }
    idToCity= (id) =>{
        let city=""
        switch (id) {
            case "71":
                // console.log({id});
                city= "New Delhi";
                break;
            case "72":
                // console.log({id});
                city="Noida";
                break;
            case "73":
                // console.log({id});
                city= "Gurugram";
                break;
            default:
                // console.log({id});
                city= "Other";
                break;
            
        }
        return city;
    }
    updateCompanyData =( data, list) => {
        if(!data)
            return null;
        const len=list.length
        const datalen=data.length
        let finalList=[]
        for( let i=0; i<len; i++)
        {   
            for (let j=0; j<datalen; j++)
            {
                if(list[i]===data[j].jobId)
                {
                    finalList.push(data[j])
                }
            }
        }
        return finalList;
        
    }
    completeData =(data, halfData) =>{
        if(!halfData)
            return null;
        const temp= halfData;
        if(!data)
            return null;
        // console.log(halfData);
        for(let i=0 ; i<temp.length; i++){
            if(data[i])
            {
                temp[i].companyName=data[i].companyName;
                temp[i].companyCity=this.idToCity(data[i].cityID);            
                temp[i].contactName=data[i].name;
                temp[i].contactNumber=data[i].phoneNumber;
            }
        }
        // console.log(temp);
        
        return temp
        
    }
    makeData = (data) => {        
        let CompanyDetail={};
        const extractedData = [];
        data.map(el => {
            if(el.slot!=0){
                extractedData.push({
                    jobId: el.jobId,
                    interviewId: el.interviewId,
                    name: `${el.firstName} ${el.lastName?el.lastName:''}`,
                    firstName: el.firstName,
                    userName: el.userName,
                    profile: el.jobTittle,
                    jobType:el.workType===1?'fullTime':el.workType===2?'partTime':'onDemand',
                    companyName:'',
                    companyCity:'',
                    interviewDate:el.slot===1?this.extractDate(el.interviewStartTime):el.slot===2?this.extractDate(el.interviewStartTime2):this.extractDate(el.interviewStartTime2) ,
                    contactName:'',
                    contactNumber:'',
                    offeredDate:el.offeredDate,
                    candidateNumber:el.contactNumber,
                    companyAddress:'',
                    address:el.address,
                    feedbackStatus: el.feedbackStatus
            })}
        })
        return extractedData;
    }

	categoriseData = (data) => {        
        const feedbackList=[]
        const roundPendingList=[]
        const resultAwaitedList=[]
        data.map(el => {
            if(el.feedbackStatus==="RESULT_AWAITED")
            {
                resultAwaitedList.push(el)
            }
            else if(el.feedbackStatus==="ROUNDS_PENDING")
            {
                roundPendingList.push(el)
            }
            else{
                feedbackList.push(el)
            }
        })
        this.setState({feedbackList:feedbackList ,roundPendingList: roundPendingList ,resultAwaitedList: resultAwaitedList, isSubmitting:false})
    }
    getFeedbackList=(value) => {
        let url=''      
        let data=[]
        this.setState({isSubmitting: true})
        Axios.get(this.state.url)
            .then(response => {          
                if (response.data.response) {
                    data=this.makeData(response.data.response);
                    const jobIdList=data?this.getIdArray(data):null;
                    url=`${urlLinks.baseUrl}tracking/getUpcomingInterviewsCompanyDetails?job_id=${jobIdList}`;                
                    Axios.get(url)
                    .then(response => {
                        const companyData = this.updateCompanyData(response.data.response , jobIdList);
                        const completeData=this.completeData(companyData, data);
                        let filters=[];
                        if(value)
                            filters=value
                        else 
                            filters=getfeedbackFilterList(data)
                        this.setState({ list: completeData, filters: filters},() => this.categoriseData(completeData))
                    })
                    .catch(error =>{
                        this.setState({isSubmitting: false})
                        toast.error(error.message);
                    })
                }
            })
            .catch(error =>{
                this.setState({isSubmitting: false})
                toast.error(error.message);
            })
    }
	
	componentDidMount () { 
        this.setState({isSubmitting: true})
        this.getFeedbackList();
    }


    render(){
        let feedbacklen=this.state.feedbackList?this.state.feedbackList.length:0;    
        let resultAwaitedlen=this.state.resultAwaitedList?this.state.resultAwaitedList.length:0;    
        let roundPendinglen=this.state.roundPendingList?this.state.roundPendingList.length:0;    
        let feedbackList=this.state.feedbackList?this.state.feedbackList.map((feedback, index)  =>
            <Feedback updateSelectedStatus={this.updateSelectedStatus} updateStatus={this.updateStatus} data={feedback} id={feedback.id} cname={index!==feedbacklen-1?'feedback':'feedbackLast'} />
        ) : null 
        let resultAwaitedList=this.state.resultAwaitedList?this.state.resultAwaitedList.map((feedback, index)  =>
            <Feedback updateStatus={this.updateStatus} data={feedback} updateSelectedStatus={this.updateSelectedStatus} id={feedback.id} cname={index!==resultAwaitedlen-1?'feedback':'feedbackLast'} />
        ) : null 
        let roundPendingList=this.state.roundPendingList?this.state.roundPendingList.map((feedback, index)  =>
            <Feedback updateStatus={this.updateStatus} data={feedback} updateSelectedStatus={this.updateSelectedStatus} id={feedback.id} cname={index!==roundPendinglen-1?'feedback':'feedbackLast'} />
        ) : null
        return (
                this.state.isSubmitting?
                <div style={{margin:"auto", position:"sticky", width: "fit-content"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
                :
                <div className="feedbacks">
                    <ToastContainer
                        position='top-right'
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        autoClose={4000}
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    ></ToastContainer>
                    {this.state.filters && <CustomFilter
                        filters={this.state.filters}
                        updateFilters={this.updateFilters}
                        isOpen={this.state.open}
                        resetFilterURL={this.resetFilterURL}
                    ></CustomFilter>}
                    <div className="ActiveFeedback">
                        <ExpansionPanel 
                                TransitionProps={{ unmountOnExit: false }}
                                defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3> Feedback </h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="list">
                                    {feedbacklen!==0? feedbackList:"No Feedback to Show"}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className="roundPending">
                        <ExpansionPanel 
                                TransitionProps={{ unmountOnExit: false }}
                                defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3> Round Pending</h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="list">
                                    {roundPendinglen!==0?roundPendingList:"No Candidate with Pending Rounds status"}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                    </div>
                    <div className="resultAwaited">
                        <ExpansionPanel 
                                TransitionProps={{ unmountOnExit: false }}
                                defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3>Result Awaited</h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="list">
                                    {resultAwaitedlen!==0? resultAwaitedList:"No candidate have Result Awaited status"}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </div>

        )
    }
}

export default Feedbacks;