import React, { Component } from 'react';
import Constant from '../../../constant';
import employerScreeningUtils from './screeningUtils';
import { Card, Grid, Button } from '@material-ui/core';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../../utils/CustomFilter/CustomFilter';
import CustomTable from "../../../utils/Table/Table";


class EmployerScreening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            filters: employerScreeningUtils.screeningFilterList ,
            currentPage: 0,
            count:0,
            rowsPerPage:10,
            isTableUpdating:false,
            column: [{
                id: "1",
                name: "status",
                label: Constant.employerScreening.status,
                isColumnVisible: true
            }, {
                id: "2",
                name: "employerId",
                label: Constant.employerScreening.employerId,
                isColumnVisible: true
            }, {
                id: "3",
                name: "company_name",
                label: Constant.employerScreening.company_name,
                isColumnVisible: true
            }, {
                id: "4",
                name: "rating",
                label: Constant.employerScreening.rating,
                isColumnVisible: true
            }
                , {
                id: "5",
                name: "payment_overdue",
                label: Constant.employerScreening.payment_overdue,
                isColumnVisible: true
            },
            {
                id: "6",
                name: "overdue_days",
                label: Constant.employerScreening.overdues_days,
                isColumnVisible: true

            },
            {
                id: "7",
                name: "cancellation",
                label: Constant.employerScreening.cancellation,
                isColumnVisible: true
            }, {
                id: "8",
                name: "total",
                label: Constant.employerScreening.total,
                isColumnVisible: true
            }, {
                id: "9",
                name: "other_breach",
                label: Constant.employerScreening.other_breach,
                isColumnVisible: true
            }, {
                id: "10",
                name: "last_updated",
                label: Constant.employerScreening.last_updated,
                isColumnVisible: true
            }, {
                id: "11",
                name: "last_reviewed",
                label: Constant.employerScreening.last_reviewed,
                isColumnVisible: true
            },
            {
                id: "12",
                name: "action",
                label: Constant.employerScreening.action,
                isColumnVisible: true
            }]
        }
        this.data = [
            {
                "status":"", 
                "employerId":"E_0001",
                "company_name":"Sushi Junction",
                "rating":"3.5",
                "payment_overdue":"Rs. 10000",
                "overdue_days":"6",
                "cancellation":"10",
                "total":"20",
                "other_breach":"2",
                "last_updated":"07/12/2019",
                "last_reviewed":"08/12/2019",
                
            },
            {
                "status":"", 
                "employerId":"E_0002",
                "company_name":"McDonald's",
                "rating":"4",
                "payment_overdue":"Rs. 50000",
                "overdue_days":"7",
                "cancellation":"15",
                "total":"20",
                "other_breach":"2",
                "last_updated":"07/12/2019",
                "last_reviewed":"08/12/2019",

            },
            {

                "status":"", 
                "employerId":"E_0003",
                "company_name":"Big Wong",
                "rating":"3.5",
                "payment_overdue":"Rs. 8000",
                "overdue_days":"9",
                "cancellation":"12",
                "total":"20",
                "other_breach":"2",
                "last_updated":"07/12/2019",
                "last_reviewed":"08/12/2019",

            }
        ];


        this.rowsOptions=[5,10,25,50,100,200,500];
        this.updateColumnFileds = this.updateColumnFileds.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateDataOnTablePagination =this.updateDataOnTablePagination.bind(this);

    }
    updateColumnFileds(columnsToHide) {
        const upadtedColumns = this.state.column.map((col) => {
            if (columnsToHide.includes(col.id)) {
                col.isColumnVisible = true;
            } else {
                col.isColumnVisible = false;
            }
            return (col);
        })

        this.setState({
            columns: upadtedColumns,
        })
    }
    async updateFilters(recievedFilters) {

        const updatedFilter = this.state.filters.map((filter) => {
            for (let el of recievedFilters) {
                if (el[filter.name]) {
                    filter.default = el[filter.name]
                }
            }
            return filter;
        })
        await this.setState({
            filters: updatedFilter
        })

    }
    updateDataOnTablePagination(rowsPerPage=this.state.rowsPerPage,nextPage=this.state.currentPage){
        //to do
     
    }

    componentDidMount() {
        // Axios.get("http://localhost:3001/employers").then(
        //     (response) => { 
        //         this.setState({
        //             employer: response.data,

        //         });
        //     }
        // ).catch(
        //     (error) => { console.log(error) }
        // );
        this.setState({ list: this.data });

    }
    render() {
        const tablePagination = {
            currentPage:this.state.currentPage,
            count:this.state.count,
            changePage:this.updateDataOnTablePagination,
            rowsOptions:this.rowsOptions,
            rowsPerPage:this.state.rowsPerPage,
            tableUpdating:this.state.isTableUpdating
        }
        return (
            <div>
                <Card style={{ margin: "20px 0px", padding: "20px" }}>
                    <Grid container >
                        <Grid item sm={3}>
                            <HideUnhideColumns columns={this.state.column} toggleColumn={this.updateColumnFileds}></HideUnhideColumns>
                        </Grid>
                        <Grid item sm={3}>
                            <CustomFilter filters={this.state.filters} updateFilters={this.updateFilters}></CustomFilter>
                        </Grid>
                        <Grid item sm={3}>
                            <Button style={{fontSize:"0.7rem"}}  variant="contained" color="primary"> Add Breach Case</Button>
                        </Grid>
                    </Grid>
                </Card>
                <CustomTable columns={this.state.column} data={this.state.list} links={employerScreeningUtils.screeningListAction} title={employerScreeningUtils.title} tablePagination={tablePagination}></CustomTable>
            </div>

        );
    }
}
 
export default EmployerScreening;