import React from "react";
import { Field } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import WeekToggle from './weektoggleButton'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import Interviews from "./interviewDetails"
import {TextField, Select, MenuItem, FormControl, InputLabel, Grid, InputAdornment, FormGroup, FormControlLabel, Checkbox} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectedButton: {
    "&$selectedRed": {
      color: "#1c75bc",
      backgroundColor: "rgba(28, 117, 188, 0.26)",
      borderColor: "#1c75bc"
    }
  },
}));

export const FormSecondStep = formikProps => {
  const classes = useStyles();
  const [companyName, setCompanyName] = React.useState('');
  let ageFromList=[]
  let ageToList=[]
  for(let i=18 ; i<51 ;i++){
    ageFromList.push(<MenuItem value={i}>{i}</MenuItem>)
    ageToList.push(<MenuItem value={i}>{i}</MenuItem>)
  }             
  const { errors, touched, values, setFieldValue, handleChange, setValues } = formikProps;
  const autocompleteInterview = new window.google.maps.places.Autocomplete(
    document.getElementById('googleInterviewAddress', {})
  );
  const handlePlaceSelectInterview = () => {
    let addressObject = autocompleteInterview.getPlace();
    const geometry = addressObject.geometry.location;
    const loc={
      lat:  geometry.lat(),
      lng: geometry.lng(),
      address: addressObject.formatted_address,
      interviewAddressUrl: addressObject.url
    }
    setValues({...values, searchInterviewAddress: loc.address, latInterview: loc.lat , longInterview: loc.lng , interviewAddressUrl: loc.interviewAddressUrl })
  }
  autocompleteInterview.addListener('place_changed', handlePlaceSelectInterview);
  const handleCheckboxes =(event) =>{
    let temp={
      [event.target.name]: event.target.checked
    }
    switch (event.target.name) {
      case "expCheck":
        if(event.target.checked)
          temp["expToggle"]=1
        else if(!event.target.checked)
          temp["expToggle"]=null
        break;
      case "lastExpCheck":
        if(event.target.checked)
          temp["lastExpToggle"]=1
        else if(!event.target.checked)
          temp["lastExpToggle"]=null
        break;
      case "lastSalCheck":
        if(event.target.checked)
          temp["lastSalToggle"]=1
        else if(!event.target.checked)
          temp["lastSalToggle"]=null
        break;
      case "noticePeriodCheck":
        if(event.target.checked)
          temp["noticePeriodToggle"]=1
        else if(!event.target.checked)
          temp["noticePeriodToggle"]=null
        break;
      case "qualification_typeCheck":
        if(event.target.checked)
          temp["qualification_typeToggle"]=1
        else if(!event.target.checked)
          temp["qualification_typeToggle"]=null
        break;
      case "specializationCheck":
        if(event.target.checked)
          temp["specializationToggle"]=1
        else if(!event.target.checked)
          temp["specializationToggle"]=null
        break;
      case "englishLanguageFluencyCheck":
        if(event.target.checked)
          temp["englishLanguageFluencytoggle"]=1
        else if(!event.target.checked)
          temp["englishLanguageFluencytoggle"]=null
        break;
    
      default:
        break;
    }
    setValues({...values, ...temp})
  }

  return (
    <div className="form">
      <Grid container spacing={0}>
        <span className="formHeadings">Requirements</span>
        <Grid container>
          <Grid item xs={12} className="padding0 marginTop26 fontWeightBold fontSize16">
            <span>Candidate should own following</span>
          </Grid>
          <Grid item xs={12} className="padding0">
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={values.bike} onChange={handleCheckboxes} name="bike"  color="primary" />}
                label="Bike"
              />
              <FormControlLabel
                control={<Checkbox checked={values.smartphone} onChange={handleCheckboxes} name="smartphone" color="primary"  />}
                label="Smartphone"
              />
              <FormControlLabel
                control={<Checkbox checked={values.laptop} onChange={handleCheckboxes} name="laptop" color="primary"  />}
                label="Laptop"
              />
              <FormControlLabel
                control={<Checkbox checked={values.wifi} onChange={handleCheckboxes} name="wifi" color="primary"  />}
                label="Wifi connection"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className="padding0 marginTop26 fontWeightBold fontSize16">
            <span>Documents required</span>
          </Grid>
          <Grid item xs={12} className="padding0">
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={values.license} onChange={handleCheckboxes} name="license"  color="primary" />}
                label="Driving License"
              />
              <FormControlLabel
                control={<Checkbox checked={values.rc} onChange={handleCheckboxes} name="rc" color="primary"  />}
                label="RC of Vehicle"
              />
              <FormControlLabel
                control={<Checkbox checked={values.aadhar} onChange={handleCheckboxes} name="aadhar" color="primary"  />}
                label="Aadhar Card"
              />
              <FormControlLabel
                control={<Checkbox checked={values.pan} onChange={handleCheckboxes} name="pan" color="primary"  />}
                label="PAN card"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} className="padding0 flexDisplay">
            <Grid item xs={6}>
              <FormControl fullWidth={true} variant="outlined" className={`margin0 ${classes.formControl}`} >
                <InputLabel id="exp">Experience</InputLabel>
                <Select
                  name='experience'
                  value={values.experience}
                  
                  label= "Experience"
                  onChange={handleChange}
                >
                  {
                    values.skillData.EXPERIENCE.map(exp => 
                      <MenuItem value={exp.id}>{exp.typeValue}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>            
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={values.expCheck} onChange={handleCheckboxes} name="expCheck" toggleName="expToggle" color="primary"  />}
                label="Required Field"
              />   
              <ToggleButtonGroup size="small" value={values.expToggle} exclusive onChange={(event, newExpToggle) => setValues({...values, expToggle: newExpToggle})}>
                <ToggleButton key={1} value={1} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.expCheck}>
                  Mandatory
                </ToggleButton>,
                <ToggleButton key={2} value={0} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.expCheck}>
                  Optional
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} className="padding0 flexDisplay">
            <Grid item xs={6} className="centerFlex fontWeightBold fontSize16">
              <span>Last experience</span>          
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={values.lastExpCheck} onChange={handleCheckboxes} name="lastExpCheck" toggleName="lastExpToggle"  color="primary"  />}
                label="Required Field"
              />   
              <ToggleButtonGroup size="small" value={values.lastExpToggle} exclusive onChange={(event, newExpToggle) => setValues({...values, lastExpToggle: newExpToggle})}>
                <ToggleButton key={1} value={1} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.lastExpCheck}>
                  Mandatory
                </ToggleButton>,
                <ToggleButton key={2} value={0} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.lastExpCheck}>
                  Optional
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} className="padding0 flexDisplay">
            <Grid item xs={6} className="centerFlex fontWeightBold fontSize16">
            <span>Current/ Last Salary</span>         
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={values.lastSalCheck} onChange={handleCheckboxes} name="lastSalCheck" toggleName="lastSalToggle"  color="primary"  />}
                label="Required Field"
              />   
              <ToggleButtonGroup size="small" value={values.lastSalToggle} exclusive onChange={(event, newExpToggle) => setValues({...values, lastSalToggle: newExpToggle})}>
                <ToggleButton key={1} value={1} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.lastSalCheck}>
                  Mandatory
                </ToggleButton>,
                <ToggleButton key={2} value={0} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.lastSalCheck}>
                  Optional
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} className="padding0 flexDisplay">
            <Grid item xs={6} className="centerFlex fontWeightBold fontSize16">
            <span>Notice period</span>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={values.noticePeriodCheck} onChange={handleCheckboxes} name="noticePeriodCheck" toggleName="noticePeriodToggle"  color="primary"  />}
                label="Required Field"
              />   
              <ToggleButtonGroup size="small" value={values.noticePeriodToggle} exclusive onChange={(event, newExpToggle) => setValues({...values, noticePeriodToggle: newExpToggle})}>
                <ToggleButton key={1} value={1} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.noticePeriodCheck}>
                  Mandatory
                </ToggleButton>,
                <ToggleButton key={2} value={0} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.noticePeriodCheck}>
                  Optional
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        {(values.jobRole>44 && values.jobRole<53)?
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
                <InputLabel id="cuisine">Cuisine</InputLabel>
                <Select
                  name='cuisine'
                  value={values.cuisine}
                  label= "Cuisine"
                  onChange={handleChange}
                >
                  {
                    values.skillData.CUISINE.map(c =>
                      <MenuItem value={c.id}>{c.typeValue}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>            
            </Grid>
          </Grid>
          :null
        }
        <Grid container spacing={0}>
          <Grid item xs={12} className="padding0">
            <Grid item xs={6}>
              <FormControl fullWidth={true} variant="outlined" className={`margin0 ${classes.formControl}`} >
                <InputLabel id="gender-preference">Gender Preference</InputLabel>
                <Select
                  name='gender'
                  value={values.gender}
                  label= "Gender Preference"
                  onChange={handleChange}
                >
                  <MenuItem value={"None"} >None</MenuItem>
                  {
                    values.skillData.GENDER.map(g =>
                      <MenuItem value={g.id}>{g.typeValue}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>            
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} className="padding0 flexDisplay">
            <Grid item xs={6}>
              <FormControl fullWidth={true} variant="outlined" className={`margin0 ${classes.formControl}`} >
                <InputLabel id="minimum-qualification">Minimum qualification</InputLabel>
                <Select
                  name='qualification_type'
                  value={values.qualification_type}
                  label= "Minimum qualification"
                  onChange={handleChange}
                >
                  <MenuItem value="None">None</MenuItem>
                  {
                    values.skillData.QUALIFICATION.map(q =>
                      <MenuItem value={q.id}>{q.typeValue}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>            
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={values.qualification_typeCheck} onChange={handleCheckboxes} name="qualification_typeCheck" toggleName="qualification_typeToggle"  color="primary" disabled={values.qualification_type==="None"} />}
                label="Required Field"
              />   
              <ToggleButtonGroup size="small" value={values.qualification_typeToggle} exclusive onChange={(event, newExpToggle) => setValues({...values, qualification_typeToggle: newExpToggle})}>
                <ToggleButton key={1} value={1} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.qualification_typeCheck}>
                  Mandatory
                </ToggleButton>,
                <ToggleButton key={2} value={0} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.qualification_typeCheck}>
                  Optional
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} className="padding0 flexDisplay">
            <Grid item xs={6}>
              <Field
                name="specialization"
                label="Specialization"
                className="margin0" 
                variant= "outlined"
                fullWidth={true}
                as={TextField}
              />        
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox checked={values.specializationCheck} onChange={handleCheckboxes} name="specializationCheck" toggleName="specializationToggle"  color="primary"  />}
                  label="Required Field"
                />   
                <ToggleButtonGroup size="small" value={values.specializationToggle} exclusive onChange={(event, newExpToggle) => setValues({...values, specializationToggle: newExpToggle})}>
                  <ToggleButton key={1} value={1} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.specializationCheck}>
                    Mandatory
                  </ToggleButton>,
                  <ToggleButton key={2} value={0} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.specializationCheck}>
                    Optional
                  </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} className="padding0 flexDisplay" >
            <Grid item xs={6}>
              <FormControl fullWidth={true} variant="outlined" className={`margin0 ${classes.formControl}`} >
                <InputLabel id="English-language-fluency">English language fluency</InputLabel>
                <Select
                  name='englishLanguageFluency'
                  value={values.englishLanguageFluency}
                  label= "English language fluency"
                  onChange={handleChange}
                >
                  <MenuItem value={1}>No English</MenuItem>
                  <MenuItem value={2}>Thoda English</MenuItem>
                  <MenuItem value={3}>Good English</MenuItem>
                </Select>
              </FormControl>            
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox checked={values.englishLanguageFluencyCheck} onChange={handleCheckboxes} name="englishLanguageFluencyCheck" toggleName="englishLanguageFluencytoggle"  color="primary"  />}
                  label="Required Field"
                />   
                <ToggleButtonGroup size="small" value={values.englishLanguageFluencytoggle} exclusive onChange={(event, newToggle) => setValues({...values, englishLanguageFluencytoggle: newToggle})}>
                  <ToggleButton key={1} value={1} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.englishLanguageFluencyCheck}>
                    Mandatory
                  </ToggleButton>,
                  <ToggleButton key={2} value={0} style={{textTransform:"capitalize"}} classes={{root: classes.selectedButton }} disabled={!values.englishLanguageFluencyCheck}>
                    Optional
                  </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <span className="smallHeading">Preferred age</span>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <FormControl fullWidth={true} variant="outlined" className={`margin0 ${classes.formControl}`} >
              <InputLabel id="exp-from">From</InputLabel>
              <Select
                name='ageFrom'
                value={values.ageFrom}
                label= "From"
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  <em>From</em>
                </MenuItem>
                {ageFromList}
              </Select>
            </FormControl>            
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth={true} variant="outlined" className={`margin0 ${classes.formControl}`} >
              <InputLabel id="age-to">To</InputLabel>
              <Select
                name='ageTo'
                value={values.ageTo}
                label= "To"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {ageToList}
              </Select>
            </FormControl>            
          </Grid>
        </Grid>
         <Grid container spacing={0}>
          <Grid item xs={6}>
            <FormControl fullWidth={true} variant="outlined" className={`margin0 ${classes.formControl}`} >
              <InputLabel id="CV-required">CV Required</InputLabel>
              <Select
                name='cvRequired'
                value={values.cvRequired}
                label= "CV Required"
                onChange={handleChange}
              >
                <MenuItem value={0} >Not Required</MenuItem>
                <MenuItem value={1}>Optional</MenuItem>
                <MenuItem value={2}>Mandatory</MenuItem>
              </Select>
            </FormControl>            
          </Grid>
        </Grid>
        <span className="formHeadings">Interview details</span>
         <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field
              name="interviewAddress"
              label="Interview address"
              variant= "outlined"
              className="margin0"
              as={TextField}
              fullWidth={true}
              rows={10}
              inputProps={{
                maxlength: 1000
              }}
              helperText={`${values.interviewAddress.length}/1000`}
            />    
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextField
              className={`margin0 ${classes.text_container}`}
              id='googleInterviewAddress'
              variant='outlined'
              fullWidth={true}
              label='Search Interview Address'
              name='searchInterviewAddress'
              onKeyPress={event =>
                event.key === 'Enter' ? event.preventDefault() : null
              }
              InputProps={{
                className: classes.input,
                inputProps: {
                  className: classes.inputStyle
                }
              }}
              InputLabelProps={{
                className: classes.inputLabel
              }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field
              name="interviewJobDescription"
              label="Interview process description"
              variant= "outlined"
              fullWidth={true}
              className="margin0"
              as= {TextField}
              multiline= {true}
              rows={10}
              rows={10}inputProps={{
                maxlength: 1000
              }}
              helperText={`${values.interviewJobDescription.length}/1000`}
            />        
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <span className="smallHeading">Preferred interview slots</span>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Interviews  
            values={values} 
            handleChange={handleChange} 
            setFieldValue={setFieldValue} 
            errors={errors} 
            touched={touched} 
          />
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field
              name="specialRequirements"
              label="Joining requirements / Special requirements"
              variant= "outlined"
              fullWidth={true}
              className="margin0"
              as= {TextField}
              multiline= {true}
              rows={10}
              rows={10}inputProps={{
                maxlength: 1000
              }}
              helperText={`${values.specialRequirements.length}/1000`}
            />        
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
};
