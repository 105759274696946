import React, {Component} from 'react'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import DropDownConstants from '../../dropDownConstant';
import urlLinks from '../../urlLinks'
import * as moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'querystring';
import utils from '../../utilMethods'

const styles = theme => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: '70px'
  },
  text_container: {
    width: '80%',
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonStyle: {
    width: '90px',
    margin: 'auto',
    fontSize: '0.8rem'
  },
  input: {
    padding: '10px',
    height: '40px',
    fontSize: '0.8rem'
  },
  inputStyle: {
    padding: '0px',
    height: '40px',
    fontSize: '0.8rem'
  },
  selectInput: {
    padding: '10px',
    height: '0px',
    fontSize: '0.8rem'
  },
  inputLabel: {
    transform: 'translate(13px, 12px) scale(1)',
    fontSize: '0.8rem'
  },
  grid: {
    maxWidth: '800px',
    margin: 'auto'
  },
  textheading: {
    textDecoration: 'underline',
    fontSize: '0.9rem'
  },
  alignFormat: {
    margin: 'auto',
    fontSize: '0.8rem'
  },
  textBoxStyle: {
    height: '35px',
    marginLeft: '10px'
  }
});

class AddInterview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        pincode: ''
      },
      interviewDate1: null,
      interviewDate2: null,
      interviewDate3: null,
      interviewTime1: null,
      interviewTime2: null,
      interviewTime3: null,
      interviewTime4: null,
      interviewTime5: null,
      interviewTime6: null,
    }
    this.autocomplete = {};
    this.lat = '';
    this.lang = '';

  }

  // handleLandmarkChange = (event) => {
  //   const address = {...this.state.address};
  //   address.addressLine2 = event.target.value;
  //   this.setState({ address: address})
  //   // console.log(event.target.value)
  // }

  handleInterviewSubmit = () => {
    // console.log('Yes')
    // console.log(this.props)
    // console.log(`DONE`)
    // this.setState({ isSubmitting: true});
    const interviewTime1 = this.state.interviewTime1 ? this.state.interviewTime1.toTimeString().split(' ')[0] : null;
    const interviewTime2 = this.state.interviewTime2 ? this.state.interviewTime2.toTimeString().split(' ')[0] : null;
    const interviewTime3 = this.state.interviewTime3 ? this.state.interviewTime3.toTimeString().split(' ')[0] : null;
    const interviewTime4 = this.state.interviewTime4 ? this.state.interviewTime4.toTimeString().split(' ')[0] : null;
    const interviewTime5 = this.state.interviewTime5 ? this.state.interviewTime5.toTimeString().split(' ')[0] : null;
    const interviewTime6 = this.state.interviewTime6 ? this.state.interviewTime6.toTimeString().split(' ')[0] : null;

    const interviewStartTime1 = this.state.interviewDate1 ? `${moment(this.state.interviewDate1).format("YYYY-MM-DD")} ${interviewTime1}` : null;
    const interviewEndTime1 = this.state.interviewDate1 ? `${moment(this.state.interviewDate1).format("YYYY-MM-DD")} ${interviewTime2}` : null;
    const interviewStartTime2 = this.state.interviewDate2 ? `${moment(this.state.interviewDate2).format("YYYY-MM-DD")} ${interviewTime3}` : null;
    const interviewEndTime2 = this.state.interviewDate2 ? `${moment(this.state.interviewDate2).format("YYYY-MM-DD")} ${interviewTime4}` : null;
    const interviewStartTime3 = this.state.interviewDate3 ? `${moment(this.state.interviewDate3).format("YYYY-MM-DD")} ${interviewTime5}` : null;
    const interviewEndTime3 = this.state.interviewDate3 ? `${moment(this.state.interviewDate3).format("YYYY-MM-DD")} ${interviewTime6}` : null;
    
    const data = {
        job_detail_id: this.props.location.jobDetailId,
        // job_location_lat: this.lat || this.props.location.jobData.jobLocationLat,
        // job_location_long: this.lang || this.props.location.jobData.jobLocationLong,
        // land_mark: this.state.address.addressLine2 || '',
        // // interview_id: null,
        // address: this.state.address.addressLine1,
        // postal_code: this.state.address.pincode,
        requested_by: this.props.location.jobData.insertedBy,
        user_id: this.props.location.data.user_id,
        interview_status: 1,
    }
    // if(!(this.state.address.addressLine1.includes('Haryana') || this.state.address.addressLine1.includes('Uttar Pradesh') || this.state.address.addressLine1.includes('Delhi'))) {
    //   data['address'] = `${this.state.address.addressLine1}, ${this.state.address.state}`;
    // }
    if(this.props.location.columnName === 'rescheduleInterview') {
        data['interview_id'] = this.props.location.data.interviewId;
    }
    if(this.state.interviewDate1 || this.state.interviewTime1 || this.state.interviewTime2) {
        if(this.state.interviewDate1 && this.state.interviewTime1 && this.state.interviewTime2) {
            
            if(this.state.interviewDate2 || this.state.interviewTime3 || this.state.interviewTime4) {
                if(this.state.interviewDate2 && this.state.interviewTime3 && this.state.interviewTime4) {

                    if(this.state.interviewDate3 || this.state.interviewTime5 || this.state.interviewTime6) { 

                        if(this.state.interviewDate3 && this.state.interviewTime5 && this.state.interviewTime6) {
                            data.interview_start_time = interviewStartTime1;
                            data.interview_end_time = interviewEndTime1;
                            data.interview_start_time2 = interviewStartTime2;
                            data.interview_end_time2 = interviewEndTime2;
                            data.interview_start_time3 = interviewStartTime3;
                            data.interview_end_time3 = interviewEndTime3;
                            // console.log(data)
                            Axios.post(`${urlLinks.baseUrl}/interview/schedule`, qs.stringify(data))
                            .then(response => this.setState({isSubmitting: false}, () => {
                                this.props.history.push('/jobs/pt_ftJob');
                            }))
                        } else {
                          // console.log(data)
                            toast.error(`Fill Interview Slot 3`)
                            this.setState({isSubmitting: false})
                        }
                    } else {
                        data.interview_start_time = interviewStartTime1;
                        data.interview_end_time = interviewEndTime1;
                        data.interview_start_time2 = interviewStartTime2;
                        data.interview_end_time2 = interviewEndTime2;
                        // console.log(data)
                        Axios.post(`${urlLinks.baseUrl}/interview/schedule`, qs.stringify(data))
                        .then(response => this.setState({isSubmitting: false}, () => {
                            this.props.history.push('/jobs/pt_ftJob');
                        }))
                    }

                } else {
                  // console.log(data)
                    toast.error(`Fill Interview Slot 2`)
                    this.setState({isSubmitting: false})
                }
            } else if(this.state.interviewDate3 || this.state.interviewTime5 || this.state.interviewTime6) {
              // console.log(data)
                toast.error(`Please Fill Slot 2 First`)
                this.setState({isSubmitting: false})
            }
             else {
                data.interview_start_time = interviewStartTime1;
                data.interview_end_time = interviewEndTime1;
                // console.log(data)
                Axios.post(`${urlLinks.baseUrl}/interview/schedule`, qs.stringify(data))
                .then(response => this.setState({isSubmitting: false}, () => {
                    this.props.history.push('/jobs/pt_ftJob');
                }))
            }

        } else {
          // console.log(data)
            toast.error(`Fill Interview Slot 1`)
            this.setState({isSubmitting: false})
        }
    } else {
      // console.log(data)
        toast.error(`Please Fill Slot 1`)
        this.setState({isSubmitting: false})
    }
    // console.log(data)
    
  }

  // handlePlaceSelect = () => {
  //   let addressObject = this.autocomplete.getPlace();
  //   const geometry = addressObject.geometry.location;
  //   this.lat = geometry.lat();
  //   this.lang = geometry.lng();
  //   if (utils.checkValidLatLng(this.lat, this.lang)) {
  //     let addressDetails = {
  //       addressLine1: '',
  //       city: '',
  //       state: '',
  //       pincode: ''
  //     };
  //     addressObject.address_components.forEach(element => {
  //       if (element.types.includes('administrative_area_level_1')) {
  //         addressDetails['state'] = element.long_name;
  //       } else if (element.types.includes('administrative_area_level_2')) {
  //         addressDetails['city'] = element.long_name;
  //       } else if (element.types.includes('postal_code')) {
  //         addressDetails['pincode'] = parseInt(element.long_name);
  //       } else if (!element.types.includes('country')) {
  //         addressDetails['addressLine1'] =
  //           (addressDetails['addressLine1']
  //             ? addressDetails['addressLine1']
  //             : '') +
  //           element.long_name +
  //           ', ';
  //       }
  //     }, addressDetails);
  //     addressDetails['addressLine1'] = addressDetails['addressLine1'].replace(
  //       /,\s*$/,
  //       ''
  //     );
  //     addressDetails['city'] = addressDetails['city']
  //       ? addressDetails['city']
  //       : addressDetails['state'];
  //     if (addressDetails.pincode) {
  //       this.setState({ address: addressDetails });
  //     }
  //     if (!addressDetails.pincode) {
  //       Axios.get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.lat},${this.lang}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`
  //       )
  //         .then(response => {
  //           if (response.data.results.length > 0) {
  //             let addressComponent = response.data.results[0].address_components;
  //             addressDetails['pincode'] = parseInt(
  //               addressComponent[addressComponent.length - 1].long_name
  //             );
  //             this.setState({ address: addressDetails });
  //           }
  //         })
  //         .catch(err => {
  //           // console.log(err);
  //           toast.error(`Error While Loading Maps`);
  //         });
  //     }
  //   } else {
  //     toast.error('Currently Non Serviceable Area :(');
  //   }
  // }

  toDateWithOutTimeZone = (date) => {
    let tempTime = date.split(":");
    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    dt.setSeconds(tempTime[2]);
    return dt;
  }

  componentDidMount() {

    if(!this.props.location.jobDetailId || !this.props.location.jobData) {
      this.props.history.push('/jobs/pt_ftJob');
    } else {
      if(this.props.location.interviewId) {
        if(this.props.location.data.interviewStatus === 'Yes') {
          const data = this.props.location.data;
          // const addressDetails = {
          //   addressLine1: data.address,
          //   addressLine2: data.landMark || '',
          //   city: data.address.includes('Haryana') ? 'Gurugram' : data.address.includes('Delhi') ? 'Delhi' : data.address.includes('Uttar Pradesh') ? 'Noida' : 'Other',
          //   state: data.address.includes('Haryana') ? 'Haryana' : data.address.includes('Delhi') ? 'Delhi' : data.address.includes('Uttar Pradesh') ? 'Uttar Pradesh' : 'Other',
          //   pincode: data.postalCode
          // }
          this.setState({
            // address: addressDetails,
            interviewDate1: data.interviewStartTime !== '-' ? new Date(data.interviewStartTime.substring(0, 10).replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) : null,
            interviewDate2: data.interviewStartTime2 !== '-' ? new Date(data.interviewStartTime2.substring(0, 10).replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) : null,
            interviewDate3: data.interviewStartTime3 !== '-' ? new Date(data.interviewStartTime3.substring(0, 10).replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) : null,
            interviewTime1: data.interviewStartTime !== '-' ? this.toDateWithOutTimeZone(data.interviewStartTime.substring(11, 19)) : null,
            interviewTime2: data.interviewEndTime !== '-' ? this.toDateWithOutTimeZone(data.interviewEndTime.substring(11, 19)) : null,
            interviewTime3: data.interviewStartTime2 !== '-' ? this.toDateWithOutTimeZone(data.interviewStartTime2.substring(11, 19)) : null,
            interviewTime4: data.interviewEndTime2 !== '-' ? this.toDateWithOutTimeZone(data.interviewEndTime2.substring(11, 19)) : null,
            interviewTime5: data.interviewStartTime3 !== '-' ? this.toDateWithOutTimeZone(data.interviewStartTime3.substring(11, 19)) : null,
            interviewTime6: data.interviewEndTime3 !== '-' ? this.toDateWithOutTimeZone(data.interviewEndTime3.substring(11, 19)) : null,
          })
        } 
        // else {
        //   const jobData = this.props.location.jobData;
        //   const addressDetails = {
        //     addressLine1: jobData.address,
        //     addressLine2: jobData.landMark || '',
        //     city: jobData.cityId === 71 ? "Delhi" : jobData.cityId === 72 ? "Noida" : jobData.cityId === 73 ? "Gurugram" : jobData.address.includes('Haryana') ? 'Gurugram' : jobData.address.includes('Delhi') ? 'Delhi' : jobData.address.includes('Uttar Pradesh') ? 'Noida' : 'Other',
        //     state: DropDownConstants.idToState[jobData.cityId] ? DropDownConstants.idToState[jobData.cityId] : jobData.address.includes('Haryana') ? 'Haryana' : jobData.address.includes('Delhi') ? 'Delhi' : jobData.address.includes('Uttar Pradesh') ? 'Uttar Pradesh' : 'Other',
        //     pincode: jobData.postalCode
        //   }
        //   this.setState({
        //     address: addressDetails
        //   })
        // }
      } 
      // else {
      //   const jobData = this.props.location.jobData;
      //   const addressDetails = {
      //     addressLine1: jobData.address,
      //     addressLine2: jobData.landMark || '',
      //     city: jobData.cityId === 71 ? "Delhi" : jobData.cityId === 72 ? "Noida" : jobData.cityId === 73 ? "Gurugram" : jobData.address.includes('Haryana') ? 'Gurugram' : jobData.address.includes('Delhi') ? 'Delhi' : jobData.address.includes('Uttar Pradesh') ? 'Noida' : 'Other',
      //     state: DropDownConstants.idToState[jobData.cityId] ? DropDownConstants.idToState[jobData.cityId] : jobData.address.includes('Haryana') ? 'Haryana' : jobData.address.includes('Delhi') ? 'Delhi' : jobData.address.includes('Uttar Pradesh') ? 'Uttar Pradesh' : 'Other',
      //     pincode: jobData.postalCode
      //   }
      //   this.setState({
      //     address: addressDetails
      //   })
      // }
    }
     
      // console.log(this.props.location)
    // this.autocomplete = new window.google.maps.places.Autocomplete(
    //   document.getElementById('google-autocomplete-input', {})
    // );
    // this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }
  
  render() {
    const { classes } = this.props;
    return (
      <>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={4000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        {/*<Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h6' className={classes.textheading}>
              Company Registered Address
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <TextField
              className={classes.text_container}
              id='google-autocomplete-input'
              variant='outlined'
              label='Search Google Maps'
              name='search Address'
              onKeyPress={event =>
                event.key === 'Enter' ? event.preventDefault() : null
              }
              InputProps={{
                className: classes.input,
                inputProps: {
                  className: classes.inputStyle
                }
              }}
              InputLabelProps={{
                className: classes.inputLabel
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.text_container}
              variant='outlined'
              readOnly
              label='Address Line 1'
              name='addressLine1'
              value={this.state.address.addressLine1}
              InputProps={{
                className: classes.input,
                inputProps: {
                  className: classes.inputStyle
                }
              }}
              InputLabelProps={{
                className: classes.inputLabel
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.text_container}
              variant='outlined'
              label='Landmark'
              name='addressLine2'
              value={this.state.address.addressLine2}
              onChange={this.handleLandmarkChange}
              InputProps={{
                className: classes.input,
                inputProps: {
                  className: classes.inputStyle
                }
              }}
              InputLabelProps={{
                className: classes.inputLabel
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.text_container}
              variant='outlined'
              label='City'
              name='city'
              readOnly
              value={this.state.address.city}
              InputProps={{
                className: classes.input
              }}
              InputLabelProps={{
                className: classes.inputLabel
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.text_container}
              variant='outlined'
              label='State'
              name='state'
              readOnly
              value={this.state.address.state}
              InputProps={{
                className: classes.input,
                inputProps: {
                  className: classes.inputStyle
                }
              }}
              InputLabelProps={{
                className: classes.inputLabel
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.text_container}
              variant='outlined'
              type='number'
              label='Pincode'
              name='pincode'
              value={this.state.address.pincode}
              readOnly
              InputProps={{
                className: classes.input,
                inputProps: {
                  className: classes.inputStyle
                }
              }}
              InputLabelProps={{
                className: classes.inputLabel
              }}
            ></TextField>
          </Grid>
        </Grid>*/}
        <Grid container style={{flexGrow: '1'}} spacing={2}>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        // minDate={new Date()}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Interview Date 1"
                        value={this.state.interviewDate1}
                        onChange={event => this.setState({ interviewDate1: event})}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        mode={24}
                        margin="normal"
                        id="time-picker"
                        label="Interview Start Time"
                        value={this.state.interviewTime1}
                        onChange={event => this.setState({ interviewTime1: utils.roundTimeQuarterHour(event)})}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Interview End Time"
                        value={this.state.interviewTime2}
                        onChange={event => this.setState({ interviewTime2: utils.roundTimeQuarterHour(event)})}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
        <Grid container style={{flexGrow: '1'}} spacing={2}>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        // minDate={new Date()}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Interview Date 2"
                        value={this.state.interviewDate2}
                        onChange={event => this.setState({ interviewDate2: event})}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Interview Start Time"
                        value={this.state.interviewTime3}
                        onChange={event => this.setState({ interviewTime3: utils.roundTimeQuarterHour(event)})}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Interview End Time"
                        value={this.state.interviewTime4}
                        onChange={event => this.setState({ interviewTime4: utils.roundTimeQuarterHour(event)})}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
        <Grid container style={{flexGrow: '1'}} spacing={2}>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        // minDate={new Date()}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Interview Date 3"
                        value={this.state.interviewDate3}
                        onChange={event => this.setState({ interviewDate3: event})}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Interview Start Time"
                        value={this.state.interviewTime5}
                        onChange={event => this.setState({ interviewTime5: utils.roundTimeQuarterHour(event)})}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Interview End Time"
                        value={this.state.interviewTime6}
                        onChange={event => this.setState({ interviewTime6: utils.roundTimeQuarterHour(event)})}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
        <Button
          // disabled={this.state.isSubmitting}
          onClick={this.handleInterviewSubmit}
          type='submit'
          variant='contained'
          className={classes.buttonStyle}
          color='primary'
        >
          Submit
        </Button>
      </> 
    );
  }
}

export default withStyles(styles)(AddInterview);