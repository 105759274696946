import React, { Component } from 'react';
import { Button, Typography, Divider, OutlinedInput } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CircleLoader } from 'react-spinners';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ImageUpload from '../../../utils/ImageUploader/ImageUpload';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import Chip from '@material-ui/core/Chip';
import qs from 'querystring';
import 'react-toastify/dist/ReactToastify.css';
import urlLinks from '../../../urlLinks';
import DropDownConstants from '../../../dropDownConstant';
import utilFunctions from '../../../utilMethods';
import BackButton from '../../../utils/BackButton/BackButton';
import { RingLoader } from 'react-spinners';

const styles = theme => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: '70px'
  },
  text_container: {
    // margin: "20px",
    // maxWidth: "250px"
    width: '80%',
    height: '55px'
  },
  buttonStyle: {
    width: '90px',
    margin: 'auto',
    fontSize: '0.8rem'
  },
  input: {
    padding: '10px',
    height: '40px',
    fontSize: '0.8rem'
  },
  inputStyle: {
    padding: '0px',
    height: '40px',
    fontSize: '0.8rem'
  },
  selectInput: {
    padding: '10px',
    height: '0px',
    fontSize: '0.8rem'
  },
  inputLabel: {
    transform: 'translate(13px, 12px) scale(1)',
    fontSize: '0.8rem'
  },
  grid: {
    maxWidth: '800px',
    margin: 'auto'
  },
  textheading: {
    textDecoration: 'underline',
    fontSize: '0.9rem'
  },
  alignFormat: {
    margin: 'auto',
    fontSize: '0.8rem'
  },
  textBoxStyle: {
    height: '35px',
    marginLeft: '10px'
  },
  loaderStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto'
  }
});

class EditEmployer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      industry: {},
      userId: null,
      companyId: null,
      companyPhotosToUpload: [],
      pincode:null,
      isValidPin:true,
      oldBannerList: '',
      companyServices: {'Meals': 'is_2meal', 'Conveyance': 'is_conveyance', 'Room': 'is_rooms', 'PF / ESIC': 'is_pf_esic'},
      isSubmitting: false,
      formData: {
        name: '',
        designation: '',
        contactNumber: '',
        email: '',
        companyDescription: '',
        companyServices: [],
        companyPhotos: [],
        companyName: '',
        brandName: '',
        industry: 0,
        gstNumber: '',
        searchAddress:'',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        pincode: '',
        companyRegistrationDoc: '',
        pancardFileName: ''
      },
      isPageLoading: true
    };
    this.addEmployerSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
      // designation: Yup.string()
      //   .min(2, 'Too Short!')
      //   .max(50, 'Too Long!')
      //   .required('Required'),
      contactNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Invalid Contact Number')
        .required('Contact Number is Required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Required'),
      companyName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
      gstNumber: Yup.string()
        .matches(
          /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/,
          'Invalid Gst Number'
        ),
      addressLine1: Yup.string()
        .min(2, 'Too Short!')
        .max(250, 'Too Long!')
        .required('Required'),
      city: Yup.string()
        .trim()
        .required('Required'),
      state: Yup.string()
        .trim()
        .required('Required'),
      pincode: Yup.string()
        .matches(/^[0-9]{6}$/, 'Invalid Pincode')
        .required('Required')
    });
    this.companyRegistrationDoc = '';
    this.lat = '';
    this.lang = '';
    this.profileImage = '';
    this.profileImageFileName = '';
    this.pancardBlob = '';
    this.autocomplete = {};
    this.formikSetValues = null;
    this.formikValues = {};
    this.industries = DropDownConstants.industry;
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.updateProfileImage = this.updateProfileImage.bind(this);
    this.redirectToEmployerList = this.redirectToEmployerList.bind(this);
    this.profileImageLink = '';
    this.companyRegFileLink = '';
    this.pancardFileLink = '';
  }
  updateProfileImage(image, filename) {
    this.profileImage = image;
    this.profileImageFileName = filename;
  }

  handlePincodeChange =(event) =>{
    let pincode=event.target.value;
    console.log({pincode});
    
    this.setFormikFieldValue("pincode", pincode)
    if(pincode.toString().length===6){
      this.setState({pincode: pincode}, () => this.checkPincode(pincode))
    }
    
  }
  checkPincode =(pincode) =>{
    console.log(this.state.formData);
    
    if(pincode.toString().length===0 || pincode===0){
      this.setState({isValidPin: false})
    }
    else{
      Axios.get(`${urlLinks.baseUrl}tracking/getcityandstate?pincode=${pincode}`)
        .then(response =>{
          if(response.data.response.length!==0){
            this.setState({isValidPin:true})
            let addressDetails = {
              pincode: pincode,
              city:response.data.response[0].district, 
              state:response.data.response[0].stateName
            };
            let newformikValues = Object.assign( {}, this.formikValues, addressDetails);
            this.formikSetValues(newformikValues);
          }
          else{
            this.setState({isValidPin:false})
          }
        })
        .catch(error =>{
          // toast.error("Unable to retrieve city and state.")
        })
    }
  }
  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    const geometry = addressObject.geometry.location;
    this.lat = geometry.lat();
    this.lang = geometry.lng();
    let pincode= 0;
    addressObject.address_components.map(component =>{
        if(component.types.includes("postal_code")){
          pincode=component.long_name;
        }
      })
    let addressDetails = {
      searchAddress: addressObject.formatted_address,
      pincode: pincode,
    };
    let newformikValues = Object.assign( {}, this.formikValues, addressDetails);
    this.formikSetValues(newformikValues);
    this.setState({pincode: pincode}, () => this.checkPincode(pincode))
    
  }

  handleCompanyPhoto = async (file, fileName, fileType, companyPhotos) => {
    let companyPhotosToUpload = [...this.state.companyPhotosToUpload];
    if (!(companyPhotos.length === this.state.companyPhotosToUpload.length+1)) {
      companyPhotosToUpload = [];
    }
    const formData = new FormData();
    formData.append('file', file)
    formData.append('user_id',226)
    await Axios.post(`${urlLinks.baseUrl}/userdocs/getDocLink`, formData,{
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(response => {
      companyPhotosToUpload.push({
        filePath: response.data.response,
        mimeType: fileType
      })
      this.setState({companyPhotosToUpload: companyPhotosToUpload})
    })
  }
  
  async getEmployerDataById(id) {
    const validExpiry = await utilFunctions.checkValidExpiry();
    if(validExpiry) {
      Axios.get(`${urlLinks.baseUrl}${urlLinks.employerUrls.getEmployerDetailById}${id}`)
        .then(response => {
          Axios.get(`${urlLinks.baseUrl}${urlLinks.employerUrls.getEmployerList}?employer_id=${id}`)
            .then(response2 => {
              Axios.get(`${urlLinks.baseUrl}/company/?user_id=${response2?.data?.response?.content[0]?.userId}`)
                .then(response3 => {
                  const data2 = response3?.data?.response?.content[0]
                  const companyPhotos = [];
                  const companyPhotosToUpload = [];
                  if(data2?.banner_list) {
                    JSON.parse(data2?.banner_list).map((el, index) => {
                      companyPhotos.push((index+1).toString());
                      companyPhotosToUpload.push({
                        filePath: el.filePath|| '',
                        mimeType: el.mimeType||''
                      })
                    })
                  }
                  const data = response?.data;
                  this.profileImageLink = data2?.companyLogo
                    ? data2.companyLogo
                    : '';
                  this.companyRegFileLink = data?.response?.companyRegFilePath
                    ? data.response.companyRegFilePath
                    : '';
                  this.pancardFileLink = data?.response?.pancardFilePath
                    ? data.response.pancardFilePath
                    : '';
                  const companyServices = [];
                  if(data2?.is_2meal) {
                    companyServices.push('Meals');
                  }
                  if(data2?.is_conveyance) {
                    companyServices.push('Conveyance');
                  }
                  if(data2?.is_rooms) {
                    companyServices.push('Rooms');
                  }
                  if(data2?.is_pf_esic) {
                    companyServices.push('PF / ESIC');
                  }
                  console.log({response2, data2, data});
                  
                  this.setState({
                    userId: response2?.data?.response?.content[0]?.userId,
                    oldBannerList: data2?.banner_list,
                    companyId: data2?.companyId,
                    companyPhotosToUpload: companyPhotosToUpload||[],
                    formData: Object.assign({}, this.state.formData, {
                      name: data?.response?.contactPerson ? data.response.contactPerson : '',
                      designation: data?.response?.designation
                        ? data.response.designation
                        : '',
                      contactNumber: data?.response?.contactNumber
                        ? data.response.contactNumber
                        : '',
                      companyDescription: data2?.shortDescription,
                      companyServices: companyServices,
                      companyPhotos: [...companyPhotos],
                      email: data?.response?.email ? data.response.email : '',
                      companyName: data?.response?.companyName
                        ? data.response.companyName
                        : '',
                      brandName: data?.response?.brandName ? data.response.brandName : '',
                      industry: data?.response?.industry ? data.response.industry : 0,
                      gstNumber: data?.response?.gstNumber ? data.response.gstNumber : '',
                      searchAddress: response2?.data?.response?.content[0]?.userGoogleLocation ?response2.data.response.content[0].userGoogleLocation:'',
                      addressLine1: data?.response?.addressLine1
                        ? data.response.addressLine1
                        : '',
                      addressLine2: data?.response?.addressLine2
                        ? data.response.addressLine2
                        : '',
                      city: response2?.data?.response?.content[0]?.newCity ? response2.data.response.content[0].newCity : '-',
                      state: response2?.data?.response?.content[0]?.newState ? response2.data.response.content[0].newState : '-',
                      pincode: response2?.data?.response?.content[0]?.pincode ? response2.data.response.content[0].pincode : ''
                    }),
                    isPageLoading: false
                  },  () => this.checkPincode(this.state.formData.pincode));
                })
            })
        })
        .catch(error => {
          toast.error('Could not load page :(');
          // console.log(error);
        });
    }
  }
  redirectToEmployerList() {
    this.props.history.push('/employer/employerLists');
  }

  async componentDidMount() {
    const validExpiry = await utilFunctions.checkValidExpiry();
    if(validExpiry) {
      Axios.get(
        `${urlLinks.baseUrl}/configmaster/?category_sub_type=industry&category_type=job_type`
        ).then(response => {
          const industries = {};
          for (const industry of response.data.response.content) {
            industries[industry.typeDesc] = industry.id;
          }
          this.setState({ industry: industries });
      });
      if (!this.props.location.id) {
        this.redirectToEmployerList();
      } else {
        this.getEmployerDataById(this.props.location.id);
      }
    }
  }

  componentDidUpdate() {
    try {
      if (document.getElementById('google-autocomplete-input')) {
        this.autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById('google-autocomplete-input'),
          {}
        );
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
      }
    } catch (error) {
      toast.error('Unable To Load Maps ');
    }
  }
  render() {
    const { classes } = this.props;

    return this.state.isPageLoading ? (
      <RingLoader
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto'
        }}
      ></RingLoader>
    ) : this.state.isSubmitting ? (
      <CircleLoader
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto'
        }}
        color={'#194D33'}
      ></CircleLoader>
    ) : (
      <div className={classes.root}>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          autoClose={4000}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <BackButton />
        <ImageUpload
          updateProfileImage={this.updateProfileImage}
          imageFileLink={this.profileImageLink}
        ></ImageUpload>
        <Formik
          enableReinitialize
          initialValues={this.state.formData}
          validationSchema={this.addEmployerSchema}
          onSubmit={async values => {

            const companyServicesData = {}
              if(values.companyServices.length > 0) {
                Object.keys(this.state.companyServices).map((el, index) => {
                  values.companyServices.map(el2 => {
                    if(Object.keys(this.state.companyServices).includes(el2)) {
                      companyServicesData[this.state.companyServices[el2]] = 1
                    } else {
                      companyServicesData[this.state.companyServices[el]] = 0
                    }
                  })
                })
              }
              Object.values(this.state.companyServices).map(el => {
                if(!Object.keys(companyServicesData).includes(el)) {
                  companyServicesData[el] = 0
                }
              })
              companyServicesData.short_description = values.companyDescription;
              // console.log(companyServicesData);

            this.setState({ isSubmitting: true });
            let industryId = '';
            for (let value of this.industries) {
              if (value.industry === values.industry) {
                industryId = value.id;
                break;
              }
            }
            // let cityId = '';
            // for (let value of DropDownConstants.cities) {
            //   if (
            //     value.city.toLowerCase().indexOf(values.city.toLowerCase()) !==
            //     -1
            //   ) {
            //     cityId = value.id;
            //     break;
            //   }
            // }
            // cityId = cityId ? cityId : 0;
            let cityId = DropDownConstants.states[values.state] || -1;
            let updatedValues = Object.assign({}, values);
            delete updatedValues.companyServices
            delete updatedValues.companyDescription
            delete updatedValues.companyPhotos
            updatedValues.industry = "0";
            updatedValues.city = cityId;
            updatedValues.countryCode = 91;
            updatedValues.lat = this.lat;
            updatedValues.requestedBy = localStorage.getItem('userId');
            updatedValues.lang = this.lang;
            updatedValues.roleType = 3;
            updatedValues.contactPerson = values.name;
            updatedValues.newPincode = values.pincode;
            updatedValues.userGoogleLocation=values.searchAddress;
            updatedValues.newCity = values.city;
            updatedValues.newState = values.state;
            updatedValues.profileImage = this.profileImage;
            updatedValues.profileImageFileName = this.profileImageFileName;
            updatedValues.companyRegFile = this.companyRegistrationDoc;
            updatedValues.pancardFile = this.pancardBlob;
            updatedValues.id = this.props.location.id;
            // console.log(updatedValues);
            const validExpiry = await utilFunctions.checkValidExpiry();
            if(validExpiry) {
              Axios.post(
                `${urlLinks.baseUrl}${urlLinks.employerUrls.postAddEditEmployer}`,
                updatedValues
              )
                .then(response => {
                  if (response.data.code === 1000) {

                    companyServicesData.requested_by = this.state.userId;
                    companyServicesData.company_id = this.state.companyId;
                    if(this.state.companyPhotosToUpload.length === 0) {
                      companyServicesData.banner_list = this.state.oldBannerList;
                    } else {
                      companyServicesData.banner_list = values.companyPhotos.length > 0 ? JSON.stringify(this.state.companyPhotosToUpload) : "";
                    }
                    toast.success('Employer Edited SuccesFully');
                    // console.log(companyServicesData)
                    Axios.post(`${urlLinks.baseUrl}/company/saveCompanyOtherInfo`, qs.stringify(companyServicesData))
                    .then(response => {
                      this.setState({ isSubmitting: false });
                      toast.success('Company Details Added SuccesFully');
                      this.props.history.push('/employer/employerLists');
                      // setTimeout(window.location.reload(), 4000);
                    })
                    .catch(err => {
                      this.setState({ isSubmitting: false });
                      setTimeout(window.location.reload(), 4000);
                      toast.error('Error Adding Company Details');
                    })
                  } else {
                    throw new Error(response.data.response);
                  }
                })
                .catch(error => {
                  this.setState({ isSubmitting: false });
                  setTimeout(window.location.reload(), 4000);
                  toast.error(error.message);
                });
              }
          }}
        >
          {props => {
            // console.log(props);
            const {
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              setFieldTouched,
              setValues
            } = props;
            this.formikSetValues = setValues;
            this.formikValues = values;
              this.setFormikFieldValue=setFieldValue
            return (
              <Form className={classes.form}>
                <Grid container spacing={2} className={classes.grid}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h6' className={classes.textheading}>
                      Contact Person Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.name && touched.name}
                      label='Name'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      helperText={errors.name && touched.name && errors.name}
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.designation && touched.designation}
                      label='Designation'
                      name='designation'
                      value={values.designation}
                      onChange={handleChange}
                      helperText={
                        errors.designation &&
                        touched.designation &&
                        errors.designation
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      type='number'
                      disabled={values.contactNumber ? true : false}
                      error={errors.contactNumber && touched.contactNumber}
                      label='Contact Number'
                      name='contactNumber'
                      value={values.contactNumber}
                      onChange={handleChange}
                      helperText={
                        errors.contactNumber &&
                        touched.contactNumber &&
                        errors.contactNumber
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.email && touched.email}
                      label='Email Id'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      helperText={errors.email && touched.email && errors.email}
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant='h6' className={classes.textheading}>
                      Company Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.companyName && touched.companyName}
                      label='Company Name'
                      name='companyName'
                      value={values.companyName}
                      onChange={handleChange}
                      helperText={
                        errors.companyName &&
                        touched.companyName &&
                        errors.companyName
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      label='Brand Name'
                      name='brandName'
                      value={values.brandName}
                      onChange={handleChange}
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      select
                      error={errors.industry && touched.industry}
                      label='Industry'
                      name='industry'
                      value={values.industry}
                      onChange={handleChange}
                      helperText={
                        errors.industry && touched.industry && errors.industry
                      }
                      InputProps={{
                        className: classes.input
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    >
                      {' '}
                      {Object.keys(this.state.industry).map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.gstNumber && touched.gstNumber}
                      label='Gst Number'
                      name='gstNumber'
                      value={values.gstNumber}
                      onChange={handleChange}
                      helperText={
                        errors.gstNumber &&
                        touched.gstNumber &&
                        errors.gstNumber
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      style={{width: '90%'}}
                      id="outlined-multiline-static"
                      label='Company Description'
                      multiline
                      value={values.companyDescription}
                      onChange={handleChange}
                      rows="3"
                      name='companyDescription'
                      variant="outlined"
                    />
                    {/* <TextField
                      className={classes.text_container}
                      multiline={true}
                      rows={3}
                      variant='outlined'
                      error={errors.companyDescription && touched.companyDescription}
                      label='Company Description'
                      name='companyDescription'
                      value={values.companyDescription}
                      onChange={handleChange}
                      helperText={
                        errors.companyDescription &&
                        touched.companyDescription &&
                        errors.companyDescription
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField> */}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                      <Autocomplete
                        multiple
                        name='companyServices'
                        onChange={(event, value) => {
                          setFieldValue('companyServices', value);
                        }}
                        options={Object.keys(this.state.companyServices)}
                        getOptionLabel={option => option}
                        defaultValue={values.companyServices}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              variant='standard'
                              error={Boolean(
                                errors.companyServices && touched.companyServices
                              )}
                              style={{ width: '200px' }}
                              label={'Company Services'}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid style={{marginLeft: '-25px', marginRight: '25px'}} item xs={12} sm={3}>
                    <Autocomplete
                        multiple
                        // disableClearable
                        name='companyPhotos'
                        onChange={(event, value) => {
                          setFieldValue('companyPhotos', value);
                        }}
                        defaultValue={values.companyPhotos}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip label={option} {...getTagProps({ index })} disabled />
                          ))
                        }
                        renderInput={params => {
                          return (
                            <TextField
                              disabled
                              {...params}
                              variant='standard'
                              error={Boolean(
                                errors.companyPhotos && touched.companyPhotos
                              )}
                              style={{ width: '200px' }}
                              label={'Company Photos'}
                            />

                          );
                        }}
                      />
                    </Grid>
                    <Grid style={{marginTop: '10px'}} item xs={12} sm={3}>
                      <Button
                        variant='contained'
                        component='label'
                        disabled={values.companyPhotos.length > 3 ? true : false}
                        className={classes.alignFormat}
                      >
                        Browse
                        <input
                          type='file'
                          accept='image/png, image/jpeg'
                          style={{ display: 'none' }}
                          onChange={event => {
                            const reader = new FileReader();
                            const file = event.target.files[0];
                            const fileName = event.target.files[0].name;
                            const fileType = event.target.files[0].type;
                            values.companyPhotos.push(fileName)
                            this.handleCompanyPhoto(file, fileName, fileType, values.companyPhotos);
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                              setFieldValue(
                                'companyPhotos',
                                values.companyPhotos
                              );
                              // setFieldTouched(
                              //   'companyRegistrationDoc',
                              //   true,
                              //   false
                              // );
                            };
                          }}
                        />
                      </Button>
                    </Grid>
                  <Grid item sm={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant='h6' className={classes.textheading}>
                      Company Registered Address
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <TextField
                      className={classes.text_container}
                      id='google-autocomplete-input'
                      variant='outlined'
                      label='Search Google Maps'
                      name='searchAddress'
                      value={values.searchAddress}
                      onChange={handleChange}
                      onKeyPress={event =>
                        event.key === 'Enter' ? event.preventDefault() : null
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.addressLine1 && touched.addressLine1}
                      onChange={handleChange}
                      label='Exact address'
                      name='addressLine1'
                      value={values.addressLine1}
                      helperText={
                        errors.addressLine1 &&
                        touched.addressLine1 &&
                        errors.addressLine1
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.addressLine2 && touched.addressLine2}
                      label='Landmark'
                      name='addressLine2'
                      value={values.addressLine2}
                      onChange={handleChange}
                      helperText={
                        errors.addressLine2 &&
                        touched.addressLine2 &&
                        errors.addressLine2
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      type='number'
                      error={(errors.pincode && touched.pincode)|| !this.state.isValidPin}
                      label='Pincode'
                      name='pincode'
                      disabled={this.state.isValidPin}
                      value={values.pincode}
                      onChange={event => this.handlePincodeChange(event)}
                      helperText={
                        (errors.pincode && touched.pincode) || (!this.state.isValidPin && "Please enter a valid pin number.")
                      }
                      InputProps={{
                        className: classes.input,
                        inputProps: {
                          className: classes.inputStyle
                        }
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.text_container}
                      variant='outlined'
                      error={errors.city && touched.city}
                      label='City'
                      name='city'
                      disabled
                      value={values.city}
                      helperText={
                        errors.city && touched.city && errors.city
                      }
                      InputProps={{
                        className: classes.input
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        error={errors.state && touched.state}
                        label='State'
                        name='state'
                        disabled
                        value={values.state}
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h6' className={classes.textheading}>
                      Upload Verification Details
                    </Typography>
                  </Grid>

                  <Grid item xs={3} style={{ alignSelf: 'center' }}>
                    <Typography style={{ fontSize: '0.9rem' }}>
                      Company Registration Document:
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={this.companyRegFileLink ? false : true}
                      onClick={() => {
                        utilFunctions.downLoadFile(
                          this.companyRegFileLink,
                          'RegFile'
                        );
                      }}
                    >
                      Download
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <OutlinedInput
                      name='companyRegistrationDoc'
                      value={values.companyRegistrationDoc}
                      error={
                        errors.companyRegistrationDoc &&
                        touched.companyRegistrationDoc
                      }
                      className={classes.textBoxStyle}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant='contained'
                      component='label'
                      className={classes.alignFormat}
                    >
                      Browse
                      <input
                        type='file'
                        accept='application/pdf'
                        style={{ display: 'none' }}
                        onChange={event => {
                          const reader = new FileReader();
                          const file = event.target.files[0];
                          reader.readAsDataURL(file);
                          reader.onload = () => {
                            this.companyRegistrationDoc = reader.result;
                            setFieldValue('companyRegistrationDoc', file.name);
                            setFieldTouched(
                              'companyRegistrationDoc',
                              true,
                              false
                            );
                          };
                        }}
                      />
                    </Button>
                  </Grid>

                  <Grid item xs={3} style={{ alignSelf: 'center' }}>
                    <Typography style={{ fontSize: '0.9rem' }}>
                      Pan Card:
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={this.pancardFileLink ? false : true}
                      onClick={() => {
                        utilFunctions.downLoadFile(
                          this.pancardFileLink,
                          'panCard'
                        );
                      }}
                    >
                      Download
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <OutlinedInput
                      name='pancardFileName'
                      value={values.pancardFileName}
                      error={errors.pancardFileName && touched.pancardFileName}
                      className={classes.textBoxStyle}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant='contained'
                      component='label'
                      className={classes.alignFormat}
                    >
                      Browse
                      <input
                        type='file'
                        accept='image/png, image/jpeg'
                        style={{ display: 'none' }}
                        onChange={event => {
                          const reader = new FileReader();
                          const file = event.target.files[0];
                          reader.readAsDataURL(file);
                          reader.onload = () => {
                            this.pancardBlob = reader.result;
                            setFieldValue('pancardFileName', file.name);
                            setFieldTouched('pancardFileName', true, false);
                          };
                        }}
                      />
                    </Button>
                  </Grid>

                  <Button
                    disabled={this.state.isSubmitting}
                    type='submit'
                    variant='contained'
                    className={classes.buttonStyle}
                    color='primary'
                  >
                    Submit
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withStyles(styles)(EditEmployer);
