import React from 'react';
import { MdComment,MdModeEdit,MdLibraryAdd} from "react-icons/md";
import { FaEye} from "react-icons/fa";

const employerScreeningUtils={
    title:"Employer Screening",
    screeningListAction:
        [{
            id:1,
            name:"View Rating History",
            subIcon:<FaEye className="fitIcon"/>,
            route:"/employer/screening"
        },
        {
            id:2,
            name:"UpdateStatus",
            subIcon:<MdModeEdit className="fitIcon"/>,
            route:"/employer/screening"
        }
        ,
        {
            id:3,
            name:"Add Breach",
            subIcon:<MdLibraryAdd className="fitIcon"/>,
            route:"/employer/screening"
        },
        {
            id:4,
            name:"Mark Reviewed",
            subIcon:<MdComment className="fitIcon"/>,
            route:"/employer/screening"
        }
    ],
 screeningFilterList:[
     {status:["Active","Suspended","Deactivated"],id:"1",name:"status",label:"Status",default:[]},

 ]

    
}

export default employerScreeningUtils