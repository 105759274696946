import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';


const PrivateComponent = (props) => {
  return (
    <AuthContext.Consumer>
      {
        (context) => {
          const { isAuthorised } = context;
          return (
            <Fragment>
              {isAuthorised ? props.children : <Redirect to="/Login" />}
            </Fragment>)

        }
      }

    </AuthContext.Consumer>

  );
}


export default PrivateComponent