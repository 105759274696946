import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { JsonToExcel } from 'react-json-excel';
import Axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';
import {
  Button,
  Toolbar,
  Typography,
  Checkbox,
  TablePagination
} from '@material-ui/core';
// import CustomPopover from '../customPopOver/customPopover';
import { ScaleLoader } from 'react-spinners';
import urlLinks from '../../urlLinks';

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  title: {
    flex: '1 1 100%'
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  colvisible: {
    display: 'table-cell'
  },
  colhide: {
    display: 'none'
  },
  tabcell: {
    padding: '2px 10px',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap'
  },
  notificationButton: {
    marginBottom: '0%',
    marginTop: '1%',
    marginLeft: '2%'
  }
});
class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      data: props.data,
      payWorkerIds: []
    };
    this.tableLoader = (
      <TableRow style={{ height: '300px', position: 'relative' }}>
        <TableCell colSpan={100}>
          <ScaleLoader
            color={'#3F75B3'}
            css={{
              textAlign: 'center'
            }}
          ></ScaleLoader>
        </TableCell>
      </TableRow>
    );
    this.checkBoxesVisible = true;
    this.numericIndex = false;
    this.headerChecked = false;
    this.getRoWData = this.getRoWData.bind(this);
    this.getTableCellElement = this.getTableCellElement.bind(this);
    // this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClickOnRow = this.handleClickOnRow.bind(this);
    this.checkRowIsSelected = this.checkRowIsSelected.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  getRoWData(data, cols, classes) {
    const rowData = data.map((row, index) => {
      const isItemSelected = this.checkRowIsSelected(row[this.props.name]);
      return (
        <TableRow
          key={row[this.props.name] ? row[this.props.name] : index + 1}
          selected={isItemSelected}
          style={
            index % 2 ? { background: '#f2f2f2' } : { background: 'white' }
          }
        >
          {this.getTableCellElement(row, cols)}

          <TableCell
            padding='checkbox'
            className={
              this.checkBoxesVisible ? classes.colvisible : classes.colhide
            }
          >
            {this.props.isPaid.includes(row.transactionId) ? (
              <Checkbox
                checked={isItemSelected}
                onChange={event => {
                  this.handleClickOnRow(event, row[this.props.name]);
                }}
              />
            ) : null}
          </TableCell>

          <TableCell
            className={`${
              this.numericIndex ? classes.colvisible : classes.colhide
            } ${classes.tabcell}`}
          >
            {index + 1}
          </TableCell>
        </TableRow>
      );
    });
    return rowData;
  }

  checkRowIsSelected(id) {
    return this.state.selectedRows.indexOf(id) !== -1;
  }

  getTableHeaderCols(cols) {
    const classes = this.props.classes;
    const colData = cols.map(col => {
      return (
        <TableCell
          key={col.id}
          align='center'
          className={`${
            col.isColumnVisible ? classes.colvisible : classes.colhide
          } ${classes.tabcell}`}
        >
          {col.label}
        </TableCell>
      );
    });
    return colData;
  }
  getTableCellElement(row, cols) {
    const classes = this.props.classes;
    const lastColumn = cols.length - 1;
    const Tablecells = cols.map((column, index) => {
      return index !== lastColumn ? (
        <TableCell
          key={column.id}
          align='center'
          className={`${
            column.isColumnVisible ? classes.colvisible : classes.colhide
          } ${classes.tabcell}`}
        >
          {row[column.name]}
        </TableCell>
      ) : null;

      // <TableCell
      //   key={column.id}
      //   className={`${
      //     column.isColumnVisible ? classes.colvisible : classes.colhide
      //   } ${classes.tabcell}`}
      // >
      //   {column.isColumnVisible ? (
      //     <CustomPopover
      //       id={row[this.props.name]}
      //       links={this.props.links}
      //     ></CustomPopover>
      //   ) : null}
      // </TableCell>
    });

    return Tablecells;
  }

  // static getDerivedStateFromProps(nextProps, prevState) {

  //   return ({
  //     data: nextProps.data,
  //     cols: nextProps.columns
  //   }) // <- this is setState equivalent

  // }

  handleSelectAll(event) {
    const target = event.target;
    this.headerChecked = target.checked;
    if (target.checked) {
      const newRows = this.props.data.map(row => row[this.props.name]);
      this.setState({
        selectedRows: newRows
      });
    } else {
      this.setState({
        selectedRows: []
      });
    }
  }

  handleClickOnRow(event, id) {
    let selected = this.state.selectedRows;
    this.props.data.map(el => {
      if (el.transactionId === id) {
        this.state.payWorkerIds.push(el.jobDetailsId);
      }
    });
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.headerChecked =
      newSelected.length === this.props.data.length ? true : false;
    this.setState({
      selectedRows: newSelected
    });
  }

  handleChangePage(nextPage) {
    this.props.tablePagination.changePage(
      this.props.tablePagination.rowsPerPage,
      nextPage
    );
  }

  handleChangeRowsPerPage(event) {
    const newrowsPerPage = parseInt(event.target.value, 10);
    if (newrowsPerPage !== this.props.tablePagination.rowsPerPage) {
      this.props.tablePagination.changePage(newrowsPerPage, 0);
    }
  }

  payWorker = event => {
    event.preventDefault();
    if (this.state.payWorkerIds.length > 0) {
      Axios.post(
        `${
          urlLinks.baseUrl
        }/transactions/worker/markAsPaid?job_details_ids=${this.state.payWorkerIds.join(
          ','
        )}`
      )
        .then(response => {
          toast.success(
            `Transactions Cleared For Job Detail Ids : ${this.state.payWorkerIds.join(
              ','
            )}`
          );
          window.location.reload(false);
        })
        .catch(err => toast.error(err));
    } else {
      toast.error('Select Transactions To Clear');
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    this.setState({ data: data });
  }

  render() {
    const classes = this.props.classes;
    this.checkBoxesVisible =
      this.props.isCheckBoxesVisible === undefined
        ? this.checkBoxesVisible
        : this.props.isCheckBoxesVisible;
    this.numericIndex =
      this.props.numericIndex === undefined
        ? this.numericIndex
        : this.props.numericIndex;
    const colms = this.getTableHeaderCols(this.props.columns);
    const rowData = this.getRoWData(
      this.props.data,
      this.props.columns,
      classes
    );
    return (
      <>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={4000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <Paper className={classes.root} style={{ width: '100%' }}>
          <Toolbar className={classes.toolbar}>
            <Typography variant='h6' id='tableTitle' className={classes.title}>
              Finance Transactions
            </Typography>
          </Toolbar>
          <Table
            className={classes.table}
            stickyHeader
            aria-label='sticky table'
          >
            <TableHead>
              <TableRow style={{ backgroundColor: '#A9A9A9' }}>
                {/* <TableCell
                  padding='checkbox'
                  className={`${
                    this.checkBoxesVisible ? classes.colvisible : classes.colhide
                  } ${classes.tabcell}`}
                >
                  <Checkbox
                    checked={this.headerChecked}
                    onChange={this.handleSelectAll}
                  />
                </TableCell> 
                
                <TableCell
                  className={`${
                    this.numericIndex ? classes.colvisible : classes.colhide
                  } ${classes.tabcell}`}
                >
                  No.
                </TableCell>
                <TableCell>Paid</TableCell> */}
                {colms}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.tablePagination.tableUpdating
                ? this.tableLoader
                : rowData}
            </TableBody>
          </Table>

          <Button
            className={classes.notificationButton}
            variant='contained'
            color='primary'
            onClick={event => this.payWorker(event)}
          >
            Approved Changes
          </Button>

          <Button
            className={classes.notificationButton}
            variant='contained'
            color='primary'
          >
            <CSVLink
              data={this.props.data}
              filename={`${this.props.title}.csv`}
              className='btn btn-primary'
              target='_blank'
              style={{
                color: 'inherit',
                textDecoration: 'none',
                fontWeight: '500'
              }}
            >
              Export To Excel
            </CSVLink>
          </Button>

          <TablePagination
            rowsPerPageOptions={this.props.tablePagination.rowsOptions}
            component='div'
            count={this.props.tablePagination.count}
            rowsPerPage={this.props.tablePagination.rowsPerPage}
            page={this.props.tablePagination.currentPage}
            onChangePage={(event, nextPage) => {
              this.handleChangePage(nextPage);
            }}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  }
}
export default withStyles(useStyles)(CustomTable);
