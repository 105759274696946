import { Button } from '@material-ui/core';
import React , {useState, useEffect} from 'react'
import urlLinks from '../../../urlLinks'
import Axios from 'axios'
import qs from 'querystring';

const CVuploadDownload =(props) =>{
    const {row, showToast, updateMyData} =props
    const inputEl = React.useRef(null);
    const handleInvoiceUpload =(e) =>{
        console.log({row})
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.readAsDataURL(file);
        let fileData=null
        let fileName=null
        reader.onload = () => {
            fileData = reader.result
            fileName = file.name
            let postData={
                file_name: file.name,
                file: reader.result,
                invoice_id: row.original.invoice_id,
                inserted_by: localStorage.getItem("userId")
            }
            // Axios.post(`${urlLinks.baseUrl}/sales/uploadInvoice?file_name=${fileName}&file=${fileData}&invoice_id=${row.original.invoice_id}&inserted_by=${localStorage.getItem("userId")}`)
            Axios.post(`${urlLinks.baseUrl}/sales/uploadInvoice`, qs.stringify(postData))
                .then( response =>{
                    showToast("success", "Invoice uploaded successfully");
                    updateMyData();
                })
                .catch(error =>{
                    showToast("error", "Invoice upload failed");
                })
        }    
    }

    return (
        row.original.invoice_id && row.original.invoiceNo&& <div>
                {row.original.file_path && <a
                    href={row.original.file_path}
                    color="transparent"
                    target="_blank"
                >
                    <Button 
                        className="padding0 textCapitalize linkButton fontBold600" 
                        disabled={!row.original.file_path}  
                        style={{marginLeft: "8px", marginTop: "-2px"}}
                    >
                        Download
                    </Button>
                </a>}
                <input
                    accept="image/*,.doc, .docx, .txt,.pdf"
                    style={{display: "none"}}
                    id={row.original.workerId}
                    onChange={handleInvoiceUpload}
                    // multiple
                    type="file"
                    ref={inputEl}
                />
                <label htmlFor={row.original.workerId}>
                    {row.original.invoiceNo && <Button className="linkButton textCapitalize fontBold600" style={{paddingBottom: "0px", paddingTop: "0px", marginTop: "-2px"}} component="span">
                        {row.original.file_path?"Update":"Upload"}
                    </Button>}
                </label>
        </div>
    )
}

export default CVuploadDownload;