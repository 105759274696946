import React from "react";
import { Field } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from './FormikRadioGroup'

import {TextField, Select, MenuItem, FormControl, InputLabel, Grid, InputAdornment} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FormThirdStep = formikProps => {
  const classes = useStyles();
  const [companyName, setCompanyName] = React.useState('');
  const { errors, touched, setFieldValue, values, handleChange, managerList } = formikProps;
   
  const handlePayoutAmount =(event) =>{
    if(event.target.value){
      if(!values.payoutCheck)
      setFieldValue("payoutCheck", true)
    }
    else{
      setFieldValue("payoutCheck", false)
    }
    setFieldValue([event.target.name], event.target.value)
  }
  console.log({values})
  return (
    <div className="form">
      <Grid container spacing={0}>
        <div className="ReqExp">
          <span className="formHeadings">HR Details</span>
          <span className="smallHeading" style={{marginTop: "24px"}}>For Candidates</span>
        </div>
        <Grid container spacing={0}>
          <Grid item sm={6}>
            <Field
              name="pocCandidateName"
              label="POC name"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
            />              
          </Grid>
          <Grid item sm={6}>
            <Field
              name="pocContactNumber"
              label="POC contact number"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              error={touched.pocContactNumber && errors.pocContactNumber}
              helperText={touched.pocContactNumber && errors.pocContactNumber}
            />              
          </Grid>
        </Grid>
        <span className="smallHeading">For OkayGo account manager</span>
        <Grid container spacing={0}>
          <Grid item sm={6}>
            <Field
              name="pocName"
              label="POC name"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
            />              
          </Grid>
          <Grid item sm={6}>
            <Field
              name="pocNumber"
              label="POC number"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              error={touched.pocNumber && errors.pocNumber}
              helperText={touched.pocNumber && errors.pocNumber}
            />              
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={6}>
            <Field
              name="pocEmailID"
              label="POC email ID"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              error={touched.pocEmailID && errors.pocEmailID}
              helperText={touched.pocEmailID && errors.pocEmailID}
            />            
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={6}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
              <InputLabel id="account-manager">Account manager *</InputLabel>
              <Select
                name='accountManager'
                value={values.accountManager}
                label= "Account manager *"
                onChange={handleChange}
                error={touched.accountManager && errors.accountManager}
                helperText={touched.accountManager && errors.accountManager}
              >
                <MenuItem value="" disabled>
                  <em>Account manager</em>
                </MenuItem>
                {managerList &&
                  managerList.map( m => 
                    <MenuItem value={m.userId}>{`${m.firstName} ${m.lastName?m.lastName:null}`}</MenuItem>
                  )
                }
              </Select>
            </FormControl>            
          </Grid>
        </Grid>
        <span className="formHeadings">Instructions</span>
        <Grid container spacing={0}>
          <Grid item sm={12}>
            <Field
              name="instructionsForRecruiter"
              label="Instructions for recruiter *"
              variant= "outlined"
              fullWidth={true}
              as= {TextField}
              
              error={touched.instructionsForRecruiter && errors.instructionsForRecruiter}
              helperText={touched.instructionsForRecruiter && errors.instructionsForRecruiter}
              multiline= {true}
              rows={10}
              inputProps={{
                maxlength: 1000
              }}
              // helperText={`${values.instructionsForRecruiter.length}/1000`}
            />        
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={12}>
            <Field
              name="instructionsForCandidate"
              label="Instructions for candidate"
              variant= "outlined"
              fullWidth={true}
              as= {TextField}
              multiline= {true}
              rows={10}
              inputProps={{
                maxlength: 1000
              }}
              // helperText={`${values.instructionsForCandidate.length}/1000`}
            />        
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={12}>
            <Field
              name="recruiterPitch"
              label="Recruiter's pitch"
              variant= "outlined"
              fullWidth={true}
              as= {TextField}
              multiline= {true}
              rows={10}
              inputProps={{
                maxlength: 1000
              }}
              // helperText={`${values.recruiterPitch.length}/1000`}
            />        
          </Grid>
        </Grid>
        <span className="formHeadings">Payout</span>
        <Grid container spacing={0}>
          <Grid item sm={6}>
            <Field
              name="recruiterPayoutAmount"
              label="Recruiter Payout (for OkayGo data)"
              variant= "outlined"
              onChange={handlePayoutAmount}
              fullWidth={true}
              as={TextField}
              error={touched.recruiterPayoutAmount && errors.recruiterPayoutAmount}
              helperText={touched.recruiterPayoutAmount && errors.recruiterPayoutAmount}
            />              
          </Grid>
          <Grid container spacing={0}>
            <Grid item sm={6}>
              <Field
                name="recruiterPayoutDetails"
                label="Recruiter payout details" 
                variant= "outlined"
                fullWidth={true}
                as={TextField}
                 multiline= {true}
                rows={10}
               
              />              
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={6}>
            <Field
              name="leaderPayoutAmount"
              label="Recruiter payout (for self data)"
              onChange={handlePayoutAmount}
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              error={touched.leaderPayoutAmount && errors.leaderPayoutAmount}
              helperText={touched.leaderPayoutAmount && errors.leaderPayoutAmount}
            />              
          </Grid>
          <Grid container spacing={0}>
            <Grid item sm={6}>
              <Field
                name="leaderPayoutDetails"
                label="Leader payout details"
                variant= "outlined"
                fullWidth={true}
                as={TextField}
                multiline= {true}
                rows={10}
              />              
            </Grid>
          </Grid>
          
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={6}>
            <Field
              name="referralAmount"
              label="Referral amount"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              error={touched.referralAmount && errors.referralAmount}
              helperText={touched.referralAmount && errors.referralAmount}
            />              
          </Grid>
        </Grid>
        <Grid container  spacing={0}>
          <span className="smallHeading">OkayGo comission term *</span>
          <Grid container spacing={0}>
            <Field
              name="radioGroup"
              options={['Fixed', 'Variable']}
              component={RadioGroup}
            />
          </Grid>
          <Grid container spacing={0}>
            <Grid item sm={4}>
              <Field
                name="commisionPerJoining"
                label="OkayGo commission per joining *"
                variant= "outlined"
                fullWidth={true}
                as={TextField}
                InputProps={{
                  endAdornment:(<InputAdornment position="end">{values.radioGroup==="Fixed"?"in ₹": "in %"}</InputAdornment>)
                }}
                error={touched.commisionPerJoining && errors.commisionPerJoining}
                helperText={touched.commisionPerJoining && errors.commisionPerJoining}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={4}>
            <Field
              name="invoicingPeriod"
              label="OkayGo invoicing period *"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              InputProps={{
                endAdornment:(<InputAdornment position="end">days</InputAdornment>)
              }}
              error={touched.invoicingPeriod && errors.invoicingPeriod}
              helperText={touched.invoicingPeriod && errors.invoicingPeriod}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={4}>
            <Field
              name="replacementPeriod"
              label="OkayGo replacement period *"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              InputProps={{
                endAdornment:(<InputAdornment position="end">days</InputAdornment>)
              }}
              error={touched.replacementPeriod && errors.replacementPeriod}
              helperText={touched.replacementPeriod && errors.replacementPeriod}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} class="ErrorDiv">
          {(touched.pocContactNumber && errors.pocContactNumber)?<span>{`* ${errors.pocContactNumber}`}</span>:null}
          {(touched.pocNumber && errors.pocNumber)?<span>{`* ${errors.pocNumber}`}</span>:null}
          {(touched.pocEmailID && errors.pocEmailID)?<span>{`* ${errors.pocEmailID}`}</span>:null}
          {(touched.accountManager && errors.accountManager)?<span>{`* ${errors.accountManager}`}</span>:null}
          {(touched.instructionsForRecruiter && errors.instructionsForRecruiter)?<span>{`* ${errors.instructionsForRecruiter}`}</span>:null}
          {(touched.radioGroup && errors.radioGroup)?<span>{`* ${errors.radioGroup}`}</span>:null}
          {(touched.commisionPerJoining && errors.commisionPerJoining)?<span>{`* ${errors.commisionPerJoining}`}</span>:null}
          {(touched.invoicingPeriod && errors.invoicingPeriod)?<span>{`* ${errors.invoicingPeriod}`}</span>:null}
          {(touched.replacementPeriod && errors.replacementPeriod)?<span>{`* ${errors.replacementPeriod}`}</span>:null}
        </Grid>
      </Grid>
    </div>
  );
};
