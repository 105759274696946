import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import Login from '../Login/Login';
import PrivateComponent from '../PrivateComponent/PrivateComponent';
import AuthContextProvider from '../../context/AuthContext';
import Axios from 'axios';

// console.log(new Date().getTime() / 1000 > localStorage.getItem('expiry'))

// Axios.interceptors.response.use(response => {
//   if(response.data.code === 1005 && response.data.message === "Sorry, your provided token information expired or not exists.") {
//     fetch("https://cognito-idp.ap-south-1.amazonaws.com/", {
//     headers: {
//       "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
//       "Content-Type": "application/x-amz-json-1.1",
//     },
//     mode: 'cors',
//     cache: 'no-cache',
//     method: 'POST',
//     body: JSON.stringify({
//       ClientId: "6smf3dhff7pt8ede6rm51insed",
//       AuthFlow: 'REFRESH_TOKEN_AUTH',
//       AuthParameters: {
//         REFRESH_TOKEN: localStorage.getItem('refreshToken'),
//         //SECRET_HASH: "your_secret", // In case you have configured client secret
//       }
//     }),
//   }).then((res) => {
//     res.json().then(data => {
//       localStorage.setItem('tokenid', data.AuthenticationResult.AccessToken);
//       localStorage.setItem('expiry', ((new Date().getTime() / 1000) + data.AuthenticationResult.ExpiresIn));
//       Axios.defaults.headers.common['Authorization'] = localStorage.getItem('tokenid');
//       window.location.reload()
//     }); // this will give jwt id and access tokens
//   });
//   } else {
//     return response;
//   }
// }, err => {
//   console.log(err)
//   if(err.status === 1005) {
//     fetch("https://cognito-idp.ap-south-1.amazonaws.com/", {
//     headers: {
//       "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
//       "Content-Type": "application/x-amz-json-1.1",
//     },
//     mode: 'cors',
//     cache: 'no-cache',
//     method: 'POST',
//     body: JSON.stringify({
//       ClientId: "6smf3dhff7pt8ede6rm51insed",
//       AuthFlow: 'REFRESH_TOKEN_AUTH',
//       AuthParameters: {
//         REFRESH_TOKEN: localStorage.getItem('refreshToken'),
//         //SECRET_HASH: "your_secret", // In case you have configured client secret
//       }
//     }),
//   }).then((res) => {
//     res.json().then(data => {
//       localStorage.setItem('tokenid', data.AuthenticationResult.AccessToken);
//       localStorage.setItem('expiry', ((new Date().getTime() / 1000) + data.AuthenticationResult.ExpiresIn));
//       Axios.defaults.headers.common['Authorization'] = localStorage.getItem('tokenid');
//       window.location.reload()
//     }); // this will give jwt id and access tokens
//   });
//   }
// })

// if (new Date().getTime() / 1000 > localStorage.getItem('expiry')) {
//   fetch("https://cognito-idp.ap-south-1.amazonaws.com/", {
//     headers: {
//       "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
//       "Content-Type": "application/x-amz-json-1.1",
//     },
//     mode: 'cors',
//     cache: 'no-cache',
//     method: 'POST',
//     body: JSON.stringify({
//       ClientId: "6smf3dhff7pt8ede6rm51insed",
//       AuthFlow: 'REFRESH_TOKEN_AUTH',
//       AuthParameters: {
//         REFRESH_TOKEN: localStorage.getItem('refreshToken'),
//         //SECRET_HASH: "your_secret", // In case you have configured client secret
//       }
//     }),
//   }).then((res) => {
//     res.json().then(data => {
//       localStorage.setItem('tokenid', data.AuthenticationResult.AccessToken);
//       localStorage.setItem('expiry', ((new Date().getTime() / 1000) + data.AuthenticationResult.ExpiresIn));
//       // console.log(data.AuthenticationResult.AccessToken)
//       Axios.defaults.headers.common['Authorization'] = localStorage.getItem('tokenid');
//     }); // this will give jwt id and access tokens
//   });
// }


Axios.defaults.headers.common['Authorization'] = localStorage.getItem('tokenid');
Axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';

function App() {
  return (
    <Router>
      <Switch>
        <AuthContextProvider>
          <Route exact path='/Login' component={Login}></Route>
          <PrivateComponent>
            {<Route path='/' component={Home}></Route>}
          </PrivateComponent>
        </AuthContextProvider>
      </Switch>
    </Router>
  );
}

export default App;
