// React Imports
import React, { Component } from 'react';
import JobCategory from './jobCategory';
// Module Imports
import AvailableTime from '../../../utils/AvailableTimeComponent/AvailableTime';
import { Formik, Form, FieldArray } from 'formik';
import utilFunctions from '../../../utilMethods';
import qs from 'querystring';
// const qs = require('querystring');
import Experience from '../../../utils/Experience/experience';
import BackButton from '../../../utils/BackButton/BackButton';
import { CircleLoader } from 'react-spinners';
import DropDownConstants from '../../../dropDownConstant';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import * as Yup from 'yup';
import Axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
// import { getSpecialRequirements } from './addJobUtilities';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import urlLinks from '../../../urlLinks';
import {
  TextareaAutosize,
  Select,
  InputLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControl
} from '@material-ui/core';
// CSS Imports
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Typography,
  Divider,
  OutlinedInput,
  Radio
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  form: {
    display: 'flex',
    flexFlow: 'column'
  },
  text_container: {
    width: '80%',
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonStyle: {
    width: '90px',
    margin: 'auto',
    fontSize: '0.8rem'
  },
  gap: {
    padding: '10px 5px',
    wordBreak: 'break-word'
  },
  input: {
    padding: '10px',
    height: '40px',
    fontSize: '0.8rem'
  },
  inputStyle: {
    padding: '0px',
    height: '40px',
    fontSize: '0.8rem'
  },
  selectInput: {
    padding: '10px',
    height: '0px',
    fontSize: '0.8rem'
  },
  inputLabel: {
    transform: 'translate(13px, 12px) scale(1)',
    fontSize: '0.8rem'
  },
  grid: {
    maxWidth: '800px',
    margin: 'auto'
  },
  textheading: {
    textDecoration: 'underline',
    fontSize: '0.9rem'
  },
  textContent: {
    fontSize: '0.8rem'
  },
  alignFormat: {
    margin: 'auto',
    fontSize: '0.8rem'
  },
  textBoxStyle: {
    height: '35px',
    marginLeft: '10px'
  }
});

class AddJob extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      selectedStartTime: new Date(),
      selectedEndTime: new Date(),
      entities: [],
      requirementsContainer: [],
      jobCategory: null,
      jobTypes: [],
      particularCompanyDetail: '',
      companyDetails: {},
      companies: {},
      entitylist: {},
      isRedirected: true,
      jobTypesList: {},
      typesToRender: [],
      labels: [],
      requirements: [],
      allEntities: [],
      userId: '',
      detailedJobTypes: [],
      googleAddress: '',
      isSubmitting: false,
      address: {
        addressLine1: '',
        landmark: '',
        city: '',
        state: '',
        pincode: ''
      },
      location: {
        lat: '',
        lng: ''
      }
    };
    this.addJobSchema = Yup.object().shape({
      companyName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
      contactPerson: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is Required'),
      radioGroup: Yup.string().required('Select job type'),
      // dateOfBirth: Yup.date()
      //   .required('Required')
      //   .nullable(),
      contactNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Invalid Contact Number')
        .required('Contact Number is Required'),
      // qualification: Yup.string().required('Required'),
      // jobScheduleDays: Yup.string().required('Required'),
      jobType: Yup.string().required('Required'),
      addressLine1: Yup.string()
        .min(2, 'Too Short!')
        .max(150, 'Too Long!')
        .required('Required'),
      city: Yup.string()
        .trim()
        .required('Required'),
      state: Yup.string()
        .trim()
        .required('Required'),
      pincode: Yup.string()
        .matches(/^[0-9]{6}$/, 'Invalid Pincode')
        .required('Required'),
      // jobScheduleCategory: Yup.array().of(
      //   Yup.object().shape({
      //     startTime: Yup.date()
      //       .required('Required')
      //       .nullable(),
      //     endTime: Yup.date()
      //       .required('Required')
      //       .nullable()
      //   })
      // ),
      category: Yup.object().shape({
        FT: Yup.object().when('radioGroup', {
          is: 'fullTime',
          then: Yup.object().shape({
            startDate: Yup.date()
              .required('Required')
              .nullable(),
            startTime: Yup.date()
              .required('Required')
              .nullable(),
            endTime: Yup.date()
              .required('Required')
              .nullable()
          })
        }),
        PT: Yup.object().when('radioGroup', {
          is: 'partTime',
          then: Yup.object().shape({
            startDate: Yup.date()
              .required('Required')
              .nullable(),
            enddate: Yup.date()
              .required('Required')
              .nullable(),
            startTime: Yup.date()
              .required('Required')
              .nullable(),
            endTime: Yup.date()
              .required('Required')
              .nullable()
          })
        }),
        OD: Yup.object().when('radioGroup', {
          is: 'onDemand',
          then: Yup.object().shape({
            startDate: Yup.date()
              .required('Required')
              .nullable(),
            startTime: Yup.date()
              .required('Required')
              .nullable(),
            endTime: Yup.date()
              .required('Required')
              .nullable()
          })
        })
      }),
      // PT: Yup.object().shape({
      //   startDate: Yup.date()
      //     .required('Required')
      //     .nullable(),
      //   enddate: Yup.date()
      //   .required('Required')
      //   .nullable(),
      //   startTime: Yup.date()
      //     .required('Required')
      //     .nullable(),
      //   endTime: Yup.date()
      //     .required('Required')
      //     .nullable()
      // })
      // .when("radioGroup", {
      //   is: 'partTime'}),
      // OD: Yup.object().shape({
      //   startDate: Yup.date()
      //     .required('Required')
      //     .nullable(),
      //   startTime: Yup.date()
      //     .required('Required')
      //     .nullable(),
      //   endTime: Yup.date()
      //     .required('Required')
      //     .nullable()
      // })
      // })
      // .when("radioGroup", {
      //   is: 'onDemand'}),
      jobType: Yup.array().of(
        Yup.object().shape({
          jobtype: Yup.string().required('Required'),
          openings: Yup.number()
            .required('Required')
            .moreThan(0)
            .nullable(),
          rate: Yup.number()
            .required('Required')
            .moreThan(0)
            .nullable()
        })
      )
      // experience: Yup.array().of(
      //   Yup.object().shape({
      //     industry: Yup.string().required('Required'),
      //     company: Yup.string().required('Required'),
      //     jobProfile: Yup.string().required('Required'),
      //     experience: Yup.string().required('Required')
      //   })
      // ),
      // socialMedium: Yup.string().required('Required'),
      // otherSource: Yup.string().when('socialMedium', {
      //   is: socialMedium => socialMedium === 'Other Source',
      //   then: Yup.string().required('Required')
      // })
    });
    this.addressProof = ['Driving Licence', 'Voter ID'];
    this.socialMedium = [
      'Quicker/Job Portal',
      'Leader',
      'Personal Contact',
      'Reference from some worker',
      'Other Source'
    ];
    this.redirected = true;
    this.addressProofBlob = null;
    this.industry = ['Restaurant', 'Mechanical'];
    this.jobProfiles = ['Chef', 'Waiter'];
    this.cities = [
      {
        id: 1,
        city: 'Gurugram',
        state: 'Haryana'
      },
      {
        id: 2,
        city: 'Delhi',
        state: 'Delhi'
      },
      {
        id: 3,
        city: 'Noida',
        state: 'UP'
      }
    ];

    this.requirements = [];
    this.requirementsContainer = [];

    this.qualifications = [
      {
        id: '1',
        qualification: '10th Pass'
      },
      {
        id: '2',
        qualification: '12th Pass'
      },
      {
        id: '3',
        qualification: 'UnderGraduate'
      },
      {
        id: '4',
        qualification: 'Graduate'
      }
    ];
    this.weekDays = [
      {
        id: '1',
        day: '1'
      },
      {
        id: '2',
        day: '2'
      },
      {
        id: '3',
        day: '3'
      },
      {
        id: '4',
        day: '4'
      },
      {
        id: '5',
        day: '5'
      },
      {
        id: '6',
        day: '6'
      },
      {
        id: '7',
        day: '7'
      }
    ];
    this.jobTypes = [
      {
        id: '1',
        type: 'Chef'
      },
      {
        id: '2',
        type: 'Bartender'
      },
      {
        id: '3',
        type: 'Receptionist'
      },
      {
        id: '4',
        type: 'other'
      }
    ];
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.formikSetValues = null;
    this.formikValues = {};
  }
  handleStartDateChange = date => {
    this.setState({ selectedStartDate: date });
  };
  handleEndDateChange = date => {
    this.setState({ selectedEndDate: date });
  };
  handleStartTimeChange = date => {
    this.setState({ selectedStartTime: date });
  };
  handleEndTimeChange = date => {
    this.setState({ selectedEndTime: date });
  };
  handlejobCategory = event => {
    this.setState({ jobCategory: event.target.value });
  };

  jobCategoryValues = values => {
    // console.log(values)
  };

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    const geometry = addressObject.geometry.location;
    this.setState({
      location: {
        lat: geometry.lat(),
        lng: geometry.lng()
      }
    });
    let addressDetails = {
      addressLine1: '',
      landmark: '',
      city: '',
      state: '',
      pincode: ''
    };
    this.lat = geometry.lat();
    this.lang = geometry.lng();
    if (utilFunctions.checkValidLatLng(this.lat, this.lang)) {
    // console.log(addressObject.address_components);
    addressObject.address_components.forEach(element => {
      if (element.types.includes('administrative_area_level_1')) {
        addressDetails['state'] = element.long_name;
      } else if (element.types.includes('administrative_area_level_2')) {
        addressDetails['city'] = element.long_name;
      } else if (element.types.includes('postal_code')) {
        addressDetails['pincode'] = parseInt(element.long_name);
      } else if (element.types.includes('sublocality_level_1')) {
        addressDetails['landmark'] = element.long_name;
      } else if (!element.types.includes('country')) {
        addressDetails['addressLine1'] =
          (addressDetails['addressLine1']
            ? addressDetails['addressLine1']
            : '') +
          element.long_name +
          ', ';
      }
    }, addressDetails);
    addressDetails['addressLine1'] = addressDetails['addressLine1'].replace(
      /,\s*$/,
      ''
    );
    if (addressDetails.pincode) {
      let newformikValues = Object.assign(
        {},
        this.formikValues,
        addressDetails
      );
      this.formikSetValues(newformikValues);
      this.setState({
        address: addressDetails,
        googleAddress: addressDetails['addressLine1']
      });
    }
    if (!addressDetails.pincode) {
      Axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${geometry.lat()},${geometry.lng()}&key=${
          process.env.REACT_APP_GOOGLE_MAPS_API
        }`
      )
        .then(response => {
          if (response.data.results.length > 0) {
            let addressComponent = response.data.results[0].address_components;
            addressDetails['pincode'] = parseInt(
              addressComponent[addressComponent.length - 1].long_name
            );
            let newformikValues = Object.assign(
              {},
              this.formikValues,
              addressDetails
            );
            // console.log(newformikValues)
            this.formikSetValues(newformikValues);
            this.setState({
              address: addressDetails,
              googleAddress: addressDetails['addressLine1']
            });
          }
        })
        .catch(err => {
          toast.error(`Error While Loading Google Maps`)
        });
      }
    } else {
      toast.error('Currently Non Serviceable Area :(');
    }
}
  handleSubmitValues = (event, values) => {
    event.preventDefault();
    // console.log(`Hello`)
    // console.log(this.formikValues, values);
  };

  mapWorkyTypesToId = workType => {
    const workTypes = {
      onDemand: 3,
      partTime: 2,
      fullTime: 1
    };
    if (workType) {
      return workTypes[workType];
    }
  };

  mapCityToId = (city, id) => {
    const citymaps = {
      Delhi: 71,
      Noida: 72,
      Gurugram: 73
    };
    const idMaps = {
      71: 'Delhi',
      72: 'Noida',
      73: 'Gurugram'
    };
    if (city) {
      return citymaps[city];
    }
    if (id) {
      return idMaps[id];
    }
  };

  getUniqueArray = array => {
    var uniqueArray = [];

    // Loop through array values
    array.map(el => {
      if (uniqueArray.indexOf(el) === -1) {
        uniqueArray.push(el);
      }
    });
    return uniqueArray;
  };

  // getSpecialRequirements(jobType);

  getSpecialRequirements = async (jobType, index) => {
    const detailedJobTypes = [...this.state.detailedJobTypes];
    return Promise.all(
      detailedJobTypes.map(async el => {
        if (el[jobType]) {
          const data = await Axios.get(
            `${urlLinks.baseUrl}/configmaster/inquery?inquery=${el[jobType]['keys']}`
          ).then(response => response.data.response.content);
          console.log(data);
          
          // Axios.get(
          //   `${urlLinks.baseUrl}/configmaster/inquery?inquery=${el[jobType]['keys']}`
          // ).then(response => {
          let typesToRender = data.map(el => {
            return el.typeKey;
          });
          let ids = data.map(el => {
            return el.id;
          });
          
          
          let labels = data.map(el => el.typeDesc);
          labels = this.getUniqueArray(labels);
          const tempLabels = [...this.state.labels];
          tempLabels[index] = labels;
          typesToRender = this.getUniqueArray(typesToRender);

          const holder = {};
          const requirementsHolder = [...this.state.requirementsContainer];
          typesToRender.map((el, index) => {
            let values = { ...this.state.entities[el] };
            const jobEntities= {};
            Object.keys(this.state.entities[el]).map(el2 => {
              if(ids.includes(+el2)) {
                // console.log(ids.includes(el2))
                jobEntities[el2] = this.state.entities[el][el2];
              }
            })
            values = {...jobEntities}
            console.log(values);

            const value = Object.values(values)[0];
            holder[el] = null;
          });
          holder['isColumnVisible'] = true;
          requirementsHolder[index] = holder;
          console.log(requirementsHolder)
          // console.log(requirementsHolder);
          this.requirementsContainer = requirementsHolder;
          // const createdLabels = [];
          // typesToRender.map((el, index) => {
          //   createdLabels.push({[el]: labels[index]})
          // })

          const allEntities = [...this.state.allEntities];
          const asd = [];

          typesToRender.map((el, index) => {
            if (el !== 'job_type') {
              const jobEntities = {};
              Object.keys(this.state.entities[el]).map(el2 => {
                if(ids.includes(+el2)) {
                  // console.log(ids.includes(el2))
                  jobEntities[el2] = this.state.entities[el][el2];
                }
              })
              if(this.state.entities[el])
              asd.push(jobEntities);
            }
          });
          allEntities[index] = asd;
          // console.log(allEntities)

          const requirements = [];
          typesToRender.map(type => requirements.push({ [type]: [] }));
          requirements.map(el2 => {
            data.map(el => {
              if (el2[el.categoryType]) {
                el2[el.categoryType].push({
                  [el.typeValue]: {
                    id: el.id,
                    label: el.typeDesc,
                    selectable: el.other
                  }
                });
              }
            });
          });
          requirements.map((el, index) => {
            // console.log(el[typesToRender[index]])
          });
          this.requirements = requirements;
          // console.log(typesToRender);
          this.setState({
            requirementsContainer: requirementsHolder,
            labels: tempLabels,
            typesToRender: typesToRender,
            allEntities: allEntities,
            requirements: requirements
          });
          // });
          // console.log(this.state);
        }
      })
    );
  };

  mapRadioGroupToJobType = jobType => {
    const jobTypes = {
      onDemand: 'OD',
      fullTime: 'FT',
      partTime: 'PT'
    };
    return jobTypes[jobType];
  };

  handleCompanyChange = companyName => {
    // console.log(this.state.companyDetails);
    const userId = this.state.companyDetails[companyName]['userId'];
    const companyDetail = { ...this.state.companyDetails[companyName] };
    console.log(companyDetail)
    const lat = companyDetail.lat;
    const lng = companyDetail.lng;
    const address = {
      addressLine1: companyDetail.addressLine1,
      landmark: companyDetail.landmark,
      city: companyDetail.city || 'Other',
      state: DropDownConstants.idToState[companyDetail.cityId] || 'Other',
      pincode: companyDetail.pincode
    };
    this.setState({
      particularCompanyDetail: companyDetail,
      location: {
        lat: lat,
        lng: lng
      },
      address: address,
      googleAddress: '',
      userId: userId
    });
    if (this.props.location.id) {
      this.setState({ isRedirected: true });
    }
    address.addressLine2 = address.landmark;
    let newformikValues = Object.assign(
      {},
      this.formikValues,
      address,
      companyDetail
    );
    this.formikSetValues(newformikValues);
  };

  componentDidMount() {
    this._isMounted = true;
    Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_type=job_type&inserted_by=0&rows=100&type_desc=job_type_name`
    ).then(response => {
      let jobTypes = response.data.response.content.map(
        el => el.categorySubType
      );
      let jobTypesList = {};
      response.data.response.content.map(
        el => (jobTypesList[el.id] = el.categorySubType)
      );
      jobTypes = this.getUniqueArray(jobTypes);
      this.setState({ jobTypes: jobTypes, jobTypesList: jobTypesList });

      const detailedJobTypes = [];
      response.data.response.content.map(el => {
        if (el.typeValue !== '-') {
          detailedJobTypes.push({
            [el.categorySubType]: {
              id: el.id,
              keys: el.typeValue
            }
          });
        }
      });
      this.setState({ detailedJobTypes: detailedJobTypes });
    });
    Axios.get(
      `${urlLinks.baseUrl}/configmaster/entityList`
    ).then(response => {
      const allEntities = Object.values(response.data.response.content);
      const entitylist = {};
      allEntities.map(el => {
        Object.keys(el).map(el2 => (entitylist[el[el2]] = el2));
      });
      this.setState({
        entities: response.data.response.content,
        entitylist: entitylist
      });
    });
    Axios.get(
      `${urlLinks.baseUrl}/admin/companies`
    ).then(response => {
      const companies = {};
      response.data.response.map(el => {
        companies[el.companyName] = el.companyId;
      });
      const companyDetail = {};
      response.data.response.map(el => {
        companyDetail[el.companyName] = {
          companyName: el.companyName,
          userId: el.userId,
          contactPerson: el.contactPerson || '',
          contactNumber: +el.contactNo || '',
          addressLine1: el.addressLine1,
          landmark: el.addressLine2,
          city: this.mapCityToId(null, el.cityId) || 'Other',
          pincode: el.postalCode,
          lat: el.locationLat,
          cityId: el.cityId,
          lng: el.locationLong
        };
      });
      this.setState({ companies: companies, companyDetails: companyDetail });
      if (this.props.location.id) {
        Axios.get(
          `${urlLinks.baseUrl}/admin/getemployerbyid?id=${this.props.location.id}`
        ).then(response => {
          this.redirected = true;
          const companyName = response.data.response.companyName;
          this.handleCompanyChange(companyName);
        });
      } else {
        this.setState({isRedirected: false});
      }
    });

    this.autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('google-autocomplete-input-worker', {})
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    // console.log(this.state.allEntities);
    const { classes } = this.props;
    // const entries = [];
    // const entitiesMap = [];
    // Object.keys(this.state.entities).map(el => {
    //   const arrayForEntity = [];
    //   arrayForEntity.push(el);
    //   arrayForEntity.push("");
    //   entries.push(arrayForEntity)
    // })
    // const objectEntries = Object.fromEntries(entries)
    // // console.log(objectEntries)
    // Object.keys(this.state.allEntities).map(el => entitiesMap.push(objectEntries))
    // // console.log(entitiesMap)
    if (this.state.isSubmitting) {
      return (
        <CircleLoader
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto'
          }}
          color={'#194D33'}
        ></CircleLoader>
      );
    } else {
      return (
        <div className={classes.root}>
          <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={4000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          ></ToastContainer>
          {this.redirected ? <BackButton /> : null}
          <Formik
            initialValues={{
              companyName: this.state.particularCompanyDetail.companyName || '',
              radioGroup: '',
              googleAddress: this.state.googleAddress,
              addressLine1: this.state.address.addressLine1
                ? this.state.address.addressLine1
                : '',
              addressLine2: this.state.address.landmark || '',
              contactPerson:
                this.state.particularCompanyDetail.contactPerson || '',
              contactNumber:
                this.state.particularCompanyDetail.contactNumber || '',
              city: this.state.address.city || '',
              state: this.state.address.state || '',
              pincode: this.state.address.pincode || '',
              longitude: this.state.location.lat || '',
              latitude: this.state.location.lng || '',
              specialRequirement: [...this.state.requirementsContainer],
              jobType: [
                {
                  jobtype: '',
                  openings: '',
                  rate: '',
                  isVisible: true
                }
              ],
              jobScheduleDays: '1',
              jobScheduleCategory: [],
              category:{
                
              }
            }}
            validationSchema={this.addJobSchema}
            onSubmit={values => {
              this.setState({ isSubmitting: true });
              // console.log(values);

              // let cityId = '';
              // for (let value of DropDownConstants.cities) {
              //   if (
              //     value.city
              //       .toLowerCase()
              //       .indexOf(values.city.toLowerCase()) !== -1
              //   ) {
              //     cityId = value.id;
              //     break;
              //   }
              // }

              const mapDaysToId = {
                Sunday: 1,
                Monday: 2,
                Tuesday: 3,
                Wednesday: 4,
                Thursday: 5,
                Friday: 6,
                Saturday: 7
              };
              const makeDayToId = {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false
              };
              let jobScheduledDays = {};
              if (Array.isArray(values.jobScheduleDays)) {
                const days = [];
                values.jobScheduleDays.map(el => days.push(mapDaysToId[el]));
                days.map(el => (makeDayToId[el] = true));
                jobScheduledDays = JSON.stringify({ ...makeDayToId });
              }
              let cityId = DropDownConstants.states[values.state] || 71;
              let data = {};
              if (values.radioGroup === 'partTime') {
                data = {
                  address: `${values.addressLine1}, ${values.city}, ${values.pincode}`,
                  city_id: cityId,
                  company_id: this.state.companies[values.companyName],
                  contact_no: values.contactNumber.toString(),
                  contact_person: values.contactPerson,
                  country_id: 0,
                  end_date:
                    values.radioGroup === 'partTime'
                      ? JSON.stringify(
                          values.jobScheduleCategory[
                            this.mapRadioGroupToJobType(values.radioGroup)
                          ].endDate
                        ).slice(1, 11)
                      : null,
                  favourite_first: 0,
                  job_location_lat: this.state.location.lat,
                  job_location_long: this.state.location.lng,
                  job_status: 1,
                  land_mark: values.addressLine2,
                  login_time: values.jobScheduleCategory[
                    this.mapRadioGroupToJobType(values.radioGroup)
                  ].startTime
                    .toTimeString()
                    .split(' ')[0],
                  logout_time: values.jobScheduleCategory[
                    this.mapRadioGroupToJobType(values.radioGroup)
                  ].endTime
                    .toTimeString()
                    .split(' ')[0],
                  no_end_date: values.radioGroup === 'partTime' ? 0 : 1,
                  postal_code: values.pincode.toString(),
                  requested_by: localStorage.getItem('userId'),
                  start_date: JSON.stringify(
                    values.jobScheduleCategory[
                      this.mapRadioGroupToJobType(values.radioGroup)
                    ].startDate
                  ).slice(1, 11),
                  user_id: this.state.userId,
                  work_type: this.mapWorkyTypesToId(values.radioGroup),
                  workdays_count: 0,
                  workdays_details:
                    values.radioGroup === 'partTime' ? Array.isArray(values.jobScheduleDays) ? jobScheduledDays : JSON.stringify({}) : JSON.stringify({})
                };
              } else {
                data = {
                  address: `${values.addressLine1}, ${values.city}, ${values.pincode}`,
                  city_id: cityId || 71,
                  company_id: this.state.companies[values.companyName],
                  contact_no: values.contactNumber.toString(),
                  contact_person: values.contactPerson,
                  country_id: 0,
                  favourite_first: 0,
                  job_location_lat: this.state.location.lat,
                  job_location_long: this.state.location.lng,
                  job_status: 1,
                  land_mark: values.addressLine2,
                  login_time: values.jobScheduleCategory[
                    this.mapRadioGroupToJobType(values.radioGroup)
                  ].startTime
                    .toTimeString()
                    .split(' ')[0],
                  logout_time: values.jobScheduleCategory[
                    this.mapRadioGroupToJobType(values.radioGroup)
                  ].endTime
                    .toTimeString()
                    .split(' ')[0],
                  no_end_date: values.radioGroup === 'fullTime' ? 1 : 0,
                  postal_code: values.pincode.toString(),
                  requested_by: localStorage.getItem('userId'),
                  start_date: JSON.stringify(
                    values.jobScheduleCategory[
                      this.mapRadioGroupToJobType(values.radioGroup)
                    ].startDate
                  ).slice(1, 11),
                  user_id: this.state.userId,
                  work_type: this.mapWorkyTypesToId(values.radioGroup),
                  workdays_count:
                    Array.isArray(values.jobScheduleDays) === false
                      ? +values.jobScheduleDays
                      : 0
                };
                if(values.radioGroup === 'onDemand') {
                  let date = values.jobScheduleCategory['OD'].startDate
                  if(+values.jobScheduleDays === 1) {
                    const startTime = values.jobScheduleCategory[
                      this.mapRadioGroupToJobType(values.radioGroup)
                    ].startTime
                      .toTimeString()
                      .split(' ')[0];
                    const endTime = values.jobScheduleCategory[
                      this.mapRadioGroupToJobType(values.radioGroup)
                    ].endTime
                      .toTimeString()
                      .split(' ')[0];
                      if(+startTime.substring(0, 2) > +endTime.substring(0, 2)) {
                        data.end_date = new Date(date.setDate(date.getDate() + (+values.jobScheduleDays))).toISOString().split('T')[0];
                      } else {
                        data.end_date = JSON.stringify(
                          values.jobScheduleCategory[
                            this.mapRadioGroupToJobType(values.radioGroup)
                          ].startDate
                        ).slice(1, 11);
                      }
                      // if(+startTime.substring(0, 2) > 12 && +endTime.substring(0, 2) < 24) {
                      //   data.end_date = JSON.stringify(
                      //     values.jobScheduleCategory[
                      //       this.mapRadioGroupToJobType(values.radioGroup)
                      //     ].startDate
                      //   ).slice(1, 11);
                      // }
                      // if(+startTime.substring(0, 2) > 12 && +endTime.substring(0, 2) > 00)
                      // console.log(date)
                      // console.log(startTime)
                      // console.log(endTime)
                  } else {
                    const startTime = data.login_time
                    const endTime = data.logout_time
                    if(+startTime.substring(0, 2) > +endTime.substring(0, 2)) {
                      data.end_date = new Date(date.setDate(date.getDate() + (+values.jobScheduleDays))).toISOString().split('T')[0];
                    } else {
                      data.end_date = new Date(date.setDate(date.getDate() + (+values.jobScheduleDays-1))).toISOString().split('T')[0];
                    }
                  }
                }
              }
              // }
              console.log(data);
              Axios.post(
                `${urlLinks.baseUrl}${urlLinks.jobUrls.postAddEditJob}`,
                qs.stringify(data)
              ).then(response => {
                const jobId = response.data.response.jobId;
                // console.log(response);
                // console.log(jobId);
                const specialRequirements = values.specialRequirement.filter(
                  el => {
                    if(el) {
                      if (el.isColumnVisible) {
                        return el.isColumnVisible !== false;
                      }
                    }
                  }
                );
                const jobTypes = values.jobType.filter(
                  el => el.isVisible !== false
                );
                var counter = 0;
                var jobTypesLength = jobTypes.length;
                jobTypes.map((el, index) => {
                  const specialRequirementsForSkills = {
                    ...specialRequirements[index]
                  };
                  delete specialRequirementsForSkills.isColumnVisible;
                  delete specialRequirementsForSkills.textarea;
                  delete specialRequirementsForSkills.job_type;
                  let skills = [];
                  Object.values(specialRequirementsForSkills).map(el => {
                    skills.push({
                      insertedBy: this.state.userId,
                      jobDetailsId: 0,
                      jobId: jobId,
                      skillsTypeId: this.state.entitylist[el],
                      updatedBy: 0
                    });
                  });
                  const detailedJob = {
                    additional_requirement: specialRequirements[index].textarea
                      ? specialRequirements[index].textarea
                      : '',
                    amount: +el.rate,
                    amount_per:
                      values.radioGroup === 'onDemand' ? 'Daily' : 'Monthly',
                    employee_required: +el.openings,
                    experience_required: 0,
                    gender: 0,
                    is_recurring: 0,
                    job_category_id: 0,
                    job_id: jobId,
                    job_position: el.jobtype,
                    job_title: el.jobtype,
                    job_type_id: +Object.keys(this.state.jobTypesList).find(
                      key => this.state.jobTypesList[key] === el.jobtype
                    ),
                    qualification_required: 0,
                    skill_set: JSON.stringify(skills)
                  };
                  // console.log(detailedJob);
                  Axios.post(
                    `${urlLinks.baseUrl}/job/details/?job_id=${jobId}`,
                    qs.stringify(detailedJob)
                  )
                    .then(response => {
                      // console.log(response);
                      counter++;
                      if (counter === jobTypesLength) {
                        if (response.data.code === 1000) {
                          this.setState({ isSubmitting: false });
                          toast.success('Job Added Successfully');
                          // setTimeout(window.location.reload(), 4000);
                        } else {
                          throw new Error(response.data.message);
                        }
                      }
                    })

                    .catch(error => {
                      this.setState({ isSubmitting: false });
                      setTimeout(window.location.reload(), 4000);
                      toast.error(error.message);
                    });
                });
              });
              
              // console.log(data);
            }}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setValues
              } = props;
              let { handleSubmit } = props;
              this.formikValues = values;
              this.formikSetValues = setValues;

              return (
                <Form
                  className={classes.form}
                  onSubmit={event => handleSubmit(event, values)}
                >
                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.textheading}>
                        Basic Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {!this.state.isRedirected ? (
                        <FormControl className={classes.text_container}>
                          <InputLabel id='demo-simple-select-label'>
                            Company Name
                          </InputLabel>
                          <Select
                            name='companyName'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={values.companyName}
                            onChange={event => {
                              handleChange(event);
                              this.handleCompanyChange(event.target.value);
                            }}
                          >
                            {Object.keys(this.state.companies).map(el => (
                              <MenuItem key={el} value={el}>
                                {el}
                              </MenuItem>
                            ))}

                            {/* <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl className={classes.text_container}>
                          <InputLabel id='demo-simple-select-label'>
                            Company Name
                          </InputLabel>
                          <Select
                            name='companyName'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            disabled
                            value={values.companyName}
                            onChange={event => {
                              handleChange(event);
                              this.handleCompanyChange(event.target.value);
                            }}
                          >
                            {Object.keys(this.state.companies).map(el => (
                              <MenuItem key={el} value={el}>
                                {el}
                              </MenuItem>
                            ))}

                            {/* <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          label='Contact Person'
                          name='contactPerson'
                          value={values.contactPerson}
                          onChange={handleChange}
                          error={
                            touched.contactPerson &&
                            Boolean(errors.contactPerson)
                          }
                          helperText={
                            touched.contactPerson ? errors.contactPerson : ''
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          className={classes.text_container}
                          variant='outlined'
                          label='Contact Number'
                          type='number'
                          name='contactNumber'
                          value={values.contactNumber}
                          onChange={handleChange}
                          error={
                            touched.contactNumber &&
                            Boolean(errors.contactNumber)
                          }
                          helperText={
                            touched.contactNumber ? errors.contactNumber : ''
                          }
                          InputProps={{
                            className: classes.input,
                            inputProps: {
                              className: classes.inputStyle
                            }
                          }}
                          InputLabelProps={{
                            className: classes.inputLabel
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginBottom: '20px' }}>
                      <Typography
                        className={classes.textheading}
                        variant='h6'
                        style={{
                          display: 'inline',
                          marginRight: '50px'
                        }}
                        // onChange= {setFieldValue("radioGroup",this.state.jobCategory)}
                      >
                        Category
                      </Typography>
                      <Radio
                        checked={this.state.jobCategory === 'onDemand'}
                        onChange={event => {
                          this.handlejobCategory(event);
                          handleChange(event);
                        }}
                        value='onDemand'
                        label='OD'
                        name='radioGroup'
                        inputProps={{ 'aria-label': 'onDemand' }}
                        control={<Radio color='primary' />}
                      />
                      OD
                      <Radio
                        checked={this.state.jobCategory === 'fullTime'}
                        onChange={event => {
                          this.handlejobCategory(event);
                          handleChange(event);
                        }}
                        value='fullTime'
                        label='FT'
                        name='radioGroup'
                        inputProps={{ 'aria-label': 'fullTime' }}
                      />
                      FT
                      <Radio
                        checked={this.state.jobCategory === 'partTime'}
                        onChange={event => {
                          this.handlejobCategory(event);
                          handleChange(event);
                        }}
                        value='partTime'
                        label='PT'
                        name='radioGroup'
                        inputProps={{ 'aria-label': 'partTime' }}
                      />
                      PT
                      {touched.radioGroup && errors.radioGroup ? (
                        <div>
                          <span style={{ color: 'red' }}>
                            {errors.radioGroup}
                          </span>
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.textheading}>
                        Job Schedule
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {this.state.jobCategory ? (
                        <JobCategory
                          setDaysValues={updatedSchedule =>
                            setFieldValue('jobScheduleDays', updatedSchedule)
                          }
                          setCategoryValues={values =>
                            setFieldValue('jobScheduleCategory', values)
                          }
                          value={this.state.jobCategory}
                          addJobSchema={this.addJobSchema}
                        />
                      ) : (
                        <Grid></Grid>
                      )}
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid container justify='space-around'>
                      <Grid item xs={12} sm={9}>
                        <Typography
                          variant='h6'
                          className={classes.textheading}
                        >
                          Job Type
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={3}
                        style={{ display: 'flex', marginBottom: '10px' }}
                      >
                        <Button
                          onClick={() => {
                            const data = [
                              ...values.jobType,
                              {
                                jobtype: '',
                                openings: '',
                                rate: '',
                                isVisible: true
                              }
                            ];
                            // console.log(this.state.requirementsContainer);
                            const data1 = this.state.requirementsContainer;
                            setFieldValue('jobType', data);
                            setFieldValue('specialRequirement', data1);
                            // console.log(values.specialRequirement);
                          }}
                        >
                          <AddBoxIcon></AddBoxIcon>
                        </Button>
                      </Grid>
                    </Grid>
                    {values.jobType.map((job, index) => {
                      if (job['isVisible']) {
                        return (
                          <React.Fragment key={index}>
                            <Grid container justify='space-around'>
                              <Grid item xs={12} sm={3}>
                                <TextField
                                  className={classes.text_container}
                                  variant='outlined'
                                  select
                                  name={`jobType[${index}].jobtype`}
                                  key={index}
                                  value={values.jobType[index].jobtype}
                                  onChange={async event => {
                                    // console.log(event)
                                    // console.log(index);
                                    // console.log(this.state.requirementsContainer)
                                    const updatedValues = values.jobType.map(
                                      (element, id) => {
                                        if (id === index) {
                                          element.jobtype = event.target.value;
                                        }
                                        return element;
                                      }
                                    );
                                    setFieldValue('jobType', updatedValues);

                                    this.getSpecialRequirements(
                                      event.target.value,
                                      index
                                    ).then(data =>
                                      setFieldValue(
                                        'specialRequirement',
                                        this.state.requirementsContainer
                                      )
                                    );

                                    // console.log(result)

                                    // setFieldValue('specialRequirement', this.state.requirementsContainer)
                                    // await console.log(this.requirementsContainer)
                                    // await console.log(this.state.requirementsContainer)
                                    // setTimeout(() => {
                                    //   setFieldValue('specialRequirement', this.state.requirementsContainer)
                                    // }, 500);
                                  }}
                                  error={
                                    errors.jobType
                                      ? errors.jobType[index]
                                        ? Boolean(errors.jobType[index].jobtype)
                                        : false
                                      : false
                                  }
                                  label='Job Type'
                                  InputProps={{
                                    className: classes.input
                                  }}
                                  InputLabelProps={{
                                    className: classes.inputLabel
                                  }}
                                >
                                  {this.state.jobTypes.map((jobType, index) => (
                                    <MenuItem key={index + 1} value={jobType}>
                                      {jobType}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <TextField
                                  className={classes.text_container}
                                  key={index}
                                  variant='outlined'
                                  name='openings'
                                  value={values.jobType[index].openings}
                                  onChange={event => {
                                    const updatedValues = values.jobType.map(
                                      (element, id) => {
                                        if (id === index) {
                                          element.openings = event.target.value;
                                        }
                                        return element;
                                      }
                                    );
                                    setFieldValue('jobType', updatedValues);
                                  }}
                                  error={
                                    errors.jobType
                                      ? errors.jobType[index]
                                        ? Boolean(
                                            errors.jobType[index].openings
                                          )
                                        : false
                                      : false
                                  }
                                  label='Openings'
                                  type='number'
                                  InputProps={{
                                    className: classes.input,
                                    inputProps: {
                                      className: classes.inputStyle
                                    }
                                  }}
                                  InputLabelProps={{
                                    className: classes.inputLabel
                                  }}
                                ></TextField>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <TextField
                                  className={classes.text_container}
                                  variant='outlined'
                                  label={
                                    values.radioGroup === 'onDemand'
                                      ? 'Rate'
                                      : 'Salary'
                                  }
                                  name={`jobType[${index}].rate`}
                                  value={values.jobType[index].rate}
                                  onChange={event => {
                                    const updatedValues = values.jobType.map(
                                      (element, id) => {
                                        if (id === index) {
                                          element.rate = event.target.value;
                                        }
                                        return element;
                                      }
                                    );
                                    setFieldValue('jobType', updatedValues);
                                  }}
                                  error={
                                    errors.jobType
                                      ? errors.jobType[index]
                                        ? Boolean(errors.jobType[index].rate)
                                        : false
                                      : false
                                  }
                                  key={index}
                                  type='number'
                                  InputProps={{
                                    className: classes.input,
                                    inputProps: {
                                      className: classes.inputStyle
                                    }
                                  }}
                                  InputLabelProps={{
                                    className: classes.inputLabel
                                  }}
                                ></TextField>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Button
                                  variant='contained'
                                  onClick={() => {
                                    if (values.jobType.length > 1) {
                                      const updatedValues = [...values.jobType];
                                      // console.log(updatedValues);
                                      // console.log(updatedValues[index]);
                                      updatedValues[index]['isVisible'] = false;
                                      updatedValues[index]['openings'] = 1;
                                      updatedValues[index]['rate'] = 1;
                                      const specialRequirementValues = [
                                        ...values.specialRequirement
                                      ];
                                      if (!specialRequirementValues[index]) {
                                        updatedValues[index]['jobtype'] = 'a';
                                        updatedValues[index]['openings'] = 1;
                                        updatedValues[index]['rate'] = 1;
                                      } else {
                                        specialRequirementValues[index][
                                          'isColumnVisible'
                                        ] = false;
                                      }
                                      // console.log(updatedValues);
                                      // console.log(values.specialRequirement);

                                      // const allEntities = this.state.allEntities[index].filter((element,id) => {
                                      //   return id!==index
                                      // })
                                      // this.setState({allEntities: allEntities})

                                      // const labels = this.state.labels[index].filter((element, id) => {
                                      //   return id!==index
                                      // })
                                      // this.setState({labels: labels})

                                      // const specialRequirements = values.specialRequirement[index].filter((element, id) => {
                                      //   return id!==index
                                      // })
                                      // setFieldValue('specialRequirement',specialRequirements);

                                      // const typesToRender = this.state.typesToRender[index].filter((element, id) => {
                                      //   return id!==index
                                      // })
                                      // this.setState({typesToRender: typesToRender})

                                      setFieldValue('jobType', updatedValues);
                                    } else {
                                      toast.error(`Jobs can't be less than 1`);
                                    }
                                  }}
                                >
                                  <DeleteIcon></DeleteIcon>
                                </Button>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        );
                      } else {
                        return null;
                      }
                    })}
                    {values.jobType.map((job, idx) => {
                      if (values.jobType[idx].jobtype === 'other') {
                        return (
                          <React.Fragment key={idx}>
                            <Grid container className={classes.grid}>
                              <Grid item xs={12} sm={3}>
                                <TextField
                                  className={classes.text_container}
                                  id='job'
                                  name='job'
                                  placeholder='specify job type'
                                  multiline
                                  variant='outlined'
                                  fullWidth
                                  onChange={value =>
                                    setFieldValue('job', value.name)
                                  }
                                  InputProps={{
                                    className: classes.input,
                                    inputProps: {
                                      className: classes.inputStyle
                                    }
                                  }}
                                  InputLabelProps={{
                                    className: classes.inputLabel
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        );
                      }
                    })}
                    {values.jobType.map((job, idx) => {
                      if (job['isVisible']) {
                        return (
                          <React.Fragment key={idx}>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={10}
                                style={{ margin: '5px' }}
                              >
                                <ExpansionPanel>
                                  <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel2a-content'
                                    id='panel2a-header'
                                    style={{
                                      height: '40px',
                                      fontSize: '0.8rem'
                                    }}
                                  >
                                    <Typography>
                                      {values.jobType[idx].jobtype}
                                    </Typography>
                                    <Typography style={{marginLeft: '270px'}}>
                                    {values.jobType[idx].jobtype ? 'Add Job description & Special Req' : null }
                                    </Typography>
                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails>
                                    <Grid container>
                                      {console.log(this.state.allEntities)} 
                                      {this.state.allEntities[idx]
                                        ? this.state.allEntities[idx].map(
                                            (requirement, index) => {
                                              return (
                                                <>
                                                  <Grid
                                                    key={index + 1}
                                                    item
                                                    xs={2}
                                                    className={classes.gap}
                                                  >
                                                    
                                                    {
                                                      this.state.labels[idx][
                                                        index
                                                      ]
                                                    }
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={4}
                                                    className={classes.gap}
                                                  >
                                                    <FormControl
                                                      variant='outlined'
                                                      fullWidth
                                                    >
                                                      <InputLabel htmlFor='outlined-age-native-simple'>
                                                        {/* {Object.values(requirement)[0]} */}
                                                        
                                                      </InputLabel>
                                                      <Select
                                                        key={idx}
                                                        value={values.specialRequirement[idx]?
                                                             values.specialRequirement[idx]["experience"]=== null? 'Fresher': 
                                                             values.specialRequirement[ idx][this.state.typesToRender[index]]: ''
                                                        }
                                                        native
                                                        onChange={event => {
                                                          // console.log(event.target.value)
                                                          // console.log(this.state.typesToRender[index])
                                                          // console.log(
                                                          //   values.specialRequirement
                                                          // );
                                                          const values1 = [
                                                            ...this.state.requirementsContainer
                                                          ];
                                                          values1[idx][
                                                            this.state.typesToRender[
                                                              index
                                                            ]
                                                          ] =
                                                            event.target.value==='None'?null: event.target.value;
                                                          const values2 = [];
                                                          console.log("targetValue", values1[idx][
                                                            this.state.typesToRender[
                                                              index
                                                            ]
                                                          ])
                                                          const updatedValues = this.state.typesToRender.map(
                                                            (element, id) => {
                                                              if (id === idx) {
                                                                [
                                                                  element
                                                                ] = event.target.value;
                                                              }
                                                              return element;
                                                            }
                                                          );
                                                          // console.log(values.specialRequirement)
                                                          values2[
                                                            idx
                                                          ] = updatedValues;
                                                          console.log(values1)
                                                          setFieldValue(
                                                            'specialRequirement',
                                                            values1
                                                          );
                                                        }}
                                                        label={
                                                          Object.values(
                                                            requirement
                                                          )[0]
                                                        }
                                                        inputProps={{
                                                          name: Object.values(
                                                            requirement
                                                          )[0],
                                                          id: `outlined-age-native-simple${index}`
                                                        }}
                                                        id={
                                                          this.state
                                                            .typesToRender[idx]
                                                        }
                                                      >
                                                        {
                                                          (this.state.labels[idx][index]!=="Experience")&&
                                                            <option
                                                                key='nullValue'
                                                                value={null}
                                                              >
                                                                None
                                                              </option>
                                                        }
                                                          {Object.values(requirement).map((el, index) => (
                                                            <option
                                                              key={index}
                                                              value={el}
                                                            >
                                                              {el}
                                                            </option>
                                                          ))}
                                                      </Select>
                                                    </FormControl>
                                                  </Grid>
                                                </>
                                              );
                                            }
                                          )
                                        : null}


                                      <Grid
                                        item
                                        xs={12}
                                        className={classes.gap}
                                      >
                                        Job Description
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        className={classes.gap}
                                      >
                                        <TextField 
                                          rows={10}
                                          style={{ width: '100%' }}
                                          id='textarea'
                                          inputProps={{
                                            maxlength: 1500
                                          }}
                                          variant="outlined"
											                    multiline
                                          helperText={
                                          `${(values&&values.specialRequirement[idx])?values.specialRequirement[idx].textarea?values.specialRequirement[idx].textarea.length:0:0}/1500`}
                                          key={idx}
                                          // value={values.jobType[idx].specialRequirement?values.jobType[idx].specialRequirement.textarea:''}
                                          onChange={event => {
                                            
                                            
                                            const updatedValues = values.specialRequirement.map(
                                              (element, id) => {
                                                if (id === idx) {
                                                  element.textarea =
                                                    event.target.value;
                                                }                                                
                                                return element;
                                              }
                                            );
                                            setFieldValue(
                                              'specialRequirement',
                                              updatedValues
                                            );
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        );
                      } else {
                        return null;
                      }
                    })}
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.textheading}>
                        Job Location
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      // style={{ display: 'flex', justifyContent: 'center' }} // --Akshat
                    >
                      <TextField
                        className={classes.text_container}
                        id='google-autocomplete-input-worker'
                        variant='outlined'
                        onKeyPress={event =>
                          event.key === 'Enter' ? event.preventDefault() : null
                        }
                        label='Search Google Maps'
                        name='search Address'
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        error={errors.addressLine1 && touched.addressLine1}
                        readOnly
                        label='Address Line 1'
                        name='addressLine1'
                        value={values.addressLine1}
                        onChange={handleChange}
                        helperText={
                          errors.addressLine1 &&
                          touched.addressLine1 &&
                          errors.addressLine1
                        }
                        InputProps={{
                          className: classes.input,
                          readOnly: Boolean(true),
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        error={errors.addressLine2 && touched.addressLine2}
                        label='Landmark'
                        name='addressLine2'
                        value={values.addressLine2}
                        onChange={handleChange}
                        helperText={
                          errors.addressLine2 &&
                          touched.addressLine2 &&
                          errors.addressLine2
                        }
                        InputProps={{
                          className: classes.input,
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        error={errors.city && touched.city}
                        label='City'
                        name='city'
                        onChange={handleChange}
                        readOnly
                        value={values.city}
                        helperText={errors.city && touched.city && errors.city}
                        InputProps={{
                          readOnly: Boolean(true),
                          className: classes.input
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        error={errors.state && touched.state}
                        label='State'
                        onChange={handleChange}
                        name='state'
                        readOnly
                        value={values.state}
                        helperText={errors.city && touched.city && errors.city}
                        InputProps={{
                          className: classes.input,
                          readOnly: Boolean(true),
                          // inputProps: {
                          //   className: classes.inputStyle
                          // }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className={classes.text_container}
                        variant='outlined'
                        type='number'
                        error={errors.pincode && touched.pincode}
                        label='Pincode'
                        name='pincode'
                        onChange={handleChange}
                        value={values.pincode}
                        readOnly
                        helperText={
                          errors.pincode && touched.pincode && errors.pincode
                        }
                        InputProps={{
                          className: classes.input,
                          readOnly: Boolean(true),
                          inputProps: {
                            className: classes.inputStyle
                          }
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                    <Button
                      type='submit'
                      disabled={this.state.isSubmitting}
                      variant='contained'
                      className={classes.buttonStyle}
                      color='primary'
                    >
                      Submit
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      );
    }
  }
}

export default withStyles(styles)(AddJob);
