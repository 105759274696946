import React, { Component} from 'react';
import Constant from '../../../constant';
import CustomTable from '../../../utils/Table/Table';
import { RingLoader } from 'react-spinners';
import './employer.css';
import * as moment from 'moment'
import FilterBase from '../../../utils/filters/filterbase'
import Axios from 'axios';
import employerUtils, { getEmployerFilterList } from './employerConstants';
import { Grid, Card } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import HideUnhideColumns from '../../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../../utils/CustomFilter/CustomFilterEmployerList';
import urls from '../../../urlLinks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mapFiltersToLink } from '../../../utils/mapFiltersToLink/mapFiltersToLink';
import { withStyles } from '@material-ui/core/styles';
import utils from '../../../utilMethods';
import Select from '../../../utils/simpleSelect/nativeSelect';

const style = {
  SearchBar: {
    marginTop: '-1%',
    marginLeft: '25%'
  }
};

class Employer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type:'',
      label:'',
      employer: [],
      url: `${urls.baseUrl}${urls.employerUrls.getEmployerList}`,
      companyList:[],
      paramsAvailable: false,
      toggleCustomFilter: false,
      isLoading: true,
      isTableUpdating: false,
      data: [],
      columns: [
        {
          id: '1',
          name: 'status',
          label: Constant.employer.status,
          isColumnVisible: true
        },
        {
          id: '2',
          name: 'employerId',
          label: Constant.employer.employerId,
          isColumnVisible: true
        },
        {
          id: '3',
          name: 'appRegistered',
          label: Constant.employer.appRegistered,
          isColumnVisible: true
        },
        {
          id: '4',
          name: 'companyName',
          label: Constant.employer.companyName,
          isColumnVisible: true
        },
        {
          id: '5',
          name: 'brandName',
          label: Constant.employer.brandName,
          isColumnVisible: true
        },
        {
          id: '6',
          name: 'industry',
          label: Constant.employer.industry,
          isColumnVisible: true
        },
        {
          id: '7',
          name: 'city',
          label: Constant.employer.city,
          isColumnVisible: true
        },
        {
          id: '8',
          name: 'contactPerson',
          label: Constant.employer.contactPerson,
          isColumnVisible: true
        },
        {
          id: '9',
          name: 'phone',
          label: Constant.employer.phone,
          isColumnVisible: true
        },
        {
          id: '10',
          name: 'email',
          label: Constant.employer.email,
          isColumnVisible: true
        },
        {
          id: '11',
          name: 'registrationDate',
          label: Constant.employer.registrationDate,
          isColumnVisible: true
        },
        {
          id: '12',
          name: 'addedBy',
          label: Constant.employer.addedBy,
          isColumnVisible: true
        },
        {
          id: '13',
          name: 'odActive',
          label: Constant.employer.odActive,
          isColumnVisible: true
        },
        {
          id: '14',
          name: 'odInactive',
          label: Constant.employer.odInactive,
          isColumnVisible: true
        },
        {
          id: '15',
          name: 'ptFtActive',
          label: Constant.employer.ptFtActive,
          isColumnVisible: true
        },
        {
          id: '16',
          name: 'ptFtInactive',
          label: Constant.employer.ptFtInactive,
          isColumnVisible: true
        },
        {
          id: '107',
          name: 'inActivejobs',
          label: Constant.employer.inActivejobs,
          isColumnVisible: true
        },
        {
          id: '17',
          name: 'hired',
          label: Constant.employer.hired,
          isColumnVisible: true
        },
        {
          id: '108',
          name: 'activeHiring',
          label: Constant.employer.activeHiring,
          isColumnVisible: true
        },
        {
          id: '18',
          name: 'totalPositions',
          label: Constant.employer.totalPositions,
          isColumnVisible: false
        },
        {
          id: '19',
          name: 'rating',
          label: Constant.employer.rating,
          isColumnVisible: true
        },
        // {
        //   id: '190',
        //   name: 'Review',
        //   label: Constant.employer.review,
        //   isColumnVisible: true
        // },
        {
          id: '20',
          name: 'pendingPayment',
          label: Constant.employer.pendingPayment,
          isColumnVisible: true
        },
        {
          id: '21',
          name: 'updatedOn',
          label: Constant.employer.updatedOn,
          isColumnVisible: true
        },
        {
          id: '22',
          name: 'action',
          label: Constant.employer.action,
          isColumnVisible: true
        }
      ],
      filters: {
        location:[],
        brandName:[],
        employer_status:[""],
        rating:0,
        registrationDate:[null, null],
      },
      filterUrl:'',
      currentPage: 0,
      count: 0,
      rowsPerPage: 100,
      open: false, 
      sortAccessed: false,
      sortParam:'',
      sortingDirection: true

    };
    this.SData='';
    this.OData='';
    this.option = [
      {
        id: 1,
        type: 2,//String
        label: 'Company Name',
        eValue:'companyName'
      },
      {
        id: 2,
        type:2,//String
        label:'Brand Name',
        eValue:'brandName'
      },
      {
        id: 3,
        type:2,
        label:'Location',
        eValue:'city'
      },
      {
        id: 4,
        type: 1,//integer
        label: 'EmployerID',
        eValue:'employerId'
      },
      {
        id:5,
        type: 3,//date
        label: 'OnBoarding Date',
        eValue:'registrationDate'
      },
      {
        id:6,
        type:3,
        label:'Last Updated On',
        eValue:'updatedOn'
      },
      {
        id:7,
        type:1,
        label:'Employer Status',
        eValue:'employerStatus'
      },
      {
        id:8,
        type:2,
        label:'Contact Person',
        eValue:'contactPerson'
      },
      {
        id:9,
        type:2,
        label:'Industry',
        eValue:'industry'
      },
      // {
      //   id:11,
      //   type: 2,//date
      //   label: 'Added By',
      //   eValue:'addedBy' 
      // },
      {
        id:12,
        type:2,
        label:'Active Hiring',
        eValue:'totalPositions'
      },
      ,
      {
        id:13,
        type:2,
        label:'Hired',
        eValue:'hired'
      },
      {
        id:13,
        type:2,
        label:'Rating',
        eValue:'rating'
      },
      // {
      //   id:14,
      //   type:2,
      //   label:'Reviews',
      //   eValue:'reviews'
      // },
      {
        id:15,
        type:2,
        label:'OD Active',
        eValue:'odActive'
      },
      {
        id:16,
        type:2,
        label:'OD Inactive',
        eValue:'odInactive'
      },
      {
        id:17,
        type:2,
        label:'PT-Ft Active',
        eValue:'ptFtActive'
      },
      {
        id:18,
        type:2,
        label:'PT-Ft Inactive',
        eValue:'ptFtInactive'
      },
    ];
    this.rowsOptions = [5, 10, 50, 100, 250, 500];
    this.updateColumnFileds = this.updateColumnFileds.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.updateDataOnTablePagination = this.updateDataOnTablePagination.bind(
      this
    );
    this.getEmployerList = this.getEmployerList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.sortData=this.sortData.bind(this);
    // this.FData=this.FData.bind(this);
  }

  updateColumnFileds(columnsToHide) {
    const upadtedColumns = this.state.columns.map(col => {
      if (columnsToHide.includes(col.id)) {
        col.isColumnVisible = true;
      } else {
        col.isColumnVisible = false;
      }
      return col;
    });
    this.setState({
      columns: upadtedColumns
    });
  }

  async updateFilters(filters) {
    let url='';
    if(filters["location"].length!==0){
      let cityIds = []
      filters["location"].map(city => cityIds.push(city.id))
      url=`${url}&city_id=${cityIds}`
    }
    if(filters["brandName"].length!==0){
      let companyIds = []
      filters["brandName"].map(brand => companyIds.push(brand.id))
      url=`${url}&user_id=${companyIds}`
    } 
    if(!filters["employer_status"].includes(""))
      url=`${url}&status=${filters["employer_status"]}`
    if(filters["rating"])
      url=`${url}&rating=${filters["rating"]}`
    if(filters["registrationDate"][0] && !filters["registrationDate"][1])
      url=`${url}&registered_from=${moment(filters["registrationDate"][0]).format("YYYY-MM-DD")} 00:00:00&registered_to=${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`
    if(!filters["registrationDate"][0] && filters["registrationDate"][1])
      url=`${url}&registered_from=2019-01-01 00:00:00&registered_to=${moment(filters["registrationDate"][1]).format("YYYY-MM-DD")} 00:00:00`
    if(filters["registrationDate"][0] && filters["registrationDate"][1])
      url=`${url}&registered_from=${moment(filters["registrationDate"][0]).format("YYYY-MM-DD")} 00:00:00&registered_to=${moment(filters["registrationDate"][1]).format("YYYY-MM-DD")} 00:00:00`
    console.log({url});
    this.setState({filters: filters, currentPage: 0, filterUrl: url, },  () => this.getEmployerList())
  }
  resetFilters =() =>{
    const filters= {
      location:[],
      brandName:[],
      employer_status:[""],
      rating:0,
      registrationDate:[null, null],
    }
    this.setState({filterUrl: '', currentPage: 0, filters: filters}, () => this.getEmployerList())
    return filters;
  }
  updateDataOnTablePagination(
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) {
    this.setState({ isTableUpdating: true }, () => {
      this.getEmployerList(rowsPerPage, nextPage);
    });
  }

  async getEmployerList(
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) {
    // console.log(this.state.url);
    
    let url = '';
    url = `${this.state.url}?page_no=${nextPage}&rows=${rowsPerPage}${this.state.filterUrl}`;
    if(this.state.sortAccessed && this.state.sortingDirection)
      url=`${url}${this.state.sortParam}&sortBy=ascending`
    if(this.state.sortAccessed && !this.state.sortingDirection)
      url=`${url}${this.state.sortParam}`
    const validExpiry = await utils.checkValidExpiry();
    if(validExpiry) {
      Axios.get(url)
        .then(response => {
          // console.log(url);
          console.log(response.data.response.content);
           this.SData= [...response.data.response.content];
           this.OData= [...response.data.response.content];
          // console.log(SData);
          this.setState({
            employer: this.makedata(response.data.response.content),
            count: response.data.response.totalElements,
            currentPage: nextPage,
            rowsPerPage: rowsPerPage,
            isLoading: false,
            isTableUpdating: false
          });
          
        })
        .catch(err => {
          if (this.state.paramsAvailable) {
            toast.error(
              `Error While loading Filtered Employer List, Please Try To Reset Filters`
            );
          } else {
            toast.error('Error While loading Employer List');
          }
        });
      }
  }

  // FData(){
  //   this.setState({
  //     employer: this.makedata(this.SData),
  //     count: response.data.response.totalElements,
  //     currentPage: nextPage,
  //     rowsPerPage: rowsPerPage,
  //     isLoading: false,
  //     isTableUpdating: false
  //   });
  // }

  makedata = data => {
    const extractedData = [];
    const allAdmins = JSON.parse(localStorage.getItem('allAdmins'));
    data.map(el => {
      extractedData.push({
        status: el.status ? el.status : '-',
        employerId: el.employerId ? el.employerId : '-',
        appRegistered:el.insetedBy
        ? allAdmins[el.insetedBy]
          ? 'No'
          : 'Yes'
        : '-',
        userId: el.userId ? el.userId : '-',
        companyName: el.companyName ? el.companyName : '-',
        brandName: el.brandName ? el.brandName : '-',
        industry: el.industry ? el.industry : '-',
        city: el.newCity?`${el.newCity}${el.newState?", " :''}${el.newState?el.newState:''}`: '-',
        contactPerson: el.contactPerson ? el.contactPerson : '-',
        phone: el.phone ? el.phone : '-',
        email: el.email ? el.email : '-',
        registrationDate: el.registrationDate ? el.registrationDate : '-',
        // addedBy: el.addedBy
        //   ? el.addedBy.split(" ")[1].toLowerCase() === 'admin'
        //     ? 'Admin'
        //     : 'Self'
        //   : '-',
        addedBy: el.insetedBy
          ? allAdmins[el.insetedBy]
            ? allAdmins[el.insetedBy]
            : 'Self'
          : '-',
        odActive: el.odActive ? el.odActive : 0,
        odInactive: el.odInactive ? el.odInactive : 0,
        ptFtActive: el.ptFtActive ? el.ptFtActive : 0,
        ptFtInactive: el.ptFtInactive ? el.ptFtInactive : 0,
        activeHiring: el.totalPositions - el.hired,
        hired: el.hired ? el.hired : 0,
        inActivejobs: el.odInactive + el.ptFtInactive,
        totalPositions: el.totalPositions ? el.totalPositions : 0,
        rating: el.rating ? el.rating : 0,
        pendingPayment: el.pendingPayment ? el.pendingPayment : 0,
        updatedOn: el.updatedOn
          ? el.updatedOn
              .substring(0, 10)
              .toString()
              .split('-')
              .reverse()
              .join('-')
          : '-'
      });
    });
    return extractedData;
  };

  getCompanyList = (data) =>{
    let list=[];
    data.map(company =>{
      list.push({
        id: company.userId,
        name: company.brandName?company.brandName: company.companyName
      })
    })
    this.setState({companyList: list});
  }



  handleChange (event){
    
    let value=event.target.value.eValue;
    let sortParam='';
    if(value==='companyName') sortParam='&orderBy=c.company_name'
    if(value==='brandName') sortParam='&orderBy=c.brand_name'
    if(value==='city') sortParam='&orderBy=city'
    if(value==='employerId') sortParam='&orderBy=um.user_id'
    if(value==='registrationDate') sortParam='&orderBy=em.inserted_on'
    if(value==='updatedOn') sortParam='&orderBy=em.updated_on'
    if(value==='employerStatus') sortParam='&orderBy=um.user_status'
    if(value==='contactPerson') sortParam='&orderBy=um.first_name'
    if(value==='industry') sortParam='&orderBy=industry'
    if(value==='addedBy') sortParam='&orderBy=added_by'
    if(value==='totalPositions') sortParam='&orderBy=total_positions'
    if(value==='hired') sortParam='&orderBy=hired'
    if(value==='rating') sortParam='&orderBy=em.average_rating'
    // if(value==='reviews') sortParam='&orderBy=em.total_reviews'
    if(value==='odActive') sortParam='&orderBy=od_active'
    if(value==='odInactive') sortParam='&orderBy=od_inactive'
    if(value==='ptFtActive') sortParam='&orderBy=ptft_active'
    if(value==='ptFtInactive') sortParam='&orderBy=ptft_inactive'
    
    this.setState(
      {
        label:event.target.value,type:event.target.value.type, 
        sortAccessed: true , 
        sortParam: sortParam
      }, 
      () => {
        this.getEmployerList();
      }
    )
  }
  componentDidMount() {
    Axios.get(`${urls.baseUrl}company/?rows=1000`)
    .then(response =>{  
        console.log({response});
        
        this.getCompanyList(response.data.response.content)
      })
    this.getEmployerList();
  }



  render() {
    const { classes } = this.props;
    const tablePagination = {
      currentPage: this.state.currentPage,
      count: this.state.count,
      changePage: this.updateDataOnTablePagination,
      rowsOptions: this.rowsOptions,
      rowsPerPage: this.state.rowsPerPage,
      tableUpdating: this.state.isTableUpdating
    };

    return this.state.isLoading ? (
      <RingLoader
        css={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto'
        }}
        color={'#194D33'}
      ></RingLoader>
    ) : (
      <div>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={4000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <Card style={{ margin: '20px 0px', padding: '20px' }}>
          <Grid container>
            <Grid item sm={3}>
              <HideUnhideColumns
                columns={this.state.columns}
                toggleColumn={this.updateColumnFileds}
              ></HideUnhideColumns>
            </Grid>
            <Grid item sm={3}>
              <FilterBase
                filters={this.state.filters}
                updateFilters={this.updateFilters}
                resetFilters={this.resetFilters}
                companyList={this.state.companyList}
                page="employerDB"
              />
            </Grid>
            <Grid item sm={2} >
              <Select
                val={this.state.label}
                type={this.state.type}
                option={this.option}
                handleChange={this.handleChange}>
              </Select>
            </Grid>
            <Grid item sm={1} >
             {this.state.sortAccessed && 
                <ToggleButton
                    className="sortDir"
                    value="check"
                    selected={this.state.sortingDirection}
                    onChange={() => {
                      this.setState({sortingDirection: !this.state.sortingDirection}, 
                        () => this.getEmployerList()
                      )
                    }}
                  >
                    {this.state.sortingDirection? 'A to Z':'Z to A' }
                </ToggleButton>
              }
            </Grid>
          </Grid>
        </Card>
        <CustomTable
          name={Constant.employer.idName}
          userId={'userId'}
          columns={this.state.columns}
          data={this.state.employer}
          numericIndex={true}
          links={employerUtils.employerListAction}
          tablePagination={tablePagination}
          title='Employer Details'
        ></CustomTable>
      </div>
    );
  }
}

export default withStyles(style)(Employer);
