import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import CustomMultiSelectDropDown from '../CustomMultiselectDropDown/CustomMultiSelectDropDown';
import DatePicker from '../DatePicker/DatePicker';
import Select from '../simpleSelect/simpleSelect';
const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '700px',
    minWidth: '300px'
  },
  gridItemStyle: {
    padding: '10px !important'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class CustomFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultValue: [{ employer_id: [] }, { city_id: [] }, { job_type: [] }, { job_status: []}, { inserted_by: []}],
      dates: null,
      postedDates: null,
      start: null,
      end: null,
      postedStart: null,
      postedEnd: null,
      start_Label: 'Job-Start-Date',
      end_Label: 'Job-End-Date',
      posted_from: 'Job-Posted-From',
      posted_to: 'Job-Posted-To',
      open: this.props.isOpen,
      anchorEl: <b></b>,
      worktype: null
    };

    this.option = [
      {
        id: 1,
        rating: 'Full Time',
        label: 'Full Time'
      },
      {
        id: 2,
        rating: 'Part Time',
        label: 'Part Time'
      },
      {
        id: 3,
        rating: 'On Demand',
        label: 'On Demand'
      }
    ];

    this.label = 'Work Type';

    this.defaultValue = this.state.defaultValue;
    this.getFilterElemnts = this.getFilterElemnts.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  handleSelectChange = val => {
    this.setState({ worktype: val });
  };

  recieveDates = (startDate, endDate) => {
    // console.log(startDate);
    // console.log(endDate);
    const dates = {
      start_date: startDate,
      end_date: endDate
    };
    this.setState({ dates: dates });
  };
  recievePostedDates = (startDate, endDate) => {
    // console.log(startDate);
    // console.log(endDate);
    const postedDates = {
      posted_from: startDate,
      posted_to: endDate
    };
    this.setState({ postedDates: postedDates });
  };

  handleInputChange(name, data) {
    const defaultValues = [...this.state.defaultValue];
    for (let el of this.defaultValue) {
      if (el[name]) {
        if (name === 'employer_id') {
          defaultValues[0] = {
            [name]: data
          };
        } else if (name === 'city_id') {
          defaultValues[1] = {
            [name]: data
          };
        } else if (name === 'job_type') {
          defaultValues[2] = {
            [name]: data
          };
        }
        else if (name === 'job_status') {
          defaultValues[3] = {
            [name]: data
          };
        }
        else if (name === 'inserted_by') {
          defaultValues[4] = {
            [name]: data
          };
        }
        el[name] = data;
      }
    }
    // console.log(defaultValues)
    // console.log(this.defaultValue)
    this.setState({
      defaultValue: defaultValues
    });
  }

  handleRequestClose(event) {
    this.setState({
      open: !this.state.open,
      anchorEl: event.currentTarget
    });
  }

  resetFilters() {
    // for (let el of this.defaultValue) {
    //   const key = Object.keys(el)[0];
    //   el[key] = [];
    // }
    this.setState(
      {
        defaultValue: [{ employer_id: [] }, { city_id: [] }, { job_type: [] } , { job_status: []}, { inserted_by: []}],
        dates: null,
        postedDates: null,
        start: null,
        end: null,
        postedStart: null,
        postedEnd: null,
        worktype: ''
      },
      () =>
        this.props.updateFilters(
          this.state.defaultValue,
          this.state.dates,
          this.state.postedDates,
          this.state.worktype
        )
    );
  }

  getFilterElemnts(classes) {
    const filters = this.props.filters.map((element, index) => {
      // console.log(element)
      return (
        <Grid
          item
          xs={6}
          key={element.id}
          className={classes.gridItemStyle}
        >
          <CustomMultiSelectDropDown
            default={this.state.defaultValue[index][element.name]}
            updatedDefault={element.default}
            data={element[element.name]}
            name={element.name}
            label={element.label}
            updateChange={this.handleInputChange}
          ></CustomMultiSelectDropDown>
        </Grid>
      );
    });
    return filters;
  }

  componentDidMount() {
    this.setState({
      defaultValue: [{ employer_id: [] }, { city_id: [] }, { job_type: [] } , { job_status: []}, { inserted_by: []}],
      dates: null,
      start: null,
      end: null,
      postedStart: null,
      postedEnd: null,
      worktype: ''
    });
  }

  render() {
    if (this.state.dates) {
      // console.log(this.state.dates.registered_from.substring(0, 10));
      // console.log(new Date(this.state.dates.registered_from.substring(0, 10)));
    }
    return (
      <PopupState variant='popover' popupId={'popover' + this.props.id}>
        {popupState => (
          <div>
            <Button
              variant='contained'
              color='primary'
              {...bindTrigger(popupState)}
              className={this.props.classes.buttonTextSize}
              onClick={this.handleRequestClose}
            >
              Filter
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                id: 2,
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.open}
              anchorEl={this.state.anchorEl}
            >
              <Grid
                container
                spacing={3}
                className={this.props.classes.gridStyle}
              >
                {this.getFilterElemnts(this.props.classes)}
                {this.props.jobType === 'jobList' ? (
                  <Grid item xs={6}>
                    <Select
                      ratingValue={this.state.worktype}
                      option={this.option}
                      label={this.label}
                      handleChange={this.handleSelectChange}
                    ></Select>
                  </Grid>
                ) : null}
                <Grid item xs={4} style={{ marginLeft: '1px' }}>
                  {' '}
                  <DatePicker
                    sendData={this.recievePostedDates}
                    start={
                      this.state.postedDates
                        ? this.state.postedDates.posted_from
                          ? new Date(
                              this.state.postedDates.posted_from.substring(
                                0,
                                10
                              )
                            )
                          : null
                        : null
                    }
                    end={
                      this.state.postedDates
                        ? this.state.postedDates.posted_to
                          ? new Date(
                              this.state.postedDates.posted_to.substring(0, 10)
                            )
                          : null
                        : null
                    }
                    label={this.state.posted_from}
                    label1={this.state.posted_to}
                  ></DatePicker>{' '}
                </Grid>
                <Grid item xs={4} style={this.props.jobType === 'jobList' ? { marginLeft: '110px' } : { marginTop: '-100px' }}>
                  {' '}
                  <DatePicker
                    sendData={this.recieveDates}
                    start={
                      this.state.dates
                        ? this.state.dates.start_date
                          ? new Date(
                              this.state.dates.start_date.substring(0, 10)
                            )
                          : null
                        : null
                    }
                    end={
                      this.state.dates
                        ? this.state.dates.end_date
                          ? new Date(this.state.dates.end_date.substring(0, 10))
                          : null
                        : null
                    }
                    toMax={true}
                    label={this.state.start_Label}
                    label1={this.state.end_Label}
                  ></DatePicker>{' '}
                </Grid>
              </Grid>

              <Divider />
              <Grid container className={this.props.classes.gridStyle}>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.resetFilters(true);
                      // this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      // this.resetFilters(false);
                      //   console.log(this.defaultValue)
                      this.props.updateFilters(
                        this.state.defaultValue,
                        this.state.dates,
                        this.state.postedDates,
                        this.state.worktype
                      );
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Apply
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>
        )}
      </PopupState>
    );
  }
}

export default withStyles(styles)(CustomFilter);
