import React, {Component}from 'react';
import TextField from '@material-ui/core/TextField';
import { Typography,Container,Grid,withStyles,Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = () => ({
    root: {
      margin: 'auto',
      flexGrow: 1,
    },
    margin: {
      margin: '2px',
    },
    textField: {
        minWidth:'500px',
        maxWindth:'500px'
      },
});

class SimpleTextField extends Component{
    constructor(props) {
        super(props);
        this.state = {
            value:this.props.value
        }; 
        
        this.label='Custom Question';
        // this.obj=this.props.obj;
    }

    handleChange=(event)=>{  
        this.setState({value:event.target.value},
           ()=>this.props.CustomQuestionHandleChange(this.state.value));
    }

    render() {
      console.log(this.props)
        const { classes } = this.props;
        return (
            
            <FormControl fullWidth  variant="outlined" className={classes.textField}>
          <InputLabel htmlFor="outlined-adornment-amount">Custom Question</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            placeholder="This is a custom question typed by the admin member. It is a free text field."   
            value={this.state.value}
            onChange={this.handleChange}
            startAdornment={<InputAdornment position="start">Q.</InputAdornment>}
            labelWidth={130}
          />
        </FormControl>
            
          );
    }

}
export default withStyles(styles)(SimpleTextField);