import React, {useState} from 'react'
import {Grid, Link } from '@material-ui/core';
import * as moment from 'moment'
import WeekToggle from './weektoggleButton' 


const InterviewDetails = (props) =>{
    const data=props.data
    
    
    // const assignSlots =(slot) =>{
    //     const today= (((new Date()).getDay())+6)%7;
    //     let count =0;
    //     let nextSlots=[]
    //     const days= Object.keys(slot)
    //     let interviewDate=1;
    //     while (count<3) {
    //         const len=slot[days[(today+interviewDate)%7]].length
    //         if(len>0)
    //         {   
    //             var i=0
    //             while(i<len)
    //             {  
    //                 nextSlots.push(
    //                     {
    //                         date: moment(new Date()).add(interviewDate, 'days').format("DD/MM/YYYY"),
    //                         startTime: moment(slot[days[(today+interviewDate)%7]][i].startTime, "hh:mm").format("hh:mm A"),
    //                         endTime: moment(slot[days[(today+interviewDate)%7]][i].endTime, "hh:mm").format("hh:mm A"),
    //                     }
    //                 )
    //                 i++;
    //                 count++;
    //             }
    //         }
    //         interviewDate++;
    //     }
    //     return nextSlots;
    // }
    // const interviewArraytoSlots =(data) =>{
    //     const slot= {
    //         monday:[],
    //         tuesday:[],
    //         wednesday:[],
    //         thursday:[],
    //         friday:[],
    //         saturday:[],
    //         sunday:[]
    //     }
    //     data.map(i =>{
    //         i.weekdays.map(day =>{
    //             slot[day].push({startTime: moment(i.startTime).format("HH:mm"), endTime: moment(i.endTime).format("HH:mm")});
    //         })
    //     })
    //     Object.keys(slot).forEach(function(key) {
    //         slot[key].sort(function(a, b){ 
    //             return (a.startTime).localeCompare(b.startTime);
    //         });
    //     });
    //     const result= assignSlots(slot);
    //     return result;
    // }

    
    const values={
        interviewAddress: data.detailsJobs[0].jobInterviewDetails?
            data.detailsJobs[0].jobInterviewDetails.interviewAddress?
                data.detailsJobs[0].jobInterviewDetails.interviewAddress:'-'
            :'-' ,
        interviewProcess: data.detailsJobs[0].jobInterviewDetails?
            data.detailsJobs[0].jobInterviewDetails.interviewProcess?
                data.detailsJobs[0].jobInterviewDetails.interviewProcess:'-'
            :'-',
        lat:data.detailsJobs[0].jobInterviewDetails?
            data.detailsJobs[0].jobInterviewDetails.interviewLat?
                data.detailsJobs[0].jobInterviewDetails.interviewLat:null
            :null,
        long:data.detailsJobs[0].jobInterviewDetails?
            data.detailsJobs[0].jobInterviewDetails.interviewLong?
                data.detailsJobs[0].jobInterviewDetails.interviewLong:null
            :null,
        // interviewSlots:  interviewArraytoSlots(JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays)),
        interviewSlots:  data.detailsJobs[0].jobInterviewDetails?
            data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays? 
                JSON.parse(data.detailsJobs[0].jobInterviewDetails.preferredInterviewDays):[]
            :[],
        interviewDesc: data.detailsJobs[0].jobInterviewDetails?
            data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement?
                data.detailsJobs[0].jobInterviewDetails.joiningSpecialRequirement:'-'
            :'-',
    }
    console.log({values});
    
    return (
        <Grid container spacing={0} className="jobDetail">
            <Grid item xs={12} className="subHeading">
            <span>Interview details</span>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Interview address</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.interviewAddress}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Google map link</span>
                </Grid>
                <Grid item xs={8} className="value">
                    {(values.lat && values.long)?
                        <Link href={`https://www.google.com/maps/search/${values.lat},${values.long}`} target="_blank">
                            Interview Location
                        </Link>:
                        <span>No link</span>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Interview process</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.interviewProcess}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Preferred interview day and slot</span>
                </Grid>
                <Grid item xs={8}>
                    <Grid container>
                        {/*{values.interviewSlots.map(slot => 
                            <Grid item xs>
                                <Grid item xs={12} className="fieldRow">
                                    {slot.date}
                                </Grid>
                                <Grid item xs={12} className="value">
                                    {`${slot.startTime} - ${slot.endTime}`}
                                </Grid>
                            </Grid>
                        )}*/}
                        {values.interviewSlots.length>0?values.interviewSlots.map(slot => 
                            slot.weekdays?slot.weekdays.length>0?
                            <Grid item xs>
                                <Grid item xs={12} className="fieldRow">
                                    <WeekToggle 
                                        value= {slot.weekdays}
                                        isdisabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} className="fieldRow">
                                    {`${moment(slot.startTime).format("hh:mm A")} - ${moment(slot.endTime).format("hh:mm A")}`}
                                </Grid>
                            </Grid>: "Empty slot":null    
                        ): "No slots Selected"}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Joining/ Special requirements</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.interviewDesc}</span>
                </Grid>
            </Grid>
        </Grid>
        
    )
}
export default InterviewDetails;

