import React from 'react'
import {Grid, Link} from '@material-ui/core';
import * as moment from 'moment'
import WeekToggle from './weektoggleButton' 

const JobDetail = (props) =>{
    const data=props.data
    console.log({data});
    
    const convertJobIdtoHex = (val) => {
        var s = "abcde" + val.toString(36);
        return " "+s.substring(s.length - 5).toUpperCase();
    }
    const startTime=data.loginTime?moment(data.loginTime, "hh:mm a").format("hh:mm A"):null;
    const endTime=data.logoutTime?moment(data.logoutTime, "hh:mm a").format("hh:mm A"):null;
    const startDate= data.startDate? data.startDate:null;
    
    const weekTodays =(week) =>{
        const days=[]
        if(week["0"]===true)
            days.push("monday","tuesday","wednesday","thursday","friday","saturday","sunday")
        if(week["2"]===true)
            days.push("monday")
        if(week["3"]===true)
            days.push("tuesday")
        if(week["4"]===true)
            days.push("wednesday")
        if(week["5"]===true)
            days.push("thursday")
        if(week["6"]===true)
            days.push("friday")
        if(week["7"]===true)
            days.push("saturday")
        if(week["1"]===true)
            days.push("sunday")
        if(week["0"]===true)
            days.push("sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday")    
        return days;
    }
    const values= {
        brandName: data.brandName?data.brandName:data.companyName,
        encryptId: data.detailsJobs[0].jobDetailsId?convertJobIdtoHex(data.detailsJobs[0].jobDetailsId):'-',
        jobTitle: data.detailsJobs[0].jobTitle?data.detailsJobs[0].jobTitle:'-',
        workType:data.workType===1?'Full Time':data.workType===2?'Part Time':'On Demand',
        vacancyType: data.detailsJobs[0].vacancyType==="entry"?
            "Entry level"
            :data.detailsJobs[0].vacancyType==="mid"?
                "Mid level"
                :data.detailsJobs[0].vacancyType==="senior"?
                    "Senior level"
                    :'N/a',
        payrollType: data.detailsJobs[0].payrollType==="company"?
            "Company"
            :data.detailsJobs[0].payrollType==="third"?
                "Third Party"
                :data.detailsJobs[0].payrollType==="contract"?
                    "Contract":'N/a',
        noOfOpenings: data.detailsJobs[0].employeeRequired?data.detailsJobs[0].employeeRequired:'-',
        city: data.city_name?`${data.city_name}${data.state_name?", " :''}${data.state_name?data.state_name:''}`:'-',
        ExactJobAddress: data.address?data.address:'-',
        lat:data.jobLocationLat?data.jobLocationLat:null,
        long:data.jobLocationLong?data.jobLocationLong:null,
        salary: data.workType===3?`${props.numberFormat(data.detailsJobs[0].amount)}`:`${props.numberFormat(data.detailsJobs[0].minSalary)} - ${props.numberFormat(data.detailsJobs[0].maxSalary)}`,
        jobDescription: data.detailsJobs[0].additionalRequirement?data.detailsJobs[0].additionalRequirement:'-',
        noOfDays: data.workdaysCount?data.workdaysCount:'-',
        noOfHrs: data.detailsJobs[0].noOfHrs?data.detailsJobs[0].noOfHrs:'-',
        weekdays: data.workdaysDetails?weekTodays(JSON.parse(data.workdaysDetails)): [],
        startTime: startTime,
        endTime: endTime,
        startDate: startDate
    }
    return (
        <Grid container spacing={0} className="jobDetail">
            <Grid item xs={12} className="subHeading">
            <span>Job Details</span>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Job title</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.jobTitle}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Vacancy type</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.vacancyType}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Payroll type</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.payrollType}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Job Category</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.workType}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Number of opening</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.noOfOpenings}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>City</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.city}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Exact Job Address</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.ExactJobAddress}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Google map link</span>
                </Grid>
                <Grid item xs={8} className="value">
                    {(values.lat && values.long)?
                        <Link href={`https://www.google.com/maps/search/${values.lat},${values.long}`} target="_blank">
                            Job Location
                        </Link>:
                        <span>No link</span>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Salary</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.salary}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Job description</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.jobDescription}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Job Schedule</span>
                </Grid>
                <Grid item xs={8}>
                    {data.workType===1?
                        <Grid container>
                            <Grid item xs={5}>
                                <Grid item xs={12} className= "fieldRow">
                                    <span>No. of Days</span>
                                </Grid>
                                <Grid item xs={12}  className="value">
                                    <span>{values.noOfDays}</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid item xs={12} className= "fieldRow">
                                    <span>No. of Hours</span>
                                </Grid>
                                <Grid item xs={12} className="value">
                                    <span>{values.noOfHrs}</span>
                                </Grid>
                            </Grid>
                        </Grid>:
                    data.workType===2?
                        <Grid container>
                            <Grid item xs>
                                <Grid item xs={12} className="fieldRow">
                                    <span>Days</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <WeekToggle 
                                        value= {values.weekdays}
                                        isdisabled={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs>
                                <Grid item xs={12} className="fieldRow">
                                    <span>Time</span>
                                </Grid>
                                <Grid item xs={12} className="value">
                                    <span>{`${values.startTime} - ${values.endTime}`}</span>
                                </Grid>
                            </Grid>
                        </Grid>:
                        data.workType===3?
                        <Grid container>
                            <Grid item xs={5}>
                                <Grid item xs={12} className= "fieldRow">
                                    <span>No. of Days: </span>
                                </Grid>
                                <Grid item xs={12} className="value">
                                    <span>{values.noOfDays}</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} >
                                <Grid item xs={12} className= "fieldRow">
                                    <span>Start Date: </span>
                                </Grid>
                                <Grid item xs={12} className="value">
                                    <span>{values.startDate}</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} className= "fieldRow">
                                    <span>Time: </span>
                                </Grid>
                                <Grid item xs={12} className="value">
                                    <span>{`${values.startTime} - ${values.endTime}`}</span>
                                </Grid>
                            </Grid>
                        </Grid>: null}
                </Grid>
            </Grid>
        </Grid>   
    )
}
export default JobDetail;