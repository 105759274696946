import React from 'react';
import { Button, Container } from '@material-ui/core';
import Experince from './experienceSubField';
import AddBoxIcon from '@material-ui/icons/AddBox';

class DynamicExperince extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.isErrorPropAvailable = false;
    this.addElements = this.addElements.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteElement = this.deleteElement.bind(this);
    this.count = 1;
    this.data = [
      // {
      //   id: 1,
      //   isVisible: true,
      //   industry: '',
      //   Company: '',
      //   jobProfile: '',
      //   // Experince: "",
      //   startDate: null,
      //   endDate: null
      // }
    ];

    this.industry = props.industry.map((el, index) => {
      return {
        id: index,
        type: el,
        isSelected: false
      };
    });
    this.jobTypes = props.jobTypes.map((el, index) => {
      return {
        id: index,
        type: el,
        isSelected: false
      };
    });
    this.Industry = [
      {
        id: '1',
        type: 'Restorent',
        isSelected: false
      },
      {
        id: '2',
        type: 'Mechanical',
        isSelected: false
      }
    ];
    this.Company = '';
    this.jobProfile = [
      {
        id: '1',
        type: 'Waiter',
        isSelected: false
      },
      {
        id: '2',
        type: 'Chef',
        isSelected: false
      }
    ];
    // this.Experince = [
    //     {
    //         id: "1",
    //         year: "1",
    //         isSelected: false
    //     },
    //     {
    //         id: "2",
    //         year: "2",
    //         isSelected: false
    //     }
    // ]
    this.state = {
      expArr: [],
      elements: [
        // {
        //   id: 1,
        //   isVisible: true,
        //   component: (
        //     <Experince
        //       id={1}
        //       errors={this.props.errors}
        //       name={this.props.name}
        //       deleteItem={this.deleteElement}
        //       updateData={this.updateData}
        //       Company={this.Company}
        //       industry={this.industry}
        //       jobProfile={this.jobTypes}
        //       // Experince={this.Experince}
        //       values={this.data[0]}
        //     />
        //   )
        // }
      ],
      counter: 0,
      errors: this.props.errors,
      isErrorPropAvailable: false,
      touched: this.props.touched
    };
  }
  updateData(id, values) {
    this.data = this.data.map(
      data => {
        if (data.id === id) {
          data = values;
        }
        return data;
      },
      id,
      values
    );
    // const newValuesArray= this.data.map((data)=>{
    //     return data.isVisible !== false;
    // });
    //  console.log(newValuesArray);
    this.props.setFieldValue(this.props.name, this.data);
  }

  addElements() {
    if (this.count < 7) {
      this.count++;
      let element = {};
      element['id'] = this.state.counter;
      element['isVisible'] = true;
      element['component'] = (
        <Experince
          id={this.state.counter}
          errors={this.props.errors}
          name={this.props.name}
          deleteItem={this.deleteElement}
          updateData={this.updateData}
          Company={this.Company}
          industry={this.industry}
          jobProfile={this.jobTypes}
          // Experince={this.Experince}
          values={{
            id: this.state.counter,
            industry: '',
            Company: '',
            jobProfile: '',
            // Experince: "",
            isVisible: true,
            startDate: null,
            endDate: null
          }}
        />
      );
      const newElements = [...this.state.expArr, element];
      this.setState({
        expArr: newElements,
        counter: this.state.counter + 1
      });
      this.data.push({
        id: this.state.counter,
        isVisible: true,
        industry: '',
        Company: '',
        jobProfile: '',
        // Experince: "",
        startDate: null,
        endDate: null
      });
      // console.log(newElements);

      const newValuesArray = newElements.map(data => {
        return data;
      });
      this.props.setFieldValue(this.props.name, newValuesArray);
    }
  }

  deleteElement(id) {
    // console.log(id);
    // if (this.count > 1) {
    this.count--;
    this.data[id - 1].isVisible = false;
    let stateData = [...this.state.expArr];

    stateData[id - 1].isVisible = false;

    this.data = this.data.map((data, index) => {
      data.id = index + 1;
      data.isVisible = data.isVisible;
      data.industry = data.industry;
      data.Company = data.Company;
      data.jobProfile = data.jobProfile;
      // data.Experince=data.Experince;
      data.startDate = data.startDate;
      data.endDate = data.endDate;
      // console.log(data.id);
      return data;
    });
    // console.log(stateData);

    // this.data = data
    // console.log(this.data);
    // this.updateData()
    const newValuesArray = stateData.map(data => {
      return data;
    });
    this.props.setFieldValue(this.props.name, this.data);
    this.setState({ expArr: stateData });
    // }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps.errors) !== JSON.stringify(prevState.errors)) {
      return {
        errors: nextProps.errors,
        isErrorPropAvailable: true,
        touched: nextProps.touched
      };
    }
    return null;
  }

  componentDidMount() {
    const exp = [...this.props.data];
    const expArr = [];
    const data = [];
    let counter = 1;

    exp.map((el, index) => {
      const values = {
        expId: el.expId,
        id: counter,
        industry: el.industry,
        Company: el.Company,
        jobProfile: el.jobProfile,
        // Experince: "",
        isVisible: true,
        startDate: el.startDate,
        endDate: el.endDate
      };
      // console.log(values);
      data.push(values);
      // console.log(data);
      expArr.push({
        id: counter,
        expId: el.expId,
        isVisible: true,
        component: (
          <Experince
            expId={values.expId}
            id={counter}
            errors={this.props.errors}
            name={this.props.name}
            deleteItem={this.deleteElement}
            updateData={this.updateData}
            Company={this.Company}
            industry={this.industry}
            jobProfile={this.jobTypes}
            // Experince={this.Experince}
            values={values}
          />
        )
      });

      counter++;
    });
    this.data = [...data];
    this.setState({ expArr: expArr, counter: counter });
    const newValuesArray = expArr.map(data => {
      return data.component.props.values;
    });
    // console.log(this.props.name);
    // this.props.setFormData(newValuesArray);
    this.props.setFieldValue(this.props.name, newValuesArray);
    // console.log(this.state.expArr);
    // console.log(this.data.values);
    // console.log(newValuesArray);
  }

  // componentDidUpdate(prevProps, PrevState) {
  //   if (this.state.isErrorPropAvailable) {
  //     // console.log(this.data);
  //     this.setState({
  //       isErrorPropAvailable: false,
  //       elements: this.state.elements.map((element, index) => {
  //         element.id = index + 1;
  //         element.component = (
  //           <Experince
  //             id={index + 1}
  //             errors={this.props.errors}
  //             name={this.props.name}
  //             deleteItem={this.deleteElement}
  //             updateData={this.updateData}
  //             Company={this.Company}
  //             industry={this.industry}
  //             jobProfile={this.jobTypes}
  //             // Experince={this.Experince}
  //             values={this.data[0]}
  //           />
  //         );
  //         return element;
  //       })
  //     });
  //   }
  // }

  render() {
    // console.log(this.state.expArr);
    // console.log(this.props);

    // console.log(this.data);
    const Elements = this.state.expArr.map((element, index) => {
      if (element.isVisible) {
        // console.log(element.component);
        return (
          <div key={index} style={{ margin: '10px 0' }}>
            {element.component}
          </div>
        );
      }
    });
    return (
      <Container>
        <div style={{ display: 'flex' }}>
          <Button style={{ marginLeft: 'auto' }} onClick={this.addElements}>
            <AddBoxIcon></AddBoxIcon>
          </Button>
        </div>
        <div>{Elements}</div>
      </Container>
    );
  }
}
export default DynamicExperince;
