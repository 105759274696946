import React, {Fragment, useEffect, useState, useCallback} from "react";
import { Field } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import Chiplist from '../../../../utils/chipList/chipList'
import LanguageButtons from '../../../../utils/toggleButtons/toggleButtons'
import Select from '../../../../utils/SelectComponent/Select'
import locationIds from '../../../../utils/filters/locationIds'
import DateFnsUtils from '@date-io/date-fns';
import * as moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import urlLinks from '../../../../urlLinks';
import Axios from 'axios';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import {TextField,  Checkbox, FormControl, FormControlLabel, InputLabel, Grid, InputAdornment, Switch} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export const FirstPage = formikProps =>{
    const classes = useStyles();
    const { errors, touched, values, handleChange, setFieldValue, setValues, genderOptions, highestQualificationOptions, optionsYearofPassing, englishProficiencyOptions, locationList, editFlag, workerData, contactExist, setContactExist, emailExist, setEmailExist} = formikProps;

    const validateUser=(event) =>{
        if(!event.target.value){
            setContactExist(false)
            setEmailExist(false)
        }
        if(event.target.name==="contact_number" && event.target.value.length===10){
            const data=`phoneNumber=${event.target.value}`
            Axios.get(`${urlLinks.baseUrl}user/validateUser?${data}`)
                .then(response =>{
                    if(response.data.response.user_exist===0)
                        setContactExist(false)
                    if(response.data.response.user_exist===1)
                        setContactExist(true)
                })
        }
        if(event.target.name==="email" && event.target.value.length>7 && !errors.email){
            const data=`email=${event.target.value}`
            Axios.get(`${urlLinks.baseUrl}user/validateUser?${data}`)
                .then(response =>{
                    console.log({response})
                    if(response.data.response.user_exist===0)
                        setEmailExist(false)
                    if(response.data.response.user_exist===1)
                        setEmailExist(true)
                })
        }
        setFieldValue(event.target.name, event.target.value)
    }
    const handleSelect =(name, value) =>{
        setFieldValue(name, value)
      }
    const workerAppStatusOptions = [
        [0 , "Registered on app"],
        [1 , "Not registered on app"]
    ]
    console.log({values})
    return (
        <Grid container className="stepButtons">
            <Grid item xs={12} className="padding0">
                <Grid item sm={6} xs= {12} className="padding0 marginTop40">
                    <Select 
                        name="appRegistered"
                        options={workerAppStatusOptions}
                        value={values.appRegistered}
                        disable ={true}
                        setFieldValue={handleSelect}
                        label="Worker app status"
                    />
                </Grid> 
            </Grid>
            <Grid item xs={12} className="padding0 marginTop20">
                <span className="fontSize32 textUnderline fontWeightBold">Personal</span>
            </Grid>
            <Grid container className="padding0">
                <Grid item xs={12} className="padding0">
                    <Field
                        name="name"
                        label="Name *"
                        variant= "outlined"
                        className="marginLeft-0"
                        fullWidth={true}
                        as={TextField}
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                    />        
                </Grid>
            </Grid>
            <Grid container className="padding0 columnOrder">
                <Grid item sm={6} xs={12} className="padding0">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            label="Date of Birth"
                            format="dd/MM/yyyy"
                            className="marginLeft-0"
                            fullWidth={true}
                            name='dob'
                            value ={values.dob}
                            maxDate={moment().subtract(18, 'years')}
                            onChange={date =>setFieldValue('dob', date)}
                            // error={touched.startDate && errors.startDate}
                            // helperText={touched.startDate && errors.startDate}
                        />
                    </MuiPickersUtilsProvider>
                            
                </Grid>
                <Grid item sm={6} xs= {12} className="padding0 marginVertical20">
                    <Select 
                        name="gender"
                        options={genderOptions}
                        value={values.gender}
                        error={errors.gender}
                        setFieldValue={handleSelect}
                        label="Gender *"
                    />
                    {errors.gender && <span style={{color:"red"}}>{errors.gender}</span>}
                </Grid> 
            </Grid>
            <Grid item xs={12} className="padding0">
                <span className="fontSize32 textUnderline fontWeightBold">Contact</span>
            </Grid>
            <Grid container className="padding0">
                <Grid item sm={6} xs={12}  className="padding0">
                    <Field
                        name="contact_number"
                        label="Contact number"
                        variant= "outlined"
                        className="marginLeft-0"
                        disabled = {editFlag}
                        onChange={validateUser}
                        fullWidth={true}
                        as={TextField}
                        error={touched.contact_number && (errors.contact_number || contactExist)}
                        helperText={touched.contact_number && errors.contact_number}
                    />        
                    {contactExist && <div style={{color: "red"}}>User already exists.</div>}
                </Grid>
                <Grid item sm={6} xs={12}  className="padding0">
                    <Field
                        name="email"
                        label="Email"
                        variant= "outlined"
                        onChange={validateUser}
                        fullWidth={true}
                        as={TextField}
                        error={touched.email && (errors.email || emailExist)}
                        helperText={touched.email && errors.email}
                    />   
                    {emailExist && <div style={{color: "red"}}>User already exists.</div>}
                </Grid>
            </Grid>
            <Grid container className="padding0">
                <Grid item sm={6} xs={12}  className="padding0">
                    <Field
                        name="whatsapp_number"
                        label="Whatsapp number"
                        variant= "outlined"
                        className="marginLeft-0"
                        fullWidth={true}
                        as={TextField}
                        error={touched.whatsapp_number && errors.whatsapp_number}
                        helperText={touched.whatsapp_number && errors.whatsapp_number}
                    />        
                </Grid>
            </Grid>
            <Grid container className="padding0">
                <Grid item sm={6} xs={12}  className="padding0">
                    <Field
                        name="address"
                        label="Address/ Locality"
                        variant= "outlined"
                        className="marginLeft-0"
                        fullWidth={true}
                        as={TextField}
                        error={touched.address && errors.address}
                        helperText={touched.address && errors.address}
                    />        
                </Grid>
            </Grid>
            
            <Grid container className="padding0">
                {locationList && <Grid item sm={6} xs= {12} className="padding0 marginBottom-80">
                    <Autocomplete
                        id="city"
                        options={locationList.sort((a, b) => -b.state.localeCompare(a.state))}
                        groupBy={(option) => option.state}
                        value={values.city}
                        fullWidth ={true}
                        onChange={(event, value) => setFieldValue("city", value)}
                        getOptionLabel={(option) => option.city}
                        // style={{ width: "250px" }}
                        renderInput={(params) => <TextField {...params} variant = "outlined" label="City" className="marginLeft-0" />}
                    />
                </Grid> }
                <Grid item sm={6} xs={12}  className="padding0">
                    <Field
                        name="pincode"
                        label="Pincode"
                        variant= "outlined"
                        fullWidth={true}
                        as={TextField}
                        error={touched.pincode && errors.pincode}
                        helperText={touched.pincode && errors.pincode}
                    />        
                </Grid>
            </Grid>
            <Grid container className="padding0">
                {locationList &&  <Grid item sm={6} xs= {12} className="padding0 marginTop20">
                    <Autocomplete
                        id="preferred_locations"
                        multiple
                        options={locationList.sort((a, b) => -b.state.localeCompare(a.state))}
                        groupBy={(option) => option.state}
                        value={values.preferred_locations}
                        fullWidth ={true}
                        onChange={(event, value) => setFieldValue("preferred_locations", value)}
                        getOptionLabel={(option) => option.city}
                        // style={{ width: "250px" }}
                        renderInput={(params) => <TextField {...params} variant = "outlined"  label="Preferred location" className="marginLeft-0" />}
                    />
                </Grid>  }
            </Grid>
            
            <Grid container className="padding0 marginHorizontal20">
                <span className="fontSize32 textUnderline fontWeightBold ">Education</span>
            </Grid>
            <Grid container className="padding0 marginVertical20">
                <Grid item sm={6} xs= {12} className="padding0">
                    <Select 
                        name="highest_qualification"
                        options={highestQualificationOptions}
                        value={values.highest_qualification}
                        setFieldValue={handleSelect}
                        label="Highest qualification"
                    />
                </Grid>  
                <Grid item sm={6} xs= {12} className="paddingLeft20only">
                    <Select 
                        name="year_of_passing"
                        options={optionsYearofPassing}
                        value={values.year_of_passing}
                        setFieldValue={handleSelect}
                        label="Year of Passing"
                    />
                </Grid>  
            </Grid>
            <Grid item xs={12} className="padding0">
                <Grid item  xs= {12} className="padding0">
                    <Field
                        name="specialization"
                        label="Specialization"
                        className="marginLeft-0"
                        variant= "outlined"
                        fullWidth={true}
                        as={TextField}
                        // error={touched.specialization && errors.specialization}
                        // helperText={touched.specialization && errors.specialization}
                    />    
                </Grid>  
            </Grid>
            <Grid container className="padding0 marginVertical20">
                <Grid item xs= {12} className="padding0 marginVertical20">
                    <span className="fontSize16 fontWeightBold">Language</span>
                </Grid> 
                <Grid item xs= {12} className="padding0">
                    <LanguageButtons
                        name="language"
                        value={values.language}
                        setFieldValue={setFieldValue}
                    />
                </Grid> 
            </Grid>
            <Grid container className="padding0 marginHorizontal20">
                <Grid item sm={6} xs= {12} className="padding0 marginHorizontal20">
                    <Select 
                        name="english_proficiency"
                        options={englishProficiencyOptions}
                        value={values.english_proficiency}
                        setFieldValue={handleSelect}
                        label="English Proficiency"
                    />
                </Grid>  
            </Grid>
            <Grid item xs={12} className="padding0">
                <span className="fontSize16 fontWeightBold">OwnerShip details</span>
            </Grid>
            <Grid container className="padding0">
                <Grid container row={true}>
                    <Grid item sm={3} xs={12}  className="padding0">
                        <FormControlLabel
                            control={
                                <Field 
                                    checked={values.bike} 
                                    onChange={(e) =>setFieldValue("bike" ,e.target.checked)} 
                                    name="bike" 
                                    component={Checkbox}
                                    color="primary"
                                />
                            }
                            label="Bike"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}  className="padding0">
                        <FormControlLabel
                            control={
                                <Field 
                                    checked={values.bike_license} 
                                    onChange={(e) =>setFieldValue("bike_license",e.target.checked)} 
                                    name="bike_license" 
                                    component={Checkbox}
                                    color="primary"
                                />
                            }
                            label="Bike license"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} className="padding0">
                        <FormControlLabel
                            control={
                                <Field 
                                    checked={values.laptop} 
                                    onChange={(e) =>setFieldValue("laptop" ,e.target.checked)} 
                                    name="laptop" 
                                    component={Checkbox}
                                    color="primary"
                                />
                            }
                            label="Laptop"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}