import React, { Component, useState, Fragment } from 'react';
import {
  Card,
  Checkbox,
  FormControlLabel,
  TablePagination,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ScaleLoader } from 'react-spinners';
import CustomPopover from '../customPopOver/customPopover';
import Axios from 'axios';
import Paper from '@material-ui/core/Paper';
import ChildTableComponent from '../ChildTableComponent/ChildTableComponent';
import './ExpandableTable.css';
// import {Animated} from "react-animated-css";
const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  cardStyle: {
    maxWidth: '1200px',
    overflowX: 'scroll'
  },
  expansionHeaderStyle: {
    display: 'flex',
    flexFlow: 'row'
  },
  td: {
    align: 'center'
  },
  content: {
    alignItems: 'center'
  },
  colVisible: {
    display: 'table-cell'
  },
  colHide: {
    display: 'none'
  },
  divStyle: {
    // width: "68px",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    // margin: "0 4px 0 2px",
    fontSize: '0.8rem'
  },
  tabcell: {
    padding: '2px 5px',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap'
  },
  tooltipStyle: {
    maxWidth: '300px'
  },
  formControlLabelStyle: {
    margin: '0px'
  },
  checkboxStyle: {
    padding: '0px'
  },
  title: {
    padding: '0 16px 0 16px',
    minHeight: '64px',
    display: 'flex',
    alignItems: 'center'
  },
  buttonStyle: {
    width: '68px',
    margin: '0 4px 0 2px',
    textAlign: 'center'
  },
  tabelCellCheckbox: {
    padding: '0 0 0 16px'
  },
  notificationButton: {
    marginBottom: '0%',
    marginTop: '1%',
    marginLeft: '2%'
  }
});

function ExpandableRow(props) {
  console.log(props)
  const [expanded, setExpansion] = useState(false);
  return (
    <Fragment key={props.id}>
      <TableRow
      onClick={() => {
        if(props.name==="odJobs")
          setExpansion(!expanded);
        }}
      >
        {props.elements}
      </TableRow>
      <TableRow className={expanded ? 'expanded' : 'hideExpansion'}>
        <TableCell
          colSpan={100}
          className={expanded ? 'colRowVisible' : 'colRowHide'}
        >
          {expanded ? (
            <ChildTableComponent
              updateParentData={props.updateParentData}
              id={props.id}
              name={props.name}
              jobIdColumn={props.jobIdColumn}
            ></ChildTableComponent>
          ) : null}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

class ExpandableTable extends Component {
  constructor(props) {
    super(props);

    this.cols = [];
    this.data = [];
    this.tableLoader = (
      <TableRow style={{ height: '300px', position: 'relative' }}>
        <TableCell colSpan={100}>
          <ScaleLoader
            color={'#3F75B3'}
            css={{
              textAlign: 'center'
            }}
          ></ScaleLoader>
        </TableCell>
      </TableRow>
    );
    this.state = {
      selectedRows: []
    };
    this.rowsPerPageOption = [5, 10, 50, 100, 250, 500];
    this.headerChecked = false;
    this.getColoums = this.getColoums.bind(this);
    this.getRowElements = this.getRowElements.bind(this);
    this.checkRowIsSelected = this.checkRowIsSelected.bind(this);
    this.handleClickOnRow = this.handleClickOnRow.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
  }
  checkRowIsSelected(id) {
    return this.state.selectedRows.indexOf(id) !== -1;
  }
  handleSelectAll(event) {
    event.stopPropagation();
    const target = event.target;
    this.headerChecked = target.checked;
    if (target.checked) {
      const newRows = this.data.map(row => row[this.props.keyColumn]);
      this.setState({
        selectedRows: newRows
      });
    } else {
      this.setState({
        selectedRows: []
      });
    }
  }
  getColoums(cols, classes, row) {
    
    const lastElement = cols.length - 1;
    if (!row) {
      const elements = cols.map(col => {
        return (
          <TableCell
            align='center'
            key={col.id}
            className={`${
              col.isColumnVisible ? classes.colVisible : classes.colHide
            } ${classes.tabcell}`}
          >
            <div className={classes.divStyle}>{col.label}</div>
          </TableCell>
        );
      });
      return elements;
    } else {
      const elements = cols.map((col, index) => {
        return index !== lastElement ? (
          <TableCell
            align='center'
            key={col.id}
            className={`${
              col.isColumnVisible ? classes.colVisible : classes.colHide
            } ${classes.tabcell}`}
          >
            {' '}
            <div className={classes.divStyle}>{row[col.name]}</div>
          </TableCell>
        ) : (
          <TableCell
            key={col.id}
            className={`${
              col.isColumnVisible ? classes.colVisible : classes.colHide
            } ${classes.tabcell}`}
          >
            <FormControlLabel
              key={col.id}
              className={classes.formControlLabelStyle}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={
                <CustomPopover
                  className={classes.buttonStyle}
                  id={row[this.props.jobIdColumn]}
                  idColumn={this.props.idColumn ? row[this.props.idColumn] : null}
                  status={row.status}
                  links={this.props.links}
                ></CustomPopover>
              }
            ></FormControlLabel>
          </TableCell>
        );
      });
      return elements;
    }
  }

  handleClickOnRow(event, id) {
    event.stopPropagation();
    let selected = this.state.selectedRows;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.headerChecked = newSelected.length === this.data.length ? true : false;
    this.setState({
      selectedRows: newSelected
    });
  }

  handleChangePage = nextPage => {
    this.props.tablePagination.changePage(
      this.props.tablePagination.rowsPerPage,
      nextPage
    );
  };

  getRowElements(data, classes, cols) {
    const dataElements = data.map((data, index) => {
      const isItemSelected = this.checkRowIsSelected(
        data[this.props.keyColumn]
      );
      let newElements = [
        <TableCell
          className={classes.tabelCellCheckbox}
          key={data[this.props.keyColumn]}
        >
          {index + 1}
          {/* <FormControlLabel
            className={classes.formControlLabelStyle}
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <Checkbox
                checked={isItemSelected}
                className={classes.checkboxStyle}
                onChange={event => {
                  this.handleClickOnRow(event, data[this.props.keyColumn]);
                }}
              ></Checkbox>
            }
          ></FormControlLabel> */}
        </TableCell>
      ];
      newElements = newElements.concat(this.getColoums(cols, classes, data));
      return (
        <ExpandableRow
          updateParentData={this.props.updateParentData}
          jobIdColumn={data[this.props.jobIdColumn]}
          key={data[this.props.keyColumn]}
          id={data[this.props.keyColumn]}
          name={this.props.name}
          elements={newElements}
        ></ExpandableRow>
      );
    });
    return dataElements;
  }

  handleChangeRowsPerPage = event => {
    const newrowsPerPage = parseInt(event.target.value, 10);
    if (newrowsPerPage !== this.props.tablePagination.rowsPerPage) {
      this.props.tablePagination.changePage(newrowsPerPage, 0);
    }
  };

  render() {
    this.cols = this.props.columns;
    console.log(this.cols);
    this.data = this.props.data;
    console.log(this.data);
    const classes = this.props.classes;
    const elements = this.getColoums(this.cols, classes);
    const dataElements = this.getRowElements(this.data, classes, this.cols);
    return (
      <Paper className={classes.root} style={{ height: '73vh', width: '100%' }}>
        <div className={classes.title}>
          <Typography variant='h6'>{this.props.title}</Typography>
        </div>
        <Table
          className={classes.table}
          style={{ height: 500, width: '100%' }}
          stickyHeader
          aria-label='sticky table'
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{ padding: '10px 17px' }}
                align='center'
                className={classes.tabelCellCheckbox}
              >
                No.
                {/* <FormControlLabel
                  key={'index-1'}
                  className={classes.formControlLabelStyle}
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <Checkbox
                      checked={this.headerChecked}
                      className={classes.checkboxStyle}
                      onChange={this.handleSelectAll}
                    ></Checkbox>
                  }
                ></FormControlLabel> */}
              </TableCell>
              {elements}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.tablePagination.tableUpdating
              ? this.tableLoader
              : dataElements}
          </TableBody>
        </Table>
        {/* <Button
          className={classes.notificationButton}
          variant='contained'
          color='primary'
        >
          Send Notification
        </Button> */}

        <TablePagination
          rowsPerPageOptions={this.props.tablePagination.rowsOptions}
          component='div'
          count={this.props.tablePagination.count}
          rowsPerPage={this.props.tablePagination.rowsPerPage}
          page={this.props.tablePagination.currentPage}
          onChangePage={(event, nextPage) => {
            this.handleChangePage(nextPage);
          }}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(ExpandableTable);
