import React from 'react'
import {Grid} from '@material-ui/core';
import * as moment from 'moment'

export const experience =(exp) =>{
    exp=parseInt(exp)
    
    switch (exp) {
        case 40:
            return "Fresher";
        case 41:
            return "1-2 yr";
        case 42:
            return "3-5 yr";
        case 43:
            return ">5 yr";
        default:
            return "Fresher"
    }
}
export const ageBracket =(min, max) =>{
    if(min && max)
        return `${min} yrs to ${max} yrs`;
    if(min && !max)
        return `more than ${min} yrs`;
    if(!min && max)
        return `18 yrs to ${max} yrs`;
    if(!min && !min)
        return  "No age Prefence"
}
export const weekTodays =(week) =>{
    const days=[]
    if(!week){
        return days
    }
    if(week["2"]===true)
    days.push("monday")
    if(week["3"]===true)
    days.push("tuesday")
    if(week["4"]===true)
    days.push("wednesday")
    if(week["5"]===true)
    days.push("thursday")
    if(week["6"]===true)
    days.push("friday")
    if(week["7"]===true)
    days.push("saturday")
    if(week["1"]===true)
    days.push("sunday")
    if(week["0"]===true)
    days.push("sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday")
    return days;
}
export const setqualification  =(data) =>{
    console.log({data});
    data=parseInt(data)
    
    switch (data) {
        case 1:
            return "Below 10th";
        case 2:
            return "10th passed";
        case 3:
            return "12th passed";
        case 4:
            return "Diploma";
        case 5:
            return "In College";
        case 6:
            return "Graduate";
        case 7:
            return "Post Graduate";
        default:
            return "Not Required"
    }
}
export const setcuisine  =(data) =>{
    switch (data) {
        case 32:
            return "Indian";
        case 33:
            return "Continental";
        case 34:
            return "Chinese";
        case 35:
            return "Bakery";
        case 115:
            return "Other";
        default:
            return "None"
    }
}
export const setgender = (gender) =>{
    gender=parseInt(gender)
    console.log({gender});
    return gender===26?"Male":gender===27?"Female":"No preference";
}

const JobRequirement = (props) =>{
    const data=props.data
    let exp="None";
    let qualification="None";
    let cuisine="None";
    console.log({data});
    
    if(data && data.detailsJobs[0] && data.detailsJobs[0].detailSkills.length>0){
      data.detailsJobs[0].detailSkills.map(skill =>{
        if(skill.skillsTypeId>0 && skill.skillsTypeId<8 )
          qualification=skill.skillsTypeId;
        else if(skill.skillsTypeId>39 && skill.skillsTypeId<44 )
          exp=skill.skillsTypeId;
        else if((skill.skillsTypeId>31 && skill.skillsTypeId<36)|| skill.skillsTypeId===115)
          cuisine=skill.skillsTypeId;
        else{
        }
      })
    }
    console.log({qualification});
    
    
    
    
    const values={
        jobRole:data.detailsJobs[0].jobTypeId,
        exp: experience(exp),
        gender:data.detailsJobs[0].gender===26?"Male":data.detailsJobs[0].gender===27?"Female":"Any",
        qualification:  setqualification(qualification),
        cuisine: setcuisine(data.detailsJobs[0].cuisine===null? cuisine:data.detailsJobs[0].cuisine),
        specialization: data.detailsJobs[0].qualificationSpecification?data.detailsJobs[0].qualificationSpecification: "N/a",
        engFluency: data.detailsJobs[0].englishFluency===1?
            "No English"
            :data.detailsJobs[0].englishFluency===2?
                "Thoda English"
                :data.detailsJobs[0].englishFluency===3?
                    "Good English":"-",
        ageBracket: ageBracket(data.detailsJobs[0].minAge, data.detailsJobs[0].maxAge),
        cvRequired: data.detailsJobs[0].cvRequired===0?
            "Not Required"
            :data.detailsJobs[0].cvRequired===1?
                "Optional"
                :data.detailsJobs[0].cvRequired===2?
                    "Mandatory":"-",
    }
    return (
        <Grid container spacing={0} className="jobDetail" >
            <Grid item xs={12} className="subHeading">
                <span>Job Requirement</span>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Experience</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.exp}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Gender Preference</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.gender}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Minimum qualification</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.qualification}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Qualification (with specialization)</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.specialization}</span>
                </Grid>
            </Grid>
            {(values.jobRole>44 && values.jobRole<53)?
                <Grid container spacing={0} className="fieldRow" >
                    <Grid item xs={4} className="key">
                        <span>Cuisine</span>
                    </Grid>
                    <Grid item xs={8} className="value">
                        <span>{values.cuisine}</span>
                    </Grid>
                </Grid>: null
            }
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>English fluency</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.engFluency}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Age bracket</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.ageBracket}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>CV Required</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.cvRequired}</span>
                </Grid>
            </Grid>
        </Grid>
        
    )
}
export default JobRequirement;

