import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import ViewEmployerDetails from './viewEmployerDetails';
import CommentScreening from './commentSection';
import AddEmployerCommment from './AddComment';
import EditEmployerCommment from './EditComment';
import EditEmployer from './editEmployer';

export default class EmployeListRoute extends Component {
  render() {
    return (
      <Fragment>
        <Route
          path='/employer/employerLists/viewEmployer'
          component={ViewEmployerDetails}
        ></Route>
        <Route
          path='/employer/employerLists/commentSection'
          component={CommentScreening}
        ></Route>
        <Route
          path='/employer/employerLists/addComment'
          component={AddEmployerCommment}
        ></Route>
        <Route
          path='/employer/employerLists/editComment'
          component={EditEmployerCommment}
        ></Route>
        <Route
          path='/employer/employerLists/editEmployer'
          component={EditEmployer}
        ></Route>
      </Fragment>
    );
  }
}
