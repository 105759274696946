import React from 'react'
import {Grid} from '@material-ui/core'
import Pagination from '../../../utils/react-table/pagination'
import { useTable, useRowSelect, useFilters } from 'react-table'
import styled from 'styled-components'

const Styles = styled.div`
        padding: 1rem;
        overFlow: auto;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            
        }
        table {
            border-spacing: 0;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #1c75bc !important;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const Referrals = (props) => {
    const {data, columns, showToast, perPage, currentPage, totalRows, totalPages, handlePageChange, handlePerRowsChange, updateMyData, currentTab} =props
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected, setHiddenColumns, state: {hiddenColumns, selectedRowIds}} = useTable({columns, data,  showToast, updateMyData, currentTab,  manualSortBy: true,  }, useFilters,   useRowSelect)

    const table=<Styles ><div className="tableContainer">
                    <table {...getTableProps()}  className="table" >
                        <thead className="thead">
                            {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (  
                                <th {...column.getHeaderProps()}  style={{textAlign: "left"}}
                                    className={column.render('Header')==="Worker Id"?"stickWorkerIdnewMatch zIndex1080 header":
                                        column.render('Header')==="Name"?"stickWorkerNameMatchNew zIndex1080 header":
                                            column.id==="selection"?"stickCheckbox zIndex1080 header":"header"}>
                                            {column.render('Header')}
                                    
                                    </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        {
                            <tbody {...getTableBodyProps()}> 
                                
                            {
                                rows.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()} className="row tableRow">
                                        {row.cells.map(cell => {
                                            // console.log("Render started", cell.column.id);
                                            return <td {...cell.getCellProps()} style={{verticalAlign: "top", paddingTop: "16px"}}
                                                className={cell.column.id==="selection"?"stickCheckbox zIndex540":"cell"}>{cell.render('Cell')}</td>
                                        })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
            </Styles>

    
    return (
        <Grid container>
            {table}
            <Pagination 
                rowsperpage={perPage}
                pageNo={currentPage}
                totalElements={totalRows}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
            /> 
        </Grid>
    )
}

export default Referrals
