import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import FinanceAllLedger from '../Finance/financeAllLedger';
import EmployerLedger from '../Finance/EmployerLedger';
import WorkerLedger from '../Finance/WorkerLedger';
import MyAccount from '../Profile/Profile';
import ChangePassword from '../Profile/changepassword';
import EmployerRoute from './employerRoute';
import NewTabJobView from '../../utils/Modals/NewTabJobView'
import WorkerList from '../worker/workerList/workerList'; 
import AddWorker from '../worker/addWorker';
import NewAddWorker from '../worker/AddEditWorker/addEditWorkerBase'
import WorkerOnBoardingReview from '../worker/OnBoardReview/WorkerOnBoardingReview';
import WorkerScreening from '../worker/Screening/WorkerScreening';
import editWorker from '../worker/workerList/editWorker';
import Referrals from "../Referral/ReferralBase"
import InterviewDashboard from '../Job/InterviewDashboard/interviewDashboard'
import JobList from '../Job/JobList/JobList';
import FormSuccess from '../Job/newEditJob/forms/FormSuccess'
import WorkerSuccess from  '../worker/AddEditWorker/pageComponents/FormSuccess'
import NewWorkerdB from '../worker/WorkerDB/workerDB'
import AddJob from '../Job/AddJob/AddJob';
import AddNewJob from '../Job/newJobForm/MultiStep'
import EditNewJob from '../Job/newEditJob/EditJob'
import NewView from '../Job/JobList/newJobView'
import EditJob from '../Job/JobList/editJob';
import AddInterview from '../Job/AddInterview';
import NewTabExternalWorker from '../worker/workerView/newTabExternalWorker'
import NewTabInternalWorker from '../worker/workerView/newTabInternalWorker'
import ODJob from '../Job/ODJob/ODJob';
import EditJobDetail from '../Job/JobList/editJobDetail';
import WorkerCommentScreening from '../worker/workerList/commentSection';
import AddWorkerCommment from '../worker/workerList/AddComment';
import EditWorkerCommment from '../worker/workerList/EditComment';
import JobCommentScreening from '../Job/JobList/commentSection';
import AddJobCommment from '../Job/JobList/AddComment';
// import WorkerView from '../worker/workerView/workerView'
import EditJobCommment from '../Job/JobList/EditComment';
import RecruiterDashboard from '../RecuiterDashboard/RecruiterDashboard'
import RecruiterDB from '../RecruiterDB/RecruiterDashboard'
import RecruiterVerification from '../RecruiterVerification/recruiterVerfication'
import ViewJob from '../Job/JobList/viewJob';
import AssignVacancyToRecruiter from '../Job/AssignVacancyToRecruiter'
import PTFTJob from '../Job/PT-FTJob/PTFTJob';
import ExternalWorkers from  '../worker/externalWorker/externalWorker'
import UpdateExcelStatus from '../worker/externalWorker/UploadStatus'
import viewWorker from '../worker/workerList/viewWorker';
import SalesTracker from '../Sales tracker/salesTracker'
import Settings from '../Settings/CutOffConditions';
import Transaction from '../Finance/Transaction';
import Settings1WN from '../Settings/CutOffConditions1';
import Settings2WL from '../Settings/CutOffConditions2';
import Settings3WB from '../Settings/CutOffConditions3';
import Questionnaire from '../Questionnaire/jobQuestion';
import RecruiterProfile from '../Recruiter Profile/Profile';

class RoutingPath extends Component {
  render() {
    return (
      <div>
        {localStorage.getItem("roleType")!== "recruiter" ?<Switch>
          <Route exact path='/' component={Dashboard}></Route>
          <Route exact path='/finance/allledger' component={FinanceAllLedger} ></Route>
          <Route exact path='/finance/employerLedger' component={EmployerLedger} ></Route>
          <Route  exact path='/finance/workerLedger' component={WorkerLedger} ></Route>
          <Route exact path='/profile' component={MyAccount}></Route>
          <Route
            path='/profile/changePassword'
            component={ChangePassword}
          ></Route>
          {/* Worker Routes */}
          {/* <Route exact path='/worker/workerList' component={WorkerList}></Route> */}
          <Route
            path='/worker/workerList/editWorker'
            component={editWorker}
          ></Route>
          <Route
            path='/worker/workerList/viewWorker'
            component={viewWorker}
          ></Route>
          <Route
            path='/worker/newAddWorker'
            component={NewAddWorker}
          ></Route>
          <Route
            path='/worker/workersuccess'
            component={WorkerSuccess}
          ></Route>
          <Route  
            path="/worker/externalWorkers" 
            component={ExternalWorkers}
          ></Route>
          <Route  
            path="/worker/uploadExcel" 
            component={UpdateExcelStatus}
          ></Route>
          <Route
            path='/worker/workerList/commentSection'
            component={WorkerCommentScreening}
          ></Route>
          <Route
            path='/worker/workerList'
            component={NewWorkerdB}
          ></Route>
          <Route
            path='/worker/workerList/addComment'
            component={AddWorkerCommment}
          ></Route>

          <Route
            path='/worker/workerList/editComment'
            component={EditWorkerCommment}
          ></Route>
          {/* <Route
            path='/worker/workerview'
            component={WorkerView}
          ></Route> */}
          <Route path='/worker/addWorker' component={AddWorker}></Route>
          <Route
            path='/worker/onBoarding'
            component={WorkerOnBoardingReview}
          ></Route>
          <Route path='/worker/screening' component={WorkerScreening}></Route>
          {/* Job Routes */}
          <Route path='/jobs/jobList/newViewJob' component={NewView}></Route>
          <Route exact path='/jobs/jobList' component={JobList}></Route>
          <Route path='/jobs/addJob' component={AddJob}></Route>
          <Route path='/jobs/newAddJobForm' component={AddNewJob}></Route>
          <Route path='/jobs/newEditJob'  component={EditNewJob}></Route>
          <Route path='/jobs/editJob' component={EditJob}></Route>
          <Route path='/jobs/JobDetailed'  component={InterviewDashboard}></Route>
          <Route path='/jobs/postjob' component={FormSuccess}></Route>
          <Route path='/jobs/editDetailedJob' component={EditJobDetail}></Route>
          <Route path='/jobs/odJob' component={ODJob}></Route>
          <Route path='/jobs/assignvacancy' component={AssignVacancyToRecruiter}></Route>
          <Route path='/jobs/pt_ftJob' component={PTFTJob}></Route>
          <Route path='/job/jobList/commentSection' component={JobCommentScreening}></Route>
          <Route path='/job/jobList/addComment' component={AddJobCommment}></Route>
          <Route path='/job/jobList/editComment' component={EditJobCommment}></Route>
          <Route path='/interview/addInterview' component={AddInterview}></Route>
          <Route path='/Settings/CutOffConditions' component={Settings}></Route>
          {/* <Route exact path='/Settings/CutOffConditions' component={Settings}></Route> */}
          <Route path='/Settings/wlConditions' component={Settings1WN}></Route>
          <Route path='/Settings/wbConditions' component={Settings2WL}></Route>
          <Route path='/Settings/ptConditions' component={Settings3WB}></Route>
          <Route path='/newtabjobview' component={NewTabJobView} ></Route>
          <Route path='/internalworkerview' component={NewTabInternalWorker} ></Route>
          <Route path='/externalworkerview' component={NewTabExternalWorker} ></Route>
          <Route exact path='/Questionnaire/jobQuestion' component={Questionnaire}></Route>
          <Route exact path='/recruiter/profile' component={RecruiterProfile}></Route>
          <Route exact path='/finance/Transaction' component={Transaction} ></Route>
          <Route path='/job/jobList/viewJob' component={ViewJob}></Route>
          {/* (localStorage.getItem('roleType')==="Super Admin" || localStorage.getItem('roleType')==="Operational Admin") ? <Route exact path='/recruiter/dashboard'  component={RecruiterDashboard}></Route>: null */}
          {(localStorage.getItem('roleType')==="Super Admin" || localStorage.getItem('roleType')==="Operational Admin") ? <Route exact path='/recruiter/DB'  component={RecruiterDB}></Route>: null}
          {(localStorage.getItem('roleType')==="Super Admin" || localStorage.getItem('roleType')==="Operational Admin") ? <Route exact path='/recruiter/verification'  component={RecruiterVerification}></Route>: null}
          {(localStorage.getItem('roleType')==="Super Admin" || localStorage.getItem("userId")==="1131" || localStorage.getItem("userId")==="38781" || localStorage.getItem("userId")==="8656")? <Route exact path='/salestracker'  component={SalesTracker}></Route>: null}
          {(localStorage.getItem("roleType")=== 'Super Admin' || localStorage.getItem("userId")==="1131" || localStorage.getItem("userId")==="38781" || localStorage.getItem("userId")==="8656") ? <Route exact path='/referrals'  component={Referrals}></Route>: null}
          <EmployerRoute />
          <Redirect to ="/" />

        </Switch>:
        <Switch>
          
          {/* <Route exact path='/recruiter/dashboard'  component={RecruiterDashboard} /> */}
          <Route exact path='/'  component={RecruiterDB}></Route>
          <Route path='/worker/newAddWorker' component={NewAddWorker} ></Route>
          <Route
            path='/worker/workersuccess'
            component={WorkerSuccess}
          ></Route>
          <Route path='/newtabjobview' component={NewTabJobView} ></Route>
          <Route path='/internalworkerview' component={NewTabInternalWorker} ></Route>
          <Route path='/externalworkerview' component={NewTabExternalWorker} ></Route>
          <Redirect to ="/" />
        </Switch>
      }
      </div>
    );
  }
}

export default RoutingPath;
