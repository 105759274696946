import React, { Component, Fragment } from 'react'
import { Route} from 'react-router-dom';
import Employer from '../Employer/EmployerList/employer';
import AddEmployer from '../Employer/addEmployer';
import EmployerScreening from '../Employer/Screening/screening';
import EmployerOnBoarding from '../Employer/OnBoarding/onboarding';
import InActiveEmployer from '../Employer/InActiveEmployer/inActiveEmployer';
import EmployerPayment from '../Employer/Payments/Payment';
import EmployerListRoute from '../Employer/EmployerList/employeListRoute';

export default class EmployerRoute extends Component {
    render() {
        return (
            <Fragment>
                    <Route exact path="/employer/employerLists" component={Employer}></Route>
                    <Route exact path="/employer/addEmployer" component={AddEmployer}></Route>
                    <Route exact path="/employer/onBoardingReview" component={EmployerOnBoarding}></Route>
                    <Route exact path="/employer/screening" component={EmployerScreening}></Route>
                    <Route exact path="/employer/inactiveEmployer" component={InActiveEmployer}></Route>
                    <Route exact path="/employer/payments" component={EmployerPayment}></Route>
                    <EmployerListRoute/>
            </Fragment>
        )
    }
}
