import React, { useState, useEffect } from 'react'
import EdiText from 'react-editext'
import styled from 'styled-components'
import '../../worker/externalWorker/modals.css'
import urlLinks from '../../../urlLinks'
import Axios from 'axios'
import qs from 'querystring'


const StyledEdiText = styled(EdiText)`
  button {
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 600;
    color: #1c75bc;
  }
  input, textarea {
    border: 1px solid #000000A1;
    width: 165px;
  }
`;

const EditOpt = (props) => {
    const {id, userId, showToast, updateMyData, int_ext} =props
    const [value, setValue] = useState(props.value)
   
    useEffect(() => {
      setValue(props.value)
    }, [props.value])
    const handleSave = val => {
      if(val!==value){

        setValue(val)
        let postData={
          type:id,
          value: val,
          userId: userId
        }
        Axios.post(`${urlLinks.baseUrl}recruiter/editWorkerContacts`, qs.stringify(postData))
        .then(response =>{
          console.log({response})
          if (response.data.code === 1000) {
            showToast("success", 'Worker details edited Successfully');
            // if(props.type!=="interviewRescheduleStatus")
            updateMyData()
          } else {
                setValue("");
                throw showToast("error", response.data.response["error "]);
              }
            })
            // .catch(error => {
            //   console.log({error})
            //   showToast("error", error);
            // })
      } 
          
    }
    const handleValidation =(text) =>{
      if(id==="email"){
        if (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(text))
        {
          return true
        }
        else{

          return false
        } 

      }
      else{
        if(text.length!==10 || !(/(\d{10})$/.test(text)))
          return false 
        else 
          return true
      }
      
    }
    return (
        <div className="container">
          <StyledEdiText
            type={id==="email"?"email":"text"}
            value={value}
            validation={handleValidation}
            validationMessage={id==="email"?"Enter a valid email.":"Enter a valid number."}
            onSave={handleSave}
            editButtonClassName={`editButtonClass custom-edit-button ${int_ext==="INTERNAL"?"displayNone":""}`}
            editButtonContent={value?"Edit":id==="email"?"Add Email":id==="phone"?"Add Phone number":id==="whatsApp"?"Add Whatsapp":""}
          />
        </div>
      );
  }

export default EditOpt