import React from 'react'
import {Grid} from '@material-ui/core';
import * as moment from 'moment'


const HRDetail = (props) =>{
    const allAdmins=JSON.parse(localStorage.getItem('allAdmins'))
    const data=props.data
    const accountManager =(data) =>{
        return allAdmins[data]?allAdmins[data]:"Manager not assigned";
    }
    const values={
        pocCandidateName:data.contactCandidateName?data.contactCandidateName:'-' ,
        pocCandidateNumber: data.contactCandidateNumber?data.contactCandidateNumber:'-',
        pocName:  data.contactPerson?data.contactPerson:'-',
        pocNumber: data.contactNo?data.contactNo:'-' ,
        pocEmail: data.emailAccountManager?data.emailAccountManager:'-' ,
        accountManager: data.accountManager? accountManager(data.accountManager):'-'
    }
    return (
        <Grid container spacing={0} className="jobDetail" >
            <Grid item xs={12} className="subHeading">
                <span>HR details</span>
            </Grid>
            <Grid item xs={12} className="semiSubHeading">
                <span>For candidates</span>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>POC name</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.pocCandidateName}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>POC contact number</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.pocCandidateNumber}</span>
                </Grid>
            </Grid>
             <Grid item xs={12} className="semiSubHeading">
                <span>For OkayGo account manager</span>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>POC name</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.pocName}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>POC contact Number</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.pocNumber}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>POC email ID</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.pocEmail}</span>
                </Grid>
            </Grid>
            <Grid container spacing={0} className="fieldRow" >
                <Grid item xs={4} className="key">
                    <span>Account manager</span>
                </Grid>
                <Grid item xs={8} className="value">
                    <span>{values.accountManager}</span>
                </Grid>
            </Grid>
        </Grid>
        
    )
}
export default HRDetail;

