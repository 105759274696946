import React, { useState, useMemo, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Grid, Checkbox, FormControl, Select, InputLabel, MenuItem, Badge, IconButton, Drawer, Button, TextField} from '@material-ui/core';
import { useTable, useRowSelect, useFilters } from 'react-table'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify';
import Referrals from './ReferralTabs/Referrals'
import OpenClaims from './ReferralTabs/OpenClaims';
import ProcessedClaims from './ReferralTabs/ProcessedClaims';
import Axios from 'axios'
import urlLinks from '../../urlLinks'
import * as moment from 'moment'
import filter from '../../assets/filter.svg'
import Filters from './FilterBase'
import { FaTemperatureHigh } from 'react-icons/fa';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {makeStyles } from "@material-ui/core/styles";
import { CSVLink } from 'react-csv';
import EditableRemark from './EditableRemark';
import EditableApprovalStatus from './EditableApprovalStatus';


const useStyles = makeStyles((theme) => ({
    select:{
        "& .MuiOutlinedInput-inputMarginDense":{
            padding: "8px 10px"
        }
    }
  }));
const Styles = styled.div`
        padding: 0 !important;
        margin-top: 20px !important;
        width: 100%;
        .tableContainer{
            border: none;
            border-radius: 5px; 
        }
        table {
            border-spacing: 0;
            border: none !important;
            vertical-align: baseline;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
                vertical-align: baseline;
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #EEEEEE !important;
                color: #333333 !important;

            }
            th,td{
                padding:5px 15px;
                vertical-align: baseline;
            }
            
            td {
                vertical-align: baseline;
                margin: 0;
                border-bottom: 1px solid #cfcfcf;
                vertical-align: top;
                background-color: white;
                nth-last-child(2) {
                    border-right: 0;
                } 
                :last-child {
                    border-right: 0;
                }

                input {
                    font-size: 1rem;
                    padding: 0;
                    margin: 0;
                    border: 0;
                }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <React.Fragment>
        <Checkbox style={{borderRadius: '2px',border: 'solid 1px #cccccc'}} ref={resolvedRef} {...rest} color= "primary" />
      </React.Fragment>
    )
  }
)
const InterviewDashboard =(props) =>{
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0)
    const [data, setData] = useState([null, null,null])
    const [sortBy, setSortBy] = useState(["",'',''])
    const [sortDirection, setSortDirection] = useState([false, false, false])
    const [perPage, setPerPage] = useState([10,10,10]);
    const [sortUrl, setSortUrl] = useState(['',"",""])
    const [totalRows, setTotalRows] = useState([0,0,0]);
    const [isFilterApplied, setIsFilterApplied] = useState([false, false, false])
    const [totalPages, setTotalPages] = useState([1,1,1])
    const [currentPage, setCurrentPage] = useState([1,1,1]);
    const [filterUrl, setFilterUrl] = useState(['',"",""])
    const [filterDrawer, setFilterDrawer] = useState(false)
    const [companyList, setCompanyList] = useState()
    const [managerList, setManagerList] = useState()
    const [recruiterList, setRecruiterList] = useState()
    const [excelData, setExcelData] = useState("")
    const [excelFileName, setExcelFileName] = useState("")
    const [filters, setFilters] = useState(
        [
            {
                referral_id:"",
                name:"",
                number:"",
                location:[],
                brand_name:[],
                referred_by_name:"",
                referred_by_number:"",
                recruiter_name:[],
                account_manager:[],
                joining_date:[null, null],
                referred_on:[null, null],
                jobRole:[]
            },
            {
                referral_id:"",
                name:"",
                number:"",
                referred_by_name:"",
                referred_by_number:"",
                location:[],
                brand_name:[],
                referred_on:[null, null],
                approval_status:[""],
                joining_date:[null, null],
                recruiter_name:[],
                account_manager:[],
                jobRole:[],
                referralApprovalStatus:[""]
            },
            {
                referral_id:"",
                name:"",
                number:"",
                referred_by_name:"",
                referred_by_number:"",
                location:[],
                brand_name:[],
                referred_on:[null, null],
                candidate_status:[""],
                joining_date:[null, null],
                transaction_date:[null, null],
                recruiter_name:[],
                account_manager:[],
                jobRole:[],
                transaction_date:[null, null]
            }
        ]
    )
    const referralColumns = useMemo(
        () => [
            {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ background: "unset", borderRadius: "2px", padding: 0, marginLeft: "10px"}} />
                </div>
            ),
            Cell: ({ row }) => (
                <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{paddingTop: 0}} />
                </div>
            ),
            },
            {
                Header: "Referral Id",
                accessor: "referralId",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Candidate details",
                accessor: "candidateDetails",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Vacancy",
                accessor: "vacancy",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "CTC",
                accessor: "ctc",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Commission amount",
                accessor: "commissionAmount",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referred by",
                accessor: "referredBy",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referred on",
                accessor: "referredOn",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Candidate status",
                accessor: "candidateStatus",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Joining date",
                accessor: "joiningDate",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referral amount",
                accessor: "referralAmount",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Recruiter name",
                accessor: "recruiterName",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Account manager",
                accessor: "accountManager",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Remarks",
                accessor: "remarks",
                Cell: EditableRemark,
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            }
        ], 
        []
    );
    const openClaimsColumns = useMemo(
        () => [
            {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ background: "unset", borderRadius: "2px", padding: 0, marginLeft: "10px"}} />
                </div>
            ),
            Cell: ({ row }) => (
                <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{paddingTop: 0}} />
                </div>
            ),
            },
            {
                Header: "Referral Id",
                accessor: "referralId",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Candidate details",
                accessor: "candidateDetails",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Vacancy",
                accessor: "vacancy",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "CTC",
                accessor: "ctc",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referred by",
                accessor: "referredBy",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referred on",
                accessor: "referredOn",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            // {
            //     Header: "Candidate status",
            //     accessor: "candidateStatus",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
            {
                Header: "Joining date",
                accessor: "joiningDate",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            // {
            //     Header: "Invoicing period",
            //     accessor: "invoicingPeriod",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
            // {
            //     Header: "Invoicing date",
            //     accessor: "invoicingDate",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
            {
                Header: "Payment date",
                accessor: "paymentDate",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Replacement period",
                accessor: "replacementPeriod",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Commission amount",
                accessor: "commissionAmount",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referral amount",
                accessor: "referralAmount",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Approval status",
                accessor: "approvalStatus",
                Cell: EditableApprovalStatus,
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Payment status",
                accessor: "paymentStatus",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Recruiter name",
                accessor: "recruiterName",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Account manager",
                accessor: "accountManager",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Remarks",
                accessor: "remarks",
                Cell: EditableRemark,
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            }
        ], 
        []
    );
    const processedClaimsColumns = useMemo(
        () => [
            {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{ background: "unset", borderRadius: "2px", padding: 0, marginLeft: "10px"}} />
                </div>
            ),
            Cell: ({ row }) => (
                <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} style={{paddingTop: 0}} />
                </div>
            ),
            },
            {
                Header: "Referral Id",
                accessor: "referralId",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Candidate details",
                accessor: "candidateDetails",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Vacancy",
                accessor: "vacancy",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "CTC",
                accessor: "ctc",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referred by",
                accessor: "referredBy",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referred on",
                accessor: "referredOn",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            // {
            //     Header: "Candidate status",
            //     accessor: "candidateStatus",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
            {
                Header: "Joining date",
                accessor: "joiningDate",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            // {
            //     Header: "Invoicing period",
            //     accessor: "invoicingPeriod",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
            // {
            //     Header: "Invoicing date",
            //     accessor: "invoicingDate",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
            // {
            //     Header: "Payment date",
            //     accessor: "paymentDate",
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
            {
                Header: "Commission amount",
                accessor: "commissionAmount",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Referral amount",
                accessor: "referralAmount",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Payment status",
                accessor: "paymentStatus",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Transaction date",
                accessor: "transactionDate",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Transaction method",
                accessor: "transactionMethod",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Transaction id",
                accessor: "transactionId",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Recruiter name",
                accessor: "recruiterName",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Account manager",
                accessor: "accountManager",
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            },
            {
                Header: "Remarks",
                accessor: "remarks",
                Cell: EditableRemark,
                defaultCanFilter :false,
                defaultCanSort:false,
                isVisible: true
            }
        ], 
        []
    );
        
    
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}/admin/companies`)
            .then(response =>{
                let list=[];
                console.log({response})
                response.data.response.map(company =>{
                    list.push({
                        id: company.userId,
                        name: company.brandName?company.brandName: company.companyName
                    })
                })

                setCompanyList(list)
            })
        Axios.get(`${urlLinks.baseUrl}user/?role_types=1,2&rows=100`)
            .then(response =>{
                console.log({response})
                let list=[]
                response.data.response.content.map(company =>{
                    list.push({
                        id: company.userId,
                        name: `${company.firstName} ${company.lastName?company.lastName:''}`
                    })
                })
                setManagerList(list)
            })
        
        Axios.get(`${urlLinks.baseUrl}user/getAllRecruiter`)
            .then(response =>{
                console.log({response})
                let list=[]
                response.data.response.map(x =>{
                    list.push({
                        id: x.userID,
                        name: `${x.firstName} ${x.lastName?x.lastName:''}` 
                    })
                })
                setRecruiterList(list)
            })
    }, [])

    const ApplyFilter =() =>{
        let url='';
        if(filters[currentTab]["referral_id"])
            url=`${url}&referral_id=${filters[currentTab]["referral_id"]}`
        if(filters[currentTab]["name"])
            url=`${url}&name=${filters[currentTab]["name"]}`
        if(filters[currentTab]["number"])
            url=`${url}&number=${filters[currentTab]["number"]}`
        if(filters[currentTab]["referred_by_name"])
            url=`${url}&refer_by_name=${filters[currentTab]["referred_by_name"]}`
        if(filters[currentTab]["referred_by_number"])
            url=`${url}&refer_by_number=${filters[currentTab]["referred_by_number"]}`
        if(filters[currentTab]["location"].length!==0){
            let cityIds = []
            filters[currentTab]["location"].map(city => cityIds.push(city.id))
            url=`${url}&city_id=${cityIds}`
        }
        if(filters[currentTab]["recruiter_name"].length!==0){
            let companyIds = []
            filters[currentTab]["recruiter_name"].map(brand => companyIds.push(brand.id))
            url=`${url}&recuiter_id=${companyIds}`
        }
        if(filters[currentTab]["jobRole"].length!==0){
            let companyIds = []
            filters[currentTab]["jobRole"].map(brand => companyIds.push(brand.name))
            url=`${url}&job_title=${companyIds}`
        }
        if(filters[currentTab]["brand_name"].length!==0){
            let companyIds = []
            filters[currentTab]["brand_name"].map(brand => companyIds.push(brand.id))
            url=`${url}&user_id=${companyIds}`
            }
        if(filters[currentTab]["account_manager"].length!==0){
            let companyIds = []
            filters[currentTab]["account_manager"].map(brand => companyIds.push(brand.id))
            url=`${url}&account_manager=${companyIds}`
        }
        if(currentTab===1 && !filters[currentTab]["referralApprovalStatus"].includes(""))
            url=`${url}&claim_approval_status=${filters[currentTab]["referralApprovalStatus"]}`
        if(filters[currentTab]["joining_date"][0] && !filters[currentTab]["joining_date"][1])
            url=`${url}&joining_from_date=${moment(filters[currentTab]["joining_date"][0]).format("YYYY-MM-DD")}&joining_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters[currentTab]["joining_date"][0] && filters[currentTab]["joining_date"][1])
            url=`${url}&joining_from_date=2019-01-01&joining_to_date=${moment(filters[currentTab]["joining_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters[currentTab]["joining_date"][0] && filters[currentTab]["joining_date"][1])
            url=`${url}&joining_from_date=${moment(filters[currentTab]["joining_date"][0]).format("YYYY-MM-DD")}&joining_to_date=${moment(filters[currentTab]["joining_date"][1]).format("YYYY-MM-DD")} 23:59:59`
            
        if(filters[currentTab]["referred_on"][0] && !filters[currentTab]["referred_on"][1])
            url=`${url}&accepted_on_from_date=${moment(filters[currentTab]["referred_on"][0]).format("YYYY-MM-DD")}&accepted_on_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(!filters[currentTab]["referred_on"][0] && filters[currentTab]["referred_on"][1])
            url=`${url}&accepted_on_from_date=2019-01-01&accepted_on_to_date=${moment(filters[currentTab]["referred_on"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(filters[currentTab]["referred_on"][0] && filters[currentTab]["referred_on"][1])
            url=`${url}&accepted_on_from_date=${moment(filters[currentTab]["referred_on"][0]).format("YYYY-MM-DD")}&accepted_on_to_date=${moment(filters[currentTab]["referred_on"][1]).format("YYYY-MM-DD")} 23:59:59`

        if(currentTab===2 && filters[currentTab]["transaction_date"][0] && !filters[currentTab]["transaction_date"][1])
            url=`${url}&claim_payment_from_date=${moment(filters[currentTab]["transaction_date"][0]).format("YYYY-MM-DD")}&claim_payment_to_date=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`
        if(currentTab===2 && !filters[currentTab]["transaction_date"][0] && filters[currentTab]["transaction_date"][1])
            url=`${url}&claim_payment_from_date=2019-01-01&claim_payment_to_date=${moment(filters[currentTab]["transaction_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        if(currentTab===2 && filters[currentTab]["transaction_date"][0] && filters[currentTab]["transaction_date"][1])
            url=`${url}&claim_payment_from_date=${moment(filters[currentTab]["transaction_date"][0]).format("YYYY-MM-DD")}&claim_payment_to_date=${moment(filters[currentTab]["transaction_date"][1]).format("YYYY-MM-DD")} 23:59:59`
        
        // if(!filters[currentTab]["candidate_status"].includes("")){
        //     let value=[]
        //     if(filters[currentTab]["candidate_status"].includes("SELECTED"))
        //         value.push(...["ACCEPTED","SELECTED","POSTPONED","NO_RESPONSE"])
        //     if(filters[currentTab]["candidate_status"].includes("NOT_JOINING"))
        //         value.push(...["NOT_JOINING","REJECTED"])
        //     if(filters[currentTab]["candidate_status"].includes("JOINED"))
        //         value.push(...["JOINED", "UPDATE JOINING DATE"])
        //     if(filters[currentTab]["candidate_status"].includes("LEFT_AFTER_JOINING"))
        //         value.push(...["LEFT_AFTER_JOINING"])
        //     url=`${url}&candidate_status=${value}`
        // }

        if(url){
            let temp=isFilterApplied
            temp[currentTab]=true
            setIsFilterApplied([...temp])
        }
        let urlList = filterUrl
        urlList[currentTab]=url
        setFilterUrl([...urlList])
    }
    
    
    useEffect(() => {
        ApplyFilter();
    }, [filters])

    const ApplySort = () =>{
        let url=''
        if(sortBy[currentTab]){
            switch (sortBy[currentTab]) {
                case "referral_date":
                    url='&orderBy=job_assign_request.applied_on'
                    break;
                case "joining_date":
                    url='&orderBy=interviews.joining_date'
                    break;
                default:
                    url=''
                    break;
            }
            if(sortDirection[currentTab])
                url=`${url}&sortBy=ascending`
            let temp=sortUrl
            temp[currentTab]=url
            setSortUrl([...temp])
        }
        else{
            let temp=sortUrl
            temp[currentTab]=url
            setSortUrl([...temp])
        }
    }
    useEffect(() => {
        ApplySort();
    }, [sortBy, sortDirection])

    const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}

    const resetFilters=() =>{
        let tempFilters=[
            {
                referral_id:"",
                name:"",
                number:"",
                location:[],
                brand_name:[],
                referred_by_name:"",
                referred_by_number:"",
                recruiter_name:[],
                account_manager:[],
                joining_date:[null, null],
                referred_on:[null, null],
                jobRole:[]
            },
            {
                referral_id:"",
                name:"",
                number:"",
                referred_by_name:"",
                referred_by_number:"",
                location:[],
                brand_name:[],
                referred_on:[null, null],
                approval_status:[""],
                joining_date:[null, null],
                recruiter_name:[],
                account_manager:[],
                jobRole:[],
                referralApprovalStatus:[""]
            },
            {
                referral_id:"",
                name:"",
                number:"",
                referred_by_name:"",
                referred_by_number:"",
                location:[],
                brand_name:[],
                referred_on:[null, null],
                candidate_status:[""],
                joining_date:[null, null],
                transaction_date:[null, null],
                recruiter_name:[],
                account_manager:[],
                jobRole:[],
                transaction_date:[null, null]
            }
        ]
        let temp=isFilterApplied
        temp[currentTab]=false
        setIsFilterApplied([...temp])
        let resetFiltersArray=filters
        resetFiltersArray[currentTab]= tempFilters[currentTab]

        setFilters([...resetFiltersArray])
        return resetFiltersArray;
    }

    const handleCandidateStatus =(data) =>{
        let temp= data.replaceAll("_", " ")
        return temp.charAt(0) + temp.slice(1).toLowerCase();
    }
    const handleJoiningDate =(data) =>{
        if(data.feedbackStatus==="SELECTED" || data.feedbackStatus==="JOINED" || data.feedbackStatus==="LEFT_AFTER_JOINING" || data.feedbackStatus==="NOT_JOINING" ){
            return moment(data.joining_date).format("DD/MM/YYYY")
        }
        return ""
    }
    const makeData= (response) =>{
        let extractedData = [];
        // let index= response.pageable.offset+1;
        response.content.map(el => {
            // console.log({el})
            extractedData.push({
                referralId: el.referral_id?el.referral_id:"",
                candidateDetails: el.first_name === null ? '-' :`${el.first_name} ${el.last_name?el.last_name:''} - ${el.phone_number?el.phone_number:''}`,
                vacancy: `${el.jobId} - ${el.jobTitle} - ${el.brandName?el.brandName:el.company} - ${el.city_name}`,
                ctc: `${numberFormat(el.job_offered_amount?el.job_offered_amount:0)} / yr`,
                referredBy: el.refer_by_name?`${el.refer_by_name} - ${el.refer_by_contact?el.refer_by_contact:""}`:'',
                referredOn: el.applied_on? moment(el.applied_on).format("DD/MM/YYYY"):"",
                candidateStatus: el.candidateStatus?handleCandidateStatus(el.candidateStatus):"",
                joiningDate: handleJoiningDate(el),
                invoicingPeriod: el.okaygoInvoicingPeriod?el.okaygoInvoicingPeriod:"",
                invoicingDate: el.invoice_date? moment(el.invoice_date).format("DD/MM/YYYY"):"",
                paymentDate: el.joining_date? moment(el.joining_date).format("DD/MM/YYYY"):"",
                replacementPeriod: el.okaygoReplacementClause?`${el.okaygoReplacementClause} days`:"",
                commissionAmount: el.amount?numberFormat(el.amount):(el.okaygoCommissionTerm===1?`${el.okaygoCommissionPerJoining?el.okaygoCommissionPerJoining:0} %` :numberFormat(el.okaygoCommissionPerJoining?el.okaygoCommissionPerJoining:0)),
                referralAmount: numberFormat(el.referral_claim_amount?el.referral_claim_amount:0),
                paymentStatus: el.claimPaymentStatus?handleCandidateStatus(el.claimPaymentStatus):"",
                approvalStatus: el.claim_approval_status?el.claim_approval_status:"",
                transactionDate: el.claimPaymentDate? moment(el.claimPaymentDate).format("DD/MM/YYYY"):"",
                transactionMethod: "UPI",
                transactionId: el.transactionUniqueId?el.transactionUniqueId:"",
                recruiterName: el.recruiter_name?el.recruiter_name: "Not Assigned",
                accountManagerId: el.accountManager || "",
                accountManager:el.accountManagerName|| "",
                remarks: el.remark?el.remark:"",
                workerId: el.worker_id?el.worker_id:'',
                userId: el.user_id?el.user_id:'',
                referredById: el.refer_by?el.refer_by:"",
                jobId: el.jobId?el.jobId:'',
                assignId: el.assign_id?el.assign_id:'',
                jobDetailsId:el.jobDetailsId?el.jobDetailsId:"",
            });
        });
        return extractedData;
    }

    const getReferralData= (rows, page) =>{
        let url=""
        if(currentTab===0){
            url=`${urlLinks.baseUrl}referral/getReferralDashboardData?page_no=${page-1}&rows=${rows}${filterUrl[currentTab]}${sortUrl[currentTab]}`
        }
        else if(currentTab===1){
            url=`${urlLinks.baseUrl}referral/getOpenClaims?page_no=${page-1}&rows=${rows}${filterUrl[currentTab]}${sortUrl[currentTab]}`
        }
        else if(currentTab===2){
            url=`${urlLinks.baseUrl}referral/getProcessedClaims?page_no=${page-1}&rows=${rows}${filterUrl[currentTab]}${sortUrl[currentTab]}`
        }


        Axios.get(url)
            .then(response =>{
                // console.log({response})
                if(response){
                    let temp=totalRows
                    temp[currentTab]=response.data.response.totalElements
                    setTotalRows([...temp])
                    temp=totalPages
                    temp[currentTab]=response.data.response.totalPages
                    setTotalPages([...temp])
                    temp=data
                    console.log({response})
                    temp[currentTab]=makeData(response.data.response)
                    setData([...temp])
                    // if(currentTab===2)
                    //     setData2(makeData(response.data.response))
                    // if(currentTab===1)
                    //     setData1(makeData(response.data.response))
                    // if(currentTab===0)
                    //     setData(makeData(response.data.response))

                }
                else{
                    throw toast.error("No data inside from API")
                }
            })
            .catch(error =>{
                throw toast.error("No data from API")
            })
    }

    useEffect(() => {
        if(data[currentTab]){
            setExcelData(data[currentTab])
            let temp=currentTab===2?`Processed_claims_${moment().format("DD-MM-YYYY")}.csv`:currentTab===1?`Open_claims_${moment().format("DD-MM-YYYY")}.csv`:`Referral_claims_${moment().format("DD-MM-YYYY")}.csv`
            setExcelFileName(temp)
        }
        console.log(data[currentTab])
    }, [currentTab, data])

    const updateMyData = () => {
        getReferralData(perPage[currentTab], currentPage[currentTab]);
    }
    useEffect(() => {
        getReferralData(perPage[currentTab] , 1);
    }, [currentTab, sortUrl, filterUrl])

    
    const showToast = (type, message) =>{
        if(type==="success")
            return toast.success(message);
        else if(type==="error")
            return toast.error(message);
    }
    const handlePageChange = page => {
        getReferralData(perPage[currentTab], page);
        let temp=currentPage
        temp[currentTab]=page
        setCurrentPage([...temp]);
    };

    const handlePerRowsChange = (newPerPage) => {
        getReferralData(newPerPage, 1);
        let temp=perPage
        temp[currentTab]=newPerPage
        setPerPage([...temp]);
        temp=currentPage
        temp[currentTab]=1
        setCurrentPage([...temp]);
    };


    return (
        <Grid container>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={true}
                closeOnClick
                autoClose={1500}
                rtl={false}
                draggable
                pauseOnHover
            ></ToastContainer>
            <Grid item xs={12}  style={{padding:0}}>
                <h1>Referrals</h1>
            </Grid>
            <Grid item xs={12}  className="flexDisplay padding0" direction ="row-reverse" >
                <Grid item sm={1} className="flexDisplay padding0" direction ="row"  direction ="row-reverse">
                    <Badge color="secondary" variant="dot" invisible={!isFilterApplied[currentTab]} style={{margin:"20px 0 "}}>
                        <IconButton 
                            className="filterButton marginLight20"
                            onClick={() =>setFilterDrawer(true)}
                        >
                            <img src={filter} alt="img" />
                        </IconButton>
                    </Badge>
                </Grid>
                <Grid item sm={3} className="flexDisplay padding0" direction ="row" justify="space-between">
                    <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel htmlFor="sortBy">Sort by:</InputLabel>
                        <Select
                            id="sortBy"
                            IconComponent= {() => <ExpandMoreIcon />}
                            value={sortBy[currentTab]}
                            onChange={event => {
                                let temp=sortBy
                                temp[currentTab]=event.target.value
                                setSortBy([...temp])
                            }}
                            className={classes.select}
                            label="Sort by:"
                            inputProps={{
                                name: 'Sort by:',
                                id: 'sortBy',
                            }}
                            >
                                <MenuItem key="referral_date_sort" value="referral_date">Referral date</MenuItem>
                                <MenuItem key="joining_date_sort" value="joining_date">Joining date</MenuItem>
                        </Select>
                    </FormControl>
                    {sortBy && <ToggleButton
                        selected={sortDirection[currentTab]}
                        className = "recSortOrderButton"
                        // style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                        onChange={() => {
                            let temp=sortDirection
                            temp[currentTab]=!sortDirection[currentTab]
                            console.log(sortDirection)
                            setSortDirection([...temp]);
                        }}
                    >
                        {!sortDirection[currentTab]?<ArrowUpwardSharpIcon />:<ArrowDownwardSharpIcon />}
                    </ToggleButton>}
                
                </Grid>
                <Grid item sm={8} className="flexDisplay padding0" >
                    <Button
                        variant='contained'
                        disabled={!excelData}
                        style={{backgroundColor: "#1c75bc !important", margin: "20px 0" }}
                    >
                        <CSVLink
                        data={excelData}
                        filename={excelFileName}
                        className='btn btn-primary'
                        target='_blank'
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            fontWeight: '500'
                        }}
                        >
                        Export To Excel
                        </CSVLink>
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}  style={{padding:0}}>
                <Tabs className="interviewDashboardTabContainer" selectedIndex={currentTab} onSelect={tabIndex => setCurrentTab(tabIndex)}>
                    <TabList className="interviewDashboardTabs">
                        <Tab>Referrals</Tab>
                        <Tab>Open claims</Tab>
                        <Tab>Processed claims</Tab>
                    </TabList>
                    <TabPanel  >
                        {data[currentTab] && <Referrals 
                            data={data[currentTab]}
                            currentTab={currentTab} 
                            showToast={showToast}
                            updateMyData={updateMyData}
                            perPage={perPage[currentTab]} 
                            columns={referralColumns}
                            currentPage={currentPage[currentTab]}
                            totalRows={totalRows[currentTab]}
                            totalPages={totalPages[currentTab]}
                            handlePageChange={handlePageChange}
                            handlePerRowsChange={handlePerRowsChange}
                        />}
                    </TabPanel>
                    <TabPanel >
                        {data[currentTab] && <OpenClaims 
                            data={data[currentTab]}
                            updateMyData={updateMyData}
                            showToast={showToast}
                            currentTab={currentTab} 
                            perPage={perPage[currentTab]} 
                            columns={openClaimsColumns}
                            currentPage={currentPage[currentTab]}
                            totalRows={totalRows[currentTab]}
                            totalPages={totalPages[currentTab]}
                            handlePageChange={handlePageChange}
                            handlePerRowsChange={handlePerRowsChange}
                        />}
                    </TabPanel>
                    <TabPanel >
                        {data[currentTab] && <ProcessedClaims 
                            currentTab={currentTab} 
                            updateMyData={updateMyData}
                            showToast={showToast}
                            data={data[currentTab]}
                            columns={processedClaimsColumns}
                            perPage={perPage[currentTab]} 
                            currentPage={currentPage[currentTab]}
                            totalRows={totalRows[currentTab]}
                            totalPages={totalPages[currentTab]}
                            handlePageChange={handlePageChange}
                            handlePerRowsChange={handlePerRowsChange}
                        />}
                    </TabPanel>
                </Tabs>
            </Grid>
            <Drawer anchor="right" open={filterDrawer} onClose={() => setFilterDrawer(false) }>
                <Filters 
                    filters={filters}
                    updateFilters={setFilters}
                    resetFilters={resetFilters}
                    isFilterApplied={isFilterApplied}
                    setIsFilterApplied={setIsFilterApplied}
                    companyList={companyList}
                    managerList={managerList}
                    recruiterList={recruiterList}
                    currentTab={currentTab}
                />           
            </Drawer>

        </Grid>
    )
}

export default InterviewDashboard