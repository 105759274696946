import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import styled from 'styled-components'
// import DoneSharpIcon from '@material-ui/icons/DoneSharp';
// import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import ScaleLoader from "react-spinners/ScaleLoader";
import Pagination from '../../utils/react-table/pagination'
import { Grid, Button, Select, MenuItem, IconButton, Modal, Checkbox, Backdrop, Slide, Zoom, TableSortLabel } from '@material-ui/core';
import urlLinks from '../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import axios from 'axios';
import Filters from './filterModal'
import { Link } from 'react-router-dom';
// import CVuploadDownload from './Functions/cvUploadDownload'
import { CSVLink } from 'react-csv';
import { ToastContainer, toast } from 'react-toastify';

import Typography from '@material-ui/core/Typography';
// import Filters from '../Sales tracker/filterModal'
// import EditableTextInput from "./Functions/EditableTextInput"
import EditableDropdown from "./functions/EditableDropdown"
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            height:700px;
        }
        table {
            border-spacing: 0;
            height:685px;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #1c75bc !important;

            }
            th,td{
                padding:12px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const EmplopyerLedgerV2 = () => { 

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [filterUrl, setFilterUrl] = useState('')
    const [sortUrl, setSortUrl] = useState('')
    const [filters, setFilters] = useState({
        invoice_date: [null, null],
        payment_date: [null, null]
    })

    const columns = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} disabled={true} />
            //         </div>
            //         ),
            //         Cell: ({ row }) =>  (
            //             <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.invoice_id?false:true} />
            //             </div>
            //             ),
            // },
            //change in emp emp
            {
                Header: "ID",
                accessor: "employerLedgerId",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Brand Name",
                accessor: "brandName",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Industry",
                accessor: "industry",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Job Details",
                accessor: "jobDetails",
                defaultCanFilter: false,
                defaultCanSort: false,
                // Cell: CVuploadDownload,
                isVisible: true
            },
            {
                Header: "Worker Invoiced",
                accessor: "workerIncoiced",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            // {
            //     Header: "Worker Payout",
            //     accessor: "workerPayout",
            //     defaultCanFilter: false,
            //     defaultCanSort: false,
            //     isVisible: true
            // },
            {
                Header: "Invoice Date",
                accessor: "invoiceDate",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice No",
                accessor: "invoiceNo",
                Cell: row => {
                    if (row.row.original.invoiceFilePath != null)
                        // return
                        return row.row.original.invoiceFilePath ? <Link to={{ pathname: `${row.row.original.invoiceFilePath}` }} target="_blank" className="nameLink">{row.row.original.invoiceNo}</Link> : <span></span>
                    else
                        return row.row.original.invoiceNo
                },
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Total Amount",
                accessor: "totalAmount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Invoice Amount",
                accessor: "invoiceAmount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Payment Status",
                accessor: "paymentStatus",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Payment Credited",
                accessor: "paymentCredited",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },

            {
                Header: "TDS Deducted",
                accessor: "tdsDeducted",
                // Cell: EditableDropdown,
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Payment Date",
                accessor: "paymentDate",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            // {
            //     Header: "Net Sales",
            //     accessor: "netSales",
            //     defaultCanFilter: false,
            //     defaultCanSort: false,
            //     isVisible: true
            // },
            {
                Header: "Last Updated On",
                accessor: "lastUpdatedOn",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Company SPOC",
                accessor: "companySpoc",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "SPOC Contact",
                accessor: "spocContact",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Account Manager",
                accessor: "accountManager",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Remarks",
                accessor: "remarks",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            }
            // {
            //     Header: "Invoice No.",
            //     accessor: "invoiceNo",
            //     Cell: EditableTextInput,
            //     defaultCanFilter :false,
            //     defaultCanSort:false,
            //     isVisible: true
            // },
        ],
        []
    );

    const showToast = (type, message) => {
        if (type === "success")
            return toast.success(message);
        else if (type === "error")
            return toast.error(message);
    }

    const updateMyData = () => {
        getTrackerList(perPage, currentPage);
    }
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected, setHiddenColumns, state: { hiddenColumns, selectedRowIds, sortBy } } = useTable({ columns, data, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)

    //added by Vaibhav
    const setWorkType = (type) => {
        if (type == 1)
            return "Full Time";
        else if (type == 2)
            return "Part Time";
        else if (type == 3)
            return "On Demand";

    }
    const makeData = (response) => {

        let extractedData = [];
        response.content.map(el => {
            console.log({ el })
            extractedData.push({

                employerLedgerId: el.employerLedgerId == null ? '-' : el.employerLedgerId,
                invoiceNo: el.invoiceNo == null ? '-' : el.invoiceNo,
                workerIncoiced: el.workerIncoiced == null ? '-' : el.workerIncoiced,
                workerPayout: el.workerPayout == null ? '-' :`₹ ` + el.workerPayout+` /-`,
                invoiceDate: el.invoiceDate == null ? '-' : moment(el.invoiceDate).format("DD/MM/YYYY"),
                brandName: el.brandName == null ? '-' : el.brandName,
                industry: el.industry == null ? '-' : el.industry,
                jobDetails: el.jobDetails == null ? '-' : el.jobDetails,
                totalAmount: el.totalAmount == null ? '-' :`₹ ` + el.totalAmount+` /-`,
                invoiceAmount: el.invoiceAmount == null ? '-' :`₹ ` + el.invoiceAmount+` /-`,
                paymentStatus: el.paymentStatus == null ? '-' : el.paymentStatus,
                paymentCredited: el.paymentCredited == null ? '-' : el.paymentCredited,
                tdsDeducted: el.tdsDeducted == null ? '-' : el.tdsDeducted,
                paymentDate: el.paymentDate == null ? '-' : moment(el.paymentDate).format("DD/MM/YYYY"),
                netSales: el.netSales == null ? '-' : el.netSales,
                lastUpdatedOn: el.lastUpdatedOn == null ? '-' : moment(el.lastUpdatedOn).format("DD/MM/YYYY"),
                companySpoc: el.companySpoc == null ? '-' : el.companySpoc,
                spocContact: el.spocContact == null ? '-' : el.spocContact,
                accountManager: el.accountManager == null ? '-' : el.accountManager,
                remarks: el.remarks == null ? '-' : el.remarks,
                invoiceFilePath:el.invoiceFilePath==null? null:el.invoiceFilePath
                // invoiceAmount :el.invoiceAmount==null?'-':el.invoiceAmount+` /-`
            });
        });
        console.log("Hello makeData ended");
        return extractedData;
    }

    const getTrackerList = (rows, page) => {
        const url = `${urlLinks.baseUrl}transactions/employerCustom/v2?page_no=${page - 1}&rows=${rows}${filterUrl}${sortUrl}`
        Axios.get(url)
            .then(response => {
                if (response) {
                    console.log({ response })
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    throw toast.error("No data from API")
                }
            })
            .catch(error => {
                setLoading(false)
                throw toast.error("No data from API")
            })
    }

    useEffect(() => {
        setLoading(true)
        getTrackerList(perPage, 1);

    }, [filterUrl, sortUrl])

    const handlePageChange = page => {
        setLoading(true)
        getTrackerList(perPage, page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setLoading(true)
        getTrackerList(newPerPage, 1);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const loader = <div style={{ margin: "auto", position: "sticky", left: "45%", top: "30%" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>
    const ApplyFilter = () => {
        let url = '';

        if (filters["invoice_date"][0] && !filters["invoice_date"][1])
            url = `${url}&invoiceDateFrom=${moment(filters["invoice_date"][0]).format("YYYY-MM-DD")}&invoiceDateTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`

        if (!filters["invoice_date"][0] && filters["invoice_date"][1])
            url = `${url}&invoiceDateFrom=2019-01-01&invoiceDateTo=${moment(filters["invoice_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["invoice_date"][0] && filters["invoice_date"][1])
            url = `${url}&invoiceDateFrom=${moment(filters["invoice_date"][0]).format("YYYY-MM-DD")}&invoiceDateTo=${moment(filters["invoice_date"][1]).format("YYYY-MM-DD")} 23:59:59`



        if (filters["payment_date"][0] && !filters["payment_date"][1])
            url = `${url}&paymentDateFrom=${moment(filters["payment_date"][0]).format("YYYY-MM-DD")}&paymentDateTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`

        if (!filters["payment_date"][0] && filters["payment_date"][1])
            url = `${url}&paymentDateFrom=2019-01-01&paymentDateTo=${moment(filters["payment_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        if (filters["payment_date"][0] && filters["payment_date"][1])
            url = `${url}&paymentDateFrom=${moment(filters["payment_date"][0]).format("YYYY-MM-DD")}&paymentDateTo=${moment(filters["payment_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        setFilterUrl(url)
    }

    useEffect(() => {
        ApplyFilter();
    }, [filters])
    const handleExternalFilters = (list) => {
        setFilters({ ...filters, ...list })
    }
    const resetFilters = () => {
        let tempFilters = {
            invoice_date: [null, null],
            payment_date: [null, null]
        }
        setFilters(tempFilters)
        return tempFilters;
    }

    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={
                                    column.render('Header') === "Brand Name" ? "stickEmployerLedger zIndex1080 header" : "header"

                                }>

                                <span {...column.getSortByToggleProps()}>
                                    {column.id !== 'selection' ?
                                        <div>
                                            {column.render('Header')}
                                            <TableSortLabel
                                                style={{ color: "white" }}
                                                active={column.isSorted}
                                                direction={column.isSortedDesc ? 'asc' : 'desc'}
                                            />
                                        </div> : column.render('Header')}
                                </span>
                                <div>{column.defaultCanFilter ? column.render('Filter') : null}</div></th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                loading ?
                    loader :
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="row">
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}
                                            className={
                                                cell.column.Header === "Brand Name" ? "stickEmployerLedger zIndex540" : ""
                                            }>
                                            {cell.render('Cell')}
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
            }
        </table>
    </div>


    return (
        <Styles>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={true}
                closeOnClick
                autoClose={1500}
                rtl={false}
                draggable
                pauseOnHover
            ></ToastContainer>

            <Grid container >
            <Typography  style={{fontWeight:" bold",fontSize:"2em"}} variant="h4" component="h2">
             Employer ledger
          </Typography>
                <Grid item xs={12} className="buttonList paddingHorizontal" >
                    <Grid item xs={8} style={{ padding: 0 }}>
                        <Button
                            variant="contained"
                            className="dataButton"
                        >
                            <CSVLink
                                data={data}
                                filename={`Employer-Ledger-${(moment().format("DD/MM/YYYY HH:mm"))}.csv`}
                                className='btn btn-primary'
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    fontWeight: '500'
                                }}
                            >
                                Export To Excel
                            </CSVLink>
                        </Button>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 0 }} className="buttonList marginRightForChildExceptLast " direction="row-reverse">

                        <Filters
                            filters={filters}
                            updateFilters={handleExternalFilters}
                            resetFilters={resetFilters}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                        />

                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ padding: 0 }}>
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>

            </Grid>

        </Styles>
    )
}
export default EmplopyerLedgerV2;