import React, {useState, useEffect} from 'react'
import { Grid, TextField, IconButton, Select, MenuItem } from '@material-ui/core'
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Axios from 'axios';
import qs from 'querystring';
import urlLinks from '../../urlLinks';
const EditableApprovalStatus = (props) => {
    const {row, showToast, updateMyData}= props
    const [value, setValue] = useState(row.original.approvalStatus)
    const initialValue=row.original.approvalStatus
    const [showButtons, setShowButtons] = useState(false)
    const handleAprrovalStatus =(event) =>{
        if(showButtons===false){
            setShowButtons(true);
        }
        setValue(event.target.value)
    }
    console.log({value})
    const handleRemarksConfirm =() =>{
        const postData={
            inserted_by: localStorage.getItem("userId"),
            job_id: row.original.jobId,
            refer_by: row.original.referredById,
            approval_status: value,
            user_id: row.original.userId
        }
        Axios.post(`${urlLinks.baseUrl}/referral/approveClaim`, qs.stringify(postData))
            .then(response =>{
                if(response.data.code===1000){
                    updateMyData();
                    showToast("success", "Approval status updated")
                }
                else {
                    showToast("error", "Approval status update failed")
                }
                setShowButtons(false)
            })
    
        
    }
    const handleCancel =() =>{
        setShowButtons(false)
        setValue(initialValue)
    }
    return (
        <Grid container>
            <Grid item xs={12} className="padding0">
                <Select 
                    variant="outlined" 
                    value={value}
                    onClick={handleAprrovalStatus}
                >
                    <MenuItem key="APPROVED" value="APPROVED">Approved</MenuItem>
                    <MenuItem key="NOT_APPROVED" value="NOT_APPROVED">Not approved</MenuItem>
                    <MenuItem key="CLAIMED" value="CLAIMED">Claimed</MenuItem>
                    <MenuItem key="LEFT_BEFORE_REPLACEMENT_PERIOD" value="LEFT_BEFORE_REPLACEMENT_PERIOD">Left before replacement period</MenuItem>
                </Select>
            </Grid>
            {showButtons && <Grid item xs={12} className="padding0">
                <IconButton color="primary" onClick={handleRemarksConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </Grid>}
        </Grid>
    )
}

export default EditableApprovalStatus
