import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Tabs, Tab, Box, Button, Grid} from '@material-ui/core';
import { useHistory ,Link } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';
import urlLinks from '../../../urlLinks';
import JobDetails from './newJobViewComponents/jobdetails';
import JobRequirement from './newJobViewComponents/jobRequirement';
import InterviewDetails from './newJobViewComponents/interviewDetails';
import HRDetail from './newJobViewComponents/hrDetails'
import Instructions from './newJobViewComponents/instructions';
import Payout from './newJobViewComponents/payout';
import { ToastContainer, toast } from 'react-toastify';
import Axios from 'axios';
import qs from 'querystring';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
         {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 424,
  },
  tabs: {
    backgroundColor: "#f4f4f4",
    width: "auto",
    // padding: "40px 60px"
    // border: "2px solid green"
  },
  indicator: {
    backgroundColor:"unset"
  }
}));

export default function VerticalTabs() {
    const classes = useStyles();
    const [value, setValue] = useState(1);
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    const history= useHistory();
    const [boost, setBoost] = useState(false)
    
    const [industryName, setIndustryName] = useState('')
    const jobId=history.location.id
    const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const convertJobIdtoHex = (val) => {
    var s = "abcde" + val.toString(36);
    return " "+s.substring(s.length - 5).toUpperCase();
  }
  const numberFormat = (value) =>{
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);}

  useEffect( () => {
    if(!history.location.id)
      history.push("/jobs/jobList");
    else{
      fetch(`${urlLinks.baseUrl}/job/V2/getdetailedjob?job_id=${history.location.id}`)
        .then(response => response.json())
        .then(res => {
          const data=res.response.content[0]
          setBoost(data.detailsJobs[0].boostJob!==1?false:true)
          setData(data);
          
          const industryId=data.detailsJobs[0].jobCategoryId? data.detailsJobs[0].jobCategoryId: data.company.industryType;
          if(industryId){
            Axios.get(`${urlLinks.baseUrl}/configmaster/?id=${industryId}`)
              .then(response => {
                const industryName=response.data.response.content[0].typeDesc;
                setIndustryName(industryName)
              });
          } 
              setIsLoading(false)
        })
      }
  }, [isLoading]);
  const boostHandler =() =>{
    
    const postData={
      job_id: data.jobId,
      job_detail_id : data.detailsJobs[0].jobDetailsId ,
      boost_job: data.detailsJobs[0].boostJob===1?0:1,
      requested_by : parseInt(localStorage.getItem('userId'))
    }
    Axios.post(`${urlLinks.baseUrl}/job/boostjob/?`,qs.stringify(postData))
      .then(response => {
        if(response.data.code === 1000)
        {
          if(boost){
            toast.success(`Job unboosted successfully`);
          }
          else{
            toast.success(`Job boosted successfully`);
          }
          const temp=data;
          setBoost(!boost)
          setData(...temp)
          history.push('/jobs/jobList');
        }
        else{
          throw new Error(response.data.message);
        }
      })
  }
  const cancelHandler =() =>{
    Axios.post(`${urlLinks.baseUrl}/job/canceljob?job_id=${jobId}&requested_by=${parseInt(localStorage.getItem('userId'))}&status=0`)
      .then(response => {
        toast.success(`Job Deleted Successfully`);
        history.push('/jobs/jobList');
      })
      .catch(error => toast.error(`Error While Deleting Job`));
  }
  return (
    isLoading?
        <div className="skeltonTabs">
            <Skeleton variant="text" />
            <Skeleton variant="circle" width={140} height={140} />
            <Skeleton variant="rect" height={118} />
        </div>:
        <Grid container className="jobDetail" style={{padding: "40px 20px"}} >
          <ToastContainer
              position='top-right'
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              autoClose={3000}
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
          ></ToastContainer>
          <Grid item lg={10} md={10}  sm={9} xs={12}>
            <Grid container className="jobDetail"  >
                <Grid item sm={3}>
                </Grid>
                <Grid item sm={9} style={{paddingLeft:"20px"}}>
                  <Grid item sm={12}>
                    <Button
                      className="backButton"
                      onClick={history.goBack}>
                      {`< Back to job list`}
                    </Button>
                  </Grid>  
                  <Grid item xs={12} className="MainHeading">
                    <span>{`${data.company.brandName?data.company.brandName:data.company.companyName} (${convertJobIdtoHex(data.detailsJobs[0].jobDetailsId)} - ${data.workType===1?'Full Time':data.workType===2?'Part Time':'On Demand'} )`}</span>
                  </Grid>  
                </Grid>
            </Grid>
            <Grid container className="jobDetail" >
              <Grid container className="jobDetail"  >
                <Grid item sm={3} className='tabs' style={{marginTop:"-130px"}}>
                  <Tabs
                    orientation="vertical"
                    // variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    classes={{ indicator: classes.indicator }} 
                    
                    className={classes.tabs}
                    >
                    <div className="profileBasic">
                        <img src={data.company.companyLogo} alt= "image" />
                        <span style={{whiteSpace: "pre-wrap"}}>Industry: {industryName} </span>
                        <span>Status: {data.jobStatus === 1? 'Active':data.jobStatus === 2? 'Inactive': "Closed"} </span>
                        <Link
                          to={{
                            pathname: '/jobs/newEditJob', 
                            id: jobId}}
                        >
                          <Button
                              style={{marginTop: "24px"}}
                              color= "primary"
                              variant= "outlined"
                          >
                            EditJob
                          </Button>
                        </Link>
                    </div>
                    <Tab label="Job details" {...a11yProps(1)} style={{margin: "0 auto !important"}}/>
                    <Tab label="Job requirements" {...a11yProps(2)} style={{margin: "0 auto !important"}} />
                    <Tab label="Interview details" {...a11yProps(3)}  style={{margin: "0 auto !important"}}/>
                    <Tab label="HR details" {...a11yProps(4)} style={{margin: "0 auto !important"}} />
                    <Tab label="Instructions" {...a11yProps(5)} style={{margin: "0 auto !important"}} />
                    <Tab label="Payout" {...a11yProps(6)} style={{margin: "0 auto !important"}} />
                  </Tabs>
                </Grid>
                <Grid item sm={9}>
                  <TabPanel value={value} index={1}>
                    <JobDetails data= {data} convertJobIdtoHex={convertJobIdtoHex} numberFormat={numberFormat} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <JobRequirement data= {data} convertJobIdtoHex={convertJobIdtoHex} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <InterviewDetails data= {data} convertJobIdtoHex={convertJobIdtoHex} />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <HRDetail data= {data} convertJobIdtoHex={convertJobIdtoHex}/>
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <Instructions data= {data} convertJobIdtoHex={convertJobIdtoHex} />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <Payout data= {data} convertJobIdtoHex={convertJobIdtoHex} numberFormat={numberFormat}/>
                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2}  md={2}  sm={3} xs={12} style={{marginTop:"65px"}}>
            <Grid item xs={12}>
              <Button
                className="boostJob"
                variant='contained'
                onClick={boostHandler}
              >
                {!boost?'BOOST JOB':'UNBOOST JOB'}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                className="cancelJob"
                variant='contained'
                onClick={cancelHandler}
              >
                CLOSE JOB
              </Button>
            </Grid>
          </Grid>
        </Grid>
  );
}
