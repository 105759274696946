import React, { Component } from 'react';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import utils from '../../../utilMethods';
import { Formik } from 'formik';
import { FormControlLabel, Checkbox } from '@material-ui/core';
const styles = theme => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  text_container: {
    width: '80%',
    height: '55px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  input: {
    padding: '10px',
    height: '40px',
    fontSize: '0.8rem'
  },
  checkBox: {
    marginLeft: '10px'
  },
  inputLabel: {
    transform: 'translate(13px, 12px) scale(1)',
    fontSize: '0.8rem'
  }
});

class JobCategory extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      selectedStartTime: new Date(),
      selectedEndTime: new Date(),
      jobCategory: null
    };
    this.weekDays = [
      {
        id: '1',
        day: '1'
      },
      {
        id: '2',
        day: '2'
      },
      {
        id: '3',
        day: '3'
      },
      {
        id: '4',
        day: '4'
      },
      {
        id: '5',
        day: '5'
      },
      {
        id: '6',
        day: '6'
      },
      {
        id: '7',
        day: '7'
      }
    ];
  }
  handleStartDateChange = date => {
    this.setState({ selectedStartDate: date });
  };
  handleEndDateChange = date => {
    this.setState({ selectedEndDate: date });
  };
  handleStartTimeChange = date => {
    // console.log(date)
    this.setState({ selectedStartTime: date });
  };
  handleEndTimeChange = date => {
    this.setState({ selectedEndTime: date });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Formik
          initialValues={{
            day: '1',
            days: [],
            category:
              {
                OD: {
                  startDate: null,
                  startTime: null,
                  endTime: null
                },
                PT: {
                  startDate: null,
                  endDate: null,
                  startTime: null,
                  endTime: null
                },
                FT: {
                  startDate: null,
                  startTime: null,
                  endTime: null
                }
              }
          }}
          validationSchema={this.addWorkerSchema}
          onSubmit={values => {
            this.handleSubmitValues(values);
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              setValues
            } = props;
            this.formikValues = values;
            this.formikSetValues = setValues;
            const handleDayChange = day => {
              if (day) {
                values.day = day;
                this.props.setDaysValues(values.day);
              }
            };
            const handleDaysChange = day => {
              if (values.days.includes(day)) {
                var index = values.days.indexOf(day);
                if (index !== -1) values.days.splice(index, 1);
              } else {
                values.days.push(day);
              }
              this.props.setDaysValues(values.days);
            };
            const handleSelectTimings = (type, value, dateTime) => {
              values.category[type][value] = utils.roundTimeQuarterHour(dateTime);
              values.category[type][value] = dateTime;
              this.props.setCategoryValues(values.category);
            };
            return (
              <React.Fragment>
                {this.props.value === 'onDemand' ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        margin='normal'
                        id='date-picker-dialog'
                        // minDate={new Date()}
                        label='Job Start Date'
                        format='dd/MM/yyyy'
                        value={values.category.OD.startDate}
                        error={values.category.OD.startDate ? false : true}
                        onChange={date =>
                          handleSelectTimings('OD', 'startDate', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />
                      <KeyboardTimePicker
                        margin='normal'
                        id='time-picker'
                        label='Job Start Time'
                        value={values.category.OD.startTime}
                        error={values.category.OD.startTime ? false : true}
                        onChange={date =>
                          handleSelectTimings('OD', 'startTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </Grid>
                    <Grid container justify='space-around'>
                      <TextField
                        style={{
                          width: '250px',
                          marginTop: '25px'
                        }}
                        className={classes.text_container}
                        variant='outlined'
                        select
                        error={errors.day && touched.day}
                        label='Select Days'
                        name='day'
                        value={values.day}
                        onChange={event => handleDayChange(event.target.value)}
                        helperText={errors.day && touched.day && errors.day}
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel
                        }}
                      >
                        {this.weekDays.map(option => (
                          <MenuItem key={option.id} value={option.day}>
                            {option.day}
                          </MenuItem>
                        ))}
                      </TextField>

                      <KeyboardTimePicker
                        margin='normal'
                        id='time-picker2'
                        label='Job End Time'
                        value={values.category.OD.endTime}
                        error={values.category.OD.endTime ? false : true}
                        onChange={date =>
                          handleSelectTimings('OD', 'endTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : (
                  <Grid></Grid>
                )}
                {this.props.value === 'partTime' ? (
                  <div>
                    <Grid style={{ textAlign: 'center' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value='Monday'
                            onClick={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={values.days.includes('Monday')}
                          />
                        }
                        label='M'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value='Tuesday'
                            className={classes.checkBox}
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={values.days.includes('Tuesday')}
                          />
                        }
                        label='T'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Wednesday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={values.days.includes('Wednesday')}
                          />
                        }
                        label='W'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Thursday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={values.days.includes('Thursday')}
                          />
                        }
                        label='T'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Friday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={values.days.includes('Friday')}
                          />
                        }
                        label='F'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Saturday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={values.days.includes('Saturday')}
                          />
                        }
                        label='S'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkBox}
                            value='Sunday'
                            onChange={event =>
                              handleDaysChange(event.target.value)
                            }
                            color='primary'
                            checked={values.days.includes('Sunday')}
                          />
                        }
                        label='S'
                      />
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify='space-around'>
                        <KeyboardDatePicker
                          margin='normal'
                          // minDate={new Date()}
                          style={{ width: '200px' }}
                          id='date-picker-dialog'
                          label='Job Start Date'
                          format='dd/MM/yyyy'
                          value={values.category.PT.startDate}
                          error={values.category.PT.startDate ? false : true}
                          onChange={date =>
                            handleSelectTimings('PT', 'startDate', date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                        <KeyboardDatePicker
                          margin='normal'
                          // minDate={values.category.PT.startDate || new Date()}
                          style={{ width: '200px', marginLeft: '50px' }}
                          id='date-picker-dialog2'
                          label='Job End Date'
                          format='dd/MM/yyyy'
                          value={values.category.PT.endDate}
                          error={values.category.PT.endDate ? false : true}
                          onChange={date =>
                            handleSelectTimings('PT', 'endDate', date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </Grid>
                      <Grid container justify='space-around'>
                        <KeyboardTimePicker
                          style={{ width: '200px' }}
                          margin='normal'
                          id='time-picker'
                          label='Job Start Time'
                          value={values.category.PT.startTime}
                          error={values.category.PT.startTime ? false : true}
                          onChange={date =>
                            handleSelectTimings('PT', 'startTime', date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change time'
                          }}
                        />
                        <KeyboardTimePicker
                          style={{ width: '200px', marginLeft: '50px' }}
                          margin='normal'
                          id='time-picker2'
                          label='Job End Time'
                          value={values.category.PT.endTime}
                          error={values.category.PT.endTime ? false : true}
                          onChange={date =>
                            handleSelectTimings('PT', 'endTime', date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change time'
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                ) : (
                  <Grid></Grid>
                )}
                {this.props.value === 'fullTime' ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        margin='normal'
                        // minDate={new Date()}
                        id='date-picker-dialog'
                        label='Job Start Date'
                        format='dd/MM/yyyy'
                        value={values.category.FT.startDate}
                        error={values.category.FT.startDate ? false : true}
                        onChange={date =>
                          handleSelectTimings('FT', 'startDate', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                      />

                      <KeyboardTimePicker
                        style={{ width: '200px' }}
                        margin='normal'
                        id='time-picker'
                        label='Job Start Time'
                        value={values.category.FT.startTime}
                        error={values.category.FT.startTime ? false : true}
                        onChange={date =>
                          handleSelectTimings('FT', 'startTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                      <KeyboardTimePicker
                        style={{ width: '200px' }}
                        margin='normal'
                        id='time-picker2'
                        label='Job End Time'
                        value={values.category.FT.endTime}
                        error={values.category.FT.endTime ? false : true}
                        onChange={date =>
                          handleSelectTimings('FT', 'endTime', date)
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change time'
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                ) : (
                  <Grid></Grid>
                )}
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    );
  }
}
export default withStyles(styles)(JobCategory);
