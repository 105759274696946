import React from 'react';
import { MdComment, MdModeEdit, MdLibraryAdd } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import utils from '../../../utilMethods';

let jobTitle = {};
let company=[];
let candidateName= [];
let phoneNumber= [];

const feedbackList = ( jobTitle, company, candidateName, phoneNumber) => {

    const feedbackFilterList = [
        {
            vacancy_name: Object.keys(jobTitle),
            jobTitle: { ...jobTitle },
            id: '1',
            name: 'vacancy_name',
            typeName: 'vacancyName',
            label: 'Job Title',
            default: []
        },
        {
            company_name: Object.keys(company),
            company: { ...company },
            id: '2',
            name: 'company_name',
            typeName: 'company',
            label: 'Company Name',
            default: []
        },
        {
            phone_number: Object.keys(phoneNumber),
            candidateNumber: { ...phoneNumber },
            id: '3',
            name: 'phone_number',
            typeName: 'candidateNumber',
            label: 'Candidate Number',
            default: []
        },
        {
            user_name: Object.keys(candidateName),
            candidateName: { ...candidateName },
            id: '4',
            name: 'user_name',
            typeName: 'candidateName',
            label: 'Candidate Name',
            default: []
        }
        
    ];
    return feedbackFilterList;
};

 const getfeedbackFilterList =  ( list) => {
    if(list)
    {
        list.map((item, index ) => {
            jobTitle[item.profile]=index;
            phoneNumber[item.candidateNumber]=index;
            candidateName[item.userName]=index;
            company[item.companyName]= index;
        })
        console.log({candidateName});
        
        const filterlist =  feedbackList(jobTitle, company, candidateName, phoneNumber );    
        return filterlist;
    }
};

export default getfeedbackFilterList;