import React from 'react';
import { MdComment,MdModeEdit,MdLibraryAdd} from "react-icons/md";
import { FaEye} from "react-icons/fa";

const onBoardUtils={
    OnBoardingListAction:
        [{
            id:1,
            name:"View Employer Details",
            subIcon:<FaEye className="fitIcon"/>,
            route:"/employer/onBoardingReview"
        },
        {
            id:2,
            name:"Update Payment Term",
            subIcon:<MdModeEdit className="fitIcon"/>,
            route:"/employer/onBoardingReview"
        }
        ,
        {
            id:3,
            name:"Update Status",
            subIcon:<MdLibraryAdd className="fitIcon"/>,
            route:"/employer/onBoardingReview"
        },
        {
            id:4,
            name:"Comments",
            subIcon:<MdComment className="fitIcon"/>,
            route:"/employer/onBoardingReview"
        }
    ],
 OnBoardingFilterList:[
     {industry:["Restaurant","Health","MEchanical"],id:"1",name:"industry",label:"Industry",default:[]},
     {location:["Delhi","Gurgaon","Noida"],id:"2",name:"location",label:"Location",default:[]},
     {status:["Registered Only","Partly Filled Info","All Info Filled"],id:"3",name:"status",label:"Status",default:[]},
     {reviewStatus:["Not Reviewed","In Review","Rejected","Reviewed","ReviewOnHold"],id:"4",name:"reviewStatus",label:"Review Status",default:[]}

 ]

    
}

export default onBoardUtils