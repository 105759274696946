import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import BackButton from '../../../utils/BackButton/BackButton';
import { CircleLoader } from 'react-spinners';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import urlLinks from '../../../urlLinks';
import Axios from 'axios';

const styles = {
  root: {
    flexGrow: 1
  },
  Container: {
    backgroundColor: '',
    // marginTop: '3%',
    fontSize: '.8rem'
  },
  Avatar: {
    marginTop: '15%',
    width: '120px',
    height: '120px',
    fontSize: '.8rem'
  },
  info: {
    marginTop: '7%',
    fontSize: '.8rem'
  },
  LocationInfo: {
    marginTop: '13%',
    fontSize: '.8rem'
  },
  BasicDetail: {
    marginTop: '4.7%',
    fontSize: '.8rem'
  },
  AvatarCaption: {
    marginLeft: '6%',
    paddingTop: '3%',
    fontSize: '.8rem'
  },
  heading: {
    fontSize: '15px',
    fontWeight: 'Regular',
    fontWeight: 'bold',
    fontSize: '.8rem'
  },
  expandSalary: {
    marginLeft: '56%',
    fontWeight: 'bold',
    fontSize: '.8rem'
  },
  innerDetail: {
    fontWeight: 'bold',
    fontSize: '.8rem'
  },
  fontSize: {
    fontSize: '.8rem'
  }
};

class JobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      industries: {},
      jobType: '',
      companyName: '',
      jobStatus: '',
      jobLocation1: '',
      jobDetailForEdit: [],
      userId: '',
      workType: '',
      jobLocation2: '',
      contactPerson: '',
      contactNumber: '',
      loginTime: '',
      logoutTime: '',
      startDate: '',
      endDate: '',
      jobDays: '',
      file: '',
      dayCount: '',
      jobId: '',
      skillName: '',
      skillValue: '',
      industryType: '',

      JobDetail: []
    };
    this.convertJobIdtoHex= this.convertJobIdtoHex.bind(this);
    this.ID='';
  }

  componentDidMount() {
    if (!this.props.location.id) {
      this.redirectToJobList();
    } else {
      const industries = {};
      Axios.get(
        `${urlLinks.baseUrl}/configmaster/?category_sub_type=industry&category_type=job_type`
      ).then(response => {
          for (const industry of response.data.response.content) {
            industries[industry.id] = [industry.typeDesc];
          }
          this.setState({ industries: industries }, () =>
            this.getEmployerDataById(this.props.location.id)
          );
      })
    }
  }

  redirectToJobList() {
    this.props.history.push('/jobs/jobList');
  }

  getEmployerDataById = id => {
    // console.log(id);

    Axios.get(`${urlLinks.baseUrl}/job/getdetailedjob?job_id=${id}`).then(
      response => {
        console.log(response.data.response.content);
        const data = response.data.response.content[0];
        console.log(data)
        Axios.get(
          `${urlLinks.baseUrl}/admin/companies`
        ).then(response2 => {
          const companyDetail = response2.data.response.filter(el => el.companyId === data.companyId)[0]
          var DAY = [];
          DAY[0] = JSON.parse(data.workdaysDetails);

          var skillName = [];
          var skillValue = [];
          var mapDateValues = {
            1: 'Sunday',
            2: 'Monday',
            3: 'Tuesday',
            4: 'Wednesday',
            5: 'Thursday',
            6: 'Friday',
            7: 'Saturday'
          };
          var fDays = '';
          if (DAY[0] !== null) {
            if (DAY[0][0]) {
              fDays = 'Everyday';
            } else if (DAY[0]) {
              var ActiveDays = [];
              Object.keys(DAY[0]).map(el => {
                if (DAY[0][el]) {
                  ActiveDays.push(el);
                }
              });
              if (ActiveDays.length === 0) {
                fDays = 'Days Not Selected';
              }
              else if (ActiveDays.length === 7) {
                fDays = 'Everyday';
              } else {
                var ActiveDAyValues = ActiveDays.map(el => mapDateValues[el]);
                fDays = ActiveDAyValues.join(', ');
              }
            }
          }
          var JobDetailCopy = [];
          var j = 0;
          for (let i = 0; i < data.detailsJobs.length; i++) {
            // console.log(data.detailsJobs)
            var skillArr = {};
            data.detailsJobs[i].detailSkills.map(el => {
              skillArr[el.skillName] = el.skillValue;
            });
            var a = {};
            a.jobDetailId = data.detailsJobs[i].jobDetailsId
            a.job = data.detailsJobs[i].jobTitle;
            a.salary = data.detailsJobs[i].amount;
            a.JobDiscription = data.detailsJobs[i].additionalRequirement;
            a.Gender = data.detailsJobs[i].gender;
            a.Qualification = data.detailsJobs[i].qualificationRequired;
            a.detailSkills = skillArr;
            JobDetailCopy[j] = a;
            j++;
          }
          let startTime = data.loginTime?data.loginTime.substring(0, 5).split(':'):null;
          startTime = startTime?
            (startTime[0] >= 12 &&
              (startTime[0] - 12 || 12) + ':' + startTime[1] + ' PM') ||
            (Number(startTime[0]) || 12) + ':' + startTime[1] + ' AM': null;
          let endTime = data.logoutTime?data.logoutTime.substring(0, 5).split(':'):null;
          endTime = endTime?
            (endTime[0] >= 12 &&
              (endTime[0] - 12 || 12) + ':' + endTime[1] + ' PM') ||
            (Number(endTime[0]) || 12) + ':' + endTime[1] + ' AM': null;
          this.setState(
            {
              jobType: data.workTypeKey ? data.workTypeKey : '',
              companyName: data.company.companyName
                ? data.company.companyName
                : '',
              jobStatus: data.jobStatus === 1 ? 'Active' : 'DeActive',
              jobDetailForEdit: data.detailsJobs,
              jobLocation1: data.address ? `${data.address.split(',').slice(0, data.address.split(',').length-2).join(',')}${data.landMark ? ', '+data.landMark+',' : ''} ${data.address.split(',').slice(data.address.split(',').length-2, data.address.split(',').length).join(',')}` : '',
              jobLocation2: data.landMark ? data.landMark : '',
              loginTime: startTime,
              logoutTime: endTime,
              startDate: data.startDate
                ? data.startDate
                    .toString()
                    .split('-')
                    .reverse()
                    .join('-')
                : '',
              endDate: data.endDate
                ? data.endDate
                    .toString()
                    .split('-')
                    .reverse()
                    .join('-')
                : '',
              userId: data.userId,
              dayCount: data.workType === 1 ? null : data.workdaysCount ? data.workdaysCount : '',
              jobId: data.jobId ? data.jobId : '',
              jobDays: data.workType === 1 ? null : fDays,
              JobDetail: JobDetailCopy,
              workType: data.workType,
              file: data.company.companyLogo,
              skillName: skillName ? skillName : [],
              skillValue: skillValue ? skillValue : [],
              contactPerson: companyDetail.contactPerson,
              contactNumber: companyDetail.contactNo,
              industryType: data.company.industryType
                ? this.state.industries[data.company.industryType]
                : '',
              isLoading: false
            });
          // const endDate=this.state.endDate
          // if(this.state.jobStatus==1){
          //     this.setState({jobStatus:"Active"})
          // }
          // if(this.state.jobStatus==0){
          //     this.setState({jobStatus:"DeActive"})
          // }
        });
      })
      .catch(err => {
        toast.error(`Error Loading Job Details`);
        this.props.history.push('/jobs/jobList');
      })
  };

  deleteHandler = () => {
    Axios.post(`${urlLinks.baseUrl}/job/canceljob?job_id=${this.state.jobId}`)
      .then(response => {
        toast.success(`Job Deleted Successfully`);
        this.props.history.push('/jobs/jobList');
      })
      .catch(error => toast.error(`Error While Deleting Job`));
  };

  convertJobIdtoHex(val){
    var s = "abcde" + val.toString(36);
    return " "+s.substring(s.length - 5).toUpperCase();
  }

  
  render() {
    // console.log(this.props.location.id);
    this.ID=this.convertJobIdtoHex(this.state.jobId);
    const { classes } = this.props;
    if (this.state.isLoading) {
      return (
        <CircleLoader
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto'
          }}
          color={'#194D33'}
        ></CircleLoader>
      );
    } else {
      return (
        <div>
          <BackButton />
          <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={4000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          ></ToastContainer>
          <Container maxWidth='xl' className={classes.Container}>
            <Toolbar>
              <Typography variant='h6' className={classes.root}>
                View Job Details ({this.ID}-{this.state.jobType})
              </Typography>
            </Toolbar>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Avatar
                  variant='square'
                  alt='Image'
                  src={this.state.file}
                  className={classes.Avatar}
                />
                <Typography className={classes.AvatarCaption}>
                  {this.state.companyName}
                  <br />
                  Industry: {this.state.industryType}
                  <br />
                  {this.state.jobStatus}
                  <br />
                  <Button
                    style={{ fontSize: '0.8rem' }}
                    variant='contained'
                    color='secondary'
                    onClick={this.deleteHandler}
                  >
                    Cancel Job
                  </Button>
                  {/* Rating: 4.5 */}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.BasicDetail}>
                <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>
                  Basic Details
                </Typography>
                <Typography
                  style={{ fontSize: '15px' }}
                  className={classes.fontSize}
                >
                  Job Location:
                  <br />
                  <br />
                  <br />
                  <br />
                  Schedule:
                  <br />
                  <br />
                  <br />
                  <br />
                  Company Details:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.LocationInfo}>
                  {this.state.jobLocation1}
                </Typography>
                {/* <Typography className={classes.fontSize}>
                  {this.state.jobLocation2}
                </Typography> */}
                <br />
                <Typography
                  style={{ marginTop: '20px' }}
                  className={classes.fontSize}
                >
                  <span style={{ fontWeight: 'bold'}}>{this.state.loginTime}</span> to <span style={{ fontWeight: 'bold'}}>{this.state.logoutTime}</span> <br />{' '}
                  Starting from <span style={{ fontWeight: 'bold'}}>{this.state.startDate}</span>
                  {this.state.endDate ? this.state.jobType === 'Part Time' ? ' To ' : ' ' : null}
                  {this.state.endDate ? this.state.jobType === 'On Demand' ? ' To ' : ' ' : null}
                  <span style={{ fontWeight: 'bold'}}>{this.state.endDate}</span>
                </Typography>

                <Typography className={classes.fontSize}>
                  {this.state.jobDays ? this.state.jobType == 'Part Time' ? ' Days: ' : ' ' : null}
                  {this.state.jobDays}
                  {this.state.dayCount ? this.state.jobType == 'On Demand' ? ' Total Days: ' : ' ' : null}
                  {this.state.dayCount}
                </Typography>
                <Typography className={classes.fontSize} style={{ marginTop: '50px' }}>
                  {this.state.contactPerson ? `Contact Person - ${this.state.contactPerson}` : null}
                </Typography>
                <Typography className={classes.fontSize}>
                  Contact Number - 
                  {this.state.contactNumber ? <a style={{color: 'blue'}} href={`tel:${this.state.contactNumber}`}>{` ${this.state.contactNumber}`}</a> : null}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {' '}
              </Grid>
              <Grid item xs={9}>
                <Typography variant='h6'>Job Requirements</Typography>
                {this.state.JobDetail.map((JobDetail, index) => {
                  // console.log(JobDetail)
                  return (
                    <div key={index}>
                      <br />
                      <ExpansionPanel>
                        <ExpansionPanelSummary
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <Typography className={classes.heading}>
                            {JobDetail.job}
                          </Typography>
                          {<ExpandMoreIcon />}
                          <Typography className={classes.expandSalary}>
                            Salary : {JobDetail.salary} /{' '}
                            {this.state.workType === 3 ? ' Daily' : ' Monthly'}
                          </Typography>
                          <Link
                            key={index}
                            to={{ pathname: '/jobs/editDetailedJob', id: JobDetail.jobDetailId, jobId: this.state.jobId, userId: this.state.userId, jobDetails: this.state.jobDetailForEdit[index] }}
                          >
                            <Button 
                              style={{ fontSize: '0.8rem', marginRight: '15%' }}
                              // variant='contained'
                              color='primary'>
                                <EditIcon></EditIcon>
                            </Button>
                          </Link>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid item xs={6}>
                            <Typography className={classes.heading}>
                              Job Description
                            </Typography>
                            <Typography className={classes.fontSize}>
                              Additional Requirements :{' '}
                              {JobDetail.JobDiscription}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={2}>
                            <Typography className={classes.fontSize}>
                              {Object.keys(JobDetail.detailSkills).length > 0
                                ? Object.keys(JobDetail.detailSkills).map(
                                    (el, index) => (
                                      <>
                                        <span key={index}>{el} : </span>
                                        <br></br>
                                      </>
                                    )
                                  )
                                : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography className={classes.fontSize}>
                              {Object.values(JobDetail.detailSkills).length > 0
                                ? Object.values(JobDetail.detailSkills).map(
                                    (el, index) => (
                                      <>
                                        <span key={index}>{el}</span>
                                        <br></br>
                                      </>
                                    )
                                  )
                                : null}
                            </Typography>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      {/* <Grid item xs={2}>
                        <Button 
                          style={{ fontSize: '0.8rem' }}
                          variant='contained'
                          color='primary'>
                            <Link
                              key={index}
                              to={{ pathname: '/jobs/editDetailedJob', id: index }}
                            >
                              <DeleteIcon></DeleteIcon>
                            </Link>
                        </Button>
                      </Grid> */}
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    }
  }
}
export default withStyles(styles)(JobDetail);
