import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem, } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import './filter.css'
const useStyles = makeStyles({

});

export default function SelectFilter(props) {
    const { column, row, filters, setFilters } = props
    const id = column.id;
    const classes = useStyles();
    const [value, setValue] = React.useState(filters[id]);
    console.log(filters)
    const optionsRecruiterStatus = [
     
        ["ALL", "All"],
        ["REGISTERED", "Registered"],
        ["UNDER ASSESMENT", "Under Assessment"],
        ["APPROVED", "Approved"],
        ["ON HOLD", "On Hold"],
        ["PENDING FOR ONBOARDING", "Pending for Onboarding"],
        ["REJECTED", "Rejected"],
        ["ACTIVE", "Active"],
        ["INACTIVE", "Inactive"],
        ["INCOMPLETE REGISTRATION", "Incomplete Registration"]


    ]
        console.log({value})
    let options = null
    switch (id) {
        case "recruiterStatus":
            options = optionsRecruiterStatus.map(option => {
                return <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>
            })
            break;
        default:
            options = null
            break;
    }
    const handleBlur = (event) => {
        console.log("This")
        // setValue(event.target.value);
        setFilters({ ...filters, [id]: value })
    };
    const handleChange = (event) => {
        // console.log(event.target.value)
        // if(value.includes("") && event.target.value.length>1)
        //     setValue(event.target.value.filter(x => x!==""));
        // else if(event.target.value.includes("") || event.target.value.length===0)
        //     setValue([""])
        // else
            setValue(event.target.value);
    };
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} style={{ color: "white", marginTop: 1 }}>
                <Select
                    id={id}
                    // multiple
                    value={value}
                    onChange={handleChange}
                    style={{ color: "white", width: 200 }}
                    onBlur={handleBlur}
                >
                    {options}
                </Select>
            </Grid>
        </Grid>
    );
}


