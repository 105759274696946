import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import CustomMultiSelectDropDown from '../CustomMultiselectDropDown/CustomMultiSelectDropDown';
import DatePicker from '../DatePicker/DatePicker';
import Select from '../simpleSelect/simpleSelect';
import TextField from '@material-ui/core/TextField';

const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '700px',
    minWidth: '300px'
  },
  gridItemStyle: {
    padding: '10px !important'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class CustomFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      averageRating:this.props.averageRating,
      actualScore:this.props.actualScore,
      cutoffScore:this.props.cutoffScore,
      expInYears:this.props.expInYears,
      totalGigs:this.props.totalGigs,
      open: this.props.isOpen,
      worktype:this.props.rset,
      name:this.props.name,
      number:this.props.number,
      dates: this.props.dates,
      statusJoining:this.props.statusJoining,
      statusInterview:this.props.statusInterview,
      salary:this.props.salary,
      city:this.props.city,
      gender:this.props.gender,
      appliedBy:this.props.appliedBy,
      qualification:this.props.qualification,
      anchorEl: <b></b>
    };
    this.option=[
      {
        id:1,
        rating:'GOING',
        label:'Going'
      },
      {
        id:2,
        rating:'NOT_GOING',
        label:'Not Going'
      },
      {
        id:3,
        rating:'RESCHEDULE',
        label:'Reschedule'
      },
      {
        id:4,
        rating:'NO_RESPONSE',
        label:'No Response'
      },
      {
        id:5,
        rating:'APPEARED',
        label:'Appeared'
      }
    ]
    this.option1=[
      {
        id:1,
        rating:'JOINED',
        label:'Joined'
      },
      {
        id:2,
        rating:'NOT_JOINING',
        label:'Not Joining'
      },
      {
        id:3,
        rating:'POSTPONED',
        label:'Postponed'
      },
      {
        id:4,
        rating:'NO_RESPONSE',
        label:'No Response'
      }
    ]
    this.city=[
      {
        id:1,
        rating:71,
        label:'Delhi'
      },
      {
        id:2,
        rating:72,
        label:'Noida'
      },
      {
        id:3,
        rating:73,
        label:'Gurugram'
      },
    ]
    this.gender=[
      {
        id:1,
        rating:26,
        label:'Male'
      },
      {
        id:2,
        rating:27,
        label:'Female'
      }
    ]
    
    this.appliedBy=[
      {
        id:1,
        rating:'1,2',
        label:'Admin'
      },
      {
        id:2,
        rating:'4,6',
        label:'Self'
      }
    ]

    this.qualification=[
      {
        id:1,
        rating:1,
        label:'Below 10th'
      },
      {
        id:2,
        rating:2,
        label:'10th std'
      },
      {id:3,
        rating:3,
        label:'12th std'
      },
      {id:4,
        rating:4,
        label:'Diloma'
      },
      {id:5,
        rating:5,
        label:'In College'
      },
      {id:6,
        rating:6,
        label:'Graduate'
      },
      {id:7,
        rating:7,
        label:'Post Graduate'
      }
    ]

    this.resetFilters = this.resetFilters.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }
  handleRequestClose(event) {
    this.setState({
      open: !this.state.open,
      anchorEl: event.currentTarget
    });
  }
  resetFilters() {
    this.setState(
      {
        averageRating:'',
        actualScore:'',
        cutoffScore:'',
        expInYears:'',
        totalGigs:'',
        worktype: 1,
        name:'',
        number:'',
        statusJoining:'',
        dates:'',
        statusInterview:'',
        salary:'',
        city:'',
        gender:'',
        appliedBy:'',
        qualification:''
      },
      ()=>this.props.updateFilters(this.state.averageRating,
        this.state.actualScore,
        this.state.cutoffScore,
        this.state.expInYears,
        this.state.totalGigs,
        this.state.worktype,
        this.state.name,
        this.state.number,
        this.state.dates,
        this.state.statusInterview,
        this.state.statusJoining, 
        this.state.salary,
        this.state.city,
        this.state.gender,
        this.state.appliedBy,
        this.state.qualification)
    );
  }

  handleExperienceChange1 = val => {
    this.setState({ cutoffScore: +val });
  };

  handleExperienceChange2 = val => {
    this.setState({ expInYears: +val });
  };

  handleExperienceChange3 = val => {
    this.setState({ averageRating: +val });
  };

  handleExperienceChange4 = val => {
    this.setState({ actualScore: +val });
  };

  handleExperienceChange5 = val => {
    this.setState({ totalGigs: +val });
  };

  handleExperienceChange6 = val => {
    this.setState({ name: val });
  };

  handleExperienceChange7 = val => {
    this.setState({ number: val });
  };

  recieveDates = (startDate, endDate) => {
    // console.log(startDate);
    // console.log(endDate);
    const dates = {
      start_date: startDate,
      end_date: endDate
    };
    this.setState({ dates: dates });
  };

  handleSelectChange = val => {
    this.setState({ statusJoining: val });
  };

  handleSelectChange1 = val => {
    this.setState({ statusInterview: val });
  };
  handleSelectChange2 = val => {
    this.setState({ city: val });
  };
  handleSelectChange3 = val => {
    this.setState({ appliedBy: val });
  };
  handleSelectChange4 = val => {
    this.setState({ gender: val });
  };
  handleSelectChange5 = val => {
    this.setState({ qualification: val });
  };

  componentDidMount() {
    this.setState({
        averageRating:'',
        actualScore:'',
        cutoffScore:'',
        expInYears:'',
        totalGigs:'',
        worktype: 0 ,
        name:'',
        number:'',
        statusJoining:'',
        dates:'',
        statusInterview:'',
        salary:'',
        city:'',
        gender:'',
        appliedBy:'',
        qualification:''
    });
  }

  render() {
    return (
      <PopupState variant='popover' popupId={'popover' + this.props.id}>
        {popupState => (
          <div>
            <Button 
              style={{ width: '90px', marginLeft: '0px', marginTop: '10px', fontSize: '0.8rem' }}
              variant='contained'
              color='primary'
              {...bindTrigger(popupState)}
              className={this.props.classes.buttonTextSize}
              onClick={this.handleRequestClose}
            >
              Filter
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                id: 2,
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.open}
              anchorEl={this.state.anchorEl}
            >
              <Grid
                container
                spacing={3}
                className={this.props.classes.gridStyle}
              >

                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Cut-off Score'
                    type='number'
                    value={this.state.cutoffScore}
                    onChange={event =>
                      this.handleExperienceChange1(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Experience'
                    type='number'
                    value={this.state.expInYears}
                    onChange={event =>
                      this.handleExperienceChange2(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Average Rating'
                    type='number'
                    value={this.state.averageRating}
                    onChange={event =>
                      this.handleExperienceChange3(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Actual Score'
                    type='number'
                    value={this.state.actualScore}
                    onChange={event =>
                      this.handleExperienceChange4(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Total Gigs'
                    type='number'
                    value={this.state.totalGigs}
                    onChange={event =>
                      this.handleExperienceChange5(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-helperText"
                    label='Name'
                    type='string'
                    value={this.state.name}
                    onChange={event =>
                      this.handleExperienceChange6(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Number'
                    type='number'
                    value={this.state.number}
                    onChange={event =>
                      this.handleExperienceChange7(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Select
                    option={this.option1}
                    handleChange={this.handleSelectChange}
                    ratingValue={this.state.statusJoining}
                    label={'Joining Status'}
                  >
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    option={this.option}
                    handleChange={this.handleSelectChange1}
                    ratingValue={this.state.statusInterview}
                    label={'Interview Status'}
                  >
                  </Select>
                </Grid>
                {/* <Grid item xs={6}>
                  <Select
                    option={this.option}
                    handleChange={this.handleSelectChange1}
                    ratingValue={this.state.statusInterview}
                    label={'Interview Status'}
                  >
                  </Select>
                </Grid> */}
                <Grid item xs={6}>
                  <Select
                    option={this.city}
                    handleChange={this.handleSelectChange2}
                    ratingValue={this.state.city}
                    label={'City'}
                  >
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    option={this.appliedBy}
                    handleChange={this.handleSelectChange3}
                    ratingValue={this.state.appliedBy}
                    label={'appliedBy'}
                  >
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    option={this.gender}
                    handleChange={this.handleSelectChange4}
                    ratingValue={this.state.gender}
                    label={'Gender'}
                  >
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    option={this.qualification}
                    handleChange={this.handleSelectChange5}
                    ratingValue={this.state.qualification}
                    label={'Qualification'}
                  >
                  </Select>
                </Grid>

                <Grid item xs={4} style={{ marginLeft: '1px' }}>
                  {' '}
                  <DatePicker
                    sendData={this.recieveDates}
                    start={
                      this.state.postedDates
                        ? this.state.postedDates.posted_from
                          ? new Date(
                              this.state.postedDates.posted_from.substring(
                                0,
                                10
                              )
                            )
                          : null
                        : null
                    }
                    end={
                      this.state.postedDates
                        ? this.state.postedDates.posted_to
                          ? new Date(
                              this.state.postedDates.posted_to.substring(0, 10)
                            )
                          : null
                        : null
                    }
                    label={'Interview Date'}
                    label1={'Joining Date'}
                  ></DatePicker>{' '}
                </Grid>
                    
              </Grid>

              <Divider />
              <Grid container className={this.props.classes.gridStyle}>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.resetFilters(true);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.setState({ worktype:0 },()=>this.props.updateFilters(
                        this.state.averageRating,
                        this.state.actualScore,
                        this.state.cutoffScore,
                        this.state.expInYears,
                        this.state.totalGigs,
                        this.state.worktype,
                        this.state.name,
                        this.state.number,
                        this.state.dates,
                        this.state.statusInterview,
                        this.state.statusJoining, 
                        this.state.salary,
                        this.state.city,
                        this.state.gender,
                        this.state.appliedBy,
                        this.state.qualification
                      ))
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Apply
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>
        )}
      </PopupState>
    );
  }
}

export default withStyles(styles)(CustomFilter);
