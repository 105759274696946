import React,{ useState, useEffect } from "react"
import { Grid, Button, Drawer } from '@material-ui/core'
import note from '../../../assets/note.svg'
import share from '../../../assets/share.svg'
import history from '../../../assets/history.svg'
import bell from '../../../assets/bell.svg'
import '../../worker/externalWorker/externalWorker.css'
import CallReminderModal from '../../../utils/Modals/CallModal'
import ShareApp from '../../../utils/Modals/ShareApp'
import UrlLinks from "../../../urlLinks"
import Notes from './Notes'
import ApplicationHistory from "./ApplicationHistory"
const iconImg = {
    width: "18px",
    height: "18px",
    marginRight: "15px"
  },
  space12 ={
      marginBottom: "12px"
  },
  colorBlue = {
      color: "#1c75bc",
      minWidth: "30px"
  };

const Action =(props) => {
    // console.log({props});
    const {row, showToast, quesList, getAllReminders} = props
    const [callReminderModal, setCallReminderModal] = useState(false)
    const [shareModal, setShareModal] = useState(false)
    const [drawers, setDrawers] = useState({
        notes: false, 
        history: false
    })
    useEffect(() => {
        console.log("started Action")
    }, [])
    const toggleDrawer = (anchor, open) => (event) => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }
        setDrawers({ ...drawers, [anchor]: open });
      };
    const handleNotes =(row) =>{
        
    }
    return(
        <Grid container style={{ width: '200px'}}>
            <Grid item  xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12} >
                <img src={note} alt="note" style={iconImg} />
                <Button 
                    className="padding0 textCapitalize" 
                    component="button" 
                    style={colorBlue}
                    onClick={toggleDrawer("notes", true)}
                >
                    Notes
                </Button>
            </Grid>
            {(!row.original.interviewFollowUp || (row.original.interviewFollowUp && row.original.interviewFollowUp!=="APPEARED")) && <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12}>
                <img src={share} alt="sendJobLink" style={iconImg} />
                <Button 
                    className="padding0 textCapitalize" 
                    component="button" 
                    style={colorBlue}
                    onClick={() => setShareModal(true)}
                >
                    {row.original.interviewId?"Send interview reminder":"Send job link"}
                </Button>
            </Grid>}
            <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12}>
                <img src={history} alt="AppHistory" style={iconImg} />
                <Button 
                    className="padding0 textCapitalize" 
                    component="button" 
                    style={colorBlue}
                    onClick={toggleDrawer("history", true)}
                >
                    Application history
                </Button>
            </Grid>
            <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12}>
                <img src={bell} alt="Reminder" style={iconImg} />
                <Button 
                    className="padding0 textCapitalize" 
                    component="button" 
                    style={colorBlue}
                    onClick={() => setCallReminderModal(true)}
                >
                    Set call reminder
                </Button>
            </Grid>
            <Drawer anchor="right" open={drawers["history"]} onClose={toggleDrawer("history", false)}>
                <ApplicationHistory row={row} quesList={quesList}/>
            </Drawer>
            <Drawer anchor="right" open={drawers["notes"]} onClose={toggleDrawer("notes", false)}>
                <Notes row={row} showToast={showToast}  getAllReminders={getAllReminders} />            
            </Drawer>
            {callReminderModal && <CallReminderModal 
                callReminderModal={callReminderModal}
                setCallReminderModal= {setCallReminderModal}
                row={row}
                getAllReminders={getAllReminders}
                showToast={showToast}
                />
            }
            {shareModal && <ShareApp 
                shareModal={shareModal}
                setShareModal= {setShareModal}
                userData={row.original}
                showToast={showToast}
                />
            }
        </Grid>
    );
}

export default Action