// const BASE_URL = process.env.REACT_APP_BASE_URL;
const urlLinks = {
  corsUrl: 'https://cors-anywhere.herokuapp.com/',
  baseUrl: 'https://api.okaygo.in/',
  // baseUrl: 'http://192.168.43.65:5000/',
  // baseUrl: 'https://qaapi.okaygo.in/',
  googleGeocodeUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
  // baseUrl: 'http://localhost:5000/',
  employerUrls: {
    getEmployerList: '/admin/employersdb',
    postAddEditEmployer: '/admin/addupdateemployer',
    getEmployerDetailById: '/admin/getemployerbyid?id=',
    getEmployerViewByID: '/admin/getemployerviewbyid?id='
  },
  workerUrls: {
    getWorkerList: '/admin/workersdb',
    getWorkerViewById: '/workermaster/workerByUserId?user_id=',
    postAddEditWorker: '/admin/addWorker'
  },
  jobUrls: {
    postAddEditJob: '/job/'
  }
};
 
export default urlLinks;
