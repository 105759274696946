import React, { Component } from 'react';
import { AppBar, Paper, Tabs, Tab, Typography, Box } from '@material-ui/core';
import CustomTable from '../Table/ExpandableTable';
import childTableConstant from './ChildTableConstant';
import Constant from '../../constant';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Button } from '@material-ui/core';
import { RingLoader } from 'react-spinners';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { lightGreen } from '@material-ui/core/colors';
import urlLinks from '../../urlLinks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomeFilterMatching from '../CustomFilter/CustomeFilterMatching';
import CustomFilterMatched from '../CustomFilter/CustomFilterApplied';
import DefaultCustomFilterMatched from '../CustomFilter/CustomFilterMached';


function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: lightGreen[200],
    '&$checked': {
      color: lightGreen['A700']
    },
    '&$checked + $track': {
      backgroundColor: lightGreen['A700']
    }
  },
  checked: {},
  track: {}
})(Switch);

class ChildTableComponent extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      averageRating2:'', 
      actualScore2:'', 
      cutoffScore2:'', 
      expInYears2:'',
      totalGigs2:'', 
      rset2:'', 
      name2:'', 
      number2:'', 
      gender2:'', 
      salary:'',
      city:'',
      gender:'',
      appliedBy:'',
      statusInterview:'',
      statusJoining:'',
      dates:null,
      rset:0,
      averageRating:'',
      actualScore:'',
      cutoffScore:'',
      expInYears:'',
      totalGigs:'',
      rset1:0,
      averageRating1:'',
      actualScore1:'',
      cutoffScore1:'',
      expInYears1:'',
      totalGigs1:'',
      name:'',
      number:'',
      userId: null,
      jobData: {},
      cut_off_availability: true,
      cut_off_breach: true,
      cut_off_distance: true,
      employeeRequired: 0,
      employeeHired: 0,
      cut_off_exp: true,
      detailJobData: {},
      cut_off_gender: true,
      cut_off_qual: true,
      cut_off_negative_rating: false,

      tabs: {
        value: 0
      },
      isPaidArr: [],
      matchedData: [],
      matchNewData: [],
      hiredData: [],
      appliedData: [],
      isDefaultSelected: true,
      table1: {
        currentPage: 0,
        count: 0,
        rowsPerPage: 100,
        isTableUpdating: false
      },
      table2: {
        currentPage: 0,
        count: 0,
        rowsPerPage: 100,
        isTableUpdating: false
      },
      rowsOptions: [5, 10, 25, 50, 100, 200, 500],

      isLoading1: true,
      isTableUpdating1: false,
      currentPage1: 0,
      count1: 0,
      rowsPerPage1: 100,

      isLoading3: true,
      isTableUpdating3: false,
      currentPage3: 0,
      count3: 0,
      rowsPerPage3: 100,

      isLoading2: true,
      isTableUpdating2: false,
      currentPage2: 0,
      count2: 0,
      rowsPerPage2: 100,

      isLoading4: true,
      isTableUpdating4: false,
      currentPage4: 0,
      count4: 0,
      rowsPerPage4: 100
    };
    this.dataCheck=[];
    this.url=`${urlLinks.baseUrl}/job/assigner/matched?job_details_id=${this.props.id}&match_type=1`;
    this.url2=`${urlLinks.baseUrl}/job/assigner/matched?job_details_id=${this.props.id}&match_type=2`;
    this.urlpagination=`&page_no=${this.state.currentPage3}&rows=${this.state.rowsPerPage3}`;

    

    // this.A_url = `${urlLinks.baseUrl}/job/assigner/getappliedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${this.state.rowsPerPage1}&rows=${this.state.currentPage1}`
    // this.A_url2 = `${urlLinks.baseUrl}/job/assigner/getAcceptedInterviewWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${this.state.rowsPerPage1}&rows=${this.state.currentPage1}`
    // this.A_url3 = `${urlLinks.baseUrl}/job/assigner/getJobOfferedWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${this.state.rowsPerPage1}&rows=${this.state.currentPage1}`

    this.B_url = `${urlLinks.baseUrl}/job/assigner/getappliedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}`
    this.B_url2 = `${urlLinks.baseUrl}/job/assigner/getAcceptedInterviewWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}`
    this.B_url3 = `${urlLinks.baseUrl}/job/assigner/getJobOfferedWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}`
    
    this.A_url = `${urlLinks.baseUrl}/job/assigner/getODappliedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}`;
    this.A_url2 = `${urlLinks.baseUrl}/job/assigner/getODUnpaidHiredworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}`;
    this.A_url3 = '';

    this.matched_url=`${urlLinks.baseUrl}/job/assigner/assigned?job_details_id=${this.props.id}`
    this.matched_url_pagination=`&page_no=${this.state.currentPage2}&rows=${this.state.rowsPerPage2}`;
    this.matchedfilterUrl=''
    this.AppliedFilterUrl=''
    this.MatchNewFilterUrl=''
    this.handelChangeValue = this.handelChangeValue.bind(this);
    this.rowsOptions = [5, 10, 50, 100, 250, 500];
    this.updateDataOnTableOnePagination = this.updateDataOnTableOnePagination.bind(
      this
    );
    this.updateDataOnTableTwoPagination = this.updateDataOnTableTwoPagination.bind(
      this
    );
    this.updateDataOnTableThreePagination = this.updateDataOnTableThreePagination.bind(
      this
    );
    this.updateDataOnTableFourPagination = this.updateDataOnTableFourPagination.bind(
      this
    );
    this.updateFilters = this.updateFilters.bind(this);
    this.updateFiltersApplied=this.updateFiltersApplied.bind(this);
    this.updateFiltersMatched=this.updateFiltersMatched.bind(this);
  }
  resetMatchedFilter= () =>{
    this.matchedfilterUrl='';
    this.getMatchedWorkerList();
  }
  resetAppliedFilter= () =>{
    this.AppliedFilterUrl='';
    this.getApppliedWorkerList();
  }
  resetMatchingFilter= () =>{
    this.matchedfilterUrl='';
    this.getMatchedWorkerList();
  }
  updateDataOnTableOnePagination(
    rowsPerPage = this.state.rowsPerPage1,
    nextPage = this.state.currentPage1
  ) {
    // console.log({rowsPerPage, nextPage});
    
    this.setState({ isTableUpdating1: true }, () => {
      this.getApppliedWorkerList(rowsPerPage, nextPage);
    });
  }
  updateDataOnTableTwoPagination(
    rowsPerPage = this.state.rowsPerPage2,
    nextPage = this.state.currentPage2
  ) {
    console.log({rowsPerPage, nextPage});
    
    this.setState({ isTableUpdating2: true }, () => {
      this.getMatchedWorkerList(rowsPerPage, nextPage);
    });
  }

  updateDataOnTableThreePagination(
    rowsPerPage = this.state.rowsPerPage3,
    nextPage = this.state.currentPage3
  ) {
    if (this.state.isDefaultSelected) {
      this.setState({ isTableUpdating3: true }, () => {
        this.getDefaultMatchData(rowsPerPage, nextPage);
      });
    } else {
      this.setState({ isTableUpdating3: true }, () => {
        this.getMatchNewWorkerList(rowsPerPage, nextPage);
      });
    }
  }
  updateDataOnTableFourPagination(
    rowsPerPage = this.state.rowsPerPage4,
    nextPage = this.state.currentPage4
  ) {
    this.setState({ isTableUpdating4: true }, () => {
      this.getHiredWorkerList(rowsPerPage, nextPage);
    });
  }

  handelChangeValue(event, newValue) {
    this.setState({
      tabs: { value: newValue }
    });
  }

  getHiredWorkerList = (
    rowsPerPage = this.state.rowsPerPage4,
    nextPage = this.state.currentPage4
  ) => {
    this.setState({isTableUpdating4: true})
    let hiredData = null;
    let paginationUrl=`&page_no=${nextPage}&rows=${rowsPerPage}`
    let url = `${urlLinks.baseUrl}/job/assigner/getODHiredworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
    let url2 = `${urlLinks.baseUrl}/job/assigner/getODCompletedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`
    if (this.props.name === 'ptftJobs') {
      url = `${urlLinks.baseUrl}/job/assigner/getJoinedWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
      url2 = '';
    }
    // console.log(`Yes`)
    // console.log(url);
    setTimeout(() => {
      Axios.get(url)
        .then(async response => {
          // toast.error('Hired Workers List Not Loaded Successfully');
          let data = response.data.response.content
          console.log(response.data.response.content)
          if(url2) {
            await Axios.get(url2)
            .then(response2 => {
              response2.data.response.content.map(el => data.push(el))
              console.log(response2.data.response.content)
            })
          }
          hiredData = this.makeData4(
            data,
            this.props.name
          );
          const isPaidArr = [];
          response.data.response.content.map(el => {
            if (el.isPaid === 0) {
              isPaidArr.push(el.assign_id);
            }
          });
          this.setState({
            userId: response.data.response.content[0] ? response.data.response.content[0].insertedBy : 0,
            hiredData: hiredData,
            isPaidArr: isPaidArr,
            count4: response.data.response.totalElements,
            currentPage4: nextPage,
            rowsPerPage4: rowsPerPage,
            isLoading4: false,
            isTableUpdating4: false
          });
        })
        .catch(error => {
          this.setState({
            isLoading4: false,
            isTableUpdating4: false
          });
          toast.error('Hired Workers List Not Loaded Successfully');
        });
    }, 1000);
  };

  getApppliedWorkerList = (
    rowsPerPage = this.state.rowsPerPage1,
    nextPage = this.state.currentPage1
  ) => {
    this.setState({isTableUpdating1: true})
    let appliedData = null;
    console.log({rowsPerPage, nextPage});
    // let urlA1=`${this.A_url}`
    // let urlA2=`${this.A_url2}`
    // let urlA3=`${this.A_url3}`
    // let pagination=`&page_no=${nextPage}&rows=${rowsPerPage}`
    // if(this.AppliedFilterUrl!==''){
    //   urlA1=`${urlA1}${this.AppliedFilterUrl}${pagination}`
    // }
    // this.A_url3 = '';
    // this.A_url = `${urlLinks.baseUrl}/job/assigner/getODappliedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${this.state.currentPage1}&rows=${this.state.rowsPerPage1}`;
    // this.A_url2 = `${urlLinks.baseUrl}/job/assigner/getODUnpaidHiredworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${this.state.currentPage1}&rows=${this.state.rowsPerPage1}`;
    // let url3 = `${urlLinks.baseUrl}/job/assigner/getODHiredworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
    // let url4 = `${urlLinks.baseUrl}/job/assigner/getODCompletedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`
    if (this.props.name === 'ptftJobs') {
      this.A_url = `${urlLinks.baseUrl}/job/assigner/getappliedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`
      this.A_url2 = `${urlLinks.baseUrl}/job/assigner/getAcceptedInterviewWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`
      this.A_url3 = `${urlLinks.baseUrl}/job/assigner/getJobOfferedWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`
        // url4 = `${urlLinks.baseUrl}/job/assigner/getJoinedWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
        this.A_url=this.B_url;
        this.A_url2=this.B_url2;
        this.A_url3=this.B_url3;
    }
    // console.log(url);
    Axios.get(`${this.A_url}${this.AppliedFilterUrl}`)
      .then(async response => {
      
        console.log("1st ",{response})
        console.log(response.data.response.content)
        // this.A_url = `${urlLinks.baseUrl}/job/assigner/getappliedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
        // this.B_url = `${urlLinks.baseUrl}/job/assigner/getappliedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
        let data = response.data.response.content
        await Axios.get(`${this.A_url2}${this.AppliedFilterUrl}&page_no=${nextPage}&rows=${rowsPerPage}`)
        .then(async response2 => {
          console.log("2nd ",{response2})
          console.log(response2.data.response.content)
          // this.A_url2 = `${urlLinks.baseUrl}/job/assigner/getAcceptedInterviewWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
          // this.B_url2 = `${urlLinks.baseUrl}/job/assigner/getAcceptedInterviewWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
          response2.data.response.content.map(el => data.push(el))
          // await Axios.get(url3)
          // .then(async response3 => {
          //   response3.data.response.content.map(el => data.push(el))
          if(this.A_url3) {
            await Axios.get(`${this.A_url3}${this.AppliedFilterUrl}&page_no=${nextPage}&rows=${rowsPerPage}`)
            .then(response4 => {
              console.log("3rd ",{response4})
              console.log(response4.data.response.content);
              // this.A_url3 = `${urlLinks.baseUrl}/job/assigner/getJobOfferedWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
              // this.B_url3 = `${urlLinks.baseUrl}/job/assigner/getJobOfferedWorkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${nextPage}&rows=${rowsPerPage}`;
              response4.data.response.content.map(el => data.push(el))
            })
          }
          // })
        })
        // console.log(data)
        appliedData = this.makeData1(
          data,
          this.props.name
        );
        const isPaidArr = [];
        response.data.response.content.map(el => {
          if (el.isPaid === 0) {
            isPaidArr.push(el.assign_id);
          }
        });
        this.setState({
          userId: response.data.response.content[0] ? response.data.response.content[0].insertedBy : 0,
          appliedData: appliedData,
          isPaidArr: isPaidArr,
          count1: response.data.response.totalElements,
          currentPage1: nextPage,
          rowsPerPage1: rowsPerPage,
          isLoading1: false,
          isTableUpdating1: false
        });
      })
      .catch(error => {
        this.setState({
          isLoading1: false,
          isTableUpdating1: false
        });
        toast.error('Applied Workers List Not Loaded Successfully');
      });
  };
  
  calltype2 =(nextPage, rowsPerPage, matchNewData) =>{
    console.log(this.url2);
    this.url2=`${urlLinks.baseUrl}/job/assigner/matched?job_details_id=${this.props.id}&match_type=2&page_no=${nextPage}&rows=${rowsPerPage}`;
    Axios.get(
      `${urlLinks.baseUrl}/job/assigner/matched?job_details_id=${this.props.id}&match_type=2&page_no=${nextPage}&rows=${rowsPerPage}${this.MatchNewFilterUrl}`
      ) .then(response => {
          matchNewData = response.data.response.content;
          console.log(response);
          this.setState({
            matchNewData: this.makeData3(matchNewData),
            count3: response.data.response.totalElements,
            currentPage3: nextPage,
            rowsPerPage3: rowsPerPage,
            isLoading3: false,
            isTableUpdating3: false
          });
        })
        .catch(error => {
          this.setState({
            isLoading3: false,
            isTableUpdating3: false
          });
          toast.error('Match New Workers List Not Loaded Successfully');
        });
  }
  getDefaultMatchData = (
    rowsPerPage = this.state.rowsPerPage2,
    nextPage = this.state.currentPage2
  ) => {
    
    this.setState({isTableUpdating3: true})
    let matchNewData = null;
    console.log(this.url);
    // this.url=`${urlLinks.baseUrl}/job/assigner/matched?job_details_id=${this.props.id}&match_type=1&page_no=${nextPage}&rows=${rowsPerPage}`;
    Axios.get(`${urlLinks.baseUrl}/job/assigner/matched?job_details_id=${this.props.id}&match_type=1&page_no=${nextPage}&rows=${rowsPerPage}${this.MatchNewFilterUrl}`)
    
          .then(response => {
        console.log("rows and page", this.state.rowsPerPage3, this.state.currentPage3)

        matchNewData = this.makeData3(response.data.response.content);
        this.dataCheck=matchNewData;
        matchNewData.length>0?
          this.setState({
            matchNewData: matchNewData,
            count3: response.data.response.totalElements,
            currentPage3: nextPage,
            rowsPerPage3: rowsPerPage,
            isLoading3: false,
            isTableUpdating3: false
            
          }): this.calltype2(nextPage, rowsPerPage, matchNewData);
      })
      .catch(error => {
        this.setState({
          isLoading3: false,
          isTableUpdating3: false
        });
        toast.error(
          'Automatically Matched Workers List Not Loaded Successfully'
        );
      });
  };

  getMatchNewWorkerList = (
    rowsPerPage = this.state.rowsPerPage3,
    nextPage = this.state.currentPage3
  ) => {
    this.setState({isTableUpdating3: true})
    let matchNewData = null;
    const url = `${
      urlLinks.baseUrl
    }/job/assigner/run/job?cut_off_availability=${
      this.state.cut_off_availability ? 1 : 0
    }&cut_off_breach=${this.state.cut_off_breach ? 1 : 0}&cut_off_distance=${
      this.state.cut_off_distance ? 1 : 0
    }&cut_off_exp=${this.state.cut_off_exp ? 1 : 0}&cut_off_gender=${
      this.state.cut_off_gender ? 1 : 0
    }&cut_off_qual=${this.state.cut_off_qual ? 1 : 0}&cut_off_negative_rating=${
      this.state.cut_off_negative_rating ? 1 : 0
    }&job_detail_id=${this.props.id}`;
    fetch(url).then(response => {
      this.calltype2(nextPage, rowsPerPage, matchNewData);
    });
  };

  matchCuttOff = cuttOff => {
    const cuttOffNotations = {
      '-1': 'Disabled',
      '0': 'Failed',
      '1': 'Passed'
    };
    return cuttOffNotations[cuttOff];
  };

  makeData4 = (data, name) => {
    const extractedData = [];
    if (name !== 'ptftJobs') {
      data.map(el => {
        extractedData.push({
          assign_id: el.assign_id !== null ? el.assign_id : '-',
          user_id: el.user_id !== null ? el.user_id : '-',
          about_me: el.about_me !== null ? el.about_me : '-',
          first_name: el.first_name !== null ? `${el.first_name} ${el.last_name ? el.last_name : ''} (${el.phone_number})` : '-',
          last_name: el.last_name !== null ? el.last_name : '-',
          phone_number: el.phone_number !== null ? el.phone_number : '-',
          job_id: el.job_id !== null ? el.job_id : '-',
          job_detail_id: el.job_detail_id !== null ? el.job_detail_id : '-',
          actual_score: el.actual_score !== null ? el.actual_score : '-',
          cutoff_score: el.cutoff_score !== null ? el.cutoff_score : '-',
          is_accepted:
            el.is_accepted !== null ? (el.is_accepted ? 'Yes' : 'No') : '-',
          is_rejected:
            el.is_rejected !== null ? (el.is_rejected ? 'Yes' : 'No') : '-',
          is_paid: el.is_paid !== null ? (el.is_paid ? 'Yes' : 'No') : '-',
          accepted_by: el.accepted_by !== null ? el.accepted_by : '-',
          rejected_by: el.rejected_by !== null ? el.rejected_by : '-',
          is_confirmed:
            el.is_confirmed !== null ? (el.is_confirmed ? 'Yes' : 'No') : '-',
          confirmed_by: el.confirmed_by !== null ? el.confirmed_by : '-',
          inserted_by: el.inserted_by !== null ? el.inserted_by : '-',
          inserted_on: el.inserted_on !== null ? el.inserted_on : '-',
          updated_by: el.updated_by !== null ? el.updated_by : '-',
          updated_on: el.updated_on !== null ? el.updated_on : '-',
          // markWorkerLeft: 'Mark Left',
          worker_id: el.worker_id !== null ? el.worker_id : '-',
          qualification_id:
            el.qualification_id !== null ? el.qualification_id : '-',
          is_kyc: el.is_kyc !== null ? (el.is_kyc ? 'Yes' : 'No') : '-',
          spoken_language:
            el.spoken_language !== null ? el.spoken_language : '-',
          total_gigs: el.total_gigs !== null ? el.total_gigs : '-',
          total_reviews: el.total_reviews !== null ? el.total_reviews : '-',
          startDate: !el.is_paid ? 'Payment Pending' : el.is_no_show ? 'Not Showed' : el.started_on ? el.started_on : 'Update CheckIn',
          endDate: !el.is_paid ? 'Payment Pending' : el.is_no_show ? 'Not Showed' : !el.ended_on && el.started_on ? 'Update CheckOut' : el.ended_on,
          updateStatus: el.is_no_show ? 'Not Showed' : el.is_paid && !el.started_on && !el.ended_on ? 'Update Status' : el.is_paid && !el.ended_on ? 'Job Started' : el.is_paid && el.ended_on ? 'Job Completed' : 'Payment Pending',
          average_rating: el.average_rating !== null ? el.average_rating : '-',
          is_leader:
            el.is_leader !== null ? (el.is_leader ? 'Yes' : 'No') : '-',
          paytm_number: el.paytm_number !== null ? el.paytm_number : '-',
          breach: el.breach !== null ? el.breach : '-',
          has_experience:
            el.has_experience !== null
              ? el.has_experience
                ? 'Yes'
                : 'No'
              : '-',
          available_pt: el.available_pt !== null ? el.available_pt : '-',
          available_ft: el.available_ft !== null ? el.available_ft : '-',
          available_od: el.available_od !== null ? el.available_od : '-',
          super_toggle: el.super_toggle !== null ? el.super_toggle ? 'Yes' : 'No' : '-',
          global_availability:
            el.global_availability !== null ? el.global_availability ? 'Yes' : 'No' : '-',
          check_in_id: el.check_in_id !== null ? el.check_in_id : '-',
          job_details_id: el.job_details_id !== null ? el.job_details_id : '-',
          started_on: el.started_on !== null ? el.started_on : '-',
          ended_on: el.ended_on !== null ? el.ended_on : '-',
          is_extended:
            el.is_extended !== null ? (el.is_extended ? 'Yes' : 'No') : '-',
          status: el.status !== null ? el.status : '-',
          otp: el.otp !== null ? el.otp : '-',
          is_verified_by_otp:
            el.is_verified_by_otp !== null
              ? el.is_verified_by_otp
                ? 'Yes'
                : 'No'
              : '-',
          is_started:
            el.is_started !== null ? (el.is_started ? 'Yes' : 'No') : '-',
          is_no_show:
            el.is_no_show !== null ? (el.is_no_show ? 'Yes' : 'No') : '-',
          is_ended: el.is_ended !== null ? (el.is_ended ? 'Yes' : 'No') : '-'
        });
      });
    } else {
      data.map(el => {
        extractedData.push({
          assign_id: el.assign_id === null ? '-' : el.assign_id,
          user_id: el.user_id === null ? '-' : el.user_id,
          first_name: el.first_name === null ? '-' : `${el.first_name} ${el.last_name ? el.last_name : ''} (${el.phone_number})`,
          last_name: el.last_name === null ? '-' : el.last_name,
          worker_id: el.worker_id === null ? '-' : el.worker_id,
          average_rating: el.average_rating === null ? '-' : el.average_rating,
          total_reviews: el.total_reviews === null ? '-' : el.total_reviews,
          phone_number: el.phone_number === null ? '-' : el.phone_number,
          interviewId: el.interviewId === null ? '-' : el.interviewId,
          insertedBy: el.insertedBy === null ? '-' : el.insertedBy,
          insertedOn: el.insertedOn === null ? '-' : el.insertedOn,
          interviewEndTime:
            el.interviewEndTime === null ?'-' : el.interviewEndTime,
          interviewStartTime:
            el.interviewStartTime === null ?'-' : el.interviewStartTime,
          interviewStatus:
            el.interviewStatus === null ? '-' : el.interviewStatus ? 'Yes' : 'No',
          isWorkerdHired:
            el.isWorkerdHired === null ? '-' : el.isWorkerdHired ? 'Yes' : 'No',
          joiningStatus: el.is_joined,
          jobDetailsId: el.jobDetailsId === null ? '-' : el.jobDetailsId,
          otp: el.otp === null ? '-' : el.otp,
          otpVerified:
            el.otpVerified === null ? '-' : el.otpVerified ? 'Yes' : 'No',
          markWorkerLeft: 'Mark Left',
          updatedBy: el.updatedBy === null ? '-' : el.updatedBy,
          updatedOn: el.updatedOn === null ? '-' : el.updatedOn,
          interviewEndTime2:
            el.interviewEndTime2 === null ? '-' : el.interviewEndTime2,
          interviewStartTime2:
            el.interviewStartTime2 === null ? '-' : el.interviewStartTime2,
          interviewEndTime3:
            el.interviewEndTime3 === null ? '-' : el.interviewEndTime3,
          interviewStartTime3:
            el.interviewStartTime3 === null ? '-' : el.interviewStartTime3,
            interviewConducted: el.otpVerified ? el.whichSlot === 1 ?
            `${el.interviewStartTime.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime.substring(11, 16)}` :
            el.whichSlot === 2 ?
            `${el.interviewStartTime2.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime2.substring(11, 16)}` :
            el.whichSlot === 3 ?
            `${el.interviewStartTime3.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime3.substring(11, 16)}` : '' : '-',
            interviewCleared: el.otpVerified ? el.whichSlot === 1 ?
            `${el.interviewStartTime.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewEndTime.substring(11, 16)}` :
            el.whichSlot === 2 ?
            `${el.interviewStartTime2.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewEndTime2.substring(11, 16)}` :
            el.whichSlot === 3 ?
            `${el.interviewStartTime3.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewEndTime3.substring(11, 16)}` : '' : '-',
          acceptedBy: el.acceptedBy === null ? '-' : el.acceptedBy,
          whichSlot: el.whichSlot === null ? '-' : el.whichSlot,
          payment :el.isPaid ? 'Done' : 'Pending',
          accepted_on: el.accepted_on === null ? '-' : el.accepted_on,
          address: el.address === null ? '-' : el.address,
          postalCode: el.postalCode === null ? '-' : el.postalCode,
          jobLocationLat: el.jobLocationLat === null ? '-' : el.jobLocationLat,
          jobLocationLong:
            el.jobLocationLong === null ? '-' : el.jobLocationLong,
          landMark: el.landMark === null ? '-' : el.landMark,
          is_job_offered:
            el.is_job_offered === null ? '-' : el.is_job_offered ? 'Yes' : 'No',
          is_worker_hired:
            el.is_joined === null
              ? '-'
              : el.is_joined === 1
              ? 'Yes'
              : 'No',
          job_offered_amount:
            el.job_offered_amount === null ? '-' : el.job_offered_amount,
          updateHiredSalary: el.transactionUniqueId === null ? 'Update Salary' : 'Invoice Generated',
          is_offer_accepted:
            el.is_offer_accepted === null
              ? '-'
              : el.is_offer_accepted
              ? 'Yes'
              : 'No',
          joining_date: el.joining_date === null ? '-' : el.joining_date.toString().substring(0, 10).split("-").reverse().join("-"),
          offer_accepted_by:
            el.offer_accepted_by === null ? '-' : el.offer_accepted_by,
          offer_accepted_date:
            el.offer_accepted_date === null ? '-' : el.offer_accepted_date,
          is_rejected:
            el.is_rejected === null ? '-' : el.is_rejected ? 'Yes' : 'No',
          is_joined: el.is_joined === null ? '-' : el.is_joined ? 'Yes' : 'No',
          invoiceAmount: el.transactionUniqueId === null ? el.invoiceAmount : 'Invoice Generated',
          isPaid: el.isPaid === null ? '-' : el.isPaid ? 'Yes' : 'No',
          transactionUniqueId:
            el.transactionUniqueId === null ? '-' : el.transactionUniqueId
        });
      });
    }
    return extractedData;
  };

  makeData1 = (data, name) => {
    // console.log(data)
    const extractedData = [];
    if (name !== 'ptftJobs') {
      data.map(el => {
        extractedData.push({
          assign_id: el.assign_id !== null ? el.assign_id : '-',
          user_id: el.user_id !== null ? el.user_id : '-',
          about_me: el.about_me !== null ? el.about_me : '-',
          first_name: el.first_name !== null ? `${el.first_name} ${el.last_name ? el.last_name : ''} (${el.phone_number})` : '-',
          last_name: el.last_name !== null ? el.last_name : '-',
          phone_number: el.phone_number !== null ? el.phone_number : '-',
          job_id: el.job_id !== null ? el.job_id : '-',
          job_detail_id: el.job_detail_id !== null ? el.job_detail_id : '-',
          actual_score: el.actual_score !== null ? el.actual_score : '-',
          cutoff_score: el.cutoff_score !== null ? el.cutoff_score : '-',
          is_accepted:
            el.is_accepted !== null ? (el.is_accepted ? 'Yes' : 'No') : '-',
          is_rejected:
            el.is_rejected !== null ? (el.is_rejected ? 'Yes' : 'No') : '-',
          is_paid: el.is_paid !== null ? (el.is_paid ? 'Yes' : 'No') : '-',
          accepted_by: el.accepted_by !== null ? el.accepted_by : '-',

          // last_event: el.event ? el.event:"--",
          // last_event_status: el.event_status ? el.event_status : "--",
          // event_captured_on: el.capturedOn ? el.capturedOn : "--",

          rejected_by: el.rejected_by !== null ? el.rejected_by : '-', 
          is_confirmed:
            el.is_confirmed !== null ? (el.is_confirmed ? 'Yes' : 'No') : '-',
          startDate: !el.is_paid ? 'Payment Pending' : el.is_no_show ? 'Not Showed' : el.started_on ? el.started_on : 'Update CheckIn',
          endDate: !el.is_paid ? 'Payment Pending' : el.is_no_show ? 'Not Showed' : !el.ended_on && el.started_on ? 'Update CheckOut' : el.ended_on,
          confirmed_by: el.confirmed_by !== null ? el.confirmed_by : '-',
          inserted_by: el.inserted_by !== null ? el.inserted_by : '-',
          inserted_on: el.inserted_on !== null ? el.inserted_on : '-',
          updated_by: el.updated_by !== null ? el.updated_by : '-',
          acceptJob: el.is_confirmed ? 'Accepted' : 'Accept Job',
          updated_on: el.updated_on !== null ? el.updated_on : '-',
          worker_id: el.worker_id !== null ? el.worker_id : '-',
          qualification_id:
            el.qualification_id !== null ? el.qualification_id : '-',
          is_kyc: el.is_kyc !== null ? (el.is_kyc ? 'Yes' : 'No') : '-',
          spoken_language:
            el.spoken_language !== null ? el.spoken_language : '-',
          total_gigs: el.total_gigs !== null ? el.total_gigs : '-',
          total_reviews: el.total_reviews !== null ? el.total_reviews : '-',
          average_rating: el.average_rating !== null ? el.average_rating : '-',
          is_leader:
            el.is_leader !== null ? (el.is_leader ? 'Yes' : 'No') : '-',
          paytm_number: el.paytm_number !== null ? el.paytm_number : '-',
          breach: el.breach !== null ? el.breach : '-',
          has_experience:
            el.has_experience !== null
              ? el.has_experience
                ? 'Yes'
                : 'No'
              : '-',
          available_pt: el.available_pt !== null ? el.available_pt : '-',
          available_ft: el.available_ft !== null ? el.available_ft : '-',
          available_od: el.available_od !== null ? el.available_od : '-',
          super_toggle: el.super_toggle !== null ? el.super_toggle ? 'Yes' : 'No' : '-',
          global_availability:
            el.global_availability !== null ? el.global_availability ? 'Yes' : 'No' : '-',
          updateStatus: el.is_no_show ? 'Not Showed' : el.is_paid && !el.started_on && !el.ended_on ? 'Update Status' : el.is_paid && !el.ended_on ? 'Job Started' : el.is_paid && el.ended_on ? 'Job Completed' : 'Payment Pending',
          check_in_id: el.check_in_id !== null ? el.check_in_id : '-',
          job_details_id: el.job_details_id !== null ? el.job_details_id : '-',
          started_on: el.started_on !== null ? el.started_on : '-',
          ended_on: el.ended_on !== null ? el.ended_on : '-',
          is_extended:
            el.is_extended !== null ? (el.is_extended ? 'Yes' : 'No') : '-',
          status: el.status !== null ? el.status : '-',
          otp: el.otp !== null ? el.otp : '-',
          is_verified_by_otp:
            el.is_verified_by_otp !== null
              ? el.is_verified_by_otp
                ? 'Yes'
                : 'No'
              : '-',
          is_started:
            el.is_started !== null ? (el.is_started ? 'Yes' : 'No') : '-',
          is_no_show:
            el.is_no_show !== null ? (el.is_no_show ? 'Yes' : 'No') : '-',
          is_ended: el.is_ended !== null ? (el.is_ended ? 'Yes' : 'No') : '-'
        });
      });
    } else {
      // console.log(data)  
      // console.log(this.makeLocalTime('2020-05-07 09:47:48'));
      data.map(el => {
        console.log({el});
        
        extractedData.push({
          assign_id: el.assign_id === null ? '-' : el.assign_id,
          user_id: el.user_id === null ? '-' : el.user_id,
          first_name: el.first_name === null ? '-' : `${el.first_name} ${el.last_name ? el.last_name : ''} (${el.phone_number})`,
          last_name: el.last_name === null ? '-' : el.last_name,
          worker_id: el.worker_id === null ? '-' : el.worker_id,
          actualScore: el.actual_score === null ? '-' : el.actual_score,
          average_rating: el.average_rating === null ? '-' : el.average_rating,
          total_reviews: el.total_reviews === null ? '-' : el.total_reviews,
          phone_number: el.phone_number === null ? '-' : el.phone_number,
          interviewId: el.interviewId === null ? '-' : el.interviewId,
          insertedBy: el.insertedBy === null ? '-' : el.insertedBy,

          last_event: el.event ? el.event:"--",
          last_event_status: el.event_status ? el.event_status : "--",
          event_captured_on: el.capturedOn ? this.makeLocalTime(el.capturedOn) : "--",

          insertedOn: el.insertedOn === null ? '-' : el.insertedOn,
          interviewEndTime:
            el.interviewEndTime === null ?'-' : el.interviewEndTime,
          interviewStartTime:
            el.interviewStartTime === null ?'-' : el.interviewStartTime,
          interviewStatus:
            el.interviewStatus === null ? '-' : el.interviewStatus ? 'Yes' : 'No',
          interviewConducted: el.otpVerified ? el.whichSlot === 1 ?
          `${el.interviewStartTime.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime.substring(11, 16)}` :
          el.whichSlot === 2 ?
          `${el.interviewStartTime2.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime2.substring(11, 16)}` :
          el.whichSlot === 3 ?
          `${el.interviewStartTime3.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime3.substring(11, 16)}` : '' : '-',
          interviewCleared: el.otpVerified ? el.whichSlot === 1 ?
          `${el.interviewStartTime.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewEndTime.substring(11, 16)}` :
          el.whichSlot === 2 ?
          `${el.interviewStartTime2.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewEndTime2.substring(11, 16)}` :
          el.whichSlot === 3 ?
          `${el.interviewStartTime3.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewEndTime3.substring(11, 16)}` : '' : '-',
          isWorkerdHired:
            el.isWorkerdHired === null ? '-' : el.isWorkerdHired ? 'Yes' : 'No',
          jobDetailsId: el.jobDetailsId === null ? '-' : el.jobDetailsId,
          otp: el.otp === null ? '-' : el.otp,
          otpVerified:
            el.otpVerified === null ? '-' : el.otpVerified ? 'Yes' : 'No',
          rejectCandidate: el.is_job_offered ? 'Job Offered' : el.is_rejected ? 'Rejected' : 'Reject Candidate',
          rejectOffer: el.is_rejected ? 'Candidate Rejected' : el.is_offer_accepted === 2 ? 'Offer Rejected' : el.is_offer_accepted === 1 ? 'Offer Accepted' : el.job_offered_amount ? 'Update Offer' : '-',
          isInterview: el.interviewId ? 'Created' : 'Create Slots',
          rescheduleInterview: el.is_no_show ? 'Not Showed' : el.interviewId === 0 && el.whichSlot === 0 || el.otpVerified === 1 ? 'Slot Selected' : el.interviewId? 'ReSchedule Interview':'-',
          cancelInterview: el.otpVerified ? 'OTP Verified' : el.interviewStatus === null ? '-' : el.interviewStatus && el.whichSlot ? 'Cancel' : '-',
          isNoShow: el.otpVerified ? 'OTP Verified' : el.is_no_show ? 'Show' : !el.otpVerified ? 'No Show' : '-',
          selectSlot: el.whichSlot === 1 ?
           `${el.interviewStartTime.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime.substring(11, 16)}-${el.interviewEndTime.substring(11, 16)}` :
           el.whichSlot === 2 ?
           `${el.interviewStartTime2.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime2.substring(11, 16)}-${el.interviewEndTime2.substring(11, 16)}` :
           el.whichSlot === 3 ?
           `${el.interviewStartTime3.toString().substring(0, 10).split("-").reverse().join("-")} ${el.interviewStartTime3.substring(11, 16)}-${el.interviewEndTime3.substring(11, 16)}` :
            'Select Slot',
          updatedBy: el.updatedBy === null ? '-' : el.updatedBy,
          updatedOn: el.updatedOn === null ? '-' : el.updatedOn,
          interviewEndTime2:
            el.interviewEndTime2 === null ? '-' : el.interviewEndTime2,
          interviewStartTime2:
            el.interviewStartTime2 === null ? '-' : el.interviewStartTime2,
          interviewEndTime3:
            el.interviewEndTime3 === null ? '-' : el.interviewEndTime3,
          interviewStartTime3:
            el.interviewStartTime3 === null ? '-' : el.interviewStartTime3,
          updateSalary:  el.is_rejected ? 'Offer Rejected' : el.job_offered_amount || 'Offer Job',
          acceptedBy: el.acceptedBy === null ? '-' : el.acceptedBy,
          whichSlot: el.whichSlot === null || el.whichSlot === 0  ? '-' : el.whichSlot,
          accepted_on: el.accepted_on === null ? '-' : el.accepted_on,
          address: el.address === null ? '-' : el.address,
          postalCode: el.postalCode === null ? '-' : el.postalCode,
          jobLocationLat: el.jobLocationLat === null ? '-' : el.jobLocationLat,
          jobLocationLong:
            el.jobLocationLong === null ? '-' : el.jobLocationLong,
          landMark: el.landMark === null ? '-' : el.landMark,
          is_job_offered:
            el.is_job_offered === null ? '-' : el.is_job_offered ? 'Yes' : 'No',
          is_worker_hired:
            el.is_joined === null
              ? '-'
              : el.is_joined === 1
              ? 'Yes'
              : 'No',
          job_offered_amount:
            el.job_offered_amount === null ? '-' : el.job_offered_amount,
          is_offer_accepted:
            el.is_offer_accepted === null
              ? '-'
              : el.is_offer_accepted
              ? 'Yes'
              : 'No',
          verifyOtp: el.is_no_show ? 'Not Showed' : el.otpVerified ? 'Done' : 'Verify',
          joining_date: el.joining_date === null ? '-' : !el.is_rejected && el.is_offer_accepted !== 2 ? el.joining_date.toString().substring(0, 10).split("-").reverse().join("-") : '-',
          offer_accepted_by:
            el.offer_accepted_by === null ? '-' : el.offer_accepted_by,
          offer_accepted_date:
            el.offer_accepted_date === null ? '-' : el.offer_accepted_date,
          is_rejected:
            el.is_rejected === null ? '-' : el.is_rejected ? 'Yes' : 'No',
          workingStatus: el.is_joined === 2 ? 'Left' : el.is_joined === 1 ? 'Joined' : !el.is_job_offered ? '-' : el.is_rejected ? 'Offer Rejected' : el.is_job_offered && el.is_offer_accepted === 1 ? 'Mark Joined' : '-',
          is_joined: el.is_joined === null ? '-' : el.is_joined === 1 ? 'Yes' : el.is_joined === 2 ? 'Left' : 'No',
          invoiceAmount:  el.is_rejected ? 'Offer Rejected' : el.invoiceAmount === null ? '-' : el.invoiceAmount,
          isPaid: el.isPaid === null ? '-' : el.isPaid ? 'Yes' : 'No',
          applyJob: 'Apply',
          transactionUniqueId:
            el.transactionUniqueId === null ? '-' : el.transactionUniqueId
        });
      });
    }
    return extractedData;
  };

  makeLocalTime= date => {
    var t=date.split(/[- :]/);
    var d=new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    var F=d.toLocaleString()
    return F;
  }
  

  makeData2 = data => {
    const extractedData = [];
    data.map(el => {
      extractedData.push({
        assignId: el.assignId === null ? '-' : el.assignId,
        actualScore: el.actualScore === null ? '-' : el.actualScore,
        phoneNumber: el.phoneNumber,
        availabilityCutOff:
          el.availabilityCutOff === null
            ? '-'
            : this.matchCuttOff(el.availabilityCutOff),
        breachCutOff:
          el.breachCutOff === null ? '-' : this.matchCuttOff(el.breachCutOff),
        cutoffScore: el.cutoffScore === null ? '-' : el.cutoffScore,
        distanceCutOff:
          el.distanceCutOff === null
            ? '-'
            : this.matchCuttOff(el.distanceCutOff),
        expCutOff:
          el.expCutOff === null ? '-' : this.matchCuttOff(el.expCutOff),
        genderCutOff:
          el.genderCutOff === null ? '-' : this.matchCuttOff(el.genderCutOff),
        qualCutOff:
          el.qualCutOff === null ? '-' : this.matchCuttOff(el.qualCutOff),
        insertedOn: el.insertedOn === null ? '-' : el.insertedOn,
        jobDetailId: el.jobDetailId === null ? '-' : el.jobDetailId,
        jobId: el.jobId === null ? '-' : el.jobId,
        negativeRatingCutOff:
          el.negativeRatingCutOff === null
            ? '-'
            : this.matchCuttOff(el.negativeRatingCutOff),
        userId: el.userId === null ? '-' : el.userId,
        workerId: el.workerId === null ? '-' : el.workerId,
        userName: el.userName === null ? '-' : el.userName,
        lastActivity: el.lastActivity === null ? '-' : el.lastActivity,
        address: el.address === null ? '-' : el.address,
        city: el.city === null ? 'Other' : el.city,
        lastSeenDistance:
          el.lastSeenDistance === null ? '-' : el.lastSeenDistance < 2500 ? el.lastSeenDistance : 'Not Captured',
        distanceCapturedOn:
          el.distanceCapturedOn === null ? '-' : el.distanceCapturedOn,
        travelMode: el.travelMode === null ? '-' : el.travelMode,
        jobType: this.state.detailJobData.jobPosition === null ? '-' : this.state.detailJobData.jobPosition,
        lastJobExp: el.lastJobExp === null ? '-' : el.lastJobExp,
        expInYears: el.expInYears === null ? '-' : el.expInYears,
        averageRating: el.averageRating === null ? '-' : el.averageRating,
        totalGigs: el.totalGigs === null ? '-' : el.totalGigs,
        applyJob: 'Apply',
        breach: el.breach === null ? '-' : el.breach
      });
    });
    return extractedData;
  };

  makeData3 = data => {
    const extractedData = [];
    data.map(el => {
      extractedData.push({
        assignId: el.assignId === null ? '-' : el.assignId,
        userName: el.userName === null ? '-' : el.userName,
        workerId: el.workerId === null ? '-' : el.workerId,
        phoneNumber: el.phoneNumber,
        lastActivity: el.lastActivity === null ? '-' : el.lastActivity,
        address: el.address === null ? '-' : el.address,
        currentLocation: el.currentLocation === null ? '-' : el.currentLocation,
        distanceCapturedOn:
          el.distanceCapturedOn === null ? '-' : el.distanceCapturedOn,
        lastSeenDistance:
          el.lastSeenDistance === null ? '-' : el.lastSeenDistance < 2500 ? el.lastSeenDistance : 'Not Captured',
        userId: el.id.userId,
        travelMode: el.travelMode === null ? '-' : el.travelMode,
        availability: el.availability === null ? '-' : el.availability,
        industry: el.industry === null ? '-' : el.industry,
        lastJobExp: el.lastJobExp === null ? '-' : el.lastJobExp,
        expInYears: el.expInYears === null ? '-' : el.expInYears,
        averageRating: el.averageRating === null ? '-' : el.averageRating,
        totalGigs: el.totalGigs === null ? '-' : el.totalGigs,
        breach: el.breach === null ? '-' : el.breach,
        cutoffScore: el.cutoffScore === null ? '-' : el.cutoffScore,
        actualScore: el.actualScore === null ? '-' : el.actualScore,
        availabilityCutOff:
          el.availabilityCutOff === null
            ? '-'
            : this.matchCuttOff(el.availabilityCutOff),
        breachCutOff:
          el.breachCutOff === null ? '-' : this.matchCuttOff(el.breachCutOff),
        distanceCutOff:
          el.distanceCutOff === null
            ? '-'
            : this.matchCuttOff(el.distanceCutOff),
        expCutOff:
          el.expCutOff === null ? '-' : this.matchCuttOff(el.expCutOff),
        genderCutOff:
          el.genderCutOff === null ? '-' : this.matchCuttOff(el.genderCutOff),
        qualCutOff:
          el.qualCutOff === null ? '-' : this.matchCuttOff(el.qualCutOff),
        negativeRatingCutOff:
          el.negativeRatingCutOff === null
            ? '-'
            : this.matchCuttOff(el.negativeRatingCutOff)
      });
    });
    return extractedData;
  };

  getMatchedWorkerList = (
    rowsPerPage = this.state.rowsPerPage2,
    nextPage = this.state.currentPage2
  ) => {
    let matchedData = null;
    this.setState({
      isLoading2: true,
      isTableUpdating2: true
    })
    console.log("hello",{rowsPerPage, nextPage});
    
    // console.log(
    //   `${urlLinks.baseUrl}/job/assigner/assigned?job_details_id=${this.props.id}&page_no=${nextPage}&rows=${rowsPerPage}`
    // );
    // this.matched_url=`${urlLinks.baseUrl}/job/assigner/assigned?job_details_id=${this.props.id}&page_no=${nextPage}&rows=${rowsPerPage}`
    Axios.get(`${this.matched_url}${this.matchedfilterUrl}&page_no=${nextPage}&rows=${rowsPerPage}`)
      .then(response => {
        console.log("rows per page",this.state.rowsPerPage2);
        matchedData = response.data.response.content;
        this.setState({
          matchedData: this.makeData2(matchedData),
          count2: response.data.response.totalElements,
          currentPage2: nextPage,
          rowsPerPage2: rowsPerPage,
          isLoading2: false,
          isTableUpdating2: false
        });
      })
      .catch(error => {
        this.setState({
          isLoading2: false,
          isTableUpdating2: false
        });
        toast.error('Matched Workers List Not Loaded Successfully');
      });
  };

  assignWorkers = ids => {
    if (ids.length > 0) {
      this.setState({ isTableUpdating2: true, isTableUpdating3: true });
      // console.log(this.state.jobData.userId)
      // console.log(`${urlLinks.baseUrl}/job/assigner/assign?requested_by=${this.state.jobData.userId}&job_detail_id=${this.props.id}&user_ids=${ids.join(',')}`)
      Axios.post(
        `${
          urlLinks.baseUrl
        }/job/assigner/assign?requested_by=${this.state.jobData.userId}&job_detail_id=${this.props.id}&user_ids=${ids.join(',')}`
      )
        .then(response => {
          console.log(response);
          this.getMatchedWorkerList();
          if (this.state.isDefaultSelected) {
            this.getDefaultMatchData();
          } else {
            this.getMatchNewWorkerList();
          }
          toast.success(`Worker Assigned Successfully`);
        })
        .catch(error => toast.error(`Error Assigning Worker`));
    } else {
      toast.error(`Select Workers To Assign`);
    }
  };

  generateInvoice = ids => {
    console.log(this.state.jobData);
    
    if (ids.length > 0) {
      Axios.get(
        `${
          urlLinks.baseUrl
        }/transactions/getInvoiceIdByInterviewId?interviewIds=${ids.join(
          '~'
        )}&user_id=${this.state.jobData.company.userId}`
      )
        .then(response => {
          toast.success(
            `Invoices Generated Successfully For : ${ids.join(
              ','
            )}`
          )
          this.getHiredWorkerList()
        })
        .catch(error => toast.error(error));
    } else {
      toast.error(`Select Workers To Generate Invoice`);
    }
  };

  componentDidMount() {
    let data = {}
    Axios.get(`${urlLinks.baseUrl}/job/getdetailedjob?job_id=${this.props.jobIdColumn}`)
    .then(response => {
      data = response.data.response.content[0];
      const detailJobData = data.detailsJobs.filter(el => el.jobDetailsId === this.props.id)[0]
      this.setState({jobData: data, detailJobData: detailJobData, employeeHired: detailJobData.employeeHired, employeeRequired: detailJobData.employeeRequired})
      this.getHiredWorkerList();
      this.getApppliedWorkerList();
      this.getMatchedWorkerList();
      this.getDefaultMatchData();
    })
  }

  updateFilters(averageRating,actualScore,cutoffScore,expInYears,totalGigs,Rset,name,number,gender){
    console.log(averageRating,actualScore,cutoffScore,expInYears,totalGigs);
    var rowsPerPage = this.state.rowsPerPage3;
    var nextPage = this.state.currentPage3;
    // var newUrl=this.url;
    this.MatchNewFilterUrl=''
    if(this.dataCheck.length>0){
      if(averageRating>0){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&averageRating=${averageRating}`
      }
      if(actualScore){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&actualScore=${actualScore}`
      }
      if(cutoffScore){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&cutoffScore=${cutoffScore}`
      }
      if(expInYears){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&expInYrs=${expInYears}`
      }
      if(totalGigs){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&totalGigs=${totalGigs}`
      }
      if(name){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&name=${name}`
      }
      if(number){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&number=${number}`
      }
      if(gender){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&_gender=${gender}`
      }
        this.getDefaultMatchData();

    }else{
      var newUrl=this.url2;
      if(averageRating>0){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&averageRating=${averageRating}`
      }
      if(actualScore){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&actualScore=${actualScore}`
      }
      if(cutoffScore){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&cutoffScore=${cutoffScore}`
      }
      if(expInYears){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&expInYrs=${expInYears}`
      }
      if(totalGigs){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&totalGigs=${totalGigs}`
      }
      if(name){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&name=${name}`
      }
      if(number){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&number=${number}`
      }
      if(gender){
        this.MatchNewFilterUrl=this.MatchNewFilterUrl+`&_gender=${gender}`
      }
        this.getDefaultMatchData();
    

    }
  }

  updateFiltersApplied(averageRating, actualScore, cutoffScore, expInYears, totalGigs,
    rset, name, number, dates, statusInterview, statusJoining, salary, city, gender,appliedBy,Qualification){
    console.log("updateFiltersApplied");
    console.log(averageRating, actualScore, cutoffScore, expInYears, totalGigs,
    rset, name, number, dates, statusInterview, statusJoining, salary, city, gender)
    this.AppliedFilterUrl=''
    if(this.props.name === 'ptftJobs'){
      if(averageRating){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&avgRating=${averageRating}`
      }
      if(actualScore){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&actualScore=${actualScore}`
      }
      if(cutoffScore){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&cut_offScore=${cutoffScore}`
      }
      if(expInYears){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&expInMonth=${expInYears}`
      }
      if(totalGigs){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&totalGigs=${totalGigs}`
      }
      if(name){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&name=${name}`
      }
      if(number){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&number=${number}`
      }
      if(gender){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&genderId=${gender}`
      }
      if(city){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&cityId=${city}`
      }
      if(appliedBy){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&appliedBy=${appliedBy}`
      }if(Qualification){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&qualificationId=${Qualification}`
      }
      if(statusInterview){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&status=${statusInterview}`
      }
      if(dates){
        if(dates.start_date){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&interviewStartTime=${dates.start_date}`
        }
      }
      if(statusJoining){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&joining=${statusJoining}`
      }
      if(dates){
        if(dates.end_date){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&joiningDate=${dates.end_date}`
        }
      }
      if(salary){
        this.AppliedFilterUrl=this.AppliedFilterUrl+`&salary=${salary}`
      }
      console.log(this.AppliedFilterUrl);
      
      this.getApppliedWorkerList();  
    }else{
      this.A_url = `${urlLinks.baseUrl}/job/assigner/getODappliedworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${this.state.currentPage1}&rows=${this.state.rowsPerPage1}`;
      this.A_url2 = `${urlLinks.baseUrl}/job/assigner/getODUnpaidHiredworkerlist?job_details_id=${this.props.id}&job_id=${this.props.jobIdColumn}&page_no=${this.state.currentPage1}&rows=${this.state.rowsPerPage1}`;
      this.getApppliedWorkerList();  
    }
  }

  updateFiltersMatched(averageRating, actualScore, cutoffScore, expInYears,
    totalGigs, worktype, name, number, gender ){
    console.log("matched");
    console.log(averageRating, actualScore, cutoffScore, expInYears,
    totalGigs, worktype, name, number, gender);
    this.matchedfilterUrl='';
    if(averageRating){
      this.matchedfilterUrl=this.matchedfilterUrl+`&averageRating=${averageRating}`
    }
    if(actualScore){
      this.matchedfilterUrl=this.matchedfilterUrl+`&actualScore=${actualScore}`
    }
    if(cutoffScore){
      this.matchedfilterUrl=this.matchedfilterUrl+`&cutoffScore=${cutoffScore}`
    }
    if(expInYears){
      this.matchedfilterUrl=this.matchedfilterUrl+`&expInYrs=${expInYears}`
    }
    if(totalGigs){
      this.matchedfilterUrl=this.matchedfilterUrl+`&totalGigs=${totalGigs}`
    }
    if(name){
      this.matchedfilterUrl=this.matchedfilterUrl+`&name=${name}`
    }
    if(number){
      this.matchedfilterUrl=this.matchedfilterUrl+`&number=${number}`
    }
    if(gender){
      this.matchedfilterUrl=this.matchedfilterUrl+`&_gender=${gender}`
    }

    this.getMatchedWorkerList();

  }
  
    

  render() {
    // console.log(this.updateFilters)
    const tableOnePagination = {
      currentPage: this.state.currentPage1 || 0,
      count: this.state.count1 || 0,
      changePage: this.updateDataOnTableOnePagination,
      rowsOptions: this.rowsOptions,
      rowsPerPage: this.state.rowsPerPage1 || 0,
      tableUpdating: this.state.isTableUpdating1
    };
    const tableTwoPagination = {
      currentPage: this.state.currentPage2,
      count: this.state.count2,
      changePage: this.updateDataOnTableTwoPagination,
      rowsOptions: this.rowsOptions,
      rowsPerPage: this.state.rowsPerPage2,
      tableUpdating: this.state.isTableUpdating2
    };
    const tableThreePagination = {
      currentPage: this.state.currentPage3,
      count: this.state.count3,
      changePage: this.updateDataOnTableThreePagination,
      rowsOptions: this.rowsOptions,
      rowsPerPage: this.state.rowsPerPage3,
      tableUpdating: this.state.isTableUpdating3
    };
    const tableFourPagination = {
      currentPage: this.state.currentPage4 || 0,
      count: this.state.count4 || 0,
      changePage: this.updateDataOnTableOnePagination,
      rowsOptions: this.rowsOptions,
      rowsPerPage: this.state.rowsPerPage4 || 0,
      tableUpdating: this.state.isTableUpdating4
    };
    
    console.log(this.props.name);
    
    return (
      <>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={2000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <Paper>
          <AppBar position='static' color='default'>
            <Tabs
              value={this.state.tabs.value}
              onChange={this.handelChangeValue}
              indicatorColor='primary'
            >
              <Tab label='Hired Workers'></Tab>
              <Tab label='Applied Workers' 
                // disabled={this.state.employeeRequired === this.state.employeeHired}
              ></Tab>
              <Tab label='Matched Workers'></Tab>
              <Tab label='Match New Workers'></Tab>
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.tabs.value} index={0}>
            {this.state.isLoading4 ? (
              <RingLoader
                css={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto'
                }}
                color={'#194D33'}
              ></RingLoader>
            ) : (
              <CustomTable
                name={'interviewId'}
                columns={childTableConstant[this.props.name].table4.cols}
                data={this.state.hiredData}
                updateParentData={this.props.updateParentData}
                isOpened={false}
                updateHireData={this.getHiredWorkerList}
                updateHiredData={this.getApppliedWorkerList}
                jobDetailId={this.props.id}
                updatedAppliedData={this.getApppliedWorkerList}
                jobData={this.state.jobData}
                isCheckBoxesVisible={this.props.name === 'ptftJobs' ? true : false}
                numericIndex={this.props.name !== 'ptftJobs' ? true : false}
                isPaid={this.state.isPaidArr}
                tablePagination={tableFourPagination}
                invoiceLabel={
                  this.props.name === 'ptftJobs' ? 'Create Invoice' : null
                }
                generateInvoice={
                  this.props.name === 'ptftJobs' ? this.generateInvoice : null
                }
                title='Hired Workers Details'
                links={childTableConstant[this.props.name].table4.listAction}
              ></CustomTable>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabs.value} index={1}>
            {this.state.isLoading3 ? (
              <RingLoader
                css={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto'
                }}
                color={'#194D33'}
              ></RingLoader>
            ) : (
              
              <div>

             <CustomFilterMatched
                averageRating={this.state.averageRating1}
                actualScore={this.state.actualScore1}
                cutoffScore={this.state.cutoffScore1}
                expInYears={this.state.expInYears1}
                totalGigs={this.state.totalGigs1}
                name={this.state.name}
                number={this.state.number}
                updateFilters={this.updateFiltersApplied}
                rset={this.rset1}
                dates={this.state.dates}
                isOpen={false}
                statusJoining={this.state.statusJoining}
                statusInterview={this.state.statusInterview}
                salary={this.state.salary}
                city={this.state.city}
                gender={this.state.gender}
                appliedBy={this.state.appliedBy}
                >
              </CustomFilterMatched>
              <CustomTable
                name={'interviewId'}
                columns={childTableConstant[this.props.name].table3.cols}
                data={this.state.appliedData}
                isCheckBoxesVisible={false}
                numericIndex={true}
                updateParentData={this.props.updateParentData}
                updateHireData={this.getHiredWorkerList}
                updateHiredData={this.getApppliedWorkerList}
                updateMatchedData={this.getMatchedWorkerList}
                jobDetailId={this.props.id}
                jobData={this.state.jobData}
                // isCheckBoxesVisible={true}
                // numericIndex={true}
                // isPaid={this.state.isPaidArr}
                tablePagination={tableOnePagination}
                // invoiceLabel={
                //   this.props.name === 'ptftJobs' ? 'Create Invoice' : null
                // }
                // generateInvoice={
                //   this.props.name === 'ptftJobs' ? this.generateInvoice : null
                // }
                title='Applied Workers Details'
                links={childTableConstant[this.props.name].table3.listAction}
              ></CustomTable>

              </div>
              
            )}
          </TabPanel>
          <TabPanel value={this.state.tabs.value} index={2}>
          {/* <CustomeFilterMatching
             averageRating={this.state.averageRating}
             actualScore={this.state.actualScore}
             cutoffScore={this.state.cutoffScore}
             expInYears={this.state.expInYears}
             totalGigs={this.state.totalGigs}
             updateFilters={this.updateFilters}
             rset={this.rset}
             isOpen={false}
            >
            </CustomeFilterMatching> */}
            {this.state.isLoading1 ? (
              <RingLoader
                css={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto'
                }}
                color={'#194D33'}
              ></RingLoader>
            ) : (

              <div>
              <DefaultCustomFilterMatched
               averageRating={this.state.averageRating2}
               actualScore={this.state.actualScore2}
               cutoffScore={this.state.cutoffScore2}
               expInYears={this.state.expInYears2}
               totalGigs={this.state.totalGigs2}
               isOpen= {false}
               worktype={this.state.rset2}
               name={this.state.name2}
               number={this.state.number2}
               gender={this.state.gender2}
               updateFilters={this.updateFiltersMatched}
               >
              </DefaultCustomFilterMatched>

              <CustomTable
                name={Constant.worker.idName}
                columns={childTableConstant[this.props.name].table1.cols}
                data={this.state.matchedData}
                isCheckBoxesVisible={false}
                numericIndex={true}
                updateHiredData={this.getApppliedWorkerList}
                updateMatchedData={this.getMatchedWorkerList}
                tablePagination={tableTwoPagination}
                title='Matched Workers Details'
                links={childTableConstant[this.props.name].table1.listAction}
              ></CustomTable>
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabs.value} index={3}>
            <PopupState variant='popover' popupId='demo-popup-popover'>
              {popupState => (
                <>
                  <Button
                    variant='contained'
                    color='primary'
                    {...bindTrigger(popupState)}
                  >
                    Matching Criteria
                  </Button>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                  >
                    <Box p={2}>
                      Availability :
                      <PurpleSwitch
                        checked={this.state.cut_off_availability}
                        onChange={() => {
                          this.setState({
                            cut_off_availability: !this.state
                              .cut_off_availability
                          });
                        }}
                      />
                      Breach :
                      <PurpleSwitch
                        checked={this.state.cut_off_breach}
                        onChange={() => {
                          this.setState({
                            cut_off_breach: !this.state.cut_off_breach
                          });
                        }}
                      />
                      Distance :
                      <PurpleSwitch
                        checked={this.state.cut_off_distance}
                        onChange={() => {
                          this.setState({
                            cut_off_distance: !this.state.cut_off_distance
                          });
                        }}
                      />
                      Experience CuttOff :
                      <PurpleSwitch
                        checked={this.state.cut_off_exp}
                        onChange={() => {
                          this.setState({
                            cut_off_exp: !this.state.cut_off_exp
                          });
                        }}
                      />
                      Gender CuttOff :
                      <PurpleSwitch
                        checked={this.state.cut_off_gender}
                        onChange={() => {
                          this.setState({
                            cut_off_gender: !this.state.cut_off_gender
                          });
                        }}
                      />
                      Qualification CuttOff :
                      <PurpleSwitch
                        checked={this.state.cut_off_qual}
                        onChange={() => {
                          this.setState({
                            cut_off_qual: !this.state.cut_off_qual
                          });
                        }}
                      />
                      Negative Rating :
                      <PurpleSwitch
                        checked={this.state.cut_off_negative_rating}
                        onChange={() => {
                          this.setState({
                            cut_off_negative_rating: !this.state
                              .cut_off_negative_rating
                          });
                        }}
                      />
                    </Box>
                  </Popover>
                </>
              )}
            </PopupState>

            <Button
              variant='contained'
              style={{ width: '90px', marginLeft: '10px', fontSize: '0.8rem' }}
              color='primary'
              onClick={() =>
                this.setState(
                  {
                    isLoading3: true,
                    isTableUpdating3: true,
                    isDefaultSelected: false,
                    currentPage3: 0,
                    count3: 0,
                    rowsPerPage3: 10
                  },
                  () => this.getMatchNewWorkerList()
                )
              }
            >
              Run
            </Button>

            <CustomeFilterMatching
             averageRating={this.state.averageRating}
             actualScore={this.state.actualScore}
             cutoffScore={this.state.cutoffScore}
             expInYears={this.state.expInYears}
             totalGigs={this.state.totalGigs}
             updateFilters={this.updateFilters}
             rset={this.rset}
             isOpen={this.state.open}
            >
            </CustomeFilterMatching>

            {this.state.isLoading2 ? (
              <RingLoader
                css={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto'
                }}
                color={'#194D33'}
              ></RingLoader>

            ) : (
              <CustomTable
                name={'userId'}
                assignLabel={'Assign Workers'}
                columns={childTableConstant[this.props.name].table2.cols}
                data={this.state.matchNewData}
                assignWorkers={this.assignWorkers}
                links={childTableConstant[this.props.name].table2.listAction}
                tablePagination={tableThreePagination}
                title={
                  this.state.isDefaultSelected
                    ? 'Automatically Generated Algorithmic Workers List'
                    : 'Manually Generated Algorithmic Workers List'
                }
              ></CustomTable>
            )}
          </TabPanel>
        </Paper>
      </>
    );
  }
}

export default ChildTableComponent;
