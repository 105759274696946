import 'date-fns';
import React, {   useState  } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import * as moment from 'moment'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  TimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const TimePicker1 =(props)=> {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const {handleChange, startTime, endTime}=props;

  const handleStartTime = (date) => {
    console.log(date)
    setSelectedDate(date);
    handleChange(date,1)
    
    
  };

  const handleEndTime = (date) => {
      console.log(date)
      setSelectedEndDate(date);
      handleChange(date,2)

  };

  return (
    console.log(startTime, endTime),
    // console.log(moment(this.editStartDate).toDate(), moment(this.editEndDate).toDate()),
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Grid container justify="space-around">
      <Grid item xs={6} className="padding0">
        <TimePicker
          margin="normal"
          // views={["hours"]}
          // disableMinutes={ true }
          id="time-picker"
          format="hh:mm a"
          label="Start Time"
          value={selectedDate? selectedDate: startTime?startTime:null}
          onChange={handleStartTime}
          KeyboardButtonProps={{
            'aria-label': 'start time',
          }}
        />
      </Grid>
      <Grid item xs={6} className="padding0" >
      <TimePicker
          margin="normal"
          id="time-picker"
          // disableMinutes={ true }
          // views={["hours"]}
          format="hh:mm a"
          label="End Time"
          value={selectedEndDate? selectedEndDate: endTime?endTime:null}
          onChange={handleEndTime}
          KeyboardButtonProps={{
            'aria-label': 'End time',
          }}
        />
        </Grid>
        </Grid>
    </MuiPickersUtilsProvider>
   
    
    
  );
}
export default TimePicker1;