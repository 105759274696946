import React, {Fragment,  useState } from 'react'
import { Field, FieldArray } from "formik";
import * as moment from 'moment'
import WeekToggle from './weektoggleButton'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import {TextField ,Button , MenuItem, FormControl, InputLabel, Grid, InputAdornment} from "@material-ui/core";


const InterviewDetails = formikProps =>{
    const { errors, touched, values, setFieldValue, handleChange } = formikProps;
    console.log({values});
    
    
    return(
        <Fragment>
            <FieldArray
              name="interviewSlots"
              render={({ insert, remove, push }) => (
                <Grid container spacing={0}>
                  {values.interviewSlots.length > 0 &&
                    values.interviewSlots.map((interview, index) => (
                        
                    
                        <Grid container spacing={0} key={index}>
                           <Grid item xs={10}>
                                <Field
                                    variant= "outlined"
                                    fullWidth={true}
                                    as= {WeekToggle}
                                    isdisabled={false}
                                    value={values.interviewSlots?values.interviewSlots[index].weekdays:[]}
                                    handleWeekdays={setFieldValue}
                                    name= {`interviewSlots.${index}.weekdays`}
                                    error={touched.interviewSlots && touched.interviewSlots[index] && touched.interviewSlots[index].weekdays && errors.interviewSlots && errors.interviewSlots[index] &&  errors.interviewSlots[index].weekdays}
                                    helperText={touched.interviewSlots && touched.interviewSlots[index] && touched.interviewSlots[index].weekdays && errors.interviewSlots && errors.interviewSlots[index] &&  errors.interviewSlots[index].weekdays}
                                />   
                                <Grid container spacing={0}>
                                    <Grid item md={6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            label="Start time"
                                            className="TimeField"
                                            name={`interviewSlots.${index}.startTime`}
                                            value={values.interviewSlots[index].startTime}
                                            onChange={date =>setFieldValue(`interviewSlots.${index}.startTime`, moment(date).add(30, 'minutes').startOf('hour'))}
                                            error={touched.interviewSlots && touched.interviewSlots[index] && touched.interviewSlots[index].startTime &&  errors.interviewSlots && errors.interviewSlots[index] && errors.interviewSlots[index].startTime}
                                            helperText={touched.interviewSlots && touched.interviewSlots[index] && touched.interviewSlots[index].startTime && errors.interviewSlots && errors.interviewSlots[index] &&  errors.interviewSlots[index].startTime}
                                        />  
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item md={6}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            label="End time"
                                            className="TimeField"
                                            name={`interviewSlots.${index}.endTime`}
                                            value={values.interviewSlots[index].endTime}
                                            onChange={date =>setFieldValue(`interviewSlots.${index}.endTime`, moment(date).add(30, 'minutes').startOf('hour'))}
                                            error={touched.interviewSlots && touched.interviewSlots[index] && touched.interviewSlots[index].endTime && errors.interviewSlots && errors.interviewSlots[index] &&  errors.interviewSlots[index].endTime}
                                            helperText={touched.interviewSlots && touched.interviewSlots[index] && touched.interviewSlots[index].endTime && errors.interviewSlots && errors.interviewSlots[index] &&  errors.interviewSlots[index].endTime}
                                        />
                                        </MuiPickersUtilsProvider>
                                        {console.log(errors.interviewSlots)
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    color="secondary"
                                    onClick={() => remove(index)}
                                    >
                                    X
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                    <Button
                        color="primary"
                        onClick={() => push({startTime: null, endTime: null })}
                        >
                        {values.interviewSlots.length > 0?<span>Add more interview slots</span>:<span>Add interview slot</span>}
                    </Button>
                </Grid>
              )}
            />
        </Fragment>
    )
}

export default InterviewDetails;