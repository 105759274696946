import React, { useState, useEffect, useMemo , useCallback} from 'react'
import { useTable, useRowSelect, useFilters, useSortBy } from 'react-table'
import {Grid, Button, Checkbox, Box, Switch, Popover, TableSortLabel} from '@material-ui/core';
import styled from 'styled-components'
import ScaleLoader from "react-spinners/ScaleLoader";
import Pagination from '../../../../utils/react-table/pagination'
import SliderFilter from './Filters/SliderFilter'
import InputTextFilter from './Filters/inputTextFilter'
import './tables.css'
import urlLinks from '../../../../urlLinks';
import Axios from 'axios';
import WorkerModal from './functions/workerModal'
import AssignVacancyThroughRecruiter from '../../../../utils/Modals/AssignToRecruiterThroughJob'
import { ToastContainer, toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';

const PurpleSwitch = withStyles({
  switchBase: {
    color: lightGreen[200],
    '&$checked': {
      color: lightGreen['A700']
    },
    '&$checked + $track': {
      backgroundColor: lightGreen['A700']
    }
  },
  checked: {},
  track: {}
})(Switch);

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} color= "primary" />
      </React.Fragment>
    )
  }
)
const Styles = 
    styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            height:700px;
        }
        table {
            border-spacing: 0;
            height:700px;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #1c75bc !important;

            }
            th,td{
                padding:5px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
    `

const MatchedWorkers =(props) =>{
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openWorkerModal, setOpenWorkerModal] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(1)
    const [perPage, setPerPage] = useState(10);
    const [openAssignRecruiterJobModal, setOpenAssignRecruiterJobModal] = useState(false)
    const [userIdList, setUserIdList] = useState([])
    const [jobRecData, setJobRecData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [defaultList, setDefaultList] = useState(true)
    const [workerDetails, setWorkerDetails] = useState({})
    const [filterUrl, setFilterUrl] = useState('')
    const [sortUrl, setSortUrl] = useState('')
    const [filters, setFilters] = useState({
        averageRating: 0,
        actualScore: 0,
        cutoffScore: 0,
        expInYears: 0,
        totalGigs: 0,
        userName: '',
        phoneNumber: '',
        gender:""
    })
    const [matchingCriteria, setMatchingCriteria] = useState({
        cut_off_availability: true,
        cut_off_breach: true,
        cut_off_distance: true,
        cut_off_exp: true,
        cut_off_gender: true,
        cut_off_qual: true,
        cut_off_negative_rating: false,
    })
    const handleWorkerDetail=(row) =>{
        setWorkerDetails({
            userId:row.row.original.userId,
            workerId:row.row.original.workerId,
            jobDetailsId: row.row.original.jobDetailsId
        })
        setTimeout(() => {
            setOpenWorkerModal(true);
        }, 300);
    }
    const columns = useMemo(
        () => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        {
            Header: "No.",
            accessor: "no",
            defaultCanFilter :false
        },
        {
            Header: "Worker Id",
            accessor: "workerId",
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Name",
            accessor: "userName",
            Cell: row => {
                return <button className="linkButton" onClick={()=>handleWorkerDetail(row)}>{row.row.original.userName}</button>},
            defaultCanFilter :true,
            Filter: InputTextFilter ,
            defaultCanSort:true
        },
        {
            Header: "Phone Number",
            accessor: "phoneNumber",
            minWidth:130,
            defaultCanFilter :true,
            Filter: InputTextFilter,
            defaultCanSort:true
        },
        {
            Header: "Last Activity (in Days)",
            accessor: "lastActivity",
            minWidth:120,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Cutoff score",
            accessor: "cutoffScore",
            minWidth:120,
            defaultCanFilter :true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Actual score",
            accessor: "actualScore",
            minWidth:120,
            defaultCanFilter :true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Last seen distance",
            accessor: "lastSeenDistance",
            minWidth:170,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Distance captured on",
            accessor: "distanceCapturedOn",
            minWidth:200,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Travel mode",
            accessor: "travelMode",
            minWidth:120,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Last job experience",
            accessor: "lastJobExp",
            minWidth: 350,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Experience In Years",
            accessor: "expInYears",
            minWidth:170,
            defaultCanFilter :true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Average rating",
            accessor: "averageRating",
            minWidth:150,
            defaultCanFilter :true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Total gigs",
            accessor: "totalGigs",
            defaultCanFilter :true,
            Filter: SliderFilter,
            defaultCanSort:true
        },
        {
            Header: "Breach",
            accessor: "breach",
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Breach cutoff",
            accessor: "breachCutOff",
            minWidth:120,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Availability cutoff",
            accessor: "availabilityCutOff",
            minWidth:180,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Distance cutoff",
            accessor: "distanceCutOff",
            minWidth:150,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Gender cutoff",
            accessor: "genderCutOff",
            minWidth:150,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Experience cutoff",
            accessor: "expCutOff",
            minWidth:160,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Qaulification cutoff",
            accessor: "qualCutOff",
            minWidth: 170,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Negative rating cutoff",
            accessor: "negativeRatingCutOff",
            minWidth:190,
            defaultCanFilter :false,
            defaultCanSort:true
        },
        {
            Header: "Address",
            accessor: "address",
            minWidth:750,
            defaultCanFilter :false
        }
        ],
        []
    );
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: {selectedRowIds, sortBy}} = useTable({columns, data, filters, setFilters, manualSortBy: true }, useFilters, useSortBy, useRowSelect)
    const ApplyFilter =() =>{
        let url='';
        if(filters["averageRating"])
            url=`${url}&averageRating=${filters["averageRating"]}`
        if(filters["actualScore"])
            url=`${url}&actualScore=${filters["actualScore"]}`
        if(filters["cutoffScore"])
            url=`${url}&cutoffScore=${filters["cutoffScore"]}`
        if(filters["expInYears"])
            url=`${url}&expInYrs=${filters["expInYears"]}`
        if(filters["totalGigs"])
            url=`${url}&totalGigs=${filters["totalGigs"]}`
        if(filters["userName"])
            url=`${url}&name=${filters["userName"]}`
        if(filters["phoneNumber"])
            url=`${url}&number=${filters["phoneNumber"]}`
        if(filters["gender"])
            url=`${url}&_gender=${filters["gender"]}`    
        setFilterUrl(url)
    }
    useEffect(() => {
        ApplyFilter();
    }, [filters])

    const resetFilters=() =>{
        setFilters({
            averageRating: 0,
            actualScore: 0,
            cutoffScore: 0,
            expInYears: 0,
            totalGigs: 0,
            userName: '',
            phoneNumber: '',
            gender:""
        })
    }
    const ApplySort = () =>{
        let url=''
        if(sortBy.length>0){
            switch (sortBy[0].id) {
                case "workerId":
                    url='&orderBy=wm.worker_id'
                    break;
                case "userName":
                    url='&orderBy=um.first_name'
                    break;
                case "phoneNumber":
                    url='&orderBy=um.phone_number'
                    break;
                case "lastActivity":
                    url='&orderBy=last_activity'
                    break;
                case "actualScore":
                    url='&orderBy=actual_score'
                    break;
                case "cutoffScore":
                    url='&orderBy=cutoff_score'
                    break;
                case "lastSeenDistance":
                    url='&orderBy=user_last_seen_distance'
                    break;
                case "distanceCapturedOn":
                    url='&orderBy=cs.distance_captured_on'
                    break;
                case "travelMode":
                    url='&orderBy=travel_mode'
                    break;
                case "lastJobExp":
                    url='&orderBy=last_job_exp'
                    break;
                case "expInYears":
                    url='&orderBy=exp_in_yrs'
                    break;
                case "averageRating":
                    url='&orderBy=wm.average_rating'
                    break;
                case "totalGigs":
                    url='&orderBy=wm.total_gigs'
                    break;
                case "breach":
                    url='&orderBy=wm.breach'
                    break;
                case "breachCutOff":
                    url='&orderBy=breach_cut_off'
                    break;
                case "availabilityCutOff":
                    url='&orderBy=availability_cut_off'
                    break;
                case "distanceCutOff":
                    url='&orderBy=distance_cut_off'
                    break;
                case "genderCutOff":
                    url='&orderBy=gender_cut_off'
                    break;
                case "expCutOff":
                    url='&orderBy=exp_cut_off'
                    break;
                case "qualCutOff":
                    url='&orderBy=qual_cut_off'
                    break;
                case "negativeRatingCutOff":
                    url='&orderBy=negative_rating_cut_off'
                    break;
                default:
                    url=''
                    break;
            }
            if(!sortBy[0].desc)
                url=`${url}&sortBy=ascending`
            setSortUrl(url)
        }
        else
            setSortUrl('')
    }
    useEffect(() => {
        ApplySort();
    }, [sortBy])
    
    const matchCuttOff = (cuttOff) =>{
        const cuttOffNotations = {
            '-1': 'Disabled',
            '0': 'Failed',
            '1': 'Passed'
        };
        return cuttOffNotations[cuttOff];
    }
    const showToast = (type, message) =>{
        if(type==="success")
            return toast.success(message);
        else if(type==="error")
            return toast.error(message);
    }
    const idToUserId =() =>{
        console.log({selectedRowIds});
        const indexes= Object.keys(selectedRowIds)
        let idList=[]
        indexes.map(id => {
            idList.push(data[id].userId)
        })
        
        console.log(idList.toString())
        return idList;
    }
    useEffect(() => {
        if(openAssignRecruiterJobModal)
            setUserIdList(idToUserId())
    }, [openAssignRecruiterJobModal])
    
    const makeData= (response) =>{
        let extractedData = [];
        let index= response.pageable.offset+1;
        response.content.map(el => {        
            extractedData.push({
                no: index++,
                userName: el.userName === null ? '-' : el.userName,
                workerId: el.workerId === null ? '-' : el.workerId,
                phoneNumber: el.phoneNumber,
                lastActivity: el.lastActivity === null ? '-' : el.lastActivity,
                address: el.address === null ? '-' : el.address,
                currentLocation: el.currentLocation === null ? '-' : el.currentLocation,
                distanceCapturedOn: el.distanceCapturedOn === null ? '-' : el.distanceCapturedOn,
                lastSeenDistance: el.lastSeenDistance === null ? '-' : el.lastSeenDistance < 2500 ? el.lastSeenDistance : 'Not Captured',
                userId: el.id.userId,
                travelMode: el.travelMode === null ? '-' : el.travelMode,
                availability: el.availability === null ? '-' : el.availability,
                industry: el.industry === null ? '-' : el.industry,
                lastJobExp: el.lastJobExp === null ? '-' : el.lastJobExp,
                expInYears: el.expInYears === null ? '-' : el.expInYears,
                averageRating: el.averageRating === null ? '-' : el.averageRating,
                totalGigs: el.totalGigs === null ? '-' : el.totalGigs,
                breach: el.breach === null ? '-' : el.breach,
                cutoffScore: el.cutoffScore === null ? '-' : el.cutoffScore,
                actualScore: el.actualScore === null ? '-' : el.actualScore,
                availabilityCutOff: el.availabilityCutOff === null ? '-': matchCuttOff(el.availabilityCutOff),
                breachCutOff: el.breachCutOff === null ? '-' : matchCuttOff(el.breachCutOff),
                distanceCutOff: el.distanceCutOff === null ? '-' : matchCuttOff(el.distanceCutOff),
                expCutOff: el.expCutOff === null ? '-' : matchCuttOff(el.expCutOff),
                genderCutOff: el.genderCutOff === null ? '-' : matchCuttOff(el.genderCutOff),
                qualCutOff:  el.qualCutOff === null ? '-' : matchCuttOff(el.qualCutOff),
                negativeRatingCutOff: el.negativeRatingCutOff === null
                    ? '-': matchCuttOff(el.negativeRatingCutOff)
            });
            
        });
        return extractedData;
    }
    const getForcedMatchingWorkersList =(rows, page) =>{  
        setDefaultList(false);  
        Axios.get(`${urlLinks.baseUrl}/job/assigner/matched?job_details_id=${props.data.jobDetailsId}&match_type=2&page_no=${page-1}&rows=${rows}${filterUrl}${sortUrl}`)
            .then(response =>{
                if(response){
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    if(currentPage>totalPages)
                        setCurrentPage(totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
                else{
                    throw toast.error(`Forced worker list not loaded`);
                }
            })
            .catch(error =>{
                setLoading(false)
                toast.error(`Forced worker list not loaded`);
            })
    }

    const getDefaultMatchingWorkersList =(rows, page) =>{    
        Axios.get(`${urlLinks.baseUrl}/job/assigner/matched?job_details_id=${props.data.jobDetailsId}&match_type=1&page_no=${page-1}&rows=${rows}${filterUrl}${sortUrl}`)
            .then(response =>{
                if(response){
                    if(response.data.response.content.length>0){
                        setTotalRows(response.data.response.totalElements)
                        setTotalPages(response.data.response.totalPages)
                        if(currentPage>totalPages)
                            setCurrentPage(totalPages)
                        setData(makeData(response.data.response))
                        setDefaultList(true);
                    }
                    else{
                        getForcedMatchingWorkersList(rows, page)
                    }
                    setLoading(false)
                }
            })
            .catch(error =>{
                toast.error(`Default worker list not loaded`);
                setLoading(false)
            })
    }

    const getAlgoBasedWorkerList =() =>{
        setLoading(true)
        const url = `${urlLinks.baseUrl}/job/assigner/run/job?cut_off_availability=${matchingCriteria.cut_off_availability ? 1 : 0
            }&cut_off_breach=${matchingCriteria.cut_off_breach ? 1 : 0}&cut_off_distance=${
            matchingCriteria.cut_off_distance ? 1 : 0
            }&cut_off_exp=${matchingCriteria.cut_off_exp ? 1 : 0}&cut_off_gender=${
            matchingCriteria.cut_off_gender ? 1 : 0
            }&cut_off_qual=${matchingCriteria.cut_off_qual ? 1 : 0}&cut_off_negative_rating=${
            matchingCriteria.cut_off_negative_rating ? 1 : 0
            }&job_detail_id=${props.data.jobDetailsId}`;
        fetch(url)
            .then(response =>{
                toast.success(`Algorithm ran Successfully`);
                getForcedMatchingWorkersList(perPage, 1);
                setCurrentPage(1)
            })
            .catch(error =>{
                toast.error(`Algorithm ran Failed`);
            })
    }
    const handlejobAssign =() =>{
        const recId=jobRecData.recruiterId?`&recruiter_id=${jobRecData.recruiterId}`:''
        Axios.post(`${urlLinks.baseUrl}extWorker/assignWorkerToRecruiter?job_id=${jobRecData.jobId}${recId}&user_ids=${userIdList}`)
            .then(response => {
                if (response.data.code === 1000) {
                    showToast("success", "Worker assigned to job successfully");
                } 
                else {
                    showToast("error","Worker assigned to job successfully") 
                }
            })
            .catch(error => {
                showToast("error",error.message) 
            })
    }
    
    useEffect(() => {
        setLoading(true);
        getDefaultMatchingWorkersList(perPage,1);
    }, [filterUrl, sortUrl]);

    const handlePageChange = page => {
        setLoading(true)
        getDefaultMatchingWorkersList(perPage, page)
        setCurrentPage(page);
    };
    
    const handlePerRowsChange =  (newPerPage) => {
        setLoading(true)
        getDefaultMatchingWorkersList(newPerPage, 1)
        setPerPage(newPerPage);
        setCurrentPage(1);
    };
    const getUserIdFromIndex =() =>{
        const users= data.filter((_, i) => Object.keys(selectedRowIds).map(x => parseInt(x, 10)).includes(i));
        const userId= users.map(u => {return u.userId})
        return userId;
    }
    const assignWorkers =() =>{
        setLoading(true);
        const idList= getUserIdFromIndex();
        Axios.post(
        `${
          urlLinks.baseUrl
        }/job/assigner/assign?requested_by=${localStorage.getItem('userId')}&job_detail_id=${props.data.jobDetailsId}&user_ids=${idList}`
      )
        .then(response => {
          toast.success(`Worker Assigned Successfully`);
          setFilterUrl('')
          if (defaultList) {
            getDefaultMatchingWorkersList(perPage, currentPage);
          } else {
            getForcedMatchingWorkersList(perPage, currentPage);
          }
        })
        .catch(error => {
            setLoading(false);
            toast.error(`Error Assigning Worker`)
        });
    }   
    
    
    return (
        <Styles>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                autoClose={2000}
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            ></ToastContainer>
            {openWorkerModal?<WorkerModal
                data={workerDetails}
                openWorkerModal={openWorkerModal}
                setOpenWorkerModal={setOpenWorkerModal}
                isWorkerHistory={false}
                interviewDashboardData= {{id: props.data.jobDetailsId, currenttab: props.currenttab, perPage: perPage, currentPage: currentPage, filters: filters}}
            />: null}
            {openAssignRecruiterJobModal && <AssignVacancyThroughRecruiter
                openAssignRecruiterJobModal={openAssignRecruiterJobModal}
                setOpenAssignRecruiterJobModal ={setOpenAssignRecruiterJobModal}
                handlejobAssign ={handlejobAssign}
                setJobRecData={setJobRecData}
                jobRecData = {jobRecData}
            />}
            <Grid container>
                <Grid item xs={12} style={{padding:"20px 0"}}>
                    <PopupState variant='popover' popupId='demo-popup-popover'>
                        {popupState => (
                            <React.Fragment>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{backgroundColor: "#1c75bc"}}
                                    {...bindTrigger(popupState)}
                                >
                                    Matching Criteria
                                </Button>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                    }}
                                >
                                    <Box p={2}>
                                        Availability :
                                        <PurpleSwitch
                                            checked={matchingCriteria.cut_off_availability}
                                            onChange={() => setMatchingCriteria({...matchingCriteria, cut_off_availability: !matchingCriteria.cut_off_availability})}   
                                        />
                                        Breach :
                                        <PurpleSwitch
                                            checked={matchingCriteria.cut_off_breach}
                                            onChange={() => setMatchingCriteria({...matchingCriteria, cut_off_breach: !matchingCriteria.cut_off_breach})}   
                                        />
                                        Distance :
                                        <PurpleSwitch
                                            checked={matchingCriteria.cut_off_distance}
                                            onChange={() => setMatchingCriteria({...matchingCriteria, cut_off_distance: !matchingCriteria.cut_off_distance})}
                                        />
                                        Experience CuttOff :
                                        <PurpleSwitch
                                            checked={matchingCriteria.cut_off_exp}
                                            onChange={() => setMatchingCriteria({...matchingCriteria, cut_off_exp: !matchingCriteria.cut_off_exp})}
                                        />
                                        Gender CuttOff :
                                        <PurpleSwitch
                                            checked={matchingCriteria.cut_off_gender}
                                            onChange={() => setMatchingCriteria({...matchingCriteria, cut_off_gender: !matchingCriteria.cut_off_gender})}
                                        />
                                        Qualification CuttOff :
                                        <PurpleSwitch
                                            checked={matchingCriteria.cut_off_qual}
                                            onChange={() => setMatchingCriteria({...matchingCriteria, cut_off_qual: !matchingCriteria.cut_off_qual})}
                                        />
                                        Negative Rating :
                                        <PurpleSwitch
                                            checked={matchingCriteria.cut_off_negative_rating}
                                            onChange={() => setMatchingCriteria({...matchingCriteria, cut_off_negative_rating: !matchingCriteria.cut_off_negative_rating})}
                                        />
                                    </Box>
                                </Popover>
                            </React.Fragment>
                        )}
                    </PopupState>
                    <Button
                    variant='contained'
                    style={{ width: '90px', marginLeft: '10px', fontSize: '0.8rem', backgroundColor: "#1c75bc" }}
                    color='primary'
                    onClick={getAlgoBasedWorkerList}
                    >
                    Run
                    </Button>
                </Grid>
                <Grid item xs={12} style={{padding:"20px 0"}}>
                    <Button
                        variant='contained'
                        onClick={assignWorkers}
                        disabled={Object.keys(selectedRowIds).length===0}
                        style={{marginRight:"10px", backgroundColor: "#1c75bc", color: 'white' }}
                    >
                        ASSIGN WORKERS
                    </Button>

                    <Button
                        variant='contained'
                        style={{backgroundColor: "#1c75bc", color:"white" }}
                    >
                        <CSVLink
                        data={data}
                        filename={`MatchedWorker.csv`}
                        className='btn btn-primary'
                        target='_blank'
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            fontWeight: '500',
                        }}
                        >
                        Export To Excel
                        </CSVLink>
                    </Button>
                    <Button
                        variant="contained"
                        className="dataButton"
                        style={{marginLeft: "25px"}}
                        disabled={Object.keys(selectedRowIds).length===0}
                        onClick={() => setOpenAssignRecruiterJobModal(true)}
                    >
                        Assign to Vacancy
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={ApplyFilter}
                        style={{float: "right", marginLeft: "20px"}}
                        >Apply
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={resetFilters}
                        style={{float: "right"}}
                        >RESET FILTERS
                    </Button>
                    
                </Grid>
                <Grid item xs={12}  style={{padding:0}} >
                    <div className="tableContainer">
                        <table {...getTableProps()}  className="table">
                            <thead className="thead">
                                {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (  
                                    <th {...column.getHeaderProps()} 
                                        className={column.render('Header')==="Worker Id"?"stickWorkerIdnewMatch zIndex1080 header":
                                            column.render('Header')==="Name"?"stickWorkerNameMatchNew zIndex1080 header":
                                                column.id==="selection"?"stickCheckbox zIndex1080 header":"header"}>
                                                <span {...column.getSortByToggleProps()}>
                                                    {column.id !== 'selection' ? 
                                                    <div>
                                                        {column.render('Header')}
                                                        <TableSortLabel
                                                            active={column.isSorted}
                                                            // react-table has a unsorted state which is not treated here
                                                            direction={column.isSortedDesc ? 'asc' : 'desc'}
                                                        />
                                                    </div> : column.render('Header')}
                                                </span>
                                                <div>{column.defaultCanFilter ? column.render('Filter') : null}</div></th>
                                    ))}
                                </tr>
                                ))}
                            </thead>
                                
                            
                            {
                                loading?
                                    <div style={{margin:"auto", position:"sticky", left:"45%", top: "30%"}}>
                                    <ScaleLoader
                                        height={70}
                                        width={10}
                                        color={"#1c75bc"}    
                                    />
                                    </div>:
                                    <tbody {...getTableBodyProps()}> 
                                        {rows.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="row">
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}  
                                                    className={cell.column.Header==="Worker Id"?"stickWorkerIdnewMatch":
                                                    cell.column.Header==="Name"?"stickWorkerNameMatchNew":
                                                        cell.column.id==="selection"?"stickCheckbox":"cell"}>{cell.render('Cell')}</td>
                                            })}
                                            </tr>
                                        )
                                        })}
                                    </tbody>
                            }
                        </table>
                    </div>
                    <Pagination 
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid> 
            </Grid>
        </Styles>
    )
}
export default MatchedWorkers;