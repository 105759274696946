import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import ProfileImages from './assets/profile/default_profile.png';
import TelephoneIcon from './assets/profile/telephone.png';
import LocationIcon from './assets/profile/location.png';
import MailIcon from './assets/profile/mail.png';
import IcardIcon from './assets/profile/id-card.png';
import CreditCardIcon from './assets/profile/credit-card.png';
import CakeOutlined from './assets/profile/noun_Birthday_975694.png';
import LinkedIn from './assets/profile/noun_linkedin_2386251.png';
import DocumentsIcon from './assets/profile/document.png';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import "./Profile.css";
import Axios from 'axios';
import urlLinks from './urlLinks'


const useStyles = makeStyles((theme) => ({

    heading: {
        fontSize: theme.typography.pxToRem(24),
        fontFamily: "Open Sans",
        fontWeight: 600,
        lineHeight: 0.96,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#455a64"
    },
    textLabel: {
        fontSize: 18,
        lineHeight: 1.28,
        fontFamily: "Open Sans",
        fontWeight: "bold",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#455a64"
    },
    iconLabel: {
        padding: 5
    },
    textCenter: {
        margin: "0 auto"
    },
    textDescription: {
        fontSize: 18,
        lineHeight: 1.28,
        fontFamily: "Open Sans",
        fontWeight: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#455a64"
    },
    tips: {
        fontFamily: "Open Sans",
        fontSize: 14,
        fontWeight: "normal",
        lineHeight: 1.64,
        textAlign: "left",
        color: "#707070"
    }, paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }, mainContent: {
        fontFamily: 'Open Sans',
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: 1.08,
        textAlign: 'left',
        color: '#1c75bc',
        padding: '0 2%',
    }
}));

const WorkSummary = (props) => {
    const classes = useStyles();
    return <>
        <AccordionDetails>
            <Grid item xs={12}   >
                <Grid item xs={props.gridSize ? props.gridSize : 6} className="padding0 field-content" >
                    {props.labelIcon && <span className={classes.iconLabel}>{props.labelIcon}</span>}
                    <span className={classes.textLabel}>{props.label}</span>
                </Grid>
                <Grid item xs={props.gridSize ? props.gridSize : 6} className={'form-group field-content'} >
                    <Typography className={classes.textDescription}>{props.description}</Typography>
                </Grid>
            </Grid>
        </AccordionDetails>
    </>;
};
const ProfileSummary = (props) => {
    const classes = useStyles();
    return <>
        <Grid item xs={12} className='field-content profile-info'  >
            <span className={classes.textLabel}>{props.label}</span>
            <span className={classes.textDescription}>{props.description}</span>
        </Grid>

    </>;
};


const Profile = () => {
    const classes = useStyles();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [languageMap, setLanguageMap] = useState();
    const [qualificationMap, setQualificationMap] = useState();
    const [jobFunctionsMap, setJobFunctionsMap] = useState();
    const [industryExperienceMap, setIndustryExperienceMap] = useState();
    const [profileImage, setProfileImage] = useState(ProfileImages);
    const [pdfFile, setPdfFile] = useState();
    const [spinner, setSpinner] = useState(true);

    const [resumeName, setResumeName] = useState({
        b64EncodedDocument: '',
        documentId: null,
        documentName: '',
        // documentTypeId: 1851
    });
    const [panCard, setPanCard] = useState({
        b64EncodedDocument: '',
        documentId: null,
        documentName: '',
        // documentTypeId: 1862
    });
    const [bankProof, setBankProof] = useState({
        b64EncodedDocument: '',
        documentId: null,
        documentName: '',
        // documentTypeId: 1936
    });
    const [aadharCard, setAadharCard] = useState({
        b64EncodedDocument: '',
        documentId: null,
        documentName: '',
        // documentTypeId: 1856
    });
    const [panCardPath, setPanCardPath] = useState();
    const [bankProofPath, setBankProofPath] = useState();
    const [aadharCardPath, setAadharCardPath] = useState();
    let formInfo = '';
    const [formInfoRes, setFormInfoRes] = useState({});

    const [documentTypeList, setDocumentTypeList] = useState()

    const createDocumentList = (documentList) => {
        let list = {}
        documentList.map(document => {
            switch (document.typeValue) {
                case "cv":
                    list.cv = document.id;
                    break;
                case "Pan Card":
                    list["Pan Card"] = document.id;
                    break;
                case "Profile Photo":
                    list["Profile Photo"] = document.id;
                    break;
                case "Aadhar Card":
                    list["Aadhar Card"] = document.id;
                    break;
                case "Bank Proof":
                    list["Bank Proof"] = document.id;
                    break;

                default:
                    break;
            }
            return true;
        })
        setDocumentTypeList(list);
    }
    useEffect(() => {
        Axios.get(`${urlLinks.baseUrl}/configmaster/getAllDocuments`)
            .then(response => {
                if (response) {
                    console.log({ response })
                    createDocumentList(response.data.response)
                    // makeDocumentList(response.data.response)
                }
            })
            .catch(error => {
                // showToast("error", "Error loading next record")
            })

    }, [])

    const [formsInfo, setFormInfo] = useState({
        recruiterMasterId: '',
        userId: '',
        isConsent: 1,
        recruiterDocuments: []
    });
    console.log("IDD", aadharCard)

    useEffect(() => {

        formInfo = JSON.parse(localStorage.getItem('userInfo'));
    
        setFormInfo(() => {
            return {
                recruiterMasterId: (formInfo) ? formInfo.recruiterMasterId : '',
                userId: formInfo.userId,
                isConsent: 1,
                recruiterDocuments: []
            }
        });
    }, [])

    function setStorageItem(emailId) {
        console.log("New Flow")
        Axios.get(`${urlLinks.baseUrl}/recruiter/getRecruiter?email=` + emailId).then(response => {
            localStorage.setItem('userInfo', JSON.stringify(response.data.response.content[0]));
            formInfo = response.data.response.content[0];
            console.log(formInfo.documents.length )
            if (formInfo.documents.length > 0) {
                formInfo.documents.map((files, index) => {
                    console.log(files.document_type)
                    switch (files.document_type) {
                        case "Profile Photo":
                            setProfileImage(files.file_path);
                            break;
                        case "cv":
                            setPdfFile(files.file_path);
                            setResumeName((preVal) => {
                                return {
                                    ...preVal,
                                    documentId: files.document_id,
                                    documentName: files.documentName,
                                    documentTypeId: files.document_type_id
                                }
                            })
                            break;
                        case "Aadhar Card":
                            setAadharCardPath(files.file_path);
                            console.log("HERE TOO")
                            setAadharCard((preVal) => {
                                return {
                                    ...preVal,
                                    documentId: files.document_id,
                                    documentName: files.documentName,
                                    documentTypeId: files.document_type_id
                                }
                            })
                            break;
                        case "Pan Card":
                            setPanCardPath(files.file_path);
                            setPanCard((preVal) => {
                                return {
                                    ...preVal,
                                    documentId: files.document_id,
                                    documentName: files.documentName,
                                    documentTypeId: files.document_type_id
                                }
                            })
                            break;
                        case "Bank Proof":
                            setBankProofPath(files.file_path);
                            setBankProof((preVal) => {
                                return {
                                    ...preVal,
                                    documentId: files.document_id,
                                    documentName: files.documentName,
                                    documentTypeId: files.document_type_id
                                }
                            })
                            break;
                        default:
                            break;
                    }
                    return true;
                })

            }
            setFormInfo(() => {
                return {
                    recruiterMasterId: (formInfo) ? formInfo.recruiterMasterId : '',
                    userId: formInfo.userId,
                    isConsent: 1,
                    recruiterDocuments: []
                }
            });
            setLanguageMap((formInfo.languageMap) ? Object.values(formInfo.languageMap).join(", ") : '');
            setQualificationMap((formInfo.qualificationMap) ? Object.values(formInfo.qualificationMap).join(", ") : '');
            setJobFunctionsMap((formInfo.jobFunctionsMap) ? Object.values(formInfo.jobFunctionsMap).join(", ") : '');
            setIndustryExperienceMap((formInfo.industryExperienceMap) ? Object.values(formInfo.industryExperienceMap).join(", ") : '');
        }).catch(err => {
            console.log('notLogged');
        });

    }
    useEffect(() => {

        setTimeout(() => setSpinner(false), 1000)

        if (localStorage.getItem('userInfo') == 'undefined' || localStorage.getItem('userInfo') == null) {
            history.push("/login");
        } else {

            const UserInfoRes = JSON.parse(localStorage.getItem('userInfo'));


            setLanguageMap((UserInfoRes.languageMap) ? Object.values(UserInfoRes.languageMap).join(", ") : '');
            setQualificationMap((UserInfoRes.qualificationMap) ? Object.values(UserInfoRes.qualificationMap).join(", ") : '');
            setJobFunctionsMap((UserInfoRes.jobFunctionsMap) ? Object.values(UserInfoRes.jobFunctionsMap).join(", ") : '');
            setIndustryExperienceMap((UserInfoRes.industryExperienceMap) ? Object.values(UserInfoRes.industryExperienceMap).join(", ") : '');
            setStorageItem(UserInfoRes.emailId);
            setFormInfoRes(UserInfoRes);
        }

    }, [])


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const setDocumentFileInput = async (file, documentTypeId, documentId) => {
        setErrorMessage();
        setIsError(false);
        const base64 = await convertBase64(file);
        const fileName = file.name;
        let file_size = file.size / 1024 / 1024;
        if (file_size > 10) {
            setErrorMessage("File of size upto 10 MB is allowed.");
            return
        }
        let allowedExtensions = /(\.png|\.jpeg|\.jpg|\.pdf)$/i;
        if (!allowedExtensions.exec(fileName)) {
            setErrorMessage(`Only image and pdf files are allowed!`);
            return
        }
        setSpinner(true)

        const docObj = {
            b64EncodedDocument: base64,
            documentId: documentId,
            documentName: fileName,
            documentTypeId: documentTypeId

        }
        if (documentTypeId === documentTypeList["Aadhar Card"]) {
            setAadharCard(docObj);
        } else if (documentTypeId === documentTypeList["Bank Proof"]) {
            setBankProof(docObj);
        } else {
            setPanCard(docObj);
        }
        if (docObj) formsInfo.recruiterDocuments.push(docObj);
  
        Axios.post(`${urlLinks.baseUrl}/recruiter/addUpdate`, formsInfo).then(data => {
            if (data.code) {
                setErrorMessage(data.response);
                setIsError(false);
                setSpinner(false)

            } else {
                data = data.data
                if (data[0].documents.length > 0) {
                    data[0].documents.map((files, index) => {
                        switch (files.document_type) {
                            case "Profile Photo":
                                setProfileImage(files.file_path);
                                break;
                            case "cv":
                                setPdfFile(files.file_path);
                                break;
                            case "Aadhar Card":
                                console.log("HERE")
                                setAadharCardPath(files.file_path);
                                break;
                            case "Pan Card":
                                setPanCardPath(files.file_path);
                                break;
                            case "Bank Proof":
                                setBankProofPath(files.file_path);
                                break;
                            default:
                                break;
                        }
                    })
                }
                const profileInfo = JSON.parse(localStorage.getItem('userInfo'));
                setStorageItem(profileInfo.emailId);
                setIsError(true);
                setErrorMessage("File Uploaded Successfully");
                setSpinner(false)

            }

        }).catch(err => {
            console.log(err);
            setErrorMessage("Somethig went wrong!");
            setIsError(false);
            setSpinner(false)

        });
    }

    const removeFileInput = async (documentId, documentType) => {
        setSpinner(true)
        Axios.post(`${urlLinks.baseUrl}/userdocs/removeDocument?document_id=${documentId}`, {})
            .then(response => {
                console.log({ documentType, documentTypeList })
                switch (documentType) {
                    case documentTypeList["Profile Photo"]:
                        setProfileImage();
                        break;
                    case documentTypeList["cv"]:
                        setPdfFile();
                        setResumeName((preVal) => {
                            return {
                                ...preVal,
                                documentId: '',
                                documentName: '',
                            }
                        })
                        break;
                    case documentTypeList["Aadhar Card"]:
                        setAadharCardPath();
                        setAadharCard((preVal) => {
                            return {
                                ...preVal,
                                documentId: '',
                                documentName: '',
                            }
                        })
                        break;
                    case documentTypeList["Pan Card"]:
                        setPanCardPath();
                        setPanCard((preVal) => {
                            return {
                                ...preVal,
                                documentId: '',
                                documentName: '',
                            }
                        })
                        break;
                    case documentTypeList["Bank Proof"]:
                        setBankProofPath();
                        setBankProof((preVal) => {
                            return {
                                ...preVal,
                                documentId: '',
                                documentName: '',
                            }
                        })
                        break;
                    default:
                        break;
                }
                const profileInfo = JSON.parse(localStorage.getItem('userInfo'));
                setStorageItem(profileInfo.emailId);
                setIsError(true);
                setErrorMessage("File Removed Successfully");
                setSpinner(false)
            })
            .catch(error => {
                setIsError(false);
                setErrorMessage("Something Went Wrong");
                setSpinner(false)
            });
    }

    return (<>

        <div className={classes.root + ' profile-content'}>
            <Grid container className="main-content">
                <Grid item xs={2} style={{ textAlign: "center" }}>
                    <img src={profileImage} alt="Profile" className="profile-image" />
                    <p className={"marginTop10"}></p>
                    <span className={`${classes.textCenter} ${classes.textLabel} user-name`}>{(formInfoRes) ? formInfoRes.firstName : ''} {(formInfoRes) ? formInfoRes.lastName : ''}</span>
                </Grid>
                <Grid item xs={10}>
                    <div className={`${classes.mainContent} field-content`}>Recruiter Profile</div>
                    <Grid item xs={5} className="myProfile-container">

                        <ProfileSummary label={<img src={TelephoneIcon} alt="tel" />} description={(formInfoRes) ? formInfoRes.phoneNumber : ''} />
                        <ProfileSummary label={<img src={LocationIcon} alt="location" />} description={(formInfoRes) ? formInfoRes.city : ''} />
                        <ProfileSummary label={<img src={CakeOutlined} alt="DOB" />} description={(formInfoRes) ? moment(formInfoRes.dateOfBirth).format("DD-MM-YYYY") : ''} />
                    </Grid>
                    <Grid item xs={6} className="myProfile-container">
                        <ProfileSummary label={<img src={MailIcon} alt="mail" />} description={(formInfoRes) ? formInfoRes.emailId : ''} />
                        <ProfileSummary label={<img src={LinkedIn} alt="linkedIn" />} description={(formInfoRes) ? formInfoRes.linkedinProfile : ''} />
                    </Grid>
                </Grid>
                <div className="devider"></div>
                <Grid item xs={6} >
                    <WorkSummary label="Languages known" description={languageMap} gridSize="12" />
                </Grid>
                <Grid item xs={6} >
                    <WorkSummary label="Resume/ CV" description={<a href={pdfFile} style={{ textDecoration: "underline" }} className={`{classes.textDescription} resume-CV linkButton`}>
                        {<img src={DocumentsIcon} alt="Documents Icon" className="vertical-align" />}{resumeName.documentName}
                    </a>} gridSize="12" />
                </Grid>
                <Grid item xs={6} >
                    <WorkSummary label="Highest education qualification" description={qualificationMap} gridSize="12" />
                </Grid>
                <Grid item xs={6} >
                    <WorkSummary label="Specialization" description={(formInfoRes) ? formInfoRes.specialisation : ''} gridSize="12" />
                </Grid>
            </Grid>

            <Grid container className="main-content profile-accordian">
                <Accordion className="" defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Work experience</Typography>
                    </AccordionSummary>
                    <WorkSummary label="Total work experience" description={`${(formInfoRes) ? formInfoRes.workExpYears : ''} years ${(formInfoRes) ? formInfoRes.workExpMonths : ''} months`} />
                    <WorkSummary label="Company last worked" description={(formInfoRes) ? formInfoRes.lastCompany : ''} />
                    <WorkSummary label="Freelance experience" description={`${(formInfoRes && formInfoRes.freelanceExpYears) ? formInfoRes.freelanceExpYears : '0'} years ${(formInfoRes && formInfoRes.freelanceExpMonths) ? formInfoRes.freelanceExpMonths : '0'} months`} />
                    <WorkSummary label="Industries experience in" description={industryExperienceMap} />
                    <WorkSummary label="Job functions recruited" description={jobFunctionsMap} />
                    <WorkSummary label="Work availability per week" description={`${(formInfoRes) ? formInfoRes.hourAvailablePerWeek : ''} hours`} />
                    <WorkSummary label="External recruiting platforms" description={(formInfoRes) ? formInfoRes.externalRecruitingPlatforms : ''} />

                </Accordion>
                <Accordion className="profile-document" defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Documents <div className={classes.tips}>(Your documents and information will be kept completely confidential)</div></Typography>
                    </AccordionSummary>
                    {errorMessage ?
                        <Grid item xs={12} className="padding0 " >
                            <Alert variant="filled" severity={isError ? "success" : "error"}>
                                {errorMessage}
                            </Alert></Grid> : ''}
                    <WorkSummary labelIcon={<img src={IcardIcon} alt="tel" />} label="Aadhar card" description={<>
                        {aadharCardPath && <a href={aadharCardPath} className={classes.textDescription}>
                            {<GetAppIcon className="vertical-align" />}
                        </a>}
                        <span style={{ fontSize: 18, color: '#bbbbbb' }} className="inline-text">{aadharCard.documentName ? <span class="selectedFile">{aadharCard.documentName}</span> : 'No file chosen'}</span>
                        <label htmlFor="aadharFile" >
                            <span style={{ fontSize: 16, fontWeight: "bold", color: "#1c75bc", textDecoration: "underline", cursor: "pointer" }} className="inline-text upload-file"  >{aadharCard.documentName ? 'Update file' : 'Upload file'}</span>
                        </label>
                        {aadharCard.documentName && <><Divider orientation="vertical" /><label htmlFor="removeCard" >
                            <span style={{ fontSize: 16, fontWeight: "bold", color: "#1c75bc", textDecoration: "underline", cursor: "pointer" }} className="inline-text upload-file" onClick={() => removeFileInput(aadharCard.documentId, documentTypeList["Aadhar Card"])} >Remove</span>
                        </label></>}
                    </>} gridSize="12" />

                    <WorkSummary label="Pan card" labelIcon={<img src={CreditCardIcon} alt='tel' />} description={<>
                        {panCardPath && <a href={panCardPath} className={classes.textDescription}>
                            {<GetAppIcon className="vertical-align" />}
                        </a>}
                        <span style={{ fontSize: 18, color: '#bbbbbb' }} className="inline-text">{panCard.documentName ? <span class="selectedFile">{panCard.documentName}</span> : 'No file chosen'}</span>
                        <label htmlFor="panCard" >
                            <span style={{ fontSize: 16, fontWeight: "bold", color: "#1c75bc", textDecoration: "underline", cursor: "pointer" }} className="inline-text upload-file"  >{panCard.documentName ? 'Update file' : 'Upload file'}</span>
                        </label>
                        {panCard.documentName && <> <Divider orientation="vertical" /><label htmlFor="removeCard" >
                            <span style={{ fontSize: 16, fontWeight: "bold", color: "#1c75bc", textDecoration: "underline", cursor: "pointer" }} className="inline-text upload-file" onClick={() => removeFileInput(panCard.documentId, documentTypeList["Pan Card"])} >Remove</span>
                        </label></>}


                    </>} gridSize="12" />

                    <WorkSummary label="Bank Proof" labelIcon={<img src={CreditCardIcon} alt='tel' />} description={<>
                        {bankProofPath && <a href={bankProofPath} className={classes.textDescription}>
                            {<GetAppIcon className="vertical-align" />}
                        </a>}
                        <span style={{ fontSize: 18, color: '#bbbbbb' }} className="inline-text">{bankProof.documentName ? <span class="selectedFile">{bankProof.documentName}</span> : 'No file chosen'}</span>
                        <label htmlFor="bankProof" >
                            <span style={{ fontSize: 16, fontWeight: "bold", color: "#1c75bc", textDecoration: "underline", cursor: "pointer" }} className="inline-text upload-file"  >{bankProof.documentName ? 'Update file' : 'Upload file'}</span>
                        </label>
                        {bankProof.documentName && <> <Divider orientation="vertical" /><label htmlFor="removeCard" >
                            <span style={{ fontSize: 16, fontWeight: "bold", color: "#1c75bc", textDecoration: "underline", cursor: "pointer" }} className="inline-text upload-file" onClick={() => removeFileInput(bankProof.documentId, documentTypeList["Bank Proof"])} >Remove</span>
                        </label></>}


                    </>} gridSize="12" />


                    <input
                        id="aadharFile"
                        style={{ display: 'none' }}
                        type={"file"}
                        onChange={(e) => setDocumentFileInput(e.target.files[0], documentTypeList["Aadhar Card"], aadharCard.documentId)}
                        accept="image/x-png,image/jpeg,.pdf"
                    />

                    <input
                        id="panCard"
                        style={{ display: 'none' }}
                        type={"file"}
                        onChange={(e) => setDocumentFileInput(e.target.files[0], documentTypeList["Pan Card"], panCard.documentId)}
                        accept="image/x-png,image/jpeg,.pdf"
                    />
                    <input
                        id="bankProof"
                        style={{ display: 'none' }}
                        type={"file"}
                        onChange={(e) => setDocumentFileInput(e.target.files[0], documentTypeList["Bank Proof"], bankProof.documentId)}
                        accept="image/x-png,image/jpeg,.pdf"
                    />
                </Accordion>
            </Grid>
        </div>
    </>
    );
}

export default Profile;