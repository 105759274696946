import React, {useState} from 'react'
import './upcomingInterviews.css'
import Axios from 'axios';
import {Chip, IconButton , Zoom, Select, MenuItem, Tooltip, Modal, Button, Backdrop, Slide, Grid, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import location from '../../../assets/icons8-marker-100 (1).png' 
import phone from '../../../assets/icons8-call-100.png' 
import clock from '../../../assets/icons8-clock-500 (1).png' 
import { withStyles, makeStyles} from '@material-ui/core/styles';
import * as moment from 'moment';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#525252',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
  },
  arrow:{
      backgroundColor: '#ffffff',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));


const UpcomingInterview=(props) =>{
    // console.log(props.data);
    const classes = useStyles();
    let interview= props.data;
    const extractDate = (date) =>{
        return moment(date).format("DD/MM/YYYY");
    }
    const extractTime = (date) =>{
        return moment(date).format("hh:mm a");
    }
    const interviewDetails={
        interviewDate: interview.slot===1?extractDate(interview.interviewStartTime):interview.slot===2?extractDate(interview.interviewStartTime2):interview.slot===3?extractDate(interview.interviewStartTime3): null ,
        startTime:interview.slot===1?extractTime(interview.interviewStartTime):interview.slot===2?extractTime(interview.interviewStartTime2):interview.slot===3?extractTime(interview.interviewStartTime3): null ,
        endTime:interview.slot===1?extractTime(interview.interviewEndTime):interview.slot===2?extractTime(interview.interviewEndTime2):interview.slot===3?extractTime(interview.interviewEndTime3): null ,
    }
    // console.log({interviewDetails});
    
    const [status, setStatus] = useState(props.data.status===null?'Interview status':props.data.status)
    const [tempstatus, setTempstatus] = useState("Interview status")
    const [openUpdateSlots, setOpenUpdateSlots] = useState(false)
    const [showButtons, setShowButtons] = useState(false)
    const [disableSelect, setDisableSelect] = useState(false)
    const [selectSlot, setSelectSlot] = useState('selectSlot')
    const [interviewData, setInterviewData] = useState({
        interviewSlot1:interview.interviewStartTime,
        interviewSlot1StartTime: interview.interviewStartTime,
        interviewSlot1EndTime: interview.interviewEndTime,
        interviewSlot2:interview.interviewStartTime2,
        interviewSlot2StartTime: interview.interviewStartTime2,
        interviewSlot2EndTime: interview.interviewEndTime2,
        interviewSlot3:interview.interviewStartTime3,
        interviewSlot3StartTime: interview.interviewStartTime3,
        interviewSlot3EndTime: interview.interviewEndTime3,
        updatearray:[]
    })
    // console.log(`${moment(interviewData.interviewSlot1).format("L")} ${moment(interviewData.interviewSlot1StartTime).format("LT")} - ${moment(interviewData.interviewSlot1EndTime).format("LT")}`);
    // console.log({interviewData});
    const [interviewSlot2StartTime, setInterviewSlot2StartTime] = useState(interview.interviewStartTime)
    const changeSelectSlot =(event) =>{
        setSelectSlot(event.target.value)
    }
    const updateInterviewData= (date, location) =>{
        setDisableSelect(true)

        if(location===1){
            setInterviewData({...interviewData, interviewSlot1: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 1]})
        }
        else if(location===2){
            setInterviewData({...interviewData, interviewSlot1StartTime: `${moment(interviewData.interviewSlot1).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 2]})
        }
        else if(location===3){
            setInterviewData({...interviewData, interviewSlot1EndTime: `${moment(interviewData.interviewSlot1).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 3]})
        }
        else if(location===4){
            setInterviewData({...interviewData, interviewSlot2: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 4]})
        }
        else if(location===5){
            setInterviewData({...interviewData, interviewSlot2StartTime: `${moment(interviewData.interviewSlot2).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 5]})
        }
        else if(location===6){
            setInterviewData({...interviewData, interviewSlot2EndTime: `${moment(interviewData.interviewSlot2).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 6]})
        }
        else if(location===7){
            setInterviewData({...interviewData, interviewSlot3: moment(date).format("YYYY-MM-DD HH:mm:ss"), updatearray:[...interviewData.updatearray, 7]})
        }
        else if(location===8){
            setInterviewData({...interviewData, interviewSlot3StartTime:`${moment(interviewData.interviewSlot3).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 8]})
        }
        else if(location===9){
            setInterviewData({...interviewData, interviewSlot3EndTime: `${moment(interviewData.interviewSlot3).format("YYYY-MM-DD")} ${moment(date).format("HH:mm:ss")}`, updatearray:[...interviewData.updatearray, 9]})
        }
    }
    const [confirmSlot, setConfirmSlot] = useState(4);
    const id= props.data.interviewId
    // console.log(tempstatus, props.data.interviewId);
    const updatedata={status, id, setStatus}
    const handleConfirm =() =>{
        props.updateStatus(updatedata, props.loc);
        setShowButtons(false);
    }
    
    const handleCancel =() =>{
        setStatus(tempstatus);
        setShowButtons(false);
    }

    const handleChange = event => {
        if(event.target.value==='RESCHEDULE'){
            setOpenUpdateSlots(true)
            setTempstatus(status)
            setStatus(event.target.value)
        }
        else{
            setShowButtons(true)
            setTempstatus(status)
            setStatus(event.target.value)
        }
    };
    const handleUpdateSlotClose =() =>{
        setOpenUpdateSlots(false)
        setStatus(tempstatus);
    }

    const handleUpdateSlot =() =>{
        let updateData={...interviewData, id }
        props.updateInteriewSlots(updateData, setDisableSelect)
        // console.log(updateData);
        
        
    }
    const handleSelectSlot =() =>{
        props.updateSelectedSlot(selectSlot, id, updatedata, props.loc)
        // props.updateStatus(updatedata);
        setOpenUpdateSlots(false)
    }
    let shortAddress=`${interview.companyName}, ${interview.companyCity}`
    let chipLabel=interview.jobType===1?'Full time':'Part time';
    console.log(moment(interviewData.interviewSlot1EndTime).format("HH:mm") < moment(interviewData.interviewSlot1StartTime).format("HH:mm") , interviewData.interviewSlot1EndTime, interviewData.interviewSlot1StartTime);
    
    return (
        <div className={props.cname}>
            <Modal
                className={classes.modal}
                open={openUpdateSlots}
                onClose={handleUpdateSlotClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Slide direction="up" in={openUpdateSlots} mountOnEnter unmountOnExit>
                    <div className={classes.paper}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="interviewSlot1"
                                    label="Slot 1 Data"
                                    format="dd-MM-yyyy"
                                    value={interviewData.interviewSlot1}
                                    onChange={date => updateInterviewData(date, 1)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    label="Slot 1 Start Time"
                                    value={interviewData.interviewSlot1StartTime}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'), 2)}
                                />
                                <KeyboardTimePicker
                                    label="Slot 1 End Time"
                                    value={interviewData.interviewSlot1EndTime}
                                    error={moment(interviewData.interviewSlot1EndTime).format("HH:mm") < moment(interviewData.interviewSlot1StartTime).format("HH:mm")}
                                    helperText={moment(interviewData.interviewSlot1EndTime).format("HH:mm") < moment(interviewData.interviewSlot1StartTime).format("HH:mm") && "Enter interview end time after start time"}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'), 3)}
                                />
                            </Grid>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="interviewSlot2"
                                    label="Slot 2 Data"
                                    format="dd-MM-yyyy"
                                    value={interviewData.interviewSlot2}
                                    onChange={date => updateInterviewData(date, 4)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    label="Slot 2 Start Time"
                                    value={interviewData.interviewSlot2StartTime}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'), 5)}
                                />
                                <KeyboardTimePicker
                                    label="Slot 2 End Time"
                                    value={interviewData.interviewSlot2EndTime}
                                    error={moment(interviewData.interviewSlot2EndTime).format("HH:mm") < moment(interviewData.interviewSlot2StartTime).format("HH:mm")}
                                    helperText={moment(interviewData.interviewSlot2EndTime).format("HH:mm") < moment(interviewData.interviewSlot2StartTime).format("HH:mm") && "Enter interview end time after start time"}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'), 6)}
                                />
                            </Grid>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="interviewSlot3"
                                    label="Slot 3 Data"
                                    format="dd-MM-yyyy"
                                    value={interviewData.interviewSlot3}
                                    onChange={date => updateInterviewData(date, 7)}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    
                                    label="Slot 3 Start Time"
                                    value={interviewData.interviewSlot3StartTime}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'), 8)}
                                />
                                <KeyboardTimePicker
                                    
                                    label="Slot 3 End Time"
                                    value={interviewData.interviewSlot3EndTime}
                                    error={moment(interviewData.interviewSlot3EndTime).format("HH:mm") < moment(interviewData.interviewSlot3StartTime).format("HH:mm")}
                                    helperText={moment(interviewData.interviewSlot3EndTime).format("HH:mm") < moment(interviewData.interviewSlot3StartTime).format("HH:mm") && "Enter interview end time after start time"}
                                    onChange={date => updateInterviewData(moment(date).add(30, 'minutes').startOf('hour'), 9)}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid container justify="space-around">
                            <Select
                                label="Select interview Slot"
                                id="selectSlot"
                                value={selectSlot}
                                variant='outlined'
                                onChange={changeSelectSlot}
                                classes={{root: classes.select}}
                                >
                                <MenuItem value="selectSlot" disabled>
                                    <em>Select Slot</em>
                                </MenuItem>
                                {interviewData.interviewSlot1!==null?
                                <MenuItem value={1}>{`${moment(interviewData.interviewSlot1).format("DD/MM/YYYY")} ${moment(interviewData.interviewSlot1StartTime).format("LT")} - ${moment(interviewData.interviewSlot1EndTime).format("LT")}`}</MenuItem>: null}
                                {interviewData.interviewSlot2!==null?
                                <MenuItem value={2}>{`${moment(interviewData.interviewSlot2).format("DD/MM/YYYY")} ${moment(interviewData.interviewSlot2StartTime).format("LT")} - ${moment(interviewData.interviewSlot2EndTime).format("LT")}`}</MenuItem>:null}
                                {interviewData.interviewSlot3!==null?
                                <MenuItem value={3}>{`${moment(interviewData.interviewSlot3).format("DD/MM/YYYY")} ${moment(interviewData.interviewSlot3StartTime).format("LT")} - ${moment(interviewData.interviewSlot3EndTime).format("LT")}`}</MenuItem>:null}
                            </Select>
                        </Grid>
                        <Button color="primary"  onClick={handleUpdateSlot} > Update Slots</Button>
                        <Button color="primary" disabled={disableSelect}  onClick={handleSelectSlot} > Change Slots</Button>
                        <Button color="secondary" onClick={handleUpdateSlotClose} >Cancel</Button>
                    </div>
                </Slide>
            </Modal>

            
            <div className="details">

                    <div className="NPT">
                        <h4>{interview.name} - {interview.profile}</h4> <Chip label={chipLabel} className='chipStyle' />
                    </div>  
            
                    <div className="address">
                        <img className="iconImg" src={location} alt='location' />
                        <HtmlTooltip
                            title={
                            <React.Fragment>
                                <div className="toolTipTitle">
                                    <span><b>{interview.companyName}</b></span>
                                    <span>{interview.contactName} - {interview.contactNumber}</span>
                                    <span>{interview.companyAddress}</span>
                                </div>
                            </React.Fragment>
                            } interactive  placement="bottom-start"
                                TransitionComponent={Zoom}  
                        > 
                            <span>{shortAddress}</span>
                        </HtmlTooltip>
                    </div>
                    <div className="time">
                        <img className="iconImg" src={clock} alt='location' /> {interviewDetails.interviewDate==moment(new Date()).format('L')?'Today':interviewDetails.interviewDate}, {interviewDetails.startTime} to {interviewDetails.endTime} slot
                    </div>
            </div>
            <div className="status">
                <div className="phoneNumber">
                    <img src={phone} alt='location' /><span className="candidateNumber"><a href={`tel:${interview.candidateNumber}`}>+91-{interview.candidateNumber}</a></span>
                </div>
                <div className="interviewStatus">
                    <Select
                        id="interviewStatus"
                        value={status}
                        variant='outlined'
                        onChange={handleChange}
                        classes={{root: classes.select}}
                        >
                        <MenuItem value="Interview status" disabled>
                            <em>Interview status</em>
                        </MenuItem>
                        <MenuItem value='GOING'>Going</MenuItem>
                        <MenuItem value='NOT_GOING'>Not going</MenuItem>
                        <MenuItem value='RESCHEDULE'>Reschedule</MenuItem>
                        <MenuItem value='NO_RESPONSE'>No Response</MenuItem>
                        <MenuItem value='APPEARED'>Appeared</MenuItem>
                    </Select>
                </div>
                {
                    showButtons &&
                    <div>
                        <IconButton color="primary" onClick={handleConfirm}>
                            <DoneSharpIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={handleCancel} >
                            <CloseSharpIcon />
                        </IconButton>
                    </div>
                }
            </div>
        </div>
    )
}

export default UpcomingInterview;