import React, {Fragment} from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  }
}));

export const StepButton = props => {
  const { step, values } = props;
  const classes = useStyles();
  const handleSave =() =>{
    localStorage.setItem('addjobform', JSON.stringify(values));
  }
  switch (step) {
    case 1:
      return (
        <Grid container className="stepButtons">
          <Grid xs={12}>
            <div className= "savenext">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                NEXT
              </Button>
             </div> 
          </Grid>
        </Grid>
      );
    case 2:
      return (
        <Grid container className="stepButtons"> 
          <Grid xs={6} >
            <Button
              variant="outlined"
              color="primary"
              onClick={props.handleBack}
              className={classes.button}
            >
              BACK
            </Button>
          </Grid>
          <Grid xs={6}>
            <div className= "savenext">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                NEXT
              </Button>
             </div> 
          </Grid>
        </Grid>
      );
    case 3:
      return (
        <Grid container className="stepButtons"> 
          <Grid xs={6} >
            <Button
              variant="outlined"
              color="primary"
              onClick={props.handleBack}
              className={classes.button}
            >
              BACK
            </Button>
          </Grid>
          <Grid xs={6}>
            <div className= "savenext">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                POST JOB
              </Button>
             </div> 
          </Grid>
        </Grid>
      );
    default:
      return <div></div>;
  }
};
