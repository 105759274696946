import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import CustomMultiSelectDropDown from '../CustomMultiselectDropDown/CustomMultiSelectDropDown';
import DatePicker from '../DatePicker/DatePicker';
import Select from '../simpleSelect/simpleSelect';
import TextField from '@material-ui/core/TextField';
const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '700px',
    minWidth: '300px'
  },
  gridItemStyle: {
    padding: '10px !important'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class CustomFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      averageRating:this.props.averageRating,
      actualScore:this.props.actualScore,
      cutoffScore:this.props.cutoffScore,
      expInYears:this.props.expInYears,
      totalGigs:this.props.totalGigs,
      open: this.props.isOpen,
      name:this.props.name,
      number:this.props.number,
      gender:this.props.gender,
      worktype:this.props.rset,
      name:this.props.name,
      number:this.props.number,
      gender:this.props.gender,
      anchorEl: <b></b>
      
    };

    this.gender=[
      {
        id:1,
        rating:26,
        label:'Male'
      },
      {
        id:2,
        rating:27,
        label:'Female'
      }
    ]
   
    this.resetFilters = this.resetFilters.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);

    this.gender=[
      {
        id:1,
        rating:26,
        label:'Male'
      },
      {
        id:2,
        rating:27,
        label:'Female'
      }
    ]
    
  }

  handleRequestClose(event) {
    this.setState({
      open: !this.state.open,
      anchorEl: event.currentTarget
    });
  }

  resetFilters() {
    this.setState(
      {
        averageRating:'',
        actualScore:'',
        cutoffScore:'',
        expInYears:'',
        totalGigs:'',
        name:'',
        number:'',
        gender:'',
        worktype: 1
      },
      () =>
        this.props.updateFilters(this.state.averageRating,this.state.actualScore,this.state.cutoffScore,this.state.expInYears, this.state.totalGigs,this.state.worktype,this.state.name,this.state.number,this.state.gender)
    );
  }



  handleExperienceChange1 = val => {
    this.setState({ cutoffScore: +val });
  };

  handleExperienceChange2 = val => {
    this.setState({ expInYears: +val });
  };

  handleExperienceChange3 = val => {
    this.setState({ averageRating: +val });
  };

  handleExperienceChange4 = val => {
    this.setState({ actualScore: +val });
  };

  handleExperienceChange5 = val => {
    this.setState({ totalGigs: +val });
  };

  handleExperienceChange6 = val => {
    this.setState({ number: val });
  };

  handleExperienceChange7 = val => {
    this.setState({ name: val });
  };

  handleSelectChange4 = val => {
    this.setState({ gender: val });
  };


  componentDidMount() {
    this.setState({
        averageRating:'',
        actualScore:'',
        cutoffScore:'',
        expInYears:'',
        totalGigs:'',
        name:'',
        number:'',
        gender:'',
        worktype: 0 
    });
  }

  render() {
    return (
      <PopupState variant='popover' popupId={'popover' + this.props.id}>
        {popupState => (
          <div>
            <Button 
              style={{ width: '90px', marginLeft: '0px', marginTop: '10px', fontSize: '0.8rem' }}
              variant='contained'
              color='primary'
              {...bindTrigger(popupState)}
              className={this.props.classes.buttonTextSize}
              onClick={this.handleRequestClose}
            >
              Filter
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                id: 2,
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.open}
              anchorEl={this.state.anchorEl}
            >
              <Grid
                container
                spacing={3}
                className={this.props.classes.gridStyle}
              >

                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Cut-off Score'
                    type='number'
                    value={this.state.cutoffScore}
                    onChange={event =>
                      this.handleExperienceChange1(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Experience'
                    type='number'
                    value={this.state.expInYears}
                    onChange={event =>
                      this.handleExperienceChange2(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Average Rating'
                    type='number'
                    value={this.state.averageRating}
                    onChange={event =>
                      this.handleExperienceChange3(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Actual Score'
                    type='number'
                    value={this.state.actualScore}
                    onChange={event =>
                      this.handleExperienceChange4(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Total Gigs'
                    type='number'
                    value={this.state.totalGigs}
                    onChange={event =>
                      this.handleExperienceChange5(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Number'
                    type='number'
                    value={this.state.number}
                    onChange={event =>
                      this.handleExperienceChange6(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='standard-number'
                    label='Name'
                    
                    value={this.state.name}
                    onChange={event =>
                      this.handleExperienceChange7(event.target.value)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Select
                    option={this.gender}
                    handleChange={this.handleSelectChange4}
                    ratingValue={this.state.gender}
                    label={'Gender'}
                  >
                  </Select>
                </Grid>
              </Grid>

              <Divider />
              <Grid container className={this.props.classes.gridStyle}>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.resetFilters(true);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.setState({ worktype:0 },()=>this.props.updateFilters(
                        this.state.averageRating,
                        this.state.actualScore,
                        this.state.cutoffScore,
                        this.state.expInYears, 
                        this.state.totalGigs,
                        this.state.worktype,
                        this.state.name,
                        this.state.number,
                        this.state.gender
                      ))
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Apply
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={event => {
                      this.handleRequestClose(event);
                    }}
                    className={this.props.classes.buttonTextSize}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>
        )}
      </PopupState>
    );
  }
}

export default withStyles(styles)(CustomFilter);
