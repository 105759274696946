import React, { createContext, Component } from 'react';
import { Auth } from 'aws-amplify'

export const AuthContext = createContext();

class AuthContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorised:
        localStorage.getItem('isAuthorised') === 'false' ||
        localStorage.getItem('isAuthorised') === null
          ? false
          : true,
      tokenId: '',
      accessTokenId: '',
      refreshToken: '',
      expiry: 0
    };
  }

  updateLoginState = async (response, allAdmins) => {
    await this.setState({ isAuthorised: !this.state.isAuthorised });
    await this.setState({
      accessTokenId: this.state.isAuthorised
        ? response.signInUserSession.accessToken.jwtToken
        : '',
      tokenId: this.state.isAuthorised
        ? response.signInUserSession.idToken.jwtToken
        : '',
      refreshToken: this.state.isAuthorised
      ? response.signInUserSession.refreshToken.token
      : '',
      expiry: this.state.isAuthorised
      ? response.signInUserSession.idToken.payload.exp
      : '',
    });
    localStorage.setItem('allAdmins', JSON.stringify(allAdmins));
    localStorage.setItem('refreshToken', this.state.refreshToken);
    localStorage.setItem('expiry', this.state.expiry);
    localStorage.setItem('isAuthorised', this.state.isAuthorised);
    localStorage.setItem('tokenid', this.state.tokenId);
    localStorage.setItem('accessTokenId', this.state.accessTokenId);
  };

  render() {
    return (
      <AuthContext.Provider
        value={{ ...this.state, updateLoginState: this.updateLoginState }}
      >
        {this.props.children}{' '}
        {/*this will pass state to all child component as prop */}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;
