import React, { useState, useEffect } from 'react'
import { Select, MenuItem, IconButton, Button} from '@material-ui/core';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import { Link } from "react-router-dom";
import DashboardRemarkModal from '../../../utils/Modals/dashboardRemarkModal'
import urlLinks from '../../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
import qs from 'querystring';



const EditableDropdownSubComponent =( props) =>{
    const {row, columnId, initialValue, updateMyData, showToast, setLoading, interviewAddress} = props
    let statusUpdateUrl=`${urlLinks.baseUrl}sales/updateStatus`
    const [value, setValue] = useState(initialValue);
    
    let label=""
    const [showButtons, setShowButtons] = useState(false)
    const onChange = e => {
        setShowButtons(true)
        setValue(e.target.value)
    }
    console.log({row})
    const updateStatus = () =>{
        setLoading(true)
        console.log("entered", value)

        const postData={
            amount: row.amount,
            updated_by: localStorage.getItem('userId'),
            interview_id: row.interviewId,
            job_id: row.jobId,
            assign_id: row.assignId,
            user_id: row.userId,
            job_details_id: row.jobDetailsId,
            field: "invoice_status",
            fieldStatus: value,
            
        }
        if(row.invoice_id)
            postData["invoice_id"]= row.invoice_id
        
        
        Axios.post(statusUpdateUrl, qs.stringify(postData))
            .then(response =>{
                 if (response.data.code === 1000) {
                    updateMyData()
                    setLoading(false);
                } else {
                    showToast(response.data.message);
                    setLoading(false);
                }
            })
        

    }
    const handleConfirm =() =>{
        setLoading(true)
        updateStatus();
    }
    const handleCancel =() =>{
        setValue(initialValue)
        setShowButtons(false)
    }
    
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    let dropdown=<div></div>
    if(columnId==="invoiceStatus")
        dropdown=<div className="index">
            <Select
                id="invoiceStatus"
                value={value}
                disableUnderline
                onChange={onChange}
                inputProps={{
                    classes: {
                        icon: "selectIcon",
                    },
                }}
                >
                <MenuItem value="invoiceStatus" disabled>
                    <em>Invoice status</em>
                </MenuItem>
                <MenuItem value='RAISED'>Raised</MenuItem>
                <MenuItem value='DEAD'>Dead</MenuItem>
                <MenuItem value='CANCELLED'>Cancelled</MenuItem>
                <MenuItem value='CREDITED'>Credited</MenuItem>
                <MenuItem value='TO_BE_RAISED'>To be raised</MenuItem>
                <MenuItem value='PENDING'>Pending</MenuItem>
            </Select>
            
            {showButtons &&
            <div>
                <IconButton color="primary" onClick={handleConfirm}>
                    <DoneSharpIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleCancel} >
                    <CloseSharpIcon />
                </IconButton>
            </div>}
            {row.callStatusDate && 
                <div>
                    <span>{row.callStatusDate}</span>
            </div>}
            
        </div>
    
    return (
        <React.Fragment>
            {dropdown}
            
        </React.Fragment>
    )
}

export default EditableDropdownSubComponent;



