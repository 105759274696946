import React, { Component } from 'react'
import OnDemandJob from './onDemandJob';
import * as moment from 'moment';
import './onDemandJobs.css'
import Axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import urlLinks from '../../../urlLinks'
import utils from '../../../utilMethods'

class OnDemandJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            list: [],
            industry: {}
        }
        this.data=[
            {
                name: 'Kunal',
                profile: 'sales',
                jobType:"onDemand",
                companyName:'OkayGo',
                companyCity:'Gurugram',
                joiningDate:moment(new Date()).format('L'),
                startTime:'6:00 pm',
                endTime:'7:00 pm',
                contactName:'Saurav',
                contactNumber:'9837328992',
                candidateNumber:'8546895121',
                address:'go hive,global Foyer, gurgaon, Haryana'
            },
            {
                name: 'Sagar',
                profile: 'Operations',
                jobType:"onDemand",
                companyName:'OkayGo',
                companyCity:'Gurugram',
                joiningDate:moment(new Date()).format('L'),
                startTime:'6:00 am',
                endTime:'7:00 am',
                contactName:'Saurav',
                contactNumber:'9837328992',
                candidateNumber:'8546895121',
                address:'go hive,global Foyer, gurgaon, Haryana'
            },
            {
                name: 'Aniket',
                profile: 'sales',
                jobType:"onDemand",
                companyName:'OkayGo',
                companyCity:'Delhi',
                joiningDate:moment(new Date()).format('L'),
                startTime:'6:00 pm',
                endTime:'7:00 pm',
                contactName:'Saurav',
                contactNumber:'9837328992',
                candidateNumber:'8546895121',
                address:'go hive,global Foyer, gurgaon, Haryana'
            },
            {
                name: 'Megha',
                profile: 'UI Developer',
                jobType:"onDemand",
                companyName:'OkayGo',
                companyCity:'Gurugram',
                joiningDate:moment(new Date()).format('L'),
                startTime:'6:00 pm',
                endTime:'7:00 pm',
                contactName:'Tom',
                contactNumber:'7755895215',
                candidateNumber:'8546895121',
                address:'go hive,global Foyer, gurgaon, Haryana'
            },
            {
                name: 'Kunal',
                profile: 'sales',
                jobType:"onDemand",
                companyName:'OkayGo',
                companyCity:'Gurugram',
                joiningDate:moment(new Date()).format('L'),
                startTime:'6:00 pm',
                endTime:'7:00 pm',
                contactName:'Tom',
                contactNumber:'7755895215',
                candidateNumber:'8546895121',
                address:'go hive,global Foyer, gurgaon, Haryana'
            }
        ]
    }
    extractDate = (date) =>{
        return moment(date).format("DD/MM/YYYY");
    }
    extractTime = (date) =>{
        return moment(date).format("hh:mm a");
    }
    getIdArray = (data) =>{
        const id=data? data.map(el =>{
            return el.jobId
        }): null;
        return id
    }
    idToCity= (id) =>{
        let city=""
        switch (id) {
            case "71":
                console.log({id});
                city= "New Delhi";
                break;
            case "72":
                console.log({id});
                city="Noida";
                break;
            case "73":
                console.log({id});
                city= "Gurugram";
                break;
            default:
                console.log({id});
                city= "Other";
                break;
            
        }
        return city;
    }
    completeData =(data) =>{
        const temp= this.state.list;
        for(let i=0 ; i<temp.length; i++){
            temp[i].companyName=data[i].companyName;
            temp[i].companyCity=this.idToCity(data[i].cityID);            
            temp[i].contactName=data[i].name;
            temp[i].contactNumber=data[i].phoneNumber;
        }
        console.log(temp);
        
        return temp
        
    }
    makeData = (data) => {
        const extractedData = [];
        data.map(el => {
            extractedData.push({
                name: `${el.firstName} ${el.lastName}`,
                profile: this.state.industry[el.company.industryType],
                jobType:el.workTypeKey,
                companyName:el.company.companyName,
                companyCity:el.cityName || 'Other',
                joiningDate:moment(new Date()).format('L'),
                contactName:el.contactPerson,
                contactNumber:el.contactNo,
                candidateNumber:el.contactNo,
                address:el.address,
                jobId: el.jobId,
                startTime:el.slot===0?this.extractTime(el.interviewStartTime):el.slot===1?this.extractTime(el.interviewStartTime2):this.extractTime(el.interviewStartTime2) ,
                endTime:el.slot===0?this.extractTime(el.interviewEndTime):el.slot===1?this.extractTime(el.interviewEndTime2):this.extractTime(el.interviewEndTime2) ,
            })
        })
        return extractedData;
    }

    async componentDidMount() {
        // const date = `${new Date().toISOString().split('T')[0]}`;
        // const industries = {};
        // const validExpiry = await utils.checkValidExpiry();
        // if(validExpiry) {
        //     Axios.get(
        //         `${urlLinks.baseUrl}/configmaster/?category_sub_type=industry&category_type=job_type`
        //     ).then(response => {
        //         for (const industry of response.data.response.content) {
        //         industries[industry.id] = industry.typeDesc;
        //         }
                // this.setState({ industry: industries, list: this.data });
                // Axios.get(`${urlLinks.baseUrl}/job/getdetailedjob?end_date=${date}&start_date=${date}&work_type=3`)
                // .then(response2 => {
                //     // console.log(response2.data.response.content)
                //     response2.data.response.content.map(el => {
                //         el.detailsJobs.map(el2 => {
                //             Axios.get(`${urlLinks.baseUrl}/interview/?job_detail_id=902`)
                //             .then(response3 => {
                //                 // console.log(response3)
                //             })
                //             const data = this.makeData(response2.data.response.content);
                //             this.setState({ list: data})
                //         })
                //     })
                // })
                // this.setState({ list: this.data})
            // });
        // }
    }
    
    render() {
        let len=this.state.list.length;    
        let joiningList=this.state.list?this.state.list.map((joining, index)  =>
                    <OnDemandJob data={joining} id={joining.id} cname={index!==len-1?'onDemandJoining':'onDemandJoiningLast'} />
                ) : null 
        
        return (
            <div>
                {joiningList}
            </div>
        )
    }
}

export default OnDemandJobs;