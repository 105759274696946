import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Grid,List, Button } from '@material-ui/core/';

const FormSuccess = () => {
  const history= useHistory();

  const location= useLocation();
  console.log({history, location});
  
  return (
    <div className="postJob">
      {(location.data && location.data.code ===1000)?<h1>Form submitted Successfully</h1>:<h1>Form submission failed</h1>}
      {(location.data && location.data.code ===1002) && <span>{location.data.message}</span>}
      <div className="postJobButtons">
          <Button 
            color="primary"
            variant="contained"
            style={{float:"left"}}
            onClick={()=> history.push("/worker/newAddWorker")}
            >
            {(location.data && location.data.code ===1000)?<span>Add new worker</span>:<span>Retry</span>}
          </Button>
          {(location?.data?.recruiterDashBoardData)?
            <Button 
              color="primary"
              variant="contained"
              style={{float:"right"}}
              onClick={()=> history.push({
                pathname: localStorage.getItem('roleType')==="recruiter"?'/':'/recruiter/dashboard',
                recruiterDashBoardData: history.location.data.recruiterDashBoardData
              })}
              >
                Go back to Recruiter DashBoard
            </Button>:
            <Button 
              color="primary"
              variant="contained"
              style={{float:"right"}}
              onClick={()=> history.push({
                pathname: location.data.from!=="internalWorker"?"/worker/externalWorkers":"/worker/workerList",

              })}
              >
                Go to worker list
            </Button>}
      </div>
    </div>
  )
}

export default FormSuccess;