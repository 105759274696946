import React, { Component } from 'react';
import financeUtils from './financeUtils';
import { Card, Grid } from '@material-ui/core';
import HideUnhideColumns from '../../utils/CustomColumnsDispaly/HideUnhideColumns';
import CustomFilter from '../../utils/CustomFilter/CustomFilter';
import CustomTable from '../../utils/Table/transactionTable';
import Axios from 'axios';
import urlLinks from '../../urlLinks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class FinanceAllLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      filters: financeUtils.allLedgerFilterList,
      currentPage: 0,
      open: false,
      count: 0,
      rowsPerPage: 10,
      isLoading: true,
      isTableUpdating: false,
      isPaidArr: [],
      column: [
        {
          id: '0',
          name: 'userCategory',
          label: 'Category',
          isColumnVisible: true
        },

        {
          id: '1',
          name: 'invoiceDate',
          label: 'Invoice Date',
          isColumnVisible: true
        },
        {
          id: '2',
          name: 'trxnDate',
          label: 'Transaction Date',
          isColumnVisible: true
        },
        {
          id: '3',
          name: 'companyName',
          label: 'Company Name',
          isColumnVisible: true
        },

        {
          id: '4',
          name: 'invoiceId',
          label: 'Invoice ID',
          isColumnVisible: true
        },

        {
          id: '5',
          name: 'jobType',
          label: 'Job Type',
          isColumnVisible: true
        },

        {
          id: '6',
          name: 'jobDetailsId',
          label: 'Job Detailed ID',
          isColumnVisible: true
        },
        {
          id: '8',
          name: 'totalAmount',
          label: 'Total Amount',
          isColumnVisible: true
        },
        {
          id: '9',
          name: 'dueAmount',
          label: 'Due Amount',
          isColumnVisible: true
        },
        {
          id: '10',
          name: 'transactionId',
          label: 'Transaction ID',
          isColumnVisible: true
        },
        //  {
        //     id: "11",
        //     name: "paid",
        //     label: "Paid",
        //     isColumnVisible: true
        // }

        // {
        //     id: "11",
        //     name: "overdue days",
        //     label: "Overdue Days",
        //     isColumnVisible: true
        // },
        // {
        //     id: "12",
        //     name: "paid amt",
        //     label: "Paid",
        //     isColumnVisible: true
        // },{
        //     id: "13",
        //     name: "trx Id",
        //     label: "Trx ID",
        //     isColumnVisible: true
        // }

        {
          id: '11',
          name: 'paidOn',
          label: 'Paid On',
          isColumnVisible: true
        },
        {
          id: '12',
          name: 'paid',
          label: 'Paid',
          isColumnVisible: true
        }
      ]
    };
    this.data = [];

    this.rowsOptions = [5, 10, 25, 50, 100];
    this.updateColumnFileds = this.updateColumnFileds.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.updateDataOnTablePagination = this.updateDataOnTablePagination.bind(
      this
    );
  }
  updateColumnFileds(columnsToHide) {
    const upadtedColumns = this.state.column.map(col => {
      if (columnsToHide.includes(col.id)) {
        col.isColumnVisible = true;
      } else {
        col.isColumnVisible = false;
      }
      return col;
    });

    this.setState({
      columns: upadtedColumns
    });
  }
  async updateFilters(recievedFilters) {
    const updatedFilter = this.state.filters.map(filter => {
      for (let el of recievedFilters) {
        if (el[filter.name]) {
          filter.default = el[filter.name];
        }
      }
      return filter;
    });
    await this.setState({
      filters: updatedFilter
    });
  }
  updateDataOnTablePagination(
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) {
    this.setState({ isTableUpdating: true }, () => {
      this.getTransactionList(rowsPerPage, nextPage);
    });
  }

  getTransactionList = (
    rowsPerPage = this.state.rowsPerPage,
    nextPage = this.state.currentPage
  ) => {
    Axios.get(
      `${urlLinks.baseUrl}/transactions/payments?page_no=${nextPage}&rows=${rowsPerPage}`
    )
      .then(response => {
        const isPaidArr = [];
        response.data.response.content.map(el => {
          if (el.isPaid === 0) {
            isPaidArr.push(el.transactionId);
          }
        });
        this.setState({
          list: response.data.response.content,
          isPaidArr: isPaidArr,
          count: response.data.response.totalElements,
          currentPage: nextPage,
          rowsPerPage: rowsPerPage,
          isLoading: false,
          isTableUpdating: false
        });
      })
      .catch(error => {
        toast.error('Error While Loading Transaction Lists');
      });
  };

  componentDidMount() {
    this.getTransactionList();

    // this.setState({ list: this.data });
  }
  render() {
    const tablePagination = {
      currentPage: this.state.currentPage,
      count: this.state.count,
      changePage: this.updateDataOnTablePagination,
      rowsOptions: this.rowsOptions,
      rowsPerPage: this.state.rowsPerPage,
      tableUpdating: this.state.isTableUpdating
    };
    return (
      <div>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={4000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <Card style={{ margin: '20px 0px', padding: '20px' }}>
          <Grid container>
            <Grid item sm={3}>
              <HideUnhideColumns
                columns={this.state.column}
                toggleColumn={this.updateColumnFileds}
              >
                Columns
              </HideUnhideColumns>
            </Grid>
            <Grid item sm={3}>
              <CustomFilter
                isOpen={this.state.open}
                filters={this.state.filters}
                updateFilters={this.updateFilters}
              ></CustomFilter>
            </Grid>
          </Grid>
        </Card>
        <CustomTable
          name={'transactionId'}
          columns={this.state.column}
          isPaid={this.state.isPaidArr}
          data={this.state.list}
          links={financeUtils.allLedgerListAction}
          title={financeUtils.title}
          tablePagination={tablePagination}
        ></CustomTable>
      </div>
    );
  }
}

export default FinanceAllLedger;
