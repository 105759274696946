import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import qs from 'querystring';
import Styles from './index.module.scss'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
// import Styles from '../index.module.scss'
import { ScaleLoader } from "react-spinners";
import { CSVLink } from "react-csv";
import Autocomplete from "@material-ui/lab/Autocomplete";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    borderBottom: "unset",
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "0 32px 32px 32px",
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const {open, setOpen, handleSendMessage, vacancyList, updateStatus} = props
  const [messageVacancy, setMessageVacancy] = useState()
  const [messageText, setMessageText] = useState("")
  const [messageModes, setMessageModes] = useState({
    sms: false,
    email: false,
    notification: false
  })
  const [disableDone, setDisableDone] = useState(true)
  const handleClose =() => {
      setOpen(false);
  }
  const handleModes = (event) => {
      console.log(event.target.name)
    setMessageModes({ ...messageModes, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
      console.log({messageVacancy, messageModes})
      if(messageVacancy && (messageModes.sms || messageModes.email || messageModes.notification) ){
          setDisableDone(false)
      }
      else{
        setDisableDone(true)
      }
  }, [messageVacancy, messageModes])
  useEffect(() => {
    if(messageVacancy){
        Axios.get(`${urlLinks.baseUrl}/workermaster/getTemplateMessage?jobDetailId=${messageVacancy.jobDetailId}&jobId=${messageVacancy.jobId}&serviceType=sms`)
        .then(response =>{
            setMessageText(response.data.response)
        })
    }
  }, [messageVacancy])
  const loader=<div style={{margin:"130px auto", width: "fit-content"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
  let dialogData=
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            classes={{paper: Styles.paper}}
            // className={Styles.paper}
        >
            <DialogTitle id="customized-dialog-title" onClose={() =>setOpen(false)}>
                
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} className={`${Styles.vacancyList} buttonList`} alignItems='flex-start'>
                    <span className="fontSize16 fontWeightBold">Select Vacancy:</span>
                    <Autocomplete
                        id="jobList"
                        options={vacancyList}
                        value={messageVacancy}
                        onChange={(event, value) => {
                            setMessageVacancy(value)
                        }}
                        style={{ width: "450px", margin: "0 50px 0 30px"}}
                        getOptionLabel={(option) => `${option.encryptId} - ${option.brandName} - ${option.jobTitle} - ${option.city}`}
                        renderInput={(params) => <TextField {...params}  className="margin0 borderLightGrey " style={{paddingLeft:"10px"}} />}
                    />
                </Grid>
                {messageText && <Grid item xs={12} className={Styles.messageMode}>
                    <span className={Styles.messageModeTitle}>Message mode</span>
                    <FormControl component="fieldset" className={Styles.checkboxContainer}>
                        <FormGroup aria-label="position" row>
                            
                            <FormControlLabel
                                value={messageModes.sms}
                                control={<Checkbox color="primary" />}
                                label="SMS"
                                onChange={handleModes}
                                labelPlacement="end"
                                name="sms"
                            />
                            <FormControlLabel
                                value={messageModes.email}
                                name="email"
                                control={<Checkbox color="primary" />}
                                label="Email"
                                onChange={handleModes}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                name="notification"
                                value={messageModes.notification}
                                onChange={handleModes}
                                control={<Checkbox color="primary" />}
                                label="App notification"
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>}
                {messageText && 
                    <Grid container>
                      <Grid item xs={12} className={Styles.messageTitleContainer}>
                        <span className={Styles.messageTitle}>Message</span>
                      </Grid>
                      <Grid item xs={12} className={Styles.messageContainer}>
                          <span style={{whiteSpace: "pre-wrap"}}>{messageText}</span>
                      </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <button
                    disabled={disableDone}
                    onClick={() =>handleSendMessage(messageVacancy, messageModes)}
                    className={Styles.blueContainedButton}
                >
                    SEND
                </button>
            </DialogActions>
        </Dialog>
  
  

  return dialogData; 
  
}
