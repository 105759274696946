import React, {Component} from 'react'
import UpcomingJoining from './upcomingJoining';
import * as moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import './upcomingJoinings.css'
import qs from 'querystring';
import ScaleLoader from "react-spinners/ScaleLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import getJoiningFilterList from './joiningconstants'
import {ExpansionPanel as MuiExpansionPanel, ExpansionPanelSummary as MuiExpansionPanelSummary, ExpansionPanelDetails as MuiExpansionPanelDetails } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const ExpansionPanel = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

class UpcomingJoinings extends Component{
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            jobIdList: {},
            isSubmitting:false
        }
	}
    updateStatus =async (updateData) =>{
        const {status, id, joiningDate}= updateData
        console.log(status, id, joiningDate);
        this.setState({isSubmitting: true})
        const postData={
            interview_id: id,
            joining_status : status,
            updated_by: localStorage.getItem('userId')
        }
        if(joiningDate)
            postData['update_joining_date']= joiningDate;
        
        Axios.post(`${urlLinks.baseUrl}tracking/updateJoiningStatus`,qs.stringify(postData))
            .then(response => {
                if (response.data.code === 1000) {

                    this.setState({isSubmitting: false})
                    toast.success('Status updated Successfully');
                } else {
                    throw toast.error(response.data.message);
                }
            })
            .catch(error => {
                this.setState({isSubmitting: false})
                toast.error(error.message);
            });
    }
    
    extractDate = (date) =>{
        return moment(date).format("DD/MM/YYYY");
    }
    extractTime = (date) =>{
        return moment(date).format("hh:mm a");
    }
    getIdArray = (data) =>{
        const id=data? data.map(el =>{
            return el.jobId
        }): null;
        return id
    }
    idToCity= (id) =>{
        let city=""
        switch (id) {
            case "71":
                console.log({id});
                city= "New Delhi";
                break;
            case "72":
                console.log({id});
                city="Noida";
                break;
            case "73":
                console.log({id});
                city= "Gurugram";
                break;
            default:
                console.log({id});
                city= "Other";
                break;
            
        }
        return city;
    }
    updateCompanyData =( data, list) => {
        if(!data)
            return null;
        const len=list.length
        const datalen=data.length
        let finalList=[]
        for( let i=0; i<len; i++)
        {   
            for (let j=0; j<datalen; j++)
            {
                if(list[i]===data[j].jobId)
                {
                    finalList.push(data[j])
                }
            }
        }
        return finalList;
        
    }
    completeData =(data, halfData) =>{
        if(!halfData)
            return null;
        const temp= halfData;
        if(!data)
            return null;
        console.log(halfData);
        for(let i=0 ; i<temp.length; i++){
            if(data[i])
            {
                temp[i].companyName=data[i].companyName;
                temp[i].companyCity=this.idToCity(data[i].cityID);            
                temp[i].contactName=data[i].name;
                temp[i].contactNumber=data[i].phoneNumber;
                temp[i].companyAddress=data[i].addressLine;
            }
        }
        console.log(temp);
        
        return temp
        
    }
	makeData = (data) => {        
        let CompanyDetail={};
        const extractedData = [];
        data.map(el => {
            if(el.joiningStatus!=="NO_RESPONSE")
            extractedData.push({
                jobId: el.jobId,
                interviewId:el.interviewId,
                name: `${el.firstName} ${el.lastName?el.lastName:""}`,
                profile: el.jobTittle,
                jobType:el.workType,
                companyName:'',
                companyCity:'',
                joiningDate: el.JoiningDate,
                // interviewDate:el.slot===0?this.extractDate(el.interviewStartTime):el.slot===1?this.extractDate(el.interviewStartTime2):this.extractDate(el.interviewStartTime2) ,
                // startTime:el.slot===0?this.extractTime(el.interviewStartTime):el.slot===1?this.extractTime(el.interviewStartTime2):this.extractTime(el.interviewStartTime2) ,
                // endTime:el.slot===0?this.extractTime(el.interviewEndTime):el.slot===1?this.extractTime(el.interviewEndTime2):this.extractTime(el.interviewEndTime2) ,
                contactName:'',
                contactNumber:'',
                companyAddress:'',
                candidateNumber:el.contactNumber,
                address:el.address,
                joiningStatus: el.joiningStatus==="POSTPONED"?null:el.joiningStatus
            })
        })
        return extractedData;
    }
    makeNoResponseData = (data) => {        
        let CompanyDetail={};
        const extractedData = [];
        data.map(el => {
            extractedData.push({
                jobId: el.jobId,
                interviewId:el.interviewId,
                name: `${el.firstName} ${el.lastName?el.lastName:""}`,
                profile: el.jobTittle,
                jobType:el.jobType,
                companyName:'',
                companyCity:'',
                joiningDate: el.JoiningDate,
                // interviewDate:el.slot===0?this.extractDate(el.interviewStartTime):el.slot===1?this.extractDate(el.interviewStartTime2):this.extractDate(el.interviewStartTime2) ,
                // startTime:el.slot===0?this.extractTime(el.interviewStartTime):el.slot===1?this.extractTime(el.interviewStartTime2):this.extractTime(el.interviewStartTime2) ,
                // endTime:el.slot===0?this.extractTime(el.interviewEndTime):el.slot===1?this.extractTime(el.interviewEndTime2):this.extractTime(el.interviewEndTime2) ,
                contactName:'',
                contactNumber:'',
                companyAddress:'',
                candidateNumber:el.contactNumber,
                address:el.address,
                joiningStatus: el.joiningStatus
            })
        })
        return extractedData;
    }
	async componentDidMount () { 
        let url="";
        let listToday= [], listTomorrow= [], listNoResponse=[];
        Axios.get(
            `${urlLinks.baseUrl}tracking/Upcomingjoining`
        ).then(response => { 
            if (response.data.response) {
                this.setState({isSubmitting: true})
                listToday=this.makeData(response.data.response);
                console.log(response.data.response);         
                const jobIdList=listToday?this.getIdArray(listToday):null;
                url=`${urlLinks.baseUrl}tracking/getUpcomingInterviewsCompanyDetails?job_id=${jobIdList}`;                
                Axios.get(url)
                    .then(response => {
                    console.log({response});
                    const companyData = this.updateCompanyData(response.data.response , jobIdList);
                    const data=this.completeData(companyData, listToday);
                    this.setState({ listToday: data, isSubmitting: false, companydetailsToday: response.data.response})
                })
            } 
        })
        Axios.get(
            `${urlLinks.baseUrl}tracking/Tomorrowjoining`
        ).then(response => {  
            this.setState({isSubmitting: true})
            listTomorrow=this.makeData(response.data.response);
            console.log(response.data.response);         
            const jobIdList=listTomorrow?this.getIdArray(listTomorrow):null;
            url=`${urlLinks.baseUrl}tracking/getUpcomingInterviewsCompanyDetails?job_id=${jobIdList}`;                
            Axios.get(url).then(response => {
                // console.log(jobIdList);
                const companyData = this.updateCompanyData(response.data.response , jobIdList);
                const data=this.completeData(companyData,listTomorrow);
                this.setState({ listTomorrow: data, isSubmitting: false,  companydetailsTomorrow: response.data.response})
            })
        })
         Axios.get(
            `${urlLinks.baseUrl}tracking/noResponseJoinings`
        ).then(response => { 
            this.setState({isSubmitting: true}) 
            listNoResponse=this.makeNoResponseData(response.data.response);
            console.log(response.data.response);         
            const jobIdList=listNoResponse?this.getIdArray(listNoResponse):null;
            url=`${urlLinks.baseUrl}tracking/getUpcomingInterviewsCompanyDetails?job_id=${jobIdList}`;                
            Axios.get(url).then(response => {
                // console.log(jobIdList);
                const companyData = this.updateCompanyData(response.data.response , jobIdList);
                const data=this.completeData(companyData,listNoResponse);
                this.setState({ listNoResponse: data, isSubmitting: false,  companydetailsNoResponse: response.data.response})
            })
        })
    }

    render() {
        let lenToday= this.state.listToday?this.state.listToday.length:0
        let lenTomorrow= this.state.listTomorrow?this.state.listTomorrow.length:0
        let lenNoResponse= this.state.listNoResponse?this.state.listNoResponse.length:0
        console.log(this.state.listToday);
        console.log(lenToday);
        let todayJoiningList=this.state.listToday?this.state.listToday.map((joining, index)  =>
            <UpcomingJoining updateStatus={this.updateStatus} data={joining} id={joining.id} cname={index!=lenToday-1?'upcomingJoining':'upcomingJoiningLast'} />
        ): null 
        console.log({todayJoiningList});
        
        let tomorrowJoiningList=this.state.listTomorrow?this.state.listTomorrow.map((joining, index)  =>
            <UpcomingJoining updateStatus={this.updateStatus}  data={joining} id={joining.id} cname={index!=lenTomorrow-1?'upcomingJoining':'upcomingJoiningLast'} />
        ) : null
        let noresponseList=this.state.listNoResponse?this.state.listNoResponse.map((joining, index)  =>
            <UpcomingJoining updateStatus={this.updateStatus}  data={joining} id={joining.id} cname={index!=lenNoResponse-1?'upcomingJoining':'upcomingJoiningLast'} />
        ) : null 
        return (
            this.state.isSubmitting?
                <div style={{margin:"auto", position:"sticky", width: "fit-content"}}>
                    <ScaleLoader
                        height={70}
                        width={10}
                        color={"#1c75bc"}    
                    />
                </div>
                :
                <div className="upcomingJoinings">
                    <ToastContainer
                        position='top-right'
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        autoClose={4000}
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    ></ToastContainer>
                    <div className="tomorrow">
                        <ExpansionPanel 
                                TransitionProps={{ unmountOnExit: false }}
                                defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3> Tomorrow </h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="list">
                                    {lenTomorrow>0?tomorrowJoiningList:"No Joinings for Tomorrow"}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className="today">
                        <ExpansionPanel 
                                TransitionProps={{ unmountOnExit: false }}
                                defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3> Today </h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <div className="list">
                                {lenToday>0?todayJoiningList:"No Joinings for Today"}
                            </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                    </div>
                    <div className="today">
                        <ExpansionPanel 
                                TransitionProps={{ unmountOnExit: false }}
                                defaultExpanded	={true}    >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}  >
                                <h3> No Response </h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="list">
                                    {lenNoResponse>0?noresponseList:"No Candidate with No Response Status"}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </div>
        )
    }
}

export default UpcomingJoinings;