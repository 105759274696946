import React, {  } from 'react'
import { Modal,  Backdrop, Grid, Slide, Button} from '@material-ui/core';
import {  makeStyles} from '@material-ui/core/styles';
import { Link } from "react-router-dom";
// import './modals.css'
import * as moment from 'moment';
import WeekToggle from '../../components/Job/JobList/newJobViewComponents/weektoggleButton'
// import Availabilities from './Availabilitylist'
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display:"flex",
    '&:focus':{
        outline: "none"
    },
    // minWidth:"1000px",
    width:"532px",
    flexDirection:"row",
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px"
  },
  select:{
    minWidth: 140,
    padding: 15,
  }
}));
const JobModal =(props) =>{
    const classes = useStyles();
    const {values, openScoreModal, setOpenScoreModal} =props
    console.log({values})
    
    const jobView=
        values && <Grid container>
            <Grid item xs={12} className="rowOrder paddingVertical12 marginBottom36 " style={{borderBottom: "1px solid #cccccc"}}>
                <Grid item xs={6} className="padding0">
                    <span className="fontSize24 fontWeightBold">Cutoff parameters</span>
                </Grid>
                <Grid item xs={6} className="padding0 rowOrder fontSize16" justify="flex-end" alignItems="flex-end">
                    <span style={{color: "#333333"}}>Actual Score: &nbsp;</span>
                    <span className="fontWeightBold">{values.actualScore.toFixed(2)}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} className="rowOrder padding0">
                <Grid item xs={8} className="padding0">
                    <Grid item xs={12} className="rowOrder padding0">
                        <span style={{color: "#333333"}}>Availability Cutoff: &nbsp;</span>
                        <span className={`${values.availabilityCutOff===1?"greenColor":values.availabilityCutOff===0?"redColor":"blackColor"}`}>{values.availabilityCutOff===1?"Pass":values.availabilityCutOff===0?"Failed":values.availabilityCutOff===-1?"Disabled":"-"}</span>
                    </Grid>
                    <Grid item xs={12} className="rowOrder padding0">
                        <span style={{color: "#333333"}}>Experience Cutoff: &nbsp;</span>
                        <span className={` ${values.expCutOff===1?"greenColor":values.expCutOff===0?"redColor":"blackColor"}`}>{values.expCutOff===1?"Pass":values.expCutOff===0?"Failed":values.expCutOff===-1?"Disabled":"-"}</span>
                    </Grid>
                    <Grid item xs={12} className="rowOrder padding0">
                        <span style={{color: "#333333"}}>Qualification Cutoff: &nbsp;</span>
                        <span className={` ${values.qualCutOff===1?"greenColor":values.qualCutOff===0?"redColor":"blackColor"}`}>{values.qualCutOff===1?"Pass":values.qualCutOff===0?"Failed":values.qualCutOff===-1?"Disabled":"-"}</span>
                    </Grid>
                </Grid>
                <Grid item xs={4} className="padding0">
                    <Grid item xs={12} className="rowOrder padding0">
                        <span style={{color: "#333333"}}>Distance Cutoff: &nbsp;</span>
                        <span className={` ${values.distanceCutOff===1?"greenColor":values.distanceCutOff===0?"redColor":"blackColor"}`}>{values.distanceCutOff===1?"Pass":values.distanceCutOff===0?"Failed":values.distanceCutOff===-1?"Disabled":"-"}</span>
                    </Grid>
                    <Grid item xs={12} className="rowOrder padding0">
                        <span style={{color: "#333333"}}>Gender Cutoff: &nbsp;</span>
                        <span className={` ${values.genderCutOff===1?"greenColor":values.genderCutOff===0?"redColor":"blackColor"}`}>{values.genderCutOff===1?"Pass":values.genderCutOff===0?"Failed":values.genderCutOff===-1?"Disabled":"-"}</span>
                    </Grid>
                </Grid>
            </Grid>           
            <Grid item xs={12} className="padding0 rowOrder marginVertical20" direction="row-reverse">
                <Button
                    variant = "outlined"
                    className="blueColor"        
                    onClick={() => setOpenScoreModal(false)}        
                >
                    Close
                </Button>
            </Grid>
        </Grid>
    return (
        <Modal
            className={classes.modal}
            open={openScoreModal}
            onClose={() => setOpenScoreModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <div className={`${classes.paper}`}>
                    {jobView}
                </div>
            </Slide>
        </Modal>
    )
}
export default JobModal;