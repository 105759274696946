import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import document from './document.svg'
// import ResponseModal from './ResponseModal'
import Typography from "@material-ui/core/Typography";
import * as moment from 'moment'
import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import Styles from './index.module.scss'
import UrlLinks from "../../urlLinks";
import Download from './Group 3238.svg'
import AlertDialog from './AlertDialog'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",

  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  }
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const { openOnboardingModal, setOpenOnboardingModal, showToast, updateMyData, open } = props
  const [excel, setExcel] = useState(null)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [name, setName] = useState("")
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  //   const [invalidData, setInvalidData] = useState()
  const [fileLink, setFileLink] = useState(null)
  const [responseData, setResponseData] = useState()
  // console.log({excel})
  // const fileLink = 'http://cdn-files.okaygo.in.s3.ap-south-1.amazonaws.com/document/templates/worker_payment_upload_excel.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20210201T092845Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86399&X-Amz-Credential=AKIAI5OYSIY7VDJWHSQA%2F20210201%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=84e26a5adfa0d1fb5a8c69cc0147eb740912db652596fb530ed1275c17eaeb46'
  const handleClose = () => {
    setOpenOnboardingModal(false)
  }
  const handleUpload = (event) => {
    //   console.log("pepe")
    const fileName = event.target.files[0].name.split(".").slice(-1)[0];
    if (fileName.toLowerCase() === "xlsx" || fileName.toLowerCase() === "xls") {
      setExcel(event.target.files[0]);
      setName(event.target.files[0].name)
      setExcelError(false)
    }
    else {
      setExcelError(true)
      setExcel(null);
      setName("");
    }
    setResponseData();
    event.target = null
  }
  //   const createInvalidData =(records) =>{
  //     // console.log({records})
  //     if(records.length>1){
  //       let list=[];
  //       records.map(record =>{
  //         if(record.hasOwnProperty("WorkerDetail")){
  //           // console.log({record});
  //           if(record.WorkerDetail.isWorkerCreated===0){

  //             list.push({
  //               "Casper Id":record.WorkerDetail.casperId,
  //               "Mobile No":record.WorkerDetail.mobileNo,
  //               "Aadhaar No":""+record.WorkerDetail.adhaarNo,
  //               "Source by":record.WorkerDetail.sourceBy,
  //               "Vendor Name/Emp id/HRBP or TA id ":record.WorkerDetail.sourceByName,
  //               "Hub Name":record.WorkerDetail.hubName,
  //               "Designation":record.WorkerDetail.designation,
  //               "Rate Card":record.WorkerDetail.rateCardType,
  //               "Joining Date":record.WorkerDetail.dateOfJoining?moment(record.WorkerDetail.dateOfJoining).format("DD/MM/YYYY"):"",
  //               "EDAB":record.WorkerDetail.physicallyDisabled===1?"Yes":record.WorkerDetail.physicallyDisabled===0?"No":"",
  //               "Reason for failure":record.WorkerDetail.uploadStatus
  //             })
  //           }
  //         }
  //         return true;
  //       })
  //       return list.length>0?list:null;
  //     }
  //   }

  const modifyResponse = (resp) => {// 
    let a = resp['job_not_associated_with_worker_id']
    let b = ``
    for (let i = 0; i < a.length; i++) {
      b = b + a[i];
      b = b + ','
    }
    if (b.length > 1)
      b = b.slice(0, b.length - 1)
    resp['job_not_associated_with_worker_id'] = b
    console.log(b)
    ///////////////////////////////////////////////////
    a = resp['wrong_records']
    b = ``
    for (let i = 0; i < a.length; i++) {
      b = b + a[i];
      b = b + ','
    }
    if (b.length > 1)
      b = b.slice(0, b.length - 1)
    resp['wrong_records'] = b
    setResponseData(resp)
  }

  // const handleExcel = (event) => {
  //     console.warn("Came")
  //     var fileObj = event.target.files[0];
  //     if (event.target.files[0]) {
  //         ExcelRenderer(fileObj, (err, resp) => {
  //             if (err) {
  //                 console.log(err);
  //             }
  //             else {
  //                 // console.log({ resp })
  //                 const formData = new FormData();
  //                 formData.append('file', fileObj)
  //                 Axios.post(`${urlLinks.baseUrl}/workermaster/uploadInvoice`, formData, {
  //                     headers: {
  //                         'content-type': 'multipart/form-data'
  //                     }
  //                 })
  //                     .then(response => {
  //                         updateMyData()
  //                         openResponseModal()
  //                         modifyResponse(response.data.response)
  //                         // AlertDialog(true)

  //                     })

  //                 // showToast("success", "Uploaded Sucessfully")
  //             }
  //         })
  //     }
  //     event.target.value = null//this was missing
  // }
  const handleExcel = () => {
    setIsLoading(true)
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(`${UrlLinks.baseUrl}/workermaster/uploadInvoice`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then(response => {
        // console.log({response});
        if (response.data) {

          setIsLoading(false)
          updateMyData()
          modifyResponse(response.data.response)
          setOpenResponseModal(true)
        }
        else {
          setResponseData([]);
        }
      })
      .catch(error => {
        // console.log({error})
        setIsLoading(false)
        //   showToast("error", error.message);
      })
  }
    useEffect(() => {
      Axios.get(`${UrlLinks.baseUrl}/admin/getSignedUrl?fileName=document/templates/worker_payment_upload_excel.xlsx`,null , {timeout:60*5*1000})
        .then(response =>{
          // console.log({response})
          setFileLink(response.data.response)
        })

    }, [])
  //   useEffect(() => {
  //     if(responseData){
  //       setOpenResponseModal(true);
  //     }
  //   }, [responseData])
  let dialogData =
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      className={Styles.onboardingDialog}
      classes={{ paper: Styles.paper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>Upload Payment Data</span> <a
          href={fileLink}
          target="_blank"
          className={Styles.downloadTemplate}
        >
          <span>Download File Template</span><img src={Download} alt="download" />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        {/* <RadioGroup  adioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel label="New workers" control={<Radio color="primary" />} value="new" />
                  <FormControlLabel label="Existing workers" control={<Radio color="primary" />} value="existing" />
              </RadioGroup> */}
        <Grid item xs={12} className="padding0">

          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className="uploadButton marginLight0"
              component="span"

              startIcon={<img src={document} alt={"upload"} style={{ color: "white" }} />}
            >
              Browse File
                      </Button>
          </label>
          {name && <span className={Styles.fileName}><span>File name: </span>{`${name}`}</span>}
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>only .xls and .xlsx are allowed</span>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? <span>Please wait... it may take few minutes</span> : <Button variant="contained" onClick={handleExcel} className={`${!excel ? "disabledButton" : "blueButtonContained"}`} disabled={!excel}>
          Upload
              </Button>}
      </DialogActions>
      {openResponseModal && <AlertDialog
        open={true}
        closeResponseModal={handleClose}
        //   setOpenResponseModal={setOpenResponseModal}
        resp={responseData}
      //   setOpenOnboardingModal={setOpenOnboardingModal}
      //   showToast={showToast}
      //   invalidData={invalidData}
      //   type="Onboarding"
      />}
    </Dialog>



  return dialogData;

}
