import React, { Component, Fragment } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { CircleLoader } from 'react-spinners';
import urlLinks from '../../../urlLinks';
import BackButton from '../../../utils/BackButton/BackButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import utilFunctions from '../../../utilMethods';

const styles = () => ({
  root: {
    margin: 'auto',
    display: 'flex'
  },
  textStyle: {
    textDecoration: 'underline'
  },
  grid: {
    marginBottom: '20px'
  },
  imageStyle: {
    height: '120px',
    width: '120px',
    margin: '10px 5px 10px 5px'
  },
  divStyle: {
    display: 'flex',
    flexFlow: 'column',
    height: '200px',
    width: '130px'
  },
  alignFormat: {
    margin: '0 auto 5px ',
    fontSize: '0.8rem'
  },
  gridStyle: {
    margin: '10px 0 10px 0'
  }
});
class ViewEmployerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personalDetails: {
        name: '',
        post: '',
        email: '',
        phone: ''
      },
      companyDetails: {
        address: '',
        Landmark:'',
        brandName: '',
        companyName: '',
        shortDescription: '',
        companyServices: '',
        gst: '',
        payment_pending: '',
        od_active_jobs: '',
        ptft_active_jobs: ''
      },
      rating: '',
      industry: '',
      status: '',
      isPageLoading: true,
      file: ''
    };
    this.pancardFileLink = '';
    this.companyRegFileLink = '';
    this.redirectToEmployerList = this.redirectToEmployerList.bind(this);
    this.getEmployerDataById = this.getEmployerDataById.bind(this);
  }
  redirectToEmployerList() {
    this.props.history.push('/employer/employerLists');
  }
  componentDidMount() {
    const id = this.props.location.id;
    if (id) {
      this.getEmployerDataById(id);
    } else {
      this.redirectToEmployerList();
    }
  }
  async getEmployerDataById(id) {
    const validExpiry = await utilFunctions.checkValidExpiry();
    if(validExpiry) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.employerUrls.getEmployerDetailById}${id}`
      ).then(response => {
        console.log(response.data.response.content)
        const data = response.data;
        Axios.get(
          `${urlLinks.baseUrl}${urlLinks.employerUrls.getEmployerList}?employer_id=${id}`
        )
          .then(response2 => {
            console.log(response2.data.response.content)
            Axios.get(`${urlLinks.baseUrl}/company/?user_id=${response2.data.response.content[0].userId}`)
            .then(response3 => {
              console.log(response3)
              const data2 = response2.data.response?.content[0];
              const data3 = response3.data.response?.content[0];
              this.profileImageLink = data3?.companyLogo
                ? data3?.companyLogo
                : '';
              if (this.profileImageLink) {
                utilFunctions
                  .getBase64String(this.profileImageLink)
                  .then(result => {
                    const base64 = 'data:image;base64,' + result;
                    this.setState({
                      ...this.state,
                      file: base64
                    });
                  });
              }
              // console.log(this.profileImageLink);
              this.companyRegFileLink = data?.response?.companyRegFilePath
                ? data.response.companyRegFilePath
                : '';
              this.pancardFileLink = data?.response?.pancardFilePath
                ? data.response.pancardFilePath
                : '';
              const companyServices = []
              if(data3?.is_2meal) {
                companyServices.push('Meal');
              }
              if(data3?.is_conveyance) {
                companyServices.push('Conveyance');
              }
              if(data3?.is_rooms) {
                companyServices.push('Rooms');
              }
              if(data3?.is_pf_esic) {
                companyServices.push('PF / ESIC');
              }
              this.setState({
                personalDetails: {
                  name: data?.response?.contactPerson ? data.response.contactPerson : '',
                  post: data?.response?.designation
                    ? data.response.designation + ' - ' + data.response.industry
                    : '',
                  phone: data?.response?.contactNumber
                    ? data.response.contactNumber
                    : '',
                  email: data?.response?.email ? data.response.email : ''
                },
                companyDetails: {
                  address: data?.response?.addressLine1
                    ? data?.response?.addressLine1
                    : '',
                  Landmark:data?.response?.addressLine2?data.response.addressLine2:'',  
                  companyName: data?.response?.companyName,
                  shortDescription: data3?.shortDescription,
                  companyServices: companyServices.join(', '),
                  brandName: data?.response?.brandName ? data.response.brandName : '',
                  gst: data?.response?.gstNumber ? data.response.gstNumber : '',
                  payment_pending: data2?.pendingPayment
                    ? data2.pendingPayment
                    : 0,
                  od_active_jobs: `${data?.response?.onDemand} ${data?.response?.onDemandJobs ? ` - ${data.response.onDemandJobs}` : ''}` || 0,
                  pt_active_jobs: `${data?.response?.partTime} ${data?.response?.partTimeJobs ? ` - ${data.response.partTimeJobs}` : ''}` || 0,
                  ft_active_jobs: `${data?.response?.fullTime} ${data?.response?.fullTimeJobs ? ` - ${data.response.fullTimeJobs}` : ''}` || 0
                },
                rating: data2?.rating ? data2.rating : 0,
                industry: data?.response.industry ? data.response.industry : '',
                status: data2?.status ? data2.status : '',
                isPageLoading: false
            })
            });
          })
          .catch(error => {
            toast.error('Could not load page :(');
            // console.log(error);
          });
      });
    }
  }
  render() {
    const classes = this.props.classes;
    return this.state.isPageLoading ? (
      <Fragment>
        <CircleLoader
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto'
          }}
          color={'#194D33'}
        ></CircleLoader>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          autoClose={4000}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
      </Fragment>
    ) : (
      <div className={classes.root}>
        <ToastContainer
          position='top-right'
          hideProgressBar
          newestOnTop={false}
          autoClose={4000}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
        <BackButton />
        <div className={classes.divStyle}>
          <Avatar
            variant='square'
            alt='image'
            src={this.state.file}
            className={classes.imageStyle}
          />
          <Typography variant='h5' gutterBottom className={classes.alignFormat}>
            {this.state.companyDetails.brandName}{' '}
          </Typography>
          <Typography variant='h5' gutterBottom className={classes.alignFormat}>
            {this.state.industry}
          </Typography>
          <Typography variant='h5' gutterBottom className={classes.alignFormat}>
            {this.state.status}
          </Typography>
          <Typography variant='h5' gutterBottom className={classes.alignFormat}>
            Rating: {this.state.rating}
          </Typography>
          <Link
            to={{
              pathname: '/employer/employerLists/editEmployer',
              id: this.props.location.id
            }}
            className={classes.alignFormat}
          >
            <Button
              style={{ fontSize: '0.8rem' }}
              variant='contained'
              color='primary'
            >
              Edit
            </Button>
          </Link>
        </div>
        <Grid container style={{ marginLeft: '70px' }}>
          <Grid container className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.textStyle}>
                Personal Details
              </Typography>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={1}>
                Name:
              </Grid>
              <Grid item xs={4}>
                {this.state.personalDetails.name}
                {this.state.personalDetails.post
                  ? ` (${this.state.personalDetails.post})`
                  : null}{' '}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={1}>
                Email:
              </Grid>
              <Grid item xs={4}>
                {this.state.personalDetails.email}{' '}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={1}>
                Phone:
              </Grid>
              <Grid item xs={4}>
                {this.state.personalDetails.phone}{' '}
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.textStyle}>
                Company Details
              </Typography>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                Company Name:
              </Grid>
              <Grid item xs={4}>
                {this.state.companyDetails.companyName}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                Address:
              </Grid>
              <Grid item xs={4}>
                {this.state.companyDetails.address}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                Landmark:
              </Grid>
              <Grid item xs={4}>
                {this.state.companyDetails.Landmark || '-'}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                Company Description:
              </Grid>
              <Grid item xs={4}>
                {this.state.companyDetails.shortDescription || '-'}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                Company Services:
              </Grid>
              <Grid item xs={4}>
                {this.state.companyDetails.companyServices || '-'}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                Gst:
              </Grid>
              <Grid item xs={4}>
                {this.state.companyDetails.gst}{' '}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                Payment Pending:
              </Grid>
              <Grid item xs={4}>
                {this.state.companyDetails.payment_pending}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                OD Active Jobs:
              </Grid>
              <Grid item xs={8}>
                {this.state.companyDetails.od_active_jobs}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                PT/FT Active Jobs:
              </Grid>
              <Grid item xs={8}>
                {this.state.companyDetails.pt_active_jobs}
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={2}>
                FT Active Jobs:
              </Grid>
              <Grid item xs={8}>
                {this.state.companyDetails.ft_active_jobs}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.textStyle}>
                Documents
              </Typography>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={6} sm={3} style={{ alignSelf: 'center' }}>
                <Typography style={{ fontSize: '0.9rem' }}>
                  Company Registration Document:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button
                  style={{ fontSize: '0.8rem' }}
                  variant='contained'
                  color='primary'
                  disabled={this.companyRegFileLink ? false : true}
                  onClick={() => {
                    utilFunctions.downLoadFile(
                      this.companyRegFileLink,
                      'RegFile'
                    );
                  }}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
            <Grid container className={classes.gridStyle}>
              <Grid item xs={6} sm={3} style={{ alignSelf: 'center' }}>
                <Typography style={{ fontSize: '0.9rem' }}>
                  Pan Card:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button
                  style={{ fontSize: '0.8rem' }}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    utilFunctions.downLoadFile(this.pancardFileLink, 'panCard');
                  }}
                  disabled={this.pancardFileLink ? false : true}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ViewEmployerDetails);
