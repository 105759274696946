import React, { Component, Fragment } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  icon: {
    marginRight: "8px",
    width:"22px",
    height:"22px",
    minWidth:"0px",
    color:"rgba(0, 0, 0, 0.74)"
  },
  link:{
    textDecoration:"none",
    color:"rgba(0, 0, 0, 0.84)"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
  

});
class CollapsableList extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };

  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const classes = this.props.classes;
    const childLinks = this.props.links.map((link) => {
      return (
        
        <Link key={link.id} to={link.route} className={classes.link}>
          <ListItem style={window.location.pathname === link.route ? {backgroundColor: '#3f51b5', color: 'white'} : null} button className={classes.nested}>
            <ListItemIcon className={classes.icon}>{link.subIcon}</ListItemIcon>
            <ListItemText primary={link.name}>
            </ListItemText>
          </ListItem>
        </Link>

      )
    });
    return (
      <Fragment>
        <ListItem button onClick={this.toggle}>
          <ListItemIcon className={classes.icon}>{this.props.icon}</ListItemIcon>
          <ListItemText primary={this.props.name} />
          {this.state.collapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.collapse} unmountOnExit>
          <List component="div" disablePadding>{childLinks}</List>
        </Collapse>
      </Fragment>
    );
  }
}

export default  withStyles(styles)(CollapsableList) ;