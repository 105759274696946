import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Grid, Divider } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { withStyles } from '@material-ui/core/styles';
import CustomMultiSelectDropDown from '../../../utils/CustomMultiselectDropDown/CustomMultiSelectDropDown';
const styles = () => ({
  gridStyle: {
    margin: '10px',
    maxWidth: '700px',
    minWidth: '300px'
  },
  gridItemStyle: {
    padding: '10px !important'
  },
  buttonTextSize: {
    fontSize: '0.7rem'
  }
});
class CustomFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
        defaultValue: [
            { job_title: [] },
            {companyName: [] },
            {status: [] },
            
        ],
        open: this.props.isOpen,
        anchorEl: <b></b>,
        

        };
        this.status=
        {
            "Going": 'GOING',
        
            "Not Going": "NOT_GOING",
    
            "Reschedule": "RESCHEDULE",
        
            "Appeared": "APPEARED",
        }
        this.fil=this.props.filters
        
        
        this.url=''
        this.defaultValue = this.state.defaultValue;
        this.getFilterElemnts = this.getFilterElemnts.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        // this.handleChange = this.handleChange.bind(this);
    }

  

    handleInputChange(name, data) {
        for (let el of this.defaultValue) {
            // console.log({name, data});
            
            if (el[name]) {
                el[name] = data;
            }
        }
        
        this.setState({
        defaultValue: this.defaultValue
        },() => this.createURL(this.defaultValue));
    }

    handleRequestClose(event) {
        this.setState({
        open: !this.state.open,
        anchorEl: event.currentTarget
        });
    }

    resetFilters() {
        // for (let el of this.defaultValue) {
        //   const key = Object.keys(el)[0];
        //   el[key] = [];
        // }
        this.setState(
        {
            defaultValue: [
                { job_title: [] },
                {companyName: [] },
                {status: []}
            ],
            open: this.props.isOpen,
            anchorEl: <b></b>,
            status: ''
        },
        () =>
            this.props.resetFilterURL(this.props.loc)
        );
    }
    updateFilters =(event) =>{
        console.log({event});
        
        this.props.updateFilters(this.url, this.props.loc, this.fil);
        this.handleRequestClose(event);
    }

    createURL =(filterList) =>{
        let url="";
        const filters=this.props.filters;
        // console.log({filters});
        
        filterList.map((filter, index) =>{

            if(filter[Object.getOwnPropertyNames(filter)].length>0)
            {
                let addurl=''
                if(Object.getOwnPropertyNames(filter)==="status")
                {
                    let statFilter=filter[Object.getOwnPropertyNames(filter)].map(stat => this.status[stat])
                    // console.log(statFilter);
                    if(filters[index])
                        filters[index]["default"]=statFilter;
                    addurl= `${Object.getOwnPropertyNames(filter)}=${statFilter}&`
                }
                else
                {
                    if(filters[index])
                        filters[index]["default"]=filter[Object.getOwnPropertyNames(filter)];
                    addurl= `${Object.getOwnPropertyNames(filter)}=${filter[Object.getOwnPropertyNames(filter)]}&`
                }          
                url=`${url}${addurl}`
                
            }
            return null;
        })
        this.fil=filters
        // console.log(this.fil);
        
        this.url=url;
        

    }
    getFilterElemnts(classes) {
        
            const filters = this.props.filters.map((element, index) => {
            console.log(element.default);
            
            return (
                <Grid item xs={6} key={element.id} className={classes.gridItemStyle}>
                <CustomMultiSelectDropDown
                    default={this.state.defaultValue[index][element.name]}
                    updatedDefault={element.default}
                    data={element[element.name]}
                    name={element.name}
                    label={element.label}
                    updateChange={this.handleInputChange}
                ></CustomMultiSelectDropDown>
                </Grid>
            );
            });
            return filters;
        }
    

    componentDidMount() {
        
        this.setState({
        defaultValue:[
                { job_title: [] },
                {companyName: [] },
                {status: [] }
            ]
        });
    }
    



    render() {
        // console.log(this.props);
       
        return (
        <PopupState variant='popover' popupId={'popover' + this.props.id}>
            {popupState => (
            <div>
                <Button
                variant='contained'
                color='primary'
                {...bindTrigger(popupState)}
                className={this.props.classes.buttonTextSize}
                onClick={this.handleRequestClose}
                >
                Filter
                </Button>
                <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    id: 2,
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                >
                <Grid
                    container
                    spacing={3}
                    className={this.props.classes.gridStyle}
                >
                    {this.getFilterElemnts(this.props.classes)}
                    
                </Grid>

                <Divider />
                <Grid container className={this.props.classes.gridStyle}>
                    <Grid item xs={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={event => {
                        this.resetFilters(true);
                        // this.handleRequestClose(event);
                        }}
                        className={this.props.classes.buttonTextSize}
                    >
                        Reset
                    </Button>
                    </Grid>
                    <Grid item xs={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={ (event) =>this.updateFilters(event)}
                        className={this.props.classes.buttonTextSize}
                    >
                        Apply
                    </Button>
                    </Grid>
                    <Grid item xs={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={event => {
                        this.handleRequestClose(event);
                        }}
                        className={this.props.classes.buttonTextSize}
                    >
                        Cancel
                    </Button>
                    </Grid>
                </Grid>
                </Popover>
            </div>
            )}
        </PopupState>
        );
    }
}

export default withStyles(styles)(CustomFilter);
