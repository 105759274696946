import React from 'react';
import { MdCall,MdMoneyOff,MdModeEdit} from "react-icons/md";
import { FaExternalLinkAlt} from "react-icons/fa";

const financeUtils={
    title:"Finance Screening",
    allLedgerListAction:
        [{
            id:1,
            name:"Razorpay payment Link",
            subIcon:<FaExternalLinkAlt className="fitIcon"/>,
            route:"/finance/allledger"
        },
        {
            id:2,
            name:"Edit/Cancel invoice",
            subIcon:<MdModeEdit className="fitIcon"/>,
            route:"/finance/allledger"
        }
        ,
        {
            id:3,
            name:"Mark uncollectible",
            subIcon:<MdMoneyOff className="fitIcon"/>,
            route:"/finance/allledger"
        },
        {
            id:4,
            name:"Contact",
            subIcon:<MdCall className="fitIcon"/>,
            route:"/finance/allledger"
        }
    ],
 allLedgerFilterList:[
     {status:["Active","Suspended","Deactivated"],id:"1",name:"status",label:"Status",default:[]},

 ]
}


export default financeUtils