import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Amplify from 'aws-amplify';
import config from './config';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import Axios from 'axios';

// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

Axios.interceptors.response.use(response => {
  if(response.data.code === 1005 && response.data.message === "Sorry, your provided token information expired or not exists.") {
    fetch("https://cognito-idp.ap-south-1.amazonaws.com/", {
    headers: {
      "Access-Control-Allow-Origin": '*',
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    },
    mode: 'cors',
    cache: 'no-cache',
    method: 'POST',
    body: JSON.stringify({
      ClientId: "6smf3dhff7pt8ede6rm51insed",
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: localStorage.getItem('refreshToken'),
        //SECRET_HASH: "your_secret", // In case you have configured client secret
      }
    }),
  }).then((res) => {
    res.json().then(data => {
      localStorage.setItem('tokenid', data.AuthenticationResult.AccessToken);
      localStorage.setItem('expiry', ((new Date().getTime() / 1000) + data.AuthenticationResult.ExpiresIn));
      Axios.defaults.headers.common['Authorization'] = localStorage.getItem('tokenid');
      window.location.reload()
    }); // this will give jwt id and access tokens
  });
  } else {
    return response;
  }
}, err => {
  console.log(err)
  if(err.status === 401) {
    fetch("https://cognito-idp.ap-south-1.amazonaws.com/", {
    headers: {
      "Access-Control-Allow-Origin": '*',
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      "Content-Type": "application/x-amz-json-1.1",
    },
    mode: 'cors',
    cache: 'no-cache',
    method: 'POST',
    body: JSON.stringify({
      ClientId: "6smf3dhff7pt8ede6rm51insed",
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: localStorage.getItem('refreshToken'),
        //SECRET_HASH: "your_secret", // In case you have configured client secret
      }
    }),
  }).then((res) => {
    res.json().then(data => {
      localStorage.setItem('tokenid', data.AuthenticationResult.AccessToken);
      localStorage.setItem('expiry', ((new Date().getTime() / 1000) + data.AuthenticationResult.ExpiresIn));
      Axios.defaults.headers.common['Authorization'] = localStorage.getItem('tokenid');
      window.location.reload()
    }); // this will give jwt id and access tokens
  });
  }
})

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
