import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTable, useRowSelect, useSortBy, useFilters } from 'react-table'
import styled from 'styled-components'
import ScaleLoader from "react-spinners/ScaleLoader";
import Pagination from '../../utils/react-table/pagination'
import { Grid, Button, Select, MenuItem, IconButton, Modal, Checkbox, Backdrop, Slide, Zoom, TableSortLabel, Container } from '@material-ui/core';
import urlLinks from '../../urlLinks';
import * as moment from 'moment'
import Axios from 'axios';
// import Filters from './filterModal'
import SelectFilter from './functions/SelectFilter'
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { ToastContainer, toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import EditableDropdown from "./functions/EditableDropdown"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import AdminNotesModel from './functions/AdminNotes';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
  } from "@material-ui/core/styles";
// import openRecruiterProfile from "./../RecruiterProfile/Communication"
// import EditableDropdown from "./functions/EditableDropdown"
const Styles = styled.div`
        padding: 1rem;
        .tableContainer{
            overFlow-x: scroll;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            height:700px;
        }
        table {
            border-spacing: 0;
            height:685px;
            width: max-content;
            tr {
            :last-child {
                td {
                border-bottom: 0;
                }
            }
            }
            th {
                :first-child{
                    border-top-left-radius:5px;
                }
                :last-child{
                    border-top-right-radius:5px;
                        border-left: 0;
                }
                background-color: #1c75bc !important;

            }
            th,td{
                padding:12px 15px;
            }
            
            td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #cfcfcf;
            border-right: 1px solid #cfcfcf;
            background-color: white;
            word-wrap:break-word;
            nth-last-child(2) {
                border-right: 0;
            } 
            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
            }
        }

        .pagination {
            padding: 0.5rem;
        }
`
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <React.Fragment>
                <Checkbox ref={resolvedRef} {...rest} color="primary" />
            </React.Fragment>
        )
    }
)

const RecruiterVerfication = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [filterUrl, setFilterUrl] = useState('')
    const [sortUrl, setSortUrl] = useState('')
    const [openAdminNotes, setOpenAdminNotes] = useState(false)
    const [row, setRow] = useState(null)
    const [filters, setFilters] = useState({
        recruiterStatus: "ALL"
    })
    const defaultTheme = createMuiTheme();
    const theme = createMuiTheme({
      overrides: {
        MuiTooltip: {
          tooltip: {
            width: "149px",
            height: "67px",
            color: "#1c75c0",
            fontSize: "14px",
            margin: "30px 38px 20px 29px",
            padding: "18px 29px 30px 14px",
            // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            backgroundColor: "#ffffff"
          }
        }
      }
    });
    const BlueOnGreenTooltip = withStyles({
      tooltip: {
        color: "#1c75c0",
        // backgroundColor: "green"
      }
    })(Tooltip);
    // will be needed later
    const handleRecruiterName = (id) => {
        //    let formData
        console.log("HEy")
        const url = `${urlLinks.baseUrl}/recruiter/getRecruiter?recruiterMasterId=${id}`
        Axios.get(url)
            .then(response => {
                if (response) {
                    console.log({ response })
                    localStorage.setItem('userInfo', JSON.stringify(response.data.response.content[0]));
                    // formData=response.data.response.content[0]
                    window.open("/recruiter/profile", "_blank")
                }
                else {
                    // setLoading(false)
                    throw toast.error("No data from API")
                }
            })
            .catch(error => {
                // setLoading(false)
                throw toast.error(error.message)
            })


    }
    const shouldDisable = (props) => {
        if (props.recruiterMasterId === '-')
            return true;
        else
            return false;
    }
    const handleAdminNote = (props) => {
        setRow(props)
        setOpenAdminNotes(true)
    }

    const fixText = (props) => {
        let str = "";
        str = props;
        if (str.length >= 40) {
            str = str.substring(0, 40) + "..."
            return str;
        } else
            return str;
    }
    const columns = useMemo(
        () => [
            // {
            //     id: 'selection',
            //     Header: ({ getToggleAllRowsSelectedProps }) => (
            //         <div>
            //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} style={{color:"white"}} disabled={true} />
            //         </div>
            //         ),
            //         Cell: ({ row }) =>  (
            //             <div>
            //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.invoice_id?false:true} />
            //             </div>
            //             ),
            // },
            //change in emp emp
            {
                Header: "ID",
                accessor: "recruiterMasterId",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Recruiter Name",
                accessor: "fullName",
                // this will be needed later
                Cell: row => {
                    return <button className="linkButton" onClick={() => handleRecruiterName(row.row.original.recruiterMasterId)}>{row.row.original.fullName}</button>
                },

                // Cell:  row => {
                //     return <button className="linkButton"  onClick={()=>openRecruiterProfile(row)}>{row.row.original.fullName}</button>},
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Phone Number",
                accessor: "phoneNumber",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Email",
                accessor: "emailId",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "City",
                accessor: "city",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Recruiter Labels",
                accessor: "label",
                defaultCanFilter: false,
                defaultCanSort: false,
                // Cell: CVuploadDownload,
                isVisible: true
            },
            {
                Header: "Industry Experiences",
                accessor: "industryExperience",
                defaultCanFilter: false,
                // width:200,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Job Function",
                accessor: "jobFunction",
                // width:"200",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Total Experience",
                accessor: "totalExperience",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Documents",
                accessor: "documentStatus",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Status",
                accessor: "recruiterStatus",
                defaultCanFilter: true,           //needed filter
                Filter: SelectFilter,
                defaultCanSort: true,
                isVisible: true,
                Cell: EditableDropdown,

            },
            {
                Header: "Reports To",
                accessor: "accountManager",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            }
            , {
                Header: "Active Vacancies",
                accessor: "activeVacanciesCount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Avg Calls/day",
                accessor: "avgCallPerDayCount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Interview Appearances",
                accessor: "interviewAppearancesCount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Selections",
                accessor: "selectionCount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },

            {
                Header: "Joinings",
                accessor: "joiningCount",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },

            {
                Header: "Conversion Percentage",
                accessor: "conversionRatio",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Registration On",
                accessor: "insertedOn",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true
            },
            {
                Header: "Status Updated",
                accessor: "paymentStatus",
                defaultCanFilter: false,
                defaultCanSort: false,
                Cell: row => {
                    return row.row.original.statusUpdatedOn && <Grid container className="padding0">
                        <Grid item xs={12} className="padding0"><span><b>Updated On: </b>{row.row.original.statusUpdatedOn}</span></Grid>
                        <Grid item xs={12} className="padding0"><span><b>Updated By: </b>{row.row.original.statusUpdatedByName}</span></Grid>
                    </Grid>
                },
                isVisible: true
            },


            {
                Header: "Admin Note",
                accessor: "adminNote",
                defaultCanFilter: false,
                defaultCanSort: false,
                isVisible: true,
                Cell: row => {
                    return (<Grid container className="padding0">{
                        row.row.original.adminNote === null && <Grid container className="padding0">
                            <button className="linkButton" disabled={shouldDisable(row.row.original)} onClick={() => handleAdminNote(row.row.original)}><b><u>Add</u></b></button>
                        </Grid>}
                        {row.row.original.adminNote && <Grid container className="padding0">

                            <Grid item xs={12} className="padding0"><span>{fixText(row.row.original.adminNote)}  </span>
                                <button className="linkButton" onClick={() => handleAdminNote(row.row.original)}><b><u>Edit</u></b></button>
                            </Grid>
                        </Grid>}
                    </Grid>)
                    //     row.row.original.adminNote ==null && <Grid container className="padding0">
                    //     <Grid item xs={12} className="padding0"><span><b>{row.row.original.adminNote} </b></span></Grid>

                    // </Grid>
                }

            },
            {
                Header: "Data Source",
                accessor: "dataForRecruitment",
                // defaultCanFilter: false,
                // defaultCanSort: false,
                Cell: EditableDropdown,
                // isVisible: true
            },
            {
                Header: "Logistics",
                accessor: "haveLaptopSmartphoneWifi",
                // defaultCanFilter: false,
                // defaultCanSort: false,
                Cell: EditableDropdown,
                // isVisible: true
            },

        ],
        []
    );

    const showToast = (type, message) => {
        if (type === "success")
            return toast.success(message);
        else if (type === "error")
            return toast.error(message);
    }

    const updateMyData = () => {
        getTrackerList(perPage, currentPage);
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, getToggleHideAllColumnsProps, toggleAllRowsSelected, setHiddenColumns, state: { hiddenColumns, selectedRowIds, sortBy } } = useTable({ columns, data, updateMyData, showToast, setLoading, filters, setFilters, manualSortBy: true, }, useFilters, useSortBy, useRowSelect)

    //added by Vaibhav
    const makeFullName = (props) => {
        if (props.firstName == null && props.lastName == null) {
            return '-'
        }
        else if (props.firstName != null && props.lastName != null) {
            return props.firstName + " " + props.lastName
        }

    }
    const makeExperience = (props) => {
        let expYear = props.workExpYears
        // let ys='years'
        let y;
        if (expYear === 1) {
            y = ' year '
        } else {
            y = ' years '
        }
        let expMonth = props.workExpMonths

        let m;
        if (expMonth === 1) {
            m = ' month '
        } else {
            m = ' months '
        }



        if (props.workExpYears != null && props.workExpMonths != null) {
            return expYear + y + expMonth + m;
        } else if (props.workExpYears != null && props.workExpMonths === null) {
            return expYear + y;
        } else if (props.workExpYears === null && props.workExpMonths != null) {
            return expMonth + m;
        } else {
            return '-';
        }

    }
    const makeData = (response) => {

        let extractedData = [];
        response.content.map(el => {
            console.log({ el })
            extractedData.push({
                recruiterMasterId: el.recruiterMasterId == null ? '-' : el.recruiterMasterId,
                userId: el.userId == null ? '-' : el.userId,
                fullName: makeFullName(el),
                phoneNumber: el.phoneNumber == null ? '-' : el.phoneNumber,
                emailId: el.emailId == null ? '-' : el.emailId,
                city: el.city == null ? '-' : el.city,
                cityId: el.cityId == null ? '-' : el.cityId,
                label: el.label == null ? '-' : el.label,
                totalExperience: makeExperience(el),
                documentStatus: el.documentStatus == null ? '-' : el.documentStatus,
                recruiterStatus: el.recruiterStatus == null ? '-' : el.recruiterStatus,
                accountManager: el.accountManager == null ? '-' : el.accountManager,
                insertedOn: el.insertedOn == null ? '-' : moment(el.insertedOn).format("DD/MM/YYYY"),
                statusUpdatedOn: el.statusUpdatedOn == null ? null : moment(el.statusUpdatedOn).format("DD/MM/YYYY"),
                statusUpdatedby: el.statusUpdatedby == null ? '-' : el.statusUpdatedby,
                statusUpdatedByName: el.statusUpdatedByName == null ? '-' : el.statusUpdatedByName,
                adminNote: el.adminNote == null ? null : el.adminNote,
                industryExperience: el.industryExperienceMap === null ? '-' : Object.values(el.industryExperienceMap).join(", "),
                jobFunction: el.jobFunctionsMap === null ? '-' : Object.values(el.jobFunctionsMap).join(", "),
                haveLaptopSmartphoneWifi: el.haveLaptopSmartphoneWifi == null ? '-' : el.haveLaptopSmartphoneWifi,
                dataForRecruitment: el.dataForRecruitment === null ? '-' : el.dataForRecruitment,
                selectionCount: el.selectionCount == null ? '-' : el.selectionCount,
                avgCallPerDayCount: el.avgCallPerDayCount == null ? '-' : el.avgCallPerDayCount,
                joiningCount: el.joiningCount == null ? '-' : el.joiningCount,
                interviewAppearancesCount: el.interviewAppearancesCount == null ? '-' : el.interviewAppearancesCount,
                activeVacanciesCount: el.activeVacanciesCount == null ? '-' : el.activeVacanciesCount,
                conversionRatio: el.conversionRatio == null ? '-' : el.conversionRatio+" %",
            });
        });
        console.log("Hello makeData ended");
        return extractedData;
    }

    const getTrackerList = (rows, page) => {
        const url = `${urlLinks.baseUrl}/recruiter/getRecruiter?page_no=${page - 1}&rows=${rows}${filterUrl}${sortUrl}`
        Axios.get(url)
            .then(response => {
                if (response) {
                    console.log({ response })
                    setTotalRows(response.data.response.totalElements)
                    setTotalPages(response.data.response.totalPages)
                    setData(makeData(response.data.response))
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    throw toast.error("No data from API")
                }
            })
            .catch(error => {
                setLoading(false)
                throw toast.error("No data from API")
            })
    }

    useEffect(() => {
        setLoading(true)
        getTrackerList(perPage, 1);

    }, [filterUrl, sortUrl])

    const handlePageChange = page => {
        setLoading(true)
        getTrackerList(perPage, page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setLoading(true)
        getTrackerList(newPerPage, 1);
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const loader = <div style={{ margin: "auto", position: "sticky", left: "45%", top: "30%" }}>
        <ScaleLoader
            height={70}
            width={10}
            color={"#1c75bc"}
        />
    </div>

    //Filters will be needed later
    const ApplyFilter = () => {
        let url = '';
        
        if (filters["recruiterStatus"]!=="ALL")

            url = `${url}&status=${filters["recruiterStatus"]}`
        // if (!filters["invoice_date"][0] && filters["invoice_date"][1])
        //     url = `${url}&invoiceDateFrom=2019-01-01&invoiceDateTo=${moment(filters["invoice_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        // if (filters["invoice_date"][0] && filters["invoice_date"][1])
        //     url = `${url}&invoiceDateFrom=${moment(filters["invoice_date"][0]).format("YYYY-MM-DD")}&invoiceDateTo=${moment(filters["invoice_date"][1]).format("YYYY-MM-DD")} 23:59:59`



        // if (filters["payment_date"][0] && !filters["payment_date"][1])
        //     url = `${url}&paymentDateFrom=${moment(filters["payment_date"][0]).format("YYYY-MM-DD")}&paymentDateTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`

        // if (!filters["payment_date"][0] && filters["payment_date"][1])
        //     url = `${url}&paymentDateFrom=2019-01-01&paymentDateTo=${moment(filters["payment_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        // if (filters["payment_date"][0] && filters["payment_date"][1])
        //     url = `${url}&paymentDateFrom=${moment(filters["payment_date"][0]).format("YYYY-MM-DD")}&paymentDateTo=${moment(filters["payment_date"][1]).format("YYYY-MM-DD")} 23:59:59`

        setFilterUrl(url)
    }

    useEffect(() => {
        ApplyFilter();
    }, [filters])
    const handleExternalFilters = (list) => {
        setFilters({ ...filters, ...list })
    }
    const resetFilters = () => {
        let tempFilters = {
            recruiterStatus:"ALL"
        }
        setFilters(tempFilters)
        return tempFilters;
    }

    const table = <div className="tableContainer">
        <table {...getTableProps()} className="table">
            <thead className="thead">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                className={
                                    column.render('Header') === "Recruiter Name" ? "stickEmployerLedger zIndex1080 header" :
                                        column.render('Header') === "Job Function" ? "width200px header" :
                                            column.render('Header') === "Industry Experiences" ? "width200px header" :
                                                column.render('Header') === "Reports To" ? "width200px header" :
                                                    column.render('Header') === "Admin Note" ? "width200px header" :"header"
                                                    // column.render('Header') === "Data Source" ? "displayFlex " : "header"
                                }>


                                {/* <span {...column.getSortByToggleProps()}> */}
                                <span>
                                    {column.id !== 'selection' ?
                                        <div>
                                            {column.render('Header')}
                                            {column.Header === "Logistics" &&
                                                <Tooltip title="Wifi/ Laptop/ Smartphone" arrow>
                                                    <InfoOutlinedIcon style={{ fontSize: "1.3rem", marginLeft: "8px" }}>
                                                    </InfoOutlinedIcon>
                                                </Tooltip>}

                                            {column.Header === "Data Source" ?
                                            <MuiThemeProvider theme={theme}>
                                             <Tooltip title="OkayGo/ Self/ Both" arrow>
                                                  <span>  <InfoOutlinedIcon style={{ fontSize: "1.3rem", marginLeft: "8px" }}></InfoOutlinedIcon> </span>
                                                </Tooltip></MuiThemeProvider> : null}

                                            {/* <TableSortLabel
                                                style={{ color: "white" }}
                                                active={column.isSorted}
                                                direction={column.isSortedDesc ? 'asc' : 'desc'}
                                            /> */}
                                        </div> : column.render('Header')}
                                </span>
                                {/* <Tooltip title="Add" className={"s"} arrow>  </Tooltip>*/}
                                <div>{column.defaultCanFilter ? column.render('Filter') : null}</div>
                                </th>
                        ))}
                    </tr>
                ))}
            </thead>
            {
                loading ?
                    loader :
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="row">
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}
                                            className={
                                                cell.column.Header === "Recruiter Name" ? "stickEmployerLedger zIndex540" : ""
                                            }>
                                            {cell.render('Cell')}

                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
            }
        </table>
    </div>


    return (
        <Styles>
            <ToastContainer
                position='top-right'
                hideProgressBar
                newestOnTop={true}
                closeOnClick
                autoClose={1500}
                rtl={false}
                draggable
                pauseOnHover
            ></ToastContainer>

            <Grid container >
                <Typography style={{ fontWeight: " bold", fontSize: "2em" }} variant="h4" component="h2">
                    Recruiter Listing
          </Typography>
                <Grid item xs={12} className="buttonList paddingHorizontal" >
                    {/* this will be needed later */}
                    {/* <Grid item xs={8} style={{ padding: 0 }}>
                        <Button
                            variant="contained"
                            className="dataButton"
                        >
                            <CSVLink
                                data={data}
                                filename={`Employer-Ledger-${(moment().format("DD/MM/YYYY HH:mm"))}.csv`}
                                className='btn btn-primary'
                                target='_blank'
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    fontWeight: '500'
                                }}
                            >
                                Export To Excel
                            </CSVLink>
                        </Button>
                    </Grid> */}
                    <Grid item xs={4} style={{ padding: 0 }} className="buttonList marginRightForChildExceptLast " direction="row-reverse">

                        {/* <Filters
                            filters={filters}
                            updateFilters={handleExternalFilters}
                            resetFilters={resetFilters}
                            isFilterApplied={isFilterApplied}
                            setIsFilterApplied={setIsFilterApplied}
                        /> */}

                    </Grid>
                </Grid>
                {openAdminNotes && <AdminNotesModel
                    openAdminNotes={openAdminNotes}
                    setOpenAdminNotes={setOpenAdminNotes}
                    noteData={row.adminNote}
                    recruiterMasterId={row.recruiterMasterId}
                    updateMyData={updateMyData}

                    showToast={showToast}
                />
                }
                <Grid item xs={12} style={{ padding: 0 }}>
                    {table}
                    <Pagination
                        rowsperpage={perPage}
                        pageNo={currentPage}
                        totalElements={totalRows}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Grid>

            </Grid>

        </Styles>
    )
}
export default RecruiterVerfication;