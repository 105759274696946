import React from 'react';
import { MdComment, MdModeEdit, MdLibraryAdd } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import Axios from 'axios';
import urlLinks from '../../../urlLinks';
import utils from '../../../utilMethods';

const cities = {};
const industries = [];
const status = {};
const company=[]

const employerList = (cities, industries) => {
  const employerFilterList = [
    {
      industry: Object.keys(industries),
      industries: { ...industries },
      id: '1',
      name: 'industry',
      typeName: 'industries',
      label: 'Industry',
      default: []
    },
    {
      location: Object.keys(cities),
      cities: { ...cities },
      id: '2',
      name: 'location',
      typeName: 'cities',
      label: 'Location',
      default: []
    },
    {
      employer_status: Object.keys(status),
      statusId: { ...status },
      id: '3',
      name: 'employer_status',
      typeName: 'statusId',
      label: 'Status',
      default: []
    },
    {
      company_name: Object.keys(company),
      company: { ...company },
      id: '4',
      name: 'company_name',
      typeName: 'company',
      label: 'Brand Name',
      default: []
    },
    // {
    //   rating: ['1', '2', '3', '4', '5'],
    //   ratingId: {
    //     1: 1,
    //     2: 2,
    //     3: 3,
    //     4: 4,
    //     5: 5
    //   },
    //   id: '4',
    //   name: 'rating',
    //   typeName: 'ratingId',
    //   label: 'Rating',
    //   default: []
    // },
    // {
    //   addedBy: ['Admin Member', 'Sagar Swami', 'Kunal Chugh', 'Leader'],
    //   addedById: {
    //     'Admin Member': 1,
    //     'Sagar Swami': 2,
    //     'Kunal Chugh': 3,
    //     Leader: 4
    //   },
    //   id: '5',
    //   name: 'addedBy',
    //   typeName: 'addedById',
    //   label: 'Added By',
    //   default: []
    // }
  ];
  return employerFilterList;
};

export const getEmployerFilterList = async () => {
  const validExpiry = await utils.checkValidExpiry();
  if(validExpiry) {
    await Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_sub_type=city_list&category_type=job_city`
    ).then(response => {
      for (const city of response.data.response.content) {
        cities[city.typeValue] = city.id;
      }
    });

    await Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_sub_type=industry&category_type=job_type`
    ).then(response => {
      
      for (const industry of response.data.response.content) {
        industries[industry.typeDesc] = industry.id;
      }
      
    });

    await Axios.get(
      `${urlLinks.baseUrl}/configmaster/?category_type=user_status`
    ).then(response => {
      for (const statusType of response.data.response.content) {
        status[statusType.typeKey] = statusType.typeValue;
      }
    });
    await Axios.get(
      `${urlLinks.baseUrl}admin/companies`
    ).then(response => {     
      console.log(response.data.response);
       
      for (const c of response.data.response) {

        company[c.brandName?c.brandName:c.companyName]=c.companyId
      }
    });
    
  }
    const list = await employerList(cities, industries);
    
  return list;
};

const employerUtils = {
  employerListAction: [
    {
      id: 1,
      name: 'View Details',
      subIcon: <FaEye className='fitIcon' />,
      route: '/employer/employerLists/viewEmployer'
    },
    {
      id: 2,
      name: 'Edit Details',
      subIcon: <MdModeEdit className='fitIcon' />,
      route: '/employer/employerLists/editEmployer'
    },
    {
      id: 3,
      name: 'Add Job',
      subIcon: <MdLibraryAdd className='fitIcon' />,
      route: '/jobs/addJob'
    },
    {
      id: 4,
      name: 'Comments',
      subIcon: <MdComment className='fitIcon' />,
      route: '/employer/employerLists/commentSection'
    }
  ]
};

export default employerUtils;
