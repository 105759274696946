import React, { useState, useEffect } from "react"
import { Grid, Button, Checkbox } from '@material-ui/core'
import EditOpt from './editOpt'
import file from '../../../assets/file.svg'
import phone from '../../../assets/phone.svg'
import whatsapp from '../../../assets/whatsapp.svg'
import WorkerModal from '../../Job/InterviewDashboard/JobTabs/functions/workerModal'
import ExternalWorkerModal from '../../worker/externalWorker/externalWorkerModal'
import envelope from '../../../assets/envelope.svg'
import wallet from '../../../assets/wallet.svg'
import NewTabExternalWorker from '../../worker/workerView/newTabExternalWorker'
import newTabInternalWorker from '../../worker/workerView/newTabInternalWorker'
import '../../worker/externalWorker/externalWorker.css'
// import validator from 'validator'
import * as Axios from 'axios'
import urlLinks from '../../../urlLinks'
import isEmail from 'validator/es/lib/isEmail';

// Style part
const iconImg = {
    width: "18px",
    height: "18px",
    marginRight: "15px"
},
    space12 = {
        marginBottom: "12px"
    },
    colorBlue = {
        color: "#1c75bc",
        marginLeft: "20px",
        fontSize: "14px"
    },
    color333 = {
        color: "#333333"
    },
    NameButton = {
        color: "#1c75bc",
        fontSize: "16.8px",
        fontWeight: "bold",
        textAlign: "left",
        display: "block"
    },
    NameButton2 = {
        color: "#1c75bc",
        fontSize: "14px",
        fontWeight: "bold",
        textAlign: "left",
        display: "block"
    },
    space15 = {
        marginBottom: "15px"
    },
    space10 = {
        marginBottom: "10px"
    },
    checkBoxIp = {
        height: "30px",
        marginRight: "36px"
    },
    fontSm = {
        fontSize: '8px !important'
    };


const CandidateDetails = (props) => {
    const { row, updateMyData, showToast } = props
    const [workerDetails, setWorkerDetails] = useState({})
    const inputEl = React.useRef(null);

    const [userId, setUserId] = useState()
    const [candidateDetail, setCandidateDetail] = useState(row.original)
    const [hubName, setHubName] = useState("")
    console.log(row.original)
    //    const handleEmail =() => {
    //         validator.isEmail(row.original.email)
    //         // var eVal = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    //         // if (eVal.test(row.original.email) === false) {
    //         //     return true
    //         // }
    //         // else{
    //         //     return false
    //         // }
    //    }
    useEffect(() => {
        console.log("started candidateDetail")
        let str ="";
        if(row.original.city!==null){
            str=str+row.original.city;
        }
        if(row.original.city!==null && row.original.locality!==null){
            str=str+ "/ "+row.original.locality;
        }
        if(row.original.city===null && row.original.locality!==null){
            str=str+row.original.locality;
        }

        if(str===""){
            setHubName(null);
        }else{
            setHubName(str);
        }
    }, [])
    const handleCVUpload = (e) => {
        const formData = new FormData();
        const cvData = {
            name: e.target.files[0].name,
            file: e.target.files[0]
        }
        // console.log({cvData})
        formData.append('file', cvData.file)
        formData.append('user_id', row.original.userId)

        Axios.post(`${urlLinks.baseUrl}userdocs/getDocLink`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(response => {
                // console.log({response})
                if (response.data.code === 1000) {
                    Axios.post(`${urlLinks.baseUrl}workermaster/AddUpdateCVLink?cv_file_name=${cvData.name}&link=${response.data.response}&worker_id=${row.original.workerId}`)
                        .then(response => {
                            showToast("success", "CV uploaded successfully");
                            updateMyData();
                        })
                        .catch(error => {
                            showToast("error", "CV upload failed");
                        })
                }
                else {
                    showToast("error", "CV conversion failed");
                }
            })
            .catch(error => {
                showToast("error", "CV upload failed");
            })
    }
    const handleWorkerDetail = (row) => {
        if (row.original.intExt === "INTERNAL") {
            const data = {
                userId: row.original.userId,
                workerId: row.original.workerId,
                jobDetailsId: row.original.jobDetailsId,
                isWorkerHistory: true
            }
            localStorage.setItem("internalWorkerData", JSON.stringify(data))
            window.open("/internalworkerview", "_blank")
        }
        else {
            const data = {
                userId: row.original.userId,
            }
            localStorage.setItem("externalWorkerData", JSON.stringify(data))
            window.open("/externalworkerview", "_blank")
        }
    }
    //    const handleWpNo =() => {
    //        validator.isMobilePhone(row.original.whatsappNumber)
    //         // const reg = /^[0]?[789]\d{9}$/;
    //         // if(reg.test(row.original.whatsappNumber) === false){
    //         //     return false
    //         // }
    //         // else{
    //         //     return true
    //         // }
    //    }
    //    const handlePhNo =() => {
    //     validator.isMobilePhone(row.original.phoneNumber)
    //     // const reg = /^[0]?[789]\d{9}$/;
    //     // if(reg.test(row.original.phoneNumber) === false){
    //     //     return false
    //     // }
    //     // else{
    //     //     return true
    //     // }
    //     }

    return (
        <Grid container style={{ width: "250px" }}>
            <Grid className="flexDisplay padding0" direction="row" justify="flex-start">
                <Grid className="flexDisplay padding0" direction="column" justify="flex-start">
                    <Grid item xs={12} className="flexDisplay padding0" direction="column" justify="flex-start">
                        <Button className="linkButton padding0" style={NameButton} onClick={() => handleWorkerDetail(row)}>{row.original.name}</Button>
                        {hubName!=null && <span className="padding0" style={NameButton2} >{hubName}</span>}
                        <span style={space10}>{row.original.workerIdLabel} &#124; &nbsp;{row.original.dataSource}</span>
                    </Grid>
                    <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12} >
                        <img src={file} alt="file" style={iconImg} />
                        <span className="padding0 textCapitalize" style={color333}>CV</span>
                        {row.original.cvLink && <a
                            href={row.original.cvLink}
                            color="transparent"
                            target="_blank"

                        >
                            <Button
                                className="padding0 textCapitalize linkButton fontBold600"
                                disabled={!row.original.cvLink}
                                style={{ marginLeft: "8px", marginTop: "-2px" }}
                            >
                                Download
                            </Button>
                        </a>}
                        <input
                            accept="image/*,.doc, .docx, .txt,.pdf"
                            style={{ display: "none" }}
                            id={row.original.workerId}
                            onChange={handleCVUpload}
                            // multiple
                            type="file"
                            ref={inputEl}
                        />
                        <label htmlFor={row.original.workerId}>
                            <Button className="linkButton textCapitalize fontBold600" style={{ paddingBottom: "0px", paddingTop: "0px", marginTop: "-2px" }} component="span">
                                {row.original.cvLink ? "Update" : "Upload"}
                            </Button>
                        </label>
                    </Grid>

                    <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12}>
                        <img src={phone} alt="phone" style={iconImg} />
                        <EditOpt
                            updateMyData={updateMyData}
                            value={row.original.phoneNumber}
                            // onChange={handlePhNo}
                            showToast={showToast}
                            userId={row.original.userId}
                            int_ext={row.original.intExt}
                            type="number"
                            id={`phone`}
                        />
                    </Grid>
                    <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12}>
                        <img src={whatsapp} alt="whatsapp" style={iconImg} />
                        <EditOpt
                            value={row.original.whatsappNumber}
                            // onChange={handleWpNo}
                            userId={row.original.userId}
                            showToast={showToast}
                            type="number"
                            id={`whatsApp`}
                            updateMyData={updateMyData}
                        />
                    </Grid>
                    <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12}>
                        <img src={envelope} alt="envelope" style={iconImg} />
                        <EditOpt
                            updateMyData={updateMyData}
                            userId={row.original.userId}
                            showToast={showToast}
                            value={row.original.email}
                            // onChange={handleEmail}
                            id={`email`}
                        />
                        {/* <span className="padding0 textCapitalize" component="button" style={color333}>nikhil@gmail.com</span>
                        <Button className="padding0" style={colorBlue}>Edit</Button> */}
                    </Grid>

                    { ((row.original.isRecruiterPaid === null || row.original.isRecruiterPaid === 0 )&& row.original.recruiterPayout!==null) && <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12}>
                        <img src={wallet} alt="wallet" style={iconImg} />
                        <Grid item xs={12} style={{ paddingRight: "10px", paddingTop: "0px", display: 'ruby' }}> <b>Expected Earning:</b> &nbsp; {row.original.recruiterPayout}</Grid>
                    </Grid>}
                    {row.original.isRecruiterPaid === 1 && <Grid item xs={12} className="flexDisplay padding0" direction="row" justify="flex-start" style={space12}>
                        <img src={wallet} alt="wallet" style={iconImg} />
                        <Grid item xs={12} style={{ paddingRight: "10px", paddingTop: "0px", display: 'ruby' }}> <b>Amount Earned:</b> &nbsp; {row.original.recruiterPayout}</Grid>
                    </Grid>}
                </Grid>
            </Grid>
            {/* {openWorkerModal?<WorkerModal
                data={workerDetails}
                openWorkerModal={openWorkerModal}
                setOpenWorkerModal={setOpenWorkerModal}
                isWorkerHistory={true}
            />: null}
            {openExternalWorkerModal?<ExternalWorkerModal
                userId= {userId}
                newTab={true}
                openExternalWorkerModal={openExternalWorkerModal}
                setOpenExternalWorkerModal={setOpenExternalWorkerModal}
            />: null} */}
        </Grid>
    );
}

export default CandidateDetails