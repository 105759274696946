import React from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const FormikRadioGroup = ({
  field,
  form: { touched, errors, values },
  name,
  options,
  ...props
}) => {
  const fieldName = name || field.name;
  
  return (
    <Grid container spacing={0}>
      <Grid container spacing={0}>
        <RadioGroup {...field} {...props}  name={fieldName} value={values[fieldName]} className="rGroup">
          {options.map(option => (
            <FormControlLabel value={option} control={<Radio color= "primary" />} label={option} />
          ))}
        </RadioGroup>
      </Grid>
      <Grid container spacing={0}>
      <p style={{color:"red"}}>{touched[fieldName] && errors[fieldName]}</p>
      </Grid>
    </Grid>
  );
};

export default FormikRadioGroup;