import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link as aLink } from '@material-ui/core';
import { Link } from 'react-router-dom';
import qs from 'querystring'
import Axios from 'axios';
import urlLinks from '../../urlLinks'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';

import {
  Button,
  Toolbar,
  Typography,
  Checkbox,
  TablePagination
} from '@material-ui/core';
import CustomPopover from '../customPopOver/customPopover';
import CustomLinkPopover from '../customPopOver/customLinkPopover';
import { ScaleLoader } from 'react-spinners';

const useStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  title: {
    flex: '1 1 100%'
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  colvisible: {
    display: 'table-cell'
  },
  colhide: {
    display: 'none'
  },
  tabcell: {
    padding: '2px 10px',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap'
  },
  notificationButton: {
    marginBottom: '0%',
    marginTop: '1%',
    marginLeft: '2%'
  }
});
class CustomTable extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      selectedRows: [],
      isClicked: this.props.isOpened,
      data: props.data
    };
    this.tableLoader = (
      <TableRow style={{ height: '300px', position: 'relative' }}>
        <TableCell colSpan={100}>
          <ScaleLoader
            color={'#3F75B3'}
            css={{
              textAlign: 'center'
            }}
          ></ScaleLoader>
        </TableCell>
      </TableRow>
    );
    this.checkBoxesVisible = true;
    this.numericIndex = false;
    this.headerChecked = false;
    this.getRoWData = this.getRoWData.bind(this);
    this.getTableCellElement = this.getTableCellElement.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleClickOnRow = this.handleClickOnRow.bind(this);
    this.checkRowIsSelected = this.checkRowIsSelected.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  getRoWData(data, cols, classes) {
    const rowData = data.map((row, index) => {
      // console.log(row)
      // console.log(row.joiningStatus)
      const isItemSelected = this.checkRowIsSelected(row[this.props.name]);
      if(this.props.title === 'Hired Workers Details' && row.joiningStatus === 2) {
        return null
      } else {
        return (
          <TableRow
            key={row[this.props.name] ? row[this.props.name] : index + 1}
            selected={isItemSelected}
            style={
              index % 2 ? { background: '#f2f2f2' } : { background: 'white' }
            }
          >
            <TableCell
              padding='checkbox'
              className={
                this.checkBoxesVisible ? classes.colvisible : classes.colhide
              }
            >
              {/* {console.log(row)} */}
              {this.props.name === 'interviewId' ? (
                row.transactionUniqueId === '-' ? (
                  <Checkbox
                    checked={isItemSelected}
                    onChange={event => {
                      this.handleClickOnRow(event, row[this.props.name]);
                    }}
                  />
                ) : null
              ) : row.assign_id ? (
                this.props.isPaid.includes(row.assign_id) ? (
                  <Checkbox
                    checked={isItemSelected}
                    onChange={event => {
                      this.handleClickOnRow(event, row[this.props.name]);
                    }}
                  />
                ) : (
                  <Checkbox
                    checked={isItemSelected}
                    onChange={event => {
                      this.handleClickOnRow(event, row[this.props.name]);
                    }}
                  />
                )
              ) : (
                <Checkbox
                  checked={isItemSelected}
                  onChange={event => {
                    this.handleClickOnRow(event, row[this.props.name]);
                  }}
                />
              )}
              {/* {
                row.isPaid === 'No' ? (
                  <Checkbox
                    checked={isItemSelected}
                    onChange={event => {
                      this.handleClickOnRow(event, row[this.props.name]);
                    }}
                  />
                ) : null
              } */}
              {/* {!row.isPaid ? (
                this.props.isPaid.includes(row.assign_id) ? (
                  <Checkbox
                    checked={isItemSelected}
                    onChange={event => {
                      this.handleClickOnRow(event, row[this.props.name]);
                    }}
                  />
                ) : (
                  <Checkbox
                    checked={isItemSelected}
                    onChange={event => {
                      this.handleClickOnRow(event, row[this.props.name]);
                    }}
                  />
                )
              ) : (
                <Checkbox
                  checked={isItemSelected}
                  onChange={event => {
                    this.handleClickOnRow(event, row[this.props.name]);
                  }}
                />
              )} */}
            </TableCell>
            <TableCell
              className={`${
                this.numericIndex ? classes.colvisible : classes.colhide
              } ${classes.tabcell}`}
            >
              {index + 1}
            </TableCell>
            {this.getTableCellElement(row, cols)}
          </TableRow>
        );
      }
    });
    return rowData;
  }

  checkRowIsSelected(id) {
    return this.state.selectedRows.indexOf(id) !== -1;
  }

  getTableHeaderCols(cols) {
    const classes = this.props.classes;
    const colData = cols.map(col => {
      return (
        <TableCell
          key={col.id}
          align='center'
          className={`${
            col.isColumnVisible ? classes.colvisible : classes.colhide
          } ${classes.tabcell}`}
        >
          {col.label}
        </TableCell>
      );
    });
    return colData;
  }
  getTableCellElement(row, cols) {
    // console.log(row)
    const classes = this.props.classes;
    const lastColumn = cols.length - 1;
    const Tablecells = cols.map((column, index) => {
      return index !== lastColumn ? (
        <TableCell
          key={column.id}
          align='center'
          // onClick={this.setState({isOpen: true})}
          className={`${
            column.isColumnVisible ? classes.colvisible : classes.colhide
          } ${classes.tabcell}`}
        >
          {column.name === 'userName' || column.name === 'first_name' ? 
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              to={{
                pathname: '/worker/workerList/viewWorker',
                id: row.userId || row.user_id,
              }}
            >
              {row[column.name]}
            </Link>
          ) : 
          (column.name === 'joining_date' && row['joining_date'] !== '-' && row['is_joined'] !== 'Yes') || 
          (column.name === 'updateHiredSalary' && row['updateHiredSalary'] === 'Update Salary' && row['transactionUniqueId'] === '-') || 
          // (column.name === 'verifyOtp' && row['verifyOtp']==='Verify' && row['isInterview']!=='Create Slots' && row['whichSlot'] !== '-') ||
          // (column.name === 'rescheduleInterview' && row['rescheduleInterview'] === 'ReSchedule Interview') || 
          (column.name === 'invoiceAmount' && row['updateSalary'] !== 'Offer Job' && row['isInterview']!=='Create Slots' && row['invoiceAmount']!=='Invoice Generated') || 
          (column.name === 'selectSlot'  && row['whichSlot'] === '-' && row['isInterview']!=='Create Slots') ||
          (column.name === 'updateStatus'  && row['updateStatus'] === 'Update Status') || 
          (column.name === 'updateSalary' && row['updateSalary'] === 'Offer Job' && row['verifyOtp']==='Done') || 
          (column.name === 'workingStatus' && row['workingStatus'] === 'Working Status' && row['joining_date'] !== 'Confirm') || 
          (column.name === 'rejectOffer' && row['rejectOffer'] === 'Update Offer' && row['verifyOtp']==='Done') ? (
            <CustomLinkPopover name={row[column.name]} updateParentData={this.props.updateParentData} updateHireData={this.props.updateHireData} updateHiredData={this.props.updateHiredData} jobDetailId={this.props.jobDetailId} jobData={this.props.jobData} columnName={column.name} row={row} />
          ) : column.name === 'applyJob' ? 
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handeApplyWorker(row)}
            >
              {row[column.name]}
            </Link>
          ) : 
          (column.name === 'verifyOtp' && row['verifyOtp']==='Verify' && row['isInterview']!=='Create Slots' && row['whichSlot'] !== '-')  ?
            (
              <Link
                style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
                onClick={ () => this.handleVerifyOtp(row)}
            >
              {row[column.name]}
            </Link>
            ) :
          (column.name === 'startDate' && row['startDate'] === 'Update CheckIn') ?
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handeCheckIn(row)}
            >
              {row[column.name]}
            </Link>
          ) : 
          (column.name === 'cancelInterview' && row['cancelInterview'] === 'Cancel') ?
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handleCanceInterview(row)}
            >
              {row[column.name]}
            </Link>
          ) : 
          (column.name === 'workingStatus' && row['workingStatus'] === 'Mark Joined' && row['joining_date'] !== 'Confirm') ?
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handleJoinWorker(row)}
            >
              {row[column.name]}
            </Link>
          ) :
          (column.name === 'markWorkerLeft') ?
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handleWorkerLeft(row)}
            >
              {row[column.name]}
            </Link>
          ) :
          (column.name === 'acceptJob' && row['acceptJob'] === 'Accept Job') ?
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handleAcceptJob(row)}
            >
              {row[column.name]}
            </Link>
          ) :
          (column.name === 'isNoShow' && ( row['isNoShow'] === 'No Show' || row['isNoShow'] === 'Show' ) && row['whichSlot'] !== '-') ?
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handleNoShow(row)}
            >
              {row[column.name]}
            </Link>
          ) :
          (column.name === 'isInterview' && row['isInterview'] === 'Create Slots') ? 
          (
            <Link
            key={row.interviewId}
            style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
            to={{ pathname: `/interview/addInterview/`, data: row, jobData: this.props.jobData, jobDetailId: this.props.jobDetailId }}
            // { data: row }
            // component={row}
          >
            {row[column.name]}
          </Link>
          ) :
          (column.name === 'rescheduleInterview' && row['rescheduleInterview'] === 'ReSchedule Interview') ? 
          (
            <Link
            key={row.interviewId}
            style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
            to={{ pathname: `/interview/addInterview/`, interviewId: row.interviewId, columnName: 'rescheduleInterview', data: row, jobData: this.props.jobData, jobDetailId: this.props.jobDetailId }}
            // { data: row }
            // component={row}
          >
            {row[column.name]}
          </Link>
          ) :
          // (column.name === 'rejectOffer' && row['rejectOffer'] === 'Reject Offer' && row['verifyOtp']!=='Verify') ? 
          // (
          //   <Link
          //   style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
          // //     onClick={ () => this.handleRejectOffer(row)}
          // //   >
          // //     {row[column.name]}
          // //   </Link>
          // ) :
          (column.name === 'rejectCandidate' && row['verifyOtp']==='Done' && row['rejectCandidate'] === 'Reject Candidate') ?
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handleRejectCandidate(row)}
            >
              {row[column.name]}
            </Link>
          ):
          (column.name === 'cancelInterview' && row['cancelInterview'] === 'Cancel')?(
            <Link 
            style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
            onClick={ () => this.handeCheckIn(row)}
            >
            Cancel
            </Link>
          )
          :
          (column.name === 'endDate' && row['endDate'] === 'Update CheckOut') ?
          (
            <Link
              style={{cursor: 'pointer', textDecoration: 'underline', color: '#3f51b5'}}
              onClick={ () => this.handeCheckOut(row)}
            >
              {row[column.name]}
            </Link>
          )
           : (
            row[column.name]
          )}
        </TableCell>
      ) : (
        <TableCell
          key={column.id}
          className={`${
            column.isColumnVisible ? classes.colvisible : classes.colhide
          } ${classes.tabcell}`}
        >
          {column.isColumnVisible ? (
            <CustomPopover
              id={row[this.props.name]}
              links={this.props.links}
            ></CustomPopover>
          ) : null}
        </TableCell>
      );
    });

    return Tablecells;
  }

  handleCanceInterview = (row) => {
    // console.log(row)
    // console.log(this.props.jobData)
    const data = {
      job_detail_id: this.props.jobDetailId,
      job_location_lat: row.jobLocationLat,
      job_location_long: row.jobLocationLong,
      land_mark: row.landMark,
      address: row.address,
      postal_code: row.postalCode,
      requested_by: this.props.jobData.insertedBy,
      user_id: row.user_id,
      interview_id: row.interviewId,
      interview_status: 0,
      interview_start_time: '2020-06-02 15:00:00',
      interview_end_time: '2020-06-02 21:00:00',
      // interview_start_time2: null,
      // interview_end_time2: null,
      // interview_start_time3: null,
      // interview_end_time3: null
    }
    console.log(data)
    Axios.post(`${urlLinks.baseUrl}interview/schedule`, qs.stringify(data))
    .then(res => {
      console.log(res)
      this.props.updateHiredData()
    })
    .catch(err => console.log(err));
  };

  handleJoinWorker = (row) => {
    const data = {
      interview_id: row.interviewId,
      requested_by: localStorage.getItem('userId'),
      is_joined: 1
    }
    Axios.post(`${urlLinks.baseUrl}/interview/userJoined`, qs.stringify(data))
    .then(res => this.setState({isSubmitting: false}, () => {
      window.location.reload();
      // this.props.updateHiredData();
      // this.props.updateParentData();
      // this.props.updateHireData();
    }))
  }

  handleVerifyOtp = (row) => {
    const data = {
      interview_id: row.interviewId,
      otp: row.otp,
      requested_by: localStorage.getItem('userId')
    }
    Axios.post(`${urlLinks.baseUrl}/interview/verifyOtp`, qs.stringify(data))
    .then(res => this.props.updateHiredData())
  }

  handleNoShow = (row) => {
    // console.log(`Not Showed`)
    console.log(row)
    const data = {
      interview_id: row.interviewId,
      is_no_show: row.isNoShow === 'Show' ? 0 : row.isNoShow === 'No Show' ? 1 : null,
      updated_by: this.props.jobData.userId
    }
    Axios.post(`${urlLinks.baseUrl}/interview/noshow`, qs.stringify(data))
    .then(res => {
      // console.log(res)
      this.props.updateHiredData();
    })
  }

  handeCheckOut = (row) => {
    const data = {
      check_in_id: row.check_in_id ,
      updated_by: localStorage.getItem('userId'),
    }
    Axios.post(`${urlLinks.baseUrl}/job/checkOut`, qs.stringify(data))
    .then(response => {
      // console.log(response)
      this.props.updateHireData();
      // this.props.updateHiredData();
    })
  }

  handleRejectCandidate = (row) => {
    // console.log(row)
    // const data = {
    //   interview_id: row.interviewId,
    //   is_rejected: 1,
    //   requested_by: row.insertedBy
    // }
    // console.log(data)
    Axios.post(`${urlLinks.baseUrl}/interview/rejectCandidate?interview_id=${row.interviewId}&is_rejected=1&requested_by=${row.insertedBy}`)
    .then(res => {
      // console.log(res)
      this.props.updateHiredData();
      // this.props.updateParentData();
    })
  }

  handleWorkerLeft = (row) => {
    const data = {
      interview_id: row.interviewId,
      requested_by: localStorage.getItem('userId'),
      is_joined: 2
    }
    Axios.post(`${urlLinks.baseUrl}/interview/userJoined`, qs.stringify(data))
    .then(res => {
      window.location.reload();
      // this.props.updateHireData();
      // this.props.updatedAppliedData();
      // this.props.updateParentData();
    })
  }

  // handleRejectOffer = (row) => {
  //   console.log(row)
  //   Axios.post(`${urlLinks.baseUrl}/job/assigner/reject?assign_id=${row.assign_id}&requested_by=${row.user_id}`)
  //   .then(response => {
  //     console.log(response)
  //     this.props.updateHiredData();
  //   })
  // }

  handeCheckIn = (row) => {
    const data = {
      check_in_id: row.check_in_id ,
      updated_by: localStorage.getItem('userId'),
      otp: row.otp
    }
    Axios.post(`${urlLinks.baseUrl}/job/checkIn/`, qs.stringify(data))
    .then(response => {
      // console.log(response)
      this.props.updateHireData();
      this.props.updateHiredData();
    })
  }

  handleAcceptJob = (row) => {
    const data = {
      assign_id : row.assign_id,
      confirmed_by : localStorage.getItem('userId'),
      is_confirmed : 1
    }
    Axios.post(`${urlLinks.baseUrl}/job/assigner/confirm`, qs.stringify(data))
    .then(res => {
      this.props.updateHireData();
      this.props.updateHiredData();
    })
    // .then(response => console.log(response))
  }

  handeApplyWorker = (data) => {
    // console.log(data)
    Axios.post(`${urlLinks.baseUrl}/job/assigner/accept?requested_by=${localStorage.getItem('userId')}&assign_id=${data.assignId}`)
    .then(response => {
      console.log(response);
      toast.success(`Worker Applied Successfully`);
      this.props.updateHiredData();
      this.props.updateMatchedData();
    })
    .catch(err => toast.error(`Error Applying Worker`));
  }

  handleSelectAll(event) {
    const target = event.target;
    this.headerChecked = target.checked;
    if (target.checked) {
      const newRows = this.props.data.map(row => row[this.props.name]);
      this.setState({
        selectedRows: newRows
      });
    } else {
      this.setState({
        selectedRows: []
      });
    }
  }

  handleClickOnRow(event, id) {
    let selected = this.state.selectedRows;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.headerChecked =
      newSelected.length === this.props.data.length ? true : false;
    this.setState({
      selectedRows: newSelected
    });
  }

  handleChangePage(nextPage) {
    this.props.tablePagination.changePage(
      this.props.tablePagination.rowsPerPage,
      nextPage
    );
  }

  handleChangeRowsPerPage(event) {
    const newrowsPerPage = parseInt(event.target.value, 10);
    if (newrowsPerPage !== this.props.tablePagination.rowsPerPage) {
      this.props.tablePagination.changePage(newrowsPerPage, 0);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    this.setState({ data: data });
  }

  // handleGenerateInvoice = () => {
  //  this.setState({isClicked: true}, () => this.props.generateInvoice(this.state.selectedRows));
  // }

  render() {
    console.log(this.props.column);
    
    const classes = this.props.classes;
    this.checkBoxesVisible =
      this.props.isCheckBoxesVisible === undefined
        ? this.checkBoxesVisible
        : this.props.isCheckBoxesVisible;
    this.numericIndex =
      this.props.numericIndex === undefined
        ? this.numericIndex
        : this.props.numericIndex;
    const colms = this.getTableHeaderCols(this.props.columns);
    const rowData = this.getRoWData(
      this.props.data,
      this.props.columns,
      classes
    );
    return (
      <>
        <ToastContainer
            position='top-right'
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            autoClose={4000}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          ></ToastContainer>
        <Paper className={classes.root} style={{ height: 500, width: '100%' }}>
          {this.props.title ? (
            <Toolbar className={classes.toolbar}>
              <Typography variant='h6' id='tableTitle' className={classes.title}>
                {this.props.title}
              </Typography>
            </Toolbar>
          ) : null}
          <Table className={classes.table} stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell
                  padding='checkbox'
                  className={`${
                    this.checkBoxesVisible ? classes.colvisible : classes.colhide
                  } ${classes.tabcell}`}
                >
                  <Checkbox
                    checked={this.headerChecked}
                    onChange={this.handleSelectAll}
                  />
                </TableCell>
                <TableCell
                  className={`${
                    this.numericIndex ? classes.colvisible : classes.colhide
                  } ${classes.tabcell}`}
                >
                  No.
                </TableCell>
                {colms}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.tablePagination.tableUpdating
                ? this.tableLoader
                : rowData}
            </TableBody>
          </Table>
          {this.props.invoiceLabel ? (
            <Button
              className={classes.notificationButton}
              variant='contained'
              color='primary'
              onClick={() => this.props.generateInvoice(this.state.selectedRows)}
            >
              Generate Invoice
            </Button>
          ) : this.props.assignLabel ? (
            <Button
              className={classes.notificationButton}
              variant='contained'
              color='primary'
              onClick={() => this.props.assignWorkers(this.state.selectedRows)}
            >
              Assign Workers
            </Button>
          ) : null
          // (
          //   <Button
          //     className={classes.notificationButton}
          //     variant='contained'
          //     color='primary'
          //   >
          //     Send Notification
          //   </Button>
          // )
          }

          <Button
            className={classes.notificationButton}
            variant='contained'
            color='primary'
          >
            <CSVLink
              data={this.props.data}
              filename={`${this.props.title}.csv`}
              className='btn btn-primary'
              target='_blank'
              style={{
                color: 'inherit',
                textDecoration: 'none',
                fontWeight: '500'
              }}
            >
              Export To Excel
            </CSVLink>
          </Button>

          <TablePagination
            rowsPerPageOptions={this.props.tablePagination.rowsOptions}
            component='div'
            count={this.props.tablePagination.count}
            rowsPerPage={this.props.tablePagination.rowsPerPage}
            page={this.props.tablePagination.currentPage}
            onChangePage={(event, nextPage) => {
              this.handleChangePage(nextPage);
            }}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  }
}
export default withStyles(useStyles)(CustomTable);
