import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Axios from 'axios';
import urlLinks from '../../urlLinks'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { SyncLoader } from 'react-spinners';
import Select from '@material-ui/core/Select';
import qs from 'querystring';
import utils from '../../utilMethods';
import Popover from '@material-ui/core/Popover';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Link } from '@material-ui/core';

class customLinkPopover extends Component {
    constructor(props) {
        super(props);
        // console.log(props)
        this.state = {
            date: null,
            status: '',
            slotSelected: 0,
            salary: 0,
            workerStatus: '',
            offerStatus: '',
            open: false,
            verifyOtp: '',
            invoiceAmount: 0,
            anchorEl: <b></b>,
            isSubmitting: false,
            interviewDate1: null,
            interviewDate2: null,
            interviewDate3: null,
            interviewTime1: null,
            interviewTime2: null,
            interviewTime3: null,
            interviewTime4: null,
            interviewTime5: null,
            interviewTime6: null,
        }
    }

    handleDateChange = (date) => {
        this.setState({date: date})
    }

    handleRequestClose(event) {
        this.setState({
          open: !this.state.open,
          anchorEl: event.currentTarget
        });
    }

    handleInterviewSubmit = () => {
        // console.log(this.props)
        // console.log(`DONE`)
        this.setState({ isSubmitting: true});
        const interviewTime1 = this.state.interviewTime1 ? this.state.interviewTime1.toTimeString().split(' ')[0] : null;
        const interviewTime2 = this.state.interviewTime2 ? this.state.interviewTime2.toTimeString().split(' ')[0] : null;
        const interviewTime3 = this.state.interviewTime3 ? this.state.interviewTime3.toTimeString().split(' ')[0] : null;
        const interviewTime4 = this.state.interviewTime4 ? this.state.interviewTime4.toTimeString().split(' ')[0] : null;
        const interviewTime5 = this.state.interviewTime5 ? this.state.interviewTime5.toTimeString().split(' ')[0] : null;
        const interviewTime6 = this.state.interviewTime6 ? this.state.interviewTime6.toTimeString().split(' ')[0] : null;

        const interviewStartTime1 = this.state.interviewDate1 ? `${this.state.interviewDate1.toISOString().split('T')[0]} ${interviewTime1}` : null;
        const interviewEndTime1 = this.state.interviewDate1 ? `${this.state.interviewDate1.toISOString().split('T')[0]} ${interviewTime2}` : null;
        const interviewStartTime2 = this.state.interviewDate2 ? `${this.state.interviewDate2.toISOString().split('T')[0]} ${interviewTime3}` : null;
        const interviewEndTime2 = this.state.interviewDate2 ? `${this.state.interviewDate2.toISOString().split('T')[0]} ${interviewTime4}` : null;
        const interviewStartTime3 = this.state.interviewDate3 ? `${this.state.interviewDate3.toISOString().split('T')[0]} ${interviewTime5}` : null;
        const interviewEndTime3 = this.state.interviewDate3 ? `${this.state.interviewDate3.toISOString().split('T')[0]} ${interviewTime6}` : null;
        
        const data = {
            // address: this.props.jobData.address,
            job_detail_id: this.props.jobDetailId,
            // job_location_lat: this.props.jobData.jobLocationLat,
            // job_location_long: this.props.jobData.jobLocationLong,
            // land_mark: this.props.jobData.landMark,
            // interview_id: null,
            // postal_code: this.props.jobData.postalCode,
            requested_by: this.props.jobData.insertedBy,
            user_id: this.props.row.user_id,
            interview_status: 1,
            // interview_start_time: interviewStartTime1,
            // interview_end_time: interviewEndTime1,
            // interview_start_time2: interviewStartTime2,
            // interview_end_time2: interviewEndTime2,
            // interview_start_time3: interviewStartTime3,
            // interview_end_time3: interviewEndTime3,
        }
        if(this.props.columnName === 'rescheduleInterview') {
            data['interview_status'] = this.props.row.interviewStatus === 'Yes' ? 1 : 0;
            data['interview_id'] = this.props.row.interviewId;
        }
        // console.log(data);

        // if(this.state.interviewDate1) {
        //     data.interview_start_time = interviewStartTime1;
        //     data.interview_end_time = interviewEndTime1;
        // }
        // if(this.state.interviewDate2) {
        //     data.interview_start_time2 = interviewStartTime2;
        //     data.interview_end_time2 = interviewEndTime2;
        // }
        // if(this.state.interviewDate3) {
        //     data.interview_start_time3 = interviewStartTime3;
        //     data.interview_end_time3 = interviewEndTime3;
        // }
        if(this.state.interviewDate1 || this.state.interviewTime1 || this.state.interviewTime2) {
            if(this.state.interviewDate1 && this.state.interviewTime1 && this.state.interviewTime2) {
                
                if(this.state.interviewDate2 || this.state.interviewTime3 || this.state.interviewTime4) {
                    if(this.state.interviewDate2 && this.state.interviewTime3 && this.state.interviewTime4) {

                        if(this.state.interviewDate3 || this.state.interviewTime5 || this.state.interviewTime6) { 

                            if(this.state.interviewDate3 && this.state.interviewTime5 && this.state.interviewTime6) {
                                data.interview_start_time = interviewStartTime1;
                                data.interview_end_time = interviewEndTime1;
                                data.interview_start_time2 = interviewStartTime2;
                                data.interview_end_time2 = interviewEndTime2;
                                data.interview_start_time3 = interviewStartTime3;
                                data.interview_end_time3 = interviewEndTime3;
                                Axios.post(`${urlLinks.baseUrl}/interview/schedule`, qs.stringify(data))
                                .then(response => this.setState({isSubmitting: false}, () => this.props.updateHiredData()))
                            } else {
                                toast.error(`Fill Interview Slot 3`)
                                this.setState({isSubmitting: false})
                            }
                        } else {
                            data.interview_start_time = interviewStartTime1;
                            data.interview_end_time = interviewEndTime1;
                            data.interview_start_time2 = interviewStartTime2;
                            data.interview_end_time2 = interviewEndTime2;
                            Axios.post(`${urlLinks.baseUrl}/interview/schedule`, qs.stringify(data))
                            .then(response => this.setState({isSubmitting: false}, () => this.props.updateHiredData()))
                        }

                    } else {
                        toast.error(`Fill Interview Slot 2`)
                        this.setState({isSubmitting: false})
                    }
                } else if(this.state.interviewDate3 || this.state.interviewTime5 || this.state.interviewTime6) {
                    toast.error(`Please Fill Slot 2 First`)
                    this.setState({isSubmitting: false})
                }
                 else {
                    data.interview_start_time = interviewStartTime1;
                    data.interview_end_time = interviewEndTime1;
                    Axios.post(`${urlLinks.baseUrl}/interview/schedule`, qs.stringify(data))
                    .then(response => this.setState({isSubmitting: false}, () => this.props.updateHiredData()))
                }

            } else {
                toast.error(`Fill Interview Slot 1`)
                this.setState({isSubmitting: false})
            }
        } else {
            toast.error(`Please Fill Slot 1`)
            this.setState({isSubmitting: false})
        }
        // console.log(data)
        
    }

    handleSubmitClick = () => {
        this.setState({ isSubmitting: true});
        let data = null;
        if(this.props.columnName === 'updateSalary' || this.props.columnName === 'updateHiredSalary') {
            if(this.state.salary) {
                if(this.state.date) {
                    data = {
                        interview_id: this.props.row.interviewId,
                        job_offered_amount: this.state.salary,
                        joining_date: `${this.state.date.toISOString().split('T')[0]} 00:00:00`,
                        requested_by: this.props.jobData.insertedBy
                    }
                    if(this.props.columnName === 'updateSalary') {
                        data.is_job_offered = this.props.row.is_job_offered === 'Yes' ? 0 : 1;
                    } else {
                        data.is_job_offered = 1;
                    }
                    Axios.post(`${urlLinks.baseUrl}/interview/offerjob`, qs.stringify(data))
                    .then(response => this.setState({isSubmitting: false}, () => {
                        this.props.updateHiredData()
                        this.props.updateHireData()
                    }))
                } else {
                    toast.error(`Please provide Joining Date`);
                    this.setState({ isSubmitting: false});
                }
            } else {
                toast.error(`Please provide salary`);
                this.setState({ isSubmitting: false});
            }
            
            // console.log(data);
            
            // this.setState({ open: false })
        }
        if(this.props.columnName === 'joining_date') {
            data = {
                interview_id: this.props.row.interviewId,
                is_job_offered: 1,
                job_offered_amount: this.props.row.job_offered_amount,
                joining_date: `${this.state.date.toISOString().split('T')[0]} 00:00:00`,
                requested_by: this.props.row.user_id
            }
            // console.log(data)
            Axios.post(`${urlLinks.baseUrl}/interview/offerjob`, qs.stringify(data))
            .then(res => this.setState({isSubmitting: false}, () => this.props.updateHiredData()))
            // this.handleRequestClose();
        }
        if(this.props.columnName === 'verifyOtp') {
            data = {
                interview_id: this.props.row.interviewId,
                otp: this.state.verifyOtp,
                requested_by: this.props.jobData.insertedBy
            }
            // console.log(data)
            Axios.post(`${urlLinks.baseUrl}/interview/verifyOtp`, qs.stringify(data))
            .then(res => this.setState({isSubmitting: false}, () => this.props.updateHiredData()))
        }
        if(this.props.columnName === 'workingStatus') {
            if(this.state.workerStatus) {
                data = {
                    interview_id: this.props.row.interviewId,
                    requested_by: localStorage.getItem('userId'),
                    is_joined: this.state.workerStatus
                }
                Axios.post(`${urlLinks.baseUrl}/interview/userJoined`, qs.stringify(data))
                .then(res => this.setState({isSubmitting: false}, () => this.props.updateParentData()))
            } else {
                toast.error(`Please provide worker status`);
                this.setState({isSubmitting: false})
            }
        }
        if(this.props.columnName === 'invoiceAmount') {
            data = {
                interview_id: this.props.row.interviewId,
                invoice_amount: +this.state.invoiceAmount,
                requested_by: this.props.jobData.userId
            }
            Axios.post(`${urlLinks.baseUrl}/interview/updateInvoiceAmount`, qs.stringify(data))
            .then(response => this.setState({isSubmitting: false}, () => {
                this.props.updateHiredData()
                this.props.updateHireData()
            }))
        }
        if(this.props.columnName === 'rejectOffer') {
            if(this.state.offerStatus) {
                // console.log(this.props.row)
                data = {
                    interview_id: this.props.row.interviewId,
                    interview_status: this.props.row.interviewStatus === 'Yes' ? 1 : 0,
                    is_offer_accepted: this.state.offerStatus === 1 ? 1 : 0,
                    is_offer_rejected: this.state.offerStatus === 2 ? 1 : 0,
                    job_detail_id: this.props.row.jobDetailsId,
                    requested_by : this.props.row.insertedBy,
                    user_id: this.props.row.user_id,
                    which_slot: this.props.row.whichSlot,
                }
                // console.log(data)
                Axios.post(`${urlLinks.baseUrl}/interview/`, qs.stringify(data))
                .then(res => this.setState({isSubmitting: false}, () => {
                    this.props.updateHiredData();
                    this.props.updateParentData();
                }))
            } else {
                toast.error(`Please provide offer status`);
                this.setState({isSubmitting: false})
            }
        }
        if(this.props.columnName === 'selectSlot') {
            if(this.state.slotSelected) {
                data = {
                    interview_id: this.props.row.interviewId,
                    which_slot: this.state.slotSelected,
                    requested_by: localStorage.getItem('userId')
                }
                Axios.post(`${urlLinks.baseUrl}/interview/`, qs.stringify(data))
                .then(res => this.setState({isSubmitting: false}, () => this.props.updateHiredData()))
            } else {
                toast.error(`Please select slot`);
                this.setState({isSubmitting: false})
            }
        }
        if(this.props.columnName === 'updateStatus') {
            // console.log(this.props.row)
            if(this.state.status) {
                data = {
                    check_in_id : this.props.row.check_in_id,
                    is_no_show : this.state.status,
                    updated_by : localStorage.getItem('userId')
                }
                Axios.post(`${urlLinks.baseUrl}/job/checkIn/noshow`, qs.stringify(data))
                .then(res => this.setState({isSubmitting: false}, () => {
                    this.props.updateHireData();
                    this.props.updateHiredData();
                }))
            } else {
                toast.error(`Please select status`);
                this.setState({isSubmitting: false})
            }
        }
    }
    
    toDateWithOutTimeZone = (date) => {
        let tempTime = date.split(":");
        let dt = new Date();
        dt.setHours(tempTime[0]);
        dt.setMinutes(tempTime[1]);
        dt.setSeconds(tempTime[2]);
        return dt;
      }

    componentDidMount() {
        if(this.props.columnName === 'updateHiredSalary') {
            // console.log(this.props)
            this.setState({ date: new Date(this.props.row.joining_date.split('-').reverse().join('-')) , salary: this.props.row.job_offered_amount})
        }
        if(this.props.columnName === 'rescheduleInterview') {
            // console.log(this.props)
            const row = this.props.row
            this.setState({
                interviewDate1: row.interviewStartTime !== '-' ? new Date(row.interviewStartTime.substring(0, 10).replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) : null,
                interviewDate2: row.interviewStartTime2 !== '-' ? new Date(row.interviewStartTime2.substring(0, 10).replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) : null,
                interviewDate3: row.interviewStartTime3 !== '-' ? new Date(row.interviewStartTime3.substring(0, 10).replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")) : null,
                interviewTime1: row.interviewStartTime !== '-' ? this.toDateWithOutTimeZone(row.interviewStartTime.substring(11, 19)) : null,
                interviewTime2: row.interviewEndTime !== '-' ? this.toDateWithOutTimeZone(row.interviewEndTime.substring(11, 19)) : null,
                interviewTime3: row.interviewStartTime2 !== '-' ? this.toDateWithOutTimeZone(row.interviewStartTime2.substring(11, 19)) : null,
                interviewTime4: row.interviewEndTime2 !== '-' ? this.toDateWithOutTimeZone(row.interviewEndTime2.substring(11, 19)) : null,
                interviewTime5: row.interviewStartTime3 !== '-' ? this.toDateWithOutTimeZone(row.interviewStartTime3.substring(11, 19)) : null,
                interviewTime6: row.interviewEndTime3 !== '-' ? this.toDateWithOutTimeZone(row.interviewEndTime3.substring(11, 19)) : null,
            })
        }
    }

    render() {
        let component = null
        if(this.props.columnName === 'joining_date') {
            component = (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        // minDate={new Date()}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Joining Date"
                        value={this.state.date}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            )
        } else if(this.props.columnName === 'updateStatus') {
            component = (
                <FormControl style={{marginBottom: '15px', width: '150px'}}>
                    <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                    <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    // open={open}
                    // onClose={handleClose}
                    // onOpen={handleOpen}
                    value={this.state.status}
                    onChange={event => this.setState({ status: event.target.value})}
                    >
                    <MenuItem value={1}>No Show</MenuItem>
                    {/* <MenuItem value={'Going'}>Going</MenuItem>
                    <MenuItem value={'Not Going'}>Not Going</MenuItem>
                    <MenuItem value={'No Reponse'}>No Reponse</MenuItem>
                    <MenuItem value={'Job Started'}>Job Started</MenuItem>
                    <MenuItem value={'Job Completed'}>Job Completed</MenuItem>
                    <MenuItem value={'Left Job Incomplete'}>Left Job Incomplete</MenuItem> */}
                    </Select>
                </FormControl>
            )
        } else if(this.props.columnName === 'updateSalary' || this.props.columnName === 'updateHiredSalary') {
            component = (
                <>
                <Grid container style={{flexGrow: '1'}} spacing={2}>
                    <FormControl style={{marginBottom: '15px', width: '150px'}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            value={this.state.salary}
                            onChange={event => this.setState({ salary: event.target.value})}
                            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                            labelWidth={60}
                        />
                    </FormControl>
                </Grid>

                    <Grid container style={{flexGrow: '1'}} spacing={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            // minDate={new Date()}
                            disableToolbar
                            // minDate={new Date()}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Joining Date"
                            value={this.state.date}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    </Grid>
                </>
            )
        } else if(this.props.columnName === 'verifyOtp') {
            component = (
                <FormControl style={{marginBottom: '15px', width: '150px'}} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">OTP</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        value={this.state.verifyOtp}
                        onChange={event => this.setState({ verifyOtp: event.target.value})}
                        // startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
            )
        } else if(this.props.columnName === 'invoiceAmount') {
            component = (
                <FormControl style={{marginBottom: '15px', width: '150px'}} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        value={this.state.invoiceAmount}
                        onChange={event => this.setState({ invoiceAmount: event.target.value})}
                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
            )
        } else if(this.props.columnName === 'workingStatus') {
            component = (
                <FormControl style={{marginBottom: '15px', width: '150px'}}>
                    <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                    <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    // open={open}
                    // onClose={handleClose}
                    // onOpen={handleOpen}
                    value={this.state.workerStatus}
                    onChange={event => this.setState({workerStatus: event.target.value})}
                    >
                    <MenuItem value={1}>Worker Joined</MenuItem>
                    {/* <MenuItem value={'Worker Not Joined'}>Worker Not Joined</MenuItem> */}
                    <MenuItem value={2}>Worker Left</MenuItem>
                    </Select>
                </FormControl>
            )
        } else if(this.props.columnName === 'rejectOffer') {
            component = (
                <FormControl style={{marginBottom: '15px', width: '150px'}}>
                    <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                    <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    // open={open}
                    // onClose={handleClose}
                    // onOpen={handleOpen}
                    value={this.state.offerStatus}
                    onChange={event => this.setState({offerStatus: event.target.value})}
                    >
                    <MenuItem value={1}>Accept Offer</MenuItem>
                    {/* <MenuItem value={'Worker Not Joined'}>Worker Not Joined</MenuItem> */}
                    <MenuItem value={2}>Reject Offer</MenuItem>
                    </Select>
                </FormControl>
            )
        } else if(this.props.columnName === 'isInterview') {
            component = (
                <>
                    <Grid container style={{flexGrow: '1'}} spacing={2}>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // minDate={new Date()}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Interview Date 1"
                                    value={this.state.interviewDate1}
                                    onChange={event => this.setState({ interviewDate1: event})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    mode={24}
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview Start Time"
                                    value={this.state.interviewTime1}
                                    onChange={event => this.setState({ interviewTime1: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview End Time"
                                    value={this.state.interviewTime2}
                                    onChange={event => this.setState({ interviewTime2: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container style={{flexGrow: '1'}} spacing={2}>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // minDate={new Date()}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Interview Date 2"
                                    value={this.state.interviewDate2}
                                    onChange={event => this.setState({ interviewDate2: event})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview Start Time"
                                    value={this.state.interviewTime3}
                                    onChange={event => this.setState({ interviewTime3: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview End Time"
                                    value={this.state.interviewTime4}
                                    onChange={event => this.setState({ interviewTime4: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container style={{flexGrow: '1'}} spacing={2}>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // minDate={new Date()}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Interview Date 3"
                                    value={this.state.interviewDate3}
                                    onChange={event => this.setState({ interviewDate3: event})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview Start Time"
                                    value={this.state.interviewTime5}
                                    onChange={event => this.setState({ interviewTime5: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview End Time"
                                    value={this.state.interviewTime6}
                                    onChange={event => this.setState({ interviewTime6: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
               </> 
            )
        } else if(this.props.columnName === 'selectSlot') {
            // console.log(this.props)
            component = (
                <FormControl style={{marginBottom: '15px', width: '150px'}}>
                    <InputLabel id="demo-controlled-open-select-label">Select Slot</InputLabel>
                    <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    // open={open}
                    // onClose={handleClose}
                    // onOpen={handleOpen}
                    value={this.state.slotSelected}
                    onChange={event => this.setState({slotSelected: event.target.value})}
                    >
                    <MenuItem value={1}>{this.props.row['interviewStartTime'] ? `${this.props.row['interviewStartTime'].substring(0, 10)} ${this.props.row['interviewStartTime'].substring(11, 16)}-${this.props.row['interviewEndTime'].substring(11, 16)}`: null}</MenuItem>
                    {this.props.row['interviewStartTime2'] ? 
                        (<MenuItem disabled={this.props.row['interviewStartTime2'] === '-'} value={2}>{this.props.row['interviewStartTime2'] !== '-' ? `${this.props.row['interviewStartTime2'].substring(0, 10)} ${this.props.row['interviewStartTime2'].substring(11, 16)}-${this.props.row['interviewEndTime2'].substring(11, 16)}`: null}</MenuItem>) : null
                    }
                    {this.props.row['interviewStartTime3'] ? 
                        (<MenuItem disabled={this.props.row['interviewStartTime2'] === '-'} value={3}>{this.props.row['interviewStartTime3'] !== '-' ? `${this.props.row['interviewStartTime3'].substring(0, 10)} ${this.props.row['interviewStartTime3'].substring(11, 16)}-${this.props.row['interviewEndTime3'].substring(11, 16)}`: null}</MenuItem>) : null
                    }
                    </Select>
                </FormControl>
            )
        } else if(this.props.columnName === 'rescheduleInterview') {
            // console.log(this.props)
            component = (
                <>
                    <Grid container style={{flexGrow: '1'}} spacing={2}>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // minDate={new Date()}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Interview Date 1"
                                    value={this.state.interviewDate1}
                                    onChange={event => this.setState({ interviewDate1: event})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    mode={24}
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview Start Time"
                                    value={this.state.interviewTime1}
                                    onChange={event => this.setState({ interviewTime1: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview End Time"
                                    value={this.state.interviewTime2}
                                    onChange={event => this.setState({ interviewTime2: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container style={{flexGrow: '1'}} spacing={2}>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // minDate={new Date()}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Interview Date 2"
                                    value={this.state.interviewDate2}
                                    onChange={event => this.setState({ interviewDate2: event})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview Start Time"
                                    value={this.state.interviewTime3}
                                    onChange={event => this.setState({ interviewTime3: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview End Time"
                                    value={this.state.interviewTime4}
                                    onChange={event => this.setState({ interviewTime4: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container style={{flexGrow: '1'}} spacing={2}>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // minDate={new Date()}
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Interview Date 3"
                                    value={this.state.interviewDate3}
                                    onChange={event => this.setState({ interviewDate3: event})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview Start Time"
                                    value={this.state.interviewTime5}
                                    onChange={event => this.setState({ interviewTime5: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Interview End Time"
                                    value={this.state.interviewTime6}
                                    onChange={event => this.setState({ interviewTime6: utils.roundTimeQuarterHour(event)})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
               </> 
            )
        }
        return (
            <>
                <ToastContainer
                    position='top-right'
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    autoClose={4000}
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                ></ToastContainer>
                <PopupState variant="popover" popupId="demo-popup-popover">
                {popupState => (
                    <div>
                    <span {...bindTrigger(popupState)}>
                        <Link style={{cursor: 'pointer'}}>{this.props.name}</Link>
                    </span>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        // open={this.state.open}
                        // anchorEl={this.state.anchorEl}
                    >
                        {
                            this.props.columnName === 'isInterview' || this.props.columnName === 'rescheduleInterview' ?
                            (
                                <Box p={8}
                                >
                                {this.state.isSubmitting ?
                                (    <SyncLoader color={'#194D33'}></SyncLoader>) :
                                
                                (
                                    <>
                                        {component}
                                        <br />
                                        <Button variant="contained" color="primary" onClick={this.handleInterviewSubmit}>
                                            Submit
                                        </Button>
                                    </>
                                )
                                }
                                </Box>
                            ) 
                             : 
                             (
                                <Box p={4}>
                                {this.state.isSubmitting ?
                                (    <SyncLoader color={'#194D33'}></SyncLoader>) :
                                    (
                                        <>
                                            {component}
                                            <br />
                                            <Button variant="contained" color="primary" onClick={this.handleSubmitClick}>
                                                Submit
                                            </Button>
                                        </>
                                    )
                                    }
                                </Box>
                            )
                        }
                    </Popover>
                    </div>
                )}
                </PopupState>
            </>
        );
    }
}
export default customLinkPopover;