import React, {Fragment, useEffect, useState, useCallback} from "react";
import { Field } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import WeekToggle from './weektoggleButton'
import * as moment from 'moment';
import urlLinks from '../../../../urlLinks';
import Axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
}  from '@material-ui/pickers';
import {TextField, Select, MenuItem, FormControl, FormControlLabel, InputLabel, Grid, InputAdornment, Switch} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FormFirstStep = formikProps => {
  const classes = useStyles();
  const { errors, touched, values, handleChange, setFieldValue, companyList, jobRoleList, setValues} = formikProps;
  const [boost, setBoost] = React.useState(values.boostJob)
  const [isValidPin, setIsValidPin] = useState(true);
  const [jobRoles, setJobRoles] = useState([])
  const [jobTypeValue, setJobTypeValue] = useState('')
  // console.log("Pincode", values.pincode, values.pincode.toString().length, isValidPin);
  
  /* for checking pincode and getting city and state */
  const checkPincode =useCallback((pincode) =>{
    Axios.get(`${urlLinks.baseUrl}tracking/getcityandstate?pincode=${pincode}`)
      .then(response =>{
        if(response.data.response.length!==0){
          setIsValidPin(true)
          
          setValues({...values, cityName:response.data.response[0].district, stateName:response.data.response[0].stateName, city:response.data.response[0].city_id })
        }
        else{
          setIsValidPin(false)
        }
      })
      .catch(error =>{
        // toast.error("Unable to retrieve city and state.")
      })
  },[values.pincode, values.cityName, values.stateName])

  useEffect(() => {
    
    if(values.pincode===0){
      setIsValidPin(false)
      setValues({...values, pincode: '' })
    }
    if(values.pincode && values.pincode.toString().length===6){
          // console.log("valid");
      checkPincode(parseInt(values.pincode))
    }
    
  }, [values.pincode])

  /* For google map address */  
  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('googleAddress', {})
    );
    const handlePlaceSelect = () => {
      let addressObject = autocomplete.getPlace();
      let pincode=0;
      addressObject.address_components.map(component =>{
        if(component.types.includes("postal_code")){
          pincode=component.long_name;
        }
      })
      const geometry = addressObject.geometry.location;
      const loc={
        lat:  geometry.lat(),
        lng: geometry.lng(),
        address: addressObject.formatted_address,
        addressUrl: addressObject.url
      }
      setValues({...values, searchAddress: loc.address, lat: loc.lat , long: loc.lng, interviewAddressUrl: loc.interviewAddressUrl, pincode: pincode })
    }
    autocomplete.addListener('place_changed', handlePlaceSelect)
  })

  /* for jobtype value */
  useEffect(() => {
    // console.log(values.jobType , jobRoleList);
    
    if(values.jobType && jobRoleList.length>0){
      const jobObj= jobRoleList.find(x=> x.id ===values.jobType)
      // console.log({jobObj});
      
      Axios.get(`${urlLinks.baseUrl}/configmaster/inquery?inquery=${jobObj.typeValue}`)
        .then(response => {
          setJobRoles(response.data.response.content) ;
          
        });
    }
  }, [values.jobType, jobRoleList])
  //  for skill set
  useEffect(() => {
    let skilldata={}
    if(values.jobRole){
      Axios.get(`${urlLinks.baseUrl}configmaster/getAllSkilllsByJobId?job_type_id=${values.jobRole}`)
      .then(response =>{
        skilldata=response.data.response;
        // console.log({skilldata})
        setValues({...values, skillData: skilldata })
      })
    }
  },[values.jobRole])
  console.log({values})
  useEffect(() => {
    let exp=40;
    let qualification="None";
    let cuisine="None";
    let skillFlags={
      bike: false,
      smartphone: false, 
      laptop:  false,
      wifi:  false,
      license:  false,
      rc:  false,
      aadhar:  false,
      pan:  false,
      expCheck:  false,
      expToggle:  null,
      lastExpCheck:  false,
      lastExpToggle:  null,
      lastSalCheck:  false,
      lastSalToggle:  null,
      noticePeriodCheck:  false,
      noticePeriodToggle:  null,
      qualification_typeCheck:  false,
      qualification_typeToggle:  null,
      specializationCheck:  false,
      specializationToggle:  null,
      englishLanguageFluencyCheck:  false,
      englishLanguageFluencytoggle:  null
  }

    if(values.detailSkills && values.detailSkills.length>0){
      values.detailSkills.map(skill =>{
        if(skill.skillName==="Qualification" ){
          qualification=skill.skillsTypeId;
          skillFlags.qualification_typeCheck= skill.isRequired?true:false;
          skillFlags.qualification_typeToggle= skill.isMandatory
        }
        else if(skill.skillName==="Experience" && skill.skillValue!=="Last work Experience" ){
          exp=skill.skillsTypeId;
          skillFlags.expCheck= skill.isRequired?true:false;
          skillFlags.expToggle= skill.isMandatory

        }
        else if(skill.skillName==="Cuisine" )
          cuisine=skill.skillsTypeId;
        else if(skill.skillValue==="Bike"){
          skillFlags.bike= skill.isRequired?true:false;
        }
        else if(skill.skillValue==="smartphone"){
          skillFlags.smartphone= skill.isRequired?true:false;
        }
        else if(skill.skillValue==="laptop"){
          skillFlags.laptop= skill.isRequired?true:false;
        }
        else if(skill.skillValue==="wifi"){
          skillFlags.wifi= skill.isRequired?true:false;
        }
        else if(skill.skillValue==="License"){
          skillFlags.license= skill.isRequired?true:false;
        }
        else if(skill.skillValue==="Vehicle RC"){
          skillFlags.rc= skill.isRequired?true:false;
        }
        else if(skill.skillValue==="Aadhar Card"){
          skillFlags.aadhar= skill.isRequired?true:false;
        }
        else if(skill.skillValue==="Pan Card"){
          skillFlags.pan= skill.isRequired?true:false;
        }
        else if(skill.skillValue==="Last work Experience"){
          skillFlags.lastExpCheck= skill.isRequired?true:false;
          skillFlags.lastExpToggle= skill.isMandatory
        }
        else if(skill.skillValue==="last salary"){
          skillFlags.lastSalCheck= skill.isRequired?true:false;
          skillFlags.lastSalToggle= skill.isMandatory
        }
        else if(skill.skillValue==="notice period"){
          skillFlags.noticePeriodCheck= skill.isRequired?true:false;
          skillFlags.noticePeriodToggle= skill.isMandatory
        }
        else if(skill.skillValue==="specialisation"){
          skillFlags.specializationCheck= skill.isRequired?true:false;
          skillFlags.specializationToggle= skill.isMandatory
        }
        else if(skill.skillValue==="english_fluency"){
          skillFlags.englishLanguageFluencyCheck= skill.isRequired?true:false;
          skillFlags.englishLanguageFluencytoggle= skill.isMandatory
        }
      })
    }
    setValues({...values, experience: exp, qualification_type:qualification, cuisine: cuisine , ...skillFlags})
  }, [values.skillData])

  let companyId= values.companyName;
  let companyValue= values.bName?values.bName:values.cName

  // useEffect(() => {
  //   if(values.city!==71 && values.city!==72 && values.city!==73)
  //     setFieldValue('city', -1);
    
  // }, [values.city])
  
  return (
    <div className="form">
      <Grid container spacing={0}>
        <span className="formHeadings">Company Details</span>
        <Grid item xs={12}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
            <InputLabel id="company-name">Company name *</InputLabel>
            <Select
              name='companyName'
              value={values.companyName}
              label= "Company name *"
              disabled
              error={touched.companyName && errors.companyName}
              onChange={handleChange}
            >
              <MenuItem value={companyId}>{companyValue}</MenuItem>
            </Select>
            <p style={{color:"red"}}>{touched.companyName && errors.companyName}</p>
          </FormControl>            
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
              <Field
                label="Boost this Job"
                name="boostJob"
                component={Switch}
                color= "primary"
                onChange={() => setFieldValue('boostJob', !values.boostJob)}
                checked={values.boostJob}
              />
            }
            label="Boost this Job"
          />
          </Grid>
        <span className="formHeadings">Job Details</span>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
              <InputLabel id="Vacancy-status">Vacancy Status *</InputLabel>
              <Select
                name='vacancyStatus'
                value={values.vacancyStatus}
                label= "Vacancy Status *"
                error={touched.vacancyStatus && errors.vacancyStatus}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Vacancy Status</em>
                </MenuItem>
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={0}>Inactive</MenuItem>
              </Select>
              <p style={{color:"red"}}>{touched.vacancyStatus && errors.vacancyStatus}</p>
            </FormControl>            
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
              <InputLabel id="Vacancy-status">Vacancy Type</InputLabel>
              <Select
                name='vacancyType'
                value={values.vacancyType}
                label= "Vacancy Type"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Vacancy Type</em>
                </MenuItem>
                <MenuItem value={"entry"}>Entry</MenuItem>
                <MenuItem value={"mid"}>Mid</MenuItem>
                <MenuItem value={"senior"}>Senior</MenuItem>
              </Select>
            </FormControl>            
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
              <InputLabel id="Payroll-type">Payroll type</InputLabel>
              <Select
                name='payrollType'
                value={values.payrollType}
                label= "Payroll type"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Payroll type</em>
                </MenuItem>
                <MenuItem value={"company"}>Company</MenuItem>
                <MenuItem value={"third"}>Third</MenuItem>
                <MenuItem value={"contract"}>Contract</MenuItem>
              </Select>
            </FormControl>            
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
              <InputLabel id="job-category">Job category *</InputLabel>
              <Select
                name='jobCategory'
                value={values.jobCategory}
                label= "Job category *"
                // disabled={true}
                onChange={handleChange}
              >
                 <MenuItem value="">
                  <em>Job category</em>
                </MenuItem>
                <MenuItem value={1}>Full Time</MenuItem>
                <MenuItem value={2}>Part Time</MenuItem>
                <MenuItem value={3}>On Demand</MenuItem>
              </Select>
            </FormControl>            
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Field
              name="numberOfOpenings"
              label="Number of openings *"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              error={touched.numberOfOpenings && errors.numberOfOpenings}
              helperText={touched.numberOfOpenings && errors.numberOfOpenings}
            />        
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
              <InputLabel id="job-type">Job Type *</InputLabel>
              <Select
                name='jobType'
                value={values.jobType}
                label= "Job type *"
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  <em>Job Type</em>
                </MenuItem>
                {jobRoleList &&
                  jobRoleList.map( j => 
                    <MenuItem value={j.id}>{j.typeDesc}</MenuItem>
                  )
                }
              </Select>
              <p style={{color:"red"}}>{touched.jobType && errors.jobType}</p>
            </FormControl>            
          </Grid>  
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
              <InputLabel id="job-role">Job role *</InputLabel>
              <Select
                name='jobRole'
                value={values.jobRole}
                label= "Job role *"
                disabled={localStorage.getItem("roleType")!=="Super Admin"}
                error={touched.jobRole && errors.jobRole}
                helperText={touched.jobRole && errors.jobRole}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  <em>Job Type</em>
                </MenuItem>
                {jobRoles &&
                  jobRoles.map( j => 
                    <MenuItem value={j.id}>{j.categorySubType}</MenuItem>
                  )
                }
              </Select>
              <p style={{color:"red"}}>{touched.jobRole && errors.jobRole}</p>
            </FormControl>            
          </Grid>
            <Grid item xs={6}>
              <Field
                name="otherJobrole"
                label="Job title"
                variant= "outlined"
                fullWidth={true}
                as={TextField}
                error={touched.otherJobrole && errors.otherJobrole}
                helperText={touched.otherJobrole && errors.otherJobrole}
              />
            </Grid>
        </Grid>
        {/* <Grid container spacing={0}>
          <Grid item xs={6}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
              <InputLabel id="city">City *</InputLabel>
              <Select
                name='city'
                value={values.city}
                label= "City *"
                error={touched.city && errors.city}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  <em>City</em>
                </MenuItem>
                <MenuItem value={71}>Delhi</MenuItem>
                <MenuItem value={72}>Noida</MenuItem>
                <MenuItem value={73}>Gurugram</MenuItem>
                <MenuItem value={-1}>Other</MenuItem>
              </Select>
              <p style={{color:"red"}}>{touched.city && errors.city}</p>
            </FormControl>            
          </Grid>
        </Grid> */}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field
              name="exactAddress"
              label="Exact address"
              variant= "outlined"
              as={TextField}
              fullWidth={true}
              inputProps={{
                maxlength: 1000
              }}
              helperText={`${values.exactAddress?values.exactAddress.length:0}/1000`}
            />    
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextField
              className={classes.text_container}
              id='googleAddress'
              variant='outlined'
              fullWidth={true}
              label='Search Google Maps'
              name='searchAddress'
              value={values.searchAddress}
              onChange={handleChange}
              onKeyPress={event =>
                event.key === 'Enter' ? event.preventDefault() : null
              }
              InputProps={{
                className: classes.input,
                inputProps: {
                  className: classes.inputStyle
                }
              }}
              InputLabelProps={{
                className: classes.inputLabel
              }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Field
              name="pincode"
              label="Pincode *"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              disabled={isValidPin}
              error={!isValidPin}
              helperText={!isValidPin && "Please enter a valid pin number."}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name="cityName"
              label="City *"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name="stateName"
              label="State *"
              variant= "outlined"
              fullWidth={true}
              as={TextField}
              disabled
            />
          </Grid>
        </Grid>
        <span className="formHeadings">Salary range</span>
        <Grid container spacing={0}>
          {values.jobCategory!==3 && <Fragment>
            <Grid item xs={4}>
              <Field
                name="minSalary"
                label="Minimum Salary *"
                variant= "outlined"
                fullWidth={true}
                as={TextField}
                InputProps={{
                  startAdornment:(<InputAdornment position="start">₹</InputAdornment>)
                }}
                error={touched.minSalary && errors.minSalary}
                helperText={touched.minSalary && errors.minSalary}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="maxSalary"
                label="Maximum Salary *"
                variant= "outlined"
                as={TextField}
                fullWidth={true}
                InputProps={{
                  startAdornment:(<InputAdornment position="start">₹</InputAdornment>)
                }}
                error={touched.maxSalary && errors.maxSalary}
                helperText={touched.maxSalary && errors.maxSalary}
              />
            </Grid>
          </Fragment>}
          {values.jobCategory===3 && <Fragment>
            <Grid item xs={4}>
              <Field
                name="odSalary"
                label="Salary *"
                variant= "outlined"
                fullWidth={true}
                as={TextField}
                InputProps={{
                  startAdornment:(<InputAdornment position="start">₹</InputAdornment>)
                }}
                error={touched.odSalary && errors.odSalary}
                helperText={touched.odSalary && errors.odSalary}
              />
            </Grid>
          </Fragment>}
          <Grid item xs={2}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
              <InputLabel id="salary-type">Salary type *</InputLabel>
              <Select
                name='salaryType'
                value={values.salaryType}
                fullWidth={true}
                label= "Salary type *"
                error={touched.salaryType && errors.salaryType}
                helperText={touched.salaryType && errors.salaryType}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  <em>Salary Type</em>
                </MenuItem>
                {values.jobCategory!==3 && 
                  <MenuItem value={"Monthly"}>Monthly</MenuItem> 
                }
                {values.jobCategory!==3 && 
                  <MenuItem value={"Yearly"}>Yearly</MenuItem>
                }
                {values.jobCategory===3 &&
                  <MenuItem value={"Daily"}>Daily</MenuItem>
                }
              </Select>
              <p style={{color:"red"}}>{touched.salaryType && errors.salaryType}</p>
            </FormControl>            
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field
              name="salaryCompensation"
              label="Salary Compensation Structure"
              variant= "outlined"
              fullWidth={true}
              as= {TextField}
              multiline= {true}
              rows={10}inputProps={{
                maxlength: 500
              }}
              helperText={`${values.salaryCompensation?values.salaryCompensation.length:0}/500`}
            />        
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field
              name="jobDescription"
              label="Job description *"
              variant= "outlined"
              fullWidth={true}
              as= {TextField}
              multiline= {true}
              rows={10}
              inputProps={{
                maxlength: 1500
              }}
              error={touched.jobDescription && errors.jobDescription}
              helperText={(touched.jobDescription && errors.jobDescription)?errors.jobDescription:`${values.jobDescription?values.jobDescription.length:0}/1500`}
            />        
          </Grid>
        </Grid>
        {values.jobCategory===1 &&
          <Fragment>
            <span className="formHeadings">Job Schedule</span>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                  <InputLabel id="noOfDays">No. of days *</InputLabel>
                  <Select
                    name='noOfDays'
                    value={values.noOfDays}
                    label= "No. of days *"
                    error={touched.noOfDays && errors.noOfDays}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      <em>No. of days</em>
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                  <p style={{color:"red"}}>{touched.noOfDays && errors.noOfDays}</p>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
                  <InputLabel id="noOfHours">No. of hours *</InputLabel>
                  <Select
                    name='noOfHours'
                    value={values.noOfHours}
                    label= "No. of hours *"
                    error={touched.noOfHours && errors.noOfHours}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      <em>No. of hours</em>
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                  </Select>
                  <p style={{color:"red"}}>{touched.noOfHours && errors.noOfHours}</p>
                </FormControl>
              </Grid>
            </Grid>   
          </Fragment> 
        }  
        {values.jobCategory===2 &&
          <Fragment>
            <span className="formHeadings">Job Schedule</span>
            <Grid container spacing={0} style={{marginTop: "40px"}}>
              <Field
                variant= "outlined"
                fullWidth={true}
                as= {WeekToggle}
                isdisabled={false}
                value={values.weekedays}
                handleWeekdays={setFieldValue}
                name= 'weekedays'
                error={touched.weekedays && errors.weekedays}
                helperText={touched.weekedays && errors.weekedays}
              />   
              <Grid container spacing={0}>
                <Grid item md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      label="Start time *"
                      name="startTime"
                      value={values.startTime}
                      onChange={date =>setFieldValue("startTime", moment(date).add(30, 'minutes').startOf('hour'))}
                      error={touched.startTime && errors.startTime}
                      helperText={touched.startTime && errors.startTime}
                    />  
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      label="End time *"
                      name="endTime"
                      value={values.endTime}
                      onChange={date =>setFieldValue("endTime", moment(date).add(30, 'minutes').startOf('hour'))}
                      error={touched.endTime && errors.endTime}
                      helperText={touched.endTime && errors.endTime}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid> 
          </Fragment>
        }
        {values.jobCategory===3 &&
          <Fragment>
            <span className="formHeadings">Job Schedule</span>
            <Grid container spacing={0} style={{marginTop: "40px"}}>
              <Grid container spacing={0}>
                <Grid item md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      label="Start Date *"
                      name="startDate"
                      format="dd/MM/yyyy"
                      value={values.startDate}
                      onChange={date =>setFieldValue("startDate", date)}
                      error={touched.startDate && errors.startDate}
                      helperText={touched.startDate && errors.startDate}
                    />  
                  </MuiPickersUtilsProvider>
                </Grid> 
                <Grid item md={3}>
                <FormControl fullWidth={true} variant="outlined" className={classes.formControl} >
                  <InputLabel id="noOfDays">No. of days *</InputLabel>
                  <Select
                    name='noOfDays'
                    value={values.noOfDays}
                    label= "No. of days"
                    error={touched.noOfDays && errors.noOfDays}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      <em>No. of days</em>
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                  </Select>
                  <p style={{color:"red"}}>{touched.noOfDays && errors.noOfDays}</p>
                </FormControl>
              </Grid> 
              </Grid>
              <Grid container spacing={0}>
                <Grid item md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      label="Start time *"
                      name="startTime"
                      value={values.startTime}
                      onChange={date =>setFieldValue("startTime", moment(date).add(30, 'minutes').startOf('hour'))}
                      error={touched.startTime && errors.startTime}
                      helperText={touched.startTime && errors.startTime}
                    />  
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      label="End time *"
                      name="endTime"
                      value={values.endTime}
                      onChange={date =>setFieldValue("endTime", moment(date).add(30, 'minutes').startOf('hour'))}
                      error={touched.endTime && errors.endTime}
                      helperText={touched.endTime && errors.endTime}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid> 
          </Fragment>
        }
        <Grid item xs={12} class="ErrorDiv">
          {(touched.companyName && errors.companyName)?<span>{`* ${errors.companyName}`}</span>:null}
          {(touched.vacancyStatus && errors.vacancyStatus)?<span>{`* ${errors.vacancyStatus}`}</span>:null}
          {(touched.jobCategory && errors.jobCategory)?<span>{`* ${errors.jobCategory}`}</span>:null}
          {(touched.numberOfOpenings && errors.numberOfOpenings)?<span>{`* ${errors.numberOfOpenings}`}</span>:null}
          {(touched.jobType && errors.jobType)?<span>{`* ${errors.jobType}`}</span>:null}
          {(touched.jobRole && errors.jobRole)?<span>{`* ${errors.jobRole}`}</span>:null}
          {(touched.city && errors.city)?<span>{`* ${errors.city}`}</span>:null}
          {(touched.minSalary && errors.minSalary)?<span>{`* ${errors.minSalary}`}</span>:null}
          {(touched.maxSalary && errors.maxSalary)?<span>{`* ${errors.maxSalary}`}</span>:null}
          {(touched.odSalary && errors.odSalary)?<span>{`* ${errors.odSalary}`}</span>:null}
          {(touched.salaryType && errors.salaryType)?<span>{`* ${errors.salaryType}`}</span>:null}
          {(touched.jobDescription && errors.jobDescription)?<span>{`* ${errors.jobDescription}`}</span>:null}
          {(touched.noOfDays && errors.noOfDays)?<span>{`* ${errors.noOfDays}`}</span>:null}
          {(touched.noOfHours && errors.noOfHours)?<span>{`* ${errors.noOfHours}`}</span>:null}
          {(touched.weekedays && errors.weekedays)?<span>{`* ${errors.weekedays}`}</span>:null}
          {(touched.startTime && errors.startTime)?<span>{`* ${errors.startTime}`}</span>:null}
          {(touched.endTime && errors.endTime)?<span>{`* ${errors.endTime}`}</span>:null}
          {(touched.startDate && errors.startDate)?<span>{`* ${errors.startDate}`}</span>:null}
        </Grid>
      </Grid>
    </div>
  );
};